import { ActionReducerMap } from '@ngrx/store';
import { phoneNumberManagementReducer, PhoneNumberManagementState } from './phone-number-management.state';

export interface FeatureState {
  phoneNumberManagement: PhoneNumberManagementState;
}

export const reducer: ActionReducerMap<FeatureState> = {
  phoneNumberManagement: phoneNumberManagementReducer,
};
