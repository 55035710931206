import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EntityService } from '../entity.service';
import { ChangeOrderFacade } from './change-order/change-order.facade';
import { ChangeOrder } from './change-order/change-order.model';
import { ChangeOrdersUiService } from './change-orders-ui.service';
import { ChangeOrdersEffects } from './change-orders.effects';
import { ChangeOrdersFacade } from './change-orders.facade';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([ChangeOrdersEffects])],
  providers: [
    ChangeOrderFacade,
    ChangeOrdersFacade,
    ChangeOrdersUiService,
    { provide: ChangeOrder, useClass: EntityService },
  ],
})
export class ChangeOrdersModule {}
