<ng-container *ngIf="!canEditProfile; else installersOwnProfile">
  <div class="alert alert__warning text-center m-20" data-cy="company-empty-product-installation-type">
    <p>This installer has claimed this installation type but it has not been verified by Go Carrera.</p>
  </div>
</ng-container>

<ng-template #installersOwnProfile>
  <div class="alert alert__warning text-center m-20" data-cy="installer-empty-product-installation-type">
    <p>
      You have claimed this installation type but it has not been verified by Go Carrera. Until verified, you can
      <strong class="button-text-red remove-button" (click)="removed.emit(selectedProductInstallationTypeClaim)" data-cy="remove-product-installation-type-button"
        >remove</strong
      >
      this from your profile.
    </p>
  </div>
</ng-template>
