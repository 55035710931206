<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="gc-green" (click)="usersUI.closeProfile()">
        <ion-icon name="chevron-back-outline"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <gc-user-header *ngIf="profile.currentInstallerProfile$ | push as profile" [user]="profile">
    <ion-button
      *ngIf="!profile.isOnTeam"
      (click)="jumpstartFavorites.jumpstarterFavorited(!!profile.favorite)"
      shape="round"
      fill="clear"
      color="warning"
      size="large"
    >
      <ion-icon name="star" [color]="!!profile.favorite ? 'warning' : 'medium'"></ion-icon>
    </ion-button>

    <ion-button
      *ngIf="profile.isOnTeam"
      (click)="jumpstartFavorites.removeJumpstarter(profile.favorite)"
      shape="round"
      fill="clear"
      color="danger"
      size="large"
    >
      <ion-icon name="trash"></ion-icon>
    </ion-button>
  </gc-user-header>

  <gc-general-area [installer]="profile.currentInstallerProfile$ | push"></gc-general-area>
</ion-content>

<ion-footer *ngIf="auth.user$ | push | isInstallerTeamLead">
  <ion-grid *ngIf="!(profile.currentInstallerProfile$ | push)?.isOnTeam">
    <ion-row>
      <!--  Need better way to do this than *ngIf *ngIf *ngIf ...   -->
      <ion-col
        *ngIf="
          !(
            jumpstartFavorites.jumpstarterFavoriteForCurrentUser$
            | push
            | jumpstarterFavoriteIsStatus: 'INVITED' : 'ACCEPTED' : 'DECLINED'
          );
          else showOffered
        "
      >
        <ion-button expand="block" (click)="jumpstartFavorites.jumpstarterHireOfferExtended()">
          Offer To Hire
        </ion-button>
      </ion-col>
      <ng-template #showOffered>
        <ion-col class="ion-text-center">
          <ion-label color="medium"> Your offer to hire has been sent.</ion-label>
        </ion-col>
      </ng-template>
    </ion-row>
  </ion-grid>
</ion-footer>
