import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Kudos } from './kudos.model';

export const {
  initialState,
  facade: KudosFacadeBase,
  actions: { create: createKudos, createSuccess: createKudosSuccess },
} = buildState(Kudos);

export function kudosReducer(state = initialState): IEntityState<Kudos> {
  return state;
}
