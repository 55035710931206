<ion-button
  *ngIf="colorMode === 'dark'"
  [disabled]="isLynkLoading"
  id="start-lynk-button"
  mode="ios"
  color="dark"
  size="medium"
  (click)="startNewLynkSession()"
>
  <ion-img
    *ngIf="!isLynkLoading"
    src="/assets/images/logos/lynk/lynk_on_dark.svg"
    slot="start"
  ></ion-img>
  <ion-spinner *ngIf="isLynkLoading"></ion-spinner>
</ion-button>

<ion-button
  *ngIf="colorMode === 'light'"
  [disabled]="isLynkLoading"
  id="start-lynk-button-clear"
  mode="ios"
  color="dark"
  size="medium"
  (click)="startNewLynkSession()"
>
  <ion-img
    *ngIf="!isLynkLoading"
    src="/assets/images/logos/lynk/lynk_on_light.svg"
    slot="start"
  ></ion-img>
  <ion-spinner *ngIf="isLynkLoading"></ion-spinner>
</ion-button>

