import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { routeStartsWithPath } from '../../../shared/utils/ngrx';
import { onlyRoles } from '../../../shared/utils/roles.operators';
import { ensureChildrenRequired } from '../../../shared/utils/rxjs';
import { RequireId } from '../../../shared/utils/types';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedRole, authenticatedUser } from '../../app/auth/auth.selectors';
import { AppState } from '../../state';
import { Company } from '../companies/company.model';
import { companyInvitedToConnections, networkConnectionAccepted, networkConnectionDeclined } from '../network.actions';
import { sendUserInviteSuccess, showAddToConnections } from '../team/team.actions';
import { User } from '../user/user.model';
import { loadUser } from '../user/user.state';
import { Connection, ConnectionStatus, Source } from './connection.model';
import {
  connectionCreationSucceeded,
  createConnection,
  deleteConnection,
  loadAllConnections,
  replaceConnection,
} from './connection.state';

@Injectable()
export class ConnectionEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  listenForRouteVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeStartsWithPath('/app/dashboard', '/app/team', '/app/work/custom-calendar'),
      onlyRoles(this.store.select(authenticatedRole), 'installerLead', 'companyAdmin', 'companyManager'),
      map(() => loadAllConnections()),
    ),
  );

  loadConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showAddToConnections),
      map(() => loadAllConnections()),
    ),
  );

  handleInvitedInstallerTeamLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendUserInviteSuccess),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(({ connection }) => [
        connectionCreationSucceeded({ entity: connection }),
        loadUser({ keys: connection.installerTeamLeadId }),
      ]),
    ),
  );

  createNetworkConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyInvitedToConnections),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ company }, user]) => ({ company, user })),
      ensureChildrenRequired<
        { company?: Company; user?: RequireId<User> },
        { company: Company; user: RequireId<User> }
      >(value => !!value?.company?.id && !!value?.user?.id),
      map(({ company, user }) => ({
        companyId: company.id,
        installerTeamLeadId: user.id,
        source: Source.Installer,
      })),
      map(pendingNetwork => createConnection({ entity: pendingNetwork as Connection })),
    ),
  );

  acceptPendingNetworkConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(networkConnectionAccepted),
      map(({ connection }) => ({
        ...connection,
        status: connection.status === ConnectionStatus.Pending ? ConnectionStatus.Accepted : ConnectionStatus.Approved,
      })),
      map(entity => replaceConnection({ entity })),
    ),
  );

  declineNetworkConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(networkConnectionDeclined),
      map(({ connection, received }) =>
        received && connection.status === ConnectionStatus.Pending
          ? replaceConnection({ entity: { ...connection, status: ConnectionStatus.Declined } })
          : deleteConnection({ entity: connection }),
      ),
    ),
  );
}
