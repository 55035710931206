<ng-container *ngIf="installationType && installationType.claimed && installationType.claimed.dateClaimed">
  <ion-item lines="none">
    <h3>EXPERIENCE DOCUMENTATION <span style="color: #ff0000">*</span></h3>
  </ion-item>
  <ion-item lines="none" *ngIf="files | isEmpty; else fileList">
    Upload any supporting documents for this skill. We'll review and activate after verification is complete.
  </ion-item>
  <ng-template #fileList>
    <ion-list>
      <ion-item *ngFor="let file of files">
        <ion-label>{{ file.filename }}</ion-label>
      </ion-item>
    </ion-list>
  </ng-template>

  <gc-file-drop-target
    buttonLabel="Browse to Add Experience Documentation"
    (filesDropped)="addDroppedFiles($event)"
    (filesAdded)="addFiles($event)"
    class="ion-margin"
  ></gc-file-drop-target>
</ng-container>
