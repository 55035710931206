import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AnyUser, CompanyAdmin, ProjectManager } from '../../domains/users';
import { AddressForm, TypedFormGroup } from '../../shared/utils/typed-form-group';

export class BasicProfileForm extends TypedFormGroup {
  readonly firstName = this.getFormControl('firstName');
  readonly lastName = this.getFormControl('lastName');
  readonly email = this.getFormControl('email');
  readonly address = this.getControl('address') as AddressForm;
  readonly profilePhoto = this.getFormControl('profilePhoto');
  readonly color = this.getFormControl('color');

  get updatedModel(): Partial<AnyUser> {
    const updatedModel = {
      ...this.model,
      ...this.value,
      addresses: [this.address.updatedModel], //, ...(this.model?.addresses?.slice(1) ?? [])],
      address: undefined,
      profilePhoto: undefined,
    };
    return updatedModel;
  }

  constructor(
    private model?: Partial<AnyUser>,
    builder = new UntypedFormBuilder(),
  ) {
    super(
      {
        controls: {
          firstName: [model?.firstName ?? null],
          lastName: [model?.lastName ?? null],
          email: [model?.email ?? null],
          address: new AddressForm(model?.addresses?.[0], builder),
          profilePhoto: [model?.profilePhoto ?? null],
          color: [(model as ProjectManager | CompanyAdmin)?.color ?? '#000000'],
        },
      },
      builder,
    );

    this.enableValidators();
  }

  updateModel(model: Partial<AnyUser>): void {
    this.patchValue({
      ...model,
      color: (model as any)?.color ?? '#000000',
    });
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = value as AnyUser;
    super.patchValue({ ...value, address: this.model.addresses?.[0], addresses: undefined }, options);
  }

  enableValidators() {
    this.firstName.setValidators(Validators.required);
    this.lastName.setValidators(Validators.required);
    this.email.setValidators(Validators.required);
    this.address.enableValidators();

    this.updateValueAndValidity();
  }

  disableValidators() {
    this.firstName.clearValidators();
    this.lastName.clearValidators();
    this.email.clearValidators();
    this.address.disableValidators();

    this.updateValueAndValidity();
  }
}
