import { ActionReducerMap } from '@ngrx/store';
import { profileReducer, ProfileState } from './profile.state';


export interface FeatureState {
  profile: ProfileState;
}

export const reducer: ActionReducerMap<FeatureState> = {
  profile: profileReducer,
}
