import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { hideLoginAlert, registerNewUser, showAlert, signup } from './login.state';
import { NewUserSignUpInfo } from './models/new-user-sign-up-info';

@Injectable({
  providedIn: 'root',
})
export class LoginFacade {
  showAlert$ = this.store.select(showAlert);

  constructor(private store: Store<AppState>) {}

  hideAlert() {
    this.store.dispatch(hideLoginAlert());
  }

  signUp() {
    this.store.dispatch(signup());
  }

  registerNewUser(userInfo: NewUserSignUpInfo) {
    this.store.dispatch(registerNewUser({ userInfo }));
  }
}
