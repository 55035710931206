import { createAction, props } from '@ngrx/store';
import { UserType } from '../../../domains/users';
import { UserQuery } from '../models/user-query.model';

export const lookupUser = createAction('[CORE: Invites] (User) Lookup', props<{ email: string; userType: UserType }>());
export const userLookupSucceeded = createAction(
  '[CORE: Invites] (User Lookup) Succeeded',
  props<{ user: UserQuery }>(),
);
export const userLookupFailed = createAction('[CORE: Invites] (User Lookup) Failed', props<{ error: Error | any }>());
