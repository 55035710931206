import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faPlus, faRuler } from '@fortawesome/free-solid-svg-icons';
import { DisplayGroupAllowance } from '../../../domains/work-order-item-group.selectors';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';
import { SelectedArea } from '../../../domains/work/work.state';


@Component({
  selector: 'gc-add-change-item-fab',
  templateUrl: './add-change-item-fab.component.html',
  styleUrls: ['./add-change-item-fab.component.scss'],
})
export class AddChangeItemFabComponent {
  @Input() area!: SelectedArea;
  @Input() userType?: string;
  @Input() workOrder!: WorkOrder;
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];

  @Output() changeOrderAdded = new EventEmitter<WorkOrder>();

  readonly faRuler = faRuler;
  readonly faPlus = faPlus;
}
