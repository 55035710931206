import { Pipe, PipeTransform } from '@angular/core';
import { USER_TYPES, UserType } from '~gc/domains/users/user/user.model';

type UserTypes = typeof USER_TYPES;

@Pipe({ name: 'userType' })
export class UserTypePipe implements PipeTransform {
  transform<T extends UserType>(user: { type: T }): UserTypes[T] {
    return USER_TYPES[user.type];
  }
}
