import { Pipe, PipeTransform } from '@angular/core';
import { isRole, PriceInfoRoles } from '../../domains/app/auth/auth.maps';

@Pipe({
  name: 'allowsPriceInfo',
})
export class AllowsPriceInfoPipe implements PipeTransform {
  transform(currentRole?: string): boolean {
    return !!currentRole && isRole(currentRole, ...PriceInfoRoles);
  }
}
