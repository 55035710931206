import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { authenticatedUser } from '../app/auth/auth.selectors';
import { DetailedInvoice } from '../models/detailed-invoice';
import { Company, Installer, isInstallerLead, User } from '../users';
import { companyEntities } from '../users/companies/company.state';
import { userEntities } from '../users/user/user.state';
import { AwardedWorkOrder } from '../work/models/detailed-work-order';
import { WorkOrder } from '../work/work-order/work-order.model';
import { workOrderEntities } from '../work/work-order/work-order.state';
import { INSTALLER_INVOICE_STATUS_GROUP_STATUS_MAP, INVOICE_STATUS_GROUP_STATUS_MAP } from './invoice-and-payment.maps';
import {
  currentStatusGroup,
  invoicesFailedToLoad,
  invoicesLoading,
  invoicesMutating,
} from './invoice-and-payment.selectors';
import { allInvoices, InvoiceStatusGroup as StatusGroup, sortedInvoices } from './invoice/invoices.state';
import { FlaggedInvoice } from './models/flagged-invoice';

export const nonArchivedInvoices = createSelector(sortedInvoices, invoices =>
  invoices.filter(invoice => !invoice?.isArchived),
);

export const flaggedInvoices = createSelector(
  nonArchivedInvoices,
  invoicesMutating,
  invoicesFailedToLoad,
  invoicesLoading,
  (invoices, mutating, failedLoad, loading): FlaggedInvoice[] =>
    invoices.map(invoice =>
      invoice.id
        ? {
            ...invoice,
            mutating: mutating.includes(invoice.id),
            failedLoad: failedLoad.includes(invoice.id),
            loading: loading.includes(invoice.id),
          }
        : invoice,
    ),
);

export const invoiceStatusGroupStatusMapForUser = createSelector(
  authenticatedUser,
  (user): Record<string, string[]> =>
    user && isInstallerLead(user) ? INSTALLER_INVOICE_STATUS_GROUP_STATUS_MAP : INVOICE_STATUS_GROUP_STATUS_MAP,
);

export const attachInstallerToWorkOrder = (
  users: IEntityDictionary<User>,
  workOrder: WorkOrder,
): AwardedWorkOrder | undefined =>
  workOrder
    ? {
        ...workOrder,
        awardedToInstaller: users[workOrder?.awardedToInstallerId] as Installer,
        _isAwardedWorkOrder: true,
      }
    : undefined;

export const detailedInvoices = createSelector(
  flaggedInvoices,
  workOrderEntities,
  userEntities,
  (
    invoices: FlaggedInvoice[],
    workOrders: IEntityDictionary<WorkOrder>,
    users: IEntityDictionary<User>,
  ): DetailedInvoice[] =>
    invoices?.length
      ? invoices.map(invoice => ({
          ...invoice,
          installer: invoice.installerId ? (users[invoice.installerId] as Installer) : undefined,
          workOrder: attachInstallerToWorkOrder(users, workOrders[invoice.workOrderId]),
        }))
      : (invoices as DetailedInvoice[]),
);

export const detailedInvoicesWithCompany = createSelector(
  detailedInvoices,
  companyEntities,
  (invoices: DetailedInvoice[], companies: IEntityDictionary<Company>) =>
    invoices.map(invoice => ({ ...invoice, company: invoice.companyId ? companies[invoice.companyId] : undefined })),
);

export const filterInvoicesByStatusGroup = (
  invoices: DetailedInvoice[],
  statusGroup: StatusGroup | undefined,
  statusGroupStatusMap: Record<string, string[]>,
): DetailedInvoice[] =>
  statusGroup ? invoices.filter(order => statusGroupStatusMap[statusGroup].includes(order.status)) : [];

export const invoicesByCurrentStatusGroup = createSelector(
  detailedInvoicesWithCompany,
  currentStatusGroup,
  invoiceStatusGroupStatusMapForUser,
  filterInvoicesByStatusGroup,
);

export const invoicesReadyToSign = createSelector(detailedInvoicesWithCompany, invoices =>
  filterInvoicesByStatusGroup(invoices, 'ready_to_sign', INSTALLER_INVOICE_STATUS_GROUP_STATUS_MAP),
);

export const sortedInvoicesByCurrentStatusGroup = createSelector(invoicesByCurrentStatusGroup, invoices =>
  invoices.sort((a, b) =>
    (a.createdOn ?? OLD_DATE) < (b.createdOn ?? OLD_DATE) ? 1 : -1,
  ),
);

const OLD_DATE = '1970-01-01T00:00:00';
