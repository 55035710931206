import { Component, Input } from '@angular/core';
import { parseISO } from 'date-fns';
import { PendingNegotiationDetails } from '~gc/domains/negotiations/models/pending-negotiation.model';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';
import { createDatePickerTime } from '../../../shared/utils/utils';
import { NegotiationForm } from '../negotiation.form';

@Component({
  selector: 'gc-bid-ask-negotiation-date',
  templateUrl: './bid-ask-negotiation-date.component.html',
  styleUrls: ['./bid-ask-negotiation-date.component.scss'],
})
export class BidAskNegotiationDateComponent {
  today = createDatePickerTime();
  @Input() role!: string;
  @Input() pendingNegotiation!: Partial<PendingNegotiationDetails>;
  @Input() form!: NegotiationForm;
  @Input() workOrder!: WorkOrder;

  getEndDate() {
    return createDatePickerTime(parseISO(this.workOrder.scheduledEndDate));
  }
}
