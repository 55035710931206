<ion-content data-e2e="dashboard-page">
  <ion-grid class="ion-no-padding">
    <ion-row class="ion-padding-top">
      <ion-col class="ion-padding-horizontal" sizeLg="6" offsetLg="3" sizeXl="4" offsetXl="4">
        <ion-card mode="ios">
          <ion-card-header>
            <ion-img id="logo" src="/assets/images/logo.svg"></ion-img>
            <ion-card-title>Your Account Needs Attention</ion-card-title>
            <ion-card-subtitle> Hmm, you don't have full access yet </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            It looks like you don't have a role assigned in the system quite yet. If you need help accessing Go Carrera,
            contact support at
            <a href="mailto:support@gocarrera.com">support@gocarrera.com</a> for assistance.
            <ion-button (click)="auth.logOut()" color="light" mode="ios" expand="block" class="ion-margin-top"
              >Logout
            </ion-button>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
