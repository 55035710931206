import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityIdentity, getKeyFromModel, IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { Store } from '@ngrx/store';
import { from, Observable, of, switchMap, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { httpResponseMetadata, httpResponseReceived } from '../http';
import { CacheMissException, ResponseCacheService } from '../http/response-cache.service';
import { uuid } from '../shared/file-uploads/file.actions';
import {
  buildUrl,
  EntityCriteria,
  IDEMPOTENCY_KEY_HEADER,
  resolveRetryCriteria,
  RetryCriteria,
} from './entity.service.utils';

export class EntityServiceError extends Error {
  constructor(
    message: string,
    public readonly originalError?: Error,
  ) {
    super(message);
  }
}

export class NotModifiedException extends EntityServiceError {
  constructor(message: string, originalError?: Error) {
    super(message, originalError);
  }
}

@Injectable()
export class EntityService implements IAutoEntityService<any> {
  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly cache: ResponseCacheService,
  ) {}

  private tryGetFromCache<T>(url: string): Observable<T> {
    return from(this.cache.get(url)).pipe(
      switchMap(body =>
        body ? of(body) : throwError(() => new NotModifiedException('Entity not modified since last request.')),
      ),
      catchError(err =>
        err instanceof CacheMissException
          ? this.http.get<T>(url, { observe: 'response' }).pipe(switchMap(res => this.cacheAndReturnResponse(url, res)))
          : throwError(() => err),
      ),
    );
  }

  private cacheAndReturnResponse<T>(url: string, res: HttpResponse<T>): Observable<T | null> {
    return of(res.body).pipe(
      tap(body => {
        this.store.dispatch(
          httpResponseReceived({
            response: {
              status: res.status,
              url,
              receivedAt: new Date().toUTCString(),
              headers: res.headers
                .keys()
                .reduce(
                  (headers: any, key: string) => ((headers[key.toLowerCase()] = res.headers.get(key)), headers),
                  {},
                ),
            },
            body,
          }),
        );
      }),
    );
  }

  private issueRequest<T>(url: string, retry?: boolean | RetryCriteria): Observable<T | null> {
    return this.store.select(httpResponseMetadata).pipe(
      first(),
      map(metadata => metadata[url]),
      map(metadata =>
        metadata ? { etag: metadata?.headers?.['etag'] ?? undefined, receivedAt: metadata.receivedAt } : {},
      ),
      switchMap(({ etag, receivedAt }) =>
        resolveRetryCriteria(
          this.http
            .get<T>(url, {
              observe: 'response',
              headers: etag
                ? {
                    'If-None-Match': etag,
                  }
                : receivedAt
                ? {
                    'If-Modified-Since': receivedAt,
                  }
                : undefined,
            })
            .pipe(
              switchMap(res =>
                res.status === 304 ? this.tryGetFromCache<T>(url) : this.cacheAndReturnResponse(url, res),
              ),
              catchError((err: HttpErrorResponse) =>
                err.status === 304 ? this.tryGetFromCache<T>(url) : throwError(() => err),
              ),
            ),
          retry,
        ),
      ),
    );
  }

  load(entityInfo: IEntityInfo, key: any, criteria?: EntityCriteria): Observable<any> {
    const url = buildUrl(entityInfo, criteria, key);
    return this.issueRequest<any>(url, criteria?.retry);
  }

  loadMany(entityInfo: IEntityInfo, criteria?: EntityCriteria): Observable<any> {
    const url = buildUrl(entityInfo, criteria);
    return this.issueRequest<any[]>(url, criteria?.retry);
  }

  loadAll(entityInfo: IEntityInfo, criteria?: EntityCriteria): Observable<any> {
    const url = buildUrl(entityInfo, criteria);
    return this.issueRequest<any[]>(url, criteria?.retry);
  }

  create(entityInfo: IEntityInfo, entity: any, criteria?: EntityCriteria, originalEntity?: any): Observable<any> {
    const url = buildUrl(entityInfo, criteria);
    return criteria?.idempotent
      ? resolveRetryCriteria(
          this.http.post<any>(url, entity, {
            headers: {
              [IDEMPOTENCY_KEY_HEADER]: uuid(),
            },
          }),
          criteria?.retry ?? true,
        )
      : this.http.post<any>(url, entity);
  }

  update(entityInfo: IEntityInfo, entity: any, criteria?: EntityCriteria, originalEntity?: any): Observable<any> {
    const url = buildUrl(entityInfo, criteria, getKeyFromModel(entityInfo.modelType, entity));
    return this.http.patch<any>(url, entity);
  }

  replace(entityInfo: IEntityInfo, entity: any, criteria?: EntityCriteria, originalEntity?: any): Observable<any> {
    const url = buildUrl(entityInfo, criteria, getKeyFromModel(entityInfo.modelType, entity));
    return this.http.put<any>(url, entity);
  }

  delete(entityInfo: IEntityInfo, entity: any, criteria?: EntityCriteria, originalEntity?: any): Observable<any> {
    const url = buildUrl(entityInfo, criteria, getKeyFromModel(entityInfo.modelType, entity));
    return this.http.delete<any>(url, entity).pipe(map(() => entity));
  }

  deleteByKey(entityInfo: IEntityInfo, key: EntityIdentity, criteria?: EntityCriteria): Observable<EntityIdentity> {
    const url = buildUrl(entityInfo, criteria, key);
    return this.http.delete<any>(url).pipe(map(() => key));
  }
}
