import { Observable, pipe, UnaryFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

type NotNill<T> = T extends null | undefined ? never : T;

type Primitive = undefined | null | boolean | string | number | Function | Date | BigInt;

type AssertDeepRequired<T> = {
  [P in keyof T]-?: T[P] extends Array<infer U>
    ? Array<U extends Primitive ? NonNullable<U> : NonNullable<AssertDeepRequired<U>>>
    : T[P] extends ReadonlyArray<infer U2>
    ? ReadonlyArray<U2 extends Primitive ? NonNullable<U2> : NonNullable<AssertDeepRequired<U2>>>
    : T[P] extends Primitive
    ? NonNullable<T[P]>
    : NonNullable<AssertDeepRequired<T[P]>>;
};

type ChildrenRequired<T> = {
  [P in keyof T]-?: T[P] extends Primitive ? NonNullable<T[P]> : NonNullable<Required<T[P]>>;
};

export const onlyTrue = pipe(filter(value => value === true));
export const onlyFalse = pipe(filter(value => value === false));
export const onlyExisting = <T>(): UnaryFunction<Observable<T>, Observable<T>> => pipe(filter(value => !!value));
export const onlyNonExisting = pipe(filter(value => !value));
export const onlyNonNullish = pipe(filter(value => value != null));
export const onlyNullish = pipe(filter(value => value == null));

export const ensureExists = <T>(
  predicate: (value: T | undefined | null) => boolean,
): UnaryFunction<Observable<T | undefined | null>, Observable<T>> =>
  pipe(filter((value: T | undefined | null): value is T => predicate(value)));

// @ts-ignore TS2344
export const ensureChildrenExist = <T, R extends T = Required<T>>(
  predicate: (value: Partial<T> | undefined | null) => boolean,
): UnaryFunction<Observable<Partial<T | undefined | null>>, Observable<R>> =>
  pipe(filter((value: Partial<T> | undefined | null): value is R => predicate(value)));

export const ensureRequired = <T, R extends T = Required<T>>(
  predicate: (value: T | undefined | null) => boolean,
): UnaryFunction<Observable<T | undefined | null>, Observable<R>> =>
  pipe(filter((value: T | undefined | null): value is R => predicate(value)));

export const ensureRequiredAs = <T, R extends T = Required<T>>(
  predicate: (value: T | undefined | null) => boolean,
): UnaryFunction<Observable<T | undefined | null>, Observable<R>> =>
  pipe(filter((value: T | undefined | null): value is R => predicate(value)));

// @ts-ignore TS2344
export const ensureChildrenRequired = <T, R extends T = ChildrenRequired<T>>(
  predicate: (value: T | undefined | null) => boolean,
): UnaryFunction<Observable<T | undefined | null>, Observable<R>> =>
  pipe(filter((value: T | undefined | null): value is R => predicate(value)));
