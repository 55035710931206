import { PhoneNumber, PhoneNumberType } from '../../domains/phone-number';
import { PhoneNumberEntry } from '../../shared/components/advanced-phone-number-manager/models/phone-number-entry.model';
import { VerifyingPhoneNumberDetails } from './store/phone-number-management.state';

export const makePhoneNumberEntry =
  (details: VerifyingPhoneNumberDetails) =>
  (phoneNumber: PhoneNumber): PhoneNumberEntry => ({
    phoneNumber,
    isVerifying: details[phoneNumber.number]?.isVerifying ?? false,
    verificationError: details[phoneNumber.number]?.error,
    type: phoneNumber.type === PhoneNumberType.Mobile ? 'M' : phoneNumber.type === PhoneNumberType.Office ? 'W' : 'O',
  });
