import { Injectable } from '@angular/core';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { faChartNetwork } from '@fortawesome/pro-solid-svg-icons';
import { AlertController, PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { AuthFacade } from '../../domains/app/auth/auth.facade';
import { Installer, User } from '../../domains/users';
import { Connection } from '../../domains/users/connection/connection.model';
import { disconnectUser, removeUser } from '../../domains/users/users-ui.actions';
import { isConnectedTo } from '../../shared/pipes/is-connected-to.pipe';
import { CustomListPopoverComponent } from '../../shared/popovers/custom-list-popover/custom-list-popover.component';
import { ListOption } from '../../shared/popovers/types/list-options';


@Injectable()
export class ViewInstallerProfileEnhancedUiService {
  user$ = this.auth.user$;
  user?: User;

  constructor(
    private readonly popovers: PopoverController,
    private readonly alerts: AlertController,
    private readonly auth: AuthFacade,
  ) {
    this.user$.subscribe(user => (this.user = user));
  }

  async showProfileActionsMenu(
    event: Event,
    installer: Installer,
    connection?: Connection,
  ): Promise<OverlayEventDetail<ListOption>> {
    const listOptions = [];

    if (this.user && isConnectedTo(installer, this.user, connection)) {
      listOptions.push({ name: disconnectUser.type, label: 'Disconnect', color: 'dark', icon: faChartNetwork });
    }

    if (installer.type === 'EMPLOYEE_INSTALLER') {
      if (!connection) {
        listOptions.push({
          name: removeUser.type,
          label: 'Remove Employee Installer',
          color: 'dark',
          icon: faTrashCan,
        });
      }
    }

    const popover = await this.popovers.create({
      component: CustomListPopoverComponent,
      componentProps: {
        title: 'Actions',
        listOptions: listOptions,
      },
      event,
    });
    await popover.present();
    return await popover.onDidDismiss();
  }
}
