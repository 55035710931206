import { createAction, props } from '@ngrx/store';
import { DetailedProductInstallationTypeClaimed } from '../../core/installer-profile';
import { PhoneNumber } from '../../domains/phone-number';
import { Category } from '../../domains/training/category/category.model';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';
import { Installer, Manager } from '../../domains/users';
import { Connection } from '../../domains/users/connection/connection.model';
import { InstallerProfileAreas } from './store/ui.state';

export const installerCalled = createAction(
  '[MODAL: View Installer Profile Enhanced] (Installer Call) Called',
  props<{ installer: Installer }>(),
);

export const installerEmailed = createAction(
  '[MODAL: View Installer Profile Enhanced] (Installer Email) Emailed',
  props<{ installer: Installer }>(),
);

export const installerWebsiteVisited = createAction(
  '[MODAL: View Installer Profile Enhanced] (Installer Website) Visited',
  props<{ installer: Installer }>(),
);

export const optionsMenuOpened = createAction(
  '[MODAL: View Installer Profile Enhanced] (Options Menu) Opened',
  props<{ event: Event; installer: Installer; connection?: Connection }>(),
);

export const overviewSelected = createAction(
  '[MODAL: View Installer Profile Enhanced] (Overview) Selected',
  props<{ area: InstallerProfileAreas }>(),
);

export const backgroundSelected = createAction(
  '[MODAL: View Installer Profile Enhanced] (Background) Selected',
  props<{ area: InstallerProfileAreas }>(),
);

export const complianceSelected = createAction(
  '[MODAL: View Installer Profile Enhanced] (Compliance) Selected',
  props<{ area: InstallerProfileAreas }>(),
);

export const trainingSelected = createAction(
  '[MODAL: View Installer Profile Enhanced] (Training) Selected',
  props<{ area: InstallerProfileAreas }>(),
);

export const detailsSelected = createAction(
  '[MODAL: View Installer Profile Enhanced] (Details) Selected',
  props<{ area: InstallerProfileAreas }>(),
);

export const installTypeClaimSelected = createAction(
  '[MODAL: View Installer Profile Enhanced] (Claim) Selected',
  props<{ claim: DetailedProductInstallationTypeClaimed }>(),
);

export const defaultClaimSelected = createAction(
  '[Installer Profile] (Default Claim) Selected',
  props<{ claim: DetailedProductInstallationTypeClaimed }>(),
);

export const closeInstallerProfileEnhancedModal = createAction(
  '[MODAL: View Installer Profile Enhanced] Closed',
  props<{ installer: Installer }>(),
);

export const editMyProfileSelected = createAction(
  '[MODAL: View Installer Profile Enhanced] (Edit Profile) Selected',
  props<{ installer: Installer }>(),
);

export const editMyBasicProfileSelected = createAction(
  '[MODAL: View Basic Profile] (Edit Profile) Selected',
  props<{ user: Manager }>(),
);

export const productInstallationTypeAdding = createAction(
  '[MODAL: View Installer Profile Enhanced] (New Product Installation Type Claim) Adding',
);

export const trainingClaimAdding = createAction(
  '[MODAL: View Installer Profile Enhanced] (New Training Claim) Adding',
  props<{ category: Category }>(),
);

export const productInstallationSubtypeAddingFromOverview = createAction(
  '[MODAL: View Installer Profile Enhanced] (New Product Installation Subtype) Adding [From Overview]',
  props<{ installType: ProductInstallationType }>(),
);

export const productInstallationSubtypeAddingFromEducationAndTraining = createAction(
  '[MODAL: View Installer Profile Enhanced] (New Product Installation Subtype) Adding [From Education and Training]',
  props<{ installType: ProductInstallationType }>(),
);

export const phoneNumberVerifying = createAction(
  '[MODAL: View Installer Profile Enhanced] (Phone Number) Verifying',
  props<{ phoneNumber: PhoneNumber; code: string }>(),
);

export const phoneNumbersAdding = createAction(
  '[MODAL: View Installer Profile Enhanced] (New Phone Number) Adding',
  props<{ phoneNumber: PhoneNumber }>(),
);

export const phoneNumberRemoving = createAction(
  '[MODAL: View Installer Profile Enhanced] (Phone Number) Removing',
  props<{ phoneNumber: PhoneNumber }>(),
);

export const phoneNumberCodeRefreshed = createAction(
  '[MODAL: View Installer Profile Enhanced] (Phone Number) Code Refreshed',
  props<{ phoneNumber: PhoneNumber }>(),
);
