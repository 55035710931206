import { Component, Input } from '@angular/core';
import { DetailedHammerRating } from '../../../domains/users/models/installer-profile';

@Component({
  selector: 'gc-hammer-rating-details',
  templateUrl: './hammer-rating-details.component.html',
  styleUrls: ['./hammer-rating-details.component.scss'],
})
export class HammerRatingDetailsComponent {
  @Input() ratings?: DetailedHammerRating[];
}
