import { Component } from '@angular/core';
import { faHandshakeSimpleSlash } from '@fortawesome/free-solid-svg-icons/faHandshakeSimpleSlash';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { ProfileFacade, TeamFacade, UsersUIFacade } from '~gc/domains/users';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';

@Component({
  selector: 'gc-view-installer-profile-modal',
  templateUrl: './view-installer-profile.modal.html',
  styleUrls: ['./view-installer-profile.modal.scss'],
})
export class ViewInstallerProfileModalComponent {
  selectedArea = 'general';

  protected readonly faTrashCan = faTrashCan;
  protected readonly faHandshakeSimpleSlash = faHandshakeSimpleSlash;

  constructor(
    public readonly auth: AuthFacade,
    public readonly team: TeamFacade,
    public readonly profile: ProfileFacade,
    public readonly usersUI: UsersUIFacade,
  ) {}

  selectArea(area: string): void {
    this.selectedArea = area;
  }
}
