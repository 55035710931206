import { createSelector } from '@ngrx/store';
import { invoiceEntities } from '~gc/domains/invoices/invoice/invoices.state';
import { get, tryGet, tryGetOrDefault } from '~gc/shared/utils/func';
import { numberOfUnitsStillPending } from '../../shared/pipes/number-of-units-still-pending.pipe';
import { compose } from '../../shared/utils/compose.util';
import { ChangeOrder, ChangeOrderStatus } from '../change-orders/change-order/change-order.model';
import { InvoiceableWorkOrderItem } from '../models/invoiceable-work-order-item';
import { HasWorkOrderItemId } from '../types';
import { User } from '../users';
import { allUsers } from '../users/user/user.state';
import { whereMatchesWorkOrder } from '../utils';
import { currentChangeOrders, currentWorkOrderItems } from '../work-order-items-detail.selectors';
import { WorkOrderItem } from '../work/work-order-item/work-order-item.model';
import { WorkOrder } from '../work/work-order/work-order.model';
import { currentWorkOrder } from '../work/work-order/work-order.state';
import { invoicesAndPaymentState } from './invoice-and-payment.state';
import { createStatusGroupCounts } from './invoice-count/invoice-count.state';
import { InvoiceItem } from './invoice-item/invoice-item.model';
import { allInvoiceItems } from './invoice-item/invoice-item.state';
import { InvoiceStatus } from './invoice/invoice.model';

export const currentStatusGroup = createSelector(invoicesAndPaymentState, tryGet('currentStatusGroup'));
export const searchCriteria = createSelector(invoicesAndPaymentState, tryGetOrDefault('searchCriteria', ''));
export const pendingInvoice = createSelector(invoicesAndPaymentState, tryGet('pendingInvoice'));
export const interactingInvoice = createSelector(invoicesAndPaymentState, tryGet('interactingInvoice'));
export const invoiceArea = createSelector(invoicesAndPaymentState, get('invoiceArea'));
export const creatingInvoice = createSelector(invoicesAndPaymentState, get('creatingInvoice'));
export const invoicesMutating = createSelector(invoicesAndPaymentState, get('invoicesMutating'));
export const invoicesFailedToLoad = createSelector(invoicesAndPaymentState, get('invoicesFailedToLoad'));
export const invoicesLoading = createSelector(invoicesAndPaymentState, get('invoicesLoading'));
export const invoiceInfoForSigningLienRelease = createSelector(
  invoicesAndPaymentState,
  tryGet('invoiceInfoForSigningLienRelease'),
);

export const byWorkOrder = (workOrder: WorkOrder) => (item: WorkOrderItem) => item.workOrderId === workOrder?.id;

export const filterByWorkOrder = (workOrder: WorkOrder) => (items: WorkOrderItem[]) =>
  (items ?? []).filter(byWorkOrder(workOrder));

export const byWorkOrderItem = (workOrderItem: WorkOrderItem) => (has: HasWorkOrderItemId) =>
  has.workOrderItemId === workOrderItem?.id;

export const pairInvoiceItemsToWorkOrderItem = (invoiceItems: InvoiceItem[]) => (workOrderItem: WorkOrderItem) => ({
  workOrderItem,
  invoiceItems: invoiceItems.filter(byWorkOrderItem(workOrderItem)),
});

export const pairChangeOrdersToWorkOrderInvoiceItemPair =
  (changeOrders: ChangeOrder[]) =>
  ({ workOrderItem, invoiceItems }: { workOrderItem: WorkOrderItem; invoiceItems: InvoiceItem[] }) => ({
    workOrderItem,
    invoiceItems,
    changeOrders: changeOrders.filter(byWorkOrderItem(workOrderItem)),
  });

export const pairInvoiceItemsToWorkOrderItems = (invoiceItems: InvoiceItem[]) => (workOrderItems: WorkOrderItem[]) =>
  workOrderItems.map(pairInvoiceItemsToWorkOrderItem(invoiceItems));

export const pairChangeOrdersToWorkOrderInvoiceItemPairs =
  (changeOrders: ChangeOrder[]) => (pairs: { workOrderItem: WorkOrderItem; invoiceItems: InvoiceItem[] }[]) =>
    pairs.map(pairChangeOrdersToWorkOrderInvoiceItemPair(changeOrders));

export const sumNumberInvoiced = (num: number, item: { numberOfUnits: number }): number => num + item.numberOfUnits;

export const clamp = (num: number, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER) =>
  num < min ? min : num > max ? max : num;

export const createInvoiceableWorkOrderItems =
  () => (sets: { workOrderItem: WorkOrderItem; invoiceItems: InvoiceItem[]; changeOrders: ChangeOrder[] }[]) =>
    sets.map(
      ({ workOrderItem, invoiceItems, changeOrders }): InvoiceableWorkOrderItem => ({
        ...workOrderItem,
        inapplicableChangeOrders: changeOrders.filter(changeOrder => changeOrder.status !== ChangeOrderStatus.ACCEPTED),
        applicableChangeOrders: changeOrders.filter(changeOrder => changeOrder.status === ChangeOrderStatus.ACCEPTED),
        remaining: clamp(
          workOrderItem.numberOfUnits ??
            0 -
              invoiceItems
                .filter(invoiceItem => invoiceItem.status !== InvoiceStatus.Declined)
                .reduce(sumNumberInvoiced, 0),
          0,
        ),
        capturedUnitsRemaining: workOrderItem.numberOfUnits - workOrderItem.numberOfUnitsAlreadyInvoiced
      }),
    );

export const buildInvoiceableWorkOrderItemsForWorkOrder = (
  workOrderItems: WorkOrderItem[],
  invoiceItems: InvoiceItem[],
  changeOrders: ChangeOrder[],
): InvoiceableWorkOrderItem[] =>
  compose(
    pairInvoiceItemsToWorkOrderItems(invoiceItems),
    pairChangeOrdersToWorkOrderInvoiceItemPairs(changeOrders),
    createInvoiceableWorkOrderItems(),
  )(workOrderItems);

export const awardedByWorkOrder =
  (workOrder: WorkOrder) =>
  (user: User): boolean =>
    user.id === workOrder?.awardedToInstallerId;

export const findInvoicerForWorkOrder = (users: User[], workOrder?: WorkOrder): User | undefined =>
  workOrder ? users.find(awardedByWorkOrder(workOrder)) : undefined;

export const currentStatusGroupAggregate = createSelector(
  currentStatusGroup,
  createStatusGroupCounts,
  (current, statusGroups) => statusGroups?.find(({ status }) => status === current),
);

export const currentWorkOrderInvoiceItems = createSelector(
  allInvoiceItems,
  currentWorkOrder,
  (invoiceItems: InvoiceItem[], workOrder?: WorkOrder): InvoiceItem[] =>
    workOrder ? invoiceItems.filter(whereMatchesWorkOrder(workOrder)) : [],
);

export const currentApprovedWorkOrderItems = createSelector(currentWorkOrderItems, (items: WorkOrderItem[]) =>
  items.filter(item => (item.isChangeOrder && !!item.acceptedById) || !item.isChangeOrder),
);

export const currentInvoiceableWorkOrderItems = createSelector(
  currentApprovedWorkOrderItems,
  currentWorkOrderInvoiceItems,
  currentChangeOrders,
  buildInvoiceableWorkOrderItemsForWorkOrder,
);

export const filterApplicableInvoicableWorkOrderItems = (items: InvoiceableWorkOrderItem[]) =>
  items.filter(
    item =>
      numberOfUnitsStillPending(item) &&
      !item.inapplicableChangeOrders?.some(co => co.status === ChangeOrderStatus.PENDING),
  );

export const applicableInvoiceableWorkOrderItems = createSelector(
  currentInvoiceableWorkOrderItems,
  filterApplicableInvoicableWorkOrderItems,
);

export const currentInvoicer = createSelector(allUsers, currentWorkOrder, findInvoicerForWorkOrder);

export const invoiceForSigningLienRelease = createSelector(
  invoiceInfoForSigningLienRelease,
  invoiceEntities,
  (info, entities) => info && entities[info.id],
);
