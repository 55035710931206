import { HttpClient } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { iif, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { debounceTime, first, map, switchMap, tap } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import {
  addToErrors,
  autoSetFormValue,
  fieldValueMatchesAcceptable,
  mapValidationErrorsFromHttpStatus,
  removeFromErrors,
  setFieldOptions,
} from './util';

export interface ZipValidResponse {
  city: string;
  state: string;
  zipCode: string;
  lat: number;
  lng: number;
  acceptableCityNames: AcceptableCityState[];
}

export interface AcceptableCityState {
  city: string;
  state: string;
}

export const zipCodeCrossMatchedValidation = ([zipMatchesCity, zipMatchesState]: [boolean, boolean]) =>
  zipMatchesCity && zipMatchesState
    ? null
    : zipMatchesCity
    ? { zipMatchesState: true }
    : zipMatchesState
    ? { zipMatchesCity: true }
    : { zipMatchesState: true, zipMatchesCity: true };

export const validateZipCode =
  (operator?: (target: AbstractControl) => OperatorFunction<ZipValidResponse, ValidationErrors | null>) =>
  (http: HttpClient): AsyncValidatorFn =>
  (target: AbstractControl): Observable<ValidationErrors | null> =>
    of(target).pipe(
      map(control => ({
        zipCode: control.value,
        host: ConfigService.getHost(),
      })),
      debounceTime(10),
      switchMap(({ zipCode, host }) =>
        iif(
          () => zipCode?.length === 5,
          http.get<ZipValidResponse>(`${host}/zip-code/${zipCode}`).pipe(
            operator ? operator(target) : tap(),
            mapValidationErrorsFromHttpStatus({
              400: { zipCodeFormat: true },
              404: { knownZipCode: true },
            }),
          ),
          of({ zipCodeFormat: true } as ValidationErrors),
        ),
      ),
      first(),
    );

export const knownValidZipCode = validateZipCode(() => map(() => null));

export const knownCrossValidZipCode =
  (http: HttpClient) =>
  (cityField = 'city', stateField = 'state'): AsyncValidatorFn =>
    validateZipCode(target =>
      pipe(
        tap(response => autoSetFormValue(target.parent?.get(cityField), response.city)),
        tap(response => autoSetFormValue(target.parent?.get(stateField), response.state)),
        map(response => [{ city: response.city, state: response.state }, ...response.acceptableCityNames]),
        tap(acceptable =>
          setFieldOptions(
            target.parent?.get(cityField),
            acceptable.map(a => a.city),
          ),
        ),
        tap(acceptable =>
          setFieldOptions(
            target.parent?.get(stateField),
            acceptable.map(a => a.state),
          ),
        ),
        map((acceptable): [boolean, boolean] => [
          fieldValueMatchesAcceptable(
            target.parent?.get(cityField),
            acceptable.map(_ => _.city),
          ),
          fieldValueMatchesAcceptable(
            target.parent?.get(stateField),
            acceptable.map(_ => _.state),
          ),
        ]),
        tap(([zipMatchesCity]) =>
          zipMatchesCity
            ? removeFromErrors(target.parent?.get(cityField), 'cityMatchesZip')
            : addToErrors(target.parent?.get(cityField), 'cityMatchesZip'),
        ),
        tap(([, zipMatchesState]) =>
          zipMatchesState
            ? removeFromErrors(target.parent?.get(stateField), 'stateMatchesZip')
            : addToErrors(target.parent?.get(cityField), 'stateMatchesZip'),
        ),
        map(zipCodeCrossMatchedValidation),
      ),
    )(http);
