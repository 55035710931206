import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';

export const knownValidPhoneNumber =
  (http: HttpClient) =>
  (): AsyncValidatorFn =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    of(control).pipe(
      map(control => control.value),
      switchMap(phoneNumber =>
        http.get(`${ConfigService.getHost()}/phone-number/${phoneNumber}`).pipe(
          map(() => null),
          catchError((err: HttpErrorResponse) =>
            err.status === 400
              ? of({ phoneNumberFormat: true })
              : err.status === 404
              ? of({ knownPhoneNumber: true })
              : of(null),
          ),
        ),
      ),
    );
