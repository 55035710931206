import { Pipe, PipeTransform } from '@angular/core';
import { DetailedInvoiceItem } from '../../domains/models/detailed-invoice-item';

@Pipe({
  name: 'itemProgressPercentComplete',
})
export class ItemProgressPercentCompletePipe implements PipeTransform {
  transform(item: DetailedInvoiceItem): number {
    return item.workOrderItem
      ? Math.round(
        ((item.numberOfUnits +
            (item.workOrderItem.numberOfUnits - item.capturedUnitsRemaining!)) / item.workOrderItem.numberOfUnits!) * 1000) / 10
      : 0;
  }
}
