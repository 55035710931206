import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { compose, Store } from '@ngrx/store';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { authenticatedUserMetadataRetrieved } from '~gc/domains/app/auth/auth.actions';
import { environment } from '../../../environments/environment';
import { GeoLocationService } from '../../shared/services/geo-location.service';
import { routeEndsInPath } from '../../shared/utils/ngrx';
import { ensureExists } from '../../shared/utils/rxjs';
import { DashboardUIService } from '../dashboard-ui.service';
import { AppState } from '../state';
import { isInstallerLead } from '../users';
import {
  appendEnterOpacityChange,
  appendExitOpacityChange,
  appendShiftDown,
  appendShiftUp,
  createTransition,
} from './animations';
import { geoLocationUpdated, navigateRoot, viewedAnnouncement } from './app.actions';
import { viewedAnnouncementForVersion } from './app.state';
import { claimsAndTokenRetrieved } from './auth/auth-connect.actions';
import { authenticatedUser } from './auth/auth.selectors';

export const fullTransition = createTransition(
  compose(appendEnterOpacityChange, appendShiftUp),
  compose(appendExitOpacityChange, appendShiftDown),
);

export const rootTransition = createTransition(
  compose(appendEnterOpacityChange, appendShiftUp),
  compose(appendExitOpacityChange),
);

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private readonly dashboard: DashboardUIService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly nav: NavController,
    private readonly geoLocation: GeoLocationService,
  ) {}

  navigateRoot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateRoot),
        tap(() => this.nav.setDirection('root', true, 'forward', fullTransition)),
        switchMap(({ url }) => this.router.navigateByUrl(url, { replaceUrl: true })),
      ),
    { dispatch: false },
  );

  showAnnouncementModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeEndsInPath('dashboard'),
      switchMap(() =>
        this.store.select(authenticatedUser).pipe(
          ensureExists(user => !!user),
          filter(user => (isInstallerLead(user) ? user.isApproved ?? true : true)),
          filter(user => environment.announcementConfig.userTypes.includes(user.type)),
          take(1),
        ),
      ),
      withLatestFrom(this.store.select(viewedAnnouncementForVersion)),
      filter(([, version]) => version !== environment.announcementConfig.version),
      switchMap(() => this.dashboard.showAnnouncementModal()),
      map(() => viewedAnnouncement()),
    ),
  );

  findCurrentGeoLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      switchMap(() => this.geoLocation.requestPermission()),
      switchMap(() => this.geoLocation.getCurrentGeolocation()),
      map(position => geoLocationUpdated({ position })),
    ),
  );

  setDataDogUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticatedUserMetadataRetrieved),
        take(1),
        tap(({ user }) =>
          datadogRum.setUser({
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            type: user.type,
          }),
        ),
      ),
    { dispatch: false },
  );
}
