import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Channel } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-angular';
import { get } from '../../../shared/utils/func';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { isInstallerLead, isManager, User } from '../../users';
import { userEntities } from '../../users/user/user.state';
import { WorkOrder } from '../work-order/work-order.model';
import { currentWorkOrder } from '../work-order/work-order.state';
import { chatInitializationFinalized } from './chat-initialization.actions';
import {
  addMemberToChannel,
  workOrderChatActivated,
  workOrderChatSidebarClosed,
  workOrderChatError,
  workOrderChatSidebarOpened, workOrderChatDeactivated,
} from './work-order-chat.actions';
import { resolveUserId } from './work-order-chat.service';


export interface WorkOrderChatState {
  isInitialized: boolean;
  activeUserId: string | '';
  isChatOpen: boolean;
  currentWorkOrder?: WorkOrder;
  currentChannelIds: string[];
  error?: any;
}

export const initialWorkOrderChatState: WorkOrderChatState = {
  isInitialized: false,
  activeUserId: '',
  isChatOpen: false,
  currentChannelIds: [],
};

const reduce = createReducer(
  initialWorkOrderChatState,
  on(
    chatInitializationFinalized,
    (state, { user }): WorkOrderChatState => (
      {
        ...state,
        isInitialized: true,
        activeUserId: resolveUserId(user),
      }
    ),
  ),
  on(workOrderChatActivated, (state, { workOrder }): WorkOrderChatState => (
    { ...state, currentWorkOrder: workOrder }
  )),
  on(
    workOrderChatSidebarOpened,
    (state, { channels }): WorkOrderChatState => (
      { ...state, isChatOpen: true, currentChannelIds: channels ?? [], }
    ),
  ),
  on(workOrderChatSidebarClosed, workOrderChatDeactivated, (state): WorkOrderChatState => (
    { ...state, isChatOpen: false }
  )),
  on(
    workOrderChatError,
    (state, { channels }): WorkOrderChatState => (
      {
        ...state,
        error: true,
        isChatOpen: true,
        currentChannelIds: channels,
      }
    ),
  ),
  on(addMemberToChannel, workOrderChatSidebarOpened, state => (
    { ...state, error: null }
  )),
  on(
    logout,
    (): WorkOrderChatState => (
      { isInitialized: false, activeUserId: '', isChatOpen: false, currentChannelIds: [] }
    ),
  ),
);

export function workOrderChatReducer(state: WorkOrderChatState, action: Action) {
  return reduce(state, action);
}

export const WORK_ORDER_CHAT_FEATURE_KEY = 'workOrderChat' as const;

export const workOrderChatState = createFeatureSelector<WorkOrderChatState>(WORK_ORDER_CHAT_FEATURE_KEY);

export const isInitialized = createSelector(workOrderChatState, get('isInitialized'));
export const activeUserId = createSelector(workOrderChatState, get('activeUserId'));
export const isChatOpen = createSelector(workOrderChatState, get('isChatOpen'));
export const currentWorkOrderChat = createSelector(workOrderChatState, get('currentWorkOrder'));
export const currentChannelIds = createSelector(workOrderChatState, get('currentChannelIds'));
export const hasError = createSelector(workOrderChatState, state => !!state.error);

/*
export const isChatOpen = createSelector(workOrderChatState, get('isChatOpen'))
*/

export interface EnrichedWorkOrderChat {
  channel: Channel<DefaultStreamChatGenerics>;
  users: User[];
}

const getOtherChannelMembers = (
  channel: Channel<DefaultStreamChatGenerics>,
  users: IEntityDictionary<User>,
  user: User,
) =>
  Object.keys(channel.state.members)
    .map(id => 'auth0|' + id)
    .filter(id => id !== user?.id)
    .map(id => users?.[id])
    .filter(val => !!val);

// Possible memoize selector factory by id?
export const enrichedWorkOrderChat = (channel: Channel<DefaultStreamChatGenerics>) =>
  createSelector(userEntities, authenticatedUser, (users, user): EnrichedWorkOrderChat | undefined =>
    user
      ? {
        channel,
        users: isInstallerLead(user)
          ? // Find manager is assigned PM for WorkOrder.
          getOtherChannelMembers(channel, users, user).filter(isManager)
          : getOtherChannelMembers(channel, users, user).filter(isInstallerLead),
      }
      : undefined,
  );

export const unmatchedWorkOrderChat = createSelector(
  currentWorkOrderChat,
  currentWorkOrder,
  (chat, workOrder): WorkOrder | undefined =>
    !!chat?.id && !!workOrder?.id && chat?.id !== workOrder?.id ? workOrder : undefined,
);
