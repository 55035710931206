<ion-list *ngIf="workOrder.currentDetailedWorkOrder$ | push as workOrder">
  <ion-list-header *ngIf="(workOrderFiles.allWorkOrderFiles$ | push)?.length">
    <h3 class="ion-text-uppercase text-smaller">
      <strong>Attached Files</strong>
    </h3>
  </ion-list-header>
  <ng-container *ngIf="!(isPending$ | push); else skeleton">
    <gc-file-manager
      *ngIf="workOrderFiles.allWorkOrderFiles$ | push as files"
      [files]="files"
      [enableUpload]="auth.role$ | push | isRole: 'companyAdmin' : 'companyManager'"
      (fileAdded)="workOrderFiles.upload($event, workOrder)"
      (fileRetried)="workOrderFiles.retry($event, workOrder)"
      (fileDeleted)="workOrderFiles.remove($event, workOrder)"
    ></gc-file-manager>
  </ng-container>
  <ng-template #skeleton>
    <ion-item *ngFor="let i of [1, 2, 3]" lines="full">
      <ion-skeleton-text animated style="height: 2em; width: 2em; margin-right: 1em"></ion-skeleton-text>
      <ion-label>
        <ion-skeleton-text animated style="height: 1.1em; width: 70%"></ion-skeleton-text>
      </ion-label>
    </ion-item>
  </ng-template>
</ion-list>
