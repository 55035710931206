<ng-container *rxLet="productInstallationSubtypesContext$; let subtypeContext">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="dark" (click)="modalGC.dismiss(undefined, 'cancel')" data-cy="close-claim-subtype-modal">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title data-cy="claim-subtype-modal-title">{{ subtypeContext.installType.type }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content [attr.data-cy]="subtypeContext.installType.type + 'modal-list'">
    <gc-item-chooser-list
      [items]="subtypeContext.items"
      noItemsMessage="No additional interests to select for this category."
      (added)="adding($event.value)"
    ></gc-item-chooser-list>
  </ion-content>
  <ion-footer class="p-3">
    <ion-button
      mode="ios"
      color="dark"
      expand="block"
      (click)="modalGC.dismiss(undefined, 'ok')"
      data-cy="subtype-submit-button"
      >Submit
    </ion-button>
  </ion-footer>
</ng-container>
