import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { SelectionIconModule } from '~gc/shared/components/selection-icon/selection-icon.module';
import { PipesModule } from '~gc/shared/pipes/pipes.module';
import { DatepickerItemModule } from '../../shared/components/datepicker-item/datepicker-item.module';
import { FileDropTargetModule } from '../../shared/components/file-drop-target/file-drop-target.module';
import { AddEditSkillsAndExperienceModal } from './add-edit-skills-and-experience-modal/add-edit-skills-and-experience-modal.component';
import { ClaimCategorizedTrainingModalComponent } from './claim-categorized-training-modal/claim-categorized-training-modal.component';
import { ClaimedTrainingsListComponent } from './claimed-trainings-list/claimed-trainings-list.component';
import { ExperienceDocumentationComponent } from './experience-documentation/experience-documentation.component';
import { ForCategoryPipe } from './for-category.pipe';
import { StartDateComponent } from './start-date/start-date.component';

@NgModule({
  declarations: [
    AddEditSkillsAndExperienceModal,
    ClaimCategorizedTrainingModalComponent,
    ExperienceDocumentationComponent,
    StartDateComponent,
    ClaimedTrainingsListComponent,
    ForCategoryPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    ReactiveFormsModule,
    SelectionIconModule,
    DatepickerItemModule,
    PushModule,
    FileDropTargetModule,
  ],
  exports: [AddEditSkillsAndExperienceModal, ClaimedTrainingsListComponent],
})
export class AddEditSkillsAndExperienceModule {}
