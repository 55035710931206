import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { SettlementTiming } from './settlement-timing.entity';

export interface SettlementTimingState extends IEntityState<SettlementTiming> {}

export const {
  actions: {
    loadAll: loadAllSettlementTimings,
    loadAllSuccess: loadAllSettlementTimingsSuccess,
    select: selectSettlementTiming,
  },
  selectors: { selectAll: allSettlementTimings, selectCurrentEntity: currentSettlementTiming },
  facade: SettlementTimingFacadeBase,
  initialState: initialSettlementTimingState,
} = buildState(SettlementTiming);

const reduce = createReducer(initialSettlementTimingState);

export function settlementTimingReducer(state: SettlementTimingState, action: Action) {
  return reduce(state, action);
}

export const SETTLEMENT_TIMING_FEATURE_KEY = 'settlementTiming';
