<ng-container [formGroup]="addressForm">
  <ion-card mode="ios" class="ion-padding" data-cy="installer-editor-address-form">
    <ion-item lines="none" *ngIf="compact" [disabled]="disabled">
      <ion-label position="stacked" [color]="validated ? 'danger' : null">
        <ng-content></ng-content>
      </ion-label>
      <ng-container *ngTemplateOutlet="lineOne"></ng-container>
      <ng-container *ngTemplateOutlet="lineTwo"></ng-container>
      <ng-container *ngTemplateOutlet="zipCode"></ng-container>
      <ng-container *ngTemplateOutlet="city"></ng-container>
      <ng-container *ngTemplateOutlet="state"></ng-container>
    </ion-item>

    <!--  If compact -> Then essential wrap inputs in items -->
    <ion-item lines="full" *ngIf="!compact" [disabled]="disabled">
      <!--    <ion-label position="floating" [color]="validated && addressForm.addressLine1.invalid ? 'danger' : undefined">-->
      <!--      Address Line 1-->
      <!--    </ion-label>-->
      <ng-container *ngTemplateOutlet="lineOne"></ng-container>
    </ion-item>
    <ion-item lines="full" *ngIf="!compact" [disabled]="disabled">
      <!--    <ion-label position="floating" [color]="validated && addressForm.addressLine2.invalid ? 'danger' : undefined">-->
      <!--      Address Line 2-->
      <!--    </ion-label>-->
      <ng-container *ngTemplateOutlet="lineTwo"></ng-container>
    </ion-item>
    <ion-item lines="full" *ngIf="!compact" [disabled]="disabled">
      <!--    <ion-label position="floating" [color]="validated && addressForm.postalCode.invalid ? 'danger' : undefined">-->
      <!--      Zip Code-->
      <!--    </ion-label>-->
      <ng-container *ngTemplateOutlet="zipCode"></ng-container>
    </ion-item>
    <ion-item lines="full" *ngIf="!compact" [disabled]="disabled">
      <!--    <ion-label position="floating" [color]="validated && addressForm.city.invalid ? 'danger' : undefined">-->
      <!--      City-->
      <!--    </ion-label>-->
      <ng-container *ngTemplateOutlet="city"></ng-container>
    </ion-item>
    <ion-item lines="full" *ngIf="!compact" [disabled]="disabled">
      <!--    <ion-label position="floating" [color]="validated && addressForm.stateCode.invalid ? 'danger' : undefined">-->
      <!--      State-->
      <!--    </ion-label>-->
      <ng-container *ngTemplateOutlet="state"></ng-container>
    </ion-item>

    <!--  Actual Form Control Inputs-->
    <ng-template #lineOne>
      <ion-input
        data-e2e="basic-company-name-input-address-line-one"
        [placeholder]="compact ? 'Address Line One' : ''"
        mode="md"
        fill="solid"
        label="Address Line 1"
        labelPlacement="floating"
        formControlName="addressLine1"
        clearInput
        data-cy="installer-editor-address-line-one-input"
      ></ion-input>
    </ng-template>

    <ng-template #lineTwo>
      <ion-input
        data-e2e="basic-company-name-input"
        [placeholder]="compact ? 'Address Line Two' : ''"
        mode="md"
        fill="solid"
        label="Address Line 2"
        labelPlacement="floating"
        formControlName="addressLine2"
        clearInput
        data-cy="installer-editor-address-line-two-input"
      ></ion-input>
    </ng-template>

    <ng-template #zipCode>
      <ion-input
        data-e2e="basic-zip-code-input"
        mode="md"
        fill="solid"
        label="Zip Code"
        labelPlacement="floating"
        formControlName="postalCode"
        inputmode="numeric"
        maxlength="5"
        [placeholder]="compact ? 'Zip Code' : ''"
        clearInput
        data-cy="installer-editor-zip-code-input"
      >
      </ion-input>
    </ng-template>

    <ng-template #city>
      <ion-input
        *ngIf="addressForm.cityOptions.length <= 0"
        [placeholder]="compact ? 'City' : ''"
        mode="md"
        fill="solid"
        label="City"
        labelPlacement="floating"
        data-e2e="basic-company-name-input"
        formControlName="city"
        clearInput
        data-cy="installer-editor-city-input-no-options"
      ></ion-input>
      <ion-select
        *ngIf="addressForm.cityOptions.length > 0"
        fill="solid"
        mode="md"
        label="City"
        label-placement="stacked"
        formControlName="city"
        data-cy="installer-editor-city-select"
      >
        <ion-select-option *ngFor="let city of addressForm.cityOptions" [value]="city">
          {{ city }}
        </ion-select-option>
      </ion-select>
    </ng-template>

    <ng-template #state>
      <ion-select
        *ngIf="states?.length"
        data-e2e="basic-company-name-input"
        [placeholder]="compact ? 'State' : ''"
        mode="md"
        fill="solid"
        label="Select State"
        labelPlacement="floating"
        formControlName="stateCode"
        data-cy="installer-editor-state-select"
      >
        <ion-select-option *ngFor="let state of states | matchingStates: addressForm.stateCode" [value]="state.code">
          {{ state.stateOrProvince }}
        </ion-select-option>
      </ion-select>
      <ion-input *ngIf="!states?.length" [value]="addressForm.updatedModel?.stateOrProvince" label="State"></ion-input>
    </ng-template>
  </ion-card>
</ng-container>
