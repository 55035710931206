import { Action, createReducer, on } from '@ngrx/store';
import { clearFlag, setFlag } from '../../core/invites/store/invites.state';
import { invoiceModalClosed, viewInvoice, workOrderInvoiceModalClosed } from '../invoices/invoice-and-payment.actions';
import { negotiationsClosed, negotiationsViewed } from '../negotiations/negotiations.actions';
import { applyReductions, Reduction } from './apply-reductions';
import {
  installerReviewCompleted,
  installersReviewCompleted,
  reviewInstaller,
  reviewInstallers,
} from './installer-review/installer-review.actions';

export interface WorkPollingState {
  paused: boolean;
  depth: number;
}

export const initialState: WorkPollingState = {
  paused: false,
  depth: 0,
};

export const incrementProp =
  <TState>(prop: keyof TState) =>
  (state: TState): TState => ({
    ...state,
    [prop]: (state[prop] as unknown as number) + 1,
  });

export const decrementProp =
  <TState>(prop: keyof TState) =>
  (state: TState): TState => ({
    ...state,
    [prop]: (state[prop] as unknown as number) - 1,
  });

export const whenCountIs =
  <TState>(value: number, prop: keyof TState, reduction: Reduction<TState>) =>
  (state: TState, action: Action): TState =>
    state[prop] === value ? reduction(state, action) : state;

export const workPollingReducer = createReducer(
  initialState,
  on(
    viewInvoice,
    reviewInstallers,
    reviewInstaller,
    negotiationsViewed,
    applyReductions(incrementProp('depth'), setFlag('paused')),
  ),
  on(
    installerReviewCompleted,
    installersReviewCompleted,
    invoiceModalClosed,
    workOrderInvoiceModalClosed,
    negotiationsClosed,
    applyReductions(decrementProp('depth'), whenCountIs(0, 'depth', clearFlag('paused'))),
  ),
);
