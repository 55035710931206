import { Component, Input } from '@angular/core';
import { faCloudArrowDown } from '@fortawesome/pro-duotone-svg-icons';

import { ComplianceRecord } from '../../../../core/installer-profile';

@Component({
  selector: 'gc-compliance-area',
  templateUrl: './compliance-area.component.html',
  styleUrls: ['./compliance-area.component.scss'],
})
export class ComplianceAreaComponent {
  @Input() complianceRecords!: ComplianceRecord[];

  protected readonly faCloudArrowDown = faCloudArrowDown;
}
