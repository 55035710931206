import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { HasTargetFiles, HasTargetValue } from '../../utils/types';

@Component({
  selector: 'gc-file-drop-target',
  templateUrl: './file-drop-target.component.html',
  styleUrls: ['./file-drop-target.component.scss'],
})
export class FileDropTargetComponent {

  @Input() instructionsLabel = 'Drag and drop files here or';
  @Input() buttonLabel = 'Browse Files'
  @Input() enableUpload = true;
  @Output() filesDropped = new EventEmitter<FileList>();
  @Output() filesAdded = new EventEmitter<Event & HasTargetFiles & HasTargetValue>();

  @ViewChild('fileInput') fileInput!: IonInput;

  async chooseFiles(): Promise<void> {
    const input = await this.fileInput.getInputElement();
    input.click();
  }
}
