import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { allConnections } from '~gc/domains/users/connection/connection.state';
import { Connection } from '../connection/connection.model';
import { isInstallerLead, Jumpstarter, User } from '../user/user.model';
import { jumpstartersNotOnTeamFavoriteStatus } from '../user/user.selectors';
import { userEntities } from '../user/user.state';
import {
  addUserSuccess,
  cancelInviteUser,
  inviteEmailUpdated,
  sendUserInviteSuccess,
  stateFilterUpdated,
  userFoundByEmail,
  userNotFoundByEmail,
} from './team.actions';

export interface TeamLoadingFlags {
  emailLookup: boolean;
}

export interface TeamState {
  foundUser?: User;
  emailLookup?: string;
  loadingFlags: TeamLoadingFlags;
  stateFilter: string[];
}

export const initialTeamState: TeamState = {
  loadingFlags: {
    emailLookup: false,
  },
  stateFilter: [],
};

const reduce = createReducer(
  initialTeamState,
  on(inviteEmailUpdated, (state, { email }) => ({ ...state, emailLookup: email })),
  on(userFoundByEmail, (state, { user }) => ({
    ...state,
    foundUser: user,
    loadingFlags: { ...state.loadingFlags, emailLookup: false },
  })),
  on(userNotFoundByEmail, state => ({
    ...state,
    foundUser: undefined,
    loadingFlags: { ...state.loadingFlags, emailLookup: false },
  })),
  on(inviteEmailUpdated, state => ({ ...state, loadingFlags: { ...state.loadingFlags, emailLookup: true } })),
  on(cancelInviteUser, addUserSuccess, sendUserInviteSuccess, state => ({
    ...state,
    foundUser: undefined,
    emailLookup: undefined,
  })),
  on(stateFilterUpdated, (state, { stateFilter }) => ({ ...state, stateFilter })),
);

export function teamReducer(state: TeamState = initialTeamState, action: Action) {
  return reduce(state, action);
}

export const teamState = createFeatureSelector<TeamState>('team');

export const foundUser = createSelector(teamState, state => state.foundUser);
export const emailLookup = createSelector(teamState, state => state.emailLookup);
export const stateFilters = createSelector(teamState, state => state.stateFilter);

export const foundUserMatchingEmail = createSelector(foundUser, emailLookup, (user, email) =>
  user && user?.email === email ? user : null,
);

export const teamLoadingFlags = createSelector(teamState, state => state.loadingFlags);

export const emailLookupLoading = createSelector(teamLoadingFlags, flags => flags.emailLookup);

export const userIsAlreadyConnected = createSelector(
  userEntities,
  allConnections,
  foundUser,
  (users: IEntityDictionary<User>, networks: Connection[], user?: User): boolean =>
    !!user?.id
      ? isInstallerLead(user)
        ? networks.some(network => network.installerTeamLeadId === user.id)
        : !!users[user.id]
      : false,
);

export const jumpstartersFilteredByState = createSelector(
  jumpstartersNotOnTeamFavoriteStatus,
  stateFilters,
  (jumpstarters: Jumpstarter[], states: string[]): Jumpstarter[] =>
    states.length
      ? jumpstarters.filter(({ addresses }) => addresses?.some(address => states.includes(address.stateCode)))
      : jumpstarters,
);
