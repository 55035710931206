import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import {
  ChangeOrderDeductModalComponent
} from '../../modals/change-order-modify/change-order-deduct-modal/change-order-deduct-modal.component';
import {
  ChangeOrderItemAddModalComponent
} from '../../modals/change-order-modify/change-order-item-add-modal/change-order-item-add-modal.component';
import { ConfirmDeclineModalComponent } from '../../modals/confirm-decline-modal/confirm-decline-modal.component';
import { DetailedWorkOrderItem } from '../models/detailed-work-order-item';
import { UnitOfMeasure } from '../unit-of-measure/unit-of-measure.model';
import { WorkOrderItem } from '../work/work-order-item/work-order-item.model';
import { WorkOrder } from '../work/work-order/work-order.model';
import { ChangeOrder } from './change-order/change-order.model';


@Injectable()
export class ChangeOrdersUiService {
  constructor(
    private readonly alerts: AlertController,
    private readonly modals: ModalController,
  ) {
  }

  async alertMissingPhoneNumber(who: string): Promise<string | undefined> {
    const alert = await this.alerts.create({
      message: `Phone number for ${ who } could not be found.`,
      header: 'Missing Phone Number',
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async alertMissingProjectManager(): Promise<string | undefined> {
    const alert = await this.alerts.create({
      message: `Project Manager could not be found.`,
      header: 'Missing Project Manager',
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async alertAlreadyAccepted(): Promise<string | undefined> {
    const alert = await this.alerts.create({
      message: `This change order has already been accepted! It can no longer be voided.`,
      header: 'Already Accepted',
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async confirmAcceptance({ message, header }: { message?: string; header?: string }): Promise<string | undefined> {
    const alert = await this.alerts.create({
      message: message ?? 'Are you sure you want to accept? This cannot be undone.',
      header: header ?? 'Accept',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Accept',
          role: 'ok',
          cssClass: 'ion-color-gc-green',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async confirmDecline(item: DetailedWorkOrderItem): Promise<OverlayEventDetail<{ reason: string, item: DetailedWorkOrderItem }>> {
    const alert = await this.modals.create({
      component: ConfirmDeclineModalComponent,
      componentProps: {
        item,
      },
      cssClass: 'rounded-modal',
    });

    await alert.present();
    return alert.onDidDismiss();
  }

  async confirmVoid({ message, header }: { message?: string; header?: string }): Promise<string | undefined> {
    const alert = await this.alerts.create({
      message: message ?? 'Are you sure you want to void? This cannot be undone.',
      header: header ?? 'Void',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Void',
          role: 'ok',
          cssClass: 'button-text-red',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async showChangeOrderDeductModal(
    item: DetailedWorkOrderItem,
  ): Promise<{ role?: string; data: ChangeOrder }> {
    const modal = await this.modals.create({
      component: ChangeOrderDeductModalComponent,
      cssClass: 'rounded-modal-tall',
      componentProps: {
        item,
      },
    });
    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return { role, data: data as ChangeOrder };
  }

  async showChangeOrderItemAddModal(
    workOrder: WorkOrder,
    unitsOfMeasure: UnitOfMeasure[],
  ): Promise<{ role?: string; data: WorkOrderItem }> {
    const modal = await this.modals.create({
      component: ChangeOrderItemAddModalComponent,
      cssClass: 'rounded-modal',
      componentProps: {
        workOrder,
        unitsOfMeasure,
      },
    });
    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return { role, data: data as WorkOrderItem };
  }
}
