import { createSelector } from '@ngrx/store';
import { get, keysToListFromMap } from '../../shared/utils/func';
import { asReadonly } from '../../shared/utils/ngrx/as-readonly.projector';
import { featureState } from './feature.name';

export const httpTrafficState = createSelector(featureState, get('traffic'));

export const httpResponseMetadata = createSelector(httpTrafficState, get('metadata'));
export const httpResponseUrls = createSelector(httpTrafficState, get('urls'));


export const httpResponseMetadataList = createSelector(
  httpResponseUrls,
  httpResponseMetadata,
  asReadonly(keysToListFromMap),
);
