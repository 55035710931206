import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  DetailedInstallerProfileContext,
  DetailedProductInstallationTypeClaimed,
} from '../../../../core/installer-profile';
import { Category } from '../../../../domains/training/category/category.model';
import { ProductInstallationSubtypesClaimed } from '../../../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import { ProductInstallationTypeClaimed } from '../../../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { ProductInstallationType } from '../../../../domains/training/product-installation-types/product-installation-types.model';
import { TrainingClaimed } from '../../../../domains/training/trainings-claimed/trainings-claimed.model';
import { Installer } from '../../../../domains/users';
import { InstallerProfileAreas } from '../../store/ui.state';

@Component({
  selector: 'gc-main-content-area',
  templateUrl: './main-content-area.component.html',
  styleUrls: ['./main-content-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContentAreaComponent {
  @Input() context!: DetailedInstallerProfileContext;
  @Input() selectedArea?: InstallerProfileAreas;
  @Input() installer!: Installer;

  @Output() educationAreaSelected = new EventEmitter<DetailedProductInstallationTypeClaimed>();
  @Output() installTypeClaimAdded = new EventEmitter();
  @Output() installTypeClaimRemoved = new EventEmitter<ProductInstallationTypeClaimed>();
  @Output() trainingClaimAdded = new EventEmitter<Category>();
  @Output() trainingClaimRemoved = new EventEmitter<TrainingClaimed>();
  @Output() subtypeAddedFromOverview = new EventEmitter<ProductInstallationType>();
  @Output() subtypeAddedFromEducationAndTraining = new EventEmitter<ProductInstallationType>();
  @Output() subtypeRemoved = new EventEmitter<ProductInstallationSubtypesClaimed>();

  constructor(public readonly store: Store) {}
}
