<div class="profile-content-section" data-cy="subtypes-area">
  <div data-cy="subtypes-title">
    <h2>My Favorite Flooring to Install</h2>
  </div>
  <ng-container *ngIf="productInstallationSubtypes; else noSubtypes">
    <div class="flex flex-column" *ngFor="let subtype of productInstallationSubtypes">
      <ng-container *ngIf="subtype.subtypesClaimed?.length > 0; else emptySubtypeArea">
        <h5 [attr.data-cy]="subtype.installTypeClaimed.type + 'subtype-subtitle'">
          {{ subtype.installTypeClaimed.type }}
        </h5>
        <gc-installer-subtypes-badge-list
          [subtypes]="subtype.subtypesClaimed"
          [showRemove]="false"
          (remove)="subtypeRemoved.emit($event)"
        ></gc-installer-subtypes-badge-list>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #emptySubtypeArea></ng-template>

  <ng-template #noSubtypes>
    <ng-container *ngIf="!canEditProfile; else installerView">
      <div class="empty-message-sub-section">
        <div class="alert alert__warning text-center mt-0" data-cy="empty-subtype-area-company">
          No Favorite Flooring to Install found.
        </div>
      </div>
    </ng-container>
    <ng-template #installerView>
      <div class="empty-message-sub-section">
        <div class="alert alert__warning text-center mt-0" data-cy="empty-subtype-area-installer">
          You have not added any Favorite Flooring to Install yet.<br />
          Go to your <strong>Training</strong> tab to get started.
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
