import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '~gc/domains/state';
import { LienReleaseTemplate } from './lien-release-template.model';
import { currentWorkOrderLienReleaseTemplate } from './lien-release-template.selectors';
import { LienReleaseTemplateFacadeBase } from './lien-release-template.state';

@Injectable()
export class LienReleaseTemplateFacade extends LienReleaseTemplateFacadeBase {
  currentWorkOrderLienReleaseTemplate$ = this.store.select(currentWorkOrderLienReleaseTemplate);

  constructor(private store: Store<AppState>) {
    super(LienReleaseTemplate, store);
  }
}
