<ion-list
  lines="full"
  class="ion-padding-horizontal"
  *ngIf="formArray.nonNew.length >= 1"
  xmlns="http://www.w3.org/1999/html"
>
  <ion-list-header *ngIf="showHeader" lines="full" class="ion-no-padding">
    <h2><b>Schedule</b></h2>
  </ion-list-header>
  <ion-item
    *ngFor="let dateForm of formArray.nonNew; index as index; trackBy: trackDatesBy"
    lines="none"
    detail="false"
    class="ion-no-padding date-entry"
    color="clear"
  >
    <div *ngIf="!dateForm.isEditing" class="date-entry-container">
      <div class="date-entry-wrapper">
        <div class="date-text ion-padding-vertical ion-padding-start">
          {{ dateForm.startDate.value | date: 'longDate' }} - {{ dateForm.endDate.value | date: 'longDate' }}
        </div>
        <ion-buttons *ngIf="enableCommands" class="ion-justify-content-end">
          <ion-button *ngIf="showModificationButtons$ | push" (click)="dateForm.edit()" fill="clear" color="dark">
            <ion-icon size="small" name="create" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-button *ngIf="showModificationButtons$ | push" (click)="deleted.emit(dateForm.updatedModel)" fill="clear" color="dark">
            <ion-icon
              [attr.data-cy]="'schedule-list-delete-button-' + dateForm.startDate.value"
              size="small"
              name="trash"
              slot="icon-only"
              [color]="formArray.nonNew.length > 1 ? 'danger' : 'medium'"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
      </div>
    </div>

    <!-- We use mat-card because ion-card misshapes gc-start-end-date's internal components: -->
    <mat-card class="date-picker-wrapper" *ngIf="dateForm.isEditing">
      <gc-start-end-date
        [showCtaButtons]="enableCommands"
        [form]="dateForm"
        (canceled)="dateForm.cancelEdit()"
        (saved)="save(formArray, $event, dateForm)"
      ></gc-start-end-date>
    </mat-card>
  </ion-item>
</ion-list>

<!-- We use mat-card because ion-card misshapes gc-start-end-date's internal components: -->
<mat-card class="date-picker-wrapper" *ngIf="formArray.hasNew">
  <gc-start-end-date
    [showCtaButtons]="true"
    [form]="formArray?.newlyAdded"
    (canceled)="formArray.newlyAdded?.cancelEdit()"
    (saved)="save(formArray, $event)"
  ></gc-start-end-date>
</mat-card>

<ion-button
  *ngIf="enableCommands && enableAdd && !formArray.isEditing && (showModificationButtons$ | async)"
  fill="solid"
  color="dark"
  class="ion-margin"
  mode="ios"
  (click)="formArray.addNew()"
  >Add Dates
  <ion-icon name="add-outline" slot="start"></ion-icon>
</ion-button>
