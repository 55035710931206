import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { InstallerTeamLead } from '../../users';

@Entity('DiscountCode', { uriName: 'discount-codes' })
export class DiscountCode {
  @Key id?: number;
  code!: string;
  name!: string;
  expirationDate!: string;
  installerTeamLeadId!: string;
  installerTeamLead?: InstallerTeamLead;
}
