import { Pipe, PipeTransform } from '@angular/core';

export const invoiceFee = (amount: number, percentage = 0): number => amount * (percentage ?? 0) * 0.01;

@Pipe({ name: 'expectedSettlementAmount' })
export class ExpectedSettlementAmountPipe implements PipeTransform {
  transform(invoiceAmount?: number, percentage?: number): number {
    return invoiceAmount ? invoiceAmount - invoiceFee(invoiceAmount, percentage) : 0;
  }
}
