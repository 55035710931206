import { createAction, props } from '@ngrx/store';
import { Training } from '../../domains/training/training/training.model';

export const trainingClaiming = createAction(
  '[Claim Training Modal] (Claiming) Training',
  props<{ training: Training }>(),
);

export const claimTrainingCancelled = createAction('[Claim Training Modal] (Claiming) Cancelled');

export const claimDateChosen = createAction('[Claim Training Modal] (Claiming) Date Chosen', props<{ date: string }>());
