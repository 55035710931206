import { createAction, props } from '@ngrx/store';
import { IStatusGroup } from '../models/status-group';
import { DetailArea, SelectedArea } from './work.state';

// -----===[ ACTIONS ]===-----
// Actions are the messages of NgRx driven applications. They carry
// the intent of things that need to be done. They represent commands,
// events, representing what's happening in the app, with any relevant
// companion data (props).

export const workPageInitialize = createAction('[Work Page] Initialize');

export const workOrdersRefresh = createAction('[Work] Work Orders Refresh');

export const workOrdersRouteVisit = createAction('[Work] Work Orders Route Visit');

export const selectWorkArea = createAction('[Work] Select Work Area', props<{ area: SelectedArea }>());

export const selectDetailArea = createAction('[Work] Select detail area', props<{ area: DetailArea }>());

export const viewOrdersByStatus = createAction('[Work] View Work Orders by Status', props<{ status: IStatusGroup }>());

export const exitStatusGroup = createAction('[Work] Exit Status Group');

export const searchWorkOrders = createAction('[Work] Search work orders', props<{ criteria: string }>());

export const viewSearchWorkOrders = createAction('[Work] View Search Page');

export const clearWorkOrderSearchCriteria = createAction('[Work] Clear work order search criteria');

export const viewWorkOrders = createAction('[Work] View Work Orders');

export const viewPendingWorkOrders = createAction('[Work] View Pending Work Orders');
