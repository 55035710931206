import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'NegotiationItem',
  uriName: 'negotiations',
})
export class NegotiationItem {
  @Key id?: number;
  workOrderId!: string;
  installerId!: string;
  workOrderItemId!: string;
  currentAskPrice!: number;
  currentAskPriceLastUpdated?: string;
  currentBidPrice?: number;
  currentBidPriceLastUpdated?: string;
  finalNegotiatedPrice?: number;
  isAcceptedByInstaller!: boolean;
}
