import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '~gc/domains/users/user/user.model';
import { ConfigService } from '../../../shared/services/config.service';
import { StorageRefService } from '../../../shared/services/storage-ref.service';

@Injectable({ providedIn: 'root' })
export class AuthUserService {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
    private readonly storageRef: StorageRefService,
  ) {}

  getUserById(id: string): Observable<any> {
    return this.http.get<User>(`${this.config.host}/users/${id}`);
  }

  async getSub(): Promise<string> {
    const storage = await this.storageRef.storage();
    const sub = await storage.get('gc_sub');
    return sub ?? '';
  }

  async setSub(sub: string): Promise<void> {
    const storage = await this.storageRef.storage();
    return storage.set('gc_sub', sub);
  }
}
