import { Component } from '@angular/core';
import { AuthFacade } from '../../domains/app/auth/auth.facade';
import { LoginFacade } from '../../domains/app/login.facade';

@Component({
  selector: 'gc-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  constructor(
    public login: LoginFacade,
    public auth: AuthFacade,
  ) {}
}
