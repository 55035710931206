<ion-grid>
  <ion-row>
    <ion-col class="ion-no-padding">
      <ion-badge [color]="invoice | invoiceStatusColor" mode="ios">
        {{ invoice?.status || 'NEW' | invoiceStatusLabel: reportsProgress }}
      </ion-badge>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col>
      <ion-text>
        {{ invoice?.createdOn || date | date: 'MMMM d, y' }}
      </ion-text>
      <h1 class="ion-no-margin" style="margin-bottom: 6px">
        <strong>{{ workOrder?.privateJobName }}</strong>
      </h1>
      <small>
        Project Manager: {{ workOrder?.projectManager?.firstName }} {{ workOrder?.projectManager?.lastName }},
        {{ company?.name }}
      </small>
      <small *ngIf="currentRole | isRole: 'companyAdmin' : 'companyManager'">
        Installer: {{ installer | fullName }}
      </small>
      <small *ngIf="invoice?.id" class="ion-no-margin">
        {{ invoiceNumberLabel }} {{ workOrder?.id || '' | slice: 0 : 6 }}-{{ invoice?.id + '' | slice: 0 : 6 }}
      </small>
    </ion-col>
  </ion-row>
</ion-grid>
