import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { addDays, formatISO, set } from 'date-fns';
import { from } from 'rxjs';
import { debounceTime, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { routeIncludesPath } from '../../../shared/utils/ngrx';
import { selectWorkOrderById } from '../../work/work-order/work-order.state';
import { workOrderCustomDatesIntervalTicked } from '../custom-date-polling.actions';
import {
  customDateCreationFailed,
  customDateDeletionFailed,
  customDateUpdateFailed, loadAllCustomDates,
  loadManyCustomDates,
} from './custom-date.store';

export const today = (date: Date | number) => set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

@Injectable()
export class CustomDateEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly toasts: ToastController,
  ) {}

  loadCustomDatesByRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeIncludesPath('/app/work/custom-calendar'),
      debounceTime(25),
      map(
        ({ payload: { routerState } }) => routerState.root?.firstChild?.firstChild?.firstChild?.firstChild?.queryParams,
      ),
      map(params => [
        params?.['startDate'] ? Number(params['startDate']) : today(Date.now()),
        params?.['endDate'] ? Number(params['endDate']) : addDays(today(Date.now()), 1),
      ]),
      map(([startDate, endDate]) =>
        loadManyCustomDates({
          criteria: {
            query: {
              startDate: formatISO(startDate),
              endDate: formatISO(endDate),
            },
          },
        }),
      ),
    ),
  );

  // TODO: Replace this with a route-based id check when possible
  loadCustomDatesByWorkOrderSelection$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(selectWorkOrderById, workOrderCustomDatesIntervalTicked),
      debounceTime(25),
      map(({ entityKey }) =>
        loadManyCustomDates({
          criteria: {
            query: {
              workOrderId: entityKey,
            },
          },
        }),
      ),
    ),
  );

  dateActionFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(customDateCreationFailed, customDateUpdateFailed, customDateDeletionFailed),
        tap(({ error }) => console.error(error)),
        map(({ actionType }) =>
          actionType === '[Entity] (Generic) Update: Failure' || actionType === '[Entity] (Generic) Replace: Failure'
            ? 'save'
            : actionType === '[Entity] (Generic) Create: Failure'
            ? 'add'
            : 'delete',
        ),
        exhaustMap(type =>
          from(
            this.toasts.create({
              duration: 5000,
              message: `Failed to ${type} scheduled dates.`,
              color: 'danger',
              position: 'bottom',
              buttons: [{ icon: 'close' }],
            }),
          ).pipe(switchMap(toast => from(toast.present()).pipe(switchMap(() => toast.onDidDismiss())))),
        ),
      ),
    { dispatch: false },
  );
}
