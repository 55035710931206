import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { loadUser } from '../../domains/users/user/user.state';
import {
  internalInviteProcessCompleting,
  newEmployeeInstallerInvited,
  newEmployeeInstallerInvitedSuccessfully,
  newEmployeeInstallerInviteFailed,
  newProjectManagerInvited,
  newProjectManagerInvitedSuccessfully,
  newProjectManagerInviteFailed,
} from './company-internal-invites.actions';
import { InvitesUIService } from './invites-ui.service';
import { inviteCompanyEmployeeInstaller, inviteCompanyProjectManager, inviteProcessInitiated } from './invites.actions';
import { InvitesService } from './invites.service';

@Injectable()
export class CompanyInternalInvitesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly ui: InvitesUIService,
    private readonly invites: InvitesService,
  ) {}

  showCompanyInviteModalForEmployeeInstallers$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(inviteCompanyEmployeeInstaller),
        exhaustMap(() =>
          this.ui
            .showCompanyInviteModal('EMPLOYEE_INSTALLER', 'Employee Installer')
            .pipe(
              switchMap(modal =>
                of().pipe(
                  takeUntil(from(modal.onDidDismiss()).pipe(tap(() => console.log('Invite modal dismissed...')))),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );

  waitForEmployeeInstallerInviteCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteCompanyEmployeeInstaller),
      // eslint-disable-next-line @ngrx/avoid-cyclic-effects
      switchMap(() => this.actions$.pipe(ofType(inviteProcessInitiated), first())),
      map(({ invite }) => newEmployeeInstallerInvited({ invite })),
    ),
  );

  showCompanyInviteModalForPrivateConnection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(inviteCompanyProjectManager),
        exhaustMap(() =>
          this.ui
            .showCompanyInviteModal('COMPANY_PROJECT_MANAGER', 'Project Manager')
            .pipe(
              switchMap(modal =>
                of().pipe(
                  takeUntil(from(modal.onDidDismiss()).pipe(tap(() => console.log('Invite modal dismissed...')))),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );

  waitFoProjectManagerInviteCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteCompanyProjectManager),
      // eslint-disable-next-line @ngrx/avoid-cyclic-effects
      switchMap(() => this.actions$.pipe(ofType(inviteProcessInitiated), first())),
      map(({ invite }) => newProjectManagerInvited({ invite })),
    ),
  );

  inviteNewEmployeeInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newEmployeeInstallerInvited),
      switchMap(({ invite }) =>
        this.invites
          .addNewUser({
            ...invite,
            role: 'EMPLOYEE_INSTALLER',
          })
          .pipe(
            map(({ userId }) => newEmployeeInstallerInvitedSuccessfully({ userId })),
            catchError(error => of(newEmployeeInstallerInviteFailed({ error }))),
          ),
      ),
    ),
  );

  inviteNewProjectManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newProjectManagerInvited),
      switchMap(({ invite }) =>
        this.invites
          .addNewUser({
            ...invite,
            role: 'COMPANY_PROJECT_MANAGER',
          })
          .pipe(
            map(({ userId }) => newProjectManagerInvitedSuccessfully({ userId })),
            catchError(error => of(newProjectManagerInviteFailed({ error }))),
          ),
      ),
    ),
  );

  loadNewEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newEmployeeInstallerInvitedSuccessfully, newProjectManagerInvitedSuccessfully),
      map(({ userId }) => loadUser({ keys: userId })),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(action => [internalInviteProcessCompleting({ correlationId: action.correlationId }), action]),
    ),
  );
}
