import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { isRole, RoleKeys } from '~gc/domains/app/auth/auth.maps';
import { InvoiceItem } from '~gc/domains/invoices/invoice-item/invoice-item.model';
import { TotalledInvoice } from '~gc/domains/invoices/models/totalled-invoice';
import { DetailedInvoiceItem } from '~gc/domains/models/detailed-invoice-item';
import { WorkOrderItem } from '~gc/domains/work/work-order-item/work-order-item.model';
import { MayHaveId } from '../shared/utils/types';
import { authenticatedRole } from './app/auth/auth.selectors';
import { ChangeOrder } from './change-orders/change-order/change-order.model';
import { changeOrderEntities } from './change-orders/change-order/change-order.state';
import { INVOICE_STATUS_GROUP_MAP, INVOICE_STATUS_GROUP_ORDER_MAP, InvoiceStatus } from './invoices';
import { currentInvoiceItems } from './invoices/invoice-item/invoice-item.selectors';
import { allInvoiceItems } from './invoices/invoice-item/invoice-item.state';
import { Invoice } from './invoices/invoice/invoice.model';
import { flaggedInvoices } from './invoices/invoices.selectors';
import { sortedWorkOrderItems, workOrderItemEntities } from './work/work-order-item/work-order-item.state';
import { WorkOrder } from './work/work-order/work-order.model';
import { detailedWorkOrder } from './work/work-order/work-order.selectors';
import { currentWorkOrder } from './work/work-order/work-order.state';

export const forWorkOrder =
  <T extends { workOrderId: string }>(workOrder: WorkOrder) =>
  (hasWorkOrder: T): boolean =>
    hasWorkOrder.workOrderId === workOrder?.id;

export const filterInvoicesForWorkOrder = (invoices: Invoice[], workOrder?: WorkOrder): Invoice[] | undefined =>
  workOrder ? invoices.filter(forWorkOrder(workOrder)) : undefined;

export const currentWorkOrderInvoices = createSelector(flaggedInvoices, currentWorkOrder, filterInvoicesForWorkOrder);

export const currentWorkOrderDetailedInvoices = createSelector(
  currentWorkOrderInvoices,
  detailedWorkOrder,
  (invoices, workOrder) => invoices?.map(invoice => ({ ...invoice, workOrder })),
);

export const forInvoice =
  <T extends { invoiceId: number }>(invoice: Invoice) =>
  (hasInvoice: T): boolean =>
    hasInvoice.invoiceId === invoice?.id;

export const forInvoiceItemByWorkOrderItem = (invoiceItem: InvoiceItem) => (has: MayHaveId) =>
  has.id === invoiceItem?.workOrderItemId;

export const joinInvoiceItemAndWorkOrderItem =
  (workOrderItems: WorkOrderItem[] = []) =>
  (invoiceItem: InvoiceItem) => ({
    invoiceItem,
    workOrderItem: workOrderItems.find(forInvoiceItemByWorkOrderItem(invoiceItem)),
  });

export const sumInvoiceTotal = (
  total: number,
  { invoiceItem, workOrderItem }: { invoiceItem: InvoiceItem; workOrderItem?: WorkOrderItem },
) => total + invoiceItem.numberOfUnits * (workOrderItem?.pricePerUnit ?? 0);

export const sumTotalsForInvoice =
  (invoiceItems: InvoiceItem[] = [], workOrderItems: WorkOrderItem[] = []) =>
  (invoice: Invoice): TotalledInvoice => ({
    ...invoice,
    totalAmount: invoiceItems
      .filter(forInvoice(invoice))
      .map(joinInvoiceItemAndWorkOrderItem(workOrderItems))
      .reduce(sumInvoiceTotal, 0),
  });

export const filterTotalledInvoicesForWorkOrder = (
  invoices: Invoice[] | undefined,
  invoiceItems: InvoiceItem[],
  workOrderItems: WorkOrderItem[],
): TotalledInvoice[] | undefined => invoices?.map(sumTotalsForInvoice(invoiceItems, workOrderItems));

export const totalledInvoicesForWorkOrder = createSelector(
  currentWorkOrderDetailedInvoices,
  allInvoiceItems,
  sortedWorkOrderItems,
  filterTotalledInvoicesForWorkOrder,
);

export const sortInvoicesByStatusGroup = <T extends Invoice>(invoices: T[]) =>
  invoices?.sort(
    (a, b) =>
      INVOICE_STATUS_GROUP_ORDER_MAP[INVOICE_STATUS_GROUP_MAP[a?.status]] -
      INVOICE_STATUS_GROUP_ORDER_MAP[INVOICE_STATUS_GROUP_MAP[b?.status]],
  );

export const sortedTotalledInvoicesForWorkOrder = createSelector(
  totalledInvoicesForWorkOrder,
  sortInvoicesByStatusGroup,
);

export const filterInvoicesByStatus =
  (...statuses: InvoiceStatus[]) =>
  (invoices: Invoice[] | undefined): Invoice[] | undefined =>
    invoices?.filter(invoice => statuses.includes(invoice.status));

export const filterInvoicesForBadges = (invoices: Invoice[], role: RoleKeys) =>
  filterInvoicesByStatus(
    isRole(role, 'companyManager', 'companyAdmin') ? InvoiceStatus.New : InvoiceStatus.PaidPendingLienRelease,
  )(invoices);

export const invoicesForBadges = createSelector(
  currentWorkOrderDetailedInvoices,
  authenticatedRole,
  filterInvoicesForBadges,
);

export const invoiceBadgeMap = createSelector(invoicesForBadges, invoices =>
  invoices?.length
    ? {
        invoices: {
          color: 'gc-green',
          label: invoices.length,
        },
      }
    : undefined,
);

export const makeDetailedInvoiceItem =
  (workOrderItems: IEntityDictionary<WorkOrderItem>, changeOrders: IEntityDictionary<ChangeOrder>) =>
  (invoiceItem: InvoiceItem): DetailedInvoiceItem => ({
    ...invoiceItem,
    workOrderItem: workOrderItems[invoiceItem.workOrderItemId],
    changeOrders: invoiceItem.changeOrderIds.map(id => changeOrders[id]), // .filter(isNotVoided())
  });

export const joinWorkOrderItemsToInvoiceItemsForCurrentInvoice = (
  invoiceItems: InvoiceItem[],
  workOrderItems: IEntityDictionary<WorkOrderItem>,
  changeOrders: IEntityDictionary<ChangeOrder>,
): DetailedInvoiceItem[] | undefined =>
  !!invoiceItems || !!workOrderItems
    ? invoiceItems.map(makeDetailedInvoiceItem(workOrderItems, changeOrders))
    : undefined;

export const detailedInvoiceItemsForCurrentInvoice = createSelector(
  currentInvoiceItems,
  workOrderItemEntities,
  changeOrderEntities,
  joinWorkOrderItemsToInvoiceItemsForCurrentInvoice,
);
