import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { EmptyKey } from '~gc/domains/entity.service.utils';
import { signLienRelease } from '~gc/domains/invoices/invoice-and-payment.actions';
import { SettlementTimingUIService } from '~gc/domains/invoices/settlement-timing/settlement-timing-ui.service';
import { showSettlementTimingExplainModal } from '~gc/domains/invoices/settlement-timing/settlement-timing.actions';
import { plaidSuccess } from '~gc/shared/dwolla/feature/plaid/plaid.actions';
import { ensureRequired } from '../../../shared/utils/rxjs';
import {
  loadAllSettlementTimings,
  loadAllSettlementTimingsSuccess,
  selectSettlementTiming,
} from './settlement-timing.state';

@Injectable()
export class SettlementTimingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly settlementTimingUI: SettlementTimingUIService,
  ) {}

  loadSettlementTimings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signLienRelease),
      map(() =>
        loadAllSettlementTimings({
          criteria: {
            parents: {
              payments: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  selectDefaultTiming$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllSettlementTimingsSuccess),
      map(({ entities }) => entities.find(timing => timing.isDefault)),
      map(entity => entity),
      ensureRequired(entity => !!entity),
      map(entity => selectSettlementTiming({ entity })),
    ),
  );

  showExplainModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showSettlementTimingExplainModal),
        switchMap(() => this.settlementTimingUI.openExplainModal()),
      ),
    { dispatch: false },
  );

  dismissExplainModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(plaidSuccess),
        switchMap(() => this.settlementTimingUI.dismissExplainModal()),
      ),
    { dispatch: false },
  );
}
