<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss(null, 'cancel')">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Pay Invoice</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-text-center">
  <ng-container *ngIf="auth.tokenPermissions$ | push | hasPermissions: 'payment:create'; else noPermissions">
    <h2 class="ion-text-center">
      <strong>
        {{ workOrder.privateJobName }}
      </strong>
    </h2>

    <gc-transaction-item
      [avatar]="invoicer.profilePhoto"
      [name]="invoicer.firstName + ' ' + invoicer.lastName"
      [amount]="invoice.invoiceAmount"
    ></gc-transaction-item>

    <ion-spinner *ngIf="bankAccounts.isLoading$ | push"></ion-spinner>

    <gc-bank-account-card *ngFor="let bank of bankAccounts.all$ | push" [bank]="bank" (selected)="makePayment($event)">
    </gc-bank-account-card>
  </ng-container>

  <ng-template #noPermissions>
    <ion-img id="no-permissions" src="assets/images/no-permissions.svg"></ion-img>
    <ion-text>Oops, it looks like you don't have permission to do that.</ion-text>
  </ng-template>
</ion-content>
