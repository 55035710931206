import { Component } from '@angular/core';
import { CategoryFacade } from '../../../domains/training/category/category.facade';
import { ProductInstallationTypeFacade } from '../../../domains/training/product-installation-types/product-installation-types.facade';
import { SkillsAndExperienceFacade } from '../../../domains/training/skills-and-experience.facade';

@Component({
  selector: 'gc-add-edit-skills-and-experience-modal',
  templateUrl: './add-edit-skills-and-experience-modal.component.html',
  styleUrls: ['./add-edit-skills-and-experience-modal.component.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AddEditSkillsAndExperienceModal {
  constructor(
    public categories: CategoryFacade,
    public installTypes: ProductInstallationTypeFacade,
    public skills: SkillsAndExperienceFacade,
  ) {}
}
