import { matchByKey } from '../../../shared/utils/utils';
import { Connection } from '../connection/connection.model';
import { InstallerTeamLead } from '../user/user.model';

export interface ConnectedInstallerTeamLead
  extends Omit<InstallerTeamLead, 'installerTeamLeadId'>,
    Omit<Omit<Connection, 'id'>, 'companyId'> {
  connectionId: string;
  connectionCompanyId: number;
}

export const buildConnectedInstaller = (
  installer: InstallerTeamLead,
  connection: Connection,
): ConnectedInstallerTeamLead => ({
  ...installer,
  ...{...connection, companyId: undefined },
  id: installer.id,
  connectionId: connection.id!,
  connectionCompanyId: connection.companyId!,
});

export const tryBuildConnectedInstaller = (
  installer: InstallerTeamLead,
  connection?: Connection,
): ConnectedInstallerTeamLead | InstallerTeamLead =>
  connection ? buildConnectedInstaller(installer, connection) : installer;

export const keepConnectedInstaller = (
  kept: ConnectedInstallerTeamLead[],
  installer: InstallerTeamLead,
  connection?: Connection,
): ConnectedInstallerTeamLead[] => (connection ? [...kept, buildConnectedInstaller(installer, connection)] : kept);

export const filterInstallersByConnections = (
  installers: InstallerTeamLead[],
  connections: Connection[],
): ConnectedInstallerTeamLead[] =>
  installers.reduce(
    (kept: ConnectedInstallerTeamLead[], installer: InstallerTeamLead) =>
      keepConnectedInstaller(kept, installer, connections.find(matchByKey(installer.id, 'installerTeamLeadId'))),
    [],
  );


export const keepNonConnectedInstaller = (
  kept: InstallerTeamLead[],
  installer: InstallerTeamLead,
  connection?: Connection,
): InstallerTeamLead[] => (connection ? kept : [...kept, installer]);
export const filterInstallersNotConnected = (
  installers: InstallerTeamLead[],
  connections: Connection[],
): InstallerTeamLead[] =>
  installers.reduce(
    (kept: InstallerTeamLead[], installer: InstallerTeamLead) =>
      keepNonConnectedInstaller(kept, installer, connections.find(matchByKey(installer.id, 'installerTeamLeadId'))),
    [],
  );
