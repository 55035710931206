import { buildFeatureState } from '@briebug/ngrx-auto-entity';
import { createReducer } from '@ngrx/store';
import { DOMAIN_NAME, domainState } from '../domain.name';
import { CustomDate } from './custom-date.model';

export const {
  actions: {
    loadAll: loadAllCustomDates,
    loadAllSuccess: allCustomDatesLoadedSuccessfully,
    loadMany: loadManyCustomDates,
    loadManySuccess: manyCustomDatesLoadedSuccessfully,
    create: createCustomDate,
    createSuccess: customDateCreatedSuccessfully,
    createFailure: customDateCreationFailed,
    replace: updateCustomDate,
    replaceSuccess: customDateUpdatedSuccessfully,
    replaceFailure: customDateUpdateFailed,
    deleteByKey: deleteCustomDate,
    deleteByKeySuccess: customDateDeletedSuccessfully,
    deleteByKeyFailure: customDateDeletionFailed,
  },
  selectors: { selectAllSorted: allSortedCustomDates },
  initialState,
  entityState: customDateState,
} = buildFeatureState(CustomDate, DOMAIN_NAME, domainState);

export const customDateReducer = createReducer(initialState);
