import { Injectable } from '@angular/core';
import { Select, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { InfiniteScrollCustomEvent, NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, from, merge, of } from 'rxjs';
import {
  catchError,
  delay,
  exhaustMap,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { calculateReviewInvoiceTotal } from '../../modals/invoicing/reviewing-invoice-total.pipe';
import { hasPermissions } from '../../shared/pipes/has-permissions.pipe';
import { totalNormalizedUnits } from '../../shared/pipes/total-normalized-units.pipe';
import { routeEndsInPath } from '../../shared/utils/ngrx';
import { matchesCorrelationId, waitOn } from '../../shared/utils/operators';
import { excludeRoles, onlyRoles } from '../../shared/utils/roles.operators';
import { ensureChildrenExist, ensureChildrenRequired } from '../../shared/utils/rxjs';
import { AppUIService } from '../app-ui.service';
import { isRole } from '../app/auth/auth.maps';
import { authenticatedRole, tokenPermissions } from '../app/auth/auth.selectors';
import { EmptyKey } from '../entity.service.utils';
import { AppState } from '../state';
import { currentWorkOrderCompany } from '../work-order-users.selectors';
import { loadAllWorkOrderItems } from '../work/work-order-item/work-order-item.state';
import { viewWorkOrder } from '../work/work-order-ui.actions';
import { WorkOrder } from '../work/work-order/work-order.model';
import { currentWorkOrder } from '../work/work-order/work-order.state';
import { workOrderRefreshIntervalTicked } from '../work/work-polling.actions';
import {
  addNewInvoice,
  confirmPendingInvoice,
  confirmPendingProgress,
  invoiceListEndReached,
  invoicePaymentMethodDetermined,
  invoicesRouteVisit,
  pendingInvoiceConfirmed,
  pendingInvoiceCreated,
  pendingInvoiceCreating,
  pendingInvoiceDeclined,
  pendingInvoiceFailed,
  pendingProgressConfirmed,
  pendingProgressDeclined,
  reviewPendingInvoice,
  reviewPendingProgress,
  searchCriteriaUpdated,
  viewInvoice,
  viewInvoicesByStatus,
} from './invoice-and-payment.actions';
import { currentStatusGroup, invoiceInfoForSigningLienRelease, searchCriteria } from './invoice-and-payment.selectors';
import { loadAllInvoiceCounts } from './invoice-count/invoice-count.state';
import { uploadQueuedFiles } from './invoice-file/invoice-file.actions';
import { selectPendingInvoiceFileUploads } from './invoice-file/invoice-files.state';
import { InvoiceUIService } from './invoice-ui.service';
import { Invoice, InvoiceStatus } from './invoice/invoice.model';
import { loadStatusGroup } from './invoice/invoices.actions';
import {
  createInvoice,
  createInvoiceFailure,
  createInvoiceSuccess,
  currentInvoicesPage,
  loadAllInvoices,
  loadInvoice,
  loadManyInvoices,
  loadManyInvoicesFailure,
  loadManyInvoicesSuccess,
  updateInvoiceSuccess,
} from './invoice/invoices.state';
import {
  invoicesByCurrentStatusGroup,
  invoiceStatusGroupStatusMapForUser,
  sortedInvoicesByCurrentStatusGroup,
} from './invoices.selectors';
import { createPaymentSuccess } from './payment/payment.state';
import { ProgressUIService } from './progress-ui.service';

@Injectable()
export class InvoiceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly invoicesUI: InvoiceUIService,
    private readonly progressUI: ProgressUIService,
    private readonly nav: NavController,
    private readonly appUI: AppUIService,
  ) {}

  listenForRouteVisit = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeEndsInPath('app/invoices'),
      map(() => invoicesRouteVisit()),
    ),
  );

  loadReadyToSignInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      routeEndsInPath('/dashboard'),
      onlyRoles(this.store.select(authenticatedRole), 'installerLead'),
      map(() =>
        loadManyInvoices({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: EmptyKey,
            },
            query: {
              '&status': InvoiceStatus.PaidPendingLienRelease,
              limit: 50,
            },
          },
        }),
      ),
    ),
  );

  loadSigningLienRelease$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      routeEndsInPath('/dashboard'),
      onlyRoles(this.store.select(authenticatedRole), 'installerLead'),
      withLatestFrom(this.store.select(invoiceInfoForSigningLienRelease)),
      map(([, info]) => info),
      ensureChildrenExist(info => !!info?.id && !!info.workOrderId),
      map(({ id, workOrderId }) =>
        loadInvoice({
          keys: id,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrderId,
            },
          },
        }),
      ),
    ),
  );

  loadWorkOrderInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrder, workOrderRefreshIntervalTicked),
      excludeRoles(this.store.select(authenticatedRole), 'installerMember', 'jumpstarter'),
      map(({ workOrder }) =>
        loadAllInvoices({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            },
          },
        }),
      ),
    ),
  );

  viewInvoicesByStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewInvoicesByStatus),
      tap(() => this.nav.navigateForward(['app/invoices/list'])),
      map(() => loadStatusGroup()),
    ),
  );

  loadInvoicesOnUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateInvoiceSuccess, createPaymentSuccess),
      withLatestFrom(this.store.select(invoicesByCurrentStatusGroup)),
      // This would load them even if theres less than 25 available from the api.
      // TODO:: Use API v1 meta response to track if more invoices are available for more precise loading.
      filter(([, invoices]) => invoices.length === 4),
      map(() => loadStatusGroup()),
    ),
  );

  loadFirstPageInvoicesForStatusGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStatusGroup, searchCriteriaUpdated),
      withLatestFrom(
        this.store.select(currentStatusGroup),
        this.store.select(invoiceStatusGroupStatusMapForUser),
        this.store.select(searchCriteria),
      ),
      map(([, status, statusGroupStatusMap, search]) =>
        loadAllInvoices({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: EmptyKey,
            },
            query: {
              ...(status ? { '&status': statusGroupStatusMap[status] } : {}),
              ...(search ? { search } : {}),
              limit: 50,
            },
          },
        }),
      ),
    ),
  );

  loadMoreInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceListEndReached),
      withLatestFrom(
        this.store.select(currentStatusGroup),
        this.store.select(currentInvoicesPage),
        this.store.select(searchCriteria),
        this.store.select(invoiceStatusGroupStatusMapForUser),
        this.store.select(currentWorkOrder),
      ),
      map(([{ event }, status, pageInfo, search, statusGroupStatusMap, workOrder]) =>
        loadManyInvoices({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder?.id || EmptyKey,
            },
            query: {
              ...(status ? { '&status': statusGroupStatusMap[status] } : {}),
              page: pageInfo?.page ?? 2,
              ...(search ? { search } : {}),
              limit: 50,
            },
            event,
          },
        }),
      ),
    ),
  );

  fillInvoicesListToMinimum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicePaymentMethodDetermined),
      withLatestFrom(this.store.select(sortedInvoicesByCurrentStatusGroup)),
      filter(([, invoices]) => invoices.length < 25),
      withLatestFrom(
        this.store.select(currentStatusGroup),
        this.store.select(currentInvoicesPage),
        this.store.select(searchCriteria),
        this.store.select(invoiceStatusGroupStatusMapForUser),
        this.store.select(currentWorkOrder),
      ),
      map(([, status, pageInfo, search, statusGroupStatusMap, workOrder]) =>
        loadManyInvoices({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder?.id || EmptyKey,
            },
            query: {
              ...(status ? { '&status': statusGroupStatusMap[status] } : {}),
              page: pageInfo?.page ?? 2,
              ...(search ? { search } : {}),
              limit: 25,
            },
          },
        }),
      ),
    ),
  );

  triggerInfiniteScrollComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadManyInvoicesSuccess, loadManyInvoicesFailure),
        tap(
          ({ criteria }: { criteria?: { event?: InfiniteScrollCustomEvent } }) =>
            criteria?.event && criteria.event.target.complete(),
        ),
      ),
    { dispatch: false },
  );

  loadInvoiceCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesRouteVisit),
      map(() =>
        loadAllInvoiceCounts({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: EmptyKey,
              [uriNameOfEntityOrEmpty(Invoice)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  setCurrentInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewInvoice),
      map(({ invoice }) => new Select(Invoice, invoice)),
    ),
  );

  showCreateInvoiceModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addNewInvoice),
        withLatestFrom(this.store.select(authenticatedRole), this.store.select(tokenPermissions)),
        filter(
          ([, role, permissions]) =>
            isRole(role, 'installerLead') && hasPermissions(permissions, 'work-order.invoice:create'),
        ),
        exhaustMap(() => from(this.invoicesUI.showCreateInvoiceModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  showReportProgressModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addNewInvoice),
        withLatestFrom(this.store.select(authenticatedRole), this.store.select(tokenPermissions)),
        filter(
          ([, role, permissions]) =>
            isRole(role, 'companyEmployee') && hasPermissions(permissions, 'work-order.invoice:create'),
        ),
        exhaustMap(() => from(this.progressUI.showReportProgressModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  alertInsufficientPermissionForInvoicing$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addNewInvoice),
        withLatestFrom(this.store.select(authenticatedRole), this.store.select(tokenPermissions)),
        filter(([, , permissions]) => !hasPermissions(permissions, 'work-order.invoice:create')),
        switchMap(([, role]) =>
          from(
            this.progressUI.toastInsufficientPermissionsToInvoice(
              role === 'companyEmployee' ? 'report progress on' : 'create invoice for',
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  showReviewInvoiceModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reviewPendingInvoice),
        switchMap(() => from(this.invoicesUI.showReviewInvoiceModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  showReviewProgressModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reviewPendingProgress),
        switchMap(() => from(this.progressUI.showReviewProgressModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  confirmPendingInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmPendingInvoice),
      withLatestFrom(this.store.select(currentWorkOrderCompany)),
      map(([{ pendingInvoice }, company]) => ({ pendingInvoice, company })),
      ensureChildrenExist(value => !!value?.company),
      exhaustMap(({ pendingInvoice, company }) =>
        this.invoicesUI.confirmPendingInvoice(company).pipe(
          map(role => (role === 'ok' ? pendingInvoiceConfirmed({ pendingInvoice }) : pendingInvoiceDeclined())),
          catchError(error => of(pendingInvoiceFailed({ error }))),
        ),
      ),
    ),
  );

  confirmPendingProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmPendingProgress),
      exhaustMap(({ pendingInvoice }) =>
        from(this.progressUI.confirmPendingProgress()).pipe(
          map(role => (role === 'ok' ? pendingProgressConfirmed({ pendingInvoice }) : pendingProgressDeclined())),
        ),
      ),
    ),
  );

  createPendingInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pendingInvoiceConfirmed),
      ensureChildrenRequired(value => !!value?.pendingInvoice),
      waitOn(({ type }) =>
        this.appUI.showPendingFileUploads(
          'Sending Invoice...',
          this.store.select(selectPendingInvoiceFileUploads),
        ),
      ),
      map(({ pendingInvoice }) => pendingInvoice),
      map(pendingInvoice => ({
        status: InvoiceStatus.New,
        workOrderId: pendingInvoice.workOrderId,
        invoiceItems: pendingInvoice.items?.map(item => ({
          workOrderItemId: item.id,
          numberOfUnits:
            (item.amountToInvoice ?? 0) + totalNormalizedUnits(item.applicableChangeOrders, item.pricePerUnit),
        })),
        invoiceAmount: pendingInvoice?.items ? calculateReviewInvoiceTotal(pendingInvoice.items) : 0,
      })),
      map(pendingInvoice =>
        createInvoice({
          entity: pendingInvoice,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: pendingInvoice.workOrderId,
            },
            idempotent: true,
          },
        }),
      ),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(create => [create, pendingInvoiceCreating({ correlationId: create.correlationId })]),
      catchError(error => of(pendingInvoiceFailed({ error }))),
    ),
  );

  createPendingProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pendingProgressConfirmed),
      ensureChildrenRequired(value => !!value?.pendingInvoice),
      waitOn(({ type }) =>
        this.appUI.showPendingFileUploads('Sending Progress...', this.store.select(selectPendingInvoiceFileUploads)),
      ),
      map(({ pendingInvoice }) => pendingInvoice),
      map(pendingInvoice => ({
        status: InvoiceStatus.New,
        workOrderId: pendingInvoice.workOrderId,
        invoiceItems: pendingInvoice.items?.map(item => ({
          workOrderItemId: item.id,
          numberOfUnits: item.amountToInvoice ?? 0,
          capturedUnitsRemaining: item.capturedUnitsRemaining,
        })),
        invoiceAmount: 0,
        isProgressReport: true,
      })),
      map(pendingInvoice =>
        createInvoice({
          entity: pendingInvoice,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: pendingInvoice.workOrderId,
            },
            idempotent: true,
          },
        }),
      ),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      // TODO: follow good action hygiene and create new actions for progress and don't re-use these for invoices.
      switchMap(create => [create, pendingInvoiceCreating({ correlationId: create.correlationId })]),
      catchError(error => of(pendingInvoiceFailed({ error }))),
    ),
  );

  createInvoiceSucceeded$ = createEffect(() =>
    this.actions$.pipe(ofType(pendingInvoiceCreating)).pipe(
      mergeMap(({ correlationId: expectedCorrelationId }) =>
        this.actions$.pipe(
          ofType(createInvoiceSuccess),
          first(({ correlationId }) => correlationId === expectedCorrelationId),
          map(({ entity }) => pendingInvoiceCreated({ invoice: entity })),
        ),
      ),
    ),
  );

  hideProcessingSpinnerAfterSuccess$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(pendingInvoiceCreating)),
        this.actions$.pipe(ofType(createInvoiceSuccess)),
      ]).pipe(
        matchesCorrelationId(),
        switchMap(([, { entity }]) =>
          this.actions$.pipe(
            ofType(uploadQueuedFiles),
            delay(50),
            switchMap(() =>
              this.store.select(selectPendingInvoiceFileUploads).pipe(
                filter(uploads => uploads?.length === 0 || uploads === undefined),
                take(1),
                tap(() => this.appUI.hidePendingFileUploads()),
                tap(() => this.invoicesUI.unwindAddReviewModalStack()),
                tap(() => this.progressUI.unwindAddReviewModalStack()),
                map(() => entity),
              ),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  createInvoiceFailed$ = createEffect(
    () =>
      merge(
        combineLatest([
          this.actions$.pipe(ofType(pendingInvoiceCreating)),
          this.actions$.pipe(ofType(createInvoiceFailure)),
        ]).pipe(
          matchesCorrelationId(),
          map(([, action]) => action),
        ),
        this.actions$.pipe(ofType(pendingInvoiceFailed)),
      ).pipe(exhaustMap(({ error }) => this.invoicesUI.toastInvoiceCreationError(error))),
    { dispatch: false },
  );

  hideProcessingSpinnerOnFailure$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(pendingInvoiceCreating)),
        this.actions$.pipe(ofType(createInvoiceFailure)),
      ]).pipe(
        matchesCorrelationId(),
        tap(() => this.appUI.hidePendingFileUploads()),
      ),
    { dispatch: false },
  );

  // TODO: Consolidate with invoice-actions.effects for loading on void invoice.
  reloadWorkOrderItemsForNewInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pendingInvoiceCreated),
      map(({ invoice }) =>
        loadAllWorkOrderItems({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
            },
          },
        }),
      ),
    ),
  );
}
