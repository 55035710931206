import { Component } from '@angular/core';
import { AuthFacade } from '../../domains/app/auth/auth.facade';

@Component({
  selector: 'gc-login-landing',
  templateUrl: './login-landing.component.html',
  styleUrls: ['./login-landing.component.scss'],
})
export class LoginLandingComponent {
  constructor(public readonly auth: AuthFacade) {}
}
