<ng-container *ngIf="items?.length > 0; else noItems">
  <ng-container *ngIf="invoice && items; else noItems">
    <ion-list *ngFor="let item of items! | sortBy: 'workOrderItem.name'; let i = index; let lastItem = last">
      <ion-item [lines]="lastItem && !item?.changeOrders?.length ? 'full' : 'none'">
        <ion-label>
          <h2>
            <strong>{{ item.workOrderItem?.name || '...' }}</strong>
          </h2>
          <p>
            <span>
              {{ item.numberOfUnits }} @
              {{ item.workOrderItem.pricePerUnit | currency }}
            </span>
            / <span class="uom">{{ item.workOrderItem?.unitOfMeasureSymbol }}</span>
          </p>
        </ion-label>
        <ion-text class="ion-no-margin ion-text-right" slot="end">
          <strong>{{ item.workOrderItem.pricePerUnit * item.numberOfUnits | currency }}</strong>
        </ion-text>
      </ion-item>
      <ion-item *ngFor="let changeOrder of item?.changeOrders; let lastCO = last" [lines]="lastCO ? 'full' : 'none'">
        <ion-label>
          <h2>
            <ion-text color="gc-blue"
              ><strong>{{ changeOrder.name }}</strong></ion-text
            >
          </h2>
          <p>
            <span> {{ changeOrder.adjustedNumberOfUnits }} @ {{ changeOrder.adjustedPricePerUnit | currency }} </span>
            / <span class="uom">{{ item.workOrderItem?.unitOfMeasureSymbol }}</span>
          </p>
        </ion-label>
        <ion-text color="gc-blue" class="ion-no-margin ion-text-right" slot="end">
          -{{ changeOrder.adjustedPricePerUnit * changeOrder.adjustedNumberOfUnits | currency }}
        </ion-text>
      </ion-item>
    </ion-list>
    <ion-list>
      <ion-item lines="none">
        <ion-label>
          <ion-text><strong>TOTAL</strong></ion-text>
        </ion-label>
        <ion-text class="ion-no-margin ion-text-right" slot="end">
          <strong>{{ invoice.invoiceAmount | currency }}</strong>
        </ion-text>
      </ion-item>
      <ion-item *ngIf="(user | isInstallerTeamLead) && invoice.settlementAmount" lines="none">
        <ion-label>
          <ion-text><strong>EXPECTED SETTLEMENT AMOUNT</strong></ion-text>
        </ion-label>
        <ion-text class="ion-no-margin ion-text-right" slot="end">
          <strong>{{ invoice.settlementAmount | currency }}</strong>
        </ion-text>
      </ion-item>
    </ion-list>
  </ng-container>
</ng-container>
<ng-template #noItems> No items found.</ng-template>
