import { Component, Input } from '@angular/core';
import { InvoiceableWorkOrderItem } from '../../../../domains/models/invoiceable-work-order-item';
import { WorkOrder } from '../../../../domains/work/work-order/work-order.model';
import { PendingInvoiceForm } from '../../invoice.form';

@Component({
  selector: 'gc-report-progress-form',
  templateUrl: './report-progress-form.component.html',
  styleUrls: ['./report-progress-form.component.scss'],
})
export class ReportProgressFormComponent {
  @Input() items!: InvoiceableWorkOrderItem[];
  @Input() workOrder!: WorkOrder;
  @Input() form!: PendingInvoiceForm;

  get itemForms() {
    return this.form.items.itemForms;
  }
}
