import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { LetModule } from '@rx-angular/template/let';
import { PushModule } from '@rx-angular/template/push';
import { InformationMessageModule } from '../../../shared/components/information-message/information-message.module';
import { CompanyInviteFooterComponent } from './company-invite-footer/company-invite-footer.component';
import { CompanyInviteFormComponent } from './company-invite-form/company-invite-form.component';
import { CompanyInviteEffects } from './company-invite.effects';
import { CompanyInviteModal } from './company-invite.modal';

@NgModule({
  declarations: [CompanyInviteModal, CompanyInviteFormComponent, CompanyInviteFooterComponent],
  imports: [
    CommonModule,
    IonicModule,
    EffectsModule.forFeature([CompanyInviteEffects]),
    InformationMessageModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    LetModule,
    PushModule,
  ],
  exports: [CompanyInviteModal],
})
export class CompanyInviteModule {}
