import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Channel } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-angular';
import { AppState } from '../../state';
import { WorkOrder } from '../work-order/work-order.model';
import {
  addMemberToChannel,
  workOrderChatChannelActivated,
  workOrderChatActivated,
  removeMemberFromChannel,
  switchToCurrentWorkOrderChat,
  workOrderChatSidebarClosed,
  workOrderChatSidebarOpened,
} from './work-order-chat.actions';
import {
  currentChannelIds,
  enrichedWorkOrderChat,
  hasError,
  isInitialized,
  isChatOpen,
  unmatchedWorkOrderChat,
} from './work-order-chat.state';


@Injectable()
export class WorkOrderChatFacade {
  isInitialized$ = this.store.select(isInitialized);
  menuOpen$ = this.store.select(isChatOpen);
  hasError$ = this.store.select(hasError);
  unmatchedWorkOrderChat$ = this.store.select(unmatchedWorkOrderChat);
  currentChannelIds$: Observable<string[]> = this.store.select(currentChannelIds);

  getEnrichedWorkOrderChat$(channel: Channel<DefaultStreamChatGenerics>) {
    return this.store.select(enrichedWorkOrderChat(channel));
  }

  constructor(private store: Store<AppState>) {
  }

  openWorkOrderChat(workOrder: WorkOrder) {
    this.store.dispatch(workOrderChatActivated({ workOrder }));
  }

  openChatByChannel(workOrderId: string, channelId: string) {
    this.store.dispatch(workOrderChatChannelActivated({ workOrderId, channelId }));
  }

  openChat(channelId: string) {
    this.store.dispatch(workOrderChatSidebarOpened({ channels: [channelId] }));
  }

  switchToCurrentWorkOrderChat() {
    this.store.dispatch(switchToCurrentWorkOrderChat());
  }

  closeMenu() {
    this.store.dispatch(workOrderChatSidebarClosed());
  }

  addMemberToChannel() {
    this.store.dispatch(addMemberToChannel());
  }

  removeMemberFromChannel() {
    this.store.dispatch(removeMemberFromChannel());
  }
}
