import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { User } from './user.model';

export const {
  initialState: initialUserState,
  facade: UserFacadeBase,
  selectors: {
    selectAll: allUsers,
    selectEntities: userEntities,
    selectCurrentEntity: currentUser,
    selectCurrentEntities: selectedUsers,
  },
  actions: {
    load: loadUser,
    loadSuccess: loadUserSuccess,
    loadAll: loadAllUsers,
    loadMany: loadManyUsers,
    loadAllIfNecessary: loadAllUsersIfNecessary,
    selectByKey: selectUserByKey,
    select: selectUser,
    selected: userSelected,
    replace: replaceUser,
    replaceSuccess: replaceUserSucceeded,
    replaceFailure: replaceUserFailed,
    delete: deleteUser,
    deleteSuccess: deleteUserSuccess,
  },
} = buildState(User);

const reduce = createReducer(initialUserState);

export function userReducer(state = initialUserState, action: Action): IEntityState<User> {
  return reduce(state, action);
}
