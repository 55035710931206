import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Connection } from './connection.model';

export const {
  initialState: initialConnectionState,
  actions: {
    load: loadConnection,
    loadAll: loadAllConnections,
    create: createConnection,
    createSuccess: connectionCreationSucceeded,
    createFailure: connectionCreationFailed,
    replace: replaceConnection,
    replaceSuccess: connectionReplacementSucceeded,
    replaceFailure: connectionReplacementFailed,
    delete: deleteConnection,
  },
  selectors: { selectAll: allConnections },
} = buildState(Connection);

export function connectionReducer(state = initialConnectionState): IEntityState<Connection> {
  return state;
}
