import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { TermsAndConditionsAccepted } from '../terms-and-conditions-accepted/terms-and-conditions-accepted.model';

export const {
  initialState: initialTermsAndConditionsAcceptedState,

  selectors: { selectAll: allTermsAndConditionsAccepted },

  facade: TermsAndConditionsAcceptedFacadeBase,
} = buildState(TermsAndConditionsAccepted);

export function termsAndConditionsAcceptedReducer(
  state = initialTermsAndConditionsAcceptedState,
): IEntityState<TermsAndConditionsAccepted> {
  return state;
}
