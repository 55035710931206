import { createSelector } from '@ngrx/store';
import {
  allProductInstallationTypeClaimed
} from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.state';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';
import { allProductInstallationTypes } from '../../domains/training/product-installation-types/product-installation-types.state';
import { ItemChooserOption } from '../../shared/components/item-chooser-list/item-chooser-option.model';
import { ClaimProductInstallationTypeContext } from './models/claim-product-installation-type-context.model';
import { added, claiming, pending } from './store/ui.selectors';

export const unclaimedProductInstallationTypes = createSelector(
  allProductInstallationTypes,
  allProductInstallationTypeClaimed,
  added,
  (productInstallationTypes, productInstallationTypesClaimed, addedIds): ProductInstallationType[] | undefined =>
    productInstallationTypes
      ? productInstallationTypes.filter(
        productInstallationType =>
          addedIds?.some(id => id === productInstallationType.id) ||
          !productInstallationTypesClaimed?.some(productInstallationTypeClaimed => productInstallationTypeClaimed.productInstallationTypeId === productInstallationType.id),
      )
      : undefined,
);

export const unclaimedProductInstallationTypeChooserOptions = createSelector(
  unclaimedProductInstallationTypes,
  pending,
  claiming,
  added,
  (unclaimedProductInstallationTypes, pendingProductInstallationType, claimingProductInstallationType, addedIds): ItemChooserOption<ProductInstallationType>[] | undefined =>
    unclaimedProductInstallationTypes
      ? unclaimedProductInstallationTypes.map(productInstallationType => ({
        id: productInstallationType.id,
        label: productInstallationType.type,
        value: productInstallationType,
        added: addedIds?.some(id => id === productInstallationType.id) ?? false,
        adding: claimingProductInstallationType?.id === productInstallationType.id || pendingProductInstallationType?.id === productInstallationType.id,
      }))
      : undefined,
);

export const claimProductInstallationTypeContext = createSelector(
  unclaimedProductInstallationTypeChooserOptions,
  (items): ClaimProductInstallationTypeContext | undefined => ({ items }),
);
