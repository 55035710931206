<form [formGroup]="form" novalidate>
  <ng-container formArrayName="items" *ngFor="let itemForm of itemForms">
    <ion-card
      *ngIf="itemForm.updatedModel as item"
      [disabled]="!(item | hasRemaining)"
      mode="ios"
      class="ion-no-margin gc-mb-3"
    >
      <ion-card-content>
        <ion-button
          fill="clear"
          color="dark"
          class="ion-no-padding ion-no-margin gc-mb-1"
          style="height: auto"
          (click)="itemForm.toggleFilled()"
        >
          <ion-icon slot="icon-only" [name]="itemForm.filled ? 'checkbox-outline' : 'square-outline'"></ion-icon>
        </ion-button>
        <ion-text color="dark">
          <h1>
            <strong>{{ item.name }}</strong>
          </h1>
        </ion-text>
        <ion-text>
          <h2>
            <strong>{{ item.numberOfUnits }} </strong>
            <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
          </h2>
        </ion-text>
        <ion-text color="gc-green">
          <strong>{{ (item | numberOfUnitsStillPending) + ' ' + item.unitOfMeasureSymbol + ' REMAINING' }}</strong>
        </ion-text>

        <ion-range
          *ngIf="item.unitOfMeasureSymbol === 'HR'"
          [formControl]="itemForm.amountToInvoice"
          min="0"
          [max]="item | numberOfUnitsStillPending"
          mode="md"
          step="1"
          snaps="true"
          ticks="true"
          pin="true"
          color="dark"
        >
          <ion-label slot="start">0</ion-label>
          <ion-label slot="end">{{ item | numberOfUnitsStillPending }}</ion-label>
        </ion-range>

        <ion-input
          [max]="item | numberOfUnitsStillPending"
          type="number"
          fill="solid"
          mode="md"
          class="ion-margin-vertical"
          [formControl]="itemForm.amountToInvoice"
          [label]="!!item.applicableChangeOrders?.length ? 'Additional Amount to Report' : 'Amount to Report'"
          labelPlacement="floating"
        ></ion-input>

        <div *ngIf="itemForm | hasItemFormError: 'maxAmount'" class="alert alert__warning">
          You must report progress within the number of units remaining.
        </div>
        <div *ngIf="itemForm | hasItemFormError: 'max'" class="alert alert__warning">
          You must report progress within the number of units remaining.
        </div>
        <div *ngIf="itemForm | hasItemFormError: 'incrementAmount'" class="alert alert__warning">
          You must report progress in {{ itemForm.amountToInvoice.errors!.incrementAmount }}
          {{ itemForm.updatedModel.unitOfMeasureSymbol }} increments.
        </div>
      </ion-card-content>
    </ion-card>
  </ng-container>
</form>
