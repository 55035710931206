import { Component, Input } from '@angular/core';
import { Channel } from 'stream-chat';
import { ChannelService, DefaultStreamChatGenerics } from 'stream-chat-angular';
import { AuthFacade } from '../../domains/app/auth/auth.facade';
import { UserFacade } from '../../domains/users';
import { WorkOrderChatFacade } from '../../domains/work/work-order-chat/work-order-chat.facade';

@Component({
  selector: 'gc-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  @Input() fullScreen!: boolean;
  @Input() channels?: Channel<DefaultStreamChatGenerics>[];
  @Input() activeChannel?: Channel<DefaultStreamChatGenerics>;
  @Input() hasError!: boolean;

  constructor(
    public auth: AuthFacade,
    public channelService: ChannelService,
    public workOrderChat: WorkOrderChatFacade,
    public users: UserFacade,
  ) {}

  toggleExpansion() {
    document.querySelector('.str-chat__textarea textarea')?.classList.toggle('expanded');
  }
}
