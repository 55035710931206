import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Invoice, InvoiceAndPaymentFacade } from '~gc/domains/invoices';
import { LienReleaseTemplateFacade } from '~gc/domains/invoices/lien-release-template/lien-release-template.facade';
import { AuthFacade } from '../../domains/app/auth/auth.facade';
import { LienReleaseFileFacade } from '../../domains/invoices/lien-release-file/lien-release-file.facade';
import { CompaniesFacade } from '../../domains/users';
import { WorkOrderFacade } from '../../domains/work/work-order/work-order.facade';
import { PrintService } from '../../shared/print/print.service';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';

@Component({
  selector: 'gc-lien-release',
  templateUrl: './lien-release.component.html',
  styleUrls: ['./lien-release.component.scss'],
})
export class LienReleaseModalComponent {
  @ViewChild('printContent') content!: TemplateRef<any>;
  template$ = this.lienReleaseTemplate.currentWorkOrderLienReleaseTemplate$;
  date = new Date();
  @Input() invoice!: Invoice;
  @Input() enableSigning!: boolean;

  @ViewChild('signature') signature!: SignaturePadComponent;
  lienReleaseAgreed = false;
  processingFeeAgreed = false;

  constructor(
    public lienReleaseTemplate: LienReleaseTemplateFacade,
    public workOrders: WorkOrderFacade,
    public companies: CompaniesFacade,
    public invoices: InvoiceAndPaymentFacade,
    public modalGC: ModalController,
    public lienReleaseFiles: LienReleaseFileFacade,
    public platform: Platform,
    public printer: PrintService,
    public auth: AuthFacade,
  ) {}

  isEmpty(): boolean {
    return this.signature?.isEmpty ?? true;
  }
}
