import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AddEditSkillsAndExperienceModal } from '../../modals/add-edit-skills-and-experience';
import { ClaimCategorizedTrainingModalComponent } from '../../modals/add-edit-skills-and-experience/claim-categorized-training-modal/claim-categorized-training-modal.component';
import { Category } from './category/category.model';
import { ProductInstallationTypeClaimed } from './product-installation-types-claimed/product-installation-types-claimed.model';
import { TrainingClaimed } from './trainings-claimed/trainings-claimed.model';

@Injectable()
export class TrainingsUIService {
  private addEditSkillsAndExperienceModal?: HTMLIonModalElement;

  constructor(
    private readonly alerts: AlertController,
    private readonly modals: ModalController,
  ) {}

  async showAddEditSkillsAndExperienceModal(): Promise<void> {
    if (this.addEditSkillsAndExperienceModal) {
      return Promise.resolve(void 0);
    }

    this.addEditSkillsAndExperienceModal = await this.modals.create({
      component: AddEditSkillsAndExperienceModal,
      backdropDismiss: false,
      cssClass: 'rounded-modal',
    });

    this.addEditSkillsAndExperienceModal.onDidDismiss().then(() => {
      this.addEditSkillsAndExperienceModal = undefined;
    });

    await this.addEditSkillsAndExperienceModal.present();
  }

  closeAddEditSkillsAndExperienceModal(): void {
    this.addEditSkillsAndExperienceModal?.dismiss();
  }

  async showClaimCategorizedTrainingModal(
    category: Category,
    claimedInstallType: ProductInstallationTypeClaimed,
  ): Promise<{ role?: string; training: TrainingClaimed }> {
    const modal = await this.modals.create({
      component: ClaimCategorizedTrainingModalComponent,
      componentProps: {
        category,
        claimedInstallType,
      },
      cssClass: 'rounded-modal',
    });
    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return { role, training: data };
  }

  async confirmDeletionOfTrainingClaimed(claimed: TrainingClaimed): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header: 'Are you sure you want to remove this item?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
        },
        {
          text: 'Yes',
          role: 'ok',
          cssClass: 'button-text-red',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }
}
