import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-change-order-modal-footer',
  templateUrl: './change-order-modal-footer.component.html',
  styleUrls: ['./change-order-modal-footer.component.scss'],
})
export class ChangeOrderModalFooterComponent {
  @Input() disabled? = false;
  @Output() submitChangeOrder = new EventEmitter();
}
