import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { hasInitialized, shouldContactSupport } from './feature/account.selectors';
import { transferDwollaBalance } from './feature/dwolla-account/dwolla.actions';
import {
  documentUploadNeeded,
  dwollaAccount,
  hasAccountBalance,
  hasDwollaAccount,
  hasVerifiedFundingSource,
  isAccountEnabled,
  isLoading,
  isTransferringBalance,
  requiresBeneficialOwnershipCertification,
  shouldShowCustomerCreate,
} from './feature/dwolla-account/dwolla.selectors';
import { openPlaid } from './feature/plaid/plaid.actions';
import { hasPlaidAccount, plaidAccountVerified, plaidPendingManualVerification } from './feature/plaid/plaid.selectors';

@Injectable()
export class DwollaFacade {
  isLoading$ = this.store.select(isLoading);
  dwollaAccount$ = this.store.select(dwollaAccount);
  hasAccountBalance$ = this.store.select(hasAccountBalance);
  hasDwollaAccount$ = this.store.select(hasDwollaAccount);
  isAccountEnabled$ = this.store.select(isAccountEnabled);
  hasVerifiedFundingSource$ = this.store.select(hasVerifiedFundingSource);
  isTransferringBalance$ = this.store.select(isTransferringBalance);
  shouldShowCustomerCreate$ = this.store.select(shouldShowCustomerCreate);
  requiresBeneficialOwnershipCertification$ = this.store.select(requiresBeneficialOwnershipCertification);
  documentUploadNeeded$ = this.store.select(documentUploadNeeded);

  hasPlaidAccount$ = this.store.select(hasPlaidAccount);
  plaidAccountVerified$ = this.store.select(plaidAccountVerified);
  plaidPendingManualVerification$ = this.store.select(plaidPendingManualVerification);

  shouldContactSupport$ = this.store.select(shouldContactSupport);
  hasInitialized$ = this.store.select(hasInitialized);

  constructor(private store: Store) {}

  openPlaid() {
    this.store.dispatch(openPlaid({}));
  }

  transferBalance() {
    this.store.dispatch(transferDwollaBalance());
  }
}
