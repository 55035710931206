<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button data-cy="installer-profile-modal-close" color="dark" (click)="usersUI!.closeProfile()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ng-container *ngIf="auth.tokenPermissions$ | push as permissions">
      <ng-container *ngIf="auth.user$ | push as authUser">
        <ng-container *ngIf="profile.currentInstallerProfile$ | push as user">
          <ion-buttons slot="end" *ngIf="user.id !== authUser.id">
            <ion-button
              data-cy="installer-profile-modal-delete"
              *ngIf="((permissions | hasPermissions: 'team:delete') && ((user | isMyCompanyEmployee: authUser) || (user | isMyTeamMember: authUser)))"
              color="danger"
              mode="md"
              (click)="usersUI!.removeUser(user)"
            >
              <fa-icon slot="icon-only" [icon]="faTrashCan" style="color: var(--ion-color-danger)"></fa-icon>
            </ion-button>

            <ion-button
              data-cy="installer-profile-modal-disconnect"
              *ngIf="((permissions | hasPermissions: 'connections:disconnect') && (user | isConnectedTo: authUser: user.networkConnection))"
              color="danger"
              mode="md"
              (click)="usersUI!.disconnectUser(user!.networkConnection)"
            >
              <fa-icon
                slot="icon-only"
                [icon]="faHandshakeSimpleSlash"
                style="color: var(--ion-color-danger)"
              ></fa-icon>
            </ion-button>
          </ion-buttons>
        </ng-container>
      </ng-container>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *rxLet="profile.currentInstallerProfile$; let user; let isLoading = suspense;">
    <gc-user-header [isLoading]="isLoading" [user]="user"></gc-user-header>
    <gc-area-segments
      [areas]="profile.currentInstallerProfileAreas$ | push"
      [selectedArea]="selectedArea"
      (selected)="selectArea($event)"
    ></gc-area-segments>

    <ng-container [ngSwitch]="selectedArea">
      <gc-general-area *ngSwitchCase="'general'" [isLoading]="isLoading" [installer]="user"></gc-general-area>
      <gc-hammer-rating-area
        *ngSwitchCase="'rating'"
        [isLoading]="isLoading"
        [installer]="user"
      ></gc-hammer-rating-area>
      <gc-compliance-area
        *ngSwitchCase="'compliance'"
        [isLoading]="isLoading"
        [files]="profile.currentInstallerProfileComplianceFiles$ | push"
      ></gc-compliance-area>
    </ng-container>
  </ng-container>
</ion-content>

<ng-container *rxLet="profile.currentInstallerProfile$; let user; let isLoading = suspense;">
  <ion-footer *ngIf="!user.networkConnection && !((user | isInstallerTeamMember) || (user | isEmployeeInstaller))">
    <ion-grid>
      <ion-row>
        <ion-col size="6" offset="6">
          <ion-button
            mode="ios"
            expand="block"
            color="gc-green"
            (click)="team.inviteInstaller(user)"
            [disabled]="isLoading"
          >
            Connect with {{ user.firstName }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
</ng-container>
