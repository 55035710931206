import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnnouncementModalComponent } from '../modals/announcement-modal/announcement-modal.component';

@Injectable({
  providedIn: 'root',
})
export class DashboardUIService {
  constructor(private modals: ModalController) {}

  async showAnnouncementModal() {
    const modal = await this.modals.create({
      component: AnnouncementModalComponent,
      cssClass: 'announcement-modal',
    });
    await modal.present();
    return modal.onDidDismiss();
  }
}
