import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AnyUser, InstallerTeamLead, ProjectManager } from '../../domains/users';
import { AddressForm, TypedFormGroup } from '../../shared/utils/typed-form-group';

export class InstallerProfileForm extends TypedFormGroup {
  readonly firstName = this.getFormControl('firstName');
  readonly lastName = this.getFormControl('lastName');
  readonly email = this.getFormControl('email');
  readonly dob = this.getFormControl('dob');
  readonly profilePhoto = this.getFormControl('profilePhoto');
  readonly ein = this.getFormControl('ein');
  readonly bio = this.getFormControl('bio');
  readonly insuranceAgentName = this.getFormControl('insuranceAgentName');
  readonly insuranceAgentPhone = this.getFormControl('insuranceAgentPhone');
  readonly insurancePolicyNumber = this.getFormControl('insurancePolicyNumber');
  readonly insuranceProvider = this.getFormControl('insuranceProvider');
  readonly insuranceExpiration = this.getFormControl('insuranceExpiration');
  readonly address = this.getControl('address') as AddressForm;

  get updatedModel(): Partial<AnyUser> {
    return {
      ...this.model,
      ...this.value,
      addresses: [this.address.updatedModel], //...(this.model?.addresses?.slice(1) ?? [])],
      address: undefined,
      profilePhoto: undefined,
    };
  }

  constructor(
    private model?: Partial<AnyUser>,
    builder = new UntypedFormBuilder(),
  ) {
    super(
      {
        controls: {
          firstName: [model?.firstName ?? null],
          lastName: [model?.lastName ?? null],
          email: [model?.email ?? null],
          color: [(model as ProjectManager)?.color ?? '#000000'],
          dob: [(model as InstallerTeamLead)?.dob ?? null],
          profilePhoto: [model?.profilePhoto ?? null],
          ein: [(model as InstallerTeamLead)?.ein ?? null],
          bio: [(model as InstallerTeamLead)?.bio ?? null],
          insuranceAgentName: [(model as InstallerTeamLead)?.insuranceAgentName ?? null],
          insuranceAgentPhone: [(model as InstallerTeamLead)?.insuranceAgentPhone ?? null],
          insurancePolicyNumber: [(model as InstallerTeamLead)?.insurancePolicyNumber ?? null],
          insuranceProvider: [(model as InstallerTeamLead)?.insuranceProvider ?? null],
          insuranceExpiration: [(model as InstallerTeamLead)?.insuranceExpiration ?? null],
          address: new AddressForm(model?.addresses?.[0], builder),
        },
      },
      builder,
    );

    this.enableBasicValidators();
  }

  updateModel(model: Partial<AnyUser>): void {
    this.patchValue(model);
    model?.addresses?.[0] ? this.address.updateModel(model.addresses[0]) : void 0;
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = value as AnyUser;
    super.patchValue({ ...value, addresses: undefined }, options);
  }

  enableValidators() {
    this.firstName.setValidators(Validators.required);
    this.lastName.setValidators(Validators.required);
    this.email.setValidators(Validators.required);
    this.dob.setValidators(Validators.required);
    this.ein.setValidators(Validators.required);
    this.bio.setValidators(Validators.required);
    this.insuranceAgentName.setValidators(Validators.required);
    this.insuranceAgentPhone.setValidators(Validators.required);
    this.insurancePolicyNumber.setValidators(Validators.required);
    this.insuranceProvider.setValidators(Validators.required);
    this.insuranceExpiration.setValidators(Validators.required);
    this.address.enableValidators();

    this.updateValueAndValidity();
  }

  disableValidators() {
    this.firstName.clearValidators();
    this.lastName.clearValidators();
    this.email.clearValidators();
    this.dob.clearValidators();
    this.ein.clearValidators();
    this.bio.clearValidators();
    this.insuranceAgentName.clearValidators();
    this.insuranceAgentPhone.clearValidators();
    this.insurancePolicyNumber.clearValidators();
    this.insuranceProvider.clearValidators();
    this.insuranceExpiration.clearValidators();
    this.address.disableValidators();

    this.updateValueAndValidity();
  }

  enableBasicValidators() {
    this.ein.clearValidators();
    this.bio.clearValidators();
    this.insuranceAgentName.clearValidators();
    this.insuranceAgentPhone.clearValidators();
    this.insurancePolicyNumber.clearValidators();
    this.insuranceProvider.clearValidators();
    this.insuranceExpiration.clearValidators();

    this.firstName.setValidators(Validators.required);
    this.lastName.setValidators(Validators.required);
    this.email.setValidators(Validators.required);
    this.dob.setValidators(Validators.required);
    this.address.enableValidators();

    this.updateValueAndValidity();
  }

  enableInsuranceValidators() {
    this.firstName.clearValidators();
    this.lastName.clearValidators();
    this.email.clearValidators();
    this.dob.clearValidators();
    this.ein.clearValidators();
    this.bio.clearValidators();
    this.address.disableValidators();

    this.insuranceAgentName.setValidators(Validators.required);
    this.insuranceAgentPhone.setValidators(Validators.required);
    this.insurancePolicyNumber.setValidators(Validators.required);
    this.insuranceProvider.setValidators(Validators.required);
    this.insuranceExpiration.setValidators(Validators.required);

    this.updateValueAndValidity();
  }

  enableTaxValidators() {
    this.firstName.clearValidators();
    this.lastName.clearValidators();
    this.email.clearValidators();
    this.dob.clearValidators();
    this.bio.clearValidators();
    this.address.disableValidators();
    this.insuranceAgentName.clearValidators();
    this.insuranceAgentPhone.clearValidators();
    this.insurancePolicyNumber.clearValidators();
    this.insuranceProvider.clearValidators();
    this.insuranceExpiration.clearValidators();

    this.ein.setValidators(Validators.required);

    this.updateValueAndValidity();
  }

  enableBioValidators() {
    this.firstName.clearValidators();
    this.lastName.clearValidators();
    this.email.clearValidators();
    this.dob.clearValidators();
    this.ein.clearValidators();
    this.insuranceAgentName.clearValidators();
    this.insuranceAgentPhone.clearValidators();
    this.insurancePolicyNumber.clearValidators();
    this.insuranceProvider.clearValidators();
    this.insuranceExpiration.clearValidators();
    this.address.disableValidators();

    this.bio.setValidators(Validators.required);

    this.updateValueAndValidity();
  }
}
