<ng-container *ngFor="let group of groups; trackBy: trackGroupBy">
  <ng-container *ngTemplateOutlet="groupHeader ?? null; context: { group: group }"></ng-container>
  <ng-container *ngFor="let item of group.items; trackBy: trackItemBy">
    <ng-container
      *ngIf="
        ((group | typeIsIn: 'original':'negotiable') &&
          (displayGroups | itemGroupsAllowed: 'original':'negotiable') &&
          (permissions | hasPermissions: 'work-order.items:view')) ||
        ((group | typeIsIn: 'change-add') &&
          (displayGroups | itemGroupsAllowed: 'change-add') &&
          (permissions | hasPermissions: 'work-order.change-order-items:view')) ||
        ((group | typeIsIn: 'installer-add') &&
          (displayGroups | itemGroupsAllowed: 'installer-add') &&
          (permissions | hasPermissions: 'work-order.change-order-items:view'))
      "
    >
      <ion-list class="ion-no-padding">
        <ion-item [attr.data-cy]="item.name + '-change-order-card'" lines="full" class="ion-align-items-start">
          <ion-label style="white-space: normal">
            <h2 [attr.data-cy]="'change-order-title-' + item.name">
              <strong>
                <span [class.muted]="item.changeStatus === 'DECLINED_WITH_REASON'">{{ item.name }}</span>
                <ion-icon
                  *ngIf="!!item.acceptedById"
                  data-cy="change-order-accepted-checkmark"
                  name="checkmark-circle"
                  color="gc-green"
                  size="small"
                  style="transform: translateY(2px)"
                ></ion-icon>
              </strong>
              <ng-container
                *ngTemplateOutlet="inlineButtons ?? null; context: { group: group, item: item }"
              ></ng-container>
            </h2>

            <ng-container *ngIf="allowedPriceBreakdown">
              <p
                *ngIf="role | allowsPriceInfo"
                [attr.data-cy]="item.name + '-change-order-item-info'"
                [class.muted]="item.changeStatus === 'DECLINED_WITH_REASON'"
              >
                <span [class.changed]="item | hasChangeOrders">
                  {{ item.numberOfUnits }} @ {{ item.pricePerUnit | currency }}
                </span>
                /
                <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
              </p>
              <p *ngIf="!(role | allowsPriceInfo)" [class.muted]="item.changeStatus === 'DECLINED_WITH_REASON'">
                <span [class.changed]="item | hasChangeOrders">
                  {{ item.numberOfUnits }}
                </span>
                <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
              </p>
            </ng-container>

            <ng-container *ngTemplateOutlet="itemExtra ?? null; context: { group: group, item: item }"></ng-container>
            <ng-container
              *ngTemplateOutlet="itemExpander ?? null; context: { group: group, item: item }"
            ></ng-container>
          </ion-label>
          <ion-text class="ion-no-padding ion-text-end" slot="end" style="margin-top: 10px">
            <ng-container *ngTemplateOutlet="itemNote ?? null; context: { group: group, item: item }"></ng-container>
          </ion-text>
        </ion-item>
      </ion-list>
      <ng-container *ngTemplateOutlet="expandedDetail ?? null; context: { group: group, item: item }"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultItemTotal let-item="item">
  <ion-text
    color="gc-green"
    [class.muted]="item.hasAcceptedDeductions || item.changeStatus === 'DECLINED_WITH_REASON'"
    [attr.data-cy]="item.name + '-change-order-total'"
  >
    <strong>{{ item.pricePerUnit * item.numberOfUnits | currency }}</strong>
  </ion-text>
</ng-template>
