import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { StorageRefService } from '../services/storage-ref.service';
import { notOfType } from './not-of-type.operator';
import { STATE_RESTORED } from './storage-sync.actions';
import { DEFAULT_STORAGE_KEY, STORE_SYNC_CONFIG, StoreSyncConfig } from './storage-sync.config';
import { buildRestorableState, getStorableState } from './utils';


@Injectable()
export class StorageSyncEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly platform: Platform,
    private readonly store: Store,
    private readonly storageRef: StorageRefService,
    @Inject(STORE_SYNC_CONFIG)
    private config: StoreSyncConfig<any>,
  ) {}

  updateStateStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        notOfType(ROOT_EFFECTS_INIT, STATE_RESTORED, ...(this.config.ignoredActions || [])),
        filter(() => !!this.config.keys),
        withLatestFrom(
          this.store.select(state => state),
          this.actions$.pipe(ofType(STATE_RESTORED)),
        ),
        map(([action, currentState]) => getStorableState(currentState, this.config.keys)),
        switchMap(storableState =>
          from(this.storageRef.storage()).pipe(
            map(storage => storage.set(this.config.storageKey || DEFAULT_STORAGE_KEY, storableState)),
            tap({ error: err => console.error('Error saving state to storage', err) }),
          ),
        ),
      ),
    { dispatch: false },
  );

  restoreStateStorage$ = createEffect(() =>
    from(this.platform.ready()).pipe(
      // switchMap(() => this.storage.create()),
      filter(() => !!this.config.keys),
      switchMap(() =>
        from(this.storageRef.storage()).pipe(
          map(storage => storage.get(this.config.storageKey || DEFAULT_STORAGE_KEY)),
        ),
      ),
      map(foundState => (foundState ? buildRestorableState(foundState, this.config.keys) : {})),
      map(restoredState => STATE_RESTORED({ restoredState })),
    ),
  );
}
