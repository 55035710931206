import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Manager } from '../../../domains/users';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-select-project-manager-popover',
  templateUrl: './select-project-manager-popover.component.html',
  styleUrls: ['./select-project-manager-popover.component.scss'],
})
export class SelectProjectManagerPopoverComponent {
  projectManagers!: Manager[];
  workOrder!: WorkOrder;

  constructor(public popoverGC: PopoverController) {}
}
