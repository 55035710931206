import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faLock, faSun } from '@fortawesome/free-solid-svg-icons';
import { Installer } from '../../../domains/users';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';
import { Selectable } from '../../utils/types';

@Component({
  selector: 'gc-select-installer',
  templateUrl: './select-installer.component.html',
  styleUrls: ['./select-installer.component.scss'],
})
export class SelectInstallerComponent<T extends Installer> {
  @Input() multi = true;
  @Input() detail = true;
  @Input() installers?: Selectable<T>[];
  @Input() selected!: T['id'][];
  @Input() workOrder!: Partial<WorkOrder>;

  @Output() selectedChange = new EventEmitter<T['id'][]>();

  protected readonly faLock = faLock;
  protected readonly faSun = faSun;

  select(installer: Selectable<T>) {
    installer.selected ? this.emitRemoval(installer.item) : this.emitAdditional(installer.item);
  }

  emitRemoval(installer: T) {
    this.multi
      ? this.selectedChange.emit(this.selected.filter(id => id !== installer.id))
      : void 0;
  }

  emitAdditional(installer: T) {
    this.multi
      ? this.selectedChange.emit([...(this.selected ?? []), installer.id])
      : this.selectedChange.emit([installer.id]);
  }
}
