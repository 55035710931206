import { Entity, Key } from '@briebug/ngrx-auto-entity';

export class DwollaFundingSource {
  id!: string;
  link!: string;
  dwollaCustomerId!: string;
}

@Entity('DwollaAccount', { uriName: 'accounts' })
export class DwollaAccount {
  @Key id!: string;
  link!: string;
  enabled!: boolean;
  // Not sure what the statuses will be.
  status!: string;
  dwollaBalance!: string;

  installerTeamLeadId!: string;
  companyId!: number;

  dwollaFundingSources!: DwollaFundingSource[];
}
