import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-hammer-rating',
  templateUrl: './hammer-rating.component.html',
  styleUrls: ['./hammer-rating.component.scss'],
})
export class HammerRatingComponent {
  @Input() hammerRating!: number;
}
