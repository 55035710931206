import { AfterViewChecked, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';


let instanceCounter = 0;

@Component({
  selector: 'gc-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() name: string = `Content${++instanceCounter}`;
  bottomEdge = 0;

  constructor(private readonly elementRef: ElementRef) {}

  private readonly contentChanged = new Subject();
  private readonly heightAdjuster = this.contentChanged.asObservable().pipe(
    debounceTime(10),
    map(() => this.elementRef.nativeElement.querySelector('.bottomEdge')?.offsetTop),
    // tap(nextBottomEdge => console.log(`${this.name}/current:bottomEdge`, this.bottomEdge)),
    filter(nextBottomEdge => nextBottomEdge > 0),
    // tap(nextBottomEdge => console.log(`${this.name}/next:bottomEdge`, nextBottomEdge)),
  ).subscribe(nextBottomEdge => (this.bottomEdge = nextBottomEdge));

  private intervalRef: any;

  ngOnInit() {
    this.intervalRef = setInterval(() => this.contentChanged.next(null), 100);
  }

  ngOnDestroy() {
    clearInterval(this.intervalRef);
    this.heightAdjuster.unsubscribe();
  }

  ngAfterViewChecked() {
    this.contentChanged.next(null);
  }
}
