import { Pipe, PipeTransform } from '@angular/core';
import { WorkOrder } from 'src/app/domains/work/work-order/work-order.model';

type WorkOrderStatusStrings =
  | 'DRAFT'
  | 'PUBLISHED_AWAITING_RESPONSE'
  | 'PUBLISHED_WITH_RESPONSES'
  | 'AWARDED_NOT_STARTED'
  | 'IN_PROGRESS_NO_INVOICES'
  | 'IN_PROGRESS_HAS_INVOICES'
  | 'DONE_AWAITING_SETTLEMENT'
  | 'DONE'
  | 'CANCELLED'
  | 'DELETED';

@Pipe({ name: 'isStatus' })
export class IsStatusPipe implements PipeTransform {
  transform(workOrder?: WorkOrder, ...statuses: WorkOrderStatusStrings[]): boolean {
    return workOrder ? statuses.includes(workOrder.status) : false;
  }
}
