import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../shared/services/config.service';
import { StripeAccount, StripeAccountType, StripeLogin } from './models/stripe-responses';

@Injectable()
export class StripeService {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
  ) {}

  create(businessType: StripeAccountType) {
    return this.http.post<StripeAccount>(`${this.config.host}/stripe/accounts`, { businessType });
  }

  login() {
    return this.http.post<StripeLogin>(`${this.config.host}/stripe/login`, {});
  }
}
