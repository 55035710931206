<form [formGroup]="form">
  <ion-list>
    <ion-item>
      <ion-textarea
        formControlName="declinedReason"
        label="Reason for declining:"
        labelPlacement="stacked"
        class="form-control"
        rows="5"
        [placeholder]="reportsProgress ? 'Please provide a reason for declining this progress report.' : 'Please provide a reason for declining this invoice.'"
        [color]="submitted ? 'danger' : null"
      ></ion-textarea>
    </ion-item>
  </ion-list>

  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-right">
        <ion-button (click)="submit(form, invoice)" color="danger">
          <ion-icon name="ban" slot="start"></ion-icon>
            {{ reportsProgress ? 'Decline Progress Report' : 'Decline Invoice' }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
