import { createSelector } from '@ngrx/store';
import { workOrderCustomDates } from '../../domains/custom-dates';
import { detailedWorkOrder } from '../../domains/work/work-order/work-order.selectors';
import { currentWorkOrder } from '../../domains/work/work-order/work-order.state';
import { Direction, filterByOptionsOnKeyIfAvailable, sortByStartDuration, tryGet } from '../../shared/utils/func';

export const currentWorkOrderId = createSelector(currentWorkOrder, tryGet('id'));

export const currentWorkOrderIds = createSelector(currentWorkOrderId, id => [id]);

export const currentWorkOrderDates = createSelector(
  workOrderCustomDates,
  currentWorkOrderIds,
  filterByOptionsOnKeyIfAvailable('workOrderId'),
);

export const currentWorkOrderDatesSorted = createSelector(
  currentWorkOrderDates,
  sortByStartDuration(Direction.Ascending),
);
