import { compose } from '../compose.util';
import { iif } from './logic';
import { isInt, isNumeric } from './number';
import { split } from './string';

export const withURLPath = <R>(parse: (path: string) => R) => compose(decodeURI, split('?'), ([path]) => parse(path));
export const withURLQuery = <R>(parse: (query: [string, string][]) => R) =>
  compose(decodeURI, split('?'), ([, query]) =>
    parse((query?.split('&') ?? []).map(pair => pair.split('=') as [string, string])),
  );

export const urlPath = withURLPath(path => path);
export const urlQuery = withURLQuery(query => query);

export const queryMap = compose(urlQuery, Object.fromEntries);

export const dropLastSegment = withURLPath((path: string): string =>
  path
    .split('/')
    .slice(0, path.split('/').length - 1)
    .join('/'),
);

export const dropDateSegment = (url: string) => (lastSegmentIsInt(url) ? dropLastSegment(url) : url);

export const lastSegment = withURLPath((path: string): string => path.split('/')[path.split('/').length - 1]);
export const lastSegmentIsNumeric = compose(lastSegment, isNumeric);
export const lastSegmentIsInt = compose(lastSegment, isInt);

export const ensureDateParam = (url: string) =>
  compose(
    lastSegment,
    isInt,
    iif(
      value => value === true,
      () => url,
      () => dropLastSegment(url),
    ),
  );

export const joinPath = (url: string, path: string) => (url.endsWith('/') ? `${url}${path}` : `${url}/${path}`);
