import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

export interface ToastOptions {
  duration?: number;
  error?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  constructor(private readonly toasts: ToastController) {}

  async toastResult(message: string, header = '', options: ToastOptions = { duration: 5000, error: false }) {
    const { duration, error } = options;
    const toast = await this.toasts.create({
      header,
      message,
      buttons: [
        {
          text: 'Dismiss',
          side: 'end',
          role: 'cancel',
        },
      ],
      color: error ? 'danger' : 'dark',
      duration,
      position: 'bottom',
    });
    await toast.present();
    return toast.onDidDismiss();
  }
}
