import { IEntityDictionary, IEntityState } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { StatusCounts } from '~gc/domains/models/status-counts';
import { isAwardedToInstaller } from '../../shared/pipes/is-awarded-to-installer.pipe';
import { compose } from '../../shared/utils/compose.util';
import { view } from '../../shared/utils/ngrx';
import { authenticatedUser } from '../app/auth/auth.selectors';
import { AppState } from '../state';
import { InstallerTeamMember, isInstaller, isInstallerLead, isManager, isProjectManager, User } from '../users';
import { userEntities } from '../users/user/user.state';
import { WorkOrderStatusGroup } from './models/work-order-status-group';
import { WorkOrder, WorkOrderStatus } from './work-order/work-order.model';
import { detailedWorkOrders } from './work-order/work-order.selectors';
import { allWorkOrders, currentWorkOrder } from './work-order/work-order.state';
import { STATUS_GROUP_LABEL_MAP, STATUS_GROUP_MAP, STATUS_GROUP_ORDER_MAP, STATUS_GROUP_STATUS_MAP } from './work.maps';
import { currentStatusGroup } from './work.selectors';
import { StatusGroup } from './work.state';

// -----===[ UTILITY FUNCTIONS]===-----
// Utility functions decompose complex logic into simpler functions
// that are easier to reason about, unit test, and understand. They
// are often composed later on, often via a pipe(), for use in
// selectors, and perhaps also reductions. Effects may also be
// broken down into simpler functions using the rxjs pipe() method
// that may be imported from 'rxjs'.

export const userCanAccessPrivateJobName = (workOrder: WorkOrder, user: User) =>
  isManager(user) || (isInstallerLead(user) && isAwardedToInstaller(workOrder, user));

export const convertToStatusGroups = (workOrders: WorkOrder[] = []): string[] =>
  workOrders.map(workOrder => STATUS_GROUP_MAP[workOrder.status || 'drafts']);

export const aggregateStatusGroupCounts = (statuses: string[] = []): StatusCounts =>
  statuses.reduce(
    (groups: StatusCounts, status) => (
      (groups[status] = {
        group: status,
        count: (groups[status]?.count || 0) + 1,
        order: STATUS_GROUP_ORDER_MAP[status],
      }),
      groups
    ),
    {},
  );

export const fillMissingStatusGroups = (counts: StatusCounts = {}): StatusCounts =>
  (Object.keys(STATUS_GROUP_ORDER_MAP) as unknown as string[]).reduce(
    (groups, status) =>
      groups[status]
        ? groups
        : ((groups[status] = {
            group: status,
            count: 0,
            order: STATUS_GROUP_ORDER_MAP[status],
          }),
          groups),
    counts ?? {},
  );

export const distributeStatusGroups = (counts: StatusCounts = {}): WorkOrderStatusGroup[] =>
  (Object.keys(counts) as unknown as string[]).map(status => ({
    group: status,
    label: STATUS_GROUP_LABEL_MAP[status],
    status,
    count: counts[status].count,
    order: counts[status].order,
  }));

export const sortStatusGroups = (statusGroups: WorkOrderStatusGroup[] = []) =>
  statusGroups.sort((a, b) => a.order - b.order);

// -----===[ FUNCTION COMPOSITIONS ]===-----
// Simple, pure, i/o functions, when designed properly,
// may be composed with a pipe() in order to create
// more complex functionality. Breaking complex logic
// down into simple functions and composing this way
// is one of the fundamental underpinnings of FUNCTIONAL
// programming, and can help maintain a low complexity
// and easy to maintain code base.

// getWorkOrdersByStatus <- WorkOrder[]
export const getWorkOrdersByStatus = compose(
  convertToStatusGroups,
  aggregateStatusGroupCounts,
  fillMissingStatusGroups,
  distributeStatusGroups,
  sortStatusGroups,
);

// -----===[ GETTERS & PROJECTIONS ]===-----
// Getters retrieve data from the root app state, mappers
// project data and transform, reduce, join, group, etc.
// to provide specific "data shapes" to the rest of the application

// -----===[ GETTERS ]===-----
// Getters perform simple retrievals, basic data access off of a piece of state
export const getWorkOrderState = (appState: AppState): IEntityState<WorkOrder> => appState.workOrder;

// -----===[ MAPPERS ]===-----
// Mappers perform data transformation, taking input in one form, and converting
// it to output of another form.

export const mapToWorkOrdersByStatus = (workOrders: WorkOrder[] = []): WorkOrderStatusGroup[] =>
  getWorkOrdersByStatus(workOrders);

// -----===[ FILTERS ]===-----
// Filters, similar to mappers, perform data transformations, usually against
// data sets, and usually by filtering out unwanted entities from those data
// sets. Filters may also perform transformation, if necessary. Complex filtration
// functions might be best broken down into individual functions for each stage
// the process, and composed using either JS Array functions (.map, .filter,
// .reduce, etc.), or via a functional pipe().

export const filterWorkOrdersWhenProjectManager = <T extends WorkOrder>(workOrders: T[] = [], user?: User): T[] =>
  !!user && isProjectManager(user)
    ? workOrders.filter(({ projectManagerId }) => projectManagerId === user.id)
    : workOrders;

export const filterWorkOrdersByStatusGroup = <T extends WorkOrder>(
  workOrders: T[] = [],
  statusGroup?: StatusGroup,
): T[] =>
  !!statusGroup ? workOrders.filter(order => STATUS_GROUP_STATUS_MAP[statusGroup].includes(order.status)) : [];

export const extractUsersByShared = (users: IEntityDictionary<User>, workOrder?: WorkOrder): InstallerTeamMember[] =>
  workOrder ? ((workOrder.sharedWith?.map(id => users[id]) || []) as InstallerTeamMember[]) : [];

// -----===[ SELECTORS ]===-----
// Selectors compose getters, other selectors and projections
// to provide memoized copies of that data to the rest of the app.

export const workOrdersAllowedToUser = createSelector(
  allWorkOrders,
  authenticatedUser,
  filterWorkOrdersWhenProjectManager,
);

export const detailedWorkOrdersAllowedToUser = createSelector(
  detailedWorkOrders,
  authenticatedUser,
  filterWorkOrdersWhenProjectManager,
);

export const workOrdersWithUnapprovedChangeOrders = createSelector(
  detailedWorkOrdersAllowedToUser,
  workOrders => workOrders?.filter(wo => wo.hasUnapprovedChangeOrders) || [],
);

export const workOrdersWithPendingNegotiations = createSelector(
  detailedWorkOrdersAllowedToUser,
  authenticatedUser,
  (workOrders, user) =>
    (user && isInstaller(user)
      ? workOrders?.filter(wo => wo.installerHasNegotiationForReview)
      : workOrders?.filter(wo => wo.companyHasNegotiationForReview)) || [],
);

export const workOrdersAwaitingResponse = createSelector(
  detailedWorkOrdersAllowedToUser,
  workOrders => workOrders?.filter(wo => wo.status === WorkOrderStatus.PublishedAwaitingResponse),
);

export const completedWorkOrders = createSelector(
  detailedWorkOrdersAllowedToUser,
  workOrders =>
    workOrders?.filter(
      wo => wo.status === WorkOrderStatus.Done || wo.status === WorkOrderStatus.DoneAwaitingSettlement,
    ),
);

export const completedWorkOrdersWithoutKudos = createSelector(
  completedWorkOrders,
  workOrders =>
    workOrders?.filter(
      wo => wo.kudos === null || wo.kudos === undefined || wo.kudos.length === 0 || wo.kudos.every(k => k === null),
    ),
);

export const workOrdersRecentlyCompleted = createSelector(
  completedWorkOrdersWithoutKudos,
  workOrders => workOrders?.filter(wo => new Date(wo.updatedOn) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)),
);

export const sortedByDateEditedWorkOrdersAwaitingResponse = createSelector(
  workOrdersAwaitingResponse,
  workOrders => workOrders?.sort((a, b) => b.updatedOn?.localeCompare(a.updatedOn, undefined, { numeric: true }) ?? 0),
);

// This selector composes the allWorkOrders selector & the currentStatusGroup
// selector (from work.selectors) to filter the list of all work orders
// down to only the work orders that match the statuses of the current
// status group.
export const workOrdersByCurrentStatusGroup = createSelector(
  detailedWorkOrdersAllowedToUser,
  currentStatusGroup,
  view('workOrdersByCurrentStatusGroup', filterWorkOrdersByStatusGroup, false),
);

export const sharedWithTeamMembers = createSelector(userEntities, currentWorkOrder, extractUsersByShared);
