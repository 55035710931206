<ion-header translucent>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ enableSigning ? 'Sign' : 'View' }} Lien Release</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen *ngIf="workOrders.currentDetailedWorkOrder$ | push as workOrder">
  <ion-list lines="none">
    <ion-item class="ion-text-center">
      <h2>
        <strong>
          {{ workOrder.privateJobName }}
        </strong>
      </h2>
    </ion-item>

    <ion-item class="ion-text-center">
      <ion-label>
        {{ date | date: 'longDate' }}
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label class="ion-text-wrap ion-text-center">
        {{ workOrder.address?.addressLine1 }} <br />
        {{ workOrder.address?.city }}, {{ workOrder.address?.stateOrProvince }}
        {{ workOrder.address?.postalCode }}
      </ion-label>
    </ion-item>

    <gc-transaction-item
      *ngIf="companies.currentWorkOrderCompany$ | push as company"
      [avatar]="(auth.user$ | push | isInstaller) ? company!.logoUrl : workOrder!.awardedToInstaller!.profilePhoto"
      [name]="
        (auth.user$ | push | isInstaller)
          ? workOrder.companyName
          : workOrder.awardedToInstaller.firstName + ' ' + workOrder.awardedToInstaller.lastName
      "
      [amount]="invoice!.invoiceAmount"
    >
    </gc-transaction-item>

    <ion-item class="padding-top">
      <ion-text color="dark">
        We collect lien releases for every payment to protect you and the flooring contractor you’re working for.
      </ion-text>
    </ion-item>

    <ion-item *ngIf="enableSigning">
      <ion-label position="stacked">
        <h4>
          <strong>{{ 'Your Signature' | uppercase }}</strong>
        </h4>
      </ion-label>
      <gc-signature-pad #signature></gc-signature-pad>
    </ion-item>

    <ion-item *ngIf="!enableSigning">
      <ion-img [src]="lienReleaseFiles.signatureImage$ | push"></ion-img>
    </ion-item>

    <ion-list class="ion-padding">
      <ion-text>
        I understand that I am receiving full payment for this invoice and, as such, am waiving my right to hold lien on
        any aspect of this project for work invoiced here.
      </ion-text>
      <ion-item *ngIf="enableSigning" class="padding-top ion-text-wrap">
        <ion-checkbox slot="start" [(ngModel)]="lienReleaseAgreed" labelPlacement="end">
          I agree to the Lien Release terms below.
        </ion-checkbox>
      </ion-item>

      <ion-text>
        Your settlement amount will be less the applicable network processing fee based on the Settlement Timing you
        select on the following screen.
      </ion-text>
      <ion-item *ngIf="enableSigning">
        <ion-checkbox slot="start" [(ngModel)]="processingFeeAgreed" labelPlacement="end"> I understand.</ion-checkbox>
      </ion-item>
    </ion-list>

    <ion-item>
      <ion-label class="ion-text-wrap">
        <h2>
          <strong>
            {{ (template$ | push) ? 'Lien Release' : 'Pending Lien Release' }}
          </strong>
        </h2>

        <ion-text *ngIf="enableSigning">Please read the full terms below before signing.</ion-text>
        <ion-text *ngIf="!(template$ | push)">
          A Lien Release has not yet been assigned by the company regarding this invoice
        </ion-text>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label class="ion-text-wrap" color="dark">
        <span *ngIf="lienReleaseTemplate.isLoading$ | push; else lienReleaseContent" class="loader">Loading...</span>
        <ng-template #lienReleaseContent>
          {{ (template$ | push)?.content }}
        </ng-template>
      </ion-label>
    </ion-item>
  </ion-list>

  <ng-template #printContent>
    <div class="space-between" style="height: 100%">
      <div style="width: 50%">
        <img src="/assets/images/logo.svg" alt="Go Carrera" />

        <div style="padding-left: 0.75rem">
          <h1>Lien Release</h1>

          <p>{{ (template$ | push)?.content }}</p>
        </div>

        <img [src]="lienReleaseFiles.signatureImage$ | push" class="print-img" alt="Signature Image" />
      </div>
      <div style="width: 50%; padding: 1rem">
        <div class="content">
          <h3>Company</h3>
          <p>{{ workOrder.companyName }}</p>
        </div>

        <div class="content">
          <h3>Job Name</h3>
          <p>{{ workOrder.privateJobName }}</p>
        </div>

        <div class="content">
          <h3>Job Site Address</h3>
          <p>
            {{ workOrder.address?.addressLine1 }} <br />
            {{ workOrder.address?.city }}, {{ workOrder.address?.stateOrProvince }}
            {{ workOrder.address?.postalCode }}
          </p>
        </div>

        <div class="content">
          <h3>Installer</h3>
          <p>{{ workOrder.awardedToInstaller.firstName }} {{ workOrder.awardedToInstaller.lastName }}</p>
        </div>

        <div class="content">
          <h3>Invoice No.</h3>
          <p>{{ workOrder?.id || '' | uppercase | slice: 0 : 6 }}-{{ invoice?.id + '' | slice: 0 : 6 }}</p>
        </div>

        <div>
          <h3>Amount</h3>
          <p>{{ invoice.invoiceAmount | currency }}</p>
        </div>
      </div>
    </div>
  </ng-template>
</ion-content>

<ion-footer *ngIf="workOrders.currentDetailedWorkOrder$ | push as workOrder">
  <ion-toolbar *ngIf="template$ | push as template">
    <ion-grid *ngIf="companies.currentWorkOrderCompany$ | push as company">
      <ion-row>
        <ion-col>
          <ion-button
            *ngIf="enableSigning"
            mode="ios"
            color="gc-green"
            expand="block"
            [disabled]="isEmpty() || !lienReleaseAgreed || !processingFeeAgreed"
            (click)="invoices.selectInvoiceSettlementTiming(workOrder, invoice, signature.dataUrl)"
          >
            Continue
          </ion-button>

          <ion-button
            *ngIf="!enableSigning && !platform.is('mobile')"
            mode="ios"
            color="gc-green"
            expand="block"
            (click)="printer.openPrint(content)"
          >
            Print
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
