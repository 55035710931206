import { Component, Input } from '@angular/core';
import { Invoice } from '../../../domains/invoices';
import { DetailedInvoiceItem } from '../../../domains/models/detailed-invoice-item';
import { User } from '../../../domains/users';

@Component({
  selector: 'gc-view-progress-list',
  templateUrl: './view-progress-list.component.html',
  styleUrls: ['./view-progress-list.component.scss'],
})
export class ViewProgressListComponent {
  @Input() invoice!: Invoice;
  @Input() items?: DetailedInvoiceItem[];
  @Input() user?: User;
  @Input() permissions!: string[];
  @Input() role?: string;
}
