<div class="flex align-items-stretch title-container">
  <h1 class="modal-title" data-cy="overview-title">Overview</h1>
</div>
<div class="flex flex-col">
  <gc-installer-hammer-rating-charts [hammerRatingsDetails]="hammerRatingsDetails"></gc-installer-hammer-rating-charts>

  <ng-container *ngIf="installer.type === 'INSTALLER_TEAM_LEAD'">
    <gc-installer-kudos-details
      [installer]="installer"
      [kudosReasonsDetails]="kudosReasonsDetails"
    ></gc-installer-kudos-details>
  </ng-container>

  <gc-installer-product-installation-subtypes
    [productInstallationSubtypes]="productInstallationSubtypes"
    [canEditProfile]="canEditProfile"
    (subtypeRemoved)="subtypeRemoved.emit($event)"
    (subtypeAdded)="subtypeAdded.emit($event)"
  ></gc-installer-product-installation-subtypes>
</div>
