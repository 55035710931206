import { createAction, props } from '@ngrx/store';
import { Address } from '../../locations';
import { PendingFile } from '../../models/pending-file';
import { StripeAccountType } from '../../models/stripe-responses';
import { PhoneNumber } from '../../phone-number';
import { WorkingUser } from '../models/working-user';
import { AnyUser, User } from '../user/user.model';

export const editUserProfile = createAction('[Profile] Edited', props<{ showEditPhoto?: boolean }>());

export const addProfilePhoneNumber = createAction(
  '[Profile] (Phone Number) Added',
  props<{ phoneNumber: PhoneNumber }>(),
);

export const unapprovedInstallerWizardSubmitted = createAction(
  '[Profile] Unapproved Installer Wizard Submitted',
  props<{ phoneNumber: PhoneNumber; address: Partial<Address> }>(),
);

export const setupPayment = createAction('[Profile] (Payment) Setup', props<{ businessType: StripeAccountType }>());

export const managePayments = createAction('[Profile] (Payment) Manage');

export const viewProvider = createAction('[Profile] (Payment) View Provider');

export const stripeUrlRequestSuccess = createAction('[Profile] (Stripe) URL Request Success', props<{ url: string }>());

export const stripeUrlRequestError = createAction('[Profile] (Stripe) URL Request Error', props<{ error: any }>());

export const addProfileFile = createAction('[Profile] (File) Added', props<{ user: User; file: PendingFile }>());

export const setProfilePhoto = createAction('[Profile] (Photo) Added', props<{ user: User; file: PendingFile }>());

export const saveUserProfile = createAction(
  '[Profile] (User) Saved',
  props<{ user: Partial<AnyUser>; event?: ProfileSaveEvents }>(),
);

export const cancelEditProfile = createAction('[Profile] (Editing) Canceled', props<{ hasChanges: boolean }>());

export const confirmCancelEditProfile = createAction('[Profile] (Cancel Edit) Confirming');

export const cancelEditProfileConfirmed = createAction('[Profile] (Cancel Edit) Confirmed');

export const finishProfileEditing = createAction('[Profile] (Editing) Finished', props<{ user: AnyUser }>());

export enum ProfileSaveEvents {
  Standard = 'Standard',
  Vacation = 'Vacation',
  Unapproved = 'Unapproved',
}

export const correlateProfileSaved = createAction(
  '[Profile] (Save) Correlated',
  props<{ correlationId: string; event?: ProfileSaveEvents }>(),
);

export const profileEditingCompleted = createAction('[Profile] (Editing) Completed');

export const vacationStatusToggled = createAction('[Profile] (Vacation) Status Toggled');

export const vacationStatusSet = createAction('[Profile] (Vacation) Status Set');

export const profileEditInitiated = createAction(
  '[Profile] Profile Edit Initiated',
  props<{
    addressCorrelationId: string;
    phoneNumberCorrelationId: string;
    userCorrelationId: string;
  }>(),
);

export const continueToNextProfileEditStep = createAction(
  '[Profile] Continue to Next Profile Edit Step',
  props<{ workingUser: Partial<WorkingUser> }>(),
);

export const backOneProfileEditStep = createAction('[Profile] Go Back a Profile Edit Step');

export const completedInitiationOfEditingProfile = createAction('[Profile] Completed Initiation Of Editing Profile');

export const initializeWorkingUser = createAction(
  '[Profile] Initialize Working User',
  props<{ workingUser: Partial<WorkingUser> }>(),
);

export const updateWorkingUser = createAction(
  '[Profile] Update Working User',
  props<{ workingUser: Partial<WorkingUser> }>(),
);

export const saveInstallerProfile = createAction(
  '[Profile] Save Installer Profile',
  props<{ workingUser: Partial<WorkingUser> }>(),
);

export const loadUserProfile = createAction('[Profile] Load User Profile', props<{ user: User }>());
