import { createAction, props } from '@ngrx/store';
import { Claims } from './claims.model';

export const claimsAndTokenRetrieved = createAction(
  '[Auth Connect] (Claims and Token) Retrieved',
  props<{ claims: Claims; token: string }>(),
);

export const expiredAccessTokenFound = createAction('[Auth Connect] (Access Token) Found Expired');

export const accessTokenNearingExpiration = createAction('[Auth Connect] (Access Token) Nearing Expiration');

export const validAccessTokenFound = createAction('[Auth Connect] (Access Token) Found Valid');

export const authSessionRefreshed = createAction('[Auth Connect] (Session) Refreshed');

export const authSessionRefreshFailed = createAction('[Auth Connect] (Session) Refresh Failed');

export const offlineTokenFound = createAction('[Auth Connect] (Offline Access Token) Found');

export const unauthorizedUserIdentified = createAction('[Auth Connect] (Unauthorized User) Identified');

export const authenticationCompleted = createAction('[Auth Connect] (Authentication) Completed');

export const queueAuthRequest = createAction('[Auth] [Connect] Queue Auth Request', props<{ url: string }>());

export const authSessionExpired = createAction('[Auth Connect] (Session) Expired');
