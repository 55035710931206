import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { WorkOrder } from '../../work/work-order/work-order.model';
import { viewInvoice } from '../invoice-and-payment.actions';
import { Invoice } from '../invoice/invoice.model';
import { createInvoiceSuccess } from '../invoice/invoices.state';
import { uploadQueuedFiles } from './invoice-file.actions';
import { loadAllInvoiceFiles, queuedInvoiceFiles, uploadInvoiceFile } from './invoice-files.state';

@Injectable()
export class InvoiceFileEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  loadInvoiceFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewInvoice),
      map(({ invoice }) =>
        loadAllInvoiceFiles({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
              [uriNameOfEntityOrEmpty(Invoice)]: invoice.id,
            },
          },
        }),
      ),
    ),
  );

  initiateUploadQueuedInvoiceFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createInvoiceSuccess),
      withLatestFrom(this.store.select(queuedInvoiceFiles)),
      map(([{ entity }, files]) => uploadQueuedFiles({ invoice: entity, files })),
    ),
  );

  uploadQueuedInvoiceFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadQueuedFiles),
      switchMap(({ invoice, files }) =>
        // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
        files.map(pendingFile =>
          uploadInvoiceFile({
            pendingFile,
            criteria: {
              parents: {
                [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
                [uriNameOfEntityOrEmpty(Invoice)]: invoice.id,
              },
            },
          }),
        ),
      ),
    ),
  );
}
