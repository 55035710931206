import { Component, Input } from '@angular/core';
import { IonPopover, ModalController } from '@ionic/angular';
import { Category } from '../../../domains/training/category/category.model';
import { ProductInstallationTypeClaimed } from '../../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { SkillsAndExperienceFacade } from '../../../domains/training/skills-and-experience.facade';
import { TrainingFacade } from '../../../domains/training/training/training.facade';
import { Training } from '../../../domains/training/training/training.model';
import { TrainingClaimed } from '../../../domains/training/trainings-claimed/trainings-claimed.model';
import { createDatePickerTime } from '../../../shared/utils/utils';

@Component({
  selector: 'gc-claim-categorized-training-modal',
  templateUrl: './claim-categorized-training-modal.component.html',
  styleUrls: ['./claim-categorized-training-modal.component.scss'],
})
export class ClaimCategorizedTrainingModalComponent {
  @Input() claimedInstallType!: ProductInstallationTypeClaimed;
  @Input() category!: Category;

  training: Training = undefined as any;
  today = createDatePickerTime();

  constructor(
    public readonly modalGC: ModalController,
    public skillsAndExperience: SkillsAndExperienceFacade,
    public trainingsFacade: TrainingFacade,
  ) {}

  async chooseTraining(training: Training): Promise<void> {
    this.training = training;
  }

  async finalizeChoice(popoverGC: IonPopover, date: CustomEvent): Promise<boolean> {
    await popoverGC.dismiss();
    return this.modalGC.dismiss(
      {
        trainingId: this.training.id,
        categoryId: this.category.id,
        productInstallationTypeId: this.claimedInstallType.productInstallationTypeId,
        productInstallationTypeClaimedId: this.claimedInstallType.id,
        dateClaimed: date.detail.value,
        isVerified: false,
      } as TrainingClaimed,
      'ok',
    );
  }
}
