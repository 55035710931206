import { NgModule } from '@angular/core';
import { DefaultImageDirective } from './default-image.directive';
import { MaskDirective } from './mask.directive';
import { SlideOnClickDirective } from './slide-on-click.directive';
import { FileDropTargetDirective } from './file-drop-target.directive';

@NgModule({
  declarations: [MaskDirective, SlideOnClickDirective, DefaultImageDirective, FileDropTargetDirective],
  exports: [MaskDirective, SlideOnClickDirective, DefaultImageDirective, FileDropTargetDirective],
})
export class DirectivesModule {}
