import { createAction, props } from '@ngrx/store';
import {
  ProductInstallationTypeClaimed
} from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';

export const showClaimProductInstallationTypeModal = createAction(
  '[CORE: Installer Profile] (Show) Claim Product Installation Type Modal',
);

export const productInstallationTypeClaimed = createAction(
  '[CORE: Installer Profile] (Claimed) Product Installation Type',
  props<{ installType: ProductInstallationType; date: string }>(),
);

export const productInstallationTypeClaimRemoved = createAction(
  '[CORE: Installer Profile] (Removed) Product Installation Type',
  props<{ claim: ProductInstallationTypeClaimed }>(),
);

export const productInstallationTypeClaimRemovalConfirmed = createAction(
  '[CORE: Installer Profile] (Confirmed) Product Installation Type Claim Removal',
  props<{ claim: ProductInstallationTypeClaimed }>()
);

export const claimProductInstallationTypeModalCancelled = createAction(
  '[CORE: Installer Profile] (Cancelled) Claiming Product Installation Type',
);



export const claimProductInstallationTypeModalDone = createAction(
  '[CORE: Installer Profile] (Done) Claiming Product Installation Type',
);
