import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'PhoneNumber',
  uriName: 'phone-numbers',
})
export class PhoneNumber {
  @Key number!: string;
  userId!: string; // TODO: Figure out how to support composite key here...without breaking ability to use only number for actual entity key (since user id will usually be present in parent paths in urls)
  contactId?: number;
  type!: PhoneNumberType;
  consentMedium?: 'app' | 'in-person';
  consentAcquiredAt?: string;
  consentAcquiredVersion!: 1;
  isVerified!: boolean;

  verificationCode?: string; // Only for sending back to server to perform verification
}

export enum PhoneNumberType {
  Mobile = 'MOBILE',
  Office = 'OFFICE',
  Other = 'OTHER',
}
