import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'LienReleaseTemplate',
  uriName: 'lien-releases',
})
export class LienReleaseTemplate {
  @Key id?: number;
  companyId!: number;
  isActive!: boolean;
  name!: string;
  content!: string;
}
