<ion-chip
  data-cy="item-change-order-expander"
  style="transform: translateX(-4px);"
  color="gc-blue"
  *ngIf="(item | hasChangeOrders) && item.id !== expanded?.id"
  (click)="expand.emit(item)"
>
  <ion-label>Change Orders
    <ion-text *ngIf="item.uninvoicedChangeInfo.changedTotal > 0" data-cy="uninvoiced-change-total" color="danger">
      ({{ item.uninvoicedChangeInfo.changedTotal | currency }})<span
      *ngIf="item.uninvoicedChangeInfo.acceptedChangedTotal < item.uninvoicedChangeInfo.changedTotal">*</span>
    </ion-text>
  </ion-label>
  <ion-icon name="caret-down"></ion-icon>
</ion-chip>
