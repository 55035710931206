import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { exhaustMap, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ensureChildrenExist, ensureChildrenRequired } from '../../../shared/utils/rxjs';
import { AppUIService } from '../../app-ui.service';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { AppState } from '../../state';
import { currentWorkOrderMatchedInterestedInstallerCount } from '../../work-order-users.selectors';
import { reviewInstallers } from '../installer-review/installer-review.actions';
import { viewWorkOrder } from '../work-order-ui.actions';
import { WorkOrder } from '../work-order/work-order.model';
import { detailedWorkOrder } from '../work-order/work-order.selectors';
import { selectWorkOrderById } from '../work-order/work-order.state';
import { previewCalendarWorkOrder, reviewInstallerFromPreview } from './calendar.actions';
import { CalendarService } from './calendar.service';

export const combineFreshMatchingWorkOrder = <T extends { workOrderId?: string }, U extends WorkOrder | undefined>(
  source$: Observable<U>,
) =>
  switchMap((action: T) =>
    combineLatest([of(action), source$.pipe(filter(workOrder => action.workOrderId === workOrder?.id))]).pipe(take(1)),
  );

@Injectable()
export class CalendarEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private calendar: CalendarService,
    private appUI: AppUIService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  // NOTE: Used by NEW calendar currently, do not remove yet!!
  selectWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(previewCalendarWorkOrder),
      ensureChildrenRequired(value => !!value?.workOrderId),
      map(({ workOrderId }) =>
        // Calendar refreshes when work orders update, select by id loads that work order
        // So calendar updates the pending reschedule event, and reverting a pending reschedule
        // causes the calendar to show both the reverted and updated event, which are identical.
        selectWorkOrderById({
          key: workOrderId,
        }),
      ),
    ),
  );

  // NOTE: Used by NEW calendar currently, do not remove yet!!
  showWorkOrderCalendarPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(previewCalendarWorkOrder),
      combineFreshMatchingWorkOrder(this.store.select(detailedWorkOrder)),
      withLatestFrom(this.store.select(currentWorkOrderMatchedInterestedInstallerCount)),
      map(([[{ event }, workOrder], matchedInstallerCount]) => ({ event, workOrder, matchedInstallerCount })),
      ensureChildrenExist(value => !!value?.workOrder && !!value?.event),
      switchMap(({ event, workOrder, matchedInstallerCount }) =>
        this.calendar.showPreview(workOrder, matchedInstallerCount, event),
      ),
      filter(({ data }) => !!data),
      map(({ data, role }) =>
        role === 'review' ? reviewInstallerFromPreview({ workOrder: data }) : viewWorkOrder({ workOrder: data }),
      ),
    ),
  );

  proxyPreviewActionToStandard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewInstallerFromPreview),
      map(({ workOrder }) => reviewInstallers({ workOrder })),
    ),
  );
}
