import { createAction, props } from '@ngrx/store';
import {
  ProductInstallationSubtypesClaimed
} from '../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import { ProductInstallationSubtype } from '../../domains/training/product-installation-subtypes/product-installation-subtypes.model';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';

export const showClaimSubtypesModal = createAction(
  '[CORE: Installer Profile] (Show) Claim Product Installation Subtypes Modal',
  props<{ installType: ProductInstallationType }>(),
);

export const subtypeClaimed = createAction(
  '[CORE: Installer Profile] (Claimed) Product Installation Subtype',
  props<{ productInstallationSubtype: ProductInstallationSubtype }>(),
);

export const subtypeClaimRemoved = createAction(
  '[CORE: Installer Profile] (Product Installation Subtype Claim) Removed',
  props<{ subtypeClaim: ProductInstallationSubtypesClaimed }>(),
);

export const claimProductInstallationSubtypeModalCancelled = createAction('[CORE: Installer Profile] (Claim Product Installation Subtype) Cancelled');

export const claimProductInstallationSubtypeModalDone = createAction('[CORE: Installer Profile] (Claim Product Installation Subtype) Done');
