<ion-header>
  <ion-toolbar>
    <ion-title *ngIf="reportsProgress$ | push; else invoiceTitle">Decline Progress Report</ion-title>
    <ng-template #invoiceTitle>
      <ion-title >Decline Invoice</ion-title>
    </ng-template>
    <ion-buttons slot="start">
      <ion-button color="gc-green" (click)="modalGC.dismiss(null, 'cancel')">
        <ion-icon name="chevron-back-outline" slot="start"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <gc-decline-invoice-form
    [invoice]="invoice"
    [reportsProgress]="reportsProgress$ | push"
    (declined)="modalGC.dismiss($event, 'ok')"
  ></gc-decline-invoice-form>
</ion-content>
