<div class="red-caret" *ngIf="!!item && (item | hasNegotiations) && item.id === expanded?.id">
  <ion-card color="danger" style="margin-top: -1px">
    <ion-card-header class="ion-no-padding">
      <ion-toolbar color="danger">
        <ion-buttons slot="end">
          <ion-button (click)="collapsed.emit()">
            <ion-icon slot="icon-only" ios="close-circle" md="close-circle"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title style="padding-left: 15px; text-align: left; font-size: 1.5em; font-weight: bold"
          >Negotiations
        </ion-title>
      </ion-toolbar>
    </ion-card-header>

    <ion-list *ngIf="(item! | hasNegotiations) && item!.id === expanded?.id" class="negotiation-list">
      <ion-item
        *ngFor="let negotiation of item!.negotiations; last as last; trackBy: trackNegotiationBy"
        [lines]="last ? 'none' : undefined"
      >
        <ion-label>
          <h6>{{ negotiation.installer!.firstName }} {{ negotiation.installer.lastName }}</h6>
          <p class="current-bid">
            <span *ngIf="negotiation.currentAskPrice"
              >Bid:
              {{ negotiation.currentBidPrice | currency }}
              / {{ item!.unitOfMeasureSymbol }}</span
            >
          </p>
          <p class="current-ask">
            <span *ngIf="negotiation.currentAskPrice"
              >Current Ask:
              {{ negotiation.currentAskPrice | currency }}
              / {{ item!.unitOfMeasureSymbol }}</span
            >
          </p>
        </ion-label>
        <ion-note class="ion-no-margin" slot="end" style="margin-top: 10px">
          <span *ngIf="showTotals" style="font-size: 0.7rem">
            <strong [class.strike]="negotiation.isAcceptedByInstaller"
              ><ion-icon name="close" *ngIf="negotiation.isAcceptedByInstaller"></ion-icon
              ><ion-icon name="checkmark" color="gc-alt-green" *ngIf="!negotiation.isAcceptedByInstaller"></ion-icon>
              {{ negotiation.currentBidPrice * item!.numberOfUnits | currency
              }}<span *ngIf="negotiation.currentAskPrice"> / bid</span></strong
            >
            <br *ngIf="negotiation.currentAskPrice" />
            <strong [class.strike]="!negotiation.isAcceptedByInstaller"
              ><ion-icon name="close" *ngIf="!negotiation.isAcceptedByInstaller"></ion-icon
              ><ion-icon name="checkmark" color="gc-alt-green" *ngIf="negotiation.isAcceptedByInstaller"></ion-icon>
              {{ negotiation.currentAskPrice * item!.numberOfUnits | currency }} / ask</strong
            >
          </span>
          <ion-button
            *ngIf="!showTotals && reviewAllowed"
            fill="outline"
            color="danger"
            mode="ios"
            (click)="reviewed.emit(negotiation!.installer)"
          >
            Review
          </ion-button>
        </ion-note>
      </ion-item>
    </ion-list>
  </ion-card>
</div>
