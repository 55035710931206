import { CustomDate } from '~gc/domains/custom-dates';
import { Installer, ProjectManager } from '~gc/domains/users';
import { DetailedWorkOrder } from '~gc/domains/work/models/detailed-work-order';

export class WorkOrderDate extends CustomDate {
  workOrder?: DetailedWorkOrder;
  installer?: Installer;
  projectManager?: ProjectManager;
}

export const isWorkOrderDate = (date?: CustomDate): date is WorkOrderDate => date?.type === 'workorder';
