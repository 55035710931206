import { Entity, IEntityTransformer, Key } from '@briebug/ngrx-auto-entity';
import { Address } from '../../locations';
import { HammerRating } from '../hammer-rating/hammer-rating.model';

export const attachGcMembershipNumber: IEntityTransformer = {
  fromServer: data => ((data.gcMembershipNumber = 'GC00000'), data),
};

@Entity({
  modelName: 'User',
  uriName: 'users',
  transform: [attachGcMembershipNumber],
})
export class User {
  @Key id?: string;
  type!: UserType;
  gcNumber!: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  email!: string;
  profilePhoto?: string;
  addresses?: Address[];
  onVacationSince?: string | Date | null;
  chargesEnabled!: boolean;
  externalId?: string;
  deletedAt?: string | null;
}

export class RatedInstaller extends User {
  hammerRatings?: HammerRating[];
}

export class InstallerTeamMember extends RatedInstaller {
  override type!: 'INSTALLER_TEAM_MEMBER';
  installerTeamLeadId!: string;
}

export class Jumpstarter extends User {
  override type!: 'JUMPSTARTER';
  installerTeamLeadId!: string;
  hammerRatings!: HammerRating[];
  completedWorkOrders!: number;
  isApproved!: boolean;
  isHired!: boolean;
}

export class InstallerTeamLead extends RatedInstaller {
  override type!: 'INSTALLER_TEAM_LEAD';
  completedWorkOrders?: number;
  commercialWorkPercentage?: number;
  kudos?: number;
  badgeCount?: number;
  isApproved?: boolean;
  isUnion?: boolean;
  reputationScore?: number;
  reputationScoreLastUpdated?: string;
  businessName?: string;
  dob?: Date;
  ein?: string;
  bio?: string;
  teamsMaximumHammerRating?: [];
  installerTeamLeadId?: string;
  insuranceAgentName?: string;
  insuranceAgentPhone?: string;
  insuranceExpiration?: any;
  insurancePolicyNumber?: string;
  insuranceProvider?: string;
}

export class EmployeeInstaller extends RatedInstaller {
  override type!: 'EMPLOYEE_INSTALLER';
  companyId!: number;
}

export class ProjectManager extends User {
  override type!: 'COMPANY_PROJECT_MANAGER';
  companyId!: number;
  color!: string;
  phoneNumber?: string;
}

export class CompanyAdmin extends User {
  override type!: 'COMPANY_ADMIN';
  companyId!: number;
  color!: string;
}

export const USER_TYPES = {
  INSTALLER_TEAM_MEMBER: 'Installer Team Member',
  INSTALLER_TEAM_LEAD: 'Installer Team Lead',
  EMPLOYEE_INSTALLER: 'Company Employee Installer',
  COMPANY_PROJECT_MANAGER: 'Company Project Manager',
  COMPANY_ADMIN: 'Company Admin',
  JUMPSTARTER: 'Jumpstarter',
};

export type UserType = keyof typeof USER_TYPES;
export type InstallerType = keyof Pick<
  typeof USER_TYPES,
  'INSTALLER_TEAM_MEMBER' | 'INSTALLER_TEAM_LEAD' | 'EMPLOYEE_INSTALLER'
>;
export type ManagerType = keyof Pick<typeof USER_TYPES, 'COMPANY_PROJECT_MANAGER' | 'COMPANY_ADMIN'>;
export type CompanyUserType = keyof Pick<
  typeof USER_TYPES,
  'COMPANY_PROJECT_MANAGER' | 'COMPANY_ADMIN' | 'EMPLOYEE_INSTALLER'
>;
export type ContractorType = keyof Pick<typeof USER_TYPES, 'INSTALLER_TEAM_LEAD' | 'INSTALLER_TEAM_MEMBER'>;

export const InstallerTypes = ['INSTALLER_TEAM_MEMBER', 'INSTALLER_TEAM_LEAD', 'EMPLOYEE_INSTALLER'];
export const ManagerTypes = ['COMPANY_PROJECT_MANAGER', 'COMPANY_ADMIN'];
export const CompanyUserTypes = ['COMPANY_PROJECT_MANAGER', 'COMPANY_ADMIN', 'EMPLOYEE_INSTALLER'];
export const ContractorTypes = ['INSTALLER_TEAM_MEMBER', 'INSTALLER_TEAM_LEAD'];
export const TeamMemberTypes = ['INSTALLER_TEAM_MEMBER', 'JUMPSTARTER'];

export type AnyUser =
  | CompanyAdmin
  | EmployeeInstaller
  | InstallerTeamLead
  | InstallerTeamMember
  | ProjectManager
  | Jumpstarter;
export type Installer = InstallerTeamMember | InstallerTeamLead | EmployeeInstaller;
export type Manager = ProjectManager | CompanyAdmin;
export type CompanyUser = CompanyAdmin | ProjectManager | EmployeeInstaller;
export type Contractor = InstallerTeamLead | InstallerTeamMember;
export type TeamUser = InstallerTeamMember | Jumpstarter;

export const isUser = (user?: any): user is User => !!user?.type && Object.keys(USER_TYPES).includes(user.type);

export const isInstaller = (user?: User): user is Installer => !!user?.type && InstallerTypes.includes(user.type);

export const isInstallerLead = (user?: User): user is InstallerTeamLead =>
  user instanceof InstallerTeamLead || user?.type === 'INSTALLER_TEAM_LEAD';

export const isInstallerMember = (user?: User): user is InstallerTeamMember =>
  user instanceof InstallerTeamMember || user?.type === 'INSTALLER_TEAM_MEMBER';

export const isTeamMember = (user?: User): user is TeamUser =>
  user instanceof InstallerTeamMember ||
  user instanceof Jumpstarter ||
  (!!user?.type && TeamMemberTypes.includes(user.type));

export const isEmployeeInstaller = (user?: User): user is EmployeeInstaller =>
  user instanceof EmployeeInstaller || user?.type === 'EMPLOYEE_INSTALLER';

export const isJumpstarter = (user?: User): user is Jumpstarter =>
  user instanceof Jumpstarter || user?.type === 'JUMPSTARTER';

export const isCompanyUser = (user?: User): user is CompanyUser =>
  user instanceof CompanyAdmin ||
  user instanceof ProjectManager ||
  user instanceof EmployeeInstaller ||
  (!!user?.type && CompanyUserTypes.includes(user.type));

export const isProjectManager = (user?: User): user is ProjectManager =>
  user instanceof ProjectManager || user?.type === 'COMPANY_PROJECT_MANAGER';

export const isManager = (user?: User): user is Manager =>
  user instanceof CompanyAdmin || user instanceof ProjectManager || (!!user?.type && ManagerTypes.includes(user.type));

export const isAdmin = (user?: User): user is CompanyAdmin =>
  user instanceof CompanyAdmin || user?.type === 'COMPANY_ADMIN';

export const isContractor = (user?: User): user is Contractor =>
  user instanceof InstallerTeamLead ||
  user instanceof InstallerTeamMember ||
  (!!user?.type && ContractorTypes.includes(user.type));

export const compareByLastName = (a?: User, b?: User) =>
  a && b ? (a.lastName ?? '').localeCompare(b.lastName ?? '', undefined, { sensitivity: 'accent' }) : 0;

export const sortUsers = <T extends User>(users?: T[]): T[] => users?.sort(compareByLastName) ?? [];
