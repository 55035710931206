import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { AppState } from '../../state';
import { negotiationsCanceled } from '../negotiations.actions';
import { NegotiationItem } from './negotiation-item.model';
import { NegotiationItemFacadeBase } from './negotiation-item.state';

@Injectable()
export class NegotiationItemFacade extends NegotiationItemFacadeBase {
  constructor(private store: Store<AppState>) {
    super(NegotiationItem, store);
  }

  cancelNegotiation(workOrder: WorkOrder): void {
    this.store.dispatch(negotiationsCanceled({ workOrder }));
  }
}
