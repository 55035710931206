<div class="flex align-items-stretch title-container">
  <h1 class="modal-title" data-cy="details-title">Details</h1>
</div>
<ng-container *rxLet="context$; let context">
  <ion-list lines="none" *ngIf="context.installer.addresses.length > 0; else noAddresses">
    <ion-list-header>
      <h2 data-cy="address-title">Work Address</h2>
    </ion-list-header>
    <ion-item *ngFor="let address of context.installer.addresses" [attr.data-cy]="context.installer.firstName + '-address'">
      <ion-label class="ion-text-wrap" data-cy="installer-address">
        {{ address.addressLine1 }} <br />
        {{ address.addressLine2 }} <br />
        {{ address.city }}, {{ address.stateOrProvince }} {{ address.postalCode }}
      </ion-label>
    </ion-item>
  </ion-list>

  <gc-advanced-phone-number-manager
    [entries]="context.phoneNumberEntries"
    [canEdit]="context.canEditProfile"
    (added)="addPhoneNumber($event)"
    (removed)="removePhoneNumber($event)"
    (verified)="verifyPhoneNumber($event)"
    (refreshed)="refreshCode($event)"
  ></gc-advanced-phone-number-manager>
</ng-container>

<ng-template #noAddresses>
  <div class="alert alert__warning text-center m-20" data-cy="empty-address-area">No addresses found.</div>
</ng-template>
