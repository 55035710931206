import { Environment } from '../Environment.type';
import {
  announcementUserTypes,
  announcementVersion,
  apiVersion,
  maximumAllowableInvoiceAmount,
  maximumAllowableItemAmount,
  rootDomain,
  version,
} from './environment.const';

export const environment: Environment = {
  envWarningLabel: "STAGING",
  envWarningBG: '#0000ff',
  apiKeys: {
    tinyMce: 'zpe5gj70hfrsou4zqbe1asx2691kgqcfrwe1f3h6z8klez7m',
    fullCalendar: 'CC-Attribution-NonCommercial-NoDerivatives',
    streamChat: { web: 'jan26ygh5u77', mobile: 'jan26ygh5u77' },
    googleMaps: 'AIzaSyChaJFxWOMLXvatOtMalP2UJD6IH-5_z10',
    lynk: '02F6951B827D4851AC8853D4A366B746',
    amCharts: 'AM5C436749798'
  },
  production: false,
  dwolla: 'sandbox',
  plaid: 'sandbox',
  maximumAllowableInvoiceAmount,
  maximumAllowableItemAmount,
  logging: {
    level: 'trace',
  },
  announcementConfig: {
    version: announcementVersion,
    userTypes: announcementUserTypes,
  },
  version: {
    backend: '2.0.0',
    frontend: version,
  },
  apiRoot: {
    web: `https://api-staging.${rootDomain}${apiVersion}`,
    mobile: `https://api-staging.${rootDomain}${apiVersion}`,
    profiles: `https://staging-profiles.${rootDomain}/installers/`,
  },
  datadog: {
    applicationId: '6a1d7267-42f6-4ee6-8682-7fec73f6c7bb',
    clientToken: 'pub71ab4672f89606bf78f2ce9c183faab9',
    site: 'datadoghq.com',
    service: 'go-carrera',
    env: 'staging',
    version,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: ['https://api-staging.gocarrera.com'],
  },
  authWeb: {
    authConfig: 'auth0',
    platform: 'web',
    clientID: 'q5qt8lsWoxdLN2v6xTss8fOruhJX4Xdq',
    discoveryUrl: 'https://staging-gocarrera.us.auth0.com/.well-known/openid-configuration',
    redirectUri: `https://staging.${rootDomain}/login-success`,
    scope: 'openid offline_access email picture profile',
    audience: `https://api-staging.${rootDomain}`,
    logoutUrl: `https://staging.${rootDomain}`,
    implicitLogin: 'CURRENT',
  },
  authMobile: {
    authConfig: 'auth0',
    platform: 'capacitor',
    clientID: 'bTTm1NGz9vKTk36G7uQRYgsNnNg1kXAc',
    discoveryUrl: 'https://staging-gocarrera.us.auth0.com/.well-known/openid-configuration',
    redirectUri: 'com.gocarrera.app://login-success',
    scope: 'openid offline_access email picture profile',
    audience: `https://api-staging.${rootDomain}`,
    logoutUrl: 'com.gocarrera.app://logout',
    iosWebView: 'private',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBg5uJoOqZnN-bi9s7TzVjsWTqrqaSIROs',
    authDomain: 'go-carrera-staging.firebaseapp.com',
    projectId: 'go-carrera-staging',
    storageBucket: 'go-carrera-staging.appspot.com',
    messagingSenderId: '512236387703',
    appId: '1:512236387703:web:802e1b3d3cba457948b572',
    measurementId: 'G-VLTPRHT2SS',
  },
};
