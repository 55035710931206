import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DetailedInstallerProfileContext, detailedInstallerProfileContext } from '../../../../core/installer-profile';
import { installerCommercialWorkPercentage } from '../../../../core/installer-profile/installer-bio.selectors';
import { ProfileFacade, User } from '../../../../domains/users';
import { onlyNonNullish } from '../../../../shared/utils/rxjs';

@Component({
  selector: 'gc-background-area',
  templateUrl: './background-area.component.html',
  styleUrls: ['./background-area.component.scss'],
})
export class BackgroundAreaComponent implements OnInit {
  @Input() canEditProfile!: boolean;

  context$: Observable<DetailedInstallerProfileContext> = this.store
    .select(detailedInstallerProfileContext)
    .pipe(onlyNonNullish, tap(console.log));
  commercialWorkPercentage$: Observable<number | undefined> = this.store.select(installerCommercialWorkPercentage);
  percentCommercialForm!: UntypedFormControl;

  constructor(
    private readonly store: Store,
    private fb: FormBuilder,
    public profile: ProfileFacade,
  ) {
    this.commercialWorkPercentage$.pipe(take(1)).subscribe(percentCommercial => {
      this.percentCommercialForm = new UntypedFormControl(percentCommercial ?? 0);
    });
  }

  ngOnInit() {
    this.commercialWorkPercentage$.subscribe(percentCommercial =>
      this.percentCommercialForm.patchValue(percentCommercial ?? 0),
    );
  }

  save(user: User) {
    if (this.percentCommercialForm.valid) {
      this.profile.saveProfile({ ...user, commercialWorkPercentage: this.percentCommercialForm.value });
    }
  }
}
