import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'TermsAndConditions',
  uriName: 'terms-and-conditions',
})
export class TermsAndConditions {
  @Key id?: number;
  description!: string;
  isActive!: boolean;
}
