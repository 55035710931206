import { ActionReducerMap } from '@ngrx/store';
import { uiReducer, UIState } from './ui.state';


export interface ModalState {
  ui: UIState;
}

export const reducer: ActionReducerMap<ModalState> = {
  ui: uiReducer,
};
