import { createAction, props } from '@ngrx/store';
import { UserQuery } from '../../../core/invites/models/user-query.model';
import { InvitedUser, UserType } from '../../../domains/users';

export const emailAddressFieldChanged = createAction(
  '[MODAL: Company Invite] (Email Address Field) Changed',
  props<{ email: string; userType: UserType }>(),
);

export const companyInviteModalCanceled = createAction('[MODAL: Company Invite] (Modal) Canceled');

export const companyInviteModalSubmitted = createAction(
  '[MODAL: Company Invite] (Modal) Submitted',
  props<{ invite: Partial<InvitedUser>; user?: UserQuery }>(),
);
