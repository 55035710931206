import { AbstractControl } from '@angular/forms';
import { removeFromErrors } from './util';

export const normalizeDate = (date: string): number => new Date(date).setHours(6, 0, 0, 0);

// Sets the 'invalidDate' error when the comparator returns false. Sets as true on field unless error is defined.
export const compareDateFields =
  (field1: string, field2: string, comparator: (date1: number, date2: number) => boolean, error?: unknown) =>
  (control: AbstractControl) =>
    !!control.get(field1)?.value && !!control.get(field2)?.value
      ? comparator(normalizeDate(control.get(field1)!.value), normalizeDate(control.get(field2)!.value))
        ? (removeFromErrors(control.get(field1)!, 'invalidDate'), null)
        : (control.get(field1)!.setErrors({ invalidDate: error || true }), { invalidDate: true })
      : null;
