import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay, exhaustMap, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { routeEndsInPath } from '../../shared/utils/ngrx';
import { ensureExists } from '../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { IStatusGroup } from '../models/status-group';
import { AppState } from '../state';
import { isInstaller, isProjectManager } from '../users';
import { userEntities } from '../users/user/user.state';
import { selectedEmployeeInstallerProfile, showProjectManagerProfile } from '../users/users-ui.actions';
import { showUserProfile } from './work-order-ui.actions';
import {
  selectWorkArea,
  viewOrdersByStatus,
  viewPendingWorkOrders,
  viewSearchWorkOrders,
  viewWorkOrders,
} from './work.actions';
import { selectedWorkArea } from './work.selectors';

export const WORK_AREA_URL_MAP: Record<string, string> = {
  workOrders: '/app/work/work-orders',
  calendar: '/app/work/calendar',
  customCalendar: '/app/work/custom-calendar',
  map: '/app/work/map',
};

@Injectable()
export class WorkEffects implements OnRunEffects {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
    private nav: NavController,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  navigateToWorkArea$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectWorkArea),
        map(({ area }) => WORK_AREA_URL_MAP[area]),
        filter(url => !!url),
        tap(url => this.router.navigateByUrl(url)),
      ),
    { dispatch: false },
  );

  restoreWorkArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      routeEndsInPath('/work'),
      withLatestFrom(this.store.select(selectedWorkArea)),
      map(([, area]) => selectWorkArea({ area })),
    ),
  );

  navigateToWorkOrders$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewPendingWorkOrders, viewWorkOrders),
        map(() => WORK_AREA_URL_MAP['workOrders']),
        tap(url => this.router.navigateByUrl(url)),
      ),
    { dispatch: false },
  );

  navigateToPendingWorkOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewPendingWorkOrders),
      delay(50),
      map(() => viewOrdersByStatus({ status: { status: 'available_bids' } as IStatusGroup })),
    ),
  );

  viewOrdersByStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewOrdersByStatus),
        switchMap(() => this.nav.navigateForward(['app/work/orders'])),
      ),
    { dispatch: false },
  );

  navigateToSearch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewSearchWorkOrders),
        switchMap(() => this.nav.navigateForward('/app/work/orders/search')),
      ),
    { dispatch: false },
  );

  determineUserType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showUserProfile),
      withLatestFrom(this.store.select(userEntities)),
      map(([{ id }, users]) => users[id]),
      map(user =>
        isInstaller(user)
          ? selectedEmployeeInstallerProfile({ installer: user })
          : isProjectManager(user)
          ? showProjectManagerProfile({ projectManager: user })
          : undefined,
      ),
      ensureExists(action => !!action),
    ),
  );
}
