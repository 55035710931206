<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-title>View {{ context.invoiceType }}</ion-title>

    <ion-buttons slot="end" *ngIf="workOrders.current$ | push as workOrder">
      <ion-button
        *ngIf="context.options.showViewWorkOrder"
        color="dark"
        (click)="workOrders.viewWorkOrderFromModal(workOrder)"
      >
        <ion-icon name="eye" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button color="dark" *ngIf="!platform.is('mobile')" (click)="printer.openPrint(content)">
        <ion-icon name="print" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <gc-invoice-header
    [reportsProgress]="reportsProgress$ | push"
    [currentRole]="auth.role$ | push"
    [workOrder]="workOrders.currentDetailedWorkOrder$ | push"
    [invoice]="invoices.current$ | push"
    [invoiceNumberLabel]="context.invoiceNumberLabel"
    [company]="workOrderRelations.currentWorkOrderCompany$ | push"
    [installer]="installer$ | push"
  >
  </gc-invoice-header>

  <gc-view-invoice-list
    *ngIf="reportsProgress$ | push | not"
    [user]="auth.user$ | push"
    [invoice]="invoices.current$ | push"
    [items]="workOrderRelations.detailedInvoiceItemsForCurrentInvoice$ | push"
    [permissions]="auth.tokenPermissions$ | push"
    [role]="auth.role$ | push"
  ></gc-view-invoice-list>

  <gc-view-progress-list
    *ngIf="reportsProgress$ | push"
    [user]="auth.user$ | push"
    [invoice]="invoices.current$ | push"
    [items]="workOrderRelations.detailedInvoiceItemsForCurrentInvoice$ | push"
    [permissions]="auth.tokenPermissions$ | push"
    [role]="auth.role$ | push"
  ></gc-view-progress-list>

  <gc-invoice-declined-reason
    [invoice]="invoices.current$ | push"
    [reportsProgress]="reportsProgress$ | push"
  ></gc-invoice-declined-reason>

  <ng-container *ngIf="workOrders.currentDetailedWorkOrder$ | push as workOrder">
    <gc-file-manager
      *ngIf="invoices.current$ | push as invoice"
      [files]="invoiceFiles.allInvoiceFiles$ | push"
      [enableUpload]="auth.role$ | push | isRole: 'installerLead'"
      (fileAdded)="invoiceFiles.upload($event, workOrder, invoice)"
      (fileRetried)="invoiceFiles.retry($event, workOrder, invoice)"
      (fileDeleted)="invoiceFiles.remove($event, workOrder, invoice)"
    ></gc-file-manager>
  </ng-container>

  <ng-template *ngIf="workOrders.currentDetailedWorkOrder$ | push as workOrder" #printContent>
    <div *ngIf="invoices.current$ | push as invoice">
      <div>
        <img src="/assets/images/logo.svg" alt="Go Carrera" />

        <div style="padding-left: 0.75rem">
          <h1>Invoice</h1>
        </div>
      </div>

      <div class="block space-between">
        <div class="content">
          <h3>Company</h3>
          <p>{{ workOrder.companyName }}</p>
        </div>

        <div class="content">
          <h3>Job Name</h3>
          <p>{{ workOrder.privateJobName }}</p>
        </div>

        <div class="content">
          <h3>Installer</h3>
          <p>{{ workOrder.awardedToInstaller.firstName }} {{ workOrder.awardedToInstaller.lastName }}</p>
        </div>
      </div>

      <div class="block space-between">
        <div class="content">
          <h3>Job Site Address</h3>
          <p>
            {{ workOrder.address?.addressLine1 }} <br />
            {{ workOrder.address?.city }}, {{ workOrder.address?.stateOrProvince }}
            {{ workOrder.address?.postalCode }}
          </p>
        </div>

        <div class="content">
          <h3>Status</h3>
          <p>{{ invoice.status | invoiceStatusLabel }}</p>
        </div>

        <div class="content">
          <h3>{{ context.invoiceType }}</h3>
          <p>{{ workOrder?.id || '' | uppercase | slice: 0 : 6 }}-{{ invoice?.id + '' | slice: 0 : 6 }}</p>
        </div>
      </div>

      <div class="block" style="margin-top: 0.5rem">
        <h3>Items</h3>
        <div
          *ngFor="let item of workOrderRelations.detailedInvoiceItemsForCurrentInvoice$ | push"
          style="border-bottom: 1px solid black; padding-top: 4px; padding-bottom: 4px"
        >
          <div class="space-between">
            <div>
              <span>
                <strong>{{ item.workOrderItem.name }}</strong>
              </span>
              <br />
              <span> {{ item.numberOfUnits }} @ {{ item.workOrderItem.pricePerUnit | currency }} </span>
              / <span class="uom">{{ item.workOrderItem?.unitOfMeasureSymbol }}</span>
            </div>

            <span>
              <strong>{{ item.workOrderItem.pricePerUnit * item.numberOfUnits | currency }}</strong>
            </span>
          </div>

          <div *ngFor="let changeOrder of item?.changeOrders" class="space-between" style="margin-top: 4px">
            <div>
              <span>
                <strong>{{ changeOrder.name }}</strong>
              </span>
              <br />
              <span> {{ changeOrder.adjustedNumberOfUnits }} @ {{ changeOrder.adjustedPricePerUnit | currency }} </span>
              / <span class="uom">{{ item.workOrderItem?.unitOfMeasureSymbol }}</span>
            </div>

            <span>
              <strong>-{{ changeOrder.adjustedPricePerUnit * changeOrder.adjustedNumberOfUnits | currency }}</strong>
            </span>
          </div>

          <div class="space-between">
            <span></span>
            <span>{{ item.invoiceItemAmount | currency }}</span>
          </div>
        </div>
      </div>

      <div class="block" style="margin-top: 0.5rem">
        <div class="content space-between">
          <strong>Total Amount</strong>
          <strong>{{ invoice.invoiceAmount | currency }}</strong>
        </div>
        <div
          class="content space-between"
          *ngIf="(auth.user$ | push | isInstallerTeamLead) && invoice.settlementAmount"
        >
          <strong>Expected Settlement Amount</strong>
          <strong>{{ invoice.settlementAmount | currency }}</strong>
        </div>
      </div>
    </div>
  </ng-template>
</ion-content>

<ion-footer class="ion-text-right">
  <gc-approve-invoice-button
    *ngIf="auth.role$ | push | isRole: 'companyAdmin' : 'companyManager'"
    [reportsProgress]="reportsProgress$ | push"
    [invoice]="invoices.current$ | push"
    (approved)="invoiceAndPayment.approveInvoice($event)"
    (declined)="invoiceAndPayment.declineInvoice($event)"
  >
  </gc-approve-invoice-button>

  <ng-container *ngIf="invoices.current$ | push as invoice">
    <ion-button
      *ngIf="(invoice | isInvoiceStatus: 'APPROVED_PENDING_PAYMENT') && (auth.role$ | push | isRole: 'companyAdmin')"
      (click)="invoiceAndPayment.payInvoice(invoice)"
      color="gc-green"
      mode="ios"
      class="ion-margin"
    >
      Pay Invoice
      <ion-icon name="logo-usd" slot="end"></ion-icon>
    </ion-button>
  </ng-container>
</ion-footer>
