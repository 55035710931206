import { createReducer, on } from '@ngrx/store';
import { DetailedProductInstallationTypeClaimed, showEnhancedInstallerProfile } from '../../../core/installer-profile';
import {
  backgroundSelected,
  complianceSelected,
  defaultClaimSelected,
  detailsSelected,
  installerCalled,
  installerWebsiteVisited,
  installTypeClaimSelected,
  optionsMenuOpened,
  overviewSelected,
  trainingSelected,
} from '../view-installer-profile-enhanced.actions';

export enum InstallerProfileAreas {
  Overview = 'overview',
  Background = 'background',
  Compliance = 'compliance',
  Training = 'training',
  Details = 'details',
}

export interface UIState {
  openPhoneModal: boolean;
  visitInstallerWebsite: boolean;
  additionalOptionsMenuOpen: boolean;
  selectedArea: InstallerProfileAreas;
  selectedClaim?: DetailedProductInstallationTypeClaimed;
}

export const initialUIState: UIState = {
  openPhoneModal: false,
  visitInstallerWebsite: false,
  additionalOptionsMenuOpen: false,
  selectedArea: InstallerProfileAreas.Overview,
};

export const uiReducer = createReducer(
  initialUIState,
  on(installerCalled, (state: UIState, { installer }): UIState => ({ ...state, openPhoneModal: true })),
  on(installerWebsiteVisited, (state: UIState, { installer }): UIState => ({ ...state, visitInstallerWebsite: true })),
  on(optionsMenuOpened, (state: UIState, { installer }): UIState => ({ ...state, additionalOptionsMenuOpen: true })),
  on(overviewSelected, (state: UIState): UIState => ({ ...state, selectedArea: InstallerProfileAreas.Overview })),
  on(backgroundSelected, (state: UIState): UIState => ({ ...state, selectedArea: InstallerProfileAreas.Background })),
  on(complianceSelected, (state: UIState): UIState => ({ ...state, selectedArea: InstallerProfileAreas.Compliance })),
  on(trainingSelected, (state: UIState): UIState => ({ ...state, selectedArea: InstallerProfileAreas.Training })),
  on(detailsSelected, (state: UIState): UIState => ({ ...state, selectedArea: InstallerProfileAreas.Details })),
  on(
    installTypeClaimSelected,
    defaultClaimSelected,
    (state: UIState, { claim }): UIState => ({ ...state, selectedClaim: claim }),
  ),
  on(
    showEnhancedInstallerProfile,
    (state: UIState): UIState => ({ ...state, selectedArea: InstallerProfileAreas.Overview }),
  ),
);
