import { createReducer, on } from '@ngrx/store';
import { inviteProcessAborted, inviteProcessCompleted } from '../invites.actions';
import { UserQuery } from '../models/user-query.model';
import { lookupUser, userLookupFailed, userLookupSucceeded } from './lookups.actions';

export interface LookupsState {
  email?: string;
  pending: boolean;
  found?: UserQuery;
}

export const initialState: LookupsState = {
  pending: false,
};

export const lookupsReducer = createReducer(
  initialState,
  on(lookupUser, (state, { email }) => ({ ...state, pending: true, email, found: undefined })),
  on(userLookupSucceeded, (state, { user }) => ({ ...state, pending: false, found: user })),
  on(userLookupFailed, inviteProcessCompleted, inviteProcessAborted, state => ({
    ...state,
    pending: false,
    found: undefined,
  })),
);
