<ion-grid *ngIf="!horizontal">
  <ion-row class="ion-justify-content-center">
    <ion-img *ngIf="imagePath; else infoIcon" [src]="imagePath"></ion-img>
    <ng-template #infoIcon>
      <ion-icon class="info-icon" name="information-circle"></ion-icon>
    </ng-template>
  </ion-row>
  <ion-row class="ion-padding ion-text-center ion-align-items-center ion-justify-content-center">
    <p>
      {{ message }}
    </p>
  </ion-row>
</ion-grid>

<ion-grid *ngIf="horizontal">
  <ion-row>
    <ion-col size="2" class="ion-justify-content-center">
      <ion-icon class="info-icon" name="information-circle"></ion-icon>
    </ion-col>
    <ion-col size="10" class="fles ion-align-items-center ion-justify-content-start">
      <p>
        {{ message }}
      </p>
    </ion-col>
  </ion-row>
</ion-grid>
