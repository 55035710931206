<form [formGroup]="form">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size-lg="4" class="ion-padding">
        <h2><b>Publish your Work Order</b></h2>
        <p>
          Need an Installer with a certain level of skill? Set the Hammer Rating Threshold to help us match your work
          with the right Installer.
        </p>
      </ion-col>

      <ion-col size="12" size-sm>
        <ion-range
          [disabled]="form.workOrderStatus | editMode: 'partial'"
          label="Hammer Rating Threshold"
          label-placement="fixed"
          mode="ios"
          snaps="true"
          pin="true"
          ticks="true"
          max="5.0"
          [pinFormatter]="actualValue"
          step="0.5"
          formControlName="minimumHammerRatingThreshold"
        ></ion-range>

        <ion-range
          [disabled]="form.workOrderStatus | editMode: 'partial'"
          label-placement="fixed"
          label="Publish Radius"
          mode="ios"
          min="10"
          step="10"
          max="500"
          pin="true"
          formControlName="radius"
        >
          >
        </ion-range>

        <ion-item [disabled]="form.workOrderStatus | editMode: 'partial'" class="ion-margin-top">
          <!--          <ion-label position="floating" [color]="form.validated && form.lienRelease.invalid ? 'danger' : null">-->
          <!--            Select a Lien Release-->
          <!--          </ion-label>-->
          <ion-select data-cy="lien-release-select" formControlName="lienReleaseTemplateId" label="Select a Lien Release" labelPlacement="stacked">
            <ion-select-option *ngFor="let template of lienReleaseTemplates" [value]="template.id">
              {{ template.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item lines="none">
          <ion-label position="stacked">Tags</ion-label>
        </ion-item>

        <form [formGroup]="tagForm" (ngSubmit)="addTag()">
          <ion-item lines="full">
            <ion-input formControlName="value"></ion-input>
            <ion-button slot="end" type="submit" fill="clear" color="dark" (click)="addTag()">Apply</ion-button>
          </ion-item>
        </form>
        <ion-text style="font-size: 11px; padding: 10px 20px; display: inline-block"
        >Tag your Work Orders by project, client, status, or however you like to help better manage your work. You can
          search by tag later.
        </ion-text>
        <div class="ion-margin">
          <ion-chip *ngFor="let tag of form.tags.tagForms; let i = index">
            <ion-label>{{ tag.value.value }}</ion-label>
            <ion-icon name="close" (click)="removeTag(i)"></ion-icon>
          </ion-chip>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
