import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { InvoiceStatus } from '..';

@Entity({
  modelName: 'InvoiceItem',
  uriName: 'items',
  comparer: (a, b) => a.id - b.id,
})
export class InvoiceItem {
  @Key id?: number;
  invoiceId!: number;
  workOrderId!: string;
  workOrderItemId!: string;
  changeOrderIds!: string[];
  changeOrderItemId!: string;
  numberOfUnits!: number;
  capturedUnitsRemaining?: number;
  invoiceItemAmount!: number;
  status!: InvoiceStatus;
}
