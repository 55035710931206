import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AreaSegmentsComponent } from './area-segments.component';

@NgModule({
  declarations: [AreaSegmentsComponent],
  imports: [CommonModule, IonicModule],
  exports: [AreaSegmentsComponent],
})
export class AreaSegmentsModule {}
