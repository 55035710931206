import { Component, TemplateRef } from '@angular/core';

@Component({
  selector: 'gc-print',
  template: `
    <div>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  `,
})
export class PrintComponent {
  content!: TemplateRef<any>;
}
