<ion-grid>
  <ion-row>
    <ion-col>
      <h1>Photo & Bio</h1>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <gc-profile-picture-form
        [validated]="validated"
        [form]="form"
        [user]="user"
        (photoChanged)="photoChanged.emit($event)"
      ></gc-profile-picture-form>
      <form [formGroup]="form" data-cy="installer-editor-bio-input">
        <ion-textarea
          data-e2e="bio-input"
          formControlName="bio"
          fill="outline"
          labelPlacement="stacked"
          label="Background Information"
          placeholder="Share a bit about your background in flooring and we'll add it to your profile."
          [rows]="10"
          class="ion-padding"
        ></ion-textarea>
      </form>
    </ion-col>
  </ion-row>
</ion-grid>
