import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { FileUploadProgressPopoverComponent } from './file-upload-progress-popover.component';

@NgModule({
  imports: [CommonModule, IonicModule, PushModule],
  declarations: [FileUploadProgressPopoverComponent],
  providers: [],
})
export class FileUploadProgressPopoverModule {}
