import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { WorkOrderForm, WorkOrderItemForm, WorkOrderItemFormArray } from '../draft-work-order.form';

@Component({
  selector: 'gc-save-next',
  templateUrl: './save-next.component.html',
  styleUrls: ['./save-next.component.scss'],
})
export class SaveNextComponent {
  @Input() form!: WorkOrderForm;
  @Input() step!: string;
  @Input() showNext = true;
  @Input() isSaving = false;
  @Input() itemForm!: WorkOrderItemForm;
  @Input() isStepInitializing = false;

  @Output() next = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() publish = new EventEmitter();
  @Output() republish = new EventEmitter();


}
