import { createAction, props } from '@ngrx/store';
import { SettlementTiming } from '~gc/domains/invoices/settlement-timing/settlement-timing.entity';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { Invoice } from '../invoice/invoice.model';

export const authorizeLienRelease = createAction(
  '[Lien Release] Authorizing',
  props<{ workOrder: WorkOrder; invoice: Invoice; dataUrl: string; settlementTiming?: SettlementTiming }>(),
);

export const lienReleaseSuccessfullySigned = createAction(
  '[Lien Release] Successfully Signed',
  props<{ invoice: Invoice }>(),
);

export const lienReleaseSigningFailed = createAction(
  '[Lien Release] Signing Failed',
  props<{ invoice: Invoice; error: any }>(),
);

export const lienReleaseCompleted = createAction('[Lien Release] Completed', props<{ invoice: Invoice }>());
