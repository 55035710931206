import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { invoiceListEndReached, searchCriteriaUpdated, viewInvoicesByStatus } from '../invoice-and-payment.actions';
import { INVOICE_STATUS_GROUP_LABEL_MAP } from '../invoice-and-payment.maps';
import { Invoice } from './invoice.model';

// -----===[ SUPPORT TYPES ]===-----
export type InvoiceStatusGroup = keyof typeof INVOICE_STATUS_GROUP_LABEL_MAP;

export interface InvoicesState extends IEntityState<Invoice> {
  isLoadingFresh: boolean;
  currentPage: { page: number; size: number };
}

export const {
  initialState: initialInvoiceState,
  facade: InvoicesFacadeBase,
  selectors: {
    selectAll: allInvoices,
    selectAllSorted: sortedInvoices,
    selectEntities: invoiceEntities,
    selectCurrentEntity: currentInvoice,
    selectCurrentPage: currentInvoicesPage,
  },
  actions: {
    load: loadInvoice,
    loadSuccess: loadInvoiceSuccess,
    loadFailure: loadInvoiceFailure,
    loadAll: loadAllInvoices,
    loadAllSuccess: loadAllInvoicesSuccess,
    loadAllFailure: loadAllInvoicesFailure,
    loadMany: loadManyInvoices,
    loadManySuccess: loadManyInvoicesSuccess,
    loadManyFailure: loadManyInvoicesFailure,
    update: updateInvoice,
    updateSuccess: updateInvoiceSuccess,
    updateFailure: updateInvoiceFailure,
    select: selectInvoice,
    selected: invoiceSelected,
    deselect: deselectInvoice,
    create: createInvoice,
    createSuccess: createInvoiceSuccess,
    createFailure: createInvoiceFailure,
    deleteSuccess: deleteInvoiceSuccess,
  },
} = buildState(Invoice, { currentPage: { page: 1, size: 25 }, isLoadingFresh: false });

const reduce = createReducer(
  initialInvoiceState,
  on(loadAllInvoices, state => ({ ...state, isLoadingFresh: true })),
  on(loadAllInvoicesSuccess, loadAllInvoicesFailure, state => ({ ...state, isLoadingFresh: false })),
  on(invoiceListEndReached, state => ({
    ...state,
    currentPage: { ...state.currentPage, page: (state.currentPage?.page ?? 1) + 1 },
  })),
  on(viewInvoicesByStatus, searchCriteriaUpdated, state => ({
    ...state,
    currentPage: { ...state.currentPage, page: 1 },
  })),
);

export function invoiceReducer(state = initialInvoiceState, action: Action): InvoicesState {
  return reduce(state, action);
}

export const invoicesState = createFeatureSelector<InvoicesState>('invoice');
export const isLoadingFresh = createSelector(invoicesState, state => state.isLoadingFresh);
