import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { QRCodeModule } from 'angularx-qrcode';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatchingStatesPipe } from '~gc/shared/components/forms-ui/matching-states.pipe';
import { PipesModule } from '../../pipes/pipes.module';
import { AddressFormComponent } from './address-form/address-form.component';
import { BasicProfileComponent } from './basic-profile/basic-profile.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { HexColorPipe } from './hex-color.pipe';
import { ImageCropperPopoverComponent } from './profile-picture-form/image-cropper-popover/image-cropper-popover.component';
import { ProfilePictureFormComponent } from './profile-picture-form/profile-picture-form.component';

@NgModule({
  imports: [CommonModule, IonicModule, PipesModule, PushModule, QRCodeModule, ImageCropperModule, ReactiveFormsModule],
  declarations: [
    AddressFormComponent,
    ColorPickerComponent,
    BasicProfileComponent,
    ProfilePictureFormComponent,
    ImageCropperPopoverComponent,
    HexColorPipe,
    MatchingStatesPipe,
  ],
  exports: [
    AddressFormComponent,
    ColorPickerComponent,
    BasicProfileComponent,
    ProfilePictureFormComponent,
    ImageCropperPopoverComponent,
    HexColorPipe,
    MatchingStatesPipe,
  ],
})
export class FormUiModule {}
