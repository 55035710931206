import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'TermsAndConditionsAccepted',
  uriName: 'terms-and-conditions-accepted',
})
export class TermsAndConditionsAccepted {
  @Key id?: number;
  accepted!: boolean;
  comments?: string;
  termsAndConditionsID!: number;
}
