import { AfterViewInit, Component, NgZone, ViewChild } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { IonMenu, IonRouterOutlet, MenuController, Platform } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ChannelService } from 'stream-chat-angular';
import { AuthFacade } from '~gc/domains/app/auth/auth.facade';
import { LogService } from '~gc/shared/services/log.service';
import { environment } from '../environments/environment';
import { AppFacade } from './domains/app/app.facade';
import { RouterOutletService } from './domains/router-outlet.service';
import { WorkOrderChatFacade } from './domains/work/work-order-chat/work-order-chat.facade';
import { SidebarService } from './shared/services/sidebar.service';

const TAGS = ['Component', 'App'];

@Component({
  selector: 'gc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild(IonRouterOutlet) routerOutlet?: IonRouterOutlet;
  @ViewChild('chatMenu') chatMenu?: IonMenu;
  fullScreen = false;
  menuOpen$: Observable<{ open: boolean }>;
  hideSidebar = false;

  envWarningLabel = environment.envWarningLabel;
  envWarningText = environment.envWarningText;
  envWarningBG = environment.envWarningBG;

  constructor(
    private platform: Platform,
    private app: AppFacade,
    private zone: NgZone,
    private log: LogService,
    public auth: AuthFacade,
    private routerOutletService: RouterOutletService,
    public readonly chat: WorkOrderChatFacade,
    public readonly channelService: ChannelService,
    private menu: MenuController,
    private sidebarService: SidebarService,
  ) {
    App.addListener('appUrlOpen', data => {
      zone.run(() => {
        log.debug(TAGS, 'App URL Open', data?.url);
        const pathAndQuery = data?.url?.split('gocarrera.com').pop();
        log.debug(TAGS, 'App URL Open - Path and Query', pathAndQuery);
        if (pathAndQuery) {
          auth.queueAuthRequest(pathAndQuery);
        }
      });
    });

    from(platform.ready())
      .pipe(
        tap(() => log.info(TAGS, 'Platform is ready!')),
        tap(() => (Capacitor.isNativePlatform() ? StatusBar?.hide() : void 0)),
      )
      .subscribe(() => app.initialize());

    this.menuOpen$ = this.chat.menuOpen$.pipe(map(open => ({ open })));

    this.sidebarService.hideSidebar$.subscribe(hide => {
      this.hideSidebar = hide;
    });
  }

  ngAfterViewInit() {
    this.routerOutletService.routerOutlet = this.routerOutlet;
  }

  toggleSidebar() {
    this.sidebarService.setHideSidebar(!this.hideSidebar);
  }

  async navigate(url: string) {
    this.fullScreen = false;
    this.app.navigateRoot(url);
    await this.menu.close('main');
  }
}
