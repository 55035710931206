import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { debounceTime, exhaustMap, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { GoogleMapsService } from '../../../shared/services/google-maps.service';
import { routeEndsInPath } from '../../../shared/utils/ngrx';
import { AppState } from '../../state';
import { loadAllWorkOrders } from '../work-order/work-order.state';
import { currentLatLong, mapsApiLoaded, searchRadius } from './work-order-map.selectors';
import {
  googleMapsApiLoaded,
  loadWorkOrdersForNewBounds,
  mapBoundsChanged,
  mapPlaceFound,
  workOrderMapRouteVisit,
} from './work-order-map.state';

@Injectable()
export class WorkOrderMapEffects {
  constructor(
    private readonly actions$: Actions,
    private store: Store<AppState>,
    private httpClient: HttpClient,
    private googleMaps: GoogleMapsService,
  ) {}

  listenForRouteVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeEndsInPath('map'),
      map(() => workOrderMapRouteVisit()),
    ),
  );

  // /work-orders/proximity?latitude=y,longitude=x,rangeInMeters=1000
  loadWorkOrdersOnMapVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderMapRouteVisit, mapPlaceFound, loadWorkOrdersForNewBounds),
      switchMap(() =>
        this.store.select(currentLatLong).pipe(
          filter(coords => !!coords),
          take(1),
        ),
      ),
      withLatestFrom(this.store.select(searchRadius)),
      map(([pos, radius]) =>
        loadAllWorkOrders({
          criteria: {
            param: 'proximity',
            query: {
              latitude: pos.lat,
              longitude: pos.lng,
              rangeInMeters: radius,
            },
          },
        }),
      ),
    ),
  );

  loadWorkOrdersOnBoundsChange = createEffect(() =>
    this.actions$.pipe(
      ofType(mapBoundsChanged),
      debounceTime(1000),
      map(() => loadWorkOrdersForNewBounds()),
    ),
  );

  loadMapsApi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderMapRouteVisit),
      withLatestFrom(this.store.select(mapsApiLoaded)),
      filter(([, loaded]) => !loaded),
      exhaustMap(() => this.googleMaps.loadMapsSDK()),
      filter(res => res),
      map(() => googleMapsApiLoaded()),
    ),
  );
}
