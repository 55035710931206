<form [formGroup]="form">
  <ion-list>
    <ion-text
      *ngIf="view.foundUser?.availability === 'N' && !view.existingConnection && !view.existingEmployee"
      slot="end"
      color="warning"
      style="margin-top: 27px"
    >
      Sorry, this user cannot be invited at this time.
    </ion-text>

    <gc-information-message
      *ngIf="!!view.existingConnection"
      [horizontal]="true"
      [message]="
        'It looks like this user is already invited to your ' +
        (view.existingConnection.source === 'COMPANY' ? 'Public Network' : 'Private Network') +
        '. Check Connections to see their invite status.'
      "
    ></gc-information-message>

    <gc-information-message
      *ngIf="!!view.existingEmployee"
      [horizontal]="true"
      [message]="
        'It looks like this user is already ' +
        (view.existingEmployee.type === 'EMPLOYEE_INSTALLER' ? 'an Employee Installer' : 'a Project Manager') +
        ' on your team.'
      "
    ></gc-information-message>

    <ion-item [lines]="view.isPending ? 'none' : 'full'">
      <ion-input
        data-cy="invite-user-email-input"
        formControlName="email"
        inputmode="email"
        type="email"
        [debounce]="600"
        [label]="form.email.valid ? 'User\'s Email Address' : '* Must be a valid email.'"
        labelPlacement="floating"
        clearInput
      ></ion-input>
      <ng-container slot="end" *ngIf="view.foundUser">
        <ion-icon
          *ngIf="view.foundUser?.availability === 'A'"
          slot="end"
          name="checkmark-circle"
          color="success"
          style="margin-top: 27px"
        ></ion-icon>
      </ng-container>
    </ion-item>
    <ion-progress-bar *ngIf="view.isPending" type="indeterminate"></ion-progress-bar>

    <ion-item>
      <ion-input
        data-cy="invite-user-first-name-field"
        formControlName="firstName"
        label="First Name"
        labelPlacement="floating"
        clearInput
        [disabled]="!!view.foundUser"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-input
        data-cy="invite-user-last-name-field"
        formControlName="lastName"
        label="Last Name"
        labelPlacement="floating"
        clearInput
        [disabled]="!!view.foundUser"
      ></ion-input>
    </ion-item>
    <ion-item>
      <ion-textarea formControlName="note" label="Note (Optional)" labelPlacement="floating" clearOnEdit></ion-textarea>
    </ion-item>
  </ion-list>
</form>
