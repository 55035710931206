import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { UnitOfMeasure } from './unit-of-measure.model';

export const {
  initialState: initialUnitOfMeasuresState,
  facade: UnitOfMeasureFacadeBase,
  selectors: { selectAll: allUnitOfMeasures },
} = buildState(UnitOfMeasure);

const reduce = createReducer(initialUnitOfMeasuresState);

export function unitOfMeasuresReducer(state = initialUnitOfMeasuresState, action: Action): IEntityState<UnitOfMeasure> {
  return reduce(state, action);
}
