import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { Address } from './address.model';
import { AddressFacadeBase } from './address.state';

@Injectable()
export class AddressFacade extends AddressFacadeBase {
  constructor(private store: Store<AppState>) {
    super(Address, store);
  }
}
