<ion-grid *ngIf="workOrder">
  <ion-row class="ion-padding ion-align-items-start">
    <ion-col>
      <ion-text>
        <h2>Public Job Name</h2>
        <p>{{ workOrder.publicJobName }}</p>
      </ion-text>
      <ion-text *ngIf="permissions | hasPermissions: 'work-orders:view'">
        <h2>Hammer Rating Threshold</h2>
        <p *ngIf="workOrder.minimumHammerRatingThreshold > 0; else noHammerRatingThresholdSet">
          Installers with a Hammer Rating in  {{ workOrder.productInstallationType?.type }} of {{ workOrder.minimumHammerRatingThreshold }} or below will be marked as such during review.
        </p>
        <ng-template #noHammerRatingThresholdSet>
          <p *ngIf="workOrder.status === 'DRAFT'">You have not selected a Hammer Rating threshold.</p>
          <p *ngIf="workOrder.status !== 'DRAFT'">You did not select a Hammer Rating threshold when publishing.</p>
        </ng-template>
      </ion-text>
      <ion-text *ngIf="!!workOrder?.address">
        <!-- Can we get rid of non null assertions here??? -->
        <h2>Job Site Address</h2>
        <div class="flex ion-justify-content-between" style="margin-bottom: 0">
          <p>
            <span>{{ workOrder.address!.addressLine1 }}</span>
            <span *ngIf="workOrder.address!.addressLine2">{{ workOrder.address!.addressLine2 }}</span>
            <span>
              {{ workOrder.address!.city }}, {{ workOrder.address!.stateOrProvince }}
              {{ workOrder.address!.postalCode }}
            </span>
          </p>
          <ion-button mode="ios" color="light" slot="end" (click)="navigate.emit(workOrder)" style="margin-left: 15px">
            <ion-icon slot="start" name="navigate"></ion-icon>
            Get Directions
          </ion-button>
        </div>
      </ion-text>
      <ion-text *ngIf="workOrder?.installationDetails">
        <h2>Installation Details</h2>
        <ng-container *ngIf="workOrder.publicJobSummary">
          <h3>Summary:</h3>
          <p>{{ workOrder.publicJobSummary}}</p>
          <h3>Details:</h3>
        </ng-container>
        <div [innerHTML]="workOrder.installationDetails | safeHtml" style="margin-bottom: 0"></div>
      </ion-text>
    </ion-col>
  </ion-row>
</ion-grid>
