import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MaskitoModule } from '@maskito/angular';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { PhoneNumberManagerComponent } from './phone-number-manager.component';

@NgModule({
  declarations: [PhoneNumberManagerComponent],
  exports: [PhoneNumberManagerComponent],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, PipesModule, DirectivesModule, MaskitoModule],
})
export class PhoneNumberManagerModule {}
