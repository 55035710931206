<gc-empty-list-warning *ngIf="!hideWarning && !(files || fileForms?.fileForms)?.length; else fileList">
  <h3 style="padding: 20px; margin: 5px; font-size: medium">
    <ion-icon name="document-outline"></ion-icon>
    <strong>No files found</strong>
  </h3>
</gc-empty-list-warning>

<ng-template #fileList>
  <ion-list>
    <ng-container *ngIf="files">
      <ng-container *ngFor="let file of files; trackBy: trackFilesBy">
        <ng-container *ngTemplateOutlet="FileTemplate; context: { $implicit: file }"></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="fileForms">
      <ng-container *ngFor="let file of fileForms.fileForms; trackBy: trackFilesBy">
        <ng-container *ngTemplateOutlet="FileTemplate; context: { $implicit: file.updatedModel }"></ng-container>
      </ng-container>
    </ng-container>
  </ion-list>
</ng-template>

<ng-template #FileTemplate let-file>
  <ion-item [lines]="file.processing ? 'none' : 'full'">
    <ion-icon slot="start" color="dark" [name]="file.filename | fileIcon"></ion-icon>
    <ion-label color="dark">
      <a [href]="file.url" target="_system">{{ file.filename }}</a>
    </ion-label>
    <ng-container *ngIf="enableUpload">
      <ion-buttons>
        <ion-spinner slot="end" *ngIf="file.processing"></ion-spinner>
        <ion-button *ngIf="!file.processing" [href]="file.url" target="_system">
          <ion-icon name="cloud-download" slot="icon-only" color="dark"></ion-icon>
        </ion-button>
        <ion-button *ngIf="file.failed" (click)="fileRetried.emit(file)" color="dark" fill="clear" slot="end">
          <ion-icon name="reload" slot="icon-only"></ion-icon>
        </ion-button>
        <ion-button *ngIf="!file.processing" (click)="deleteFile(file)" color="danger" fill="clear">
          <ion-icon name="trash" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ng-container>
  </ion-item>
  <ion-progress-bar *ngIf="file.processing" [value]="file.progressPercent"></ion-progress-bar>
</ng-template>

<gc-file-drop-target
  [enableUpload]="enableUpload"
  (filesDropped)="addDroppedFiles($event)"
  (filesAdded)="addFiles($event)"
></gc-file-drop-target>
