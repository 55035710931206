import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IonicAuthOptions } from '@ionic-enterprise/auth';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigService {
  static getHost = () => (Capacitor.isNativePlatform() ? environment.apiRoot.mobile : environment.apiRoot.web);

  get streamChatKey(): string {
    return Capacitor.isNativePlatform() ? environment.apiKeys.streamChat.mobile : environment.apiKeys.streamChat.web;
  }

  get host(): string {
    return ConfigService.getHost();
  }

  get authConfig(): IonicAuthOptions {
    return Capacitor.isNativePlatform() ? environment.authMobile : environment.authWeb;
  }
}
