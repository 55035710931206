import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EmptyListWarningComponent } from './empty-list-warning.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [EmptyListWarningComponent],
  exports: [EmptyListWarningComponent],
})
export class EmptyListWarningModule {}
