import { createSelector } from '@ngrx/store';
import { PlaidAccountVerificationStatus } from './models/plaid-account-details.model';
import { plaidAccountState } from './plaid-account.state';

export const plaidAccountDetails = createSelector(plaidAccountState, state => state.accountDetails);
export const initialPlaidLoadComplete = createSelector(plaidAccountState, state => state.initialLoadComplete);

export const hasPlaidAccount = createSelector(plaidAccountDetails, details => !!details);
export const plaidAccountStatus = createSelector(plaidAccountDetails, details => details?.status);
export const plaidPendingManualVerification = createSelector(
  plaidAccountStatus,
  status => status === 'pending_manual_verification',
);
export const plaidAccountVerified = createSelector(
  plaidAccountStatus,
  status => status === 'automatically_verified' || status === 'manually_verified',
);

const ExpectedPlaidAccountStatuses: PlaidAccountVerificationStatus[] = [
  'manually_verified',
  'automatically_verified',
  'pending_manual_verification',
  'pending_automatic_verification',
  'verification_failed',
];

export const plaidAccountInUnexpectedStatus = createSelector(
  plaidAccountStatus,
  status => status && !ExpectedPlaidAccountStatuses.includes(status),
);
