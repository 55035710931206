<ion-card *ngIf="!!installer" mode="ios">
  <ion-card-header>
    <gc-user-header [user]="installer" [workOrder]="workOrder.currentDetailedWorkOrder$ | push"></gc-user-header>
  </ion-card-header>

  <ion-card-content class="ion-text-center">
    <ion-row>
      <ion-col size="12" size-md="9">
        <p class="text-left">
          <ion-text *ngIf="!!$any(realInstaller).bio" color="dark">
            {{ $any(realInstaller).bio }}
          </ion-text>
        </p>
      </ion-col>
      <ion-col size="12" size-md="3" class="flex-center-end">
        <ion-button
          *ngIf="installer.negotiation || installer.negotiationItems?.length"
          color="danger"
          class="ion-margin"
          (click)="viewed.emit(installer)"
        >
          Review Negotiation
        </ion-button>
        <ion-button
          *ngIf="!(installer.negotiation || installer.negotiationItems?.length)"
          color="dark"
          class="ion-margin"
          (click)="viewed.emit(installer)"
        >
          Review Award Options
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-card-content>
</ion-card>
