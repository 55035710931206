import { Action, ActionCreator, ActionType } from '@ngrx/store';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

export type ExtractActionTypes<Creators extends readonly ActionCreator[]> = {
  [Key in keyof Creators]: Creators[Key] extends ActionCreator<infer T> ? T : never;
};

export type WhenTypeTransformer<T, Creators extends readonly ActionCreator[]> = (
  action: ActionType<Creators[number]>,
) => T;
export type ActionTransformer<T, V extends Action = Action> = (action: V) => T;

/**
 * Return type of the `on` fn.
 * Contains the action reducer coupled to one or more action types.
 */
export interface TransformerTypes<T, Creators extends readonly ActionCreator[]> {
  transformer: WhenTypeTransformer<T, Creators>;
  types: ExtractActionTypes<Creators>;
}

export const whenType = <T, Creators extends readonly ActionCreator[]>(
  ...args: [...creators: Creators, transformer: WhenTypeTransformer<T extends infer S ? S : never, Creators>]
): TransformerTypes<T, Creators> => {
  const creators = args.slice(0, -1) as unknown as Creators;
  const transformer = args[args.length - 1] as WhenTypeTransformer<T extends infer S ? S : never, Creators>;
  return {
    types: creators.map(c => c.type) as any,
    transformer: transformer as any,
  };
};

export const createTransformer = <T, A extends Action = Action>(
  ...whens: TransformerTypes<T, readonly ActionCreator[]>[]
): ActionTransformer<T, A> => {
  return (action: A): T => {
    const fn = whens.find(when => when.types.includes(action.type));
    if (fn?.transformer) {
      return fn.transformer(action);
    } else {
      throw new Error(`No Transformer Found For Action: ${action.type}`);
    }
  };
};

export const transformWhenType = <T, A extends Action = Action>(
  ...whens: TransformerTypes<T, readonly ActionCreator[]>[]
): OperatorFunction<A, T> => map(createTransformer(...whens));
