export interface NameLabel {
  name: string;
  label: string;
}

export const mapToNameLabelArray = (map: Record<string, string>): NameLabel[] =>
  Object.keys(map).map(key => ({
    name: key,
    label: map[key],
  }));

// -----===[ MAPS ]===-----
// Maps represent a very simple, highly effective way to transform
// data of one form into data of another form. Maps are a very
// functional way to handle simple operations that might otherwise
// require much more complex code. Maps are often used in selectors,
// but may also be used in reductions or effects, or anywhere else
// that their simple behavior may apply.

export const INVOICE_STATUS_LABEL: Record<string, string> = {
  NEW: 'New',
  READ: 'Read',
  REJECTED: 'Declined',
  APPROVED_PENDING_PAYMENT: 'Approved',
  PAID_PENDING_LIEN_RELEASE: 'Pending',
  PAYMENT_PROCESSING: 'Processing',
  PAID: 'Paid',
};

export const INVOICE_STATUS_GROUP_MAP: Record<string, string> = {
  NEW: 'needs_review',
  READ: 'needs_review',
  REJECTED: 'rejected',
  APPROVED_PENDING_PAYMENT: 'ready_to_pay',
  PAID_PENDING_LIEN_RELEASE: 'processing',
  PAYMENT_PROCESSING: 'processing',
  PAID: 'settled',
};

export const INSTALLER_INVOICE_STATUS_GROUP_MAP: Record<string, string> = {
  NEW: 'sent',
  READ: 'sent',
  REJECTED: 'rejected',
  APPROVED_PENDING_PAYMENT: 'sent',
  PAID_PENDING_LIEN_RELEASE: 'ready_to_sign',
  PAYMENT_PROCESSING: 'processing',
  PAID: 'paid',
};

export const INVOICE_STATUS_GROUP_LABEL_MAP: Record<string, string> = {
  needs_review: 'Needs Review',
  sent: 'Sent',
  ready_to_pay: 'Ready to Pay',
  ready_to_sign: 'Ready To Sign',
  processing: 'Processing',
  settled: 'Settled',
  paid: 'Paid',
  rejected: 'Rejected',
};

export const INVOICE_STATUS_GROUP_ORDER_MAP: Record<string, number> = {
  needs_review: 0,
  ready_to_pay: 1,
  processing: 2,
  settled: 3,
  rejected: 4,
  undefined: 99,
};

export const INSTALLER_INVOICE_STATUS_GROUP_ORDER_MAP: Record<string, number> = {
  sent: 0,
  ready_to_sign: 1,
  processing: 2,
  paid: 3,
  rejected: 4,
  undefined: 99,
};

export const ALL_INVOICE_STATUS_GROUP_ORDER_MAPS: Record<string, number> = {
  ...INVOICE_STATUS_GROUP_ORDER_MAP,
  ...INSTALLER_INVOICE_STATUS_GROUP_ORDER_MAP,
};

export const INVOICE_STATUS_GROUP_STATUS_MAP: Record<string, string[]> = {
  needs_review: ['NEW', 'READ'],
  ready_to_pay: ['APPROVED_PENDING_PAYMENT'],
  processing: ['PAID_PENDING_LIEN_RELEASE', 'PAYMENT_PROCESSING'],
  settled: ['PAID'],
  rejected: ['REJECTED'],
};

export const INSTALLER_INVOICE_STATUS_GROUP_STATUS_MAP: Record<string, string[]> = {
  sent: ['NEW', 'READ', 'APPROVED_PENDING_PAYMENT'],
  ready_to_sign: ['PAID_PENDING_LIEN_RELEASE'],
  processing: ['PAYMENT_PROCESSING'],
  paid: ['PAID'],
  rejected: ['REJECTED'],
};

export const INVOICE_STATUS_GROUP_TO_COLOR: Record<string, string> = {
  needs_review: 'medium',
  sent: 'medium',
  ready_to_pay: 'gc-yellow',
  ready_to_sign: 'gc-yellow',
  processing: 'dark',
  settled: 'gc-green',
  paid: 'gc-green',
  rejected: 'danger',
};

export const INVOICE_AREAS: Record<string, string> = {
  workOrder: 'work-order',
  adHoc: 'ad-hoc',
} as const;
