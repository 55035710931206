import { Pipe, PipeTransform } from '@angular/core';
import {
  DisplayGroupAllowance,
  DisplayOptions,
  groupIsAllowed,
  normalizeGroup,
  TYPE_TO_GROUP_CODE,
  wasDenied
} from '../../domains/work-order-item-group.selectors';


@Pipe({ name: 'allowsItemGroups' })
export class AllowsItemGroupsPipe implements PipeTransform {
  transform(options: DisplayOptions, ...groups: Array<keyof typeof TYPE_TO_GROUP_CODE>): boolean {
    return groups.every(group => !wasDenied(options, normalizeGroup(group)));
  }
}

@Pipe({ name: 'itemGroupsAllowed' })
export class ItemGroupsAllowedPipe implements PipeTransform {
  transform(allowances: DisplayGroupAllowance, ...groups: Array<keyof typeof TYPE_TO_GROUP_CODE>): boolean {
    return groups.every(group => groupIsAllowed(allowances, normalizeGroup(group)));
  }
}
