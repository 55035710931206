import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { ensureExists } from '../../../shared/utils/rxjs';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { AppState } from '../../state';
import { editUserProfile, loadUserProfile } from '../profile/profile.actions';
import { userSelected } from '../user/user.state';
import { loadAllUserFiles } from './user-files.state';

@Injectable()
export class UserFileEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  loadFilesForSelectedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userSelected),
      map(({ entity: user }) =>
        loadAllUserFiles({
          criteria: {
            parents: { installers: typeof user === 'string' || typeof user === 'number' ? user : user.id },
          },
        }),
      ),
    ),
  );

  loadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editUserProfile),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) => user),
      ensureExists(user => !!user),
      map(user =>
        loadAllUserFiles({
          criteria: {
            parents: { installers: user.id },
          },
        }),
      ),
    ),
  );

  loadProfileFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserProfile),
      map(({ user }) =>
        loadAllUserFiles({
          criteria: {
            parents: { installers: user.id },
          },
        }),
      ),
    ),
  );
}
