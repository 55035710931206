import { createAction, props } from '@ngrx/store';
import { PendingFile } from '../models/pending-file';
import { Category } from './category/category.model';
import { ProductInstallationTypeClaimed } from './product-installation-types-claimed/product-installation-types-claimed.model';
import { ProductInstallationType } from './product-installation-types/product-installation-types.model';
import { TrainingClaimed } from './trainings-claimed/trainings-claimed.model';

export const productInstallTypeClaimedSelected = createAction(
  '[Training] (Product Installation Type Claimed) Selected',
  props<{ installType: ProductInstallationType }>(),
);

export const productInstallationTypeClaimUpdated = createAction(
  '[Training] (Product Installation Type Claim) Updated',
  props<{ claim: ProductInstallationTypeClaimed }>(),
);

export const confirmDeletionOfInstallationTypesClaimed = createAction(
  '[Training] (Product Installation Type Claim) Confirm Deletion',
  props<{ claimed: ProductInstallationTypeClaimed }>(),
);

export const addTrainingFile = createAction(
  '[Training] (Training File) Added',
  props<{ claim: ProductInstallationTypeClaimed; file: PendingFile }>(),
);

export const showUnclaimedTrainingSelection = createAction(
  '[Training] (Unclaimed) Selection',
  props<{ category: Category; claimedInstallType: ProductInstallationTypeClaimed }>(),
);

export const confirmDeletionOfTrainingClaimed = createAction(
  '[Training] (Claimed) Confirm Deletion',
  props<{ claimed: TrainingClaimed }>(),
);

export const cancelEditSkillsAndExperience = createAction('[Training] Cancel Edit Skills And Experience');
