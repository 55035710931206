import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PhoneNumberService } from './phone-number.service';
import { PhoneNumbersUIService } from './phone-numbers-ui.service';
import { PhoneNumbersEffects } from './phone-numbers.effects';
import { FEATURE_NAME } from './store/feature.name';
import { reducer } from './store/feature.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([PhoneNumbersEffects]),
  ],
  providers: [PhoneNumbersUIService, PhoneNumberService],
})
export class ProfileModule {}
