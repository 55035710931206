import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { InvoiceItem } from '~gc/domains/invoices/invoice-item/invoice-item.model';

export const {
  initialState: initialInvoiceItemState,
  facade: InvoiceItemsFacadeBase,
  selectors: { selectAllSorted: allInvoiceItems },
  actions: { loadAll: loadAllInvoiceItems, deleteSuccess: deleteInvoiceItemSuccess },
} = buildState(InvoiceItem);

const reduce = createReducer(initialInvoiceItemState);

export function invoiceItemReducer(state = initialInvoiceItemState, action: Action): IEntityState<InvoiceItem> {
  return reduce(state, action);
}
