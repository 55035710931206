import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LienReleaseFile } from './lien-release-file.model';
import { LienReleaseFileFacadeBase, signatureImage } from './lien-release-file.state';

@Injectable()
export class LienReleaseFileFacade extends LienReleaseFileFacadeBase {
  signatureImage$ = this.store.select(signatureImage);

  constructor(private store: Store) {
    super(LienReleaseFile, store);
  }
}
