import { createReducer, on } from '@ngrx/store';
import { showEnhancedInstallerProfile } from '../installer-profile.actions';
import {
  claimProductInstallationSubtypeModalCancelled,
  claimProductInstallationSubtypeModalDone,
  showClaimSubtypesModal,
} from '../product-installation-subtypes.actions';
import {
  claimTrainingModalCancelled,
  claimTrainingModalDone,
  installTypeClaimIdSelected,
  showClaimCategorizedTrainingModal,
} from '../trainings.actions';

export interface ProfileState {
  installerId?: string;
  selectedInstallTypeClaimedId?: number;
  categoryId?: number;
  installTypeId?: number;
}

export const initialState: ProfileState = {};

export const profileReducer = createReducer(
  initialState,
  on(showEnhancedInstallerProfile, (state, { installer }): ProfileState => ({ ...state, installerId: installer.id })),
  on(
    installTypeClaimIdSelected,
    (state, { installTypeClaimId }): ProfileState => ({
      ...state,
      selectedInstallTypeClaimedId: installTypeClaimId,
    }),
  ),
  on(showClaimCategorizedTrainingModal, (state, { category }): ProfileState => ({ ...state, categoryId: category.id })),
  on(showClaimSubtypesModal, (state, { installType }): ProfileState => ({ ...state, installTypeId: installType.id })),
  on(
    claimProductInstallationSubtypeModalCancelled,
    claimProductInstallationSubtypeModalDone,
    (state): ProfileState => ({
      ...state,
      installTypeId: undefined,
    }),
  ),
  on(
    claimTrainingModalCancelled,
    claimTrainingModalDone,
    (state): ProfileState => ({ ...state, categoryId: undefined }),
  ),
);
