import { IEntityState } from '@briebug/ngrx-auto-entity';
import { ActionReducerMap } from '@ngrx/store';
import { CustomDate } from './custom-date/custom-date.model';
import { customDateReducer } from './custom-date/custom-date.store';

export interface DomainState {
  customDate: IEntityState<CustomDate>;
}

export const reducer: ActionReducerMap<DomainState> = {
  customDate: customDateReducer,
};
