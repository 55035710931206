import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { openT4FDiscountForm } from './partner-deals.actions';

@Injectable()
export class PartnerDealsFacade {
  constructor(private store: Store) {}

  openT4FDiscountForm() {
    this.store.dispatch(openT4FDiscountForm());
  }
}
