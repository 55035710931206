import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import { defer, firstValueFrom, from, iif, merge, Observable, of, Subject, throwError } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Channel, Event } from 'stream-chat';
import { ChannelService, ChatClientService, StreamI18nService, ThemeService } from 'stream-chat-angular';
import { ConfigService } from '../../../shared/services/config.service';
import { User, USER_TYPES } from '../../users';
import { MatchedInstaller } from '../matched-installer/matched-installer.model';
import { WorkOrder } from '../work-order/work-order.model';
import { LocalNotificationsService } from './local-notifications.service';

export const resolveUserId = (user?: User): string => user?.id?.replace('auth0|', '') ?? '';

export interface StreamChatFCMNotification {
  sender: 'stream.chat';
  type: 'message.new';
  version: 'v2';
  message_id: string;
  id: string;
  channel_type: 'messaging';
  channel_id: string;
  // cid: `messaging:${string}`;
  cid: string;
}

export interface WorkOrderChatLocalNotificationData {
  type: 'WorkOrderChat';
  channelId: string;
}

export class NonMemberError extends Error {
  constructor(
    public channels: string[],
    message: string,
  ) {
    super(message);
  }
}

@Injectable()
export class WorkOrderChatService {
  // activeUserId?: string;
  channels$ = this.channelService.channels$;
  notification$ = this.chatService.events$;
  toastAction$ = new Subject<WorkOrderChatLocalNotificationData>();
  /* Combination of local notification action events and toast action events for notifications around work order chat. */
  chatNotificationAction$: Observable<WorkOrderChatLocalNotificationData> = merge(
    this.toastAction$,
    this.localNotifications.notificationActionPerformed$.pipe(
      map(n => n.notification.extra as WorkOrderChatLocalNotificationData),
      filter(data => data.type === 'WorkOrderChat'),
    ),
  );

  constructor(
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private theme: ThemeService,
    private http: HttpClient,
    private readonly localNotifications: LocalNotificationsService,
    private readonly toasts: ToastController,
    private readonly config: ConfigService,
  ) {}

  // async disconnect() {
  //   try {
  //     await this.chatService.disconnectUser();
  //     this.channelService.reset();
  //   } catch (err) {
  //     console.error('Error Disconnecting User!', err);
  //   }
  // }

  getToken(user: User) {
    return this.http.get<{ token: string }>(this.config.host + '/chats/token').pipe(map(({ token }) => token));
  }

  initializeChat(user: User) {
    return this.chatService.init(
      this.config.streamChatKey,
      {
        name: user.email,
        username: `${user.firstName} ${user.lastName} (${USER_TYPES[user.type]})`,
        image: user.profilePhoto,
        id: resolveUserId(user),
      },
      () => firstValueFrom(this.getToken(user)),
    );
  }

  initializeChannel(user: User) {
    return this.channelService.init(
      {
        type: 'commerce',
        members: { $in: [resolveUserId(user)] },
      },
      { last_message_at: -1, unread_count: -1 },
      { limit: 30 },
      false,
    );
  }

  // initChat(user: User) {
  //   this.streamI18nService.setTranslation();
  //   this.activeUserId = resolveUserId(user);
  //   return this.http.get<{ token: string }>(this.config.host + '/chats/token').pipe(
  //     switchMap(({ token }) =>
  //       this.chatService.init(
  //         this.config.streamChatKey,
  //         {
  //           name: user.email,
  //           username: `${ user.firstName } ${ user.lastName } (${ USER_TYPES[user.type] })`,
  //           image: user.profilePhoto,
  //           id: this.activeUserId!, // TODO: Can we eliminate this non-null assertion?
  //         },
  //         token,
  //       ),
  //     ),
  //     switchMap(() =>
  //       this.channelService.init(
  //         {
  //           type: 'commerce',
  //           members: { $in: [ this.activeUserId! ] }, // TODO: Can we eliminate this non-null assertion?
  //         },
  //         { last_message_at: -1, unread_count: -1 },
  //         { limit: 30 },
  //         false,
  //       ),
  //     ),
  //     switchMap(() =>
  //       from(this.localNotifications.init()).pipe(
  //         tap({ error: console.error }),
  //         catchError(err => of(null)),
  //       ),
  //     ),
  //   );
  // }

  async registerPush(token: string) {
    return this.chatService.chatClient.addDevice(token, Capacitor.getPlatform() === 'ios' ? 'apn' : 'firebase');
  }

  async notifyMessage(event: Event | StreamChatFCMNotification, channel?: Channel) {
    if (!channel) {
      return;
    }

    const header = channel.data?.name || 'Work Order Chat';
    const message = (event as any).user ? `New Message from ${(event as any).user.name}` : 'New Message';
    const extra: WorkOrderChatLocalNotificationData = {
      type: 'WorkOrderChat',
      channelId: channel.id!, // TODO: Can we eliminate this non-null assertion?
    };

    const state = await App.getState();

    try {
      Capacitor.isNativePlatform() && state.isActive
        ? await this.toastNotification(header, message, extra)
        : await this.localNotifications.alertImmediately(header, message, extra);
    } catch (err) {
      console.error(err);
    }
  }

  addAwardedWorkOrderChannel(workOrder: WorkOrder, activeUserId: string) {
    return this.appendChannels(workOrder.chatChannelId, activeUserId);
  }

  addMatchedInstallerChannels(matchedInstallers: MatchedInstaller[], activeUserId: string) {
    return this.appendChannels(
      matchedInstallers.map(match => match.chatChannelId!),
      activeUserId,
    ); // TODO: Can we eliminate this non-null assertion?
  }

  appendChannels(ids: string | string[] | undefined, activeUserId: string) {
    if (!ids) {
      return of([]);
    }

    const arrIds = typeof ids === 'string' ? [ids] : ids;
    const query = {
      type: 'commerce',
      members: { $in: [activeUserId!] },
      id: arrIds.length === 1 ? { $eq: arrIds[0] } : { $in: arrIds },
    };

    return from(this.channelService.init(query, undefined, undefined, false)).pipe(
      switchMap(available =>
        iif(
          () => !available.length,
          defer(() => throwError(new NonMemberError(arrIds, 'User is not a member of this channel!'))),
          of(available),
        ),
      ),
      map(channels => channels.find(channel => arrIds.some(id => id === channel.id))),
      tap(channel => channel && this.channelService.setAsActiveChannel(channel)),
      map(() => arrIds),
    );
  }

  clearActiveChannel() {
    this.channelService.deselectActiveChannel();
  }

  private async toastNotification(header: string, message: string, extra: WorkOrderChatLocalNotificationData) {
    const toast = await this.toasts.create({
      header,
      message,
      position: 'bottom',
      duration: 5000,
    });
    await toast.present();
    toast.onclick = () => this.toastAction$.next(extra);
  }

  addMemberToChannel(channelId: string) {
    return this.http.post(`${this.config.host}/chats/${channelId}/members`, {});
  }

  removeMemberFromChannel(channelId: string) {
    return this.http.delete(`${this.config.host}/chats/${channelId}/members`);
  }
}
