import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { WorkOrderCount } from './work-order-count.model';
import { sortedStatusGroupCounts, WorkOrderCountFacadeBase } from './work-order-count.state';

@Injectable()
export class WorkOrderCountFacade extends WorkOrderCountFacadeBase {
  statusGroupCounts$ = this.store.select(sortedStatusGroupCounts);

  constructor(private store: Store<AppState>) {
    super(WorkOrderCount, store);
  }
}
