import { Component, Input } from '@angular/core';
import { PendingNegotiationDetails } from '~gc/domains/negotiations/models/pending-negotiation.model';
import { NegotiationForm } from '../negotiation.form';

@Component({
  selector: 'gc-bid-ask-negotiation-item-list',
  templateUrl: './bid-ask-negotiation-item-list.component.html',
  styleUrls: ['./bid-ask-negotiation-item-list.component.scss'],
})
export class BidAskNegotiationItemListComponent {
  @Input() role!: string;
  @Input() pendingNegotiation!: Partial<PendingNegotiationDetails>;
  @Input() form!: NegotiationForm;
}
