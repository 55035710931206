import { Pipe, PipeTransform } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Channel } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-angular';
import { createChannelEventListener } from '../../domains/work/work-order-chat/chat-utils';

@Pipe({
  name: 'channelHasUnread',
})
export class ChannelHasUnreadPipe implements PipeTransform {
  transform(
    channels: Channel<DefaultStreamChatGenerics> | Channel<DefaultStreamChatGenerics>[] = [],
  ): Observable<boolean> {
    const channelsArr = channels instanceof Array ? channels : [channels];
    return merge(
      of(null),
      ...channelsArr
        .map(channel => [
          createChannelEventListener(channel, 'message.new'),
          createChannelEventListener(channel, 'message.read'),
        ])
        .flat(),
    ).pipe(map(() => channelsArr.some(channel => !!channel.countUnread())));
  }
}
