import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, take, tap, timeout, withLatestFrom } from 'rxjs/operators';
import { PhoneNumber } from '../../domains/phone-number';
import {
  createPhoneNumber,
  deletePhoneNumber,
  phoneNumberCreationFailed,
  phoneNumberUpdatedFailed,
  phoneNumberUpdatedSuccessfully,
  updatePhoneNumber,
} from '../../domains/phone-number/phone-number.state';
import { User } from '../../domains/users';
import { PhoneNumberService } from './phone-number.service';
import { PhoneNumbersUIService } from './phone-numbers-ui.service';
import {
  addPhoneNumber,
  confirmPhoneNumberRemoval,
  phoneNumberRemovalConfirmed,
  phoneNumberVerificationFailure,
  phoneNumberVerificationInitiated,
  phoneNumberVerificationSuccess,
  phoneNumberVerificationTimedOut,
  refreshPhoneNumberCode,
  warnMinimumPhoneNumbersRequired,
} from './phone-numbers.actions';
import { verifyingPhoneNumbers } from './store/phone-number-management.selectors';

@Injectable()
export class PhoneNumbersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly ui: PhoneNumbersUIService,
    private readonly service: PhoneNumberService,
  ) {}

  verifyPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberVerificationInitiated),
      map(({ phoneNumber, code, user }) =>
        updatePhoneNumber({
          entity: {
            number: phoneNumber.number,
            userId: user.id,
            verificationCode: code,
          } as PhoneNumber,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
            },
          },
        }),
      ),
    ),
  );

  timeoutPhoneNumberVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberVerificationInitiated),
      mergeMap(({ phoneNumber }) =>
        // eslint-disable-next-line @ngrx/avoid-cyclic-effects
        this.actions$.pipe(
          ofType(phoneNumberUpdatedSuccessfully, phoneNumberUpdatedFailed),
          timeout(30000),
          take(1),
          catchError(() => of(phoneNumberVerificationTimedOut({ phoneNumber }))),
        ),
      ),
    ),
  );

  toastVerificationTimeout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(phoneNumberVerificationTimedOut),
        switchMap(() => this.ui.toastVerificationTimeout()),
      ),
    { dispatch: false },
  );

  capturePhoneNumberVerificationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberUpdatedSuccessfully),
      withLatestFrom(this.store.select(verifyingPhoneNumbers)),
      filter(([{ entity }, phoneNumbers]) => phoneNumbers.includes(entity.number)),
      map(([{ entity }]) => phoneNumberVerificationSuccess({ phoneNumber: entity })),
    ),
  );

  toastVerificationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(phoneNumberVerificationSuccess),
        map(({ phoneNumber }) => phoneNumber),
        switchMap(phoneNumber => this.ui.toastVerificationSuccess(phoneNumber)),
      ),
    { dispatch: false },
  );

  capturePhoneNumberVerificationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberUpdatedFailed),
      withLatestFrom(this.store.select(verifyingPhoneNumbers)),
      filter(([{ entity }, phoneNumbers]) => phoneNumbers.includes(entity.number)),
      map(([{ entity, error }]) => phoneNumberVerificationFailure({ phoneNumber: entity, error })),
    ),
  );

  toastVerificationError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(phoneNumberVerificationFailure),
        map(({ error }) => error),
        filter(error => !!error),
        switchMap(error => this.ui.toastVerificationError(error)),
      ),
    { dispatch: false },
  );

  confirmPhoneNumberRemoval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmPhoneNumberRemoval),
      concatMap(({ phoneNumber, user }) =>
        this.ui.confirmRemovePhoneNumber(phoneNumber).pipe(
          filter(confirmed => confirmed),
          map(() => phoneNumberRemovalConfirmed({ phoneNumber, user })),
        ),
      ),
    ),
  );

  removePhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberRemovalConfirmed),
      map(({ phoneNumber, user }) =>
        deletePhoneNumber({
          entity: {
            ...phoneNumber,
            userId: user.id,
          },
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
            },
          },
        }),
      ),
    ),
  );

  warnMinimumPhoneNumbersRequired$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(warnMinimumPhoneNumbersRequired),
        switchMap(() => this.ui.warnMinimumPhoneNumbersRequired()),
      ),
    { dispatch: false },
  );

  addPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addPhoneNumber),
      map(({ phoneNumber, user }) =>
        createPhoneNumber({
          entity: {
            ...phoneNumber,
            userId: user.id,
            consentMedium: 'app'
          },
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
            },
          },
        }),
      ),
    ),
  );

  warnPhoneNumberTaken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addPhoneNumber),
        mergeMap(({ phoneNumber }) =>
          this.actions$.pipe(
            ofType(phoneNumberCreationFailed),
            first(({ entity, error }) => error?.status === 409 && entity.number === phoneNumber.number),
            switchMap(({ error }) =>
              error.reason === 1 || error.error.reason === 1
                ? this.ui.toastPhoneNumberYours(phoneNumber)
                : this.ui.toastPhoneNumberExists(phoneNumber),
            ),
          ),
        ),
      ),
    { dispatch: false },
  );

  warnPhoneNumberInvalid$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addPhoneNumber),
        mergeMap(({ phoneNumber }) =>
          this.actions$.pipe(
            ofType(phoneNumberCreationFailed),
            first(({ entity, error }) => error?.status === 400 && entity.number === phoneNumber.number),
            switchMap(({ error }) => this.ui.toastPhoneNumberInvalid(phoneNumber)),
          ),
        ),
      ),
    { dispatch: false },
  );

  warnPhoneNumberError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addPhoneNumber),
        mergeMap(({ phoneNumber }) =>
          this.actions$.pipe(
            ofType(phoneNumberCreationFailed),
            first(({ entity, error }) => error?.status >= 500 && entity.number === phoneNumber.number),
            switchMap(({ error }) => this.ui.toastPhoneNumberError(phoneNumber, error)),
          ),
        ),
      ),
    { dispatch: false },
  );

  refreshPhoneNumberCode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshPhoneNumberCode),
        switchMap(({ phoneNumber, user }) =>
          this.service
            .refreshCode(user.id, phoneNumber.number)
            .pipe(switchMap(() => this.ui.toastCodeRefreshed(phoneNumber))),
        ),
      ),
    { dispatch: false },
  );
}
