import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, tap } from 'rxjs/operators';
import { phoneNumberVerificationInitiated } from '../../../core/profile/phone-numbers.actions';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';
import { StateFacade } from '../../../domains/locations';
import { PhoneNumber, PhoneNumberFacade } from '../../../domains/phone-number';
import { AnyUser } from '../../../domains/users';
import { ProfileFacade } from '../../../domains/users/profile/profile.facade';
import { filterUntilPredicatePassed } from '../../../shared/utils/operators';
import { phoneNumberAdding, phoneNumberRemoving, phoneNumberVerifying } from '../basic-profile.actions';
import { BasicProfileForm } from '../basic-profile.form';
import { authenticatedUserPhoneNumberEntries } from '../basic-profile.selectors';

@Component({
  selector: 'gc-basic-profile-modal',
  templateUrl: './basic-profile-modal.component.html',
  styleUrls: ['./basic-profile-modal.component.scss'],
})
export class BasicProfileModalComponent implements OnInit {
  @Input() step = 1;
  form!: BasicProfileForm;
  phoneNumberForm!: UntypedFormGroup;
  validated = false;
  lastStep = 3;

  phoneNumbers$ = this.store.select(authenticatedUserPhoneNumberEntries);

  constructor(
    public readonly auth: AuthFacade,
    public readonly profile: ProfileFacade,
    public readonly states: StateFacade,
    public readonly phoneNumbers: PhoneNumberFacade,
    private readonly modalGC: ModalController,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.form = new BasicProfileForm();
    this.phoneNumberForm = new UntypedFormGroup({
      number: new UntypedFormControl(null, Validators.required),
      type: new UntypedFormControl('MOBILE', Validators.required),
    });

    combineLatest([this.profile.currentEditedUser$])
      .pipe(
        filterUntilPredicatePassed(([edited]) => !!edited),
        distinctUntilChanged(),
        tap(([edited]) => (edited ? this.form.updateModel(edited as unknown as AnyUser) : void 0)),
        filter(edited => !edited),
        first(),
      )
      .subscribe(() => this.modalGC.dismiss());
  }

  back() {
    this.updateStep(this.step > 1 ? -1 : 0);
  }

  next() {
    this.updateStep(this.step < this.lastStep ? 1 : 0);
  }

  updateStep(amount: number) {
    this.validated = true;
    this.step += amount;
    this.validated = false;
  }

  close() {
    this.validated = true;
    this.profile.cancelEditProfile(this.form.dirty);
    this.validated = false;
  }

  save() {
    this.validated = true;
    this.profile.saveProfile(this.form.updatedModel);
  }

  addPhoneNumber(phoneNumber: PhoneNumber) {
    this.store.dispatch(phoneNumberAdding({ phoneNumber }));
  }

  removePhoneNumber(phoneNumber: PhoneNumber) {
    this.store.dispatch(phoneNumberRemoving({ phoneNumber }));
  }

  verifyPhoneNumber({ phoneNumber, code }: { phoneNumber: PhoneNumber; code: string }) {
    this.store.dispatch(phoneNumberVerifying({ phoneNumber, code }));
  }
}
