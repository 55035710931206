<ng-container
  *ngIf="
    (group | typeIsIn: 'original' : 'change-add' : 'installer-add') &&
    (displayGroups | itemGroupsAllowed: 'original' : 'change-add' : 'installer-add') &&
    (permissions | hasPermissions: 'work-order.item.price:view')
  "
>
  <ion-text
    [color]="(group | typeIsIn: 'original') ? 'gc-green' : 'gc-blue'"
    [class.muted]="item.invoiceInfo.hasBeenInvoiced"
  >
    <strong data-cy="change-order-original-total" [class.muted]="item.changeStatus === 'DECLINED_WITH_REASON'">{{ item.invoiceInfo.originalTotal | currency }}</strong>
  </ion-text>

  <br *ngIf="item.invoiceInfo.hasBeenInvoiced" />
  <ion-text *ngIf="item.invoiceInfo.hasBeenInvoiced" [color]="item.isChangeOrder ? 'gc-blue' : 'gc-green'">
    <strong data-cy="change-order-amount-to-invoice">{{ item.invoiceInfo.amountToInvoice | currency }}</strong>
  </ion-text>
</ng-container>
