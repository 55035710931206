import { createSelector } from '@ngrx/store';
import { workOrderCustomDates } from '../../domains/custom-dates';
import { editedWorkOrder } from '../../domains/work/work-order/work-order.state';
import { Direction, filterByOptionsOnKeyIfAvailable, sortByStartDuration, tryGet } from '../../shared/utils/func';

export const editedWorkOrderId = createSelector(editedWorkOrder, tryGet('id'));

export const editedWorkOrderIds = createSelector(editedWorkOrderId, id => [id]);

export const editedWorkOrderDates = createSelector(
  workOrderCustomDates,
  editedWorkOrderIds,
  filterByOptionsOnKeyIfAvailable('workOrderId'),
);

export const editedWorkOrderDatesSorted = createSelector(
  editedWorkOrderDates,
  sortByStartDuration(Direction.Ascending),
);
