import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { DetailedWorkOrderItem } from '../../../domains/models/detailed-work-order-item';
import { UnitOfMeasure } from '../../../domains/unit-of-measure/unit-of-measure.model';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';
import { GenericValidators } from '../../../shared/validators/generic.validators';
import { newChangeTotalLessEqualRemaining } from '../change-order-deduct-modal/change-order-deduct-modal.component';

@Component({
  selector: 'gc-change-order-item-add-modal',
  templateUrl: './change-order-item-add-modal.component.html',
  styleUrls: ['./change-order-item-add-modal.component.scss'],
})
export class ChangeOrderItemAddModalComponent implements OnInit {
  @Input() workOrder!: WorkOrder;
  @Input() unitsOfMeasure!: UnitOfMeasure[];
  @Input() item!: DetailedWorkOrderItem;

  form?: UntypedFormGroup;
  readonly maxItemTotalAmountAllowed = environment.maximumAllowableItemAmount;

  constructor(
    readonly builder: UntypedFormBuilder,
    private readonly modalGC: ModalController,
  ) {
  }

  ngOnInit() {
    this.form = this.builder.group(
      {
        name: [null, Validators.required],
        numberOfUnits: [
          null,
          [
            Validators.required,
            GenericValidators.isPositive,
          ],
        ],
        pricePerUnit: [null, [Validators.required, GenericValidators.isPositive]],
        unitOfMeasureSymbol: [null, Validators.required],
      },
      {
        validators: [GenericValidators.formGroupIncrementAmount, GenericValidators.maxItemTotalAmountAllowed]
      },
    );
  }

  async cancelAddEdit() {
    await this.modalGC.dismiss(null, 'cancel');
  }

  async submit() {
    if (this.form!.invalid) {
      return;
    }
    const updatedModel = {
      ...this.form!.value,
      isChangeOrder: true,
      workOrderId: this.workOrder.id,
    };
    await this.modalGC.dismiss(updatedModel, 'ok');
  }
}
