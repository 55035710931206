<ng-container *ngIf="auth.user$ | push | isInstaller | not">
  <ion-chip
    (click)="viewInstaller.emit(workOrder.awardedToInstaller)"
    outline
    class="installer-avatar-chip"
    *ngIf="workOrder | isAwardedToInstaller"
  >
    <ng-container *ngIf="workOrder.awardedToInstaller as installer">
      <ion-avatar *ngIf="!!installer.profilePhoto">
        <img alt="{{ installer.firstName }} {{ installer.lastName }}" [src]="installer.profilePhoto" />
      </ion-avatar>
      <ion-icon name="person-circle" size="large" color="medium" *ngIf="!installer.profilePhoto"></ion-icon>
      <ion-label> {{ installer.firstName }} {{ installer.lastName }}</ion-label>
    </ng-container>
  </ion-chip>

  <ion-chip
    *ngIf="(workOrder | isAwardedToInstaller | not) && (workOrder | isPublishedAwaitingResponse)"
    color="gc-yellow"
  >
    <ion-icon name="time-outline"></ion-icon>
    <ion-label>Awaiting Responses</ion-label>
  </ion-chip>

  <ion-chip
    *ngIf="
      !!matchedInstallerCount && (workOrder | isAwardedToInstaller | not) && (workOrder | isPublishedWithResponses)
    "
    color="gc-green"
    (click)="reviewInstallers.emit(workOrder)"
  >
    <ion-icon name="checkmark-circle"></ion-icon>
    <ion-label>
      Review {{ matchedInstallerCount }}
      {{ matchedInstallerCount > 1 ? 'Installers' : 'Installer' }}
    </ion-label>
  </ion-chip>
</ng-container>

<ion-chip
  *ngIf="(auth.user$ | push | isInstaller) && workOrder.isMatchedInstaller"
  class="company-avatar-chip"
  outline
>
  <ion-avatar>
    <img alt="{{ workOrder.companyName }}" src="https://gocarrera.com/img.jpg" />
  </ion-avatar>
  <ion-icon name="business-outline" color="dark"></ion-icon>
  <ion-label>{{ workOrder.companyName }}</ion-label>
</ion-chip>
