import { Entity, IEntityTransformer, Key } from '@briebug/ngrx-auto-entity';
import { add } from 'date-fns';

let instance = 0;
export const randomBetween = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);
export const setStatusDateChange: IEntityTransformer = {
  fromServer: data => (
    (data.statusDateChanged =
      data.status === 'DECLINED'
        ? add(new Date(), { days: instance % 2 === 0 ? 0 : -8 })
        : (data.statusDateChanged = new Date())),
    (instance = instance + 1),
    data
  ),
  toServer: entity => ((entity.statusDateChanged = undefined), entity),
};

@Entity('Connection', { uriName: 'my-network' })
export class Connection {
  @Key id?: string;
  companyId!: number;
  installerTeamLeadId!: string;
  source!: Source;
  status!: ConnectionStatus;
  statusChangedOn!: string;
}

// TODO: Use new set of status values?? - REVIEW LATER!
// export enum ConnectionStatus {
//   Pending = 'AWAITING_ACCEPTANCE_BY_INSTALLER',
//   Accepted = 'ACCEPTED_BY_INSTALLER',
//   PendingApproval = 'PENDING_APPROVAL_BY_GC', // This is currently isApproved flag on INSTALLER OBJECT!
//   Approved = 'APPROVED_BY_COMPANY',
//   Declined = 'DECLINED_BY_INSTALLER',
//   Disconnected = 'DISCONNECTED', // Either installer or company
//   NoSubscription = 'NO_SUBSCRIPTION',
// }

export enum ConnectionStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Disconnected = 'DISCONNECTED',
  NoSubscription = 'NO_SUBSCRIPTION',
}

export const isOfConnectionStatus = (request: Connection, ...statuses: ConnectionStatus[]) =>
  statuses.includes(request.status);

export enum Source {
  Installer = 'INSTALLER',
  Company = 'COMPANY',
  CompanyPrivate = 'COMPANY_PRIVATE',
}

export type PendingConnections = Pick<Connection, 'companyId' | 'installerTeamLeadId' | 'source'>;
