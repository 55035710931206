import { Pipe, PipeTransform } from '@angular/core';
import * as pluck from 'object-path-immutable';

const isString = (value: unknown): value is string => typeof value === 'string';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform<T>(
    items: T[],
    path: string,
    { defaultValue, direction }: { defaultValue?: any; direction?: 'asc' | 'desc' } = {},
  ): T[] {
    return !!items?.length
      ? isString(pluck.get(items[0], path, defaultValue))
        ? items.sort(
            (a, b) =>
              ((pluck.get(a, path, defaultValue) as unknown as string)?.localeCompare(
                pluck.get(b, path, defaultValue) as unknown as string,
              ) ?? 0) * (direction === 'desc' ? -1 : 1),
          )
        : items.sort(
            (a, b) =>
              ((pluck.get(a, path, defaultValue) as unknown as any) ?? 0) -
              ((pluck.get(b, path, defaultValue) as any) ?? 0) * (direction === 'desc' ? -1 : 1),
          )
      : items;
  }
}
