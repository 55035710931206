import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BankAccount } from '../../../domains/invoices/bank-account/bank-account.model';

@Component({
  selector: 'gc-bank-account-card',
  templateUrl: './bank-account-card.component.html',
  styleUrls: ['./bank-account-card.component.scss'],
})
export class BankAccountCardComponent {
  @Input() bank!: BankAccount;
  @Output() selected = new EventEmitter<BankAccount>();
}
