import { createSelector } from '@ngrx/store';
import { sortedApprovedConnectedTeamLeads } from '../../../domains/users/network.selectors';
import { makeSelectables, searchByCriteria, view } from '../../../shared/utils/ngrx';
import { publishSearchCriteria, selectedInstallerIds } from '../store/ui.selectors';


export const filteredConnections = createSelector(
  sortedApprovedConnectedTeamLeads,
  publishSearchCriteria,
  view('filteredConnections', searchByCriteria('firstName', 'lastName', 'email', 'gcNumber', 'id'), false),
);

export const selectableFilteredConnections = createSelector(
  filteredConnections,
  selectedInstallerIds,
  view('selectableFilteredConnections', makeSelectables, false),
);
