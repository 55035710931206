<ion-header class="nav-header">
  <gc-work-order-detail-header
    *ngIf="auth.user$ | push as user"
    [workOrder]="workOrders.currentDetailedWorkOrder$ | push"
    [matchedInstaller]="matchedInstallers.currentMatchedInstallerForWorkOrder$ | push"
    [biddingMatchedInstallers]="matchedInstallers.biddingMatchedInstallersForCurrentWorkOrder$ | push"
    [isModal]="showAsModal"
    [schedulerOpen]="schedulerOpen$ | push"
    [isChatOpen]="isChatOpen$ | push"
    (schedulerToggled)="toggleScheduler()"
    (closeModal)="modals.dismiss()"
    (back)="workOrders.deselect(); nav.back();"
    (menu)="workOrders.openDropdownMenu($event)"
    (chat)="workOrderChat.openWorkOrderChat($event)"
  ></gc-work-order-detail-header>
</ion-header>

<ion-content *ngIf="auth.user$ | push as user">
  <gc-detail-header
    [currentRole]="auth.role$ | push"
    [currentUser]="user"
    [workOrder]="workOrders.currentDetailedWorkOrder$ | push"
    [workOrderDates]="dates$ | push"
    [allowedPrivateDetails]="workOrderRelations.userAllowedPrivateDetails$ | push"
    [matchedInstallerCount]="workOrderRelations.currentWorkOrderMatchedInterestedNonPendingInstallerCount$ | push"
    [scheduleOpen]="schedulerOpen$ | push"
    [isChatOpen]="isChatOpen$ | push"
    [company]="workOrderRelations.currentWorkOrderCompany$ | push"
    (reviewInstallers)="installerReview.reviewInstallers($event)"
    (viewInstaller)="showAwardedInstallerProfile($event)"
    (dateAdded)="addDate($event)"
    (dateSaved)="saveDate($event)"
    (dateDeleted)="deleteDate($event)"
  ></gc-detail-header>

  <gc-area-segments
    *ngIf="((user | isInstallerApproved) || (user | userType | isRole: 'companyAdmin' : 'companyManager' : 'companyEmployee': 'installerMember')) && (workOrderRelations.userAllowedPrivateDetails$ | push)"
    [areas]="work.detailAreasByRoleOrderStateAndAssignment$ | push"
    [badgeMap]="workOrderRelations.invoiceBadgeMap$ | push"
    [selectedArea]="work.selectedDetailArea$ | push"
    (selected)="work.selectDetailArea($event)"
  ></gc-area-segments>

  <ng-container [ngSwitch]="work.selectedDetailArea$ | push">
    <gc-work-order-items-detail *ngSwitchCase="'items'"></gc-work-order-items-detail>
    <gc-work-order-detail-detail *ngSwitchCase="'details'"></gc-work-order-detail-detail>
    <gc-work-order-files-detail *ngSwitchCase="'files'"></gc-work-order-files-detail>
    <gc-work-order-invoices-detail *ngSwitchCase="'invoices'"></gc-work-order-invoices-detail>
    <gc-work-order-invoices-detail *ngSwitchCase="'progress'" invoiceLabel="Progress"></gc-work-order-invoices-detail>
  </ng-container>

  <!-- Little square so we can scroll down enough to get fab out of the way -->
  <div style="height: 100px"></div>
</ion-content>

<gc-add-fab
  *ngIf="(auth.role$ | push | isRole: 'installerLead':'companyEmployee') && (work.selectedDetailArea$ | push | isArea:'invoices':'progress') && (workOrders.current$ | push | isInProgress)"
  (clicked)="invoiceAndPayment.addNewInvoice()"
>
  <!--  [iconTemplate]="invoideAndPay"-->
  <ng-template #invoideAndPay>
    <fa-layers>
      <fa-icon [icon]="faFileInvoiceDollar" transform="grow-14 up-1"></fa-icon>
      <fa-icon [icon]="faHandHolding" style="color: black; opacity: 0.3" transform="grow-5 down-8 right-1"></fa-icon>
      <fa-icon [icon]="faHandHolding" style="color: var(--ion-color-gc-yellow)" transform="grow-5 down-7"></fa-icon>
    </fa-layers>
  </ng-template>
</gc-add-fab>

<ng-container *ngIf="reportsProgress$ | push | not">
  <gc-add-change-item-fab
    *ngIf="(auth.user$ | push) as user"
    [area]="work.selectedDetailArea$ | push"
    [userType]="(user | userType)"
    [workOrder]="workOrders.current$ | push"
    [displayGroups]="workOrderRelations.workOrderItemDisplayGroups$ | push"
    [permissions]="permissions$ | push"
    (changeOrderAdded)="changeOrders.addChangeOrderItem($event)"
  ></gc-add-change-item-fab>
</ng-container>

<ion-footer
  *ngIf="(auth.role$ | push | isRole: 'installerLead') && (workOrders.current$ | push | isStatus: 'PUBLISHED_AWAITING_RESPONSE':'PUBLISHED_WITH_RESPONSES')"
>
  <ion-toolbar class="p-2">
    <gc-work-order-installer-options
      *ngIf="auth.user$ | push as user"
      [loading]="matchedInstallers.isLoading$ | push"
      [savingMatchedInstaller]="(matchedInstallers.isSaving$ | push)"
      [workOrder]="workOrders.currentDetailedWorkOrder$ | push"
      [matchedInstaller]="matchedInstallers.currentMatchedInstallerForWorkOrder$ | push"
      [qualifications]="workOrderRelations.userWorkOrderQualifications$ | push"
      (interested)="matchedInstallers.accept($event)"
      (negotiated)="matchedInstallers.negotiate($event); negotiationsFacade.beginNegotiating()"
      (canceled)="matchedInstallers.cancelNegotiation($event)"
      (declined)="matchedInstallers.decline($event)"
      (appendMatchedInstaller)="appendMatchedInstaller(user, $event)"
      (openProfile)="profile.editProfile()"
      (openSkillsAndExperience)="usersUI.viewSkillsAndExperience(user)"
    ></gc-work-order-installer-options>
  </ion-toolbar>
</ion-footer>

<ion-footer
  *ngIf="(auth.role$ | push | isRole: 'companyAdmin':'companyManager') && (workOrders.current$ | push | isStatus: 'DONE')"
>
  <gc-work-order-leave-kudos
    [currentRole]="auth.role$ | push"
    [workOrder]="workOrders.current$ | push"
    (given)="kudos.give($event)"
  ></gc-work-order-leave-kudos>
</ion-footer>
