import { createSelector } from '@ngrx/store';
import { ProductInstallationSubtype } from '~gc/domains/training/product-installation-subtypes/product-installation-subtypes.model';
import {
  ProductInstallationSubtypesClaimed
} from '../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import {
  allProductInstallationSubtypesClaimed
} from '../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.state';
import { allProductInstallationSubtypes } from '../../domains/training/product-installation-subtypes/product-installation-subtypes.state';
import {
  ProductInstallationTypeClaimed
} from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import {
  allProductInstallationTypeClaimed
} from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.state';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';
import { allProductInstallationTypes } from '../../domains/training/product-installation-types/product-installation-types.state';
import { Installer } from '../../domains/users';
import { view } from '../../shared/utils/ngrx';
import { DetailedProductInstallationSubtypeClaimed } from './models/detailed-product-installation-subtype-claimed.model';
import { DetailedProductInstallationTypeClaimed } from './models/detailed-product-installation-type-claimed.model';
import { InstallationTypeSubtypesClaimed } from './models/installation-type-subtypes-claimed.model';

import { isCurrentInstallerAlsoCurrentUser } from './store/feature.selectors';
import { currentInstaller } from './store/profile.selectors';


export const extractDisplayDetailsFromProductInstallationType = (
  installType?: ProductInstallationType,
):
  | {
  type: string;
  description?: string;
}
  | undefined =>
  installType
    ? {
      type: installType.type,
      description: installType.description,
    }
    : undefined;

export const buildDetailedProductInstallationTypeClaimed =
  (installationTypes: ProductInstallationType[]) =>
    (claimedType: ProductInstallationTypeClaimed): DetailedProductInstallationTypeClaimed => (
      {
        ...claimedType,
        ...extractDisplayDetailsFromProductInstallationType(
          installationTypes.find(type => type.id === claimedType.productInstallationTypeId),
        ),
        installationType: installationTypes.find(type => type.id === claimedType.productInstallationTypeId),

        installTypeClaim: claimedType,
      }
    );

export const detailedProductInstallationTypesClaimed = createSelector(
  allProductInstallationTypeClaimed,
  allProductInstallationTypes,
  (claimedTypes: ProductInstallationTypeClaimed[], installationTypes) =>
    claimedTypes.map(buildDetailedProductInstallationTypeClaimed(installationTypes)),
);

export const currentInstallerProductInstallationTypesClaimed = createSelector(
  detailedProductInstallationTypesClaimed,
  currentInstaller,
  (
    claims: DetailedProductInstallationTypeClaimed[],
    installer: Installer,
  ): DetailedProductInstallationTypeClaimed[] | undefined =>
    installer ? claims.filter(claim => claim.userId === installer.id) : undefined,
);

export const buildDetailedProductInstallationSubtypesClaimed = (
  subtypes: ProductInstallationSubtype[],
  claims: ProductInstallationSubtypesClaimed[],
): DetailedProductInstallationSubtypeClaimed[] | undefined =>
  subtypes?.length && claims?.length
    ? claims.map(claim => (
      {
        productInstallationSubtype: subtypes.find(subtype => subtype.id === claim.productInstallationSubtypeId)!,
        subtypeClaim: claim,
      }
    ))
    : undefined;

export const detailedProductInstallationSubtypesClaimed = createSelector(
  allProductInstallationSubtypes,
  allProductInstallationSubtypesClaimed,
  view('detailedProductInstallationSubtypesClaimed', buildDetailedProductInstallationSubtypesClaimed, true, true),
);

export const currentInstallerSubtypesClaimed = createSelector(
  detailedProductInstallationSubtypesClaimed,
  currentInstaller,
  (
    subtypes: DetailedProductInstallationSubtypeClaimed[],
    installer: Installer,
  ): DetailedProductInstallationSubtypeClaimed[] | undefined =>
    installer && subtypes ? subtypes.filter(claim => claim.subtypeClaim?.userId === installer.id) : undefined,
);

export const currentInstallerProductInstallationSubtypes = createSelector(
  currentInstaller,
  isCurrentInstallerAlsoCurrentUser,
  currentInstallerProductInstallationTypesClaimed,
  currentInstallerSubtypesClaimed,
  (
    installer: Installer,
    showRemove: boolean,
    claims: DetailedProductInstallationTypeClaimed[],
    subtypesClaimed?: DetailedProductInstallationSubtypeClaimed[],
  ): InstallationTypeSubtypesClaimed[] | undefined =>
    subtypesClaimed
      ? claims?.map(claim => (
        {
          installTypeClaimed: claim,
          subtypesClaimed: subtypesClaimed.filter(
            subtype => subtype.productInstallationSubtype.productInstallationTypeId === claim.productInstallationTypeId,
          ),
          showRemove,
        }
      ))
      : undefined,
);
