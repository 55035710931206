import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { InvoiceCount } from './invoice-count.model';
import { InvoiceCountFacadeBase, sortedStatusGroupCounts } from './invoice-count.state';

@Injectable()
export class InvoiceCountFacade extends InvoiceCountFacadeBase {
  statusGroupCounts$ = this.store.select(sortedStatusGroupCounts);

  constructor(private store: Store<AppState>) {
    super(InvoiceCount, store);
  }
}
