import { Component, Input } from '@angular/core';
import { InstallerTeamLead } from '../../../../domains/users';

@Component({
  selector: 'gc-user-rating',
  templateUrl: './user-rating.component.html',
  styleUrls: ['./user-rating.component.scss'],
})
export class UserRatingComponent {
  @Input() user!: InstallerTeamLead;
}
