<ion-item lines="none" class="ion-margin-bottom">
  <ion-avatar slot="start">
    <img [src]="avatar" alt="Logo" />
  </ion-avatar>
  <ion-label class="ion-text-nowrap">
    {{ name }}
  </ion-label>
  <ion-text slot="end">
    <strong>
      {{ amount | currency }}
    </strong>
  </ion-text>
</ion-item>
