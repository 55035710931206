import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CompanyInviteModule } from '../../modals/invites/company-invite/company-invite.module';
import { CompanyInstallerInvitesEffects } from './company-installer-invites.effects';
import { CompanyInternalInvitesEffects } from './company-internal-invites.effects';
import { InvitesUIService } from './invites-ui.service';
import { InvitesEffects } from './invites.effects';
import { InvitesService } from './invites.service';
import { FEATURE_NAME } from './store/feature.name';
import { reducer } from './store/feature.state';
import { LookupsEffects } from './store/lookups.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      CompanyInstallerInvitesEffects,
      CompanyInternalInvitesEffects,
      InvitesEffects,
      LookupsEffects,
    ]),
    CompanyInviteModule,
  ],
  providers: [InvitesService, InvitesUIService],
})
export class CoreInvitesModule {}
