import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { PendingFullWorkOrder } from '../models/pending-full-work-order';
import { WorkOrder } from '../work-order/work-order.model';
import { hasUnsavedEdits } from '../work-order/work-order.selectors';
import { currentWorkOrderEditingStep } from '../work.selectors';
import {
  addNewWorkOrder,
  backOneEditStep,
  cancelEditWorkOrder,
  continueToNextEditStep,
  deleteDraftWorkOrder,
  editCurrentWorkOrder,
  publishWorkOrder,
  republishEditedWorkOrder,
  saveAndExitEditingWorkOrder,
  workOrderInvalid,
} from './work-order-edit.actions';

@Injectable()
export class WorkOrderEditFacade {
  currentEditingStep$ = this.store.select(currentWorkOrderEditingStep);
  hasUnsavedEdits$ = this.store.select(hasUnsavedEdits);

  constructor(private store: Store<AppState>) {}

  addNewWorkOrder() {
    this.store.dispatch(addNewWorkOrder());
  }

  editCurrentWorkOrder() {
    this.store.dispatch(editCurrentWorkOrder());
  }

  nextEditStep(workOrder: Partial<WorkOrder>) {
    this.store.dispatch(continueToNextEditStep({ workOrder }));
  }

  savePendingWorkOrder(pendingWorkOrder: Partial<PendingFullWorkOrder>) {
    this.store.dispatch(saveAndExitEditingWorkOrder({ workOrder: pendingWorkOrder }));
  }

  goBackAnEditStep() {
    this.store.dispatch(backOneEditStep());
  }

  cancelEditWorkOrder(workOrder: Partial<WorkOrder>) {
    this.store.dispatch(cancelEditWorkOrder({ workOrder }));
  }

  deleteWorkOrderDraft(workOrder: Partial<WorkOrder>) {
    this.store.dispatch(deleteDraftWorkOrder({ workOrder }));
  }

  publish(workOrder: Partial<WorkOrder>) {
    this.store.dispatch(publishWorkOrder({ workOrder }));
  }

  republish(workOrder: Partial<WorkOrder>) {
    this.store.dispatch(republishEditedWorkOrder({ workOrder }));
  }

  warnInvalid(errors: any) {
    this.store.dispatch(workOrderInvalid({ errors }));
  }
}
