import { Injectable } from '@angular/core';
import { getKeyFromModel, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { EMPTY, from, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { isRole } from '~gc/domains/app/auth/auth.maps';
import { SettlementTimingUIService } from '~gc/domains/invoices/settlement-timing/settlement-timing-ui.service';
import { waitOn } from '../../shared/utils/operators';
import { ensureChildrenRequired } from '../../shared/utils/rxjs';
import { AppUIService } from '../app-ui.service';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { authenticatedRole, authenticatedUser } from '../app/auth/auth.selectors';
import { loadAllChangeOrders } from '../change-orders/change-order/change-order.state';
import { EmptyKey } from '../entity.service.utils';
import { currentRouteUrl } from '../router.selectors';
import { AppState } from '../state';
import { User } from '../users';
import { WorkOrderItem } from '../work/work-order-item/work-order-item.model';
import { loadAllWorkOrderItems } from '../work/work-order-item/work-order-item.state';
import { WorkOrder } from '../work/work-order/work-order.model';
import { currentWorkOrder, selectWorkOrderById } from '../work/work-order/work-order.state';
import { BankAccount } from './bank-account/bank-account.model';
import { loadAllBankAccounts } from './bank-account/bank-account.state';
import { INVOICES_URL } from './constants';
import {
  approveInvoice,
  archiveInvoice,
  declineInvoice,
  invoiceAction,
  invoiceApprovalConfirmed,
  invoiceArchiveConfirmed,
  invoiceDeclineConfirmed,
  invoicePaymentMethodDetermined,
  invoiceVoidConfirmed,
  payInvoice,
  pendingInvoiceCreated,
  progressApprovalConfirmed,
  progressVoidConfirmed,
  selectInvoiceSettlementTiming,
  showInvoiceOptions,
  signLienRelease,
  signLienReleaseClosed,
  viewInvoice,
  viewLienRelease,
  voidInvoice,
} from './invoice-and-payment.actions';
import { currentInvoicer } from './invoice-and-payment.selectors';
import { loadAllInvoiceItems } from './invoice-item/invoice-item.state';
import { isProgressReportOnly } from './invoice-ui.selectors';
import { InvoiceUIService } from './invoice-ui.service';
import { Invoice, InvoiceStatus } from './invoice/invoice.model';
import {
  deleteInvoiceSuccess,
  loadInvoice,
  selectInvoice,
  updateInvoice,
  updateInvoiceFailure,
  updateInvoiceSuccess,
} from './invoice/invoices.state';
import {
  authorizeLienRelease,
  lienReleaseCompleted,
  lienReleaseSigningFailed,
  lienReleaseSuccessfullySigned,
} from './lien-releases/lien-release.action';
import { lienReleaseForCurrentInvoice } from './lien-releases/lien-release.selector';
import { LienReleaseService } from './lien-releases/lien-release.service';
import { Payment, PaymentStatus } from './payment/payment.model';
import { createPayment, createPaymentSuccess } from './payment/payment.state';

const INVOICE_ACTIONS: Record<string, (...params: any) => Action> = {
  [approveInvoice.type]: invoice => approveInvoice({ invoice }),
  [declineInvoice.type]: invoice => declineInvoice({ invoice }),
  [payInvoice.type]: invoice => payInvoice({ invoice }),
  [signLienRelease.type]: invoice => signLienRelease({ invoice }),
  [viewLienRelease.type]: invoice => viewLienRelease({ invoice }),
  [viewInvoice.type]: invoice => viewInvoice({ invoice }),
  [voidInvoice.type]: invoice => voidInvoice({ invoice }),
  [archiveInvoice.type]: invoice => archiveInvoice({ invoice }),
} as const;

@Injectable()
export class InvoiceActionsEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private invoicesUI: InvoiceUIService,
    private lienReleaseService: LienReleaseService,
    private settlementTimingUI: SettlementTimingUIService,
    private app: AppUIService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  handleInvoiceAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceAction),
      map(({ action, invoice }) => INVOICE_ACTIONS[action](invoice)),
    ),
  );

  confirmApproveInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveInvoice),
      withLatestFrom(this.store.select(currentInvoicer)),
      filter(([, invoicer]) => invoicer?.type === 'INSTALLER_TEAM_LEAD'),
      exhaustMap(([{ invoice }, invoicer]) =>
        from(
          this.invoicesUI.confirmInvoiceAction(
            'Approve this Invoice?',
            `Would you like to approve this invoice from ${invoicer?.firstName ?? 'the installer'}?`,
            'Approve',
          ),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => invoiceApprovalConfirmed({ invoice })),
        ),
      ),
    ),
  );

  confirmApproveProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveInvoice),
      withLatestFrom(this.store.select(currentInvoicer)),
      filter(([, invoicer]) => invoicer?.type === 'EMPLOYEE_INSTALLER'),
      exhaustMap(([{ invoice }, invoicer]) =>
        from(
          this.invoicesUI.confirmInvoiceAction(
            'Approve this Progress?',
            `Would you like to approve this progress from ${invoicer?.firstName ?? 'the installer'}?`,
            'Approve',
          ),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => progressApprovalConfirmed({ invoice })),
        ),
      ),
    ),
  );

  invoiceApprovalConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceApprovalConfirmed, progressApprovalConfirmed),
      map(({ invoice }) => invoice),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([invoice, user]) => ({ invoice, user })),
      ensureChildrenRequired(value => !!value?.user?.id),
      map(({ invoice, user }) => ({
        id: invoice.id,
        workOrderId: invoice.workOrderId,
        approvedAt: formatISO(Date.now()),
        approvedById: user?.id,
        status: invoice.status,
      })),
      map(invoice =>
        updateInvoice({
          entity: invoice,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  confirmDeclineInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(declineInvoice),
      exhaustMap(({ invoice }) =>
        from(this.invoicesUI.confirmDecline(invoice)).pipe(
          filter(({ role }) => role === 'ok'),
          map(({ data }) => invoiceDeclineConfirmed({ invoice: data })),
        ),
      ),
    ),
  );

  invoiceDeclineConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceDeclineConfirmed),
      map(({ invoice }) => ({
        id: invoice.id,
        workOrderId: invoice.workOrderId,
        status: InvoiceStatus.Declined,
        declinedReason: invoice.declinedReason,
      })),
      map(invoice =>
        updateInvoice({
          entity: invoice,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  confirmVoidInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voidInvoice),
      withLatestFrom(this.store.select(isProgressReportOnly)),
      filter(([, progressOnly]) => !progressOnly),
      exhaustMap(([{ invoice }]) =>
        from(
          this.invoicesUI.confirmInvoiceAction(
            'Void Invoice?',
            'Are you sure you want to void this Invoice? This action cannot be undone.',
            'Confirm',
          ),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => invoiceVoidConfirmed({ invoice })),
        ),
      ),
    ),
  );

  confirmVoidProgress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(voidInvoice),
      withLatestFrom(this.store.select(isProgressReportOnly)),
      filter(([, progressOnly]) => progressOnly),
      exhaustMap(([{ invoice }]) =>
        from(
          this.invoicesUI.confirmInvoiceAction(
            'Remove Progress?',
            'Are you sure you want to remove this Progress Report? This action cannot be undone.',
            'Confirm',
          ),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => progressVoidConfirmed({ invoice })),
        ),
      ),
    ),
  );

  invoiceVoidConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceVoidConfirmed, progressVoidConfirmed),
      map(({ invoice }) => ({
        id: invoice.id,
        workOrderId: invoice.workOrderId,
        status: InvoiceStatus.Voided,
      })),
      map(invoice =>
        updateInvoice({
          entity: invoice,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  confirmArchiveInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(archiveInvoice),
      exhaustMap(({ invoice }) =>
        from(
          this.invoicesUI.confirmInvoiceAction(
            'Archive Invoice',
            'Are you sure you want to archive this invoice? This action cannot be undone.',
            'Confirm',
          ),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => invoiceArchiveConfirmed({ invoice })),
        ),
      ),
    ),
  );

  invoiceArchiveConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceArchiveConfirmed),
      map(({ invoice: { id, workOrderId, status } }) => ({
        id,
        workOrderId,
        status,
        isArchived: true,
      })),
      map(entity =>
        updateInvoice({
          entity,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: entity.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  // TODO: Consolidate with invoices.effects for loading on new invoice.
  removeInvoiceAndReloadOnVoid$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(updateInvoiceSuccess),
      filter(({ entity }) => entity.status === InvoiceStatus.Voided),
      switchMap(({ entity }) => [
        deleteInvoiceSuccess({ entity }),
        loadAllWorkOrderItems({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: entity.workOrderId,
            },
          },
        }),
      ]),
    ),
  );

  handleUpdateInvoiceFailure$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(updateInvoiceFailure),
      tap(({ error }) => console.log(error)),
      waitOn(({ error }) => this.invoicesUI.toastInvoiceUpdateError(error)),
      map(({ entity }) =>
        loadInvoice({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: entity.workOrderId,
            },
          },
          keys: getKeyFromModel(Invoice, entity),
        }),
      ),
    ),
  );

  loadOnPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payInvoice),
      map(() => loadAllBankAccounts()),
    ),
  );

  determinePaymentAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payInvoice),
      map(({ invoice }) => invoice),
      withLatestFrom(this.store.select(currentInvoicer), this.store.select(currentWorkOrder)),
      map(([invoice, invoicer, workOrder]) => ({ invoice, invoicer, workOrder })),
      ensureChildrenRequired<
        { invoice: Invoice; invoicer?: User; workOrder?: WorkOrder },
        { invoice: Invoice; invoicer: Required<User>; workOrder: Required<WorkOrder> }
      >(value => !!value?.invoicer && !!value.workOrder?.id),
      exhaustMap(({ invoice, invoicer, workOrder }) =>
        from(this.invoicesUI.showPaymentMethods(invoice, invoicer, workOrder)).pipe(
          filter(result => result.role === 'dismiss'),
          map(({ data }) => invoicePaymentMethodDetermined({ bankAccount: data, invoice })),
        ),
      ),
    ),
  );

  initiatePayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicePaymentMethodDetermined),
      ensureChildrenRequired<
        { invoice: Invoice; bankAccount?: BankAccount },
        {
          invoice: Required<Invoice>;
          bankAccount?: BankAccount;
        }
      >(params => !!params?.invoice?.id),
      map(({ bankAccount, invoice }) => ({
        bankAccountId: bankAccount?.id,
        invoiceId: invoice.id,
        status: PaymentStatus.processing,
        createdOn: new Date().toISOString(),
        workOrderId: invoice.workOrderId,
      })),
      map(payment =>
        createPayment({
          entity: payment,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: payment.workOrderId,
              [uriNameOfEntityOrEmpty(Invoice)]: payment.invoiceId,
            },
          },
        }),
      ),
    ),
  );

  alertPaymentConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPaymentSuccess),
      map(({ entity }) => entity),
      withLatestFrom(this.store.select(currentInvoicer), this.store.select(currentWorkOrder)),
      map(([payment, invoicer, workOrder]) => ({ payment, invoicer, workOrder })),
      ensureChildrenRequired<
        { payment: Payment; invoicer?: User; workOrder?: WorkOrder },
        { payment: Payment; invoicer: Required<User>; workOrder: Required<WorkOrder> }
      >(value => !!value?.invoicer && !!value.workOrder?.id),
      switchMap(({ payment, invoicer, workOrder }) =>
        from(this.invoicesUI.notifyInvoicePaymentConfirmed(invoicer.firstName)).pipe(
          tap(() => this.invoicesUI.closeViewInvoiceModal()),
          map(() =>
            loadInvoice({
              keys: payment.invoiceId,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  selectInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showInvoiceOptions),
      map(({ invoice }) => selectInvoice({ entity: invoice })),
    ),
  );

  selectWorkOrderForViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showInvoiceOptions),
      withLatestFrom(this.store.select(currentRouteUrl)),
      filter(([, url]) => url.startsWith(INVOICES_URL)),
      map(([{ invoice }]) => selectWorkOrderById({ key: invoice.workOrderId })),
    ),
  );

  updateInvoiceStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewInvoice),
      filter(({ invoice }) => invoice.status === InvoiceStatus.New),
      withLatestFrom(this.store.select(authenticatedRole)),
      filter(([, role]) => isRole(role, 'companyManager', 'companyAdmin')),
      map(([{ invoice }]) => ({
        id: invoice.id,
        workOrderId: invoice.workOrderId,
        status: InvoiceStatus.Read,
      })),
      map(invoice =>
        updateInvoice({
          entity: invoice,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  loadInvoiceItemsForViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewInvoice, pendingInvoiceCreated),
      withLatestFrom(this.store.select(currentRouteUrl)),
      filter(([action, url]) => action.type === pendingInvoiceCreated.type || url.startsWith(INVOICES_URL) || url.startsWith('/app/work/orders/detail')),
      map(([{ invoice }]) =>
        loadAllInvoiceItems({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
              [uriNameOfEntityOrEmpty(Invoice)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  loadChangeOrdersForViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewInvoice, pendingInvoiceCreated),
      withLatestFrom(this.store.select(currentRouteUrl)),
      filter(([action, url]) => action.type === pendingInvoiceCreated.type || url.startsWith(INVOICES_URL)),
      map(([{ invoice }]) =>
        loadAllChangeOrders({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
              [uriNameOfEntityOrEmpty(WorkOrderItem)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  loadWorkOrderItemsForViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewInvoice, pendingInvoiceCreated),
      withLatestFrom(this.store.select(currentRouteUrl)),
      filter(([action, url]) => action.type === pendingInvoiceCreated.type || url.startsWith(INVOICES_URL)),
      map(([{ invoice }]) =>
        loadAllWorkOrderItems({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  showViewLienReleaseModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewLienRelease),
        delay(150),
        exhaustMap(({ invoice }) =>
          from(this.invoicesUI.showViewLienReleaseModal(invoice)).pipe(catchError(() => EMPTY)),
        ),
      ),
    { dispatch: false },
  );

  showSignLienReleaseModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signLienRelease),
      exhaustMap(({ invoice }) =>
        from(this.invoicesUI.showSignLienReleaseModal(invoice)).pipe(
          catchError(() => EMPTY),
          map(() => signLienReleaseClosed()),
        ),
      ),
    ),
  );

  selectSettlementTimingForInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectInvoiceSettlementTiming),
      exhaustMap(({ invoice, workOrder, dataUrl }) =>
        from(this.settlementTimingUI.openSelectModal(invoice)).pipe(
          filter(({ role }) => role === 'submit'),
          map(({ data }) => authorizeLienRelease({ invoice, workOrder, dataUrl, settlementTiming: data })),
        ),
      ),
    ),
  );

  authorizeLienRelease$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authorizeLienRelease),
      tap(() => this.app.showBlockingSpinner()),
      withLatestFrom(this.store.select(lienReleaseForCurrentInvoice)),
      map(([{ invoice, workOrder, dataUrl, settlementTiming }, lienRelease]) => ({
        invoice,
        workOrder,
        dataUrl,
        settlementTiming,
        lienRelease,
      })),
      ensureChildrenRequired<
        { invoice: Invoice; workOrder: WorkOrder; dataUrl: string; settlementTiming: any; lienRelease?: any },
        {
          invoice: Required<Invoice>;
          workOrder: Required<WorkOrder>;
          dataUrl: string;
          settlementTiming: any;
          lienRelease: any;
        }
      >(
        value =>
          !!value?.invoice?.id && !!value.workOrder?.id && !!value?.lienRelease?.id && !!value?.settlementTiming?.id,
      ),
      exhaustMap(({ invoice, workOrder, dataUrl, settlementTiming, lienRelease }) =>
        this.lienReleaseService
          .uploadSignature(dataUrl, workOrder.id, invoice.id, lienRelease.id, settlementTiming.id)
          .pipe(
            take(1),
            map(() => lienReleaseSuccessfullySigned({ invoice })),
            catchError(error => of(lienReleaseSigningFailed({ invoice, error }))),
            tap(() => this.app.hideBlockingSpinner()),
          ),
      ),
    ),
  );

  notifyPaymentInitiated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lienReleaseSuccessfullySigned),
      exhaustMap(({ invoice }) =>
        from(this.invoicesUI.notifyPaymentInitiated()).pipe(
          switchMap(() => this.invoicesUI.unwindLienReleaseStack()),
          catchError(() => of(null)),
          map(() => lienReleaseCompleted({ invoice })),
        ),
      ),
    ),
  );

  notifyPaymentFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(lienReleaseSigningFailed),
        exhaustMap(({ invoice }) => from(this.invoicesUI.notifyPaymentFailure()).pipe(catchError(() => of(null)))),
      ),
    { dispatch: false },
  );

  reloadInvoiceOnLienReleaseCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lienReleaseCompleted),
      delay(100),
      map(({ invoice }) =>
        loadInvoice({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
            },
          },
          keys: getKeyFromModel(Invoice, invoice),
        }),
      ),
    ),
  );
}
