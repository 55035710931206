import { createAction, props } from '@ngrx/store';
import { FiltersState } from './filters.state';

export const calendarFlterOptionsRestored = createAction(
  '[CORE: Calendar] (Filter Options) Restored',
  props<{ restored: FiltersState }>(),
);

export const calendarFilterOptionsChanged = createAction(
  '[CORE: Calendar] (Filter Options) Changed',
  props<Partial<FiltersState>>(),
);
