import { Pipe, PipeTransform } from '@angular/core';
import { WorkOrder, WorkOrderStatus } from '../../domains/work/work-order/work-order.model';

@Pipe({
  name: 'isInProgress',
})
export class IsInProgressPipe implements PipeTransform {
  transform(workOrder: WorkOrder): boolean {
    return (
      workOrder?.status === WorkOrderStatus.AwardedNotStarted ||
      workOrder?.status === WorkOrderStatus.InProgressNoInvoices ||
      workOrder?.status === WorkOrderStatus.InProgressHasInvoices
    );
  }
}
