import { Component, Input } from '@angular/core';
import { LienReleaseTemplate } from '../../../domains/invoices/lien-release-template/lien-release-template.model';
import { WorkOrderForm, WorkOrderTagForm } from '../draft-work-order.form';

@Component({
  selector: 'gc-publishing-step',
  templateUrl: './publishing-step.component.html',
  styleUrls: ['./publishing-step.component.scss'],
})
export class PublishingStepComponent {
  @Input() form!: WorkOrderForm;
  @Input() lienReleaseTemplates!: LienReleaseTemplate[];

  tagForm = new WorkOrderTagForm();

  addTag() {
    if (this.tagForm.invalid) {
      return;
    }

    this.form.addTag(this.tagForm.updatedModel);
    this.tagForm.reset();
  }

  removeTag(index: number) {
    this.form.removeTagAt(index);
  }

  public actualValue(value: number) {
    return value;
  }
}
