import { Action, createReducer, on } from '@ngrx/store';
import { WorkingUser } from '../models/working-user';
import {
  backOneProfileEditStep,
  continueToNextProfileEditStep,
  editUserProfile,
  finishProfileEditing,
  initializeWorkingUser,
  managePayments,
  saveInstallerProfile,
  setupPayment,
  stripeUrlRequestError,
  stripeUrlRequestSuccess,
  updateWorkingUser,
  vacationStatusSet,
  vacationStatusToggled,
} from './profile.actions';
import { EDIT_PROFILE_STEPS } from './profile.maps';

export interface ProfileLoadingFlags {
  setupPayments: boolean;
  managePayments: boolean;
  toggleVacation: boolean;
}

export interface ProfileState {
  isEditing: boolean;
  loadingFlags: ProfileLoadingFlags;
  currentEditingStep: string;
  workingUser?: WorkingUser;
}

const initialState: ProfileState = {
  isEditing: false,
  loadingFlags: {
    setupPayments: false,
    managePayments: false,
    toggleVacation: false,
  },
  currentEditingStep: 'basics',
};

export const setNextEditStep = (state: ProfileState): ProfileState => ({
  ...state,
  currentEditingStep: EDIT_PROFILE_STEPS[EDIT_PROFILE_STEPS.indexOf(state.currentEditingStep) + 1],
});

export const setEditStepBack = (state: ProfileState): ProfileState => ({
  ...state,
  currentEditingStep: EDIT_PROFILE_STEPS[EDIT_PROFILE_STEPS.indexOf(state.currentEditingStep) - 1],
});

export const resetEditStep = (state: ProfileState): ProfileState => ({
  ...state,
  currentEditingStep: 'basics',
});

export const setWorkingUser = (state: ProfileState, { workingUser }: { workingUser: WorkingUser }): ProfileState => ({
  ...state,
  workingUser,
});

export const clearWorkingUserProfile = (state: ProfileState): ProfileState => ({
  ...state,
  workingUser: undefined,
});

export const updateWorkingUserProfile = (
  state: ProfileState,
  { workingUser }: { workingUser: WorkingUser },
): ProfileState => ({
  ...state,
  workingUser: {
    ...state.workingUser,
    ...workingUser,
  },
});

export const startEditing = (state: ProfileState): ProfileState => ({ ...state, isEditing: true });
export const stopEditing = (state: ProfileState): ProfileState => ({ ...state, isEditing: false });

export const setLoadingFlag = (flag: keyof ProfileState['loadingFlags']) => (state: ProfileState) => ({
  ...state,
  loadingFlags: {
    ...state.loadingFlags,
    [flag]: true,
  },
});

export const clearLoadingFlag = (flag: keyof ProfileState['loadingFlags']) => (state: ProfileState) => ({
  ...state,
  loadingFlags: {
    ...state.loadingFlags,
    [flag]: false,
  },
});

const reduce = createReducer(
  initialState,
  on(continueToNextProfileEditStep, setNextEditStep),
  on(backOneProfileEditStep, setEditStepBack),
  on(initializeWorkingUser, setWorkingUser),
  on(updateWorkingUser, updateWorkingUserProfile),
  on(saveInstallerProfile, resetEditStep),
  on(editUserProfile, startEditing),
  on(finishProfileEditing, stopEditing),
  on(setupPayment, setLoadingFlag('setupPayments')),
  on(stripeUrlRequestSuccess, stripeUrlRequestError, clearLoadingFlag('setupPayments')),
  on(managePayments, setLoadingFlag('managePayments')),
  on(stripeUrlRequestSuccess, stripeUrlRequestError, clearLoadingFlag('managePayments')),
  on(vacationStatusToggled, setLoadingFlag('toggleVacation')),
  on(vacationStatusSet, clearLoadingFlag('toggleVacation')),
);

export function profileReducer(state = initialState, action: Action): ProfileState {
  return reduce(state, action);
}
