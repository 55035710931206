import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { DateNegotiationComponent } from '~gc/shared/components/installer-review/date-negotiation/date-negotiation.component';
import { PipesModule } from '../../pipes/pipes.module';
import { ContentModule } from '../content/content.module';
import { HammerRatingDetailsModule } from '../hammer-rating-details/hammer-rating-details.module';
import { HammerRatingModule } from '../hammer-rating/hammer-rating.module';
import { NegotiableItemListModule } from '../negotiable-item-list/negotiable-item-list.module';
import { UserHeaderModule } from '../user-header/user-header.module';
import { CompanyUserInstallerOptionsComponent } from './company-user-installer-options/company-user-installer-options.component';
import { InstallerCardComponent } from './installer-card/installer-card.component';
import { ReviewInstallerModalComponent } from './review-installer-modal/review-installer-modal.component';
import { ReviewInstallersModalComponent } from './review-installers-modal/review-installers-modal.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    UserHeaderModule,
    PipesModule,
    HammerRatingModule,
    HammerRatingDetailsModule,
    NegotiableItemListModule,
    PushModule,
    ContentModule,
  ],
  declarations: [
    ReviewInstallersModalComponent,
    ReviewInstallerModalComponent,
    InstallerCardComponent,
    CompanyUserInstallerOptionsComponent,
    DateNegotiationComponent,
  ],
  exports: [ReviewInstallersModalComponent, ReviewInstallerModalComponent],
})
export class InstallerReviewModule {}
