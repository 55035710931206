import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LongRunningRequestService implements HttpInterceptor {
  private readonly TIMEOUT = 5000; // 5 seconds

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();

    return next.handle(request).pipe(
      tap(event => {
        if (event.type === 0) {
          // Request sent
          const elapsed = Date.now() - started;
          if (elapsed > this.TIMEOUT) {
            console.warn(`Long running request: ${request.method} ${request.urlWithParams}`);
            // TODO: Show a message to the user
          }
        }
      }),
    );
  }
}
