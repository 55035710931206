import { Component, Input } from '@angular/core';
import { UnitOfMeasure } from '~gc/domains/unit-of-measure/unit-of-measure.model';
import { WorkOrderItem } from '~gc/domains/work/work-order-item/work-order-item.model';
import { WorkOrderForm } from '../draft-work-order.form';

@Component({
  selector: 'gc-items-step',
  templateUrl: './items-step.component.html',
  styleUrls: ['./items-step.component.scss'],
})
export class ItemsStepComponent {
  @Input() form!: WorkOrderForm;
  @Input() unitsOfMeasure!: UnitOfMeasure[];
  @Input() workOrderItems!: WorkOrderItem[];

  createItem() {
    if (this.form.workingItem.invalid) {
      return;
    }

    this.form.endAddItem();
  }
}
