import { Component, Input } from '@angular/core';
import { PhoneNumber } from '../../../domains/phone-number';

@Component({
  selector: 'gc-phone-number-list',
  templateUrl: './phone-number-list.component.html',
  styleUrls: ['./phone-number-list.component.scss'],
})
export class PhoneNumberListComponent {
  @Input() isLoading = false;
  @Input() phoneNumbers?: PhoneNumber[] = [];
}
