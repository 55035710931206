<ng-template #view>
  <ion-list>
    <ion-list-header>
      <ion-label color="medium"> Phone Numbers</ion-label>
    </ion-list-header>
    <ng-container *ngIf="phoneNumbers?.length">
      <ion-item *ngFor="let phoneNumber of phoneNumbers" [href]="'tel:' + phoneNumber.number">
        <ion-label>
          {{ phoneNumber.number | phoneFormat }}
        </ion-label>
        <ng-container [ngSwitch]="phoneNumber.type">
          <ion-icon name="radio" slot="end" *ngSwitchCase="'MOBILE'"></ion-icon>
          <ion-icon name="call" slot="end" *ngSwitchCase="'OFFICE'"></ion-icon>
          <ion-icon name="help-circle" slot="end" *ngSwitchCase="'OTHER'"></ion-icon>
        </ng-container>
      </ion-item>
    </ng-container>

    <gc-empty-list-warning *ngIf="!phoneNumbers?.length">No Phone Numbers Found</gc-empty-list-warning>
  </ion-list>
</ng-template>

<ng-container *ngIf="isLoading; else view">
  <ion-list>
    <ion-list-header>
      <ion-label color="medium">Phone Numbers</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
  </ion-list>
</ng-container>
