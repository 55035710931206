<ion-header>
  <ion-toolbar>
    <ion-title>Manage {{ installTypes.currentTitle$ | push }}</ion-title>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="skills.cancelEditSkillsAndExperience()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <gc-start-date
    *ngIf="skills.currentClaimedProductInstallationType$ | push"
    [installationType]="skills.currentClaimedProductInstallationType$ | push"
    (changed)="skills.updateProductInstallationTypeClaim($event)"
  ></gc-start-date>

  <gc-claimed-trainings-list
    [installationType]="skills.currentClaimedProductInstallationType$ | push"
    [categories]="skills.currentInstallTypeCategories$ | push"
    [claims]="skills.currentUserTrainingsObtainedForCurrentInstallType$ | push"
    (added)="skills.showUnclaimedTrainingSelection($event.category, $event.claimedInstallType)"
    (deleted)="skills.confirmDeletionOfTrainingClaimed($event)"
  ></gc-claimed-trainings-list>

  <gc-experience-documentation
    [installationType]="skills.currentClaimedProductInstallationType$ | push"
    [files]="skills.trainingFiles$ | push"
    (fileAdded)="skills.addTrainingFile($event)"
  ></gc-experience-documentation>
</ion-content>
