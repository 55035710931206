import { Pipe, PipeTransform } from '@angular/core';
import { isCompanyUser, User } from '../../domains/users';

export const isMyCompanyAdmin = (user: User, authUser: User): boolean =>
  isCompanyUser(user) &&
  isCompanyUser(authUser) &&
  user.companyId === authUser.companyId &&
  user.type === 'COMPANY_ADMIN';

@Pipe({ name: 'isMyCompanyAdmin' })
export class IsMyCompanyAdminPipe implements PipeTransform {
  transform(user: User, authUser: User): boolean {
    return isMyCompanyAdmin(user, authUser);
  }
}
