import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AuthFacade } from '~gc/domains/app/auth/auth.facade';
import { isManager } from '~gc/domains/users';
import { WorkOrderDate } from '../../../core/calendar';
import { CustomDate } from '../../../domains/custom-dates';
import { CustomDateForm, CustomDateFormArray } from './schedule.form';

@Component({
  selector: 'gc-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent {
  @Input() enableCommands = false;
  @Input() enableAdd = true;
  @Input() formArray!: CustomDateFormArray;
  @Input() showHeader = false;
  @Input() compactMode = false;
  @Output() added = new EventEmitter<WorkOrderDate>();
  @Output() saved = new EventEmitter<WorkOrderDate>();
  @Output() deleted = new EventEmitter<WorkOrderDate>();

  showModificationButtons$ = this.auth.user$.pipe(map(user => isManager(user!)));



  constructor(
    private readonly alerts: AlertController,
    public readonly auth: AuthFacade,
    private readonly store: Store
  ) {}

  trackDatesBy = (_: number, date: CustomDateForm) => {
    return date.originalModel.id;
  };

  save(formArray: CustomDateFormArray, date?: CustomDate, dateForm?: CustomDateForm) {
    if (!date || formArray.newlyAdded?.isIncomplete) {
      return;
    }

    formArray.newlyAdded || date
      ? (this.saved.emit(formArray.newlyAdded?.updatedModel || date),
        (formArray.newlyAdded || dateForm)?.completeEdit())
      : void 0;
  }
}
