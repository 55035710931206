import { createSelector } from '@ngrx/store';
import { allEmployeeInstallers } from '../../../domains/users/user/user.selectors';
import { makeSelectables, searchByCriteria, view } from '../../../shared/utils/ngrx';
import { publishSearchCriteria, selectedInstallerIds } from '../store/ui.selectors';

export const filteredEmployees = createSelector(
  allEmployeeInstallers,
  publishSearchCriteria,
  view('filteredConnections', searchByCriteria('firstName', 'lastName', 'email', 'id'), false),
);

export const selectableFilteredEmployees = createSelector(
  filteredEmployees,
  selectedInstallerIds,
  view('selectableFilteredEmployees', makeSelectables, false),
);
