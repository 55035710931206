import { Pipe, PipeTransform } from '@angular/core';
import { NegotiationMode } from '~gc/modals/bid-ask-negotiation/negotiation-mode';

const BidAskModeMap = {
  [NegotiationMode.Company]: 'Ask',
  [NegotiationMode.Installer]: 'Bid',
};

const BidAskModeInverseMap = {
  [NegotiationMode.Company]: 'Bid',
  [NegotiationMode.Installer]: 'Ask',
};

@Pipe({ name: 'negotiationModeFormat' })
export class NegotiationModeFormatPipe implements PipeTransform {
  transform(mode: NegotiationMode, formatString: string, isInverse = false): string {
    return formatString.replace('%mode%', isInverse ? BidAskModeInverseMap[mode] : BidAskModeMap[mode]);
  }
}
