<ng-container *ngIf="installationType && installationType.claimed && installationType.claimed.dateClaimed">
  <ion-list *ngFor="let category of categories">
    <ion-list-header>
      <ion-label>
        <h2 class="flex">
          {{ category.type }}
          <ion-button
            fill="clear"
            color="gc-green"
            size="small"
            (click)="added.emit({ category: category, claimedInstallType: installationType.claimed })"
          >
            <ion-icon name="add" slot="start"></ion-icon>
            Add
          </ion-button>
        </h2>
      </ion-label>
    </ion-list-header>
    <ion-item *ngIf="claims | forCategory: category | isEmpty; else claimsList">
      <ion-label>
        <ion-text color="dark"
          ><small><em>No trainings claimed for this category.</em></small></ion-text
        >
      </ion-label>
    </ion-item>
    <ng-template #claimsList>
      <ion-item *ngFor="let claim of claims | forCategory: category">
        <ion-label class="ion-align-self-center" style="white-space: normal">
          <h2 class="flex">
            {{ claim.training?.certification }}
          </h2>
        </ion-label>
        <ion-buttons slot="end">
          <ion-button (click)="deleted.emit(claim)" *ngIf="!claim.isVerified">
            <ion-icon fill="clear" size="small" color="danger" name="trash" slot="icon-only"></ion-icon>
          </ion-button>
          <ion-badge slot="end" [color]="claim.isVerified ? 'gc-green' : 'gc-yellow'" mode="ios">
            {{ claim.isVerified ? 'Verified' : 'Pending' }}
          </ion-badge>
        </ion-buttons>
      </ion-item>
    </ng-template>
  </ion-list>
</ng-container>
