<ion-list lines="none">
  <ion-list-header>Select Project Manager</ion-list-header>

  <ion-radio-group [value]="workOrder.projectManagerId">
    <ion-item
      *ngFor="let projectManager of projectManagers"
      (click)="popoverGC.dismiss(projectManager.id)"
      detail="false"
      button
    >
      <ion-avatar slot="start">
        <ion-img [src]="projectManager.profilePhoto"></ion-img>
      </ion-avatar>
      <ion-label> {{ projectManager.firstName }} {{ projectManager.lastName }} </ion-label>
      <ion-radio slot="end" [value]="projectManager.id"></ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-list>
