import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { productInstallationTypeClaimed } from '~gc/core/installer-profile/product-installation-type.actions';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';
import { ensureChildrenRequired } from '../../shared/utils/rxjs';
import { claimProductInstallationTypeDateChosen } from './claim-product-installation-type-modal.actions';
import { installTypeBeingClaimed } from './store/ui.selectors';


@Injectable()
export class ClaimProductInstallationTypeModalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  beginClaimProductInstallationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimProductInstallationTypeDateChosen),
      withLatestFrom(this.store.select(installTypeBeingClaimed)),
      map(([{ date }, installType]) => ({ date, installType })),
      ensureChildrenRequired<{ date: string; installType?: ProductInstallationType }, { date: string; installType: ProductInstallationType }>(
        value => !!value?.installType && !!value.date,
      ),
      map(({ date, installType }) => productInstallationTypeClaimed({ installType, date })),
    ),
  );
}
