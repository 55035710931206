<gc-work-order-information
  [workOrder]="workOrders.currentDetailedWorkOrder$ | push"
  [permissions]="permissions$ | push"
  (navigate)="workOrders.navigateToWorkOrderAddress($event)"
></gc-work-order-information>

<gc-work-order-tags
  *ngIf="permissions$ | push | hasPermissions: 'work-order.tags:view'"
  [tags]="workOrderTags.all$ | push"
  [workOrder]="workOrders.current$ | push"
  [permissions]="permissions$ | push"
  (added)="workOrderTags.create($event.tag, { parents: { 'work-orders': $event.workOrder.id } })"
  (deleted)="workOrderTags.delete($event.tag, { parents: { 'work-orders': $event.workOrder.id } })"
></gc-work-order-tags>

<ion-item lines="none">
  <small slot="end">Work Order ID: {{ (workOrders.currentDetailedWorkOrder$ | push)?.id }}</small>
</ion-item>
