import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Connection } from '~gc/domains/users/connection/connection.model';
import { NetworkConnectionModule } from '../../modals/network-connection/network-connection.module';
import { EntityService } from '../entity.service';
import { CompaniesFacade } from './companies/company.facade';
import { Company } from './companies/company.model';
import { HammerRatingFacade } from './hammer-rating/hammer-rating.facade';
import { HammerRating } from './hammer-rating/hammer-rating.model';
import { JumpstarterFavoriteEffects } from './jumpstarter-favorites/jumpstarter-favorite.effects';
import { JumpstarterFavorite } from './jumpstarter-favorites/jumpstarter-favorite.model';
import { JumpstarterFavoritesFacade } from './jumpstarter-favorites/jumpstarter-favorites.facade';
import { KudosReason } from './kudos-reason/kudos-reason.model';
import { NetworkFacade } from './network.facade';
import { NetworksUIService } from './networks-ui.service';
import { ProfileFacade } from './profile/profile.facade';
import { ProfilesUIService } from './profile/profiles-ui.service';
import { TeamUIService } from './team/team-ui.service';
import { TeamFacade } from './team/team.facade';
import { TeamService } from './team/team.service';
import { UserFile } from './user-files/user-file.model';
import { UserFacade } from './user/user.facade';
import { User } from './user/user.model';
import { UsersUIService } from './users-ui.service';

@NgModule({
  imports: [NetworkConnectionModule, EffectsModule.forFeature([JumpstarterFavoriteEffects])],
  providers: [
    CompaniesFacade,
    HammerRatingFacade,
    JumpstarterFavoritesFacade,
    TeamFacade,
    TeamService,
    TeamUIService,
    UserFacade,
    UsersUIService,
    ProfileFacade,
    ProfilesUIService,
    NetworkFacade,
    NetworksUIService,
    { provide: Company, useClass: EntityService },
    { provide: JumpstarterFavorite, useClass: EntityService },
    { provide: User, useClass: EntityService },
    { provide: UserFile, useClass: EntityService },
    { provide: HammerRating, useClass: EntityService },
    { provide: Connection, useClass: EntityService },
    { provide: KudosReason, useClass: EntityService },
  ],
})
export class UserModule {}
