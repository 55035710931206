import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-information-message',
  templateUrl: './information-message.component.html',
  styleUrls: ['./information-message.component.scss'],
})
export class InformationMessageComponent {
  @Input() message = 'Once your profile is complete and approved, you’ll have access to this section.';
  @Input() horizontal = false;
  @Input() imagePath: string | undefined;
}
