import appPackage from '../../package.json';

export const rootDomain: string = 'gocarrera.com';
export const apiVersion: string = '/v0';
export const version = appPackage?.version ?? '0.0.0';
export const announcementVersion = 1;
export const maximumAllowableInvoiceAmount = 75000;
export const maximumAllowableItemAmount = 100000;
// User Types to show announcement modal for. Comment and uncomment to update.
export const announcementUserTypes: string[] = [
  // 'COMPANY_PROJECT_MANAGER',
  'INSTALLER_TEAM_LEAD',
  // 'INSTALLER_TEAM_MEMBER',
  // 'EMPLOYEE_INSTALLER',
  // 'COMPANY_ADMIN',
];
