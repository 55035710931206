import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ensureExists } from '../../shared/utils/rxjs';
import { authenticatedUserMetadataRetrieved } from '../app/auth/auth.actions';
import { authenticatedUser } from '../app/auth/auth.selectors';
import { BrowserService } from '../browser.service';
import { AppState } from '../state';
import { isInstallerLead, User } from '../users';
import { loadAllDiscountCodes } from './discount-codes/discount-codes.state';
import { openT4FDiscountForm } from './partner-deals.actions';

@Injectable()
export class PartnerDealsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private browser: BrowserService,
  ) {}

  loadDiscountCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticatedUserMetadataRetrieved),
      filter(({ user }) => isInstallerLead(user)),
      map(({ user }) =>
        loadAllDiscountCodes({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
            },
          },
        }),
      ),
    ),
  );

  openT4FDiscountForm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openT4FDiscountForm),
        withLatestFrom(this.store.select(authenticatedUser)),
        map(([, user]) => user),
        ensureExists(user => !!user),
        map(
          user =>
            'https://gocarrera.com/industry/t4f/10-off-request/?utm_source=go-carrera&utm_medium=app&firstName=' +
            user.firstName +
            '&lastName=' +
            user.lastName +
            '&email=' +
            user.email,
        ),
        switchMap(url => this.browser.openUrl(url)),
      ),
    { dispatch: false },
  );
}
