import { Validators } from '@angular/forms';
import { Observable, PartialObserver } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserQuery } from '../../core/invites/models/user-query.model';
import { InvitedUser } from '../../domains/users';
import { ensureExists } from '../../shared/utils/rxjs';
import { TypedFormGroup } from '../../shared/utils/typed-forms';

export class InviteForm extends TypedFormGroup<InvitedUser> {
  readonly email = this.getFormControl('email');
  readonly note = this.getFormControl('note');
  readonly firstName = this.getFormControl('firstName');
  readonly lastName = this.getFormControl('lastName');

  get isEmpty(): boolean {
    return false;
  }

  override get updatedModel(): InvitedUser {
    return {
      ...(super.updatedModel as InvitedUser),
      fullName: `${this.firstName.value ?? ''} ${this.lastName.value ?? ''}`,
    };
  }

  constructor({
    emailChanges,
    listener,
  }: {
    emailChanges: PartialObserver<string>;
    listener?: Observable<Partial<UserQuery> | null | undefined>;
  }) {
    super({
      config: {
        controls: {
          email: [undefined, Validators.email],
          note: [undefined],
          firstName: [undefined, Validators.required],
          lastName: [undefined, Validators.required],
        },
      },
      changesOf: {
        email: emailChanges,
      },
      listener: listener?.pipe(
        ensureExists(query => !!query),
        // map(({ email, firstName, lastName }) => ({ email, firstName, lastName })),
        tap(found => this.patchValue(found!)), // TS compiler is failing here, as the ensureExist type guard should GUARANTEE that found CANNOT be undefined!!!
      ),
    });
  }

  foundUser(user: UserQuery) {
    this.patchValue({ firstName: user.firstName, lastName: user.lastName });
  }
}
