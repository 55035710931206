import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity('JumpstarterFavorite', { uriName: 'jumpstarter-favorites' })
export class JumpstarterFavorite {
  @Key id!: 0;
  jumpstarterId!: string;
  installerTeamLeadId!: string;
  addToTeamStatus!: TeamMatchStatus;
}

export enum TeamMatchStatus {
  PENDING = 'PENDING',
  INVITED = 'INVITED',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}
