import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HammerRatingChartComponent } from './hammer-rating-chart.component';



@NgModule({
  declarations: [HammerRatingChartComponent],
  exports: [
    HammerRatingChartComponent
  ],
  imports: [
    CommonModule
  ]
})
export class HammerRatingChartModule { }
