import { createSelector } from '@ngrx/store';
import { selectedCategory } from '../../core/installer-profile';
import { Training } from '../../domains/training/training/training.model';
import { allTrainings } from '../../domains/training/training/training.state';
import { TrainingClaimed } from '../../domains/training/trainings-claimed/trainings-claimed.model';
import { allTrainingsClaimed } from '../../domains/training/trainings-claimed/trainings-claimed.state';
import { ItemChooserOption } from '../../shared/components/item-chooser-list/item-chooser-option.model';
import { ClaimTrainingContext } from './models/claim-training-context.model';
import { added, claiming, pending } from './store/ui.selectors';

export const trainingsForCategory = createSelector(
  selectedCategory,
  allTrainings,
  (category, trainings): Training[] | undefined =>
    category ? trainings.filter(training => training.categoryId === category.id) : undefined,
);

export const claimedTrainingsForCategory = createSelector(
  selectedCategory,
  allTrainingsClaimed,
  (category, trainingsClaimed): TrainingClaimed[] | undefined =>
    category ? trainingsClaimed.filter(trainingClaimed => trainingClaimed.categoryId === category.id) : undefined,
);

export const unclaimedTrainingsForCategory = createSelector(
  trainingsForCategory,
  claimedTrainingsForCategory,
  added,
  (trainings, trainingsClaimed, addedIds): Training[] | undefined =>
    trainings
      ? trainings.filter(
          training =>
            addedIds?.some(id => id === training.id) ||
            !trainingsClaimed?.some(trainingClaimed => trainingClaimed.trainingId === training.id),
        )
      : undefined,
);

export const unclaimedTrainingChooserOptions = createSelector(
  unclaimedTrainingsForCategory,
  pending,
  claiming,
  added,
  (unclaimedTrainings, pendingTraining, claimingTraining, addedIds): ItemChooserOption<Training>[] | undefined =>
    unclaimedTrainings
      ? unclaimedTrainings.map(training => ({
          id: training.id,
          label: training.certification,
          value: training,
          added: addedIds?.some(id => id === training.id) ?? false,
          adding: claimingTraining?.id === training.id || pendingTraining?.id === training.id,
        }))
      : undefined,
);

export const claimTrainingContext = createSelector(
  selectedCategory,
  unclaimedTrainingChooserOptions,
  (category, items): ClaimTrainingContext | undefined =>
    category
      ? {
          category,
          items,
        }
      : undefined,
);
