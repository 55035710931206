import { Injectable } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { ReportProgressComponent } from '../../modals/invoicing/report-progress/report-progress.component';
import { ReviewProgressModalComponent } from '../../modals/invoicing/review-progress-modal/review-progress-modal.component';
import { tap } from '../../shared/utils/utils';
import { RouterOutletService } from '../router-outlet.service';

@Injectable()
export class ProgressUIService {
  private currentReportProgressModal?: HTMLIonModalElement;
  private currentReviewProgressModal?: HTMLIonModalElement;

  constructor(
    private readonly modals: ModalController,
    private readonly toasts: ToastController,
    private readonly alerts: AlertController,
    private readonly routerOutletService: RouterOutletService,
  ) {}

  async showReportProgressModal(): Promise<void> {
    if (this.currentReportProgressModal) {
      return Promise.resolve(void 0);
    }

    this.currentReportProgressModal = await this.modals.create({
      component: ReportProgressComponent,
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      backdropDismiss: false,
    });

    this.currentReportProgressModal.onDidDismiss().then(() => {
      this.currentReportProgressModal = undefined;
    });

    await this.currentReportProgressModal.present();
  }

  async showReviewProgressModal(): Promise<OverlayEventDetail | void> {
    if (this.currentReviewProgressModal) {
      return Promise.resolve(void 0);
    }

    this.currentReviewProgressModal = await this.modals.create({
      component: ReviewProgressModalComponent,
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      backdropDismiss: false,
    });

    await this.currentReviewProgressModal.present();

    return this.currentReviewProgressModal
      .onDidDismiss()
      .then(tap(() => (this.currentReviewProgressModal = undefined)));
  }

  async toastInsufficientPermissionsToInvoice(invoiceType: string): Promise<void> {
    const toast = await this.toasts.create({
      message: `You do not have permission to ${invoiceType} this Work Order.`,
      duration: 5000,
      position: 'bottom',
      color: 'danger',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }

  async confirmPendingProgress(): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header: 'Send Progress Report',
      message: `Are you sure you want to report this progress?`,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        { text: 'Send', role: 'ok' },
      ],
      backdropDismiss: false,
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async unwindAddReviewModalStack() {
    await this.currentReportProgressModal?.dismiss();
    await this.currentReviewProgressModal?.dismiss();
  }
}
