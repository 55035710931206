import { createSelector } from '@ngrx/store';
import { dwollaAccountsState } from './dwolla-accounts.state';
import { BeneficialOwnersStatus, BusinessCustomerStatus } from './models/customer-details.model';

export const isTransferringBalance = createSelector(dwollaAccountsState, state => state.transferringBalance);
export const isLoading = createSelector(dwollaAccountsState, state => state.loading);
export const dwollaAccount = createSelector(dwollaAccountsState, state => state.dwollaAccount);
export const dwollaCustomerDetails = createSelector(dwollaAccountsState, state => state.customerDetails);
export const initialDwollaLoadComplete = createSelector(dwollaAccountsState, state => state.initialLoadComplete);

export const dwollaAccountLink = createSelector(dwollaAccount, account => account?.link);
export const hasDwollaAccount = createSelector(dwollaAccount, account => !!account);
export const isAccountEnabled = createSelector(dwollaAccount, account => account?.enabled);
export const hasAccountBalance = createSelector(
  dwollaAccount,
  account => parseFloat(account?.dwollaBalance || '0') > 0,
);

export const hasCustomerDetails = createSelector(dwollaCustomerDetails, details => !!details);
export const dwollaFundingSources = createSelector(dwollaCustomerDetails, details => details?.fundingSources || []);
export const documentUploadNeeded = createSelector(
  dwollaCustomerDetails,
  details =>
    details?.type === 'business' &&
    (details?.status === 'document' ||
      details?.beneficialOwners.some(({ verificationStatus }) => verificationStatus === 'document')),
);
export const requiresBeneficialOwnershipCertification = createSelector(
  dwollaCustomerDetails,
  details => details?.type === 'business' && details?.requiresBeneficialOwnershipCertification,
);

export const hasVerifiedFundingSource = createSelector(dwollaFundingSources, sources =>
  sources.some(source => source.status === 'verified' || 'unverified'),
);

export const shouldShowCustomerCreate = createSelector(
  hasDwollaAccount,
  hasCustomerDetails,
  documentUploadNeeded,
  requiresBeneficialOwnershipCertification,
  (hasAccount, hasDetails, needsUpload, requiresCertification) =>
    !hasDetails || !hasAccount || needsUpload || requiresCertification,
);

const ExpectedReceiveOnlyStatus = ['verified', 'unverified'] as const;
const ExpectedBusinessStatuses: BusinessCustomerStatus[] = ['verified', 'document'];
const ExpectedBeneficialOwnerStatuses: BeneficialOwnersStatus[] = ['verified', 'document'];

export const dwollaAccountInUnexpectedStatus = createSelector(
  dwollaAccount,
  dwollaCustomerDetails,
  (account, details) =>
    details &&
    !(details.type === 'receive-only'
      ? ExpectedReceiveOnlyStatus.includes(details.status)
      : ExpectedBusinessStatuses.includes(details?.status) &&
        details.beneficialOwners.every(({ verificationStatus }) =>
          ExpectedBeneficialOwnerStatuses.includes(verificationStatus),
        )),
);
