import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { NGRX_AUTO_ENTITY_APP_STORE } from '@briebug/ngrx-auto-entity';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import { IonicModule, IonicRouteStrategy, isPlatform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { MaskitoModule } from '@maskito/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { PushModule } from '@rx-angular/template/push';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { StreamAutocompleteTextareaModule, StreamChatModule } from 'stream-chat-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StateModule } from './domains/state.module';
import { AnnouncementModalModule } from './modals/announcement-modal/announcement-modal.module';
import { LiveUpdateModalModule } from './modals/live-update-modal/live-update-modal.module';
import { UnapprovedInstallerModule } from './modals/unapproved-installer/unapproved-installer.module';
import { LoginLandingComponent } from './pages/login-landing/login-landing.component';
import { LoginFormComponent } from './pages/login/login-form/login-form.component';
import { LoginPage } from './pages/login/login.page';
import { SignupFormComponent } from './pages/signup/signup-form/signup-form.component';
import { SignupPage } from './pages/signup/signup.page';
import { ChatModule } from './shared/chat/chat.module';
import { CustomBannerModule } from './shared/components/custom-banner/custom-banner.module';
import { InstallerReviewModule } from './shared/components/installer-review/installer-review.module';
import { DwollaModule } from './shared/dwolla/dwolla.module';
import { AuthSessionInterceptor } from './shared/interceptors/auth-session.interceptor';
import { AuthInterceptorService } from './shared/interceptors/auth.interceptor';
import { LongRunningRequestService } from './shared/interceptors/long-running-request.interceptor';
import { VersionInterceptor } from './shared/interceptors/version.interceptor';
import { ZipCodeValidationCacheInterceptor } from './shared/interceptors/zip-code-validation-cache.interceptor';
import { PipesModule } from './shared/pipes/pipes.module';
import { PrintModule } from './shared/print/print.module';
import { GoogleMapsService } from './shared/services/google-maps.service';
import { LynkService } from './shared/services/lynk.service';
import { AsyncAddressValidators } from './shared/validators/async-address.validators';

export function provideAppStore(store: Store) {
  return store;
}

const getConfig = () => {
  if (isPlatform('desktop')) {
    return {
      mode: 'ios',
    };
  }
  return undefined;
};

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent, LoginPage, SignupPage, LoginFormComponent, LoginLandingComponent, SignupFormComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true, animated: false }),
    TranslateModule.forRoot(),
    StreamAutocompleteTextareaModule,
    StreamChatModule,
    ChatModule,
    StateModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CustomBannerModule,
    InstallerReviewModule,
    FullCalendarModule,
    FontAwesomeModule,
    PipesModule,
    PushModule,
    PrintModule,
    LiveUpdateModalModule,
    AnnouncementModalModule,
    UnapprovedInstallerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    DwollaModule.forRoot(),
    MaskitoModule,
    NgxTippyModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GoogleMapsService,
    LynkService,
    AsyncAddressValidators,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthSessionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LongRunningRequestService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: VersionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ZipCodeValidationCacheInterceptor,
      multi: true,
    },
    {
      provide: NGRX_AUTO_ENTITY_APP_STORE,
      useFactory: provideAppStore,
      deps: [Store],
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(addressValidators: AsyncAddressValidators, storage: Storage) {}
}
