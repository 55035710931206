<ng-container *ngIf="permissions | hasPermissions: 'work-order.change-orders:view'">
  <ng-container
    *ngIf="
      (userType | isRole: 'companyManager' : 'companyAdmin') &&
      (group | typeIsIn: 'change-add') &&
      (displayGroups | itemGroupsAllowed: 'change-add')
    "
  >
    <ng-container *ngIf="item.changeStatus === 'PENDING_ACCEPTANCE'">
      <p>Pending Acceptance</p>
      <ion-button
        data-cy="void-change-order-item-button-pending-acceptance"
        *ngIf="permissions | hasPermissions: 'work-order.change-order:void'"
        color="danger"
        mode="ios"
        (click)="voided.emit(item)"
      >
        <ion-icon name="trash" slot="start"></ion-icon>
        Void Change Order Item
      </ion-button>
    </ng-container>
  </ng-container>

  <ng-container
    *ngIf="
      (userType | isRole: 'installerLead') &&
      (group | typeIsIn: 'installer-add') &&
      (displayGroups | itemGroupsAllowed: 'installer-add')
    "
  >
    <ng-container *ngIf="item.changeStatus === 'PENDING_ACCEPTANCE'">
      <p>Pending Approval</p>
      <ion-button
        data-cy="void-change-order-item-button-pending-approval"
        *ngIf="permissions | hasPermissions: 'work-order.change-order:void'"
        color="danger"
        mode="ios"
        (click)="voided.emit(item)"
      >
        <ion-icon name="trash" slot="start"></ion-icon>
        Void Change Order Item
      </ion-button>
    </ng-container>
  </ng-container>
</ng-container>
