import { createSelector } from '@ngrx/store';
import { allProductInstallationTypes } from '../../domains/training/product-installation-types/product-installation-types.state';
import { allHammerRatings } from '../../domains/users/hammer-rating/hammer-rating.state';
import { view } from '../../shared/utils/ngrx';
import { ExtendedHammerRatingDetails } from './models/extended-hammer-rating.details.model';
import { currentInstaller } from './store/profile.selectors';


export const MAX_HAMMER_RATING = 5;

export const currentInstallerHammerRatingCount = createSelector(
  allHammerRatings,
  currentInstaller,
  (ratings, installer): number | undefined => installer?.hammerRatings?.length,
);

export const currentInstallerHammerRatingDetails = createSelector(
  allProductInstallationTypes,
  currentInstaller,
  view(
    'currentInstallerHammerRatingDetails',
    (types, installer): ExtendedHammerRatingDetails[] | undefined =>
      installer?.hammerRatings?.map(rating => ({
        rating: rating.value,
        maxRating: MAX_HAMMER_RATING,
        ratingRemainder: MAX_HAMMER_RATING - rating.value,
        category: types.find(type => type.id === rating.productInstallationTypeId)?.type,
        productInstallationTypeId: rating.productInstallationTypeId,
      })),
    true,
    true,
  ),
);
