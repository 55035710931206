import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'State',
  uriName: 'states',
})
export class State {
  @Key code!: string;
  stateOrProvince!: string;
  countryCode!: string;
}
