import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SettlementTimingUIService } from '~gc/domains/invoices/settlement-timing/settlement-timing-ui.service';
import { SettlementTimingEffects } from '~gc/domains/invoices/settlement-timing/settlement-timing.effects';
import { SettlementTiming } from '~gc/domains/invoices/settlement-timing/settlement-timing.entity';
import { SettlementTimingFacade } from '~gc/domains/invoices/settlement-timing/settlement-timing.facade';
import {
  SETTLEMENT_TIMING_FEATURE_KEY,
  settlementTimingReducer,
} from '~gc/domains/invoices/settlement-timing/settlement-timing.state';
import { PayInvoiceModule } from '~gc/modals/pay-invoice/pay-invoice.module';
import { EntityService } from '../entity.service';
import { BankAccountFacade } from './bank-account/bank-account.facade';
import { BankAccount } from './bank-account/bank-account.model';
import { InvoiceAndPaymentFacade } from './invoice-and-payment.facade';
import { InvoiceCountFacade } from './invoice-count/invoice-count.facade';
import { InvoiceCount } from './invoice-count/invoice-count.model';
import { InvoiceFileEffects } from './invoice-file/invoice-file.effects';
import { InvoiceFilesFacade } from './invoice-file/invoice-file.facade';
import { InvoiceFile } from './invoice-file/invoice-file.model';
import { InvoiceItemEffects } from './invoice-item/invoice-item.effects';
import { InvoiceItem } from './invoice-item/invoice-item.model';
import { InvoiceItemsFacade } from './invoice-item/invoice-items.facade';
import { InvoiceUIService } from './invoice-ui.service';
import { Invoice } from './invoice/invoice.model';
import { InvoicesFacade } from './invoice/invoices.facade';
import { LienReleaseFileEffects } from './lien-release-file/lien-release-file.effects';
import { LienReleaseFileFacade } from './lien-release-file/lien-release-file.facade';
import { LienReleaseFile } from './lien-release-file/lien-release-file.model';
import { LienReleaseTemplateEffects } from './lien-release-template/lien-release-template.effects';
import { LienReleaseTemplateFacade } from './lien-release-template/lien-release-template.facade';
import { LienReleaseTemplate } from './lien-release-template/lien-release-template.model';
import { LienRelease } from './lien-releases/lien-release.model';
import { LienReleaseService } from './lien-releases/lien-release.service';
import { Payment } from './payment/payment.model';
import { ProgressUIService } from './progress-ui.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(SETTLEMENT_TIMING_FEATURE_KEY, settlementTimingReducer),
    EffectsModule.forFeature([
      InvoiceItemEffects,
      LienReleaseTemplateEffects,
      InvoiceFileEffects,
      LienReleaseFileEffects,
      SettlementTimingEffects,
    ]),
    PayInvoiceModule,
  ],
  providers: [
    InvoiceUIService,
    ProgressUIService,
    InvoicesFacade,
    InvoiceItemsFacade,
    InvoiceFilesFacade,
    InvoiceAndPaymentFacade,
    InvoiceCountFacade,
    LienReleaseService,
    LienReleaseFileFacade,
    LienReleaseTemplateFacade,
    BankAccountFacade,
    SettlementTimingFacade,
    SettlementTimingUIService,

    { provide: BankAccount, useClass: EntityService },
    { provide: Payment, useClass: EntityService },
    { provide: Invoice, useClass: EntityService },
    { provide: InvoiceCount, useClass: EntityService },
    { provide: InvoiceItem, useClass: EntityService },
    { provide: InvoiceFile, useClass: EntityService },
    { provide: LienRelease, useClass: EntityService },
    { provide: LienReleaseFile, useClass: EntityService },
    { provide: LienReleaseTemplate, useClass: EntityService },
    { provide: SettlementTiming, useClass: EntityService },
  ],
})
export class InvoiceModule {}
