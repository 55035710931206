import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { subtypeClaimed } from '../../core/installer-profile';
import { ProductInstallationSubtype } from '../../domains/training/product-installation-subtypes/product-installation-subtypes.model';
import { claimProductInstallationSubtypesContext } from './claim-product-installation-subtypes-modal.selectors';

@Component({
  selector: 'gc-claim-product-installation-subtypes-modal',
  templateUrl: './claim-product-installation-subtypes-modal.component.html',
  styleUrls: ['./claim-product-installation-subtypes-modal.component.scss'],
})
export class ClaimProductInstallationSubtypesModalComponent {
  productInstallationSubtypesContext$ = this.store.select(claimProductInstallationSubtypesContext);

  constructor(
    public readonly modalGC: ModalController,
    private readonly store: Store,
  ) {}

  adding(subtype: ProductInstallationSubtype): void {
    this.store.dispatch(subtypeClaimed({ productInstallationSubtype: subtype }));
  }
}
