import { Injectable } from '@angular/core';
import { App, AppState } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, from, iif, merge, ReplaySubject } from 'rxjs';
import {
  concatMap,
  delay,
  distinctUntilKeyChanged,
  exhaustMap,
  filter,
  map,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';
import { appInitialize } from '../app.actions';
import { LiveUpdateService } from '../live-update.service';
import { EnvironmentService } from './environment.service';
import {
  appStateChanged,
  initiateLiveUpdate,
  liveUpdateDetected,
  liveUpdateDownloadComplete,
  liveUpdateDownloadProgress,
  liveUpdateExtractionComplete,
  liveUpdateExtractionProgress,
  networkStatusChanged,
  updateBackendVersion,
  windowSizeUpdated,
} from './environment.state';

@Injectable()
export class EnvironmentEffects {
  private networkStatus$$ = new ReplaySubject<ConnectionStatus>(1);
  private appState$$ = new ReplaySubject<AppState>(1);
  private windowSize$$ = new ReplaySubject<{ width: number; height: number }>(1);

  setBackendVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialize),
      switchMap(() => this.environment.getBackendVersion()),
      map(({ version }) => updateBackendVersion({ backendVersion: version })),
    ),
  );

  checkForUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialize),
      filter(() => Capacitor.isNativePlatform()),
      tap(() => console.log('Checking for live update')),
      switchMap(() => this.liveUpdate.hasUpdate()),
      filter(update => update),
      map(() => liveUpdateDetected()),
    ),
  );

  toastUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liveUpdateDetected),
      switchMap(() => this.liveUpdate.toastAvailableUpdate()),
      switchMap(() => this.liveUpdate.showUpdateProgress()),
      map(() => initiateLiveUpdate()),
    ),
  );

  processUpdateDownload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateLiveUpdate),
      exhaustMap(() =>
        this.liveUpdate
          .downloadLiveUpdate()
          .pipe(
            map(event =>
              event.type === 'progress'
                ? liveUpdateDownloadProgress({ progress: event.progress })
                : liveUpdateDownloadComplete(),
            ),
          ),
      ),
    ),
  );

  processUpdateExtraction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(liveUpdateDownloadComplete),
      exhaustMap(() =>
        this.liveUpdate
          .extractLiveUpdate()
          .pipe(
            map(event =>
              event.type === 'progress'
                ? liveUpdateExtractionProgress({ progress: event.progress })
                : liveUpdateExtractionComplete(),
            ),
          ),
      ),
    ),
  );

  applyLiveUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(liveUpdateExtractionComplete),
        delay(2000),
        switchMap(() => this.liveUpdate.applyUpdate()),
      ),
    { dispatch: false },
  );

  updateNetworkStatus$ = createEffect(() =>
    merge(from(Network.getStatus()), this.networkStatus$$.asObservable()).pipe(
      map(({ connected }) => networkStatusChanged({ connected })),
    ),
  );

  updateAppState$ = createEffect(() =>
    merge(from(App.getState()), this.appState$$.asObservable()).pipe(
      map(({ isActive }) => appStateChanged({ isActive })),
    ),
  );

  toastNetworkConnectionStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(networkStatusChanged),
        distinctUntilKeyChanged('connected'),
        // Skip first event to prevent toasting initial setting of network status
        skip(1),
        concatMap(({ connected }) =>
          iif(
            () => connected,
            defer(() => this.environment.toastNetworkConnected()),
            defer(() => this.environment.toastNetworkDisconnected()),
          ),
        ),
      ),
    { dispatch: false },
  );

  watchWindowResize$ = createEffect(() =>
    this.windowSize$$.asObservable().pipe(map(dimensions => windowSizeUpdated(dimensions))),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private environment: EnvironmentService,
    private liveUpdate: LiveUpdateService,
  ) {
    App.addListener('appStateChange', state => this.appState$$.next(state));
    Network.addListener('networkStatusChange', status => this.networkStatus$$.next(status));
    window.addEventListener('resize', () =>
      this.windowSize$$.next({ width: window.innerWidth, height: window.innerHeight }),
    );
  }
}
