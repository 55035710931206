import { Entity, Key } from '@briebug/ngrx-auto-entity';

export interface GeoPoint {
  type: 'Point';
  coordinates: [number, number];
}

@Entity({
  modelName: 'Address',
  uriName: 'addresses',
})
export class Address {
  @Key id?: number;
  addressLine1!: string;
  addressLine2?: string;
  city!: string;
  postalCode!: string;
  addressTypeId!: number;
  stateCode!: string;
  stateOrProvince!: string;
  workOrderId?: string;
  companyId?: number;
  userId?: string;
  location?: GeoPoint;
}
