import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { negotiationsFilterUpdated } from './negotiations.actions';

export interface INegotiationsState {
  filterOnlyNegotiated: boolean;
}

export const initialNegotiationsState: INegotiationsState = {
  filterOnlyNegotiated: false,
};

export const setFilter = (
  state: INegotiationsState,
  { filterOnlyNegotiated }: { filterOnlyNegotiated: boolean },
): INegotiationsState => ({
  ...state,
  filterOnlyNegotiated,
});

const reduce = createReducer(initialNegotiationsState, on(negotiationsFilterUpdated, setFilter));

export function negotiationsReducer(state = initialNegotiationsState, action: Action) {
  return reduce(state, action);
}

export const NEGOTIATIONS_FEATURE_KEY = 'negotiations';

export const negotiationsState = createFeatureSelector<INegotiationsState>(NEGOTIATIONS_FEATURE_KEY);

export const onlyNegotiated = createSelector(negotiationsState, state => state.filterOnlyNegotiated);
