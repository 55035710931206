import { createSelector } from '@ngrx/store';
import { get } from '../../../shared/utils/func';
import { modalState } from './modal.name';


export const uiState = createSelector(
  modalState,
  get('ui')
);

export const added = createSelector(
  uiState,
  get('added')
);

