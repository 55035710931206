import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { WorkOrder } from '../work-order/work-order.model';
import { updateCalendarAreaTitle, updateCalendarDate } from './calendar.actions';

export interface ICalendarState {
  title: string;
  date: number;
  dateRange?: { start: Date; end: Date };
  filterGroup?: 'employee' | 'contract' | 'pm';
  filterUsers: string[];
  showPending: boolean;
  ghostWorkOrders: Partial<WorkOrder>[];
}

export const initialCalendarState: ICalendarState = {
  title: 'Calendar',
  date: Date.now(),
  filterUsers: [],
  showPending: true,
  ghostWorkOrders: [],
};

const reduce = createReducer(
  initialCalendarState,
  on(updateCalendarAreaTitle, (state, { title }): ICalendarState => ({ ...state, title: title ?? state.title })),
  on(updateCalendarDate, (state, { date }): ICalendarState => ({ ...state, date: date ?? state.date })),
);

export function calendarReducer(state = initialCalendarState, action: Action): ICalendarState {
  return reduce(state, action);
}

export const calendarState = createFeatureSelector<ICalendarState>('calendar');
