import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../shared/services/config.service';
import { PendingFullWorkOrder } from './models/pending-full-work-order';
import { WorkOrder } from './work-order/work-order.model';

@Injectable()
export class WorkService {
  constructor(
    private http: HttpClient,
    private config: ConfigService,
  ) {}

  replace(workOrder: PendingFullWorkOrder) {
    return this.http.put<WorkOrder>(`${this.config.host}/work-orders/${workOrder.id}`, workOrder);
  }

  duplicateWorkOrder(workOrder: WorkOrder) {
    return this.http.post<WorkOrder>(`${this.config.host}/work-orders/${workOrder.id}/duplicate`, workOrder);
  }
}
