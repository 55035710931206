<div id="profile-picture-wrapper">
  <img
    alt="{{ user | fullName }}"
    [class.user-avatar]="centered"
    [src]="user | profilePhoto"
    class="profile-picture"
    gcDefaultImage
    data-cy="installer-profile-picture"
  />
  <fa-icon
    data-cy="installer-profile-lock-icon"
    *ngIf="isPrivate"
    [icon]="faLock"
    class="installer-profile-lock-icon"
  ></fa-icon>
</div>
