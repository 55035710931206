import { Injectable } from '@angular/core';
import { ActionPerformed, LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class LocalNotificationsService {
  notificationReceived$ = new ReplaySubject<LocalNotificationSchema>();
  notificationActionPerformed$ = new ReplaySubject<ActionPerformed>();

  constructor() {
    LocalNotifications.addListener('localNotificationReceived', notification =>
      this.notificationReceived$.next(notification),
    );
    LocalNotifications.addListener('localNotificationActionPerformed', action =>
      this.notificationActionPerformed$.next(action),
    );
  }

  async init() {
    await LocalNotifications.requestPermissions();
  }

  alertImmediately(header: string, message: string, extra?: any) {
    return LocalNotifications.schedule({
      notifications: [
        {
          title: header,
          body: message,
          id: 0,
          extra,
        },
      ],
    });
  }
}
