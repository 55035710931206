import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ExtendedHammerRatingDetails,
  InstallationTypeSubtypesClaimed,
  KudosReasonsDetails,
} from '../../../../core/installer-profile';
import { ProductInstallationSubtypesClaimed } from '../../../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import { ProductInstallationType } from '../../../../domains/training/product-installation-types/product-installation-types.model';
import { Installer } from '../../../../domains/users';

@Component({
  selector: 'gc-overview-area',
  templateUrl: './overview-area.component.html',
  styleUrls: ['./overview-area.component.scss'],
})
export class OverviewAreaComponent {
  @Input() kudosReasonsDetails?: KudosReasonsDetails;
  @Input() hammerRatingsDetails!: ExtendedHammerRatingDetails[];
  @Input() productInstallationSubtypes!: InstallationTypeSubtypesClaimed[];
  @Input() canEditProfile!: boolean;
  @Input() installer!: Installer;

  @Output() subtypeAdded = new EventEmitter<ProductInstallationType>();
  @Output() subtypeRemoved = new EventEmitter<ProductInstallationSubtypesClaimed>();
}
