import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { ProductInstallationTypeClaimed } from './product-installation-types-claimed.model';
import { ProductInstallationTypeClaimedFacadeBase } from './product-installation-types-claimed.state';

@Injectable()
export class ProductInstallationTypeClaimedFacade extends ProductInstallationTypeClaimedFacadeBase {
  constructor(store: Store<AppState>) {
    super(ProductInstallationTypeClaimed, store);
  }
}
