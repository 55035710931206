import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { UnitsPendingModule } from '../../shared/components/units-pending/units-pending.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { ConfirmDeclineModalComponent } from './confirm-decline-modal.component';


@NgModule({
  declarations: [ConfirmDeclineModalComponent],
  imports: [CommonModule, IonicModule, UnitsPendingModule, ReactiveFormsModule, PipesModule, PushModule],
  exports: [ConfirmDeclineModalComponent],
})
export class ConfirmDeclineModalModule {
}
