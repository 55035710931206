import { IEntityState } from '@briebug/ngrx-auto-entity';
import { buildFileState } from '../../../shared/file-uploads/build-file-state';
import { UserFile } from './user-file.model';

export const {
  initialState,
  selectors: { selectAll: allUserFiles },
  actions: { loadAll: loadAllUserFiles, createSuccess: createUserFileSuccess, clear: clearUserFiles },
  fileActions: { upload: uploadUserFile, uploadSuccess: uploadUserFileSuccess, uploadFailure: uploadUserFileFailure },
  fileSelectors: { selectPendingUploads: selectPendingUserFileUploads },
} = buildFileState(UserFile);

export function userFileReducer(state = initialState): IEntityState<UserFile> {
  return state;
}
