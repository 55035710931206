import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isAdding } from '~gc/domains/work/work.selectors';
import { DetailedInstaller } from '../../models/detailed-installer';
import { AppState } from '../../state';
import { PendingFullWorkOrder } from '../models/pending-full-work-order';
import { pendingWorkOrderSaved, saveEditedWorkOrder } from '../work-order-edit/work-order-edit.actions';
import {
  detailedWorkOrdersAllowedToUser,
  sortedByDateEditedWorkOrdersAwaitingResponse,
  workOrdersAwaitingResponse,
  workOrdersByCurrentStatusGroup,
  workOrdersRecentlyCompleted,
  workOrdersWithPendingNegotiations,
  workOrdersWithUnapprovedChangeOrders,
} from '../work-order-header.selectors';
import { navigateToWorkOrderAddress, viewWorkOrder, viewWorkOrderFromModal } from '../work-order-ui.actions';
import { workOrdersRefresh } from '../work.actions';
import { confirmAwardToInstaller, openDropdownMenu, workOrderListEndReached } from './work-order.actions';
import { WorkOrder } from './work-order.model';
import { detailedWorkOrder, isLoadingFresh, isPendingInitialLoad } from './work-order.selectors';
import { WorkOrderFacadeBase } from './work-order.state';

@Injectable()
export class WorkOrderFacade extends WorkOrderFacadeBase {
  workOrdersByStatusGroup$ = this.store.select(workOrdersByCurrentStatusGroup);
  workOrdersAwaitingResponse$ = this.store.select(workOrdersAwaitingResponse);
  workOrdersRecentlyCompleted$ = this.store.select(workOrdersRecentlyCompleted);
  sortedByDateEditedWorkOrdersAwaitingResponse$ = this.store.select(sortedByDateEditedWorkOrdersAwaitingResponse);
  workOrdersWithUnapprovedChangeOrders$ = this.store.select(workOrdersWithUnapprovedChangeOrders);
  workOrdersWithPendingNegotiations$ = this.store.select(workOrdersWithPendingNegotiations);
  detailedWorkOrders$ = this.store.select(detailedWorkOrdersAllowedToUser);
  currentDetailedWorkOrder$ = this.store.select(detailedWorkOrder);
  isAdding$ = this.store.select(isAdding);
  isLoadingFresh$ = this.store.select(isLoadingFresh);
  isPendingInitialLoad$ = this.store.select(isPendingInitialLoad);

  constructor(private store: Store<AppState>) {
    super(WorkOrder, store);
  }

  navigateToWorkOrderAddress(workOrder: WorkOrder) {
    this.store.dispatch(navigateToWorkOrderAddress({ workOrder }));
  }

  workOrderListEndReached(event: CustomEvent<void>) {
    this.store.dispatch(workOrderListEndReached({ event }));
  }

  awardToInstaller(workOrder: WorkOrder, installer: DetailedInstaller) {
    this.store.dispatch(confirmAwardToInstaller({ workOrder, installer }));
  }

  save(workOrder: Partial<WorkOrder>) {
    this.store.dispatch(saveEditedWorkOrder({ workOrder }));
  }

  savePending(pendingWorkOrder: Partial<PendingFullWorkOrder>) {
    this.store.dispatch(pendingWorkOrderSaved({ workOrder: pendingWorkOrder }));
  }

  viewWorkOrderFromModal(workOrder: WorkOrder) {
    this.store.dispatch(viewWorkOrderFromModal({ workOrder }));
  }

  viewWorkOrder(workOrder: WorkOrder) {
    this.store.dispatch(viewWorkOrder({ workOrder }));
  }

  openDropdownMenu(event: Event) {
    this.store.dispatch(openDropdownMenu({ event }));
  }

  refresh() {
    this.store.dispatch(workOrdersRefresh());
  }
}
