import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { HammerRatingDetailsComponent } from './hammer-rating-details.component';

@NgModule({
  declarations: [HammerRatingDetailsComponent],
  imports: [CommonModule, IonicModule, PipesModule],
  exports: [HammerRatingDetailsComponent],
})
export class HammerRatingDetailsModule {}
