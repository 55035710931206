<ion-toolbar color="gc-blue">
  <ion-buttons slot="start">
    <ion-button (click)="cancel.emit()">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button>
      <ion-icon name="{{ icon }}"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-title>
    <ng-content></ng-content>
  </ion-title>
</ion-toolbar>
