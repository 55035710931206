import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomDate } from '../../../domains/custom-dates';
import { CustomDateForm } from '../schedule/schedule.form';

@Component({
  selector: 'gc-start-end-date',
  templateUrl: './start-end-date.component.html',
  styleUrls: ['./start-end-date.component.scss'],
})
export class StartEndDateComponent {
  @Input() form?: CustomDateForm;
  @Input() minDate!: Date;
  @Input() showCtaButtons!: boolean;

  @Output() canceled = new EventEmitter();
  @Output() saved = new EventEmitter<CustomDate>();

  save(form: CustomDateForm): void {
    if (form.isEmpty) {
      return;
    }
    this.saved.emit(form.updatedModel);
  }
}
