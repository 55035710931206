<ion-grid *ngIf="(permissions$ | push | hasPermissions: 'work-order.details.kudos:give') else noPermissions">
  <ion-row>
    <ion-col>
      <ion-button
        *ngIf="!workOrder.kudos?.length"
        expand="block"
        mode="ios"
        color="gc-alt-green"
        size="large"
        (click)="given.emit(workOrder)"
      >
        <ion-icon name="thumbs-up" size="small"></ion-icon>
        <strong>Give Kudos</strong>
      </ion-button>
      <ion-button *ngIf="!!workOrder.kudos?.length" expand="block" mode="ios" color="light" size="large">
        <ion-icon name="checkmark-circle" color="dark" size="small"></ion-icon>
        <strong>Thanks for giving kudos</strong>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>

<ng-template #noPermissions>
  <div class="alert alert__warning p-20 m-20">
    You do not have permission to view and/or give Kudos.
  </div>
</ng-template>
