<ng-template #view>
  <ng-container *ngIf="installer">
    <ion-list>
      <ion-list-header>
        <ion-label color="medium">HAMMER RATINGS</ion-label>
      </ion-list-header>
    </ion-list>

    <gc-hammer-rating-details [ratings]="installer.hammerRatings"></gc-hammer-rating-details>

    <ion-list class="ion-margin-top">
      <ion-item lines="none">
        <ion-label color="medium">
          <strong>CERTIFICATIONS & EXPERIENCE</strong>
        </ion-label>
      </ion-item>
    </ion-list>
    <ion-list *ngIf="!!installer.trainingGroups?.length">
      <ng-container *ngFor="let group of installer.trainingGroups">
        <ion-list-header>
          <ion-label>{{ group.name }}</ion-label>
        </ion-list-header>
        <ion-item *ngFor="let training of group.trainings">
          <ion-label>{{ training.trainingName }}</ion-label>
          <ion-note slot="end">
            <ion-icon *ngIf="training.isVerified" name="checkmark-circle" color="gc-green"></ion-icon>
            <span *ngIf="!training.isVerified" class="unverified">Unverified</span>
          </ion-note>
        </ion-item>
      </ng-container>
    </ion-list>
  </ng-container>
</ng-template>

<ng-container *ngIf="isLoading; else view">
  <ion-list>
    <ion-list-header>
      <ion-label color="medium">HAMMER RATINGS</ion-label>
    </ion-list-header>
  </ion-list>

  <ion-list class="ion-margin-top">
    <ion-item lines="none">
      <ion-label color="medium">
        <strong>CERTIFICATIONS & EXPERIENCE</strong>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list>
    <ion-list-header>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-list-header>
    <ion-item>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
  </ion-list>
</ng-container>
