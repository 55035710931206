import { Component, Input } from '@angular/core';
import { Negotiation } from '~gc/domains/negotiations/negotiation/negotiation.model';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { DetailedInstaller } from '../../../../domains/models/detailed-installer';
import { User } from '../../../../domains/users';

@Component({
  selector: 'gc-date-negotiation',
  templateUrl: './date-negotiation.component.html',
  styleUrls: ['./date-negotiation.component.scss'],
})
export class DateNegotiationComponent {
  @Input() dateNegotiation?: Negotiation;
  @Input() workOrder?: WorkOrder;
  @Input() user!: User | DetailedInstaller;
}
