import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthFacade } from '~gc/domains/app/auth/auth.facade';
import { InvoiceAndPaymentFacade } from '~gc/domains/invoices/invoice-and-payment.facade';
import { InvoicesFacade } from '../../../domains/invoices';
import { reportsProgressForCurrentWorkOrder } from '../../../domains/invoices/invoice-ui.selectors';
import { WorkOrderRelationsFacade } from '../../../domains/work-order-relations.facade';
import { areInvoicesPending } from '../store/ui.selectors';

@Component({
  selector: 'gc-work-order-invoices-detail',
  templateUrl: './work-order-invoices-detail.component.html',
  styleUrls: ['./work-order-invoices-detail.component.scss'],
})
export class WorkOrderInvoicesDetailComponent {
  @Input() invoiceLabel: string = 'Invoices';

  isPending$ = this.store.select(areInvoicesPending);
  reportsProgress$ = this.store.select(reportsProgressForCurrentWorkOrder);

  constructor(
    public invoices: InvoicesFacade,
    public workOrderRelations: WorkOrderRelationsFacade,
    public invoiceAndPayment: InvoiceAndPaymentFacade,
    public auth: AuthFacade,
    private readonly store: Store,
  ) {}
}
