import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { OutputFormat } from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';

@Component({
  selector: 'gc-image-cropper-popover',
  templateUrl: './image-cropper-popover.component.html',
  styleUrls: ['./image-cropper-popover.component.scss'],
})
export class ImageCropperPopoverComponent implements OnInit {
  @Input() imageBase64!: string;
  pendingBase64?: string | null | undefined;
  format: OutputFormat = 'jpeg';

  constructor(public popoverGC: PopoverController) {}

  ngOnInit() {
    this.pendingBase64 = this.imageBase64;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.pendingBase64 = event.base64;
  }
}
