import { Pipe, PipeTransform } from '@angular/core';
import { STATUS_GROUP_TO_COLOR } from '../../domains/work/work.maps';
import { StatusGroup } from '../../domains/work/work.state';

@Pipe({ name: 'statusGroupColor' })
export class StatusGroupColorPipe implements PipeTransform {
  transform(status: StatusGroup): string | null {
    return STATUS_GROUP_TO_COLOR[status] || null;
  }
}
