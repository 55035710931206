import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { concat, interval, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LiveUpdateModalComponent } from '../../modals/live-update-modal/live-update-modal.component';

export interface UpdateEvent {
  type: string;
}

export interface UpdateProgressEvent extends UpdateEvent {
  type: 'progress';
  progress: number;
}

export interface UpdateCompleteEvent extends UpdateEvent {
  type: 'complete';
  success: boolean;
}

export type UpdateEvents = UpdateProgressEvent | UpdateCompleteEvent;

export const mockDownload = (): Observable<UpdateEvents> =>
  concat(
    interval(10).pipe(
      take(101),
      map((progress): UpdateProgressEvent => ({ type: 'progress', progress: progress / 100 })),
    ),
    of<UpdateCompleteEvent>({ type: 'complete', success: true }),
  );

@Injectable()
export class LiveUpdateService {
  constructor(
    private deploy: Deploy,
    private toasts: ToastController,
    private modals: ModalController,
  ) {}

  async hasUpdate(): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) {
      return true;
    }
    const update = await this.deploy.checkForUpdate();
    console.log(update);
    return update.available;
  }

  async toastAvailableUpdate() {
    const toast = await this.toasts.create({
      header: 'New Update Available',
      message: 'Tap to start the download.',
      buttons: [
        {
          icon: 'cloud-download-outline',
        },
      ],
      cssClass: 'download-toast',
      color: 'dark',
      position: 'bottom',
    });
    await toast.present();
    toast.onclick = () => toast.dismiss();
    return toast.onDidDismiss();
  }

  async showUpdateProgress() {
    const modal = await this.modals.create({
      component: LiveUpdateModalComponent,
      cssClass: '', //TODO: make this black with white status and logo
      backdropDismiss: false,
    });
    await modal.present();
    // return popover.onDidDismiss();
  }

  applyUpdate() {
    return this.deploy.reloadApp();
  }

  downloadLiveUpdate(): Observable<UpdateEvents> {
    if (!Capacitor.isNativePlatform()) {
      return mockDownload();
    }
    return new Observable<UpdateEvents>(subscriber => {
      subscriber.next({ type: 'progress', progress: 0.01 });
      this.deploy
        .downloadUpdate(progress => subscriber.next({ type: 'progress', progress: (progress ?? 0) / 100 }))
        .then(success => (subscriber.next({ type: 'complete', success }), subscriber.complete()));
    });
  }

  extractLiveUpdate(): Observable<UpdateEvents> {
    if (!Capacitor.isNativePlatform()) {
      return mockDownload();
    }
    return new Observable<UpdateEvents>(subscriber => {
      subscriber.next({ type: 'progress', progress: 0.01 });
      this.deploy
        .extractUpdate(progress => subscriber.next({ type: 'progress', progress: (progress ?? 0) / 100 }))
        .then(success => (subscriber.next({ type: 'complete', success }), subscriber.complete()));
    });
  }
}
