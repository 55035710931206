<ion-card mode="ios" class="ion-margin">
  <ion-img id="sign-up-form-logo" src="/assets/images/logo.svg"></ion-img>
  <ion-text color="dark" class="ion-text-center">
    <h1>Start FREE Profile</h1>
  </ion-text>
  <form id="signup-form" [formGroup]="form">
    <ion-input
      data-e2e="signup-name-input"
      color="dark"
      mode="md"
      labelPlacement="floating"
      formControlName="firstName"
      label="First Name"
      fill="solid"
      clearInput="true"
    ></ion-input>
    <ion-input
      data-e2e="signup-name-input"
      color="dark"
      mode="md"
      labelPlacement="floating"
      formControlName="lastName"
      label="Last Name"
      fill="solid"
      clearInput="true"
    ></ion-input>
    <ion-input
      data-e2e="signup-email-input"
      color="dark"
      mode="md"
      labelPlacement="floating"
      formControlName="email"
      label="Email"
      fill="solid"
      clearInput="true"
    ></ion-input>

    <ion-item lines="none" button="false" class="ion-margin-vertical">
      <ion-checkbox slot="start" labelPlacement="end" formControlName="agreedToTermsAndPrivacy">
        I agree. Let's get my profile started!
      </ion-checkbox>
    </ion-item>

    <ion-button
      data-e2e="create-profile-button"
      size="large"
      expand="block"
      color="gc-green"
      type="submit"
      [disabled]="!form.valid"
      (click)="signUp(form)"
      >Create Profile
    </ion-button>

    <p>
      <ion-text color="dark">
        By creating a profile on Go Carrera, you agree to its
        <a href="https://gocarrera.com/terms-conditions/" target="_blank">Terms and Conditions</a>
        and <a href="https://gocarrera.com/privacy/" target="_blank">Privacy Policy</a>.
      </ion-text>
    </p>
  </form>
</ion-card>
