<!--CHANGE ORDER TOTAL?-->
<ion-list *ngIf="changeOrders?.length" style="margin-top: 12px">
  <ion-list-header color="gc-blue"> {{ changeOrders | changeOrdersTotal | currency }} Change Orders </ion-list-header>
  <ion-item
    *ngFor="let changeOrder of changeOrders; last as last; trackBy: trackChangeOrderBy"
    [attr.data-cy]="'invoice-change-order-title-' + changeOrder.name"
    class="bg-faded-blue"
    [lines]="last ? 'none' : 'full'"
  >
    <ion-row style="width: 100%">
      <ion-col size="6">
        <ion-label style="white-space: normal">
          <strong>{{ changeOrder.name }}</strong>
          <p class="current-ask">
            <strong>
              {{ changeOrder.adjustedNumberOfUnits }}
              @
              {{ changeOrder.adjustedPricePerUnit | currency }}
              <span *ngIf="!changeOrder.acceptedById"> </span>
            </strong>
          </p>
        </ion-label>
      </ion-col>

      <ion-col size="6" class="flex flex-col justify-content-around align-items-end">
        <ion-text color="danger" style="font-weight: bold">
          ({{ changeOrder.adjustedNumberOfUnits * changeOrder.adjustedPricePerUnit | currency }})
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-item>
  <ion-item class="change-summary" color="gc-dark-blue height-50">
    <ion-label class="text-left" style="white-space: normal">
      <h2>
        <strong>Total Deductions:</strong>
      </h2>
    </ion-label>
    <div slot="end" class="slotted-green slotted-box-right px-10">
      <span style="font-weight: bold">{{ changeOrders | totalDeductions | currency }}</span>
    </div>
  </ion-item>

  <ion-item>
    <ion-label class="text-center" style="white-space: normal">
      <h2>
        <strong
          >{{ changeOrders | totalNormalizedUnits: item.pricePerUnit }} Minimum Units to Invoice for Total of
          {{ changeOrders | totalNormalizedPrice: item.pricePerUnit | currency }}</strong
        >
      </h2>
    </ion-label>
  </ion-item>
</ion-list>
