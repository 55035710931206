import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { RequireId } from '../../../shared/utils/types';
import { removeProperty } from '../../utils';
import {
  saveInProgressWorkOrder,
  saveInProgressWorkOrderFailure,
  saveInProgressWorkOrderSuccess,
} from '../work-order-edit/work-order-edit.actions';
import { searchWorkOrders, viewOrdersByStatus, viewSearchWorkOrders } from '../work.actions';
import { workOrderListEndReached } from './work-order.actions';
import { WorkOrder } from './work-order.model';

export interface WorkOrderState extends IEntityState<WorkOrder> {
  editedWorkOrders: Record<string, RequireId<Partial<WorkOrder>>>;
  isLoadingFresh: boolean;
}

export const {
  initialState: initialWorkOrderState,
  facade: WorkOrderFacadeBase,
  selectors: {
    selectAll: allWorkOrders,
    selectEntities: workOrderEntities,
    selectCurrentEntityKey: currentWorkOrderKey,
    selectCurrentEntity: currentWorkOrder,
    selectEditedEntity: editedWorkOrder,
    selectCurrentPage: currentWorkOrderPage,
  },
  actions: {
    edit: editWorkOrder,
    change: changeWorkOrder,
    changed: changedWorkOrder,
    endEdit: endWorkOrderEdit,
    load: loadWorkOrder,
    loadSuccess: loadWorkOrderSuccess,
    loadFailure: loadWorkOrderFailure,
    loadAll: loadAllWorkOrders,
    loadAllSuccess: loadAllWorkOrdersSuccess,
    loadAllFailure: loadAllWorkOrdersFailure,
    loadMany: loadManyWorkOrders,
    loadManySuccess: loadManyWorkOrdersSuccess,
    loadManyFailure: loadManyWorkOrdersFailure,
    create: createWorkOrder,
    createSuccess: createWorkOrderSuccess,
    deleteByKey: deleteWorkOrderByKey,
    replace: replaceWorkOrder,
    replaceSuccess: replaceWorkOrderSuccess,
    replaceFailure: replaceWorkOrderFailure,
    update: updateWorkOrder,
    updateSuccess: updateWorkOrderSuccess,
    updateFailure: updateWorkOrderFailure,
    deselect: deselectWorkOrder,
    deselected: workOrderDeselected,
    selectByKey: selectWorkOrderById,
  },
} = buildState(WorkOrder, {
  paging: {
    currentPage: { page: 1, size: 25 },
  },
  editedWorkOrders: {},
  isLoadingFresh: true,
} as WorkOrderState);

export const trackEditedWorkOrder = (
  state: WorkOrderState,
  { entity }: { entity: Partial<WorkOrder> },
): WorkOrderState => ({
  ...state,
  editedWorkOrders: {
    ...state.editedWorkOrders,
    [entity.id!]: { ...entity, id: entity.id! }, // TODO: Reevaluate this to see if we can remove non-nullish assertion!!
  },
});

const reduce = createReducer(
  initialWorkOrderState,
  on(
    loadAllWorkOrdersSuccess,
    loadAllWorkOrdersFailure,
    loadManyWorkOrdersSuccess,
    loadManyWorkOrdersFailure,
    state => ({ ...state, isLoadingFresh: false }),
  ),
  on(workOrderListEndReached, state => ({
    ...state,
    paging: {
      ...state.paging,
      currentPage: { size: state.paging?.currentPage?.size ?? 25, page: (state.paging?.currentPage?.page ?? 1) + 1 },
    },
  })),
  on(viewOrdersByStatus, viewSearchWorkOrders, searchWorkOrders, state => ({
    ...state,
    paging: {
      currentPage: { size: state.paging?.currentPage?.size ?? 25, page: 1 },
    },
  })),
  on(changedWorkOrder, trackEditedWorkOrder),
  on(saveInProgressWorkOrder, state => ({ ...state, isSaving: true })),
  on(saveInProgressWorkOrderSuccess, (state, { workOrder }) => ({
    ...state,
    editedWorkOrders: removeProperty(state.editedWorkOrders, workOrder.id!), // TODO: Reevaluate this to see if we can remove non-nullish assertion!!
    isDirty: false,
    isSaving: false,
  })),
  on(saveInProgressWorkOrderFailure, state => ({
    ...state,
    isSaving: false,
  })),
);

export function workOrderReducer(state = initialWorkOrderState, action: Action): WorkOrderState {
  return reduce(state, action);
}

export const workOrdersState = createFeatureSelector<WorkOrderState>('workOrder');
