import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { SelectByKey, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { ModalController, NavController } from '@ionic/angular';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, take, takeUntil, tap, withLatestFrom, } from 'rxjs/operators';
import { InfiniteScrollCustomEvent } from '~gc/shared/models/infinite-scroll-custom-event.model';
import { SidebarService } from '../../../shared/services/sidebar.service';
import { routeEndsInPath } from '../../../shared/utils/ngrx';
import { ensureChildrenRequired, ensureExists, ensureRequired } from '../../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { loadManyCustomDates } from '../../custom-dates';
import { invoicesRouteVisit } from '../../invoices/invoice-and-payment.actions';
import { AppState } from '../../state';
import { Installer, TeamUser, User } from '../../users';
import { allInstallers, combinedTeamMembers } from '../../users/user/user.selectors';
import { allUsers } from '../../users/user/user.state';
import { InstallerResponse } from '../matched-installer/matched-installer.model';
import { replaceMatchedInstaller } from '../matched-installer/matched-installer.state';
import {
  closeWorkOrder,
  deletePendingWorkOrder,
  duplicateAsDraft,
  editCurrentWorkOrder,
  showSelectEmployee,
  showSelectProjectManager,
} from '../work-order-edit/work-order-edit.actions';
import { sharedWithTeamMembers } from '../work-order-header.selectors';
import { declineWorkOrder, navigateToWorkOrderAddress, viewWorkOrder, viewWorkOrderFromModal, } from '../work-order-ui.actions';
import { WorkUIService } from '../work-ui.service';
import { workOrdersRouteVisit } from '../work.actions';
import { WorkService } from '../work.service';
import {
  awardedToInstaller,
  awardToInstaller,
  awardToInstallerCompleted,
  confirmAwardToInstaller,
  openDropdownMenu,
  shareWithInstallers,
  shareWorkOrder,
} from './work-order.actions';
import { WorkOrder, WorkOrderStatus } from './work-order.model';
import { WorkOrderService } from './work-order.service';
import {
  currentWorkOrder,
  deleteWorkOrderByKey,
  deselectWorkOrder,
  loadManyWorkOrdersFailure,
  loadManyWorkOrdersSuccess,
  loadWorkOrder,
  loadWorkOrderSuccess,
  replaceWorkOrder,
  replaceWorkOrderSuccess,
  updateWorkOrder,
} from './work-order.state';


export const WORK_ORDER_ACTIONS: Record<string, (...params: any[]) => ActionCreator<string, any>> = {
  [shareWorkOrder.type]: () => shareWorkOrder(),
  [editCurrentWorkOrder.type]: () => editCurrentWorkOrder(),
  [duplicateAsDraft.type]: () => duplicateAsDraft(),
  [closeWorkOrder.type]: () => closeWorkOrder(),
  [showSelectProjectManager.type]: (workOrder: WorkOrder, event: Event) =>
    showSelectProjectManager({ workOrder, event }),
  [showSelectEmployee.type]: (workOrder: WorkOrder, event: Event) => showSelectEmployee({ workOrder, event }),
  [deletePendingWorkOrder.type]: (workOrder: WorkOrder) => deletePendingWorkOrder({ workOrder }),
};

@Injectable()
export class WorkOrderEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private nav: NavController,
    private modals: ModalController,
    private router: Router,
    private work: WorkService,
    private workUI: WorkUIService,
    private workOrders: WorkOrderService,
    private store: Store<AppState>,
    private navigator: LaunchNavigator,
    private sidebarService: SidebarService,
  ) {}

  clearCurrentWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesRouteVisit),
      map(() => deselectWorkOrder()),
    ),
  );

  workOrderRouteListener$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      routeEndsInPath('/work-orders', '/invoices'),
      map(route => workOrdersRouteVisit()),
    ),
  );

  triggerInfiniteScrollComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadManyWorkOrdersSuccess, loadManyWorkOrdersFailure),
        tap(
          ({ criteria }: { criteria?: { event?: InfiniteScrollCustomEvent } }) =>
            criteria?.event && criteria.event.target.complete(),
        ),
      ),
    { dispatch: false },
  );

  translateViewWorkOrderFromModalToViewWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrderFromModal),
      tap(() => this.sidebarService.setHideSidebar(true)),
      map(({ workOrder }) => viewWorkOrder({ workOrder })),
    ),
  );

  selectViewedWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrder),
      map(({ workOrder }) => workOrder),
      ensureRequired(workOrder => !!workOrder?.id),
      map(workOrder => new SelectByKey(WorkOrder, workOrder.id)),
    ),
  );

  viewWorkOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewWorkOrder),
        tap(() => this.nav.navigateForward(['app/work/orders/detail'])),
      ),
    { dispatch: false },
  );

  // viewWorkOrderModal$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(viewWorkOrderFromModal),
  //     switchMap(() => this.workUI.showViewWorkOrderModal()),
  //     map(() => viewWorkOrderModalClosed()),
  //   ),
  // );

  confirmAwardToInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmAwardToInstaller),
      exhaustMap(({ workOrder, installer }) =>
        from(this.workUI.confirmAwardToInstaller(installer)).pipe(
          filter(role => role === 'ok'),
          map(() => awardToInstaller({ workOrder, installer })),
        ),
      ),
    ),
  );

  awardToInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(awardToInstaller),
      map(({ workOrder, installer }) => ({
        ...workOrder,
        awardedToInstallerId: installer.id!, // TODO: Figure out how to get rid of this non-null assertion!
        status: WorkOrderStatus.AwardedNotStarted,
      })),
      map(workOrder => replaceWorkOrder({ entity: workOrder })),
      switchMap(action => [awardedToInstaller({ correlationId: action.correlationId }), action]),
    ),
  );

  returnToWorkOrderDetailAfterAwarding$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(replaceWorkOrderSuccess)),
      this.actions$.pipe(ofType(awardedToInstaller)),
    ]).pipe(
      filter(
        ([{ correlationId }, { correlationId: expectedCorrelationId }]) => correlationId === expectedCorrelationId,
      ),
      tap(() => this.workUI.unwindReviewInstallersModalStack()),
      map(() => awardToInstallerCompleted()),
      catchError(() => of(awardToInstallerCompleted())),
    ),
  );

  reloadWorkOrderScheduleDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(awardToInstallerCompleted),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureRequired(workOrder => !!workOrder?.id),
      map(workOrder =>
        loadManyCustomDates({
          criteria: {
            query: {
              workOrderId: workOrder['id'],
            },
          },
        }),
      ),
    ),
  );

  confirmWorkOrderDecline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(declineWorkOrder),
      switchMap(({ matchedInstaller, workOrder }) =>
        from(this.workUI.confirmAction('Declining')).pipe(
          filter(role => role === 'ok'),
          map(() =>
            replaceMatchedInstaller({
              entity: {
                ...matchedInstaller,
                response: InstallerResponse.Declined,
              },
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
                },
              },
            }),
          ),
        ),
      ),
      tap(() => this.nav.pop()),
    ),
  );

  // TODO:: Determine if this is necessary/effective with new work order loading scheme.
  // loadAllWorkOrders$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(replaceMatchedInstallerSuccess),
  //     map(() => loadAllWorkOrders())
  //   )
  // );

  showDropdownMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openDropdownMenu),
      withLatestFrom(
        this.store.select(currentWorkOrder),
        this.store.select(authenticatedUser),
        this.store.select(allInstallers),
      ),
      map(([{ event }, workOrder, user, installers]) => ({ event, workOrder, user, installers })),
      ensureChildrenRequired<
        { event: Event; workOrder?: WorkOrder; user?: User; installers?: Installer[] },
        { event: Event; workOrder: WorkOrder; user: Required<User>; installers?: Installer[] }
      >(value => !!value?.workOrder && !!value?.user),
      switchMap(({ event, workOrder, user, installers }) =>
        from(
          this.workUI.showDropdownMenu(
            event,
            workOrder,
            user,
            installers?.find(installer => installer.id === workOrder.awardedToInstallerId),
          ),
        ).pipe(
          ensureChildrenRequired(value => value?.role === 'act' && !!value?.data?.name),
          map(({ data }) => WORK_ORDER_ACTIONS[data.name]?.(workOrder, event) as Action),
          filter(action => !!action),
        ),
      ),
    ),
  );

  deletePendingWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePendingWorkOrder),
      map(({ workOrder }) => workOrder),
      ensureRequired(value => !!value?.id),
      switchMap(workOrder =>
        from(this.workOrders.confirmDeletePendingWorkOrder()).pipe(
          filter(role => role === 'delete'),
          map(() => deleteWorkOrderByKey({ key: workOrder.id })),
        ),
      ),
      tap(() => this.nav.back()),
    ),
  );

  duplicateWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(duplicateAsDraft),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureExists(workOrder => !!workOrder),
      switchMap(workOrder => this.work.duplicateWorkOrder(workOrder)),
      ensureRequired(value => !!value?.duplicatedId),
      map(workOrder => loadWorkOrder({ keys: workOrder.duplicatedId })),
    ),
  );

  showDuplicateWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(duplicateAsDraft),
      switchMap(() => this.actions$.pipe(ofType(loadWorkOrderSuccess), take(1))),
      map(({ entity }) => viewWorkOrder({ workOrder: entity })),
    ),
  );

  closeWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeWorkOrder),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureChildrenRequired<WorkOrder, Required<WorkOrder>>(value => !!value?.id),
      switchMap(workOrder =>
        from(
          this.workUI.confirm(
            'Do you want to close this Work Order?',
            'Are you sure you want to close this Work Order? Unreported remaining units will be ignored.',
          ),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => updateWorkOrder({ entity: { id: workOrder.id, status: WorkOrderStatus.Done } as WorkOrder })),
        ),
      ),
    ),
  );

  showShareWorkOrderMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(shareWorkOrder),
      withLatestFrom(
        this.store.select(combinedTeamMembers),
        this.store.select(sharedWithTeamMembers),
        this.store.select(currentWorkOrder),
      ),
      map(([, installers, selected, workOrder]) => ({ installers, selected, workOrder })),
      ensureChildrenRequired<
        { installers?: TeamUser[]; selected?: TeamUser[]; workOrder?: WorkOrder },
        { installers: Required<TeamUser>[]; selected: Required<TeamUser>[]; workOrder: Required<WorkOrder> }
      >(value => !!value?.installers && !!value.selected && !!value?.workOrder),
      switchMap(({ installers, selected, workOrder }) =>
        from(this.workUI.showShareWorkOrderModal(installers, selected, workOrder)).pipe(
          ensureRequired(value => value?.role !== 'cancel' && !!value?.data),
          map(({ data }) => installers.filter(installer => data.includes(installer.id))),
          map(installers => shareWithInstallers({ installers })),
        ),
      ),
    ),
  );

  synchronizeShared$ = createEffect(() =>
    // TODO: Tame this monster of an effect
    this.actions$.pipe(
      ofType(shareWithInstallers),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([{ installers }, workOrder]) => ({ installers, workOrder })),
      ensureChildrenRequired<
        { installers: TeamUser[]; workOrder?: WorkOrder },
        { installers: TeamUser[]; workOrder: Required<WorkOrder> }
      >(value => !!value?.installers && !!value?.workOrder),
      map(({ installers, workOrder }) => ({
        installersToShare: installers
          .filter(({ id }) => !!id && !workOrder?.sharedWith?.includes(id))
          .map(({ id }) => id!), // TODO: Can we get rid of non-nullable assertion?
        installersToUnShare: workOrder?.sharedWith?.filter(key => !installers.some(({ id }) => id === key)) || [],
        workOrder,
      })),
      ensureChildrenRequired<
        { installersToShare: string[]; installersToUnShare: string[]; workOrder?: WorkOrder },
        {
          installersToShare: string[];
          installersToUnShare: string[];
          workOrder: Required<WorkOrder>;
        }
      >(value => !!value?.installersToShare && !!value?.installersToUnShare && !!value?.workOrder),
      concatMap(({ installersToShare, installersToUnShare, workOrder }) =>
        combineLatest([
          ...installersToShare.map(installer => this.workOrders.shareWithUser(workOrder.id, installer)),
          ...installersToUnShare.map(installer => this.workOrders.unShareWithUser(workOrder.id, installer)),
        ]).pipe(take(1)),
      ),
      map(workOrders => loadWorkOrder({ keys: workOrders[0].id })),
    ),
  );

  navigateToWorkOrderAddress$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigateToWorkOrderAddress),
        map(
          ({ workOrder }) =>
            `${workOrder.address?.addressLine1} ${workOrder.address?.city}, ${workOrder.address?.stateCode} ${workOrder.address?.postalCode}`,
        ),
        switchMap(address => this.workUI.navigateToWorkOrderAddress(address)),
      ),
    { dispatch: false },
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }
}
