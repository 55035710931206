import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'TrainingClaimed',
  uriName: 'trainings-claimed',
})
export class TrainingClaimed {
  @Key id?: number;
  userId?: string;
  categoryId!: number;
  trainingId!: number;
  productInstallationTypeId!: number;
  productInstallationTypeClaimedId!: number;
  dateClaimed!: Date;
  isVerified?: boolean;
}
