import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { LienReleaseFile } from './lien-release-file.model';

export const {
  initialState: initialLienReleaseFileState,
  actions: { loadAll: loadAllLienReleaseFiles },
  selectors: { selectAll: allLienReleaseFiles },
  facade: LienReleaseFileFacadeBase,
} = buildState(LienReleaseFile);

export function lienReleaseFileReducer(state = initialLienReleaseFileState): IEntityState<LienReleaseFile> {
  return state;
}

export const signatureImage = createSelector(allLienReleaseFiles, files => (files?.length ? files[0].url : ''));
