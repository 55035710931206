import { Component, Input } from '@angular/core';
import { UserType } from '../../../../domains/users';
import { InviteForm } from '../../invite.form';
import { InviteView } from '../company-invite.selectors';

@Component({
  selector: 'gc-company-invite-form',
  templateUrl: './company-invite-form.component.html',
  styleUrls: ['./company-invite-form.component.scss'],
})
export class CompanyInviteFormComponent {
  @Input() view!: InviteView;
  @Input() type!: UserType;
  @Input() form!: InviteForm;
}
