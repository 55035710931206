import { Component, EventEmitter, Input, Output } from '@angular/core';

interface CustomBannerType {
  alert: string;
  promo: string;
}

@Component({
  selector: 'gc-custom-banner',
  templateUrl: './custom-banner.component.html',
  styleUrls: ['./custom-banner.component.scss'],
})
export class CustomBannerComponent {
  @Input() color!: string;
  @Input() type!: CustomBannerType;
  @Input() text!: string;

  @Output() closed = new EventEmitter();
}
