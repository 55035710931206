import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Channel } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-angular';
import { createChannelEventListener } from '../../../domains/work/work-order-chat/chat-utils';
import { EnrichedWorkOrderChat } from '../../../domains/work/work-order-chat/work-order-chat.state';

@Component({
  selector: 'gc-channel-item',
  templateUrl: './channel-item.component.html',
  styleUrls: ['./channel-item.component.scss'],
})
export class ChannelItemComponent implements OnInit {
  @Input() workOrderChat?: EnrichedWorkOrderChat;
  @Input() isCurrent!: boolean;
  @Output() selected = new EventEmitter<Channel<DefaultStreamChatGenerics>>();

  unread$!: Observable<number>;

  ngOnInit() {
    this.registerListeners();
  }

  registerListeners() {
    this.unread$ = merge(
      of(null),
      createChannelEventListener(this.workOrderChat!.channel, 'message.new'),
      createChannelEventListener(this.workOrderChat!.channel, 'message.read'),
    ).pipe(map(() => this.workOrderChat!.channel.countUnread()));
  }
}
