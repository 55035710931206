import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Category',
  uriName: 'categories',
})
export class Category {
  @Key id?: number;
  type!: string;
  productInstallationTypeId!: number;
}
