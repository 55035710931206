import { createAction, props } from '@ngrx/store';
import { Connection } from '~gc/domains/users/connection/connection.model';
import { Company } from './companies/company.model';

export const companyInvitedToConnections = createAction('[Network] Company Invited', props<{ company: Company }>());

export const networkConnectionSelected = createAction(
  '[Network] Connection Selected',
  props<{ connection: Connection }>(),
);

export const confirmNetworkConnectionDeclined = createAction(
  '[Network] Confirm Connection Decline',
  props<{ connection: Connection; received: boolean }>(),
);

export const networkConnectionAccepted = createAction(
  '[Network] Connection Accepted',
  props<{ connection: Connection }>(),
);

export const networkConnectionDeclined = createAction(
  '[Network] Connection Declined',
  props<{ connection: Connection; received: boolean }>(),
);
