import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import { AfterViewChecked, Component, Input, NgZone, OnDestroy } from '@angular/core';
import { KudosReasonsDetails } from '../../../../core/installer-profile';
import { kudosDisplayNames } from '../../../../core/installer-profile/kudos-reasons-details.selectors';
import { Installer } from '../../../../domains/users';
import { createAm5Root } from '../../../../shared/charts';

@Component({
  selector: 'gc-installer-kudos-details',
  templateUrl: './installer-kudos-details.component.html',
  styleUrls: ['./installer-kudos-details.component.scss'],
})
export class InstallerKudosDetailsComponent implements OnDestroy, AfterViewChecked {
  private kudosReasonsRoot?: am5.Root;

  @Input() kudosReasonsDetails?: KudosReasonsDetails;
  @Input() installer!: Installer;

  constructor(private zone: NgZone) {}

  ngAfterViewChecked() {
    if (!this.kudosReasonsRoot && this.kudosReasonsDetails) {
      this.kudosReasonsRadarChart(this.kudosReasonsDetails);
    }
  }

  kudosReasonsRadarChart(kudosReasonsDetails?: KudosReasonsDetails) {
    this.kudosReasonsRoot = createAm5Root('kudosReasonsRadarChart');

    let chart = this.kudosReasonsRoot.container.children.push(
      am5radar.RadarChart.new(this.kudosReasonsRoot, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
      }),
    );

    const cursor = chart.set(
      'cursor',
      am5radar.RadarCursor.new(this.kudosReasonsRoot, {
        behavior: 'none',
      }),
    );

    cursor.lineY.set('forceHidden', true);

    let xRenderer = am5radar.AxisRendererCircular.new(this.kudosReasonsRoot, {});
    xRenderer.labels.template.setAll({
      radius: 10,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(this.kudosReasonsRoot, {
        maxDeviation: 0,
        categoryField: 'category',
        renderer: xRenderer,
      }),
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(this.kudosReasonsRoot, {
        min: 0,
        renderer: am5radar.AxisRendererRadial.new(this.kudosReasonsRoot, {}),
      }),
    );

    let series = chart.series.push(
      am5radar.RadarLineSeries.new(this.kudosReasonsRoot, {
        name: 'Kudos',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'count',
        categoryXField: 'category',
        stroke: am5.color('#ff2a00'),
        fill: am5.color('#ff2a00'),
        opacity: 0.5,
        tooltip: am5.Tooltip.new(this.kudosReasonsRoot, {
          labelText: '{valueY}',
        }),
      }),
    );

    series.strokes.template.setAll({
      strokeWidth: 2,
    });

    series.bullets.push(() => {
      return am5.Bullet.new(this.kudosReasonsRoot!, {
        sprite: am5.Circle.new(this.kudosReasonsRoot!, {
          radius: 5,
          fill: series.get('fill'),
        }),
      });
    });

    let data = (
      kudosReasonsDetails?.dataPoints ?? [
        {
          name: 'attitudeCount',
          count: 0,
          description: kudosDisplayNames['attitudeCount'],
        },
        {
          name: 'punctualityCount',
          count: 0,
          description: kudosDisplayNames['punctualityCount'],
        },
        {
          name: 'qualityCount',
          count: 0,
          description: kudosDisplayNames['qualityCount'],
        },
        {
          name: 'professionalismCount',
          count: 0,
          description: kudosDisplayNames['professionalismCount'],
        },
        {
          name: 'dependabilityCount',
          count: 0,
          description: kudosDisplayNames['dependabilityCount'],
        },
      ]
    )
      .filter(reason => reason.name !== 'notProvidedCount')
      .map(reason => ({
        name: reason.name,
        count: reason.count,
        category: reason.description,
      }));

    xAxis.data.setAll(data);
    series.data.setAll(data);

    series.appear(1200);
    chart.appear(1200, 250);
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.kudosReasonsRoot?.dispose();
    });
  }
}
