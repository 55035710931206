<!-- TODO: Hide Deduction Button when DONE, DONE_AWAITING_SETTLEMENT, CANCELLED -->
<ion-icon
  *ngIf="
    (item | numberOfUnitsStillPending) > 0 &&
    (!item.createdByInstaller || item.acceptedById) &&
    (isStatusGroup(workOrder?.status, 'in_progress')) &&
    (permissions | hasPermissions: 'work-order.change-orders:view' : 'work-order.change-order:create') &&
    (((group | typeIsIn: 'original') && (displayGroups | itemGroupsAllowed: 'original')) ||
      ((group | typeIsIn: 'change-add') && (displayGroups | itemGroupsAllowed: 'change-add')) ||
      ((group | typeIsIn: 'installer-add') && (displayGroups | itemGroupsAllowed: 'installer-add')))
  "
  data-cy="add-deduction-button"
  name="remove-circle"
  color="gc-blue"
  style="transform: translateY(2px); cursor: pointer"
  size="small"
  (click)="added.emit(item)"
></ion-icon>

<!-- TODO: This is the desired result once we have the selector doing the work -->
<!--<ion-icon-->
<!--  *ngIf="displayAddDeductionButton"-->
<!--  data-cy="add-deduction-button"-->
<!--  name="remove-circle"-->
<!--  color="gc-blue"-->
<!--  style="transform: translateY(2px); cursor: pointer"-->
<!--  size="small"-->
<!--  (click)="added.emit(item)"-->
<!--&gt;</ion-icon>-->
