import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceableWorkOrderItem } from '../../domains/models/invoiceable-work-order-item';
import { ChangeOrder } from '../../domains/change-orders/change-order/change-order.model';
import { totalNormalizedPrice } from '../../shared/pipes/total-normalized-price.pipe';
import { totalDeductions } from '../../shared/pipes/total-price.pipe';

export const calculateExcessOfDeductionTotal = (
  changeOrders: ChangeOrder[] | undefined,
  pricePerUnit: number,
): number =>
  changeOrders?.length ? totalNormalizedPrice(changeOrders, pricePerUnit) - totalDeductions(changeOrders) : 0;

export const calculateReviewInvoiceTotal = (items?: InvoiceableWorkOrderItem[]): number =>
  items?.reduce(
    (total, curr) =>
      total +
      (curr.amountToInvoice ?? 0) * (curr.pricePerUnit ?? 0) +
      calculateExcessOfDeductionTotal(curr.applicableChangeOrders, curr.pricePerUnit ?? 0),
    0,
  ) ?? 0;

@Pipe({ name: 'reviewingInvoiceTotal' })
export class ReviewingInvoiceTotalPipe implements PipeTransform {
  transform(items?: InvoiceableWorkOrderItem[]): number {
    return calculateReviewInvoiceTotal(items);
  }
}
