import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../state';
import { User } from '../../users';
import { authSessionExpired, queueAuthRequest } from './auth-connect.actions';
import { clear, login, logout } from './auth.actions';
import {
  authenticatedClaims,
  authenticatedRole,
  authenticatedToken,
  authenticatedUser,
  authenticatedUserPicture,
  authenticationError,
  isAuthenticated,
  tokenPermissions,
} from './auth.selectors';

@Injectable()
export class AuthFacade {
  isAuthenticated$ = this.store.select(isAuthenticated);
  role$ = this.store.select(authenticatedRole);
  claims$ = this.store.select(authenticatedClaims);
  picture$ = this.store.select(authenticatedUserPicture);
  error$ = this.store.select(authenticationError);
  token$ = this.store.select(authenticatedToken);
  user$: Observable<User | undefined> = this.store.select(authenticatedUser);
  tokenPermissions$ = this.store.select(tokenPermissions);

  constructor(private store: Store<AppState>) {}

  logIn() {
    this.store.dispatch(login());
  }

  logOut() {
    this.store.dispatch(logout());
  }

  clear() {
    this.store.dispatch(clear());
  }

  queueAuthRequest(url: string) {
    this.store.dispatch(queueAuthRequest({ url }));
  }

  authSessionExpired() {
    this.store.dispatch(authSessionExpired());
  }
}
