import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { MatchedInstaller } from '~gc/domains/work/matched-installer/matched-installer.model';

export const {
  initialState: initialMatchedInstallerState,
  facade: MatchedInstallersFacadeBase,
  actions: {
    create: createMatchedInstaller,
    createSuccess: createMatchedInstallerSuccess,
    replace: replaceMatchedInstaller,
    replaceSuccess: replaceMatchedInstallerSuccess,
    loadAll: loadAllMatchedInstallers,
    loadMany: loadManyMatchInstallers,
    loadManyIfNecessary: loadManyMatchedInstallersIfNecessary,
  },
  selectors: { selectAll: allMatchedInstallers },
} = buildState(MatchedInstaller);

const reduce = createReducer(initialMatchedInstallerState);

export function matchedInstallerReducer(
  state = initialMatchedInstallerState,
  action: Action,
): IEntityState<MatchedInstaller> {
  return reduce(state, action);
}
