import { Pipe, PipeTransform } from '@angular/core';
import { Channel } from 'stream-chat';
import { ChatClientService, DefaultStreamChatGenerics } from 'stream-chat-angular';


export const defaultIfNullish = <T>(possiblyNullish: T | undefined | null, alt: T) =>
  possiblyNullish == null ? alt : possiblyNullish;

@Pipe({ name: 'idsToChannels', pure: true })
export class IdsToChannelsPipe implements PipeTransform {
  constructor(private chat: ChatClientService) {
  }

  getChannel(id: string) {
    return this.chat.chatClient?.activeChannels?.[`commerce:${ id }`];
  }

  transform(channelIds: string[]): Channel<DefaultStreamChatGenerics>[] {
    return this.idsToChannels(defaultIfNullish(channelIds, []));
  }

  private idsToChannels(channelIds: string[]): Channel<DefaultStreamChatGenerics>[] {
    return channelIds.map(id => this.getChannel(id)).filter(c => !!c);
  }
}
