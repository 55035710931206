import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { HammerRatingDetails } from '../models/hammer-rating-details.model';

import { createAm5Root } from './roots';

export const createHammerRatingChart = (root: am5.Root, detail: HammerRatingDetails): HammerRatingChart => {
  let chart = root.container.children.push(
    am5percent.PieChart.new(root, {
      startAngle: 160,
      endAngle: 380,
    }),
  );

  let series = chart.series.push(
    am5percent.PieSeries.new(root, {
      valueField: 'hammerRating',
      categoryField: 'productionType',
      startAngle: 160,
      endAngle: 380,
      radius: am5.percent(85),
      innerRadius: am5.percent(65),
    }),
  );

  series.slices.template.setAll({
    templateField: 'sliceSettings',
  });
  series.ticks.template.set('forceHidden', true);
  series.labels.template.set('forceHidden', true);
  series.slices.template.set('tooltipText', '');

  const categoryLabel = chart.seriesContainer.children.push(
    am5.Label.new(root, {
      textAlign: 'center',
      centerY: am5.p100,
      centerX: am5.p50,
      text: detail.category!.length > 12 ? detail.category!.replace(' ', '\n') : detail.category!,
      fontSize: detail.category!.length > 12 ? 11.5 : detail.category!.length > 9 ? 13 : 15,
      fontWeight: 'bold',
      dy: -10,
    }),
  );

  const ratingLabel = chart.seriesContainer.children.push(
    am5.Label.new(root, {
      textAlign: 'center',
      centerY: am5.p100,
      centerX: am5.p50,
      text: `${detail.rating}`,
      fontSize: 40,
      fontWeight: 'bold',
      dy: 30,
    }),
  );

  return { chart, series };
};

export const createHammerRatingChartData = (detail: HammerRatingDetails) => [
  {
    productionType: detail.category,
    hammerRating: detail.rating,
    sliceSettings: {
      fill: am5.color('#7ac143'),
      stroke: am5.color('#7ac143'),
    },
  },
  {
    productionType: '',
    hammerRating: detail.ratingRemainder,
    sliceSettings: {
      fill: am5.color('#f4f5f8'),
      stroke: am5.color('#f4f5f8'),
    },
  },
];

export interface HammerRatingRenderOptions {
  seriesAppearTime?: number;
  chartAppearTime?: number;
  chartAppearDelay?: number;
  seriesAnimateDuration?: number;
}

const DEFAULT_HAMMER_RATING_RENDER_OPTIONS: HammerRatingRenderOptions = {
  seriesAppearTime: 1500,
  chartAppearTime: 1000,
  chartAppearDelay: 100,
  seriesAnimateDuration: 1500,
};

export const renderHammerRatingChart = (
  chart: am5percent.PieChart,
  series: am5percent.PieSeries,
  options = DEFAULT_HAMMER_RATING_RENDER_OPTIONS,
) => {
  const opts = { ...DEFAULT_HAMMER_RATING_RENDER_OPTIONS, ...options };
  series.appear(opts.seriesAppearTime);
  series.animate({
    key: 'startAngle',
    to: 160,
    duration: opts.seriesAnimateDuration ?? 1000,
    easing: am5.ease.out(am5.ease.cubic),
  });
  series.slices.template.set('toggleKey', 'none');
  chart.appear(opts.chartAppearTime, opts.chartAppearDelay);
};

export interface HammerRatingChart {
  chart: am5percent.PieChart;
  series: am5percent.PieSeries;
  root?: am5.Root;
}

export const renderHammerRatingCharts = (charts: HammerRatingChart[], options = DEFAULT_HAMMER_RATING_RENDER_OPTIONS) =>
  charts.forEach(({ chart, series }) => renderHammerRatingChart(chart, series, options));

export const buildHammerRatingCharts = (hammerRatingDetails: HammerRatingDetails[]): HammerRatingChart[] =>
  hammerRatingDetails.map((detail, index) => {
    const root = createAm5Root(`hammerRatingChart${index}`);
    root.setThemes([am5themes_Responsive.new(root)]);
    const { chart, series } = createHammerRatingChart(root, detail);
    const data = createHammerRatingChartData(detail);

    series.data.setAll(data);

    return { chart, series, root };
  });
