import { IEntityState } from '@briebug/ngrx-auto-entity';
import { EntityKey } from '../../domains/entity.service.utils';
import { GenericFile } from '../../domains/models/generic-file';
import { PendingFile } from '../../domains/models/pending-file';

export interface FileState<T extends GenericFile> extends IEntityState<T> {
  pendingUploads?: PendingFile[];
  pendingDelete?: EntityKey[];
}

export const initialFileState = {
};
