import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '~gc/shared/pipes/pipes.module';
import { OptionsPopoverComponent } from './options-popover.component';

@NgModule({
  declarations: [OptionsPopoverComponent],
  imports: [CommonModule, IonicModule, PipesModule],
  exports: [OptionsPopoverComponent],
})
export class OptionsPopoverModule {}
