import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { tokenPermissions } from '../../../domains/app/auth/auth.selectors';

@Component({
  selector: 'gc-work-order-leave-kudos',
  templateUrl: './work-order-leave-kudos.component.html',
  styleUrls: ['./work-order-leave-kudos.component.scss'],
})
export class WorkOrderLeaveKudosComponent {
  @Input() currentRole!: string;
  @Input() workOrder!: WorkOrder;
  @Input() permissions$ = this.store.select(tokenPermissions)
  @Output() given = new EventEmitter<WorkOrder>();

  constructor(
    private readonly store: Store,
  ) {
  }
}
