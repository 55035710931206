import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { HammerRating } from './hammer-rating.model';
import { HammerRatingFacadeBase } from './hammer-rating.state';

@Injectable({ providedIn: 'root' })
export class HammerRatingFacade extends HammerRatingFacadeBase {
  constructor(store: Store<AppState>) {
    super(HammerRating, store);
  }
}
