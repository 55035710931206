import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { InvitedUser } from '../models/invited-user';
import { User, UserType } from '../user/user.model';
import {
  addUserSubmitted,
  cancelInviteUser,
  inviteEmailUpdated,
  inviteInstaller,
  inviteUserSubmitted,
  showAddToConnections,
  showInviteUser,
  stateFilterUpdated,
  teamPageInitialize,
  viewProjectManagers,
  viewTeamInstallers,
} from './team.actions';
import {
  emailLookup,
  emailLookupLoading,
  foundUser,
  foundUserMatchingEmail,
  jumpstartersFilteredByState,
  userIsAlreadyConnected,
} from './team.state';

@Injectable()
export class TeamFacade {
  foundUser$ = this.store.select(foundUser);
  foundUserMatchingEmail$ = this.store.select(foundUserMatchingEmail);
  emailLookupLoading$ = this.store.select(emailLookupLoading);
  emailLookup$ = this.store.select(emailLookup);
  userIsAlreadyConnected$ = this.store.select(userIsAlreadyConnected);
  filteredJumpstarters$ = this.store.select(jumpstartersFilteredByState);

  constructor(private store: Store<AppState>) {}

  openAddToConnections() {
    this.store.dispatch(showAddToConnections());
  }

  initializeTeamPage(): void {
    this.store.dispatch(teamPageInitialize());
  }

  viewProjectManagers(): void {
    this.store.dispatch(viewProjectManagers());
  }

  viewTeamInstallers(): void {
    this.store.dispatch(viewTeamInstallers());
  }

  showInviteUser(userType: UserType): void {
    this.store.dispatch(showInviteUser({ userType }));
  }

  cancelInviteUser(): void {
    this.store.dispatch(cancelInviteUser());
  }

  inviteEmailUpdated(email: string): void {
    this.store.dispatch(inviteEmailUpdated({ email }));
  }

  submitUserAdd(user: InvitedUser): void {
    this.store.dispatch(addUserSubmitted({ user }));
  }

  submitUserInvite(user: InvitedUser): void {
    this.store.dispatch(inviteUserSubmitted({ user }));
  }

  inviteInstaller(installer: User): void {
    this.store.dispatch(inviteInstaller({ installer }));
  }

  updateStateFilter(stateFilter: string[]) {
    this.store.dispatch(stateFilterUpdated({ stateFilter }));
  }
}
