import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChartNetwork } from '@fortawesome/pro-solid-svg-icons';
import { PopoverController } from '@ionic/angular';
import { Installer, InstallerTeamLead } from '../../../../domains/users';
import { Connection } from '../../../../domains/users/connection/connection.model';

@Component({
  selector: 'gc-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  styleUrls: ['./sidebar-header.component.scss'],
})
export class SidebarHeaderComponent {
  @Input() installer!: InstallerTeamLead;
  @Input() connection?: Connection;
  @Input() canEditProfile = false;

  @Output() called = new EventEmitter<Installer>();
  @Output() emailed = new EventEmitter<Installer>();
  @Output() websiteVisited = new EventEmitter<Installer>();
  @Output() optionsOpened = new EventEmitter<{ event: Event; installer: Installer }>();
  @Output() closeProfileModal = new EventEmitter<{ event: Event; installer: Installer }>();
  @Output() editMyProfile = new EventEmitter<{ event: Event; installer: Installer }>();

  protected readonly faChartNetwork = faChartNetwork;

  constructor(public readonly popoverGC: PopoverController) {}
}
