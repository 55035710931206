import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ConfigService } from '../../../shared/services/config.service';
import { ensureRequired } from '../../../shared/utils/rxjs';
import { GenericFile } from '../../models/generic-file';

@Injectable()
export class LienReleaseService {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
  ) {}

  toBlob(dataUrl: string) {
    return from(fetch(dataUrl)).pipe(switchMap(res => res.blob()));
  }

  uploadSignature(
    dataUrl: string,
    workOrderId: string,
    invoiceId: number,
    lienReleaseId: number,
    settlementTimingId: number,
  ): Observable<any> {
    return this.http
      .post<GenericFile>(
        `${this.config.host}/work-orders/${workOrderId}/invoices/${invoiceId}/lien-releases/${lienReleaseId}/files/uploads`,
        {
          filename: 'Signature',
          contentType: 'image/jpeg',
        },
      )
      .pipe(
        ensureRequired(file => !!file?.uploadUrl),
        withLatestFrom(this.toBlob(dataUrl)),
        switchMap(([file, signatureImage]) =>
          this.http.put(file.uploadUrl, signatureImage).pipe(
            map(() => ({ ...file, settlementTimingId, isUploaded: true })),
            switchMap(uploadedFile =>
              this.http
                .put(
                  `${this.config.host}/work-orders/${workOrderId}/invoices/${invoiceId}/lien-releases/${lienReleaseId}/files/${file.id}`,
                  uploadedFile,
                )
                .pipe(map(() => uploadedFile)),
            ),
          ),
        ),
      );
    // signatureImageUrl <-- will be the final path for the signature image https://gocarrera-prod/work/..../hash.jpg
  }
}
