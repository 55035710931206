import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthFacade } from '../../domains/app/auth/auth.facade';

@Injectable()
export class AuthSessionInterceptor implements HttpInterceptor {
  constructor(private auth: AuthFacade) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: error => error instanceof HttpErrorResponse && error.status === 401 && this.auth.authSessionExpired(),
      }),
    );
  }
}
