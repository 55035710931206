import { UntypedFormBuilder, Validators } from '@angular/forms';
import { parseISO } from 'date-fns';
import { TypedFormArray, TypedFormGroup } from '~gc/shared/utils/typed-forms';
import { CustomDate } from '../../../domains/custom-dates';
import { ValueChangeOptions } from '../../utils/typed-form-group';
import { compareDateFields } from '../../validators/compare-date-fields';

export class CustomDateFormArray extends TypedFormArray<CustomDate, CustomDateForm> {
  get nonEditing(): CustomDateForm[] {
    return this.items.filter(item => !item.isEditing);
  }

  get nonNew(): CustomDateForm[] {
    return this.items.filter(item => !item.isNew);
  }

  get isEditing(): boolean {
    return this.items.some(item => item.isEditing);
  }

  get hasNew(): boolean {
    return this.items.some(item => item.isNew);
  }

  get newlyAdded(): CustomDateForm | undefined {
    return this.items.find(item => item.isNew);
  }

  constructor(
    dateForms: CustomDateForm[],
    private readonly baseDate?: () => Partial<CustomDate>,
  ) {
    super(dateForms);
  }

  addNew(): CustomDateForm {
    const form = new CustomDateForm(this.baseDate?.(), true);
    this.push(form);
    this.updateValueAndValidity();
    return form;
  }

  remove(date: CustomDateForm) {
    this.removeAt(this.items.indexOf(date));
    this.updateValueAndValidity();
  }

  protected override makeNewGroup(model: CustomDate): CustomDateForm {
    return new CustomDateForm(model);
  }
}

export class CustomDateForm extends TypedFormGroup<CustomDate> {
  readonly startDate = this.getFormControl('startDate');
  readonly endDate = this.getFormControl('endDate');
  readonly title = this.getFormControl('title');
  readonly description = this.getFormControl('description');
  readonly userId = this.getFormControl('userId');
  readonly changeOrderItemId = this.getFormControl('changeOrderItemId');
  readonly workOrderId = this.getFormControl('workOrderId');
  readonly workOrderItemId = this.getFormControl('workOrderItemId');

  // tslint:disable-next-line:variable-name
  private _isEditing = false;
  private originalValue?: CustomDate;

  override get updatedModel(): CustomDate {
    return {
      ...this.originalModel,
      ...this.value,
      startDateValue:
        typeof this.value?.startDate === 'string'
          ? parseISO(this.value.startDate).valueOf()
          : this.value?.startDate?.valueOf(),
      endDateValue:
        typeof this.value?.endDate === 'string'
          ? parseISO(this.value.endDate).valueOf()
          : this.value?.endDate?.valueOf(),
    } as CustomDate;
  }

  get isEmpty(): boolean {
    return !this.value.startDate && !this.value.endDate;
  }

  get isIncomplete(): boolean {
    return !this.value.startDate || !this.value.endDate;
  }

  get isEditing(): boolean {
    return this._isEditing;
  }

  get isNew(): boolean {
    return this._isNew;
  }

  get parentArray(): CustomDateFormArray {
    return this.parent as unknown as CustomDateFormArray;
  }

  // tslint:disable-next-line:variable-name
  constructor(
    model?: Partial<CustomDate>,
    private _isNew = false,
    private builder: UntypedFormBuilder = new UntypedFormBuilder(),
  ) {
    super({
      model,
      config: {
        controls: {
          title: [model?.title],
          description: [model?.description],
          startDate: builder.control(model?.startDate, { validators: Validators.required, updateOn: 'blur' }),
          endDate: builder.control(model?.endDate, { validators: Validators.required, updateOn: 'blur' }),
          userId: [model?.userId],
          // changeOrderItemId: [model?.changeOrderItemId || defaultValues.changeOrderItemId],
          workOrderId: [model?.workOrderId],
          workOrderItemId: [model?.workOrderItemId],
        },
        validatorOrOpts: [
          compareDateFields(
            'endDate',
            'startDate',
            (end, start) => end >= start,
            'End Date must match or fall after the Start Date.',
          ),
          compareDateFields(
            'startDate',
            'endDate',
            (start, end) => start <= end,
            'Start Date cannot be set after End Date',
          ),
        ],
      },
    });
  }

  edit() {
    this._isEditing = true;
  }

  completeEdit() {
    this._isEditing = false;
    this._isNew = false;
  }

  cancelEdit() {
    this._isNew ? this.parentArray.remove(this) : this.patchValue({ ...this.originalModel, ...this.originalValue });
    this._isEditing = false;
  }

  override patchValue(
    value: {
      [p: string]: any;
    },
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ) {
    this.model = value as Partial<CustomDate>;
    super.patchValue(value, options);
    this.updateValueAndValidity();
  }

  override setValue(value: any, options: ValueChangeOptions = {}) {
    super.setValue(value, options);
    this.updateValueAndValidity();
  }

  updateModel(model: Partial<CustomDate>): void {
    this.patchValue(model);
    this.updateValueAndValidity();
  }
}
