export interface DwollaLink<T extends string, U extends string> {
  href: `${string}/${U}`;
  type: 'application/vnd.dwolla.v1.hal+json';
  'resource-type': T;
}

export interface DwollaSuccessResponseBase {
  resource: string;
  response: Record<string, any>;
}

export interface DwollaFailureResponseBase {
  resource: string;
  response: {
    code: string;
    message: string;
    _links: {
      about?: DwollaLink<string, string>;
    };
  };
}

export interface DwollaCustomerCreateSuccess extends DwollaSuccessResponseBase {
  resource: 'customers';
  response: {
    location: string;
  };
}

type DwollaBeneficialOwnershipResource<T extends string = ':id'> = `customers/${T}/beneficial-ownership`;

export interface DwollaBeneficialOwnerCertification extends DwollaSuccessResponseBase {
  resource: DwollaBeneficialOwnershipResource;
  response: {
    status: 'certified';
    _links: {
      self: DwollaLink<'beneficial-ownership', DwollaBeneficialOwnershipResource>;
    };
  };
}

export type DwollaSuccessResponse = DwollaCustomerCreateSuccess | DwollaBeneficialOwnerCertification;
export type DwollaFailureResponse = DwollaFailureResponseBase;

export type DwollaSuccessCallbackArg = DwollaSuccessResponse | DwollaFailureResponse;

export const dwollaResponseIsFailure = (res: DwollaSuccessCallbackArg): res is DwollaFailureResponse =>
  !!(res as DwollaFailureResponseBase).response.code || !!(res as DwollaFailureResponseBase).response.message;
