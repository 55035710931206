<ng-container *ngIf="pendingInvoice">
  <ion-list *ngFor="let item of pendingInvoice.items; let lastItem = last">
    <ion-item [lines]="lastItem && !item?.applicableChangeOrders?.length ? 'full' : 'none'">
      <ion-label>
        <h2>
          <strong>{{ item.name }}</strong>
        </h2>
        <p>
          <span>
            {{ item.amountToInvoice }} <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
          </span>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
</ng-container>
