<gc-hammer-rating-chart
  *ngIf="chart; else noProductInstallationType"
  [chart]="chart"
  [title]="title"
></gc-hammer-rating-chart>

<ng-template #noProductInstallationType>
  <gc-empty-product-installation-type
    [canEditProfile]="canEditProfile"
    [selectedProductInstallationTypeClaim]="selectedProductInstallationTypeClaim"
    [types]="types"
    (removed)="productInstallationTypeClaimRemoved.emit($event)"
  ></gc-empty-product-installation-type>
</ng-template>
