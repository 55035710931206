<ion-header>
  <ion-toolbar color="gc-blue">
    <ion-buttons slot="start">
      <ion-button (click)="modalGC.dismiss(undefined, 'cancel')">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> Decline this <span class="text-title">change order item</span>?</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-margin">
    <strong>
      <ion-text class="ion-text-wrap">
        Please provide a reason why you are declining this <span class="text-title">change order item</span>. This will
        be shared with the {{ (user$ | push | isInstaller) ? 'company' : 'installer' }}.
      </ion-text>
    </strong>
  </div>
  <ion-item lines="full" class="ion-align-items-start">
    <ion-label>
      <h2>
        <strong>
          {{ item.name }}
        </strong>
      </h2>

      <ng-container>
        <p>
          <span> {{ item.numberOfUnits }} @ {{ item.pricePerUnit | currency }} </span>
          /
          <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
        </p>
      </ng-container>
      <gc-units-pending [item]="item"></gc-units-pending>
    </ion-label>
    <ion-text class="ion-no-padding ion-text-end" slot="end" style="margin-top: 10px">
      <ion-text [color]="item.isChangeOrder ? 'gc-blue' : 'gc-green'">
        <strong>{{ item.invoiceInfo.originalTotal | currency }}</strong>
      </ion-text>
    </ion-text>
  </ion-item>

  <form [formGroup]="form">
    <ion-item lines="none">
      <ion-textarea
        rows="5"
        formControlName="reason"
        label="Reason for declining"
        errorText="You must provide a reason for declining this change order item."
        labelPlacement="stacked"
        clearInput
        [color]="form!.get('reason')?.invalid ? 'danger' : undefined"
      ></ion-textarea>
    </ion-item>
  </form>
</ion-content>

<ion-footer class="ion-padding">
  <ion-button
    mode="ios"
    color="gc-green"
    expand="block"
    size="large"
    (click)="modalGC.dismiss({ reason: form.get('reason')!.value, item: item }, 'ok')"
    [disabled]="form.invalid"
  >
    <strong> Confirm </strong>
  </ion-button>
</ion-footer>
