import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DetailedWorkOrderItem } from '../models/detailed-work-order-item';
import { AppState } from '../state';
import { WorkOrderItem } from '../work/work-order-item/work-order-item.model';
import { WorkOrder } from '../work/work-order/work-order.model';
import { ChangeOrder } from './change-order/change-order.model';
import {
  changeOrderItemAcceptanceRequested,
  changeOrderItemAdded,
  changeOrderItemApprovalRequested, changeOrderItemDeclineRequested,
  changeOrderItemVoidRequested,
  changeOrderModificationAcceptanceRequested,
  changeOrderModificationCreated,
  changeOrderModificationVoidRequested,
  projectManagerCallRequested,
} from './change-orders.actions';

@Injectable()
export class ChangeOrdersFacade {
  constructor(private readonly store: Store<AppState>) {}

  createChangeOrderDeduction(workOrderItem: DetailedWorkOrderItem): void {
    this.store.dispatch(changeOrderModificationCreated({ workOrderItem }));
  }

  addChangeOrderItem(workOrder: WorkOrder): void {
    this.store.dispatch(changeOrderItemAdded({ workOrder }));
  }

  acceptModifyByCurrentUser(changeOrder: ChangeOrder): void {
    this.store.dispatch(changeOrderModificationAcceptanceRequested({ changeOrder }));
  }

  callProjectManager(workOrder: WorkOrder): void {
    this.store.dispatch(projectManagerCallRequested({ workOrder }));
  }

  voidPendingChangeOrderModify(changeOrder: ChangeOrder): void {
    this.store.dispatch(changeOrderModificationVoidRequested({ changeOrder }));
  }

  acceptItemByCurrentUser(workOrderItem: WorkOrderItem): void {
    this.store.dispatch(changeOrderItemAcceptanceRequested({ workOrderItem }));
  }

  approveItemByCurrentUser(workOrderItem: WorkOrderItem): void {
    this.store.dispatch(changeOrderItemApprovalRequested({ workOrderItem }));
  }

  declineItemByCurrentUser(workOrderItem: DetailedWorkOrderItem): void {
    this.store.dispatch(changeOrderItemDeclineRequested({ workOrderItem }));
  }

  voidPendingChangeOrderItem(workOrderItem: WorkOrderItem): void {
    this.store.dispatch(changeOrderItemVoidRequested({ workOrderItem }));
  }
}
