import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SettlementTimingFacade } from '~gc/domains/invoices/settlement-timing/settlement-timing.facade';
import { DwollaFacade } from '~gc/shared/dwolla/dwolla.facade';
import { BrowserService } from '../../../domains/browser.service';

@Component({
  selector: 'gc-explain-settlement-timing',
  templateUrl: './explain-settlement-timing.component.html',
  styleUrls: ['./explain-settlement-timing.component.scss'],
})
export class ExplainSettlementTimingComponent {
  constructor(
    private browser: BrowserService,
    public dwolla: DwollaFacade,
    public modalGC: ModalController,
    public settlementTimings: SettlementTimingFacade,
  ) {}

  async openUrl(url: string): Promise<void> {
    await this.browser.openUrl(url);
  }
}
