import { CustomDate, CustomDateType } from '../../domains/custom-dates';
import { Installer, isInstallerLead, isManager, ProjectManager, User } from '../../domains/users';
import { DetailedWorkOrder } from '../../domains/work/models/detailed-work-order';
import { WorkOrder } from '../../domains/work/work-order/work-order.model';
import { isAwardedToInstaller } from '../../shared/pipes/is-awarded-to-installer.pipe';
import { compose } from '../../shared/utils/compose.util';
import { matchOptionalByOptionalId } from '../../shared/utils/utils';
import { UserCustomDate } from './models/user-custom-date.model';
import { WorkOrderDate } from './models/work-order-date.model';

export const findFilteredWorkOrders = (
  workOrders: DetailedWorkOrder[],
  installerIds?: string[],
  projectManagerIds?: string[],
): DetailedWorkOrder[] =>
  !installerIds?.length && !projectManagerIds?.length
    ? workOrders
    : workOrders.filter(
        wo =>
          installerIds?.some(id => id === wo.awardedToInstallerId) ||
          projectManagerIds?.some(id => id === wo.projectManagerId),
      );

export const whenType =
  <R extends CustomDate>(options: { [key in CustomDateType]: (date: CustomDate) => R | CustomDate }) =>
  (date: CustomDate): R | CustomDate =>
    date.type ? options[date.type]?.(date) ?? date : date;

export const buildWorkOrderDate =
  (workOrders: DetailedWorkOrder[]) =>
  (date: CustomDate): WorkOrderDate => ({
    ...date,
    workOrder: workOrders.find(matchOptionalByOptionalId(date.workOrderId)),
  });

export const formatCalendarEventTitle = (workOrder: WorkOrder, currentUser?: User) =>
  !!currentUser &&
  (isManager(currentUser) || (isInstallerLead(currentUser) && isAwardedToInstaller(workOrder, currentUser)))
    ? workOrder.privateJobName || workOrder.publicJobName
    : workOrder.publicJobName || '[Job Name Not Specified]';

export const setDescriptionTitle = (currentUser?: User) => (date: WorkOrderDate) =>
  date.workOrder
    ? {
        ...date,
        title: formatCalendarEventTitle(date.workOrder, currentUser),
        description: date.workOrder.publicJobSummary || '[Job Summary Not Specified]',
      }
    : date;

export const attachInstaller = (installers: Installer[]) => (date: WorkOrderDate) =>
  date.workOrder
    ? {
        ...date,
        installer:
          date.workOrder.awardedToInstaller ??
          installers.find(matchOptionalByOptionalId(date.workOrder.awardedToInstallerId)),
      }
    : date;

export const attachProjectManager = (projectManagers: ProjectManager[]) => (date: WorkOrderDate) =>
  date.workOrder
    ? {
        ...date,
        projectManager:
          date.workOrder.projectManager ??
          projectManagers.find(matchOptionalByOptionalId(date.workOrder.projectManagerId)),
      }
    : date;

export const passThroughDate =
  () =>
  (date: CustomDate): UserCustomDate =>
    date;

export const buildTypedCustomDates = (
  customDates: CustomDate[],
  workOrders: DetailedWorkOrder[],
  installers: Installer[],
  projectManagers: ProjectManager[],
  currentUser?: User,
) =>
  customDates.map(
    whenType({
      workorder: compose(
        buildWorkOrderDate(workOrders),
        setDescriptionTitle(currentUser),
        attachInstaller(installers),
        attachProjectManager(projectManagers),
      ),
      userevent: passThroughDate(),
      changeorder: passThroughDate(),
    }),
  );
