import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { AppUIService } from '../../../domains/app-ui.service';
import { GenericFile } from '../../../domains/models/generic-file';
import { PendingFile } from '../../../domains/models/pending-file';
import { WorkOrderFileForm, WorkOrderFileFormArray } from '../../../modals/add-edit-work-order/draft-work-order.form';
import { ProcessingFile } from '../../file-uploads/file.selectors';
import { HasTargetFiles, HasTargetValue } from '../../utils/types';

@Component({
  selector: 'gc-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.scss'],
})
export class FileManagerComponent {
  @Input() files?: ProcessingFile<GenericFile>[];
  @Input() fileForms?: WorkOrderFileFormArray;
  @Input() enableUpload = true;
  @Input() hideWarning = false;
  @Output() fileRetried = new EventEmitter<PendingFile>();
  @Output() fileDeleted = new EventEmitter<PendingFile>();
  @Output() fileAdded = new EventEmitter<PendingFile>();

  constructor(private appUI: AppUIService) {}

  private addPendingFile(files: File[]) {
    files
      .map((file: File) => ({
        filename: file.name,
        contentType: file.type,
        fileBlob: file,
      }))
      .forEach(file => {
        this.fileAdded.emit(file);
      });
  }

  addFiles($event: Event & HasTargetFiles & HasTargetValue): void {
    this.addPendingFile(Array.from($event.target.files));
    $event.target.value = null;
  }

  addDroppedFiles(files: FileList): void {
    this.addPendingFile(Array.from(files));
  }

  trackFilesBy(index: number, file: ProcessingFile<GenericFile> | WorkOrderFileForm) {
    return file instanceof WorkOrderFileForm ? file.filename : file.id || (file as PendingFile).correlationId;
  }

  async deleteFile(file: PendingFile) {
    if (await this.appUI.confirm('Delete File', 'Are you sure you want to delete this file?')) {
      this.fileDeleted.emit(file);
    }
  }
}
