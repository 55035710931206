import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { LienReleaseTemplate } from './lien-release-template.model';

export const {
  initialState: initialLienReleaseState,
  facade: LienReleaseTemplateFacadeBase,
  selectors: {
    selectAll: allLienReleaseTemplates,
    selectEntities: allLienReleaseEntities,
    selectCurrentEntity: currentLienReleaseTemplate,
    selectIsLoading: lienReleaseIsLoading,
  },
  actions: {
    load: loadLienReleaseTemplate,
    loadAll: loadAllLienReleaseTemplates,
    loadAllIfNecessary: loadAllLienReleaseTemplatesIfNecessary,
    selectByKey: selectLienReleaseTemplateByKey,
  },
} = buildState(LienReleaseTemplate);

export function lienReleaseTemplateReducer(state = initialLienReleaseState): IEntityState<LienReleaseTemplate> {
  return state;
}
