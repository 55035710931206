import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { TrainingClaimed } from './trainings-claimed.model';

export const {
  initialState,
  facade: TrainingClaimedFacadeBase,
  selectors: { selectAll: allTrainingsClaimed },
  actions: { loadAll: loadAllTrainingsClaimed, create: createTrainingClaim, delete: deleteTrainingClaimed },
} = buildState(TrainingClaimed);

export function trainingClaimedReducer(state = initialState): IEntityState<TrainingClaimed> {
  return state;
}
