import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { detailedInstallerProfileContext } from '../../../../core/installer-profile';
import { phoneNumberVerificationInitiated } from '../../../../core/profile/phone-numbers.actions';
import { PhoneNumber } from '../../../../domains/phone-number';
import { onlyNonNullish } from '../../../../shared/utils/rxjs';
import {
  phoneNumberCodeRefreshed,
  phoneNumberRemoving,
  phoneNumbersAdding,
  phoneNumberVerifying
} from '../../view-installer-profile-enhanced.actions';

@Component({
  selector: 'gc-details-area',
  templateUrl: './details-area.component.html',
  styleUrls: ['./details-area.component.scss'],
})
export class DetailsAreaComponent {
  context$ = this.store.select(detailedInstallerProfileContext).pipe(onlyNonNullish, tap(console.log));

  constructor(private readonly store: Store) {}

  addPhoneNumber(phoneNumber: PhoneNumber) {
    this.store.dispatch(phoneNumbersAdding({ phoneNumber }));
  }

  removePhoneNumber(phoneNumber: PhoneNumber) {
    this.store.dispatch(phoneNumberRemoving({ phoneNumber }));
  }

  verifyPhoneNumber({ phoneNumber, code }: { phoneNumber: PhoneNumber; code: string }) {
    this.store.dispatch(phoneNumberVerifying({ phoneNumber, code }));
  }

  refreshCode(phoneNumber: PhoneNumber) {
    this.store.dispatch(phoneNumberCodeRefreshed({ phoneNumber }));
  }
}
