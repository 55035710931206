import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { ChangeOrder } from './change-order.model';

export const {
  initialState: initialChangeOrderState,
  facade: ChangeOrderFacadeBase,
  selectors: { selectEntities: changeOrderEntities, selectAll: allChangeOrders },
  actions: {
    loadAll: loadAllChangeOrders,
    replace: replaceChangeOrder,
    replaceSuccess: replaceChangeOrderSuccess,
    create: createChangeOrder,
    clear: clearChangeOrders,
  },
} = buildState(ChangeOrder);

const reduce = createReducer(initialChangeOrderState);

export function changeOrderReducer(state = initialChangeOrderState, action: Action): IEntityState<ChangeOrder> {
  return reduce(state, action);
}
