import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PendingFile } from '../../../domains/models/pending-file';
import { User } from '../../../domains/users';
import { InstallerProfileForm } from '../installer-profile.form';

@Component({
  selector: 'gc-installer-profile-part-five',
  templateUrl: './installer-profile-part-five.component.html',
  styleUrls: [
    './installer-profile-part-five.component.scss',
    '../installer-profile-modal/installer-profile-modal.component.scss',
  ],
})
export class InstallerProfilePartFiveComponent {
  @Input() validated = false;
  @Input() form!: InstallerProfileForm;
  @Input() user?: User;
  @Output() photoChanged = new EventEmitter<{ user: User; file: PendingFile }>();
}
