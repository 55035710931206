import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { FileType, GenericFile } from '../../models/generic-file';

@Entity({
  modelName: 'TrainingFile',
  uriName: 'files',
})
export class TrainingFile extends GenericFile {
  @Key override id?: number;
  override type!: FileType.Experience;
}
