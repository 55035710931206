import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { State } from './state.model';
import { StateFacadeBase } from './state.state';

@Injectable({ providedIn: 'root' })
export class StateFacade extends StateFacadeBase {
  constructor(private store: Store<AppState>) {
    super(State, store);
  }
}
