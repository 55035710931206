import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Connection } from '~gc/domains/users/connection/connection.model';
import { ConfigService } from '../../../shared/services/config.service';
import { ROLES_MAP } from '../../app/auth/auth.maps';
import { InvitedUser } from '../models/invited-user';
import { USER_ROLES } from '../types';
import { User } from '../user/user.model';

@Injectable()
export class TeamService {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
  ) {}

  lookupUserByEmail(email: string) {
    return this.http.get<User>(`${this.config.host}/users/lookup/${email}`);
  }

  addNewUser(user: InvitedUser): Observable<{ userId: string }> {
    const role = USER_ROLES[user.role];
    return this.http.post<{ userId: string }>(`${this.config.host}/users/invite`, {
      ...user,
      role: ROLES_MAP[role],
    });
  }

  sendUserInvite(email: string): Observable<Connection> {
    return this.http.post<Connection>(`${this.config.host}/my-network/invite`, { email });
  }
}
