import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { WorkOrder } from '../work-order/work-order.model';
import { currentWorkOrder } from '../work-order/work-order.state';
import { Contact } from './contact';

export const {
  initialState: initialContactState,
  facade: ContactFacadeBase,
  selectors: { selectAll: allContacts },
  actions: {
    loadAll: loadAllContacts,
    clear: clearContacts,
  }
} = buildState(Contact);

export function contactReducer(state = initialContactState): IEntityState<any> {
  return state;
}

export const filterContactsForWorkOrder = (contacts: Contact[], workOrder?: WorkOrder): Contact[] =>
  workOrder?.id ? contacts.filter(contact => contact.workOrderId === workOrder.id) : [];

export const contactsForWorkOrder = createSelector(allContacts, currentWorkOrder, filterContactsForWorkOrder);
