import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { DisplayGroupAllowance, isStatusGroup, WorkOrderItemGroup } from '../../../../domains/work-order-item-group.selectors';
import { WorkOrder } from '../../../../domains/work/work-order/work-order.model';


@Component({
  selector: 'gc-add-deduction-button',
  templateUrl: './add-deduction-button.component.html',
  styleUrls: ['./add-deduction-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDeductionButtonComponent {
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() workOrder!: WorkOrder;
  @Input() group!: WorkOrderItemGroup;
  @Input() permissions!: string[];
  @Input() item!: DetailedWorkOrderItem;
  @Input() displayAddDeductionButton!: boolean;

  @Output() added = new EventEmitter<DetailedWorkOrderItem>();
  protected readonly isStatusGroup = isStatusGroup;
}
