import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InformationMessageComponent } from './information-message.component';

@NgModule({
  declarations: [InformationMessageComponent],
  imports: [CommonModule, IonicModule],
  exports: [InformationMessageComponent],
})
export class InformationMessageModule {}
