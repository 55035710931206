import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'gc-datepicker-item',
  templateUrl: './datepicker-item.component.html',
  styleUrls: ['./datepicker-item.component.scss'],
})
export class DatepickerItemComponent {
  @Input() disabled = false;
  @Input() control?: UntypedFormControl | null;
  @Input() placeHolder = '';
  @Input() max?: string;
  @Input() min?: string;
  @Input() presentation = 'date';
  @Input() size = 'cover';
  @Input() lines = 'full';
  @Input() closeOn!: 'select' | 'submit';
  @Input() closeOnSelect = false;
  @Input() showSubmit = false;

  @Output() dismissed = new EventEmitter<'cancel' | 'confirm'>();
}
