import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IonicAuth } from '@ionic-enterprise/auth';
import { AuthResult } from '@ionic-enterprise/auth/dist/esm/interfaces';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { LogService } from '~gc/shared/services/log.service';
import { ConfigService } from '../../../shared/services/config.service';
import { AppState } from '../../state';
import { authenticatedRole } from './auth.selectors';

const TAGS = ['Service', 'Auth', 'AuthConnect'];

@Injectable({ providedIn: 'root' })
export class AuthConnectService extends IonicAuth {
  constructor(
    private readonly platform: Platform,
    private readonly router: Router,
    private readonly log: LogService,
    private readonly store: Store<AppState>,
    private readonly config: ConfigService,
  ) {
    super(config.authConfig);
  }

  override onLoginSuccess(result: AuthResult): void {
    this.log.debug(TAGS, 'Login successful, redirecting...');

    // TODO: The following code does not have a subscription, so probably never gets executed!!!
    this.store.select(authenticatedRole).pipe(
      take(1),
      switchMap(role =>
        !!role ? this.router.navigateByUrl('/app/dashboard') : this.router.navigateByUrl('/login-landing'),
      ),
    );
  }

  override async onLogout(): Promise<void> {
    this.log.debug(TAGS, 'Logout completed.');
    await this.clearStorage();
    await this.router.navigateByUrl('/login');
  }

  getJWT(): Promise<string | undefined> {
    return super.getImplementation(this.config.authConfig).getRawIdToken();
  }
}
