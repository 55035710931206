import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PendingFile } from '../models/pending-file';
import { AppState } from '../state';
import { Category } from './category/category.model';
import { ProductInstallationTypeClaimed } from './product-installation-types-claimed/product-installation-types-claimed.model';
import { ProductInstallationType } from './product-installation-types/product-installation-types.model';
import {
  claimableProductInstallationTypes,
  currentClaimedProductInstallationType,
  currentInstallTypeCategories,
  currentInstallTypeTrainingFiles,
  currentUserTrainingsClaimedForCurrentInstallType,
  unclaimedTrainingsForCurrentCategory,
} from './skills-and-experience.selectors';
import {
  addTrainingFile,
  cancelEditSkillsAndExperience,
  confirmDeletionOfInstallationTypesClaimed,
  confirmDeletionOfTrainingClaimed,
  productInstallationTypeClaimUpdated,
  productInstallTypeClaimedSelected,
  showUnclaimedTrainingSelection,
} from './training.actions';
import { TrainingClaimed } from './trainings-claimed/trainings-claimed.model';

@Injectable()
export class SkillsAndExperienceFacade {
  claimableProductInstallationTypes$ = this.store.select(claimableProductInstallationTypes);
  currentClaimedProductInstallationType$ = this.store.select(currentClaimedProductInstallationType);
  currentInstallTypeCategories$ = this.store.select(currentInstallTypeCategories);
  currentUserTrainingsObtainedForCurrentInstallType$ = this.store.select(
    currentUserTrainingsClaimedForCurrentInstallType,
  );
  unclaimedTrainingsForCurrentCategory$ = this.store.select(unclaimedTrainingsForCurrentCategory);
  trainingFiles$ = this.store.select(currentInstallTypeTrainingFiles);

  constructor(private store: Store<AppState>) {}

  selectDiscipline(discipline: ProductInstallationType): void {
    this.store.dispatch(productInstallTypeClaimedSelected({ installType: discipline }));
  }

  updateProductInstallationTypeClaim(claim: ProductInstallationTypeClaimed): void {
    this.store.dispatch(productInstallationTypeClaimUpdated({ claim }));
  }

  addTrainingFile({ claim, file }: { claim: ProductInstallationTypeClaimed; file: PendingFile }): void {
    this.store.dispatch(addTrainingFile({ claim, file }));
  }

  cancelEditSkillsAndExperience(): void {
    this.store.dispatch(cancelEditSkillsAndExperience());
  }

  showUnclaimedTrainingSelection(category: Category, claimedInstallType: ProductInstallationTypeClaimed): void {
    this.store.dispatch(showUnclaimedTrainingSelection({ category, claimedInstallType }));
  }

  confirmDeletionOfTrainingClaimed(claimed: TrainingClaimed): void {
    this.store.dispatch(confirmDeletionOfTrainingClaimed({ claimed }));
  }

  confirmDeletionOfInstallationTypesClaimed(claimed: ProductInstallationTypeClaimed): void {
    this.store.dispatch(confirmDeletionOfInstallationTypesClaimed({ claimed }));
  }
}
