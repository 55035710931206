import { Pipe, PipeTransform } from '@angular/core';
import { DetailedWorkOrderItem } from '../../domains/models/detailed-work-order-item';
import { NegotiableWorkOrderItem } from '../../domains/models/negotiable-work-order-item';

@Pipe({ name: 'hasNegotiations' })
export class HasNegotiationsPipe implements PipeTransform {
  transform(item?: DetailedWorkOrderItem | NegotiableWorkOrderItem): boolean {
    return !!item?.negotiations?.some(
      negotiation => negotiation?.currentBidPrice && negotiation.currentBidPrice !== item.pricePerUnit,
    );
  }
}
