import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({ providedIn: 'root' })
export class StorageRefService {
  private isCreated = false;

  constructor(private readonly _storage: Storage) {}

  async storage(): Promise<Storage> {
    if (!this.isCreated) {
      await this._storage.create();
      this.isCreated = true;
    }
    return this._storage;
  }
}
