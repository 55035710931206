import { Action, createAction, createFeatureSelector, createReducer, on, props } from '@ngrx/store';

export interface LocationCoords {
  lat: number;
  lng: number;
}

export const workOrderMapRouteVisit = createAction('[Work Order] (Map) Route Visit');

export const googleMapsApiLoaded = createAction('[Work Order] (Map) Google API Loaded');

export const mapPlaceFound = createAction('[Work Order] (Map) Place Found', props<{ place: LocationCoords }>());

export const mapBoundsChanged = createAction(
  '[Work Order] (Map) Map Bounds Changed',
  props<{ place: LocationCoords; searchRadius: number }>(),
);

export const mapCenterChanged = createAction('[Work Order] (Map) Map Center Changed');

export const loadWorkOrdersForNewBounds = createAction('[Work Order] (Map) Load For New Bounds');

export interface WorkOrderMapState {
  mapsApiLoaded: boolean;
  place?: LocationCoords;
  // Radius in meters
  searchRadius: number;
  loading: boolean;
}

export const initialWorkOrderMapState: WorkOrderMapState = {
  mapsApiLoaded: false,
  searchRadius: 9000 * 10,
  loading: false,
};

const reduce = createReducer(
  initialWorkOrderMapState,
  on(googleMapsApiLoaded, state => ({ ...state, mapsApiLoaded: true })),
  on(mapPlaceFound, (state, { place }) => ({ ...state, place, loading: true })),
  on(mapBoundsChanged, (state, { place, searchRadius }) => ({ ...state, place, searchRadius })),
  on(mapCenterChanged, state => ({ ...state, loading: false })),
);

export function workOrderMapReducer(state = initialWorkOrderMapState, action: Action) {
  return reduce(state, action);
}

export const workOrderMapState = createFeatureSelector<WorkOrderMapState>('workOrderMap');
