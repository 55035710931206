import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { WorkOrderTag } from './work-order-tag.model';

export const {
  initialState: initialWorkOrderTagState,
  facade: WorkOrderTagFacadeBase,
  selectors: { selectAll: allWorkOrderTags },
  actions: {
    loadAll: loadAllWorkOrderTags,
    loadAllSuccess: loadAllWorkOrderTagsSuccess,
    create: createWorkOrderTag,
    delete: deleteWorkOrderTag,
    clear: clearWorkOrderTags,
  },
} = buildState(WorkOrderTag);

const reduce = createReducer(initialWorkOrderTagState);

export function workOrderTagReducer(state = initialWorkOrderTagState, action: Action): IEntityState<WorkOrderTag> {
  return reduce(state, action);
}
