import { SyncKeyMap } from './storage-sync.config';

// TODO: Figure out how the heck to properly type this so we can't use it incorrectly!!!
export const getRestorationState = <T, K extends keyof T>(state: T, key: K, keyMap: SyncKeyMap<T>): T[K] =>
  typeof keyMap[key] === 'object' ? buildRestorableState(state[key], keyMap[key] as SyncKeyMap<T[K]>) : state[key];

export const buildRestorableState = <T, K extends keyof T>(state: T, keys: SyncKeyMap<T>) =>
  (Object.keys(state as unknown as object) as unknown as K[]).reduce(
    (restoredState: T, key: K) =>
      keys[key] === true || typeof keys[key] === 'object'
        ? { ...restoredState, [key]: getRestorationState(state, key, keys) }
        : restoredState,
    {} as T,
  );

export const getStorableState = <T>(state: T, keys: SyncKeyMap<T>) =>
  (Object.keys(state as unknown as object) as unknown as string[]).reduce(
    (restoredState: T, key: string) =>
      keys[key as keyof T] === true || typeof keys[key as keyof T] === 'object'
        ? { ...restoredState, [key]: getRestorationState(state, key as keyof T, keys) }
        : restoredState,
    {} as T,
  );
