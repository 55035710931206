<div class="flex align-items-center justify-content-between title-container pr-20">
  <h1 class="modal-title" data-cy="training-title">Education & Training</h1>
</div>
<ng-container *rxLet="installationTypesClaimed$; let types; suspense: tabSuspense">
  <gc-training-tabs
    *ngIf="types?.length; else emptyArea"
    [types]="types"
    [selectedProductInstallationTypeClaim]="selectedProductInstallationTypeClaim$ | push"
    [canEditProfile]="canEditProfile"
    (selected)="installTypeAreaTabSelected.emit($event)"
    (added)="installTypeClaimAdded.emit()"
  ></gc-training-tabs>
  <ng-template #emptyArea>
    <div class="alert alert__warning text-center m-20" data-cy="empty-training-area">No Education & Training found.</div>
  </ng-template>
</ng-container>

<ng-template #tabSuspense>
  <gc-tab-skeleton></gc-tab-skeleton>
</ng-template>

<div *rxLet="educationAndTrainingContext$; let trainingContext">
  <ng-container *ngIf="trainingContext">
    <gc-training-product-installation-type-details
      [canEditProfile]="canEditProfile"
      [selectedProductInstallationTypeClaim]="selectedProductInstallationTypeClaim$ | push"
      [types]="installationTypesClaimed$ | push"
      [chart]="trainingContext.chart"
      [title]="trainingContext.installType.type"
      (productInstallationTypeClaimRemoved)="installTypeClaimRemoved.emit($event)"
    ></gc-training-product-installation-type-details>
    <gc-training-product-installation-subtypes
      [claimedInstallType]="trainingContext?.installType"
      [productInstallationSubtypes]="trainingContext?.subtype"
      [canEditProfile]="canEditProfile"
      (subtypeAdded)="subtypeAdded.emit($event)"
      (subtypeRemoved)="subtypeRemoved.emit($event)"
    ></gc-training-product-installation-subtypes>
    <ng-container *rxLet="installationTypesClaimed$; let types; suspense: tabSuspense">
      <gc-training-list
        [groups]="trainingContext.trainingsByCategory"
        [canEditProfile]="canEditProfile"
        [selectedProductInstallationTypeClaim]="selectedProductInstallationTypeClaim$ | push"
        [types]="types"
        (added)="trainingClaimAdded.emit($event)"
        (removed)="trainingClaimRemoved.emit($event)"
        [attr.data-cy]="trainingContext.installType.type + '-training-list'"
      ></gc-training-list>
    </ng-container>
  </ng-container>
</div>
