import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DetailedProductInstallationSubtypeClaimed
} from '../../../../core/installer-profile';
import {
  ProductInstallationSubtypesClaimed
} from '../../../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';

@Component({
  selector: 'gc-installer-subtypes-badge-list',
  templateUrl: './installer-subtypes-badge-list.component.html',
  styleUrls: ['./installer-subtypes-badge-list.component.scss'],
})
export class InstallerSubtypesBadgeListComponent {
  @Input() subtypes?: DetailedProductInstallationSubtypeClaimed[];
  @Input() showRemove!: boolean;
  @Output() remove = new EventEmitter<ProductInstallationSubtypesClaimed>();
}
