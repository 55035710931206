import { RouterNavigatedAction, RouterNavigationAction, RouterRequestAction } from '@ngrx/router-store';
import { of, pipe } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { GCSerializedRouterStateSnapshot } from '../../../domains/router-store.serializer';

export type RouterAction =
  | RouterRequestAction<GCSerializedRouterStateSnapshot>
  | RouterNavigationAction<GCSerializedRouterStateSnapshot>
  | RouterNavigatedAction<GCSerializedRouterStateSnapshot>;

export const getPath = (state: GCSerializedRouterStateSnapshot): string => state.path ?? state.url;

export const routeMatchesPath = (...urls: string[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => urls.some(expected => expected === url)),
        map(() => r),
      ),
    ),
  );
export const routeNotMatchesPath = (...urls: string[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => !urls.some(expected => expected === url)),
        map(() => r),
      ),
    ),
  );

export const routeIncludesPath = (...urls: string[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => urls.some(expected => url.includes(expected))),
        map(() => r),
      ),
    ),
  );
export const routeNotIncludesPath = (...urls: string[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => !urls.some(expected => url.includes(expected))),
        map(() => r),
      ),
    ),
  );

export const routeEndsInPath = (...urls: string[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => urls.some(expected => url.endsWith(expected))),
        map(() => r),
      ),
    ),
  );
export const routeNotEndsInPath = (...urls: string[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => !urls.some(expected => url.endsWith(expected))),
        map(() => r),
      ),
    ),
  );

export const routeStartsWithPath = (...urls: string[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => urls.some(expected => url.startsWith(expected))),
        map(() => r),
      ),
    ),
  );
export const routeNotStartsWithPath = (...urls: string[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => !urls.some(expected => url.startsWith(expected))),
        map(() => r),
      ),
    ),
  );

export const routeMatchesRegExp = (...urlExps: RegExp[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => urlExps.some(urlExp => urlExp.test(url))),
        map(() => r),
      ),
    ),
  );
export const routeNotMatchesRegExp = (...urlExps: RegExp[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) => !urlExps.some(urlExp => urlExp.test(url))),
        map(() => r),
      ),
    ),
  );

export const routeMatches = (...urls: (string | RegExp)[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter((url: string) =>
          urls.some(expected => (typeof expected === 'string' ? url.startsWith(expected) : expected.test(url))),
        ),
        map(() => r),
      ),
    ),
  );
export const routeNotMatches = (...urls: (string | RegExp)[]) =>
  pipe(
    mergeMap((r: RouterAction) =>
      of(getPath(r.payload.routerState)).pipe(
        filter(
          (url: string) =>
            !urls.some(expected => (typeof expected === 'string' ? url.startsWith(expected) : expected.test(url))),
        ),
        map(() => r),
      ),
    ),
  );
