import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ClaimableProductInstallationType } from '../../../domains/training/models/obtainable-product-installation-types';
import { ProductInstallationTypeClaimed } from '../../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { createDatePickerTime } from '../../../shared/utils/utils';

@Component({
  selector: 'gc-start-date',
  templateUrl: './start-date.component.html',
  styleUrls: ['./start-date.component.scss'],
})
export class StartDateComponent implements OnInit {
  @Input() installationType?: ClaimableProductInstallationType;
  @Output() changed = new EventEmitter<ProductInstallationTypeClaimed>();

  dateClaimed!: UntypedFormControl;
  today = createDatePickerTime();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.dateClaimed = this.fb.control(this.installationType!.claimed?.dateClaimed || null, Validators.required);
  }

  save() {
    if (this.dateClaimed.invalid) {
      return;
    }

    this.changed.emit({
      ...(this.installationType?.claimed || {}),
      dateClaimed: this.dateClaimed.value,
      productInstallationTypeId: this.installationType!.id,
    } as ProductInstallationTypeClaimed);
  }
}
