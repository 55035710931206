import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { STATE_RESTORED } from './storage-sync.actions';
import { storageSyncConfig, storageSyncConfigFactory } from './storage-sync.config';

const resolveHydrationKey = () => (storageSyncConfigFactory as any)[storageSyncConfig]?.hydrationKey || 'hydrated';

const mergeStateSlice = <T>(state: T, restoredState: T): T =>
  typeof state === 'object'
    ? {
        ...state,
        ...restoredState,
      }
    : state || restoredState;

const mergeNestedState = <T extends object>(state: T, restoredState: T): T =>
  (Object.keys(restoredState) as unknown as (keyof T)[]).reduce(
    (merged: T, key: keyof T) => ({
      ...merged,
      [key]: mergeStateSlice(state[key], restoredState[key]),
    }),
    {} as T,
  );

export const storageSyncReducer: MetaReducer = (reducer: ActionReducer<any>) => (state, action: Action) =>
  reducer(
    action.type === STATE_RESTORED.type
      ? {
          ...state,
          ...mergeNestedState(state, (action as any).restoredState),
          [resolveHydrationKey()]: true,
        }
      : state,
    action,
  );
