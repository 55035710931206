import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { exhaustMap, filter, takeUntil, tap } from 'rxjs/operators';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { authenticatedUser } from '../app/auth/auth.selectors';
import { currentRouteUrl } from '../router.selectors';
import { isEmployeeInstaller } from '../users';
import { currentWorkOrder } from './work-order/work-order.state';

@Injectable()
export class WorkAssignmentEffects implements OnRunEffects {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly router: Router,
  ) {}

  // current work order goes undefined when work order gets re-assigned
  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  redirectToWorkOrdersOnUnassigned$ = createEffect(
    () =>
      combineLatest([
        this.store.select(currentWorkOrder),
        this.store.select(currentRouteUrl),
        this.store.select(authenticatedUser),
      ]).pipe(
        filter(
          ([workOrder, url, user]) =>
            isEmployeeInstaller(user) &&
            workOrder?.awardedToInstallerId !== user?.id &&
            url?.startsWith('/app/work/orders/detail'),
        ),
        tap(() => this.router.navigateByUrl('/app/work/orders')),
      ),
    { dispatch: false },
  );

  redirectToWorkOrdersWhenVanished$ = createEffect(
    () =>
      combineLatest([this.store.select(currentWorkOrder), this.store.select(currentRouteUrl)]).pipe(
        filter(([workOrder, url]) => !workOrder && url?.startsWith('/app/work/orders/detail')),
        tap(() => this.router.navigateByUrl('/app/work/orders')),
      ),
    { dispatch: false },
  );
}
