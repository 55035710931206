import { matchOptionsToKey } from '../utils';

export const filterItems =
  <T>(where: (item: T) => boolean) =>
  (items: T[]): T[] =>
    items.filter(where);

export const filters =
  <T extends object, K extends keyof T>(prop: K) =>
  (items: T[], allowed: (T[K] | string)[]) =>
    items.filter(item => allowed.some(allow => allow === item[prop]));

export const filterByOptionsOnKeyIfAvailable =
  <T extends object, K extends keyof T>(key: K) =>
  (items?: T[], options?: T[K][]): T[] =>
    (options?.length ?? 0) > 0 ? items?.filter(matchOptionsToKey(options, key)) ?? [] : items ?? [];

export const wherePropMatches =
  <T, K extends keyof T, V extends T[K]>(prop: K, value?: V) =>
  (item: T) =>
    item[prop] === value;

export const exists = <T>(item?: T): boolean => !!item;

export const whereNull =
  <T extends object, K extends keyof T>(key: K) =>
  (item: T): boolean =>
    item[key] === null;

export const whereUndefined =
  <T extends object, K extends keyof T>(key: K) =>
  (item: T): boolean =>
    item[key] === undefined;

export const whereNullish =
  <T extends object, K extends keyof T>(key: K) =>
  (item: T): boolean =>
    item[key] == null;

export const whereFalsy =
  <T extends object, K extends keyof T>(key: K) =>
  (item: T): boolean =>
    !item[key];

export const whereTruthy =
  <T extends object, K extends keyof T>(key: K) =>
  (item: T): boolean =>
    !!item[key];
