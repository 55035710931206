<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="modalGC.dismiss(null, 'cancel')">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> Connections</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card class="ion-padding" mode="ios">
    <ion-icon name="information-circle" style="display: block; margin: 0 auto" size="large" color="dark"></ion-icon>
    <h2 class="ion-text-center" style="color: #222428">Looking to connect with additional flooring contractors?</h2>
    <ion-card-content class="ion-text-center"
      >Please reach out to
      <a href="mailto:support@gocarrera.com?subject=Add%20more%20companies%20to%20My%20Network">Go Carrera Support</a>
      for more information on Go Carrera licensed companies near you.
    </ion-card-content>
  </ion-card>
</ion-content>
