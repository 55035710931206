import { Injectable } from '@angular/core';
import {
  AlertController,
  IonicSafeString,
  LoadingController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { from, Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, takeUntil, tap, timeout } from 'rxjs/operators';
import { ProcessingFile } from '../shared/file-uploads/file.selectors';
import { FileUploadProgressPopoverComponent } from '../shared/popovers/file-upload-progress-popover/file-upload-progress-popover.component';
import { waitOn } from '../shared/utils/operators';
import { PendingFile } from './models/pending-file';

const PROCESS_TOAST_ID = 'ProcessToast';

@Injectable()
export class AppUIService {
  private cancelDelayHide$$ = new Subject();
  private spinner?: HTMLIonLoadingElement;
  private processingToast?: HTMLIonToastElement;
  private fileUploadPopover?: HTMLIonPopoverElement;

  constructor(
    private loader: LoadingController,
    private toasts: ToastController,
    private alerts: AlertController,
    private popovers: PopoverController,
  ) {}

  async showProcessingToast(text = 'Processing...') {
    await this.closeProcessingToast();

    const message = new IonicSafeString(
      `<ion-item color="dark">
        <ion-label>${text}</ion-label>
        <ion-spinner slot="end" style="--color: var(--ion-color-light);"></ion-spinner>
      </ion-item>`,
    );
    this.processingToast = await this.toasts.create({
      message,
      color: 'dark',
      position: 'bottom',
      id: PROCESS_TOAST_ID,
    });
    await this.processingToast.present();
    this.processingToast.onWillDismiss().then(() => (this.processingToast = undefined));
    return this.processingToast;
  }

  async closeProcessingToast() {
    return this.processingToast ? this.toasts.dismiss(null, undefined, PROCESS_TOAST_ID) : false;
  }

  async showLoading(message?: string) {
    const spinner = await this.loader.create({
      backdropDismiss: false,
      message,
    });
    await spinner.present();
    return spinner;
  }

  hideLoading() {
    return this.loader.dismiss();
  }

  async showBlockingSpinner() {
    if (this.spinner) {
      return;
    }

    this.spinner = await this.loader.create({
      showBackdrop: true,
      backdropDismiss: false,
      duration: 30000,
    });
    await this.spinner.present();
    this.spinner.onDidDismiss().then(() => (this.spinner = undefined));
  }

  async hideBlockingSpinner() {
    if (this.spinner) {
      await this.spinner.dismiss();
    }
  }

  hideBlockingSpinnerAfterDelay(delay: number = 1000) {
    this.cancelDelayHide$$.next(null);
    timer(delay)
      .pipe(takeUntil(this.cancelDelayHide$$))
      .subscribe(() => this.hideBlockingSpinner());
  }

  async toastResult(message: string, header = '', options = { duration: 5000, error: false }) {
    const { duration, error } = options;
    const toast = await this.toasts.create({
      header,
      message,
      buttons: [
        {
          text: 'Dismiss',
          side: 'end',
        },
      ],
      color: error ? 'danger' : 'dark',
      duration,
      position: 'bottom',
    });
    await toast.present();
    return toast.onDidDismiss();
  }

  showPendingFileUploads(header: string, pendingFiles$: Observable<ProcessingFile<PendingFile>[] | undefined>) {
    return from(
      this.popovers.create({
        component: FileUploadProgressPopoverComponent,
        backdropDismiss: false,
        cssClass: 'create-invoice-progress-popover',
        componentProps: {
          header,
          pendingFiles$,
        },
      }),
    ).pipe(
      tap(popover => (this.fileUploadPopover = popover)),
      timeout(5000),
      waitOn(confirm => confirm.present()),
      catchError(err =>
        throwError(new Error('Failed to load processing indicator. Please check your internet connection')),
      ),
    );
  }

  async hidePendingFileUploads() {
    if (this.fileUploadPopover) {
      await this.fileUploadPopover.dismiss();
    }
  }

  async alert(header: string, message: string, showAccept: boolean = false) {
    const alert = await this.alerts.create({
      header,
      message,
      buttons: showAccept ? ['Okay'] : undefined,
    });
    await alert.present();
    return alert.onDidDismiss();
  }

  async confirm(
    header?: string,
    message?: string,
    options?: { confirmText?: string; cssClass?: string },
  ): Promise<boolean> {
    const alert = await this.alerts.create({
      header: header ?? 'Confirm',
      message: message ?? 'Are you sure you want to continue? This cannot be undone.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: options?.confirmText ?? 'Continue',
          role: 'confirm',
          cssClass: options?.cssClass ?? 'ion-color-danger',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role === 'confirm';
  }
}
