<ng-template #view>
  <ng-container *ngIf="!!installer">
    <ion-list lines="none" *ngIf="installer | isInstallerTeamLead">
      <ion-list-header>
        <ion-label color="medium">BIO</ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label class="ion-text-wrap">
          {{ installer.bio }}
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-list lines="none" *ngIf="installer.addresses!.length > 0">
      <ion-list-header>
        <ion-label color="medium">WORK ADDRESS</ion-label>
      </ion-list-header>
      <ng-container *ngFor="let address of installer.addresses">
        <ion-item>
          <ion-label class="ion-text-wrap">
            {{ address.addressLine1 }} <br />
            {{ address.city }}, {{ address.stateOrProvince }}
            {{ address.postalCode }}
          </ion-label>
        </ion-item>
      </ng-container>
    </ion-list>

    <ng-container *ngIf="installer | asInstallerTeamLead as teamLead">
      <ion-list lines="none" *ngIf="teamLead.insuranceExpiration as expiration">
        <ion-list-header>
          <ion-label color="medium">INSURANCE EXPIRATION</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label [color]="(expiration | isWithinDays: 30) ? 'danger' : 'dark'">
            {{ expiration | date: 'mediumDate' }}
            <p *ngIf="expiration | isWithinDays: 30">Insurance Expires In {{ expiration | daysUntil }} Days</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ng-container>

    <div *ngIf="installer | asInstallerTeamLead as teamLead">
      <ion-list lines="none" *ngIf="teamLead.commercialWorkPercentage as commercialWorkPercentage">
        <ion-list-header>
          <ion-label color="medium">WORK EXPERIENCE BREAKDOWN</ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label *ngIf="commercialWorkPercentage >= 50" style="white-space: normal">
            {{ installer.firstName }} has indicated a focus on commerical work with
            just {{ 100 - commercialWorkPercentage }}
            % spent on residential work over the past three years.
          </ion-label>
          <ion-label *ngIf="commercialWorkPercentage <= 49" style="white-space: normal">
            {{ installer.firstName }} has indicated a focus on residential work with just {{ commercialWorkPercentage }}
            % spent on commercial work over the past three years.
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <gc-phone-number-list [phoneNumbers]="installer.phoneNumbers"></gc-phone-number-list>
  </ng-container>
</ng-template>

<ng-container *ngIf="isLoading; else view">
  <ion-list lines="none">
    <ion-list-header>
      <ion-label color="medium">BIO</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
  </ion-list>

  <ion-list lines="none">
    <ion-list-header>
      <ion-label color="medium">WORK ADDRESS</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-label class="ion-text-wrap">
        <ion-skeleton-text></ion-skeleton-text>
        <br />
        <ion-skeleton-text></ion-skeleton-text>
        ,
        <ion-skeleton-text></ion-skeleton-text>
        <br />
        <ion-skeleton-text></ion-skeleton-text>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-list lines="none">
    <ion-list-header>
      <ion-label color="medium">INSURANCE EXPIRATION</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
  </ion-list>

  <gc-phone-number-list [isLoading]="isLoading"></gc-phone-number-list>
</ng-container>
