import { buildState } from '@briebug/ngrx-auto-entity';
import { DiscountCode } from './discount-code.model';

export const {
  initialState: initialDiscountCodesState,
  facade: DiscountCodesFacadeBase,
  actions: { loadAll: loadAllDiscountCodes },
} = buildState(DiscountCode);

export function discountCodesReducer(state = initialDiscountCodesState) {
  return state;
}

export const DISCOUNT_CODES_FEATURE_KEY = 'discountCode';
