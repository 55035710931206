import { buildState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer, createSelector } from '@ngrx/store';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { isInstallerLead, User } from '../../users';
import {
  INSTALLER_INVOICE_STATUS_GROUP_ORDER_MAP,
  INSTALLER_INVOICE_STATUS_GROUP_STATUS_MAP,
  INVOICE_STATUS_GROUP_LABEL_MAP,
  INVOICE_STATUS_GROUP_ORDER_MAP,
  INVOICE_STATUS_GROUP_STATUS_MAP,
} from '../invoice-and-payment.maps';
import { InvoiceStatusGroup } from '../models/invoice-status.group';
import { InvoiceCount } from './invoice-count.model';

export const {
  initialState: initialInvoiceCountState,
  facade: InvoiceCountFacadeBase,
  actions: { loadAll: loadAllInvoiceCounts },
  selectors: { selectEntities: workOrderCountEntities },
} = buildState(InvoiceCount);

const reduce = createReducer(initialInvoiceCountState);

export function invoiceCountReducer(state = initialInvoiceCountState, action: Action) {
  return reduce(state, action);
}

export const createStatusGroupCounts = createSelector(
  workOrderCountEntities,
  authenticatedUser,
  (entities, user?: User): InvoiceStatusGroup[] =>
    Object.keys(
      !!user && isInstallerLead(user) ? INSTALLER_INVOICE_STATUS_GROUP_ORDER_MAP : INVOICE_STATUS_GROUP_ORDER_MAP,
    )
      .filter(key => key !== 'undefined')
      .map(key => ({
        status: key,
        group: key,
        label: INVOICE_STATUS_GROUP_LABEL_MAP[key],
        order: INVOICE_STATUS_GROUP_ORDER_MAP[status],
        ...(!!user && isInstallerLead(user)
          ? INSTALLER_INVOICE_STATUS_GROUP_STATUS_MAP
          : INVOICE_STATUS_GROUP_STATUS_MAP)[key]
          .map(status => entities[status])
          .reduce(
            (total, cur: InvoiceCount) => ({
              count: total.count + (+cur?.count || 0),
              totalAmount: total.totalAmount + (+cur?.totalAmount || 0),
            }),
            { count: 0, totalAmount: 0 },
          ),
      })),
);

export const sortStatusGroups = (statusGroups: InvoiceStatusGroup[]) => statusGroups.sort((a, b) => a.order - b.order);

export const sortedStatusGroupCounts = createSelector(createStatusGroupCounts, sortStatusGroups);
