import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserType } from '../../domains/users';
import { CompanyInviteModal } from '../../modals/invites/company-invite/company-invite.modal';

@Injectable()
export class InvitesUIService {
  private modalInstance?: HTMLIonModalElement;

  constructor(private readonly modals: ModalController) {}

  showCompanyInviteModal(type: UserType, typeDisplay: string): Observable<HTMLIonModalElement> {
    if (this.modalInstance) {
      throw new Error('Company Invite modal is already displayed!');
    }

    return from(
      this.modals.create({
        component: CompanyInviteModal,
        componentProps: { type, typeDisplay },
      }),
    ).pipe(
      switchMap(modal =>
        from(modal.present()).pipe(
          tap(() => (this.modalInstance = modal)),
          map(() => modal),
        ),
      ),
    );
  }

  dismissCompanyInviteModal(): Observable<any> {
    return this.modalInstance
      ? from(this.modalInstance.dismiss()).pipe(tap(() => (this.modalInstance = undefined)))
      : of();
  }
}
