<ion-grid *ngIf="invoice | isInvoiceStatus: 'NEW' : 'READ'">
  <ion-row>
    <ion-col class="ion-text-right">
      <ion-button *ngIf="!reportsProgress" (click)="declined.emit(invoice)" color="danger" mode="ios">
        <ion-icon name="ban" slot="start"></ion-icon>
        Decline
      </ion-button>
      <ion-button (click)="approved.emit(invoice)" color="gc-green" mode="ios">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        Approve
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
