import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[gcFileDropTarget]',
})
export class FileDropTargetDirective {
  @HostBinding('class.file-drag-over') fileDragOver!: boolean;

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    console.log('dragover', evt);
    evt.preventDefault();
    this.fileDragOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    console.log('dragleave', evt);
    evt.preventDefault();
    this.fileDragOver = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    console.log('drop', evt);
    evt.preventDefault();
    evt.dataTransfer && this.uploadEnabled ? this.filesDropped.emit(evt.dataTransfer.files) : void 0;
    this.fileDragOver = false;
  }

  @Input() uploadEnabled = true;

  @Output() filesDropped = new EventEmitter<FileList>();

  constructor() {}
}

