import { AbstractControl, ValidationErrors } from '@angular/forms';
import { addToErrors, removeFromErrors } from './util';

export const setIncrementError = (control: AbstractControl, increment: number) =>
  control.value % increment === 0
    ? (removeFromErrors(control, 'incrementAmount'), null)
    : (addToErrors(control, 'incrementAmount', increment), { incrementAmount: increment });

export const incrementAmount =
  (unitOfMeasureSymbol?: string) =>
  (target: AbstractControl | null): ValidationErrors | null =>
    target?.value
      ? unitOfMeasureSymbol === 'HR'
        ? setIncrementError(target, 0.25)
        : setIncrementError(target, 1)
      : null;
