import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { DatepickerItemComponent } from './datepicker-item.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    PipesModule,
    MatInputModule,
  ],
  declarations: [DatepickerItemComponent],
  exports: [DatepickerItemComponent],
})
export class DatepickerItemModule {}
