import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { first, map, tap } from 'rxjs/operators';
import { InvoiceAndPaymentFacade } from '../../../domains/invoices';
import { WorkOrderRelationsFacade } from '../../../domains/work-order-relations.facade';
import { WorkOrderFacade } from '../../../domains/work/work-order/work-order.facade';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';
import { PendingInvoiceForm } from '../invoice.form';

@Component({
  selector: 'gc-report-progress',
  templateUrl: './report-progress.component.html',
  styleUrls: ['./report-progress.component.scss'],
})
export class ReportProgressComponent implements OnInit, OnDestroy {
  todaysDate = new Date();
  form!: PendingInvoiceForm;

  constructor(
    public modalGC: ModalController,
    public workOrders: WorkOrderFacade,
    public workOrderRelations: WorkOrderRelationsFacade,
    public invoiceAndPayment: InvoiceAndPaymentFacade,
  ) {}

  ngOnInit() {
    this.workOrderRelations.currentInvoiceableWorkOrderItems$
      .pipe(
        first(),
        map(items => new PendingInvoiceForm({ items })),
        tap(form => (this.form = form)),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.form.clearSubscriptions();
  }

  save(workOrder: WorkOrder) {
    if (this.form.invalid) {
      return;
    }
    const items = this.form.items.itemForms.map(item => item.updatedModel).filter(item => !!item.amountToInvoice);

    const pendingInvoice = {
      workOrderId: workOrder.id!, // TODO: Can we remove the non-nullish assertion here?
      items,
    };
    this.invoiceAndPayment.reviewProgress(pendingInvoice);
  }

}
