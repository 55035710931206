import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { currentRouteUrl } from '../../domains/router.selectors';
import { AppState } from '../../domains/state';

@Pipe({ name: 'isActiveRoute' })
export class IsActiveRoutePipe implements PipeTransform {
  url$ = this.store.select(currentRouteUrl);

  constructor(private store: Store<AppState>) {}

  transform(route: string) {
    return this.url$.pipe(map(url => url?.includes(route) ?? false));
  }
}
