import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { DisplayGroupAllowance, DisplayOptions, WorkOrderItemGroup } from '../../../../domains/work-order-item-group.selectors';

@Component({
  selector: 'gc-basic-price-display',
  templateUrl: './basic-price-display.component.html',
  styleUrls: ['./basic-price-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicPriceDisplayComponent {
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];
  @Input() group!: WorkOrderItemGroup;
  @Input() item!: DetailedWorkOrderItem;
}
