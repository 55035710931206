<ion-content>
  <ion-grid class="flex ion-justify-content-center" style="flex-direction: column; height: 100%">
    <ion-row>
      <ion-col>
        <ion-img src="/assets/images/logo.svg" style="margin: auto"></ion-img>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-padding ion-text-center">
        <ng-container *ngIf="app.updateProgress$ | push as progress">
          <ion-item lines="none" *ngIf="!(app.updateComplete$ | push)">
            <ion-progress-bar [value]="(progress.download + progress.extraction) / 2"></ion-progress-bar>
          </ion-item>
          <ion-spinner *ngIf="app.updateComplete$ | push"></ion-spinner>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
