export const ROLES_MAP: Record<string, string> = {
  companyAdmin: 'Company Admin',
  companyManager: 'Company Project Manager',
  companyEmployee: 'Company Employee Installer',
  installerLead: 'Installer Team Lead',
  installerMember: 'Installer Team Member',
  jumpstarter: 'Jumpstarter',
};

export type RoleKeys = keyof typeof ROLES_MAP;

export const ROLE_NAMES_MAP: Record<string, RoleKeys> = {
  'Company Admin': 'companyAdmin',
  'Company Project Manager': 'companyManager',
  'Company Employee Installer': 'companyEmployee',
  'Installer Team Lead': 'installerLead',
  'Installer Team Member': 'installerMember',
  Jumpstarter: 'jumpstarter',
};
export type RoleNames = keyof typeof ROLE_NAMES_MAP;

export const PriceInfoRoles: RoleKeys[] = ['installerLead', 'companyAdmin', 'companyManager'];

export const CompanyRoles: RoleKeys[] = ['companyAdmin', 'companyManager', 'companyEmployee'];

export const InstallerRoles: RoleKeys[] = ['installerLead'];

export const isRole = (currentRole?: string, ...roles: RoleKeys[]): boolean =>
  !roles || !roles.length ? false : currentRole ? roles.some(role => ROLES_MAP[role] === currentRole) : false;

export const isRoleKey = (currentRole?: string, ...roles: RoleKeys[]): boolean =>
  !roles || !roles.length ? false : currentRole ? roles.some(role => role === currentRole) : false;
