export interface HasStartEndDates {
  startDateValue?: number;
  endDateValue?: number;
}

export const toStartDurationValue = <T extends HasStartEndDates>(item: T): number =>
  (item.startDateValue ?? Date.now()) + ((item.endDateValue ?? Date.now()) - (item.startDateValue ?? Date.now()));

export enum Direction {
  Ascending,
  Descending,
}

export const sortByStartDuration =
  (direction: Direction = Direction.Ascending) =>
  <T extends HasStartEndDates>(items?: T[]) =>
    items?.sort((a, b) =>
      direction === Direction.Ascending
        ? toStartDurationValue(a) - toStartDurationValue(b)
        : toStartDurationValue(b) - toStartDurationValue(a),
    ) ?? [];

export interface HasFirstLast {
  firstName?: string;
  lastName?: string;
}

export const toLastFirstValue = <T extends HasFirstLast>(item: T): string =>
  `${item.lastName ?? ''} ${item.firstName ?? ''}`.trim();
export const sortByLastFirst =
  (direction: Direction = Direction.Ascending) =>
  <T extends HasFirstLast>(items?: T[]) =>
    items?.sort((a, b) =>
      direction === Direction.Ascending
        ? toLastFirstValue(a).localeCompare(toLastFirstValue(b), undefined, { numeric: true })
        : toLastFirstValue(b).localeCompare(toLastFirstValue(a), undefined, { numeric: true }),
    ) ?? [];
