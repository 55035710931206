import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerRequestAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { authenticatedUserMetadataRetrieved } from '~gc/domains/app/auth/auth.actions';
import { loadDwollaAccount } from '~gc/shared/dwolla/feature/dwolla-account/dwolla.actions';
import { ToastService } from '~gc/shared/services/toast.service';
import {
  deletePlaidAccountDetailsSuccess,
  loadPlaidAccountDetails,
  loadPlaidAccountDetailsFailure,
  loadPlaidAccountDetailsSuccess,
  openPlaid,
  openPlaidError,
  openPlaidSuccess,
  plaidExit,
  plaidLoad,
  plaidMicroDepositVerificationFailure,
  plaidSuccess,
  plaidTokenExchangeFailure,
  plaidTokenExchangeSuccess,
} from './plaid.actions';
import { PlaidService, PlaidTokenExchange } from './plaid.service';

@Injectable()
export class PlaidEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly plaid: PlaidService,
    private readonly store: Store,
    private readonly loading: LoadingController,
    private readonly toasts: ToastService,
  ) {}

  // loadPlaidDetails$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loadDwollaCustomerDetailsSuccess),
  //     withLatestFrom(this.store.select(shouldShowCustomerCreate)),
  //     filter(([, shouldShow]) => !shouldShow),
  //     map(() => loadPlaidAccountDetails())
  //   )
  // );

  loadPlaidDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticatedUserMetadataRetrieved),
      first(),
      map(() => loadPlaidAccountDetails()),
    ),
  );

  handleLoadPlaidDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPlaidAccountDetails),
      concatMap(() =>
        this.plaid.getAccountDetails().pipe(
          map(accountDetails => loadPlaidAccountDetailsSuccess({ accountDetails })),
          catchError(error => of(loadPlaidAccountDetailsFailure({ error }))),
        ),
      ),
    ),
  );

  openPlaidOnRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerRequestAction),
      filter(({ payload }) => payload.event.url.includes('oauth_state_id=')),
      tap(() => console.log('[Effects] [Dwolla] (Plaid) Plaid RedirectURI Found... Opening Plaid to finish session.')),
      map(({ payload }) => openPlaid({ redirectUri: payload.event.url })),
    ),
  );

  openPlaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openPlaid),
      switchMap(({ redirectUri }) =>
        this.loading
          .create({ message: 'Connecting to Plaid' })
          .then(loading => loading.present())
          .then(() => redirectUri),
      ),
      switchMap(redirectUri =>
        this.plaid.createPlaidLink(redirectUri).pipe(
          tap(link => link.open()),
          map(() => openPlaidSuccess()),
          catchError(error => of(openPlaidError({ error }))),
        ),
      ),
    ),
  );

  watchForMicroDepositFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(plaidExit),
      filter(
        ({ error }) =>
          error?.error_code === 'TOO_MANY_VERIFICATION_ATTEMPTS' ||
          (error?.error_code === 'INVALID_FIELD' &&
            error?.error_message === 'this account is not eligible for verification'),
      ),
      map(() => plaidMicroDepositVerificationFailure()),
    ),
  );

  handleMicroDepositVerificationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(plaidMicroDepositVerificationFailure),
      switchMap(() =>
        this.toasts.toastResult(
          'Your Plaid account has been reset. You may try linking it to your bank again.',
          'Micro-deposit Verification Failed',
          { duration: 0 },
        ),
      ),
      switchMap(() => this.plaid.deleteAccountDetails()),
      map(() => deletePlaidAccountDetailsSuccess()),
    ),
  );

  handlePlaidOpenError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openPlaidError),
        switchMap(() => this.loading.dismiss()),
        switchMap(() =>
          this.plaid.toastPlaidError('Error connecting to Plaid. Make sure Dwolla customer account is created first.'),
        ),
      ),
    { dispatch: false },
  );

  handlePlaidLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(plaidLoad),
        switchMap(() => this.loading.dismiss()),
      ),
    { dispatch: false },
  );

  handlePlaidSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(plaidSuccess),
      map(
        ({ publicToken, metadata }): PlaidTokenExchange => ({
          publicToken,
          accountId: metadata.accounts[0].id,
          accountName: metadata.accounts[0].name,
          status: metadata.accounts[0].verification_status,
        }),
      ),
      switchMap(linkExchange =>
        this.plaid.exchangeLinkToken(linkExchange).pipe(
          map(() => plaidTokenExchangeSuccess({ status: linkExchange.status })),
          catchError(error => of(plaidTokenExchangeFailure({ error }))),
        ),
      ),
    ),
  );

  reloadAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(plaidTokenExchangeSuccess),
      switchMap(() => [loadDwollaAccount(), loadPlaidAccountDetails()]),
    ),
  );

  toastSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(plaidTokenExchangeSuccess),
        filter(
          ({ status }) => status === null || status === 'manually_verified' || status === 'automatically_verified',
        ),
        switchMap(() =>
          this.toasts.toastResult(
            'Your payment account has been created successfully.',
            'Account Created Successfully',
            { duration: 10000 },
          ),
        ),
      ),
    { dispatch: false },
  );

  handleExchangeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(plaidTokenExchangeFailure),
        switchMap(() => this.plaid.toastPlaidError('Error exchanging token for Plaid account.')),
      ),
    { dispatch: false },
  );
}
