import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Invoice, InvoiceStatus } from '../../../domains/invoices';
import { DetailedInvoice } from '../../../domains/models/detailed-invoice';
import { Installer } from '../../../domains/users';
import { selectedInvoicingInstallerProfile } from '../../../domains/users/users-ui.actions';

@Component({
  selector: 'gc-invoice-table',
  templateUrl: './invoice-table.component.html',
  styleUrls: ['./invoice-table.component.scss'],
})
export class InvoiceTableComponent {
  PaidPendingLienRelease = InvoiceStatus.PaidPendingLienRelease;

  @Input() invoices!: DetailedInvoice[];
  @Input() showDetails = false;
  @Input() isInstaller = true;
  @Input() loading!: boolean;
  @Input() invoiceType: string = 'Invoice';
  @Input() reportsProgress = false;

  @Output() added = new EventEmitter();
  @Output() reload = new EventEmitter<Invoice>();
  @Output() options = new EventEmitter<{ event: Event; invoice: Invoice }>();
  @Output() listEndReached = new EventEmitter<InfiniteScrollCustomEvent>();

  trackBy(index: number, invoice: DetailedInvoice) {
    return invoice.id;
  }

  showInvoicingInstallerProfile(installer: Installer) {
    this.store.dispatch(selectedInvoicingInstallerProfile({ installer: installer as Installer }));
  }

  constructor(public readonly store: Store) {}
}
