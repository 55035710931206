import { createSelector } from '@ngrx/store';
import { get, isNotEmpty, joinArrays } from '~gc/shared/utils/func';
import { featureState } from './feature.name';

export const filterState = createSelector(featureState, get('filters'));

export const showPending = createSelector(filterState, get('showPending'));

export const showUnapprovedConnections = createSelector(filterState, get('showUnapprovedConnections'));

export const includedPrivateConnections = createSelector(filterState, get('privateConnections'));
export const includedPublicConnections = createSelector(filterState, get('publicConnections'));
export const includedNonConnectedInstallers = createSelector(filterState, get('nonConnectedInstallers'));
export const includedEmployeeInstallers = createSelector(filterState, get('employeeInstallers'));

export const includedInstallers = createSelector(
  includedPrivateConnections,
  includedPublicConnections,
  includedNonConnectedInstallers,
  includedEmployeeInstallers,
  joinArrays,
);

export const includedManagers = createSelector(filterState, get('managers'));

export const allIncludedUsers = createSelector(includedInstallers, includedManagers, joinArrays);

export const hasActiveFilters = createSelector(allIncludedUsers, isNotEmpty);
