import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'GenericFile',
  uriName: 'files',
})
export class GenericFile {
  @Key id?: number;
  filename?: string;
  contentType?: string;
  productInstallationTypeClaimedId?: number;
  workOrderId?: string;
  userId?: string;
  url?: string;
  uploadUrl?: string;
  type?: FileType;
  expirationDate?: string;
}

export enum FileType {
  Experience = 'EXPERIENCE',
  W9 = 'W9',
  Insurance = 'INSURANCE',
  WorkOrderAttachment = 'WORK_ORDER_ATTACHMENT',
  InvoiceAttachment = 'INVOICE_ATTACHMENT',
  LienRelease = 'LIEN_RELEASE',
}
