import { createSelector } from '@ngrx/store';
import { first } from '~gc/shared/utils/func';
import { hasPermissions } from '../shared/pipes/has-permissions.pipe';
import { compose } from '../shared/utils/compose.util';
import { isRoleKey, RoleKeys } from './app/auth/auth.maps';
import { authenticatedRoleKey, tokenPermissions } from './app/auth/auth.selectors';
import { DetailedWorkOrderItem } from './models/detailed-work-order-item';
import { NegotiableWorkOrderItem } from './models/negotiable-work-order-item';
import { Connection } from './users/connection/connection.model';
import { byNameAscending, pickOrDefault } from './utils';
import { currentDetailedWorkOrderItems } from './work-order-items-detail.selectors';
import { WorkOrderItem } from './work/work-order-item/work-order-item.model';
import { WorkOrder } from './work/work-order/work-order.model';
import { currentWorkOrder } from './work/work-order/work-order.state';
import { isStatus, STATUS_GROUP_MAP, STATUS_GROUP_STATUS_MAP } from './work/work.maps';

export type WorkOrderItemGroupType = 'original' | 'negotiable' | 'change-add' | 'installer-add';

export type WorkOrderItemKinds = WorkOrderItem | DetailedWorkOrderItem | NegotiableWorkOrderItem;

export interface WorkOrderItemGroup {
  items: WorkOrderItemKinds[];
  type: WorkOrderItemGroupType;
  title?: string;
  baseColor: string;
}

export const TYPE_TO_GROUP_CODE: Record<
  string | 'original' | 'negotiable' | 'change-add' | 'installer-add' | DisplayGroup,
  DisplayGroup
> = {
  original: 'O',
  negotiable: 'O',
  'change-add': 'C',
  'installer-add': 'I',
  O: 'O',
  C: 'C',
  I: 'I',
};

export const normalizeGroup = (group: string | DisplayGroup): DisplayGroup => TYPE_TO_GROUP_CODE[group];

export const ACTION_TO_OPTION_CODE: Record<
  | string
  | 'seebasic'
  | 'viewonly'
  | 'change'
  | 'approve'
  | 'invoice'
  | 'pay'
  | 'negotiate'
  | 'deductible'
  | 'decline'
  | DisplayOption,
  DisplayOption
> = {
  seebasic: 'b',
  viewonly: 'v',
  change: 'c',
  approve: 'a',
  invoice: 'i',
  pay: 'p',
  negotiate: 'n',
  deductible: 'd',
  decline: 'e',
  b: 'b',
  c: 'c',
  a: 'a',
  i: 'i',
  p: 'p',
  n: 'n',
  v: 'v',
  d: 'd',
  e: 'e',
};

export const normalizeAction = (action: string | DisplayOption): DisplayOption => ACTION_TO_OPTION_CODE[action];

export const groupAllAsNegotiable = (items?: DetailedWorkOrderItem[]): WorkOrderItemGroup[] => [
  { type: 'negotiable', items: items?.sort(byNameAscending) ?? [], baseColor: 'gc-green' },
];

export const groupByAllTypes = (items?: DetailedWorkOrderItem[]): WorkOrderItemGroup[] => [
  {
    type: 'original',
    items: (items?.filter(item => !item.isChangeOrder) ?? []).sort(byNameAscending),
    baseColor: 'gc-green',
  },
  {
    type: 'change-add',
    title: 'Change Order Items',
    items: (items?.filter(item => item.isChangeOrder && !item.createdByInstaller) ?? []).sort(byNameAscending),
    baseColor: 'gc-blue',
  },
  {
    type: 'installer-add',
    title: 'Installer Change Order Items',
    items: (items?.filter(item => item.isChangeOrder && item.createdByInstaller) ?? []).sort(byNameAscending),
    baseColor: 'gc-light-blue',
  },
];

export const isStatusGroup = (status?: string, ...groups: (keyof typeof STATUS_GROUP_STATUS_MAP)[]) =>
  status ? groups.some(group => STATUS_GROUP_STATUS_MAP[group].includes(status)) : false;

// export const chooseAndGroupWorkOrderItems = (
//   role: string,
//   workOrder: WorkOrder,
//   negotiableItems: NegotiableWorkOrderItem[],
//   changeableItems: ChangeableWorkOrderItem[]
// ): WorkOrderItemGroup[] =>
//   isStatusGroup(workOrder?.status, 'draft', 'pending')
//     ? groupAllAsNegotiable(negotiableItems)
//     : groupByAllTypes(changeableItems);
//
// export const workOrderItemGroups = createSelector(
//   authenticatedRole,
//   currentWorkOrder,
//   negotiableWorkOrderItems,
//   changeableWorkOrderItems,
//   chooseAndGroupWorkOrderItems
// );

export const chooseAndGroupWorkOrderItems = (
  items?: DetailedWorkOrderItem[],
  workOrder?: WorkOrder,
): WorkOrderItemGroup[] =>
  isStatusGroup(workOrder?.status, 'draft', 'pending') ? groupAllAsNegotiable(items) : groupByAllTypes(items);
// ==========================================================================================================
// TODO: I don't see that this usage of permissions fits with what we are doing now...let's remove these now.
// ==========================================================================================================
export const permissableWorkOrderItems = createSelector(
  tokenPermissions,
  currentDetailedWorkOrderItems,
  (permissions: string[], items) =>
    permissions.includes('read:unapproved_change_order_item')
      ? items
      : items?.filter(item => !item.isChangeOrder || item.acceptedAt),
);

export const permissableWorkOrderItemsChangeOrders = createSelector(
  tokenPermissions,
  permissableWorkOrderItems,
  (permissions: string[], items) =>
    permissions.includes('read:unapproved_change_order')
      ? items
      : items?.map(item => ({ ...item, changeOrders: item?.changeOrders?.filter(change => change.acceptedAt) })),
);

export const currentWorkOrderItemGroups = createSelector(
  permissableWorkOrderItemsChangeOrders,
  currentWorkOrder,
  chooseAndGroupWorkOrderItems,
);

// Display Mode Options:
// - Groups:
//   O = original
//   C = change-add
//   I = installer-add
// - Options
//   - = none
//   b = basic
//   c = changeable
//   v = view changes
//   a = approvable
//   i = invoiceable
//   p = payable
//   n = negotiable
//   d = deductible
//   e = decline
//
// PERMUTATIONS:
//
// Role => companyAdmin, companyManager
// Status => DRAFT, PENDING_AWAITING_RESPONSES
// Mode Options => ObC-I-
// Meaning: Render original items with basic mode
//
// Role => companyEmployee, installerMember
// Status => DRAFT
// Mode Options => O-C-I-
// Meaning: Cannot view any details!
//
// Role =>  installerMember
// Status => PENDING_AWAITING_RESPONSES, PENDING_WITH_RESPONSES
// Mode Options => O-C-I-
// Meaning: Cannot view any details!
//
// Role => installerLead
// Status => PENDING_AWAITING_RESPONSES, PENDING_WITH_RESPONSES
// Mode Options => OnC-I-
// Meaning: Show negotiable items, change order add and installer added don't apply yet
//
// Role => companyEmployee
// Status => PENDING_AWAITING_RESPONSES, PENDING_WITH_RESPONSES
// Mode Options => ObC-I-
// Meaning: Show basic items, do not allow negotiation, change order add and installer added don't apply yet
//
// Role => companyAdmin, companyManager
// Status => PENDING_WITH_RESPONSES
// Mode Options => OnC-I-
// Meaning: Show negotiable items, change order add and installer added don't apply yet
//
// Role => companyAdmin, companyManager
// Status => AWARDED_NOT_STARTED
// Mode Options => OcdCcdIad
// Meaning: Show changeable items for original and change-add, show approvable items for installer-add. Can deduct all
//
// Role => companyAdmin, companyManager
// Status => IN_PROGRESS_NO_INVOICES, IN_PROGRESS_HAS_INVOICES
// Mode Options => OcdpCcdpIadp
// Meaning: Show changeable items for original and change-add, show approvable items for installer-add, pay all, deduct all
//
// Role => installerLead,
// Status => AWARDED_NOT_STARTED
// Mode Options => OaCaIc
// Meaning: Show approvable items for original and change-add, show changeable items for installer-add
//
// Role => companyEmployee
// Status => AWARDED_NOT_STARTED
// Mode Options => OvCvIc
// Meaning: Show viewable change order details for original and change-add, chow changeable items for installer-add
//
// Role => installerLead
// Status => IN_PROGRESS_NO_INVOICES, IN_PROGRESS_HAS_INVOICES
// Mode Options => OaiCaiIci
// Meaning: Show approvable for original, change-add, changeable for installer-add, allow invoicing for all
//
// Role => companyEmployee
// Status => IN_PROGRESS_NO_INVOICES, IN_PROGRESS_HAS_INVOICES
// Mode Options => OviCviIci
// Meaning: Show viewable for original, change-add, changeable for installer-add, allow invoicing for all
//
// Role => installerMember
// Status => IN_PROGRESS_NO_INVOICES, IN_PROGRESS_HAS_INVOICES
// Mode Options => OviCviIci
// Meaning: Show viewable for original, change-add, changeable for installer-add, allow invoicing for all
//
// Role => companyAdmin, companyManager, companyEmployee, installerLead, installerMember
// Status => DONE, CANCELLED
// Mode Options => OvCvIv
// Meaning: Show viewable for all, do not allow further change orders of any kind (company or installer), do not allow further invoicing
//

export type Statuses = keyof typeof STATUS_GROUP_MAP;
export type RoleStatusToOptionsMapping = [RoleKeys[], Statuses[], string];

// TODO: verify we aren't really using this anymore and remove if not needed.
export const DISPLAY_OPTIONS_MATRIX: RoleStatusToOptionsMapping[] = [
  [['companyAdmin', 'companyManager'], ['DRAFT', 'PUBLISHED_AWAITING_RESPONSE'], 'OvC-I-'],
  [['companyEmployee', 'installerMember'], ['DRAFT'], 'O-C-I-'],
  [['installerMember'], ['PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES'], 'O-C-I-'],
  [['installerLead'], ['PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES'], 'OnC-I-'],
  [['companyEmployee'], ['PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES'], 'ObC-I-'],
  [['companyAdmin', 'companyManager'], ['PUBLISHED_WITH_RESPONSES'], 'OnC-I-'],
  [['companyAdmin', 'companyManager'], ['AWARDED_NOT_STARTED'], 'OcdeCcdeIade'],
  [['companyAdmin', 'companyManager'], ['IN_PROGRESS_NO_INVOICES', 'IN_PROGRESS_HAS_INVOICES'], 'OcdepCcdepIadep'],
  [['installerLead'], ['AWARDED_NOT_STARTED'], 'OaeCaeIce'],
  [['companyEmployee'], ['AWARDED_NOT_STARTED'], 'OveCveIce'],
  [['installerLead'], ['IN_PROGRESS_NO_INVOICES', 'IN_PROGRESS_HAS_INVOICES'], 'OaeiCaeiIcei'],
  [['companyEmployee'], ['IN_PROGRESS_NO_INVOICES', 'IN_PROGRESS_HAS_INVOICES'], 'OeviCeviIcei'],
  [['installerMember'], ['IN_PROGRESS_NO_INVOICES', 'IN_PROGRESS_HAS_INVOICES'], 'OeviCeviIbei'],
  [
    ['companyAdmin', 'companyManager', 'companyEmployee', 'installerLead', 'installerMember'],
    ['DONE', 'CANCELLED', 'DONE_AWAITING_SETTLEMENT'],
    'OvCvIv',
  ],
];

export const DISPLAY_GROUPS_MATRIX: RoleStatusToOptionsMapping[] = [
  [['companyAdmin', 'companyManager'], ['DRAFT', 'PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES'], 'O+C-I-'],
  [
    ['installerMember', 'companyEmployee'],
    ['DRAFT', 'PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES'],
    'O-C-I-',
  ],
  [['installerLead'], ['PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES'], 'O+C-I-'],
  [
    ['companyAdmin', 'companyManager', 'installerLead', 'companyEmployee', 'installerMember'],
    ['AWARDED_NOT_STARTED', 'IN_PROGRESS_NO_INVOICES', 'IN_PROGRESS_HAS_INVOICES'],
    'O+C+I+',
  ],
  // [
  //   ['installerLead', 'companyEmployee', 'installerMember'],
  //   ['AWARDED_NOT_STARTED', 'IN_PROGRESS_NO_INVOICES', 'IN_PROGRESS_HAS_INVOICES'],
  //   'O+C+I+',
  // ],
  [['companyEmployee', 'installerMember'], ['DONE', 'CANCELLED', 'DONE_AWAITING_SETTLEMENT'], 'O-C-I-'],
  [['companyAdmin', 'companyManager', 'installerLead'], ['DONE', 'CANCELLED', 'DONE_AWAITING_SETTLEMENT'], 'O+C-I+'],
];

export type DisplayGroup = 'O' | 'C' | 'I';
export type DisplayOption = '-' | '+' | 'b' | 'c' | 'a' | 'i' | 'p' | 'n' | 'v' | 'd' | 'e';

export type DisplayOptions = {
  [group in DisplayGroup]?: {
    [option in DisplayOption]?: boolean;
  };
};

export type DisplayGroupAllowance = {
  [group in DisplayGroup]?: boolean;
};

export const isUpperCase = (char: string | undefined | null) => char === (char?.toUpperCase() ?? '');

export const grantOption = (
  opts: NonNullable<DisplayOptions>,
  group: NonNullable<DisplayGroup>,
  option: NonNullable<DisplayOption>,
) => ({
  ...opts,
  [group]: { ...opts[group], [option]: true },
});

export const nilGroup = () => ({
  '-': false,
  b: false,
  c: false,
  a: false,
  i: false,
  p: false,
  n: false,
  v: false,
  d: false,
  e: false,
});

export const denyGroup = (opts: NonNullable<DisplayOptions>, group: NonNullable<DisplayGroup>) => ({
  ...opts,
  [group]: { ...nilGroup(), '-': true },
});

export const wasDenied = (opts: NonNullable<DisplayOptions>, group: NonNullable<DisplayGroup>) => opts?.[group]?.['-'];

export const groupIsAllowed = (allowances: NonNullable<DisplayGroupAllowance>, group: NonNullable<DisplayGroup>) =>
  allowances?.[group];

export const ensureGroup = (opts: NonNullable<DisplayOptions>, group: NonNullable<DisplayGroup>): DisplayOptions => ({
  ...opts,
  [group]: opts[group] || nilGroup(),
});

export const parseDisplayChar = (
  [opts, group]: [DisplayOptions, DisplayGroup | undefined],
  char: string,
): [DisplayOptions, DisplayGroup] =>
  isUpperCase(char) && char !== '-'
    ? ((group = char as DisplayGroup), [ensureGroup(opts, group), group!])
    : char === '-' || wasDenied(opts, group!)
    ? [denyGroup(opts, group!), group!]
    : [grantOption(opts, group!, char as DisplayOption), group!];

export const parseDisplayOptions = () => (options?: string) =>
  first(
    (options || '').split('').reduce(parseDisplayChar, [{}, undefined] as [DisplayOptions, DisplayGroup | undefined]),
  ) ?? {};

export const findMapping =
  (matrix: RoleStatusToOptionsMapping[]) =>
  ([role, workOrder]: [string | RoleKeys, WorkOrder | undefined]): RoleStatusToOptionsMapping | undefined =>
    matrix.find(([roles, statuses]) => isRoleKey(role, ...roles) && isStatus(workOrder?.status, ...statuses));

export const findDisplayOptionString = (matrix: RoleStatusToOptionsMapping[]) =>
  compose(findMapping(matrix), pickOrDefault(2, 'O-C-I-'));

export const buildDisplayOptions = (matrix: RoleStatusToOptionsMapping[]) =>
  compose(findDisplayOptionString(matrix), parseDisplayOptions());

export const workOrderItemDisplayMode = createSelector(
  authenticatedRoleKey,
  currentWorkOrder,
  (role: string | RoleKeys, workOrder?: WorkOrder) => buildDisplayOptions(DISPLAY_OPTIONS_MATRIX)([role, workOrder]),
);

const DISALLOWED_GROUPS = { O: false, C: false, I: false };

export const parseDisplayGroups =
  () =>
  (groups?: string): DisplayGroupAllowance =>
    (first(
      (groups || '').split('').reduce(
        ([allowance, group]: [DisplayGroupAllowance, DisplayGroup | undefined], char: string) =>
          ['O', 'C', 'I'].includes(char)
            ? ([allowance, char] as [DisplayGroupAllowance, DisplayGroup | undefined])
            : ([
                {
                  ...allowance,
                  [group! as DisplayGroup]: char === '+',
                },
                undefined,
              ] as [DisplayGroupAllowance, DisplayGroup | undefined]),
        [DISALLOWED_GROUPS, undefined] as [DisplayGroupAllowance, DisplayGroup | undefined],
      ),
    ) as DisplayGroupAllowance) ?? DISALLOWED_GROUPS;

export const buildDisplayGroupAllowances = (matrix: RoleStatusToOptionsMapping[]) =>
  compose(findDisplayOptionString(matrix), parseDisplayGroups());

export const workOrderItemDisplayGroupAllowance = createSelector(
  authenticatedRoleKey,
  currentWorkOrder,
  (role: string | RoleKeys, workOrder?: WorkOrder): DisplayGroupAllowance =>
    buildDisplayGroupAllowances(DISPLAY_GROUPS_MATRIX)([role, workOrder]),
);

// TODO: once this selector is finished, we can update the add-deduction-button.component to use it
// export const displayDeductionButtons = createSelector(
//   tokenPermissions,
//   (permissions: string[]): boolean =>
//     hasPermissions(permissions, 'work-order.change-orders:view', 'work-order.change-order:create')
// );

// Below is the old template logic—excluding the permissions check since I already added that in the WIP selector above.
// (item | numberOfUnitsStillPending) > 0 &&-->
// (!item.createdByInstaller || item.acceptedById) && -->
// (((group | typeIsIn: 'original') && (displayGroups | itemGroupsAllowed: 'original')) ||-->
// ((group | typeIsIn: 'change-add') && (displayGroups | itemGroupsAllowed: 'change-add')) ||-->
// ((group | typeIsIn: 'installer-add') && (displayGroups | itemGroupsAllowed: 'installer-add')))-->
