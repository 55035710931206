import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatchedInstaller } from '~gc/domains/work/matched-installer/matched-installer.model';
import { DetailedWorkOrder } from '~gc/domains/work/models/detailed-work-order';
import { WorkOrder, WorkOrderStatus } from '~gc/domains/work/work-order/work-order.model';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';

@Component({
  selector: 'gc-work-order-detail-header',
  templateUrl: './work-order-detail-header.component.html',
  styleUrls: ['./work-order-detail-header.component.scss'],
})
export class WorkOrderDetailHeaderComponent {
  @Input() workOrder?: DetailedWorkOrder;
  @Input() isModal = false;
  @Input() matchedInstaller!: MatchedInstaller;
  @Input() biddingMatchedInstallers!: MatchedInstaller[];
  @Input() schedulerOpen = true;
  @Input() isChatOpen = false;

  @Output() back = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() menu = new EventEmitter();
  @Output() chat = new EventEmitter<WorkOrder>();
  @Output() schedulerToggled = new EventEmitter();


  WorkOrderStatus = WorkOrderStatus;

  constructor(public auth: AuthFacade) {}
}
