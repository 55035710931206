import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ViewInstallerProfileEnhancedComponent } from '../../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.component';

@Injectable({
  providedIn: 'root',
})
export class InstallerProfileUiService {
  private currentInstallerProfileModal?: HTMLIonModalElement;

  constructor(private readonly modals: ModalController) {}

  async showInstallerProfileModal(): Promise<void> {
    if (this.currentInstallerProfileModal) {
      return Promise.resolve();
    }

    this.currentInstallerProfileModal = await this.modals.create({
      component: ViewInstallerProfileEnhancedComponent,
      backdropDismiss: true,
      cssClass: 'fullscreen-modal rounded-modal',
    });

    this.currentInstallerProfileModal.onDidDismiss().then(() => {
      this.currentInstallerProfileModal = undefined;
    });

    await this.currentInstallerProfileModal.present();
  }

  async closeInstallerProfileModal() {
    if (this.currentInstallerProfileModal) {
      await this.currentInstallerProfileModal.dismiss();
    }
  }
}
