import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, from, merge, Observable, of, pipe } from 'rxjs';
import { catchError, debounceTime, exhaustMap, filter, map, switchMap, takeUntil, tap, withLatestFrom, } from 'rxjs/operators';
import { deepObjectMerge } from '../../../shared/utils/deep-object-merge';
import { filterCorrelationProcess } from '../../../shared/utils/operators';
import { ensureChildrenRequired, ensureExists, ensureRequired } from '../../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { Country } from '../../locations';
import { loadAllFacilityTypesIfNecessary } from '../../locations/facility-type/facility-type.state';
import { loadAllStatesIfNecessary } from '../../locations/state/state.state';
import { AppState } from '../../state';
import { loadAllProductInstallationTypesIfNecessary } from '../../training/product-installation-types/product-installation-types.state';
import { isAdmin, User } from '../../users';
import { allEmployeeInstallers, nonDeletedProjectManagers } from '../../users/user/user.selectors';
import { PendingFullWorkOrder } from '../models/pending-full-work-order';
import { loadAllWorkOrderFiles } from '../work-order-files/work-order-files.state';
import { loadAllWorkOrderItems, loadAllWorkOrderItemsSuccess } from '../work-order-item/work-order-item.state';
import { loadAllWorkOrderTagsSuccess } from '../work-order-tag/work-order-tag.state';
import { WorkOrder, WorkOrderStatus } from '../work-order/work-order.model';
import { unsavedEditedWorkOrders } from '../work-order/work-order.selectors';
import {
  changedWorkOrder,
  changeWorkOrder,
  createWorkOrder,
  createWorkOrderSuccess,
  currentWorkOrder,
  deleteWorkOrderByKey,
  editedWorkOrder,
  editWorkOrder,
  endWorkOrderEdit,
  loadWorkOrder,
  loadWorkOrderSuccess,
  replaceWorkOrder,
  replaceWorkOrderFailure,
  replaceWorkOrderSuccess,
  updateWorkOrder,
} from '../work-order/work-order.state';
import { WorkUIService } from '../work-ui.service';
import { currentWorkOrderEditingStep } from '../work.selectors';
import { WorkService } from '../work.service';
import {
  addNewWorkOrder,
  cancelEditWorkOrder,
  cancelWorkOrder,
  confirmCancelWorkOrder,
  continueToNextEditStep,
  deleteDraftWorkOrder,
  editCurrentWorkOrder,
  editLoadedWorkOrder,
  editWorkOrderInitiated,
  goToEditStep,
  pendingWorkOrderSaved,
  publishWorkOrder,
  reloadDataOnWorkOrderSaved,
  saveAndExitEditingWorkOrder,
  saveEditedWorkOrder,
  saveInProgressWorkOrder,
  saveInProgressWorkOrderFailure,
  saveInProgressWorkOrderSuccess,
  showSelectEmployee,
  showSelectProjectManager,
  updateEditedWorkOrder,
  updateEditedWorkOrderWithLatest,
  workOrderInvalid,
} from './work-order-edit.actions';


export const ensureFullWorkOrder = () =>
  pipe(
    map((workOrder: PendingFullWorkOrder) =>
      workOrder
        ? {
          ...workOrder,
          address: workOrder.address, // TODO: Do we NEED `null` here???????
          files: workOrder.files ?? [],
          tags: workOrder.tags ?? [],
          workOrderItems: workOrder.workOrderItems ?? [],
          pendingFiles: workOrder.pendingFiles ?? [],
        }
        : workOrder,
    ),
  );

export const renderBasicWorkOrder = (
  workOrder: Partial<WorkOrder | PendingFullWorkOrder>,
): Partial<PendingFullWorkOrder> => (
  {
    ...workOrder,
    files: undefined,
    pendingFiles: undefined,
    tags: undefined,
    workOrderItems: undefined,
    dates: undefined,
  }
);

export const WORK_ORDER_SAVE_CORRELATION = 'WorkOrderSave';

@Injectable()
export class WorkOrderEditEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private workUI: WorkUIService,
    private work: WorkService,
    private nav: NavController,
  ) {
  }

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  addNew$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewWorkOrder),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) => user),
      ensureExists(user => !!user),
      map(user =>
        createWorkOrder({
          entity: {
            publicJobName: `New Work Order from ${ user.firstName } ${ user.lastName }`,
            status: 'DRAFT',
          } as WorkOrder,
        }),
      ),
    ),
  );

  initiateEditCurrent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editCurrentWorkOrder),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureExists(workOrder => !!workOrder),
      map(workOrder =>
        loadWorkOrder({
          keys: workOrder.id,
          criteria: {
            query: {
              '&join': 'tags,files,workOrderItems,address',
            },
          },
        }),
      ),
      switchMap(action => [action, editWorkOrderInitiated({ correlationId: action.correlationId })]),
    ),
  );

  correlateFullWorkOrderForEdit$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(loadWorkOrderSuccess)),
      this.actions$.pipe(ofType(editWorkOrderInitiated)),
    ]).pipe(
      filter(
        ([{ correlationId }, { correlationId: expectedCorrelationId }]) => correlationId === expectedCorrelationId,
      ),
      map(([{ entity: workOrder }]) => editLoadedWorkOrder({ workOrder })),
    ),
  );

  initiateEditing$ = createEffect(() =>
    merge(this.actions$.pipe(ofType(createWorkOrderSuccess)), this.actions$.pipe(ofType(editLoadedWorkOrder))).pipe(
      map((action: any) => (
        action.entity || action.workOrder
      ) as PendingFullWorkOrder),
      ensureFullWorkOrder(),
      withLatestFrom(this.store.select(unsavedEditedWorkOrders)),
      map(([workOrder, edited]) => (
        { workOrder, edited }
      )),
      ensureChildrenRequired(value => !!value?.workOrder?.id && !!value.edited),
      map(({ workOrder, edited }) =>
        editWorkOrder({ entity: edited[workOrder.id] ? deepObjectMerge(edited[workOrder.id], workOrder) : workOrder }),
      ),
      switchMap(action => [
        action,
        loadAllFacilityTypesIfNecessary({}),
        loadAllProductInstallationTypesIfNecessary({}),
      ]),
    ),
  );

  loadDetailsStepEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueToNextEditStep),
      withLatestFrom(this.store.select(currentWorkOrderEditingStep), this.store.select(editedWorkOrder)),
      filter(([, step]) => step === 'details'),
      map(([, , workOrder]) => workOrder),
      ensureRequired(value => !!value?.id),
      switchMap(workOrder => [
        loadAllStatesIfNecessary({
          criteria: {
            parents: { [uriNameOfEntityOrEmpty(Country)]: 'USA' },
          },
        }),
        loadAllWorkOrderFiles({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            },
          },
        }),
      ]),
    ),
  );

  loadItemsStepEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueToNextEditStep),
      withLatestFrom(this.store.select(currentWorkOrderEditingStep)),
      filter(([, step]) => step === 'items'),
      map(([{ workOrder }]) =>
        loadAllWorkOrderItems({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            },
          },
        }),
      ),
    ),
  );

  showEditModal$ = createEffect(
    () =>
      merge(this.actions$.pipe(ofType(createWorkOrderSuccess)), this.actions$.pipe(ofType(editLoadedWorkOrder))).pipe(
        exhaustMap(() => from(this.workUI.showAddEditModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  cancelAddEditWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelEditWorkOrder),
      map(({ workOrder }) => workOrder),
      ensureRequired(value => !!value?.status),
      exhaustMap(workOrder =>
        from(this.workUI.showAddEditActionSheet(workOrder.status)).pipe(
          map(({ role }) => role),
          ensureRequired(role => !!role && role !== 'backdrop'),
          tap(role => (
            ['delete', 'setCancel'].includes(role) ? this.nav.pop() : null
          )),
          map(role =>
            role === 'save'
              ? [pendingWorkOrderSaved({ workOrder }), endWorkOrderEdit()]
              : role === 'delete'
                ? [deleteWorkOrderByKey({ key: workOrder.id }), endWorkOrderEdit()]
                : role === 'leave'
                  ? [endWorkOrderEdit()]
                  : role === 'setCancel'
                    ? [confirmCancelWorkOrder({ workOrder })]
                    : [],
          ),
          switchMap(actions => actions),
        ),
      ),
    ),
  );

  deleteDraftWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDraftWorkOrder),
      map(({ workOrder }) => workOrder),
      ensureRequired(value => !!value?.id),
      switchMap(workOrder => [deleteWorkOrderByKey({ key: workOrder.id }), endWorkOrderEdit()]),
    ),
  );

  confirmCancelWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmCancelWorkOrder),
      exhaustMap(({ workOrder }) =>
        from(this.workUI.confirmCancelWorkOrder()).pipe(
          filter(role => role === 'ok'),
          switchMap(() => [cancelWorkOrder({ workOrder }), endWorkOrderEdit()]),
        ),
      ),
    ),
  );

  saveAndExitWorkOrderEditing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveAndExitEditingWorkOrder),
      switchMap(({ workOrder }) => [pendingWorkOrderSaved({ workOrder }), endWorkOrderEdit()]),
    ),
  );

  cancelWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelWorkOrder),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => pendingWorkOrderSaved({ workOrder: { ...workOrder, status: WorkOrderStatus.Cancelled } })),
    ),
  );

  saveEditedWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveEditedWorkOrder, publishWorkOrder),
      map(({ workOrder }) => replaceWorkOrder({ entity: workOrder as WorkOrder })),
      switchMap(action => [action, reloadDataOnWorkOrderSaved({ correlationId: action.correlationId })]),
    ),
  );

  reloadWorkOrderAfterSave$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(replaceWorkOrderSuccess)),
      this.actions$.pipe(ofType(reloadDataOnWorkOrderSaved)),
    ]).pipe(
      filter(
        ([{ correlationId }, { correlationId: expectedCorrelationId }]) => correlationId === expectedCorrelationId,
      ),
      map(([{ entity }]) =>
        loadWorkOrder({
          keys: entity.id,
          criteria: {
            query: {
              '&join': 'tags,files,workOrderItems,address',
            },
          },
        }),
      ),
      switchMap(action => [action, updateEditedWorkOrderWithLatest({ correlationId: action.correlationId })]),
    ),
  );

  updateEditedWorkOrderWithLatest$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(loadWorkOrderSuccess)),
      this.actions$.pipe(ofType(updateEditedWorkOrderWithLatest)),
    ]).pipe(
      filter(
        ([{ correlationId }, { correlationId: expectedCorrelationId }]) => correlationId === expectedCorrelationId,
      ),
      map(([{ entity }]) => entity as PendingFullWorkOrder),
      ensureRequired(value => !!value?.workOrderItems && !!value?.tags),
      switchMap(workOrder => [
        loadAllWorkOrderItemsSuccess({ entities: workOrder.workOrderItems }),
        loadAllWorkOrderTagsSuccess({ entities: workOrder.tags }),
        // files must be loaded directly
        // updateEditedWorkOrder({ workOrder }),
      ]),
    ),
  );

  updateEditedWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateEditedWorkOrder),
      map(({ workOrder }) => workOrder as PendingFullWorkOrder),
      ensureFullWorkOrder(),
      map(entity => changeWorkOrder({ entity })),
    ),
  );

  synchronizeWorkOrderChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changedWorkOrder),
      debounceTime(700),
      map(({ entity }) => entity as PendingFullWorkOrder),
      ensureFullWorkOrder(),
      map(workOrder => saveInProgressWorkOrder({ workOrder })),
    ),
  );

  saveInProgressWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveInProgressWorkOrder),
      // Custom replace handling to allow for request canceling.
      exhaustMap(({ workOrder }) =>
        this.work.replace(workOrder).pipe(
          map(entity => saveInProgressWorkOrderSuccess({ workOrder: entity })),
          catchError(error => of(saveInProgressWorkOrderFailure({ workOrder, error }))),
        ),
      ),
    ),
  );

  toastPendingWorkOrderSaveSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(replaceWorkOrderSuccess),
        filterCorrelationProcess(WORK_ORDER_SAVE_CORRELATION),
        switchMap(() => this.workUI.toastResult('Work Order saved successfully.', true)),
      ),
    { dispatch: false },
  );

  toastPendingWorkOrderSaveFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(replaceWorkOrderFailure),
        filterCorrelationProcess(WORK_ORDER_SAVE_CORRELATION),
        switchMap(() => this.workUI.toastResult('Work Order failed to save. Changes may be saved locally.', false)),
      ),
    { dispatch: false },
  );

  savePendingWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pendingWorkOrderSaved),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ workOrder }, user]) => (
        { workOrder, user }
      )),
      ensureChildrenRequired<
        { workOrder: Partial<PendingFullWorkOrder>; user?: User },
        { workOrder: PendingFullWorkOrder; user: Required<User> }
      >(value => !!value?.workOrder && !!value.user?.id),
      map(({ workOrder, user: { id } }) => (
        {
          workOrder: { ...workOrder, projectManagerId: id, files: undefined, pendingFiles: undefined },
        }
      )),
      map(({ workOrder }) => saveEditedWorkOrder({ workOrder })),
    ),
  );

  warnWorkOrderInvalid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderInvalid),
      switchMap(({ errors }) =>
        from(this.workUI.warnWorkOrderInvalid(errors)).pipe(
          filter(step => !!step),
          map(step => goToEditStep({ step })),
          catchError(() => EMPTY),
        ),
      ),
    ),
  );

  showSelectProjectManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showSelectProjectManager),
      withLatestFrom(this.store.select(nonDeletedProjectManagers), this.store.select(authenticatedUser)),
      map(([{ workOrder, event }, projectManagers, user]) => ({ workOrder, event, projectManagers, user })),
      ensureChildrenRequired(value => !!value?.workOrder && !!value?.projectManagers && !!value?.user),
      switchMap(({ workOrder, event, projectManagers, user }) =>
        from(
          this.workUI.showSelectProjectManager(
            workOrder,
            isAdmin(user) ? [...projectManagers, user] : projectManagers,
            event,
          ),
        ).pipe(
          filter(({ data }) => !!data),
          map(({ data }) =>
            updateWorkOrder({
              entity: {
                id: workOrder.id,
                projectManagerId: data,
              } as WorkOrder,
            }),
          ),
        ),
      ),
    ),
  );

  showSelectEmployee = createEffect(() =>
    this.actions$.pipe(
      ofType(showSelectEmployee),
      withLatestFrom(this.store.select(allEmployeeInstallers), this.store.select(authenticatedUser)),
      map(([{ workOrder, event }, employees, user]) => (
        { workOrder, event, employees, user }
      )),
      ensureChildrenRequired(value => !!value?.workOrder && !!value?.employees && !!value?.user),
      switchMap(({ workOrder, event, employees, user }) =>
        from(this.workUI.showSelectEmployee(workOrder, employees, event)).pipe(
          filter(({ data }) => !!data),
          map(({ data }) =>
            replaceWorkOrder({
              entity: {
                ...{
                  ...workOrder,
                  awardedToInstallerId: data,
                  awardedToInstaller: undefined,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );
}
