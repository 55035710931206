import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { TransactionItemModule } from '~gc/shared/components/transaction-item/transaction-item.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { BankAccountCardComponent } from './bank-account-card/bank-account-card.component';
import { PayInvoiceComponent } from './pay-invoice.component';

@NgModule({
  declarations: [PayInvoiceComponent, BankAccountCardComponent],
  imports: [CommonModule, IonicModule, TransactionItemModule, PipesModule, PushModule],
  exports: [PayInvoiceComponent],
})
export class PayInvoiceModule {}
