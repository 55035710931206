import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './domains/app/auth/auth.guard';
import { LoginLandingComponent } from './pages/login-landing/login-landing.component';
import { LoginPage } from './pages/login/login.page';
import { SignupFormComponent } from './pages/signup/signup-form/signup-form.component';
import { SignupPage } from './pages/signup/signup.page';

// TODO: Added routerOptions but not using in RouterModule.forRoot until we can get named anchors working and tested
const routerOptions: ExtraOptions = {};

const routes: Routes = [
  {
    path: 'login',
    component: LoginPage,
  },
  {
    path: 'login-landing',
    component: LoginLandingComponent,
  },
  {
    path: 'signup',
    component: SignupPage,
    children: [
      { path: 'installer', component: SignupFormComponent },
      { path: 'jumpstarter', component: SignupFormComponent },
    ],
  },
  {
    path: '',
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsModule),
  },
  { path: 'login-success', redirectTo: '/app', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
