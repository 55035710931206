import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CustomBannerComponent } from './custom-banner.component';

@NgModule({
  declarations: [CustomBannerComponent],
  imports: [CommonModule, IonicModule],
  exports: [CustomBannerComponent],
})
export class CustomBannerModule {}
