<ion-chip
  data-cy="item-negotiation-expander"
  style="transform: translateX(-4px)"
  color="danger"
  *ngIf="(item | hasNegotiations) && item.id !== expanded?.id"
  (click)="expand.emit(item)"
>
  <ion-label>Negotiations</ion-label>
  <ion-icon name="caret-down"></ion-icon>
</ion-chip>
