<ion-list>
  <ion-img class="ion-padding" [src]="installationType!.imageUrl"></ion-img>
  <ion-item lines="none">
    <h1 class="ion-no-margin">{{ installationType!.type | titlecase }}</h1>
  </ion-item>
  <!--
  TODO: Verify should be "false"
  -->
  <gc-datepicker-item
    [max]="today"
    [disabled]="installationType!.claimed?.isVerified ?? false"
    [control]="dateClaimed"
    closeOn="submit"
    presentation="month-year"
    (dismissed)="$event === 'confirm' && save()"
  >
    <ion-label class="ion-margin-none">
      <span *ngIf="!dateClaimed.value"> When did you start in {{ installationType!.type | lowercase }}? </span>
      <span *ngIf="!!dateClaimed.value">Start Date</span>
    </ion-label>
  </gc-datepicker-item>
  <ion-item *ngIf="!dateClaimed.value" lines="none">
    <ion-text class="ion-margin" color="dark">
      Please add your starting date. Next, you can add trainings you've completed and any supporting documents
      (certificates, letters of completion, etc.)
    </ion-text>
  </ion-item>
</ion-list>
