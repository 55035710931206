import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../domains/users/user/user.model';

export const formatFullName = (user: User) =>
  user ? `${user.firstName ? `${user.firstName} ` : ''}${user.lastName}` : '';

@Pipe({ name: 'fullName' })
export class FullNamePipe implements PipeTransform {
  transform(user: User): string {
    return formatFullName(user);
  }
}
