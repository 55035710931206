import { Entity } from '@briebug/ngrx-auto-entity';
import { compose } from '../../../shared/utils/compose.util';
import { BasicIdentity, MayHaveId } from '../../../shared/utils/types';
import { filter, map } from '../../../shared/utils/utils';
import { FileType, GenericFile } from '../../models/generic-file';

@Entity({
  modelName: 'UserFile',
  uriName: 'files',
})
export class UserFile extends GenericFile {
  override type!: UserFileType;
}

export type UserFileType = FileType.W9 | FileType.Insurance | FileType.Experience;

export const COMPLIANCE_TYPES = [FileType.W9, FileType.Insurance];

export const isComplianceFile = (file: UserFile) => COMPLIANCE_TYPES.includes(file.type);

export const fileHasType =
  (...types: UserFileType[]) =>
  (file: UserFile) =>
    types.includes(file.type);

export const fileMatchesUser =
  (user?: BasicIdentity | MayHaveId) =>
  (file: UserFile): boolean =>
    file.userId === (typeof user === 'string' || typeof user === 'number' ? user : user?.id);

export const findFileTypeForUser = (files: UserFile[], type: UserFileType, user?: BasicIdentity | MayHaveId) =>
  compose(
    filter(fileMatchesUser(user)),
    filter(fileHasType(type)),
    map(results => results[0]),
  )(files);

export const filterComplianceFilesForUser = (files: UserFile[], user?: BasicIdentity | MayHaveId) =>
  compose(filter(fileMatchesUser(user)), filter(isComplianceFile))(files);
