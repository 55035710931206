import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { SelectInstallerComponent } from './select-installer.component';

@NgModule({
  imports: [CommonModule, IonicModule, PipesModule, ReactiveFormsModule, FontAwesomeModule],
  declarations: [SelectInstallerComponent],
  exports: [SelectInstallerComponent],
})
export class SelectInstallerModule {}
