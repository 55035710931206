import { createSelector } from '@ngrx/store';
import { allSortedCustomDates } from '~gc/domains/custom-dates';
import { Direction, filterByOptionsOnKeyIfAvailable, filters, joinArrays, sortByLastFirst, } from '~gc/shared/utils/func';
import { authenticatedUser } from '../../domains/app/auth/auth.selectors';
import { EmployeeInstaller, Manager } from '../../domains/users';
import { ConnectionStatus } from '../../domains/users/connection/connection.model';
import {
  activeConnections,
  privateActiveConnections,
  publicActiveConnections,
} from '../../domains/users/connection/connections.selectors';
import {
  ConnectedInstallerTeamLead,
  filterInstallersByConnections,
  filterInstallersNotConnected,
} from '../../domains/users/models/connected-installer-team-lead.model';
import {
  allEmployeeInstallers,
  allInstallers,
  allManagers,
  allProjectManagers,
  allTeamLeads,
} from '../../domains/users/user/user.selectors';
import { detailedWorkOrders } from '../../domains/work/work-order/work-order.selectors';
import { buildTypedCustomDates, findFilteredWorkOrders } from './calendar.utils';
import {
  includedEmployeeInstallers,
  includedInstallers,
  includedManagers,
  includedNonConnectedInstallers,
  includedPrivateConnections,
  includedPublicConnections,
  showPending,
  showUnapprovedConnections,
} from './store/filters.selectors';
import { calendarsDisplayed, calendarsDisplayedFlags, calendarView } from './store/settings.selectors';


export const sortedCustomDatesByCalendarSelections = createSelector(
  allSortedCustomDates,
  calendarsDisplayed,
  filters('type'),
);

export const filteredWorkOrdersForCalendar = createSelector(
  detailedWorkOrders,
  includedInstallers,
  includedManagers,
  findFilteredWorkOrders,
);

export const calendarCustomDates = createSelector(
  sortedCustomDatesByCalendarSelections,
  filteredWorkOrdersForCalendar,
  allInstallers,
  allProjectManagers,
  authenticatedUser,
  buildTypedCustomDates,
);

export const allCalendarInstallers = createSelector(
  includedPrivateConnections,
  includedPublicConnections,
  includedNonConnectedInstallers,
  includedEmployeeInstallers,
  joinArrays,
);

// export const connectedInstallerIds = createSelector(allConnections, extract('installerTeamLeadId'));

// export const approvedConnectionInstallerIds = createSelector(approvedConnections, extract('installerTeamLeadId'));

// export const onlyConnectedTeamLeads = createSelector(
//   allTeamLeads,
//   approvedConnectionInstallerIds,
//   filterByOptionsOnKeyIfAvailable('id'),
// );

export const filterConnectionsToApprovedIfNecessary = (
  connections: ConnectedInstallerTeamLead[],
  showUnapproved: boolean,
) => (
  showUnapproved ? connections : connections.filter(connection => connection.status === ConnectionStatus.Approved)
);

const filterDeletedManagers = (sortedCalendarManagers: Manager[]) => {
  return sortedCalendarManagers.filter(manager => manager.deletedAt == null);
};

const filterDeletedEmployees = (sortedCalendarEmployees: EmployeeInstaller[]) => {
  return sortedCalendarEmployees.filter(employee => employee.deletedAt == null);
};

export const connectedPrivateTeamLeads = createSelector(
  allTeamLeads,
  privateActiveConnections,
  filterInstallersByConnections,
);

export const approvedOrAllConnectedPrivateTeamLeads = createSelector(
  connectedPrivateTeamLeads,
  showUnapprovedConnections,
  filterConnectionsToApprovedIfNecessary,
);

export const calendarPrivateConnectionInstallers = createSelector(
  approvedOrAllConnectedPrivateTeamLeads,
  allCalendarInstallers,
  filterByOptionsOnKeyIfAvailable('id'),
);

export const connectedPublicTeamLeads = createSelector(
  allTeamLeads,
  publicActiveConnections,
  filterInstallersByConnections,
);

export const approvedOrAllConnectedPublicTeamLeads = createSelector(
  connectedPublicTeamLeads,
  showUnapprovedConnections,
  filterConnectionsToApprovedIfNecessary,
);

export const calendarPublicConnectionInstallers = createSelector(
  approvedOrAllConnectedPublicTeamLeads,
  allCalendarInstallers,
  filterByOptionsOnKeyIfAvailable('id'),
);

export const allConnectedTeamLeads = createSelector(connectedPrivateTeamLeads, connectedPublicTeamLeads, joinArrays);

export const nonConnectedTeamLeads = createSelector(allTeamLeads, activeConnections, filterInstallersNotConnected);

export const calendarNonConnectedInstallers = createSelector(
  nonConnectedTeamLeads,
  allCalendarInstallers,
  filterByOptionsOnKeyIfAvailable('id'),
);

export const calendarEmployees = createSelector(
  allEmployeeInstallers,
  allCalendarInstallers,
  filterByOptionsOnKeyIfAvailable('id'),
);

export const sortedCalendarEmployees = createSelector(calendarEmployees, sortByLastFirst(Direction.Ascending));
export const includedCalendarManagers = createSelector(includedManagers, showPending, (ids, show) => (
  show ? ids : []
));

export const calendarManagers = createSelector(
  allManagers,
  includedCalendarManagers,
  filterByOptionsOnKeyIfAvailable('id'),
);

export const sortedCalendarManagers = createSelector(calendarManagers, sortByLastFirst(Direction.Ascending));

export const sortedCalendarManagersNotDeleted = createSelector(sortedCalendarManagers, filterDeletedManagers);

export const sortedCalendarEmployeesNotDeleted = createSelector(sortedCalendarEmployees, filterDeletedEmployees);

export const calendarSettings = createSelector(calendarView, calendarsDisplayedFlags, (view, calendars) => (
  {
    view,
    calendars,
  }
));
