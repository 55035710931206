import { Component, Input } from '@angular/core';
import { InstallerProfileForm } from '~gc/modals/installer-profile/installer-profile.form';
import { BasicProfileForm } from '../../../../modals/basic-profile/basic-profile.form';

@Component({
  selector: 'gc-basic-profile',
  templateUrl: './basic-profile.component.html',
  styleUrls: ['./basic-profile.component.scss'],
})
export class BasicProfileComponent {
  @Input() validated = false;
  @Input() form!: InstallerProfileForm | BasicProfileForm;
}
