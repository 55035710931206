import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NegotiableWorkOrderItem } from '~gc/domains/models/negotiable-work-order-item';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';

@Component({
  selector: 'gc-item-negotiations-expander',
  templateUrl: './item-negotiations-expander.component.html',
  styleUrls: ['./item-negotiations-expander.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemNegotiationsExpanderComponent {
  @Input() item!: DetailedWorkOrderItem | NegotiableWorkOrderItem;
  @Input() expanded!: DetailedWorkOrderItem | NegotiableWorkOrderItem;
  @Output() expand = new EventEmitter<DetailedWorkOrderItem | NegotiableWorkOrderItem>();
}
