<form [formGroup]="form" data-cy="installer-editor-insurance-form">
  <fieldset>
    <legend>Policy Info</legend>
    <ion-item lines="full">
      <ion-input
        data-e2e="insurance-provider-input"
        formControlName="insuranceProvider"
        labelPlacement="stacked"
        clearInput="true"
        data-cy="installer-editor-insurance-provider-input"
      >
        <div slot="label">
          <ion-text [color]="validated && form.insuranceProvider.invalid ? 'danger' : undefined">
            Insurance Provider
          </ion-text>
        </div>
      </ion-input>
    </ion-item>
    <ion-item lines="full">
      <ion-input
        data-e2e="insurance-policy-number-input"
        formControlName="insurancePolicyNumber"
        labelPlacement="stacked"
        clearInput="true"
        data-cy="installer-editor-insurance-policy-number-input"
      >
        <div slot="label">
          <ion-text [color]="validated && form.insurancePolicyNumber.invalid ? 'danger' : undefined">
            Policy Number
          </ion-text>
        </div>
      </ion-input>
    </ion-item>

    <gc-datepicker-item
      [control]="form.get('insuranceExpiration')"
      closeOn="select"
      placeHolder="Insurance Expiration Date"
      data-cy="installer-editor-insurance-expiration-input"
    >
      <ion-text [color]="validated && form.insuranceExpiration.invalid ? 'danger' : undefined">
        Insurance Expiration Date
      </ion-text>
    </gc-datepicker-item>

    <ion-item lines="full" *ngFor="let file of files" data-cy="insurance-files-list">
      <ion-label [attr.data-cy]="'file-name-' + file.filename">{{ file.filename }}</ion-label>
      <ion-icon slot="start" name="document"></ion-icon>
    </ion-item>
    <gc-file-drop-target
      (filesDropped)="addDroppedFiles($event)"
      (filesAdded)="addFiles($event)"
    ></gc-file-drop-target>
  </fieldset>

  <fieldset>
    <legend>Agent Information</legend>
    <ion-item lines="full">
      <ion-input
        data-e2e="insurance-agent-name-input"
        formControlName="insuranceAgentName"
        labelPlacement="stacked"
        clearInput="true"
        data-cy="installer-editor-insurance-agent-name-input "
      >
        <div slot="label">
          <ion-text [color]="validated && form.insuranceAgentName.invalid ? 'danger' : undefined">
            Agent Name
          </ion-text>
        </div>
      </ion-input>
    </ion-item>
    <ion-item lines="full">
      <ion-input
        data-e2e="insurance-agent-phone-input"
        formControlName="insuranceAgentPhone"
        labelPlacement="stacked"
        clearInput="true"
        data-cy="installer-editor-insurance-agent-phone-input"
      >
        <div slot="label">
          <ion-text [color]="validated && form.insuranceAgentPhone.invalid ? 'danger' : undefined">
            Agent Phone Number
          </ion-text>
        </div>
      </ion-input>
    </ion-item>
  </fieldset>
</form>
