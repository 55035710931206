import { Component } from '@angular/core';

@Component({
  selector: 'gc-tab-skeleton',
  templateUrl: './tab-skeleton.component.html',
  styleUrls: ['./tab-skeleton.component.scss']
})
export class TabSkeletonComponent {

}
