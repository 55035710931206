import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { UserHeaderModule } from '../../shared/components/user-header/user-header.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { ViewInstallerProfileModalModule } from '../view-installer-profile';
import { ViewJumpstarterProfileModalComponent } from './view-jumpstarter-profile-modal.component';

@NgModule({
  imports: [CommonModule, IonicModule, ViewInstallerProfileModalModule, UserHeaderModule, PipesModule, PushModule],
  declarations: [ViewJumpstarterProfileModalComponent],
  exports: [ViewJumpstarterProfileModalComponent],
})
export class ViewJumpstarterProfileModalModule {}
