import { createSelector } from '@ngrx/store';
import { allCategories } from '../../../domains/training/category/category.state';
import { allProductInstallationTypes } from '../../../domains/training/product-installation-types/product-installation-types.state';
import { allInstallers } from '../../../domains/users/user/user.selectors';
import { get } from '../../../shared/utils/func';
import { featureState } from './feature.name';


export const profileState = createSelector(featureState, get('profile'));

export const currentInstallerId = createSelector(profileState, get('installerId'));

export const currentInstaller = createSelector(currentInstallerId, allInstallers, (id, installers) =>
  installers.find(installer => installer.id === id),
);

export const selectedInstallTypeClaimedId = createSelector(profileState, get('selectedInstallTypeClaimedId'));

export const selectedCategoryId = createSelector(profileState, get('categoryId'));

export const selectedCategory = createSelector(selectedCategoryId, allCategories, (id, categories) =>
  categories.find(category => category.id === id),
);

export const selectedInstallTypeId = createSelector(profileState, get('installTypeId'));
export const selectedInstallType = createSelector(selectedInstallTypeId, allProductInstallationTypes, (id, types) =>
  types.find(type => type.id === id),
);

