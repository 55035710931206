import { SignaturePadComponent as SignaturePad } from '@almothafar/angular-signature-pad';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'gc-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
})
export class SignaturePadComponent implements AfterViewInit {
  @Input() padding = 20;
  @ViewChild(SignaturePad) signaturePad!: SignaturePad;

  signaturePadOptions = {
    canvasWidth: this.getWidth() - this.padding * 2,
    canvasHeight: (this.getWidth() - this.padding * 2) * 0.5,
  };

  constructor(private platform: Platform) {}

  get isEmpty(): boolean {
    return this.signaturePad?.isEmpty() ?? true;
  }

  get dataUrl(): string {
    return this.signaturePad.toDataURL('image/jpeg', 0.5);
  }

  ngAfterViewInit() {
    this.signaturePad.set('penColor', 'rgb(0, 0, 0)');
    this.signaturePad.set('backgroundColor', 'rgb(255, 255, 255)');
    this.signaturePad.clear();
  }

  getWidth() {
    return this.platform.width() > 600 ? 600 : this.platform.width();
  }

  clearSignaturePad() {
    this.signaturePad.clear();
  }
}
