import { RouterStateSnapshot } from '@angular/router';
import { MinimalRouterStateSerializer, MinimalRouterStateSnapshot } from '@ngrx/router-store';

export interface GCSerializedRouterStateSnapshot extends MinimalRouterStateSnapshot {
  path: string;
  query: any;
}

export class GRouterStateSerializer extends MinimalRouterStateSerializer {
  override serialize(routerState: RouterStateSnapshot): GCSerializedRouterStateSnapshot {
    const serialized = super.serialize(routerState);
    return {
      ...serialized,
      path: serialized.url.split('?')?.[0],
      query: serialized.url.split('?')?.[1],
    };
  }
}
