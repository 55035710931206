import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { ProductInstallationSubtype } from './product-installation-subtypes.model';

export const {
  initialState: initialProductInstallationSubtypeState,
  selectors: {
    selectAll: allProductInstallationSubtypes,
    selectEntities: productInstallationSubtypeEntities,
    selectCurrentEntity: currentProductInstallationSubtype,
  },
  actions: {
    loadAllIfNecessary: loadAllProductInstallationSubtypesIfNecessary,
    select: selectProductInstallSubtype,
    deselect: deselectProductInstallSubtype,
  },
} = buildState(ProductInstallationSubtype);

export function productInstallationSubtypeReducer(
  state = initialProductInstallationSubtypeState,
): IEntityState<ProductInstallationSubtype> {
  return state;
}

