import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  exhaustMap,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ensureRequired } from '~gc/shared/utils/rxjs';
import { LogService } from '../../shared/services/log.service';
import { AppState } from '../state';
import { onNativePlatformOnly } from '../utils';
import { AnalyticsEvents, AnalyticsService } from './analytics.service';
import { claimsAndTokenRetrieved } from './auth/auth-connect.actions';
import { authenticatedUserMetadataRetrieved } from './auth/auth.actions';
import { authenticatedUser } from './auth/auth.selectors';
import {
  deviceNotificationPermissionDenied,
  deviceNotificationRegistrationSucceeded,
  devicePushNotificationRegistrationFailed,
  devicePushNotificationsRegistered,
} from './firebase.actions';
import { FirebaseService } from './firebase.service';

const TAGS = ['Firebase'];

@Injectable()
export class FirebaseEffects {
  constructor(
    private actions$: Actions,
    private analytics: AnalyticsService,
    private store: Store<AppState>,
    private firebase: FirebaseService,
    private log: LogService,
  ) {}

  registerDeviceForPushNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      onNativePlatformOnly(),
      switchMap(() => this.firebase.initialize()),
      tap(() => this.log.debug(TAGS, '[Firebase] Getting ready to Request Device Token Permission')),
      switchMap(() =>
        from(this.firebase.requestPermission()).pipe(
          tap(() => console.log('[Firebase] Requested Device Token Permission')),
          switchMap(({ receive }) =>
            receive !== 'denied'
              ? this.firebase.initialize()
              : throwError(new Error('[Firebase] Push Notification Permission Denied')),
          ),
        ),
      ),
      tap(() => console.log('[Firebase] After Token Registration')),
      map(() => deviceNotificationRegistrationSucceeded()),
      catchError(error => of(deviceNotificationPermissionDenied({ error }))),
    ),
  );

  listenForDeviceToken$ = createEffect(() =>
    this.firebase.deviceToken$.pipe(
      map(token => devicePushNotificationsRegistered({ token })),
      catchError(() =>
        of(devicePushNotificationRegistrationFailed({ error: new Error('Push Notification Registration Failed') })),
      ),
    ),
  );

  saveDevicePushNotificationToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(devicePushNotificationsRegistered),
        switchMap(action =>
          this.actions$.pipe(
            ofType(authenticatedUserMetadataRetrieved),
            map(() => action),
          ),
        ),
        withLatestFrom(this.store.select(authenticatedUser)),
        debounceTime(1000),
        switchMap(([{ token }, user]) => (user?.id ? this.firebase.saveToken(user.id, token) : EMPTY)),
      ),
    { dispatch: false },
  );

  subscribeToUserTopic$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authenticatedUserMetadataRetrieved),
        onNativePlatformOnly(),
        withLatestFrom(this.store.select(authenticatedUser)),
        map(([, user]) => user),
        ensureRequired(user => !!user?.type),
        switchMap(user => this.firebase.subscribeToTopic(user.type)),
      ),
    { dispatch: false },
  );

  handlePushNotificationErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(devicePushNotificationRegistrationFailed, deviceNotificationPermissionDenied),
        tap(({ error }) => console.log(error)),
      ),
    { dispatch: false },
  );

  initializeAndRegisterAnalyitcs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(claimsAndTokenRetrieved),
        take(1),
        tap(() => this.log.info(TAGS, 'Looking For User')),
        exhaustMap(() =>
          this.store.select(authenticatedUser).pipe(
            filter(user => !!user),
            tap(() => this.log.info(TAGS, 'Analytics User Found')),
            take(1),
          ),
        ),
        tap(() => this.log.info(TAGS, 'Analytics Initializing')),
        ensureRequired(user => !!user?.id),
        switchMap(user =>
          from(this.analytics.initialize()).pipe(
            switchMap(() => this.analytics.setUserData(user)),
            tap(
              () => this.log.info(TAGS, 'Analytics Registration Complete'),
              err => this.log.error(TAGS, 'Unknown Analytics Registration Error!', err),
            ),
            switchMap(() => this.analytics.logEvent(AnalyticsEvents.sessionStart, {})),
          ),
        ),
      ),
    { dispatch: false },
  );
}
