import { createSelector } from '@ngrx/store';
import { get, trueToArray } from '~gc/shared/utils/func';
import { featureState } from './feature.name';

export const settingsState = createSelector(featureState, get('settings'));

export const calendarView = createSelector(settingsState, get('view'));
export const calendarsDisplayedFlags = createSelector(settingsState, get('calendars'));

export const calendarsDisplayed = createSelector(calendarsDisplayedFlags, trueToArray);
