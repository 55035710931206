import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PrintComponent } from './print.component';
import { PrintService } from './print.service';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [PrintComponent],
  exports: [PrintComponent],
  providers: [PrintService],
})
export class PrintModule {}
