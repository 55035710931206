<ion-grid *ngIf="invoice | isInvoiceStatus: 'REJECTED'">
  <ion-row>
    <ion-col>
      <ion-text color="danger">{{ reportsProgress ? 'This progress report was declined.' : 'This invoice was declined.' }}</ion-text>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-text><strong>Reason:</strong> {{ invoice.declinedReason }}</ion-text>
    </ion-col>
  </ion-row>
</ion-grid>
