import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Negotiation } from './negotiation.model';

export const {
  initialState: initialNegotiationDateState,
  facade: NegotiationFacadeBase,
  selectors: { selectAll: allNegotiations, selectCurrentEntity: currentNegotiation },
  actions: {
    loadAll: loadAllNegotiations,
    select: selectNegotiation,
    deselect: deselectNegotiation,
    create: createNegotiation,
    replace: replaceNegotiation,
    delete: deleteNegotiation,
  },
} = buildState(Negotiation);

export function negotiationReducer(state = initialNegotiationDateState): IEntityState<Negotiation> {
  return state;
}
