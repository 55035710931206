import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedProductInstallationTypeClaimed, InstallerTrainingsByCategory } from '../../../../core/installer-profile';
import { Category } from '../../../../domains/training/category/category.model';
import { TrainingClaimed } from '../../../../domains/training/trainings-claimed/trainings-claimed.model';

@Component({
  selector: 'gc-training-list',
  templateUrl: './training-list.component.html',
  styleUrls: ['./training-list.component.scss'],
})
export class TrainingListComponent {
  @Input() groups!: InstallerTrainingsByCategory[];
  @Input() canEditProfile!: boolean;
  @Input() selectedProductInstallationTypeClaim?: DetailedProductInstallationTypeClaimed;
  @Input() types?: DetailedProductInstallationTypeClaimed[];

  @Output() added = new EventEmitter<Category>();
  @Output() removed = new EventEmitter<TrainingClaimed>();
}
