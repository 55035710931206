import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BrowserService } from '../../domains/browser.service';
import { User } from '../../domains/users';

export interface BlitzzUserAPIResponse {
  UserToken: string;
}

export interface BlitzzSessionAPIResponse {
  AgentUrl: string;
  GuestUrl: string;
}

@Injectable()
export class LynkService {
  private isLoadingSubject = new Subject<boolean>();
  isLoading$ = this.isLoadingSubject.asObservable();

  constructor(
    private http: HttpClient,
    public browser: BrowserService,
  ) {}

  public userToken?: string;
  public agentUrl?: string;
  public guestUrl?: string;
  public currentUser?: User;

  public authenticateThenStartNewLynkSession(currentUser: User): any {
    this.currentUser = currentUser;
    this.isLoadingSubject.next(true);

    const apiKey = environment.apiKeys.lynk;
    const apiRootUrl = 'https://restapi.blitzz.co';

    // post login and get userToken
    this.http
      .post<BlitzzUserAPIResponse>(`${apiRootUrl}/api/auth`, {
        SecretKey: apiKey,
        AutoProvision: 1,
        FirstName: this.currentUser.firstName,
        LastName: this.currentUser.lastName,
        UserName: this.currentUser.email,
        CallExperinces: ['Video'], // typo intentional to match API :/
      })
      .pipe(
        switchMap(response => {
          this.userToken = response.UserToken;

          // start session with userToken
          return this.http.post<BlitzzSessionAPIResponse>(
            `${apiRootUrl}/api/session`,
            {
              SessionType: 1,
              ExperienceId: 1760,
            },
            {
              headers: new HttpHeaders({
                UserToken: this.userToken,
              }),
            },
          );
        }),
        switchMap(response => {
          this.agentUrl = response.AgentUrl;
          this.guestUrl = response.GuestUrl;

          this.isLoadingSubject.next(false);

          return this.browser.openUrl(this.agentUrl);
        }),
      )
      .subscribe(() => {});
  }
}
