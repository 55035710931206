<ion-header>
  <ion-toolbar>
    <ion-title>Create Invoice</ion-title>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <gc-invoice-header
      [workOrder]="workOrders.currentDetailedWorkOrder$ | push"
      [company]="workOrderRelations.currentWorkOrderCompany$ | push"
      [date]="todaysDate"
    >
    </gc-invoice-header>
    <gc-review-invoice-list
      [pendingInvoice]="invoiceAndPayment.pendingInvoice$ | push"
      [role]="auth.role$ | push"
      [permissions]="auth.tokenPermissions$ | push"
    ></gc-review-invoice-list>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row class="ion-justify-content-center ion-align-items-end">
      <div
        *ngFor="let queuedFile of invoiceFiles.queuedInvoiceFiles$ | push; first as first"
        class="file-thumbnail {{ first ? '' : 'ion-margin-start' }}"
      >
        <ion-thumbnail>
          <ion-img [src]="queuedFile.base64Url"></ion-img>
        </ion-thumbnail>
        <div class="remove-queue" (click)="invoiceFiles.removeQueuedFile(queuedFile)">
          <ion-icon name="close-circle"></ion-icon>
        </div>
      </div>
    </ion-row>

    <ion-row>
      <ion-col size="6">
        <ion-button
          *ngIf="invoiceAndPayment.pendingInvoice$ | push as pendingInvoice"
          expand="block"
          size="large"
          color="gc-alt-green"
          [disabled]="invoiceAndPayment.creatingInvoice$ | push"
          (click)="invoiceAndPayment.confirmInvoice(pendingInvoice)"
        >
          Submit
          <ion-spinner slot="end" *ngIf="invoiceAndPayment.creatingInvoice$ | push" name="bubbles"></ion-spinner>
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-input #fileInput type="file" [hidden]="true" (change)="addFiles($event)"></ion-input>
        <ion-button
          color="dark"
          expand="block"
          size="large"
          fill="outline"
          [disabled]="invoiceAndPayment.creatingInvoice$ | push"
          (click)="chooseFiles()"
        >
          <ion-icon name="add" slot="start"></ion-icon>
          Add Images
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
