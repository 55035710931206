<ion-card *ngIf="workOrder && !closed && !(workOrder | isStatus: 'PUBLISHED_AWAITING_RESPONSE' : 'DRAFT')" mode="ios">
  <ion-card-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button color="dark" (click)="closed = true">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-card-header>
  <ion-card-content>
    <ion-card-title lines="none" class="ion-text-wrap"> Switch to this Work Order's chat channel?</ion-card-title>
    <ion-button expand="block" color="gc-green" (click)="this.switch.emit(workOrder)"> Switch</ion-button>
  </ion-card-content>
</ion-card>
