import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../domains/models/detailed-work-order-item';


@Component({
  selector: 'gc-units-pending',
  templateUrl: './units-pending.component.html',
  styleUrls: ['./units-pending.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsPendingComponent {
  @Input() item!: DetailedWorkOrderItem;
}
