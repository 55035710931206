import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { PhoneNumber, PhoneNumberType } from '../../../domains/phone-number';
import { NewPhoneNumberForm } from './forms/new-phone-number.form';
import { PhoneNumberEntry } from './models/phone-number-entry.model';
import { PhoneNumberVerificationAttempt } from './models/phone-number-verification-attempt.model';


@Component({
  selector: 'gc-advanced-phone-number-manager',
  templateUrl: './advanced-phone-number-manager.component.html',
  styleUrls: ['./advanced-phone-number-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvancedPhoneNumberManagerComponent implements OnChanges {
  @Input() entries?: PhoneNumberEntry[];
  @Input() canEdit = false;

  @Output() removed = new EventEmitter<PhoneNumber>();
  @Output() added = new EventEmitter<PhoneNumber>();
  @Output() verified = new EventEmitter<PhoneNumberVerificationAttempt>();
  @Output() refreshed = new EventEmitter<PhoneNumber>();

  form = new NewPhoneNumberForm();

  submitted = false;

  // mask = '(000) 000-0000'
  readonly phoneMask: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };
  readonly maskPredicate: MaskitoElementPredicateAsync = async el => (el as HTMLIonInputElement).getInputElement();

  codeInputs: FormControl[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entries && this.entries?.length) {
      this.codeInputs = this.entries.map(
        () =>
          new FormControl(null, {
            validators: [Validators.minLength(6), Validators.maxLength(6)],
            updateOn: 'change',
          }),
      );
    } else if (!this.entries?.length) {
      this.codeInputs = [];
    }
  }

  tryCode(input: FormControl, phoneNumber: PhoneNumber) {
    if (input.invalid) {
      return;
    }

    this.verified.emit({ code: input.value, phoneNumber });
  }

  add() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const phoneNumber = this.form.updatedModel;

    this.added.emit({
      ...this.form.updatedModel,
      isVerified: this.form.type.value !== PhoneNumberType.Mobile,
    } as PhoneNumber);
    this.form.resetForNewEntry();
    this.submitted = false;
  }
}
