import { Injectable } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, defer, forkJoin, from, iif, merge, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ensureExists } from '../../../shared/utils/rxjs';
import { appActive } from '../../app/environment/environment.state';
import { FirebaseService } from '../../app/firebase.service';
import { currentWorkOrderMatchedInterestedInstallers } from '../../work-order-users.selectors';
import { viewWorkOrder } from '../work-order-ui.actions';
import { WorkOrder, workOrderIsStatus, WorkOrderStatus } from '../work-order/work-order.model';
import { currentWorkOrder } from '../work-order/work-order.state';
import {
  addMemberToChannel,
  removeMemberFromChannel,
  switchToCurrentWorkOrderChat,
  workOrderChatActivated,
  workOrderChatChannelActivated,
  workOrderChatDeactivated,
  workOrderChatError,
  workOrderChatInitialized,
  workOrderChatSidebarClosed,
  workOrderChatSidebarOpened,
} from './work-order-chat.actions';
import { NonMemberError, StreamChatFCMNotification, WorkOrderChatService } from './work-order-chat.service';
import { activeUserId, currentChannelIds, isChatOpen } from './work-order-chat.state';

@Injectable()
export class WorkOrderChatEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly chat: WorkOrderChatService,
    private readonly store: Store,
    private readonly menu: MenuController,
    private readonly nav: NavController,
    private readonly firebase: FirebaseService,
  ) {}

  openMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(workOrderChatSidebarOpened),
        switchMap(() => this.menu.open('gc-chat')),
      ),
    { dispatch: false },
  );

  clearActiveChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(workOrderChatSidebarClosed, workOrderChatDeactivated),
        withLatestFrom(this.store.select(isChatOpen)),
        filter(([, isOpen]) => isOpen),
        tap(() => this.chat.clearActiveChannel()),
      ),
    { dispatch: false },
  );

  closeMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(workOrderChatSidebarClosed, workOrderChatDeactivated),
        withLatestFrom(this.store.select(isChatOpen)),
        // filter(([, isOpen]) => isOpen),
        switchMap(() => this.menu.close('gc-chat')),
      ),
    { dispatch: false },
  );

  closeChatWhenActivatedAndOpen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderChatActivated),
      withLatestFrom(this.store.select(isChatOpen)),
      filter(([, isOpen]) => isOpen),
      map(([{ workOrder }]) => workOrderChatDeactivated({ workOrder })),
    ),
  );

  registerForPushNotifications$ = createEffect(
    () =>
      combineLatest([this.firebase.rawToken$, this.actions$.pipe(ofType(workOrderChatInitialized))]).pipe(
        switchMap(([token]) => this.chat.registerPush(token)),
        tap(
          res => console.log(res),
          err => console.error(err),
        ),
      ),
    { dispatch: false },
  );

  handleNotificationAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderChatInitialized),
      switchMap(() =>
        merge(
          this.firebase.notificationAction$.pipe(
            map(n => n.notification.data as StreamChatFCMNotification),
            filter(data => data?.sender === 'stream.chat'),
            map(data => ({ channelId: data.channel_id })),
          ),
          this.chat.chatNotificationAction$,
        ),
      ),
      withLatestFrom(this.store.select(activeUserId)),
      switchMap(([notification, activeUserId]) => this.chat.appendChannels(notification.channelId, activeUserId)),
      // This would only open the single chat to the menu.
      // Might need to get matching work order and matched installers to preload related chats.
      map(channels => workOrderChatSidebarOpened({ channels })),
    ),
  );

  notifyNewMessages$ = createEffect(
    () =>
      merge(
        this.chat.notification$.pipe(
          map((event: any) => event.event),
          withLatestFrom(this.store.select(activeUserId)),
          filter(([event, activeUserId]) => event.user?.id !== activeUserId),
        ),
        this.firebase.notificationReceived$.pipe(
          map(({ data }) => data as StreamChatFCMNotification),
          filter(data => data.sender === 'stream.chat'),
        ),
      ).pipe(
        filter((event: any) => event.type === 'message.new'),
        withLatestFrom(this.chat.channels$),
        map(([event, channels]: [any, any]) => ({
          channel: channels.find((c: any) => c.id === event.channel_id),
          event,
        })),
        withLatestFrom(this.store.select(isChatOpen), this.store.select(appActive)),
        concatMap(([event, open, isActive]) =>
          iif(
            () => open && isActive,
            defer(() =>
              of(event).pipe(
                // Delay just a bit to give time for read events to fire.
                delay(300),
                filter(({ channel }) => !channel || !!channel.countUnread()),
              ),
            ),
            defer(() => of(event)),
          ),
        ),
        filter(({ channel }: any) => !!channel),
        switchMap(({ event, channel }) => this.chat.notifyMessage(event, channel)),
      ),
    { dispatch: false },
  );

  switchToCurrentWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchToCurrentWorkOrderChat),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureExists(workOrder => !!workOrder),
      map(workOrder => workOrderChatActivated({ workOrder })),
    ),
  );

  openWorkOrderChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderChatActivated),
      withLatestFrom(
        this.store.select(currentWorkOrderMatchedInterestedInstallers),
        this.store.select(activeUserId),
        this.store.select(isChatOpen),
      ),
      filter(([, , , isOpen]) => !isOpen),
      switchMap(([{ workOrder }, matchedInstallers, activeUserId]) =>
        from(
          workOrderIsStatus(
            workOrder,
            WorkOrderStatus.PublishedWithResponses,
            WorkOrderStatus.PublishedAwaitingResponse,
          )
            ? this.chat.addMatchedInstallerChannels(matchedInstallers, activeUserId)
            : this.chat.addAwardedWorkOrderChannel(workOrder, activeUserId),
        ).pipe(
          map(channels => workOrderChatSidebarOpened({ channels })),
          tap({ error: err => console.warn('Error Appending Channels:', err.message) }),
          catchError(err =>
            from([
              workOrderChatError({ channels: err.channels }),
              ...(err instanceof NonMemberError ? [workOrderChatSidebarOpened({ channels: err.channels })] : []),
            ]),
          ),
        ),
      ),
    ),
  );

  openChatByChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderChatChannelActivated),
      switchMap(({ workOrderId, channelId }) => [
        viewWorkOrder({ workOrder: { id: workOrderId } as WorkOrder }),
        workOrderChatSidebarOpened({ channels: [channelId] }),
      ]),
    ),
  );

  addMemberToChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addMemberToChannel),
        withLatestFrom(this.store.select(currentChannelIds)),
        switchMap(([, channels]) =>
          forkJoin([
            ...channels.map(channel =>
              this.chat.addMemberToChannel(channel).pipe(
                take(1),
                map(() => channel),
              ),
            ),
          ]),
        ),
        withLatestFrom(this.store.select(activeUserId)),
        switchMap(([channels, activeUserId]) => this.chat.appendChannels(channels, activeUserId)),
      ),
    { dispatch: false },
  );

  removeMemberFromChannel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMemberFromChannel),
      withLatestFrom(this.store.select(currentChannelIds)),
      switchMap(([, channels]) =>
        forkJoin([
          ...channels.map(channel =>
            this.chat.removeMemberFromChannel(channel).pipe(
              take(1),
              map(() => channel),
            ),
          ),
        ]),
      ),
      map(() => workOrderChatSidebarClosed()),
    ),
  );
}
