<ion-header class="ion-no-border">
  <ion-toolbar class="ion-padding-top">
    <ion-buttons slot="start">
      <ion-button color="gc-green" (click)="modalGC.dismiss(undefined, 'cancel'); clearSelection()">
        <ion-icon name="chevron-back-outline"></ion-icon>
        Back
      </ion-button>
    </ion-buttons>
    <ion-title>Select Installers</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <gc-select-installer
    [detail]="false"
    [installers]="installers$ | push"
    [workOrder]="workOrder"
    [selected]="selected$ | push"
    (selectedChange)="selectionChanged($event)"
  >
  </gc-select-installer>

  <ion-button expand="block" color="gc-alt-green" (click)="publish()"> Share with Installers</ion-button>
</ion-content>
