import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { WorkOrder } from '../work-order/work-order.model';
import { kudosGiven } from './kudos.actions';
import { Kudos } from './kudos.model';
import { KudosFacadeBase } from './kudos.state';

@Injectable()
export class KudosFacade extends KudosFacadeBase {
  constructor(private readonly store: Store<AppState>) {
    super(Kudos, store);
  }

  give(workOrder: WorkOrder): void {
    this.store.dispatch(kudosGiven({ workOrder }));
  }
}
