import { createReducer, on } from '@ngrx/store';
import {
  connectionSelectionChanged,
  connectionSelectionCleared,
  connectionsSearchCriteriaChanged,
  connectionsSearchCriteriaCleared,
} from '../publish-to-connections-modal/publish-to-connections.actions';
import {
  employeeSearchCriteriaChanged,
  employeeSearchCriteriaCleared,
  employeeSelectionChanged,
  employeeSelectionCleared,
} from '../publish-to-employees-modal/publish-to-employees.actions';

export interface UIState {
  publishSearchCriteria?: string;
  selectedInstallerIds: string[];
}

export const initialState: UIState = {
  selectedInstallerIds: [],
};

export const uiReducer = createReducer(
  initialState,
  on(
    connectionsSearchCriteriaChanged,
    employeeSearchCriteriaChanged,
    (state, { searchCriteria }): UIState => ({
      ...state,
      publishSearchCriteria: searchCriteria,
    }),
  ),
  on(
    connectionsSearchCriteriaCleared,
    employeeSearchCriteriaCleared,
    (state): UIState => ({
      ...state,
      publishSearchCriteria: undefined,
    }),
  ),
  on(
    employeeSelectionChanged,
    connectionSelectionChanged,
    (state, { selection }): UIState => ({
      ...state,
      selectedInstallerIds: selection as string[],
    }),
  ),
  on(
    employeeSelectionCleared,
    connectionSelectionCleared,
    (state): UIState => ({
      ...state,
      selectedInstallerIds: [],
    }),
  ),
);
