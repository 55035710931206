import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-invoice-table-skeleton',
  templateUrl: './invoice-table-skeleton.component.html',
  styleUrls: ['./invoice-table-skeleton.component.scss'],
})
export class InvoiceTableSkeletonComponent {
  rows?: number[]; // TODO: not sure that this is correct was previously: rows = [], caused an error that number[] is not assignable to never[]
  @Input() showDetails!: boolean;

  @Input() set count(length: number) {
    this.rows = Array.from({ length }).map((_, i) => i);
  }
}
