import { createAction, props } from '@ngrx/store';
import { DetailedInstaller } from '../../models/detailed-installer';
import { InstallerTeamLead } from '../user/user.model';
import { JumpstarterFavorite } from './jumpstarter-favorite.model';

export const jumpstarterFavorited = createAction(
  '[JumpstarterFavorite] Jumpstarter Favorited',
  props<{ favorite: boolean }>(),
);

export const jumpstarterHireOfferExtended = createAction('[JumpstarterFavorite] Jumpstarter Hire Offer Extended');

export const jumpstarterHireOfferAccepted = createAction(
  '[JumpstarterFavorite] Jumpstarter Hire Offer Accepted',
  props<{ installer: InstallerTeamLead }>(),
);

export const jumpstarterHireOfferDeclined = createAction(
  '[JumpstarterFavorite] Jumpstarter Hire Offer Declined',
  props<{ installer: InstallerTeamLead }>(),
);

export const confirmLeaveTeam = createAction('[JumpstarterFavorite] Confirm Leave Team');
export const leaveTeamConfirmed = createAction('[JumpstarterFavorite] Leave Team Confirmed');

export const reviewJumpstarterHireOffer = createAction(
  '[JumpstarterFavorite] Review Jumpstarter Hire Offer',
  props<{ installer: DetailedInstaller }>(),
);

export const removeJumpstarter = createAction(
  '[JumpstarterFavorite] Remove Jumpstarter',
  props<{ favorite: JumpstarterFavorite }>(),
);
