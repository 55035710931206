import { checkKeyName, ENTITY_OPTS_PROP, IEntityInfo, IEntityOptions } from '@briebug/ngrx-auto-entity';
import { ActionCreator, createAction, Creator, props } from '@ngrx/store';
import { EntityCriteria } from '../../domains/entity.service.utils';
import { GenericFile } from '../../domains/models/generic-file';
import { PendingFile } from '../../domains/models/pending-file';

// tslint:disable
export const uuid = (a?: any, b?: any) => {
  for (
    b = a = '';
    a++ < 36;
    b += (a * 51) & 52 ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16) : '-'
  ) {}
  return b;
};
// tslint:enable

export const setInfo = (type: any): IEntityInfo => {
  if (type) {
    const instance = new type();
    const opts = (type[ENTITY_OPTS_PROP] || { modelName: instance.constructor.name }) as IEntityOptions;
    const modelName = opts.modelName;
    checkKeyName(type, modelName);
    return {
      modelType: type,
      ...opts,
    };
  } else {
    return { modelName: 'null', modelType: String };
  }
};

export type GenericActionCreator<T extends string = string, C extends Creator = Creator> = ActionCreator<T, C> & {
  genericType: string;
};

export function defineTypedFactoryFunction<T extends string, C extends Creator>(
  type: T,
  genericType: string,
  creator: C,
): GenericActionCreator<T, C> {
  Object.defineProperty(creator, 'genericType', { value: genericType, writable: false });
  return Object.defineProperty(creator, 'type', {
    value: type,
    writable: false,
  }) as GenericActionCreator<T, C>;
}

export const FILE_ACTION_TYPES: Record<string, string> = {
  Upload: '[File] (GenericFile) Upload',
  UploadRetry: '[File] (GenericFile) Upload Retry',
  UploadSuccess: '[File] (GenericFile) Upload Success',
  UploadProgress: '[File] (GenericFile) Upload Progress',
  UploadFailure: '[File] (GenericFile) Upload Failure',
  Delete: '[File] (GenericFile) Delete',
  DeleteSuccess: '[File] (GenericFile) Delete Success',
  DeleteFailure: '[File] (GenericFile) Delete Failure',
  RemovePending: '[File] (GenericFile) Remove Pending',
} as const;

export interface BaseProps {
  info: IEntityInfo;
  correlationId: string;
  genericType: (typeof FILE_ACTION_TYPES)[keyof typeof FILE_ACTION_TYPES];
}

export interface UploadActionProps extends BaseProps {
  pendingFile: PendingFile;
  criteria: EntityCriteria;
  skipAPISuccessCall?: boolean;
}

export const uploadFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Upload`,
    FILE_ACTION_TYPES['Upload'],
    ({
      pendingFile,
      criteria,
      skipAPISuccessCall,
    }: {
      pendingFile: PendingFile;
      criteria: EntityCriteria;
      skipAPISuccessCall?: boolean;
    }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Upload`,
        props<UploadActionProps>(),
      )({
        info: setInfo(model),
        pendingFile,
        criteria,
        correlationId: uuid(),
        skipAPISuccessCall,
        genericType: FILE_ACTION_TYPES['Upload'],
      }),
  );

export interface UploadRetryActionProps {
  info: IEntityInfo;
  pendingFile: PendingFile;
  criteria: EntityCriteria;
  skipAPISuccessCall?: boolean;
  genericType: string;
}

export const uploadRetryFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Retry`,
    FILE_ACTION_TYPES['UploadRetry'],
    ({
      pendingFile,
      criteria,
      skipAPISuccessCall,
    }: {
      pendingFile: PendingFile;
      criteria: EntityCriteria;
      skipAPISuccessCall?: boolean;
    }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Retry`,
        props<UploadRetryActionProps>(),
      )({
        info: setInfo(model),
        pendingFile,
        criteria,
        skipAPISuccessCall,
        genericType: FILE_ACTION_TYPES['UploadRetry'],
      }),
  );

export interface UploadSuccessActionProps {
  info: IEntityInfo;
  pendingFile: PendingFile;
  criteria: EntityCriteria;
  genericType: string;
}

export const uploadSuccessFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Upload Success`,
    FILE_ACTION_TYPES['UploadSuccess'],
    ({ pendingFile, criteria }: { pendingFile: PendingFile; criteria: EntityCriteria }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Upload Success`,
        props<UploadSuccessActionProps>(),
      )({ info: setInfo(model), pendingFile, criteria, genericType: FILE_ACTION_TYPES['UploadSuccess'] }),
  );

export interface UploadProgress {
  loaded: number;
  total?: number;
}

export interface UploadProgressActionProps {
  info: IEntityInfo;
  pendingFile: PendingFile;
  progress: UploadProgress;
  genericType: string;
}

export const uploadProgressFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Upload Progress`,
    FILE_ACTION_TYPES['UploadProgress'],
    ({ pendingFile, progress }: { pendingFile: PendingFile; progress: UploadProgress }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Upload Progress`,
        props<UploadProgressActionProps>(),
      )({ info: setInfo(model), pendingFile, progress, genericType: FILE_ACTION_TYPES['UploadProgress'] }),
  );

export interface UploadFailureActionProps {
  info: IEntityInfo;
  pendingFile: PendingFile;
  error: any;
  genericType: string;
}

export const uploadFailureFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Upload Failure`,
    FILE_ACTION_TYPES['UploadFailure'],
    ({ pendingFile, error }: { pendingFile: PendingFile; error: any }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Upload Failure`,
        props<UploadFailureActionProps>(),
      )({ info: setInfo(model), pendingFile, error, genericType: FILE_ACTION_TYPES['UploadFailure'] }),
  );

export interface DeleteActionProps {
  info: IEntityInfo;
  file: GenericFile & { correlationId?: string };
  criteria: EntityCriteria;
  correlationId: string;
  genericType: string;
}

export const deleteFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Delete`,
    FILE_ACTION_TYPES['Delete'],
    ({ file, criteria }: { file: GenericFile & { correlationId?: string }; criteria: EntityCriteria }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Delete`,
        props<DeleteActionProps>(),
      )({ info: setInfo(model), file, criteria, correlationId: uuid(), genericType: FILE_ACTION_TYPES['Delete'] }),
  );

export interface RemovePendingActionProps {
  info: IEntityInfo;
  pendingFile: GenericFile & { correlationId?: string };
  criteria: EntityCriteria;
  correlationId: string;
  genericType: string;
}

export const removePendingFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Remove Pending`,
    FILE_ACTION_TYPES['RemovePending'],
    ({
      pendingFile,
      criteria,
      correlationId,
    }: {
      pendingFile: GenericFile & { correlationId?: string };
      criteria: EntityCriteria;
      correlationId: string;
    }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Remove Pending`,
        props<RemovePendingActionProps>(),
      )({
        info: setInfo(model),
        pendingFile,
        criteria,
        correlationId,
        genericType: FILE_ACTION_TYPES['RemovePending'],
      }),
  );

export interface DeleteSuccessActionProps {
  info: IEntityInfo;
  file: GenericFile;
  criteria: EntityCriteria;
  correlationId: string;
  genericType: string;
}

export const deleteSuccessFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Delete Success`,
    FILE_ACTION_TYPES['DeleteSuccess'],
    ({ file, criteria, correlationId }: { file: GenericFile; criteria: EntityCriteria; correlationId?: string }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Delete Success`,
        props<DeleteSuccessActionProps>(),
      )({
        info: setInfo(model),
        file,
        criteria,
        correlationId: correlationId ?? uuid(),
        genericType: FILE_ACTION_TYPES['DeleteSuccess'],
      }),
  );

export interface DeleteFailureActionProps {
  info: IEntityInfo;
  file: GenericFile;
  error: any;
  correlationId: string;
  genericType: string;
}

export const deleteFailureFactory = <T>(model: T) =>
  defineTypedFactoryFunction(
    `[${setInfo(model).modelName}] (GenericFile) Delete Failure`,
    FILE_ACTION_TYPES['DeleteFailure'],
    ({ file, error, correlationId }: { file: GenericFile; error: any; correlationId?: string }) =>
      createAction(
        `[${setInfo(model).modelName}] (GenericFile) Delete Failure`,
        props<DeleteFailureActionProps>(),
      )({
        info: setInfo(model),
        file,
        error,
        correlationId: correlationId ?? uuid(),
        genericType: FILE_ACTION_TYPES['DeleteFailure'],
      }),
  );
