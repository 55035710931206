import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TeamUser } from '../../domains/users';
import { WorkOrder } from '../../domains/work/work-order/work-order.model';
import { Selectable } from '../../shared/utils/types';
import { shareSelectionChanged, shareSelectionCleared } from './share-with-installers-modal.actions';
import { selectableTeamMemberInstallers } from './share-with-installers-modal.selectors';
import { selectedInstallerIds } from './store/ui.selectors';

@Component({
  selector: 'gc-share-with-installers-modal',
  templateUrl: './share-with-installers-modal.component.html',
  styleUrls: ['./share-with-installers-modal.component.scss'],
})
export class ShareWithInstallersModalComponent {
  // @Input() installers!: InstallerTeamMember[];
  @Input() workOrder!: WorkOrder;
  // @Input() selected: InstallerTeamMember[] = [];

  installers$: Observable<Selectable<TeamUser>[]> = this.store.select(selectableTeamMemberInstallers);
  selected$ = this.store.select(selectedInstallerIds).pipe(tap(selected => (this.selected = selected)));

  selected: TeamUser['id'][] = [];

  constructor(
    public readonly modalGC: ModalController,
    private readonly store: Store,
  ) {}

  async publish() {
    await this.modalGC.dismiss(this.selected, 'ok');
    this.clearSelection();
  }

  selectionChanged(selection: TeamUser['id'][]) {
    this.store.dispatch(shareSelectionChanged({ selection }));
  }

  clearSelection() {
    this.store.dispatch(shareSelectionCleared());
  }
}
