import { Injectable } from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { PendingInvoice } from '../models/pending-invoice';
import { IStatusGroup } from '../models/status-group';
import { AppState } from '../state';
import { WorkOrder } from '../work/work-order/work-order.model';
import {
  addNewInvoice,
  approveInvoice,
  confirmPendingInvoice, confirmPendingProgress,
  declineInvoice,
  invoiceListEndReached,
  payInvoice,
  reviewPendingInvoice, reviewPendingProgress,
  searchCriteriaUpdated,
  selectInvoiceSettlementTiming,
  showInvoiceOptions,
  showSignLienRelease,
  updateInvoiceArea,
  viewInvoice,
  viewInvoicesByStatus,
} from './invoice-and-payment.actions';
import {
  creatingInvoice,
  currentStatusGroup,
  currentStatusGroupAggregate,
  invoiceArea,
  invoiceForSigningLienRelease,
  pendingInvoice,
  searchCriteria,
} from './invoice-and-payment.selectors';
import { InvoiceArea } from './invoice-and-payment.state';
import { Invoice } from './invoice/invoice.model';
import { invoicesReadyToSign, sortedInvoicesByCurrentStatusGroup } from './invoices.selectors';

@Injectable()
export class InvoiceAndPaymentFacade {
  currentStatusGroup$ = this.store.select(currentStatusGroup);
  currentStatusGroupAggregate$ = this.store.select(currentStatusGroupAggregate);
  pendingInvoice$ = this.store.select(pendingInvoice);
  creatingInvoice$ = this.store.select(creatingInvoice);
  currentStatusInvoices$ = this.store.select(sortedInvoicesByCurrentStatusGroup);
  searchCriteria$ = this.store.select(searchCriteria);
  invoiceArea$ = this.store.select(invoiceArea);
  invoicesReadyToSign$ = this.store.select(invoicesReadyToSign);
  invoiceForSigningLienRelease$ = this.store.select(invoiceForSigningLienRelease);

  constructor(private store: Store<AppState>) {}

  updateInvoiceArea(area: InvoiceArea) {
    this.store.dispatch(updateInvoiceArea({ area }));
  }

  invoiceListEndReached(event: InfiniteScrollCustomEvent) {
    this.store.dispatch(invoiceListEndReached({ event }));
  }

  viewByStatus(status: IStatusGroup): void {
    this.store.dispatch(viewInvoicesByStatus({ status }));
  }

  viewInvoice(invoice: Invoice): void {
    this.store.dispatch(viewInvoice({ invoice }));
  }

  signLienRelease(invoice: Invoice): void {
    return this.store.dispatch(showSignLienRelease({ invoice }));
  }

  approveInvoice(invoice: Invoice): void {
    this.store.dispatch(approveInvoice({ invoice }));
  }

  declineInvoice(invoice: Invoice): void {
    this.store.dispatch(declineInvoice({ invoice }));
  }

  payInvoice(invoice: Invoice): void {
    this.store.dispatch(payInvoice({ invoice }));
  }

  showInvoiceOptions(event: Event, invoice: Invoice): void {
    this.store.dispatch(showInvoiceOptions({ event, invoice }));
  }

  addNewInvoice(): void {
    this.store.dispatch(addNewInvoice());
  }

  selectInvoiceSettlementTiming(workOrder: WorkOrder, invoice: Invoice, dataUrl: string) {
    this.store.dispatch(selectInvoiceSettlementTiming({ workOrder, invoice, dataUrl }));
  }

  updateSearchCriteria(criteria: string) {
    this.store.dispatch(searchCriteriaUpdated({ criteria }));
  }

  reviewInvoice(pendingInvoice: PendingInvoice): void {
    this.store.dispatch(reviewPendingInvoice({ pendingInvoice }));
  }

  reviewProgress(pendingInvoice: PendingInvoice): void {
    this.store.dispatch(reviewPendingProgress({ pendingInvoice }));
  }

  confirmInvoice(pendingInvoice: PendingInvoice): void {
    this.store.dispatch(confirmPendingInvoice({ pendingInvoice }));
  }

  confirmProgress(pendingInvoice: PendingInvoice): void {
    this.store.dispatch(confirmPendingProgress({ pendingInvoice }));
  }
}
