import { Injectable, TemplateRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PrintComponent } from './print.component';

@Injectable()
export class PrintService {
  constructor(private modals: ModalController) {}

  async openPrint(content: TemplateRef<any>) {
    const modal = await this.modals.create({
      component: PrintComponent,
      componentProps: {
        content,
      },
    });
    await modal.present();
    window.print();
    await modal.dismiss();
  }
}
