import { Action, createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import { AppState } from '../state';
import { UserType } from '../users';
import { NewUserSignUpInfo } from './models/new-user-sign-up-info';

export interface LoginState {
  showAlert: boolean;
  signUpUserType?: UserType;
}

export const initialLoginState: LoginState = {
  showAlert: false,
};

export const showLoginAlert = createAction('[Login] Show Alert');

export const hideLoginAlert = createAction('[Login] Hide Alert');

export const signup = createAction('[Login] Navigate to Signup');

export const registerNewUser = createAction('[Login] Create Profile', props<{ userInfo: NewUserSignUpInfo }>());

export const registerNewUserSuccess = createAction('[Login] Create New Profile Success');
export const registerNewUserFailure = createAction(
  '[Login] Create New Profile Failure',
  props<{ error: Error | any }>(),
);

export const setShowAlertTrue = (state: LoginState): LoginState => ({
  ...state,
  showAlert: true,
});

export const setShowAlertFalse = (state: LoginState): LoginState => ({
  ...state,
  showAlert: false,
});

const reduce = createReducer(
  initialLoginState,
  on(showLoginAlert, setShowAlertTrue),
  on(hideLoginAlert, setShowAlertFalse),
);

export function loginReducer(state = initialLoginState, action: Action): LoginState {
  return reduce(state, action);
}

export const getLoginState = (state: AppState): LoginState => state.login;

export const mapToShowAlert = (state: LoginState): boolean => state.showAlert;

export const showAlert = createSelector(getLoginState, mapToShowAlert);
