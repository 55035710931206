import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { from, interval } from 'rxjs';
import { delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../state';
import { claimsAndTokenRetrieved } from './auth-connect.actions';
import {
  authenticated,
  authenticatedUserMetadataRetrieved,
  authSubjectChanged,
  authSubjectChecked,
  claimsInvalidated,
  claimsReverifying,
  loggedOut,
} from './auth.actions';
import { authenticatedClaims, hasToken, isAuthenticated } from './auth.selectors';
import { AuthUserService } from './user.service';


@Injectable()
export class AuthEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>, // NOTE: In this case, store MUST have generic type!!!
    private readonly nav: NavController,
    private readonly authUser: AuthUserService,
  ) {}

  checkLastSub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticated, claimsAndTokenRetrieved),
      switchMap(({ claims }) =>
        from(this.authUser.getSub()).pipe(
          tap(sub => console.log('Last sub:', sub)),
          map(sub => [claims, sub] as const),
        ),
      ),
      tap(([claims, sub]) => console.log('Last sub:', sub, 'Current sub:', claims.sub)),
      map(([claims, sub]) => authSubjectChecked({ claims, changed: claims.sub !== sub })),
    ),
  );

  updateSub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authSubjectChecked),
      filter(({ changed }) => changed),
      tap(({ claims }) => this.authUser.setSub(claims.sub)),
      map(({ claims }) => authSubjectChanged({ claims })),
    ),
  );

  loadAuthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticated, claimsAndTokenRetrieved),
      switchMap(({ claims, token }) => this.authUser.getUserById(claims.sub)),
      map(user => authenticatedUserMetadataRetrieved({ user })),
    ),
  );

  finalizeLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter(({ payload: { routerState } }: RouterNavigatedAction) => routerState.url === '/logout'),
      map(() => loggedOut()),
    ),
  );

  reverifyClaims$ = createEffect(() =>
    interval(30000).pipe(
      withLatestFrom(this.store.select(hasToken)),
      filter(([, has]) => has),
      withLatestFrom(this.store.select(authenticatedClaims)),
      filter(([, claims]) => !!claims),
      map(() => claimsReverifying()),
    ),
  );

  checkClaimsValidity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimsReverifying),
      withLatestFrom(this.store.select(hasToken)),
      filter(([, has]) => !!has),
      withLatestFrom(this.store.select(isAuthenticated)),
      filter(([, is]) => !is),
      tap(() => console.log('Claims invalidated!')),
      map(() => claimsInvalidated()),
    ),
  );

  logoutOnClaimsInvalidation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimsInvalidated),
      delay(5000),
      withLatestFrom(this.store.select(isAuthenticated)),
      filter(([, is]) => !is),
      tap(() => console.log('Claims invalidated! Token invalidated, logging out.')),
      map(() => loggedOut()),
    ),
  );
}
