import { Component } from '@angular/core';
import { AppFacade } from '../../domains/app/app.facade';

@Component({
  selector: 'gc-live-update-modal',
  templateUrl: './live-update-modal.component.html',
  styleUrls: ['./live-update-modal.component.scss'],
})
export class LiveUpdateModalComponent {
  constructor(public app: AppFacade) {}
}
