import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { UnitsPendingComponent } from './units-pending.component';


@NgModule({
  declarations: [UnitsPendingComponent],
  imports: [CommonModule, IonicModule, PipesModule],
  exports: [UnitsPendingComponent],
})
export class UnitsPendingModule {
}
