<ion-item-sliding [disabled]="disabled" #sliding (click)="toggleOpenState(sliding)">
  <ion-item>
    <ion-label>
      <h2>{{ itemForm.name.value }}</h2>
      <p>
        {{ itemForm.numberOfUnits.value }} @ {{ itemForm.pricePerUnit.value | currency }} /
        {{ itemForm.unitOfMeasureSymbol.value }}
      </p>
    </ion-label>
    <ng-container slot="end">
      <ion-item lines="none">
        <strong>{{ itemForm.numberOfUnits.value * itemForm.pricePerUnit.value | currency }}</strong>
      </ion-item>
      <ion-buttons>
        <ion-button *ngIf="!disabled" fill="clear" color="dark">
          <ion-icon name="ellipsis-vertical" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ng-container>
  </ion-item>
  <ion-item-options side="end" (ionSwipe)="this.edit.emit(itemForm)">
    <ion-item-option color="danger" (click)="remove.emit(itemForm)">
      <ion-icon name="trash" slot="top"></ion-icon>
      Delete
    </ion-item-option>
    <ion-item-option color="gc-alt-green" (click)="edit.emit(itemForm)" expandable>
      <ion-icon name="create" slot="top"></ion-icon>
      Edit
    </ion-item-option>
  </ion-item-options>
</ion-item-sliding>
