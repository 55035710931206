<form [formGroup]="form" data-cy="installer-editor-tax-info-form">
  <fieldset>
    <legend>Tax Information</legend>
    <ion-item lines="full">
      <ion-input
        data-e2e="signup-first-name-input"
        formControlName="ein"
        labelPlacement="stacked"
        clearInput
        data-cy="installer-editor-form-name-input"
      >
        <div slot="label">
          <ion-text [color]="validated && form.ein.invalid ? 'danger' : undefined"> EIN *</ion-text>
        </div>
      </ion-input>
    </ion-item>

    <ion-item lines="full" *ngFor="let file of files" data-cy="installer-editor-tax-file-list">
      <ion-icon slot="start" name="document"></ion-icon>
      <ion-label [attr.data-cy]="'file-name-' + file.filename">{{ file.filename }}</ion-label>
    </ion-item>
    <gc-file-drop-target (filesDropped)="addDroppedFiles($event)" (filesAdded)="addFiles($event)"></gc-file-drop-target>
  </fieldset>
</form>
