import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { fromRGBToHex, RGBObject } from '../hex-color.pipe';

const defaultColor: RGBObject = {
  red: 128,
  green: 128,
  blue: 128,
};

export const createRGB = (value: string): RGBObject => ({
  red: parseInt(value.substring(1, 3), 16),
  green: parseInt(value.substring(3, 5), 16),
  blue: parseInt(value.substring(5, 7), 16),
});

@Component({
  selector: 'gc-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnDestroy {
  @Output() valueChanges = new EventEmitter<string>();
  color$ = new BehaviorSubject(defaultColor);
  formSubs!: Subscription;
  colorSub: Subscription;

  @Input() set form(form: UntypedFormControl) {
    this.formSubs?.unsubscribe();
    this.formSubs = new Subscription();
    this.nextColor(form.value);

    this.formSubs.add(form.valueChanges.pipe(distinctUntilChanged(), map(createRGB)).subscribe(this.color$));

    this.formSubs.add(this.valueChanges.subscribe(next => form.patchValue(next)));
  }

  // val is a Hex String #RRGGBB
  @Input() set value(val: string) {
    this.nextColor(val);
  }

  constructor() {
    this.colorSub = this.color$.pipe(map(fromRGBToHex)).subscribe(next => this.valueChanges.emit(next));
  }

  ngOnDestroy() {
    this.formSubs.unsubscribe();
    this.colorSub.unsubscribe();
  }

  nextColor(value: string) {
    if (!value || (value.length < 7 && !value.includes('#'))) {
      return;
    }
    this.color$.next(createRGB(value));
  }

  updateColor(event: Event & { target: { value: string } }, color: keyof RGBObject) {
    this.color$.next({
      ...this.color$.getValue(),
      [color]: event.target!.value,
    });
  }
}
