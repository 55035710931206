import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EmployeeInstaller } from '../../../domains/users';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';
import { Selectable } from '../../../shared/utils/types';
import { publishSearchCriteria, selectedInstallerIds } from '../store/ui.selectors';
import {
  employeeSearchCriteriaChanged,
  employeeSearchCriteriaCleared,
  employeeSelectionChanged,
  employeeSelectionCleared,
} from './publish-to-employees.actions';
import { selectableFilteredEmployees } from './publish-to-employees.selectors';

@Component({
  selector: 'gc-publish-to-employees-modal',
  templateUrl: './publish-to-employees-modal.component.html',
  styleUrls: ['./publish-to-employees-modal.component.scss'],
})
export class PublishToEmployeesModalComponent {
  @Input() workOrder!: WorkOrder;

  installers$: Observable<Selectable<EmployeeInstaller>[]> = this.store.select(selectableFilteredEmployees);
  selected$ = this.store.select(selectedInstallerIds).pipe(tap(selected => (this.selected = selected)));
  criteria$ = this.store.select(publishSearchCriteria);

  selected: EmployeeInstaller['id'][] = [];

  constructor(
    public readonly modalGC: ModalController,
    private readonly store: Store,
  ) {}

  async publish() {
    await this.modalGC.dismiss(this.selected, 'ok');
    this.clearSelection();
  }

  selectionChanged(selection: EmployeeInstaller['id'][]) {
    this.store.dispatch(employeeSelectionChanged({ selection }));
  }

  clearSelection() {
    this.store.dispatch(employeeSelectionCleared());
  }

  search(criteria: string) {
    this.store.dispatch(employeeSearchCriteriaChanged({ searchCriteria: criteria }));
  }

  searchCleared() {
    this.store.dispatch(employeeSearchCriteriaCleared());
  }
}
