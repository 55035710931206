<ng-container *ngIf="!loading; else loadingDisplay">
  <ion-grid *ngIf="matchedInstaller | isPendingResponse">
    <ion-row>
      <ion-col size="6">
        <ion-button
          color="danger"
          expand="block"
          fill="clear"
          size="large"
          mode="ios"
          (click)="declined.emit({ matchedInstaller: matchedInstaller, workOrder: workOrder })"
        >
          <ion-icon name="trash"></ion-icon>
          <strong>Decline</strong>
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button mode="ios" expand="block" size="large" color="gc-green" (click)="openModal(bidModal)">
          Bid Options
          <ion-icon slot="end" [name]="reason ? 'warning' : 'chevron-forward'"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-button
    mode="ios"
    expand="block"
    size="large"
    color="dark"
    *ngIf="!matchedInstaller"
    (click)="attemptMarkInterested(unqualifiedModal)"
  >
    Unlock Details
    <ion-icon *ngIf="!savingMatchedInstaller" slot="start"
              [name]="reason ? 'lock-closed' : 'lock-closed'"></ion-icon>
    <!--    TODO: I left the ternary above in place and made the lock show for both so we can see how `reason` is evaluated for future refactoring -->
    <ion-spinner *ngIf="savingMatchedInstaller" slot="start"></ion-spinner>
  </ion-button>

  <ion-grid *ngIf="matchedInstaller | hasDeclinedWorkOrder">
    <ion-row>
      <ion-col size="6">
        <ion-button
          *ngIf="matchedInstaller | hasDeclinedWorkOrder"
          expand="block"
          color="danger"
          size="large"
          mode="ios"
        >
          <strong>You Declined This</strong>
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button mode="ios" expand="block" size="large" color="gc-green" (click)="openModal(bidModal)">
          Bid Options
          <ion-icon slot="end" [name]="reason ? 'warning' : 'chevron-forward'"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>


  <ion-button
    *ngIf="matchedInstaller | hasShownInterest"
    class="ion-margin"
    expand="block"
    color="light"
    size="large"
    mode="ios"
  >
    <strong>Bid Submitted</strong>
  </ion-button>

  <ion-grid *ngIf="matchedInstaller | isNegotiating">
    <ion-row>
      <ion-col>
        <ion-button color="danger" expand="block" size="large" (click)="openModal(negotiateModal)" mode="ios">
          <strong>View Negotiations</strong>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>

<ng-template #loadingDisplay>
  <div class="flex ion-justify-content-center">
    <ion-spinner></ion-spinner>
  </div>
</ng-template>

<!-- Utility Modals -->

<ion-modal initialBreakpoint="0.8" [breakpoints]="[0, 0.5, 0.8]" #bidModal>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Bid Options</ion-title>
        <ion-buttons slot="end" (click)="bidModal.dismiss()">
          <ion-button>Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-button
        mode="ios"
        expand="block"
        color="gc-alt-green"
        size="large"
        class="ion-margin"
        (click)="bidModal.dismiss(); interested.emit({ matchedInstaller: matchedInstaller, workOrder: workOrder })"
      >
        <strong>Bid As-is</strong>
      </ion-button>
      <ion-button
        mode="ios"
        color="danger"
        expand="block"
        size="large"
        class="ion-margin"
        (click)="bidModal.dismiss(); negotiated.emit({ matchedInstaller: matchedInstaller, workOrder: workOrder })"
      >
        <strong>Negotiate</strong>
      </ion-button>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal initialBreakpoint="0.8" [breakpoints]="[0, 0.5, 0.8]" #negotiateModal>
  <ng-template>
    <ion-header>
      <ion-toolbar color="danger">
        <ion-title>
          Negotiate
          <ion-icon name="checkmark-circle" style="transform: translateY(5px)"></ion-icon>
        </ion-title>
        <ion-buttons slot="end" (click)="negotiateModal.dismiss()">
          <ion-button>Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-item class="ion-margin-top" lines="none">
        <ion-label class="ion-text-wrap ion-text-center">
          Your negotiation has been submitted. Update your bid price as needed. It is up to the Company to make the
          final decision as to whom they award the Work Order. If awarded, this Work Order will move to In Progress.
        </ion-label>
      </ion-item>

      <ion-button
        mode="ios"
        color="light"
        class="ion-margin"
        size="large"
        expand="block"
        (click)="
          negotiateModal.dismiss(); negotiated.emit({ matchedInstaller: matchedInstaller, workOrder: workOrder })
        "
      >
        Review Items
      </ion-button>

      <ion-button
        color="danger"
        class="ion-margin"
        size="large"
        fill="clear"
        expand="block"
        (click)="negotiateModal.dismiss(); canceled.emit({ matchedInstaller: matchedInstaller, workOrder: workOrder })"
      >
        <ion-icon name="close"></ion-icon>
        Cancel
      </ion-button>
    </ion-content>
  </ng-template>
</ion-modal>

<!-- Unqualified Sketch, should probably refactor out once flow is more defined -->
<ion-modal initialBreakpoint="0.8" [breakpoints]="[0, 0.5, 0.8]" #unqualifiedModal>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title slot="start">
          <ion-icon size="small" name="warning" color="dark"></ion-icon>
          Requirements
        </ion-title>
        <ion-buttons slot="end" (click)="unqualifiedModal.dismiss()">
          <ion-button>Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ng-container [ngSwitch]="reason">
        <div *ngSwitchCase="'approvedQualification'" class="ion-text-center">
          <ion-item class="ion-margin-top" lines="none">
            <ion-label class="ion-text-wrap ion-text-center">
              Before you bid on this Work Order, you'll need to finish your profile. If you're done and haven't been
              approved after a few days, <a href="mailto:support@gocarrera.com" target="_blank">shoot us an email.</a>
            </ion-label>
          </ion-item>

          <ion-button
            mode="ios"
            class="ion-margin"
            size="large"
            expand="block"
            (click)="unqualifiedModal.dismiss(); openProfile.emit()"
          >Manage Profile
          </ion-button>
        </div>

        <div *ngSwitchCase="'trainingQualification'" class="ion-text-center">
          <ion-item class="ion-margin-top" lines="none">
            <ion-label class="ion-text-wrap ion-text-center">
              This Work Order requires that you have
              <strong>{{ workOrder.productInstallationType!.type }}</strong> claimed on your profile. To bid on this
              Work Order, add <strong>{{ workOrder.productInstallationType!.type }}</strong>
              to your profile and any trainings you've completed.
            </ion-label>
          </ion-item>

          <ion-button
            mode="ios"
            fill="clear"
            class="ion-margin"
            size="large"
            expand="block"
            (click)="unqualifiedModal.dismiss(); openSkillsAndExperience.emit()"
          >Manage Profile
          </ion-button>
        </div>

        <div *ngSwitchCase="'hammerRatingQualification'" class="ion-text-center">
          <ion-item lines="none">
            <ion-label class="ion-text-wrap ion-text-center">
              Based on your personal Hammer Rating, you are not a match for this Work Order. However, you can still
              place a bid.
              <a
                target="_blank"
                href="https://gocarrera.com/resources/knowledge-base/why-am-i-not-a-match-for-a-work-order/"
              >Why am I not a match?</a
              >
            </ion-label>
          </ion-item>

          <ion-button
            mode="ios"
            class="ion-margin"
            size="large"
            expand="block"
            (click)="unqualifiedModal.dismiss(); attemptMarkInterested(unqualifiedModal, true)"
          >Proceed to View Details
          </ion-button>

          <ion-button
            mode="ios"
            fill="clear"
            class="ion-margin"
            size="large"
            expand="block"
            (click)="unqualifiedModal.dismiss(); openSkillsAndExperience.emit()"
          >Manage Profile
          </ion-button>

          <ion-button
            mode="ios"
            class="ion-margin"
            fill="clear"
            size="small"
            expand="block"
            (click)="
              unqualifiedModal.dismiss();
              openBrowser(
                'https://gocarrera.com/resources/training/?utm_source=gocarrera&utm_medium=app&utm_campaign=hammer_rating_filter'
              )
            "
          >
            How do I boost my Hammer Rating&trade;?
          </ion-button>
        </div>
      </ng-container>
    </ion-content>
  </ng-template>
</ion-modal>
