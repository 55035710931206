import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { first, map, tap } from 'rxjs/operators';
import { InvoiceAndPaymentFacade } from '~gc/domains/invoices/invoice-and-payment.facade';
import { WorkOrderRelationsFacade } from '~gc/domains/work-order-relations.facade';
import { WorkOrderFacade } from '~gc/domains/work/work-order/work-order.facade';
import { environment } from '../../../../environments/environment';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';
import { PendingInvoiceForm } from '../../invoicing/invoice.form';

@Component({
  selector: 'gc-create-invoice-modal',
  templateUrl: './create-invoice-modal.component.html',
  styleUrls: ['./create-invoice-modal.component.scss'],
})
export class CreateInvoiceModalComponent implements OnInit, OnDestroy {
  todaysDate = new Date();
  form!: PendingInvoiceForm;

  constructor(
    public modalGC: ModalController,
    public workOrders: WorkOrderFacade,
    public workOrderRelations: WorkOrderRelationsFacade,
    public invoiceAndPayment: InvoiceAndPaymentFacade,
  ) {}

  readonly maximumAllowableInvoiceAmount = environment.maximumAllowableInvoiceAmount;

  ngOnInit() {
    this.workOrderRelations.currentInvoiceableWorkOrderItems$
      .pipe(
        first(),
        map(items => new PendingInvoiceForm({ items })),
        tap(form => (this.form = form)),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.form.clearSubscriptions();
  }

  save(workOrder: WorkOrder) {
    if (this.form.invalid) {
      return;
    }
    const items = this.form.items.itemForms.map(item => item.updatedModel).filter(item => !!item.amountToInvoice);

    const pendingInvoice = {
      workOrderId: workOrder.id!, // TODO: Can we remove the non-nullish assertion here?
      items,
    };
    this.invoiceAndPayment.reviewInvoice(pendingInvoice);
  }
}
