import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceFile } from '../../../domains/invoices/invoice-file/invoice-file.model';
import { PendingFile } from '../../../domains/models/pending-file';
import { ProcessingFile } from '../../file-uploads/file.selectors';

@Component({
  selector: 'gc-file-upload-progress-popover',
  templateUrl: './file-upload-progress-popover.component.html',
  styleUrls: ['./file-upload-progress-popover.component.scss'],
})
export class FileUploadProgressPopoverComponent {
  @Input() header!: string;
  @Input() pendingFiles$!: Observable<ProcessingFile<PendingFile>[] | undefined>;

  trackUploadsBy(index: number, file: ProcessingFile<InvoiceFile>) {
    return file.id || (file as PendingFile).correlationId;
  }
}
