import { Injectable } from '@angular/core';
import { LoadAll, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { transformWhenType, whenType } from '../../../shared/utils/action-transformer.util';
import { ensureExists, ensureRequired } from '../../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { reviewInstallerFromPreview } from '../calendar/calendar.actions';
import { createMatchedInstallerSuccess } from '../matched-installer/matched-installer.state';
import { viewWorkOrder } from '../work-order-ui.actions';
import { awardToInstallerCompleted } from '../work-order/work-order.actions';
import { WorkOrder } from '../work-order/work-order.model';
import { currentWorkOrder } from '../work-order/work-order.state';
import { workOrderRefreshIntervalTicked } from '../work-polling.actions';
import { WorkOrderItem } from './work-order-item.model';
import { loadAllWorkOrderItems } from './work-order-item.state';

@Injectable()
export class WorkOrderItemEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadWorkOrderItemsForWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        viewWorkOrder,
        reviewInstallerFromPreview,
        // changeOrderItemVoided,
        // changeOrderModificationVoided,
        createMatchedInstallerSuccess,
      ),
      transformWhenType<string>(
        whenType(viewWorkOrder, reviewInstallerFromPreview, action => action.workOrder.id!), // TODO: Can we eliminate the non-nullish here???
        // whenType(changeOrderItemVoided, action => action.workOrderItem.workOrderId),
        // whenType(changeOrderModificationVoided, action => action.changeOrder.workOrderId),
        whenType(createMatchedInstallerSuccess, action => action.entity.workOrderId),
      ),
      ensureExists(workOrderId => !!workOrderId),
      map(
        workOrderId =>
          new LoadAll(WorkOrderItem, {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrderId,
            },
          }),
      ),
    ),
  );

  reloadWorkOrderItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(awardToInstallerCompleted, workOrderRefreshIntervalTicked),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureRequired(workOrder => !!workOrder?.id),
      map(workOrder =>
        loadAllWorkOrderItems({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            },
          },
        }),
      ),
    ),
  );
}
