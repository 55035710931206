<ion-header class="settlement-timing-header header-outline-none">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Settlement Timing</ion-title>
    <ion-buttons slot="end" *ngIf="dwolla.isLoading$ | push">
      <ion-button mode="md">
        <ion-spinner slot="icon-only" color="dark"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-grid *ngIf="settlementTimings.currentDetailedSettlementTiming$ | push as current">
    <ion-row *ngIf="dwolla.plaidPendingManualVerification$ | push" class="ion-padding-horizontal ion-padding-bottom">
      <div class="alert alert__warning ion-no-margin">
        <div class="flex-wrapper">
          <ion-icon name="time" class="icon"></ion-icon>
          <span>
            Once your micro-deposits have been verified you'll be able to use expanded settlement options as
            needed.
          </span>
        </div>
      </div>
    </ion-row>
    <ion-row>
      <ion-col *ngFor="let option of settlementTimings.detailedSettlementTimings$ | push">
        <ion-card
          (click)="settlementTimings.select(option)"
          [ngClass]="{ selected: current.id === option.id }"
          class="pricing-option"
        >
          <ion-card-content>
            <ion-thumbnail>
              <ion-img [src]="option.icon"></ion-img>
            </ion-thumbnail>
          </ion-card-content>
          <ion-card-header class="ion-text-center">
            <ion-text>
              <strong>{{ option.name }}</strong>
            </ion-text>
            <br />
            <ion-text class="text-smaller">
              {{ option.timing }}
            </ion-text>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="invoice">
      <ion-col>
        <ion-item lines="none">
          <ion-label>Expected Settlement Amount:</ion-label>
          <ion-text
            slot="end"
            *ngIf="invoice.invoiceAmount | expectedSettlementAmount: current.percentage as settlement"
          >
            {{ settlement | currency }}
          </ion-text>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-button
          class="ion-no-margin"
          *ngIf="current.available; else verify"
          color="gc-green"
          expand="block"
          mode="ios"
          (click)="modalGC.dismiss(current, 'submit')"
        >
          Submit Lien Release
        </ion-button>

        <ng-template #verify>
          <ion-button
            class="ion-no-margin"
            *ngIf="!current.available && (dwolla.plaidPendingManualVerification$ | push); else explain"
            color="gc-green"
            expand="block"
            [disabled]="dwolla.isLoading$ | push"
            mode="ios"
            (click)="dwolla.openPlaid()"
          >
            Verify Micro-Deposit Amounts
          </ion-button>

          <ng-template #explain>
            <ion-button
              class="ion-no-margin"
              color="dark"
              expand="block"
              [disabled]="dwolla.isLoading$ | push"
              mode="ios"
              (click)="settlementTimings.showSettlementTimingExplainModal()"
            >
              Verify Account
            </ion-button>
          </ng-template>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
