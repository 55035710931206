<div class="flex badge-wrap" data-cy="subtype-badge-area">
  <div class="flex-row" *ngFor="let productInstallationSubtype of subtypes">
    <div *ngIf="!!productInstallationSubtype.subtypeClaim">
      <ion-badge
        class="subtype-badge"
        color="light"
        mode="ios"
        slot="start"
        [attr.data-cy]="productInstallationSubtype.productInstallationSubtype.name + '-badge'"
        >{{ productInstallationSubtype.productInstallationSubtype.name }}
        <ion-icon
          *ngIf="showRemove"
          class="remove-icon"
          (click)="remove.emit(productInstallationSubtype.subtypeClaim)"
          name="close-circle-outline"
          data-cy="remove-subtype-button"
        ></ion-icon>
      </ion-badge>
    </div>
    <div *ngIf="!productInstallationSubtype.subtypeClaim"></div>
  </div>
</div>
