import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { UnitOfMeasure } from './unit-of-measure.model';
import { UnitOfMeasureFacadeBase } from './unit-of-measure.state';

@Injectable()
export class UnitOfMeasureFacade extends UnitOfMeasureFacadeBase {
  constructor(private store: Store<AppState>) {
    super(UnitOfMeasure, store);
  }
}
