import { Component, ViewChild } from '@angular/core';
import { IonInput, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';
import { InvoiceAndPaymentFacade } from '../../../domains/invoices';
import { InvoiceFilesFacade } from '../../../domains/invoices/invoice-file/invoice-file.facade';
import { reportsProgressForCurrentWorkOrder } from '../../../domains/invoices/invoice-ui.selectors';
import { WorkOrderRelationsFacade } from '../../../domains/work-order-relations.facade';
import { WorkOrderFacade } from '../../../domains/work/work-order/work-order.facade';
import { getFileDataUrl } from '../../../shared/utils/files';
import { HasTargetFiles, HasTargetValue } from '../../../shared/utils/types';

@Component({
  selector: 'gc-review-progress-modal',
  templateUrl: './review-progress-modal.component.html',
  styleUrls: ['./review-progress-modal.component.scss'],
})
export class ReviewProgressModalComponent {
  todaysDate = new Date();
  @ViewChild('fileInput') fileInput!: IonInput;

  reportsProgress$ = this.store.select(reportsProgressForCurrentWorkOrder);

  constructor(
    public modalGC: ModalController,
    public invoiceAndPayment: InvoiceAndPaymentFacade,
    public workOrders: WorkOrderFacade,
    public workOrderRelations: WorkOrderRelationsFacade,
    public invoiceFiles: InvoiceFilesFacade,
    public readonly auth: AuthFacade,
    public readonly store: Store,
  ) {}

  async chooseFiles(): Promise<void> {
    const input = await this.fileInput.getInputElement();
    input.click();
  }

  async addFiles($event: Event & HasTargetFiles & HasTargetValue): Promise<void> {
    const files = await Promise.all(
      Array.from($event.target.files).map(async (file: File) => ({
        filename: file.name,
        contentType: file.type,
        fileBlob: file,
        base64Url: await getFileDataUrl(file),
      })),
    );
    $event.target.value = null;

    files.forEach(file => this.invoiceFiles.queue(file));
  }
}
