import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { format } from 'date-fns';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';
import { Address } from '../../domains/locations';
import { PhoneNumber, PhoneNumberType } from '../../domains/phone-number';
import { buildPhoneNumber } from '../../domains/phone-number/utils';
import { InstallerTeamLead, ProfileFacade } from '../../domains/users';
import { formatPhoneNumber } from '../../shared/components/advanced-phone-number-manager/forms/new-phone-number.form';
import { AsyncAddressValidators } from '../../shared/validators/async-address.validators';

register();

@Component({
  selector: 'gc-unapproved-installer',
  templateUrl: './unapproved-installer.component.html',
  styleUrls: ['./unapproved-installer.component.scss'],
})
export class UnapprovedInstallerComponent implements OnInit {
  @Input() user!: InstallerTeamLead;

  readonly phoneMask: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };
  readonly maskPredicate: MaskitoElementPredicateAsync = async el => (el as HTMLIonInputElement).getInputElement();

  zipCodeForm!: UntypedFormControl;
  phoneNumberForm!: UntypedFormControl;
  consent!: UntypedFormControl;
  continueEnabled$!: Observable<boolean>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private profile: ProfileFacade,
  ) {}

  ngOnInit() {
    this.zipCodeForm = this.formBuilder.control(
      '',
      [Validators.required, Validators.minLength(5), Validators.maxLength(10)],
      [AsyncAddressValidators.knownValidZipCode],
    );
    this.phoneNumberForm = this.formBuilder.control('', [Validators.required, Validators.minLength(13)]);
    this.consent = this.formBuilder.control(false);

    this.continueEnabled$ = merge(
      of(true),
      this.zipCodeForm.statusChanges.pipe(map(() => this.zipCodeForm.invalid || this.zipCodeForm.pending)),
    );
  }

  submit() {
    if (this.phoneNumberForm.invalid || this.zipCodeForm.invalid) {
      return;
    }

    const phoneNumber = buildPhoneNumber(
      undefined,
      this.user.id,
      this.phoneNumberForm.value,
      PhoneNumberType.Mobile,
      this.consent.value,
    ) as PhoneNumber;

    const address: Partial<Address> = {
      postalCode: this.zipCodeForm.value,
      addressTypeId: 1,
    };

    this.profile.submitUnapprovedInstaller(phoneNumber, address);
  }
}
