import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '../../directives/directives.module';
import { FileDropTargetComponent } from './file-drop-target.component';



@NgModule({
  declarations: [
  
    FileDropTargetComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    IonicModule
  ],
  exports: [
    FileDropTargetComponent
  ]
})
export class FileDropTargetModule { }
