import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ConfigService } from '../../../shared/services/config.service';
import { WorkOrder } from './work-order.model';

@Injectable()
export class WorkOrderService {
  constructor(
    private http: HttpClient,
    private alerts: AlertController,
    private readonly config: ConfigService,
  ) {}

  shareWithUser(workOrderId: string, userId: string) {
    return this.http.post<WorkOrder>(`${this.config.host}/work-orders/${workOrderId}/share/${userId}`, {});
  }

  unShareWithUser(workOrderId: string, userId: string) {
    return this.http.delete<WorkOrder>(`${this.config.host}/work-orders/${workOrderId}/share/${userId}`);
  }
  
  async confirmDeletePendingWorkOrder(): Promise<any> {
    const alert = await this.alerts.create({
      header: 'Confirm Work Order Deletion',
      message: 'Are you sure you want to delete this Work Order? Each matched Installer will be notified.',
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        { text: 'Delete', role: 'delete', cssClass: 'button-text-red' },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }
}
