import { createAction, props } from '@ngrx/store';
import { Category } from '../../domains/training/category/category.model';
import { Training } from '../../domains/training/training/training.model';
import { TrainingClaimed } from '../../domains/training/trainings-claimed/trainings-claimed.model';

export const showClaimCategorizedTrainingModal = createAction(
  '[CORE: Installer Profile] (Show) Claim Categorized Training Modal',
  props<{ category: Category }>(),
);

export const trainingClaimed = createAction(
  '[CORE: Installer Profile] (Claimed) Training',
  props<{ training: Training; date: string }>(),
);
export const installTypeClaimIdSelected = createAction(
  '[CORE: Installer Profile] (Selected) Install Type Claim ID',
  props<{ installTypeClaimId: number }>(),
);

export const trainingClaimRemoved = createAction(
  '[CORE: Install Profile] (Removed) Training Claim',
  props<{ claim: TrainingClaimed }>(),
);

export const claimTrainingModalCancelled = createAction('[CORE: Installer Profile] (Cancelled) Claiming Training');

export const claimTrainingModalDone = createAction('[CORE: Installer Profile] (Done) Claiming Training');
