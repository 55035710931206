import { Pipe, PipeTransform } from '@angular/core';

export const hasPermissions = (permissions: string[], ...expected: string[]): boolean =>
  expected.every(arg => permissions.includes(arg));

@Pipe({ name: 'hasPermissions' })
export class HasPermissionsPipe implements PipeTransform {
  transform(permissions: string[], ...expected: string[]): boolean {
    return hasPermissions(permissions, ...expected);
  }
}

export const hasAnyPermission = (permissions: string[], ...expected: string[]): boolean =>
  expected.some(arg => permissions.includes(arg));

@Pipe({ name: 'hasAnyPermission' })
export class HasAnyPermissionPipe implements PipeTransform {
  transform(permissions: string[], ...expected: string[]): boolean {
    return hasAnyPermission(permissions, ...expected);
  }
}
