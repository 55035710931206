import { formatCurrency } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { AuthFacade } from '../../domains/app/auth/auth.facade';
import { Invoice } from '../../domains/invoices';
import { BankAccountFacade } from '../../domains/invoices/bank-account/bank-account.facade';
import { BankAccount } from '../../domains/invoices/bank-account/bank-account.model';
import { User } from '../../domains/users';
import { WorkOrder } from '../../domains/work/work-order/work-order.model';
import { DwollaFacade } from '../../shared/dwolla/dwolla.facade';

@Component({
  selector: 'gc-pay-invoice',
  templateUrl: './pay-invoice.component.html',
  styleUrls: ['./pay-invoice.component.scss'],
})
export class PayInvoiceComponent {
  @Input() workOrder!: WorkOrder;
  @Input() invoice!: Invoice;
  @Input() invoicer!: User;

  constructor(
    public auth: AuthFacade,
    public bankAccounts: BankAccountFacade,
    public modalGC: ModalController,
    public actionSheetController: ActionSheetController,
    public dwolla: DwollaFacade,
  ) {}

  async makePayment(bank: BankAccount) {
    if (this.invoice.invoiceAmount != null) {
      const actionSheet: HTMLIonActionSheetElement = await this.actionSheetController.create({
        buttons: [
          {
            text: `Pay ${formatCurrency(this.invoice.invoiceAmount, 'en', '$')} from ${bank.bankName}?`,
            handler: () => this.modalGC.dismiss(bank, 'dismiss'),
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => actionSheet.dismiss(),
          },
        ],
      });
      await actionSheet.present();
    }
  }
}
