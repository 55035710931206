import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity('SettlementTiming', { uriName: 'pricing' })
export class SettlementTiming {
  @Key id?: number;
  name!: string;
  timing!: string;
  icon!: string;
  description!: string;
  percentage!: number;
  isDefault!: boolean;
  showForPrivateConnections?: boolean;
  providers!: ('DWOLLA' | 'STRIPE')[];
}
