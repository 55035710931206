<ion-header>
  <ion-toolbar>
    <ion-title>Welcome to Go Carrera</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-text-center p-20">
    <ion-item lines="none">
      <ion-input
        fill="solid"
        mode="md"
        [helperText]="zipCodeForm.hasError('knownZipCode') ? 'Hmm, is that a valid ZIP Code?' : 'We can help you explore work in your area.'"
        errorText="Hmm, is that a valid ZIP Code?"
        [formControl]="zipCodeForm"
        label="Enter your ZIP Code&trade;"
        labelPlacement="floating"
        placeholder="Enter your ZIP Code&trade;"
        inputmode="numeric"
      ></ion-input>
    </ion-item>

    <ion-item lines="none" class="ion-margin-top">
      <ion-input
        [formControl]="phoneNumberForm"
        fill="solid"
        mode="md"
        placeholder="Enter your Mobile Number"
        label="Enter your Mobile Number"
        labelPlacement="floating"
        helperText="Receive new work alerts and live support."
        errorText="Hmm, is that a good mobile number?"
        inputmode="numeric"
        clearInput="true"
        [maskito]="phoneMask"
        [maskitoElement]="maskPredicate"
      ></ion-input>
    </ion-item>
    <ion-text class="p-20 flex flex-column text-left">
      By adding your mobile number, you agree to receive recurring automated transactional text (SMS) messages (e.g. new
      work opportunities, changes to awarded work) from us at this mobile number. Your consent here is not a condition
      of participation. Reply HELP for help and STOP to cancel. Message frequency varies. Message and data rates may
      apply.
    </ion-text>
    <ion-checkbox class="py-10 ion-text-wrap" [formControl]="consent" labelPlacement="end" justify="start"
    >I want to receive SMS from time to time.
    </ion-checkbox>
    <ion-button
      mode="ios"
      size="large"
      class="ion-margin-top"
      color="gc-green"
      expand="block"
      [disabled]="phoneNumberForm.invalid || zipCodeForm.invalid"
      (click)="submit()"
    >
      Get Started
    </ion-button>
    <ion-text>Visit <em>Settings > My Profile > Details</em> to verify your number.</ion-text>
  </div>
</ion-content>
