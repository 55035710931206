import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { defer, iif, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { GoogleMapsService } from '../services/google-maps.service';

export interface AddressParams {
  state: string;
  city: string;
  address: string;
  zip: string;
}

export interface AddressFields {
  state: AbstractControl<string> | null;
  city: AbstractControl<string> | null;
  address: AbstractControl<string> | null;
  zip: AbstractControl<string> | null;
}

const DefaultCache: AddressParams = { state: '', city: '', address: '', zip: '' };

// TODO: Reevaluate the typing here, and improve the robustness fo this validator!

export const latLng =
  (maps: GoogleMapsService) =>
  (
    cityField = 'city',
    stateField = 'state',
    addressField = 'addressLine1',
    zipField = 'postalCode',
    inputCache: AddressParams = { ...DefaultCache },
  ): AsyncValidatorFn =>
  <
    TForm extends AddressFields & {
      location: AbstractControl<{ type: string; coordinates: number[] }> | null;
    },
  >(
    target: AbstractControl<TForm>,
  ) =>
    of(target).pipe(
      delay(500),

      map(
        (targetForm: AbstractControl<TForm>): AddressFields => ({
          state: targetForm.get(stateField),
          city: targetForm.get(cityField),
          address: targetForm.get(addressField),
          zip: targetForm.get(zipField),
        }),
      ),
      switchMap((fields: AddressFields) =>
        iif(
          () =>
            Object.keys(DefaultCache).every(key => fields[key as keyof AddressFields]?.valid) &&
            Object.keys(DefaultCache).some(
              key => fields[key as keyof AddressFields]?.value !== inputCache[key as keyof AddressParams],
            ),
          defer(() =>
            maps.getLocation(
              fields.address?.value ?? '',
              fields.city?.value ?? '',
              fields.state?.value ?? '',
              fields.zip?.value ?? '',
            ),
          ).pipe(
            tap(
              () =>
                (inputCache = {
                  city: fields.city!.value ?? '',
                  state: fields.state!.value ?? '',
                  address: fields.address!.value ?? '',
                  zip: fields.zip!.value ?? '',
                }),
            ),
            map(res => res.results[0].geometry.location),
            tap(
              ({ lat, lng }) =>
                target.get('location')?.setValue(
                  {
                    type: 'Point',
                    coordinates: [lng, lat],
                  } as any, // TODO: Figure out how to integrate with the new Strictly Typed Forms!!!
                  { onlySelf: true },
                ),
            ),

            map(() => null),

            catchError(err =>
              of({ latLng: true })
                .pipe
                // tap(() => console.error(err))
                (),
            ),
          ),
          of(null),
        ),
      ),
    );
