import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { loadUserSuccess } from '../../domains/users/user/user.state';
import { installerInviteProcessCompleting } from './company-installer-invites.actions';
import { internalInviteProcessCompleting } from './company-internal-invites.actions';
import { InvitesUIService } from './invites-ui.service';
import { inviteProcessAborted, inviteProcessCompleted } from './invites.actions';

@Injectable()
export class InvitesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly ui: InvitesUIService,
  ) {}

  completeInviteProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(internalInviteProcessCompleting, installerInviteProcessCompleting),
      switchMap(({ correlationId: expectedCorrelationId }) =>
        // eslint-disable-next-line @ngrx/avoid-cyclic-effects
        this.actions$.pipe(
          ofType(loadUserSuccess),
          first(({ correlationId }) => correlationId === expectedCorrelationId),
        ),
      ),
      switchMap(() => this.ui.dismissCompanyInviteModal()),
      map(() => inviteProcessCompleted()),
    ),
  );

  abortInviteProcess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(inviteProcessAborted),
        switchMap(() => this.ui.dismissCompanyInviteModal()),
        tap(() => console.log('Invite process aborting...')),
      ),
    { dispatch: false },
  );
}
