export type MonadicFunction<T, U> = (value: T) => U;

export function compose<T, U>(fn: MonadicFunction<T, U>): MonadicFunction<T, U>;
export function compose<T, T1, U>(fn: MonadicFunction<T, T1>, fn1: MonadicFunction<T1, U>): MonadicFunction<T, U>;
export function compose<T, T1, T2, U>(
  fn: MonadicFunction<T, T1>,
  fn1: MonadicFunction<T1, T2>,
  fn2: MonadicFunction<T2, U>,
): MonadicFunction<T, U>;
export function compose<T, T1, T2, T3, U>(
  fn: MonadicFunction<T, T1>,
  fn1: MonadicFunction<T1, T2>,
  fn2: MonadicFunction<T2, T3>,
  fn3: MonadicFunction<T3, U>,
): MonadicFunction<T, U>;
export function compose<T, T1, T2, T3, T4, U>(
  fn: MonadicFunction<T, T1>,
  fn1: MonadicFunction<T1, T2>,
  fn2: MonadicFunction<T2, T3>,
  fn3: MonadicFunction<T3, T4>,
  fn4: MonadicFunction<T4, U>,
): MonadicFunction<T, U>;
export function compose<T, T1, T2, T3, T4, T5, U>(
  fn: MonadicFunction<T, T1>,
  fn1: MonadicFunction<T1, T2>,
  fn2: MonadicFunction<T2, T3>,
  fn3: MonadicFunction<T3, T4>,
  fn4: MonadicFunction<T4, T5>,
  fn5: MonadicFunction<T5, U>,
): MonadicFunction<T, U>;
export function compose<T, T1, T2, T3, T4, T5, T6, U>(
  fn: MonadicFunction<T, T1>,
  fn1: MonadicFunction<T1, T2>,
  fn2: MonadicFunction<T2, T3>,
  fn3: MonadicFunction<T3, T4>,
  fn4: MonadicFunction<T4, T5>,
  fn5: MonadicFunction<T5, T6>,
  fn6: MonadicFunction<T6, U>,
): MonadicFunction<T, U>;
export function compose<T, T1, T2, T3, T4, T5, T6, T7, U>(
  fn: MonadicFunction<T, T1>,
  fn1: MonadicFunction<T1, T2>,
  fn2: MonadicFunction<T2, T3>,
  fn3: MonadicFunction<T3, T4>,
  fn4: MonadicFunction<T4, T5>,
  fn5: MonadicFunction<T5, T6>,
  fn6: MonadicFunction<T6, T7>,
  fn7: MonadicFunction<T7, U>,
): MonadicFunction<T, U>;
export function compose<T, T1, T2, T3, T4, T5, T6, T7, T8, U>(
  fn: MonadicFunction<T, T1>,
  fn1: MonadicFunction<T1, T2>,
  fn2: MonadicFunction<T2, T3>,
  fn3: MonadicFunction<T3, T4>,
  fn4: MonadicFunction<T4, T5>,
  fn5: MonadicFunction<T5, T6>,
  fn6: MonadicFunction<T6, T7>,
  fn7: MonadicFunction<T7, T8>,
  fn8: MonadicFunction<T8, U>,
): MonadicFunction<T, U>;
export function compose<T, T1, T2, T3, T4, T5, T6, T7, T8, T9, U>(
  fn: MonadicFunction<T, T1>,
  fn1: MonadicFunction<T1, T2>,
  fn2: MonadicFunction<T2, T3>,
  fn3: MonadicFunction<T3, T4>,
  fn4: MonadicFunction<T4, T5>,
  fn5: MonadicFunction<T5, T6>,
  fn6: MonadicFunction<T6, T7>,
  fn7: MonadicFunction<T7, T8>,
  fn8: MonadicFunction<T8, T9>,
  fn9: MonadicFunction<T9, U>,
): MonadicFunction<T, U>;

export function compose(
  ...fns: [...MonadicFunction<any, any>[], MonadicFunction<any, any>]
): MonadicFunction<any, any> {
  return data => fns.reduce((v, fn) => fn(v), data);
}
