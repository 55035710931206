import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceableWorkOrderItem } from '../../domains/models/invoiceable-work-order-item';

@Pipe({
  name: 'hasRemaining',
})
export class HasRemainingPipe implements PipeTransform {
  transform(item: InvoiceableWorkOrderItem): boolean {
    return !!item.remaining;
  }
}
