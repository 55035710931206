import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthFacade } from '../../domains/app/auth/auth.facade';
import { ConfigService } from '../services/config.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  endpoints = ['/users/signup', 'healthz', '/healthz/info'];

  constructor(
    private auth: AuthFacade,
    private readonly config: ConfigService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(null).pipe(
      withLatestFrom(this.auth.token$),
      switchMap(([, token]) =>
        iif(
          () => this.shouldHandleRoute(req.url),
          of(
            req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            }),
          ),
          of(req),
        ),
      ),
      switchMap(actualReq => next.handle(actualReq)),
      catchError(err => throwError(err)),
    );
  }

  private shouldHandleRoute(url: string): boolean {
    return url.startsWith(this.config.host) && !this.endpoints.some(endpoint => url.endsWith(endpoint));
  }
}
