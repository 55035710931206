import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { currentWorkOrderItemGroups } from '../../work-order-item-group.selectors';
import { negotiableWorkOrderItems, workOrderUnderNegotiation } from '../../work-order-item-negotiation.selectors';
import { currentDetailedWorkOrderItems } from '../../work-order-items-detail.selectors';
import { WorkOrderItem } from './work-order-item.model';
import { WorkOrderItemFacadeBase } from './work-order-item.state';

@Injectable()
export class WorkOrderItemFacade extends WorkOrderItemFacadeBase {
  negotiableWorkOrderItems$ = this.store.select(negotiableWorkOrderItems);
  workOrderUnderNegotiation$ = this.store.select(workOrderUnderNegotiation);

  workOrderItemGroups$ = this.store.select(currentWorkOrderItemGroups);
  currentWorkOrderItems$ = this.store.select(currentDetailedWorkOrderItems);

  constructor(private store: Store<AppState>) {
    super(WorkOrderItem, store);
  }
}
