import { Pipe, PipeTransform } from '@angular/core';
import { isInstallerLead, isInstallerMember, User } from '../../domains/users';

export const isMyTeamMember = (user: User, authUser: User): boolean =>
  isInstallerMember(user) && isInstallerLead(authUser) && user.installerTeamLeadId === authUser.id;

@Pipe({ name: 'isMyTeamMember' })
export class IsMyTeamMemberPipe implements PipeTransform {
  transform(user: User, authUser: User): boolean {
    return isMyTeamMember(user, authUser);
  }
}
