import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { currentLatLong, mapsApiLoaded, mapsLoading, mapWorkOrders, searchRadius } from './work-order-map.selectors';
import { LocationCoords, mapBoundsChanged, mapCenterChanged, mapPlaceFound } from './work-order-map.state';

@Injectable()
export class WorkOrderMapFacade {
  currentLatLong$ = this.store.select(currentLatLong);
  mapsApiLoaded$ = this.store.select(mapsApiLoaded);
  mapWorkOrders$ = this.store.select(mapWorkOrders);
  searchRadius$ = this.store.select(searchRadius);
  mapsLoading$ = this.store.select(mapsLoading);

  constructor(private store: Store<AppState>) {}

  mapPlaceFound(place: LocationCoords) {
    this.store.dispatch(mapPlaceFound({ place }));
  }

  mapBoundsChanged(place: LocationCoords, radius: number) {
    this.store.dispatch(mapBoundsChanged({ place, searchRadius: radius }));
  }

  mapCenterChanged() {
    this.store.dispatch(mapCenterChanged());
  }
}
