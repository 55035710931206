import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { UserType } from '../../../../domains/users';
import { DisplayGroupAllowance, DisplayOptions, WorkOrderItemGroup } from '../../../../domains/work-order-item-group.selectors';
import { WorkOrder } from '../../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-change-order-approval',
  templateUrl: './change-order-approval.component.html',
  styleUrls: ['./change-order-approval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderApprovalComponent {
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];
  @Input() userType!: string;
  @Input() userId!: string;
  @Input() group!: WorkOrderItemGroup;
  @Input() item!: DetailedWorkOrderItem;
  @Input() workOrder!: WorkOrder;
  @Output() called = new EventEmitter<WorkOrder>();
  @Output() accepted = new EventEmitter<DetailedWorkOrderItem>();
  @Output() approved = new EventEmitter<DetailedWorkOrderItem>();
  @Output() declined = new EventEmitter<DetailedWorkOrderItem>();
}
