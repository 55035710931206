import { Capacitor } from '@capacitor/core';
import { MonoTypeOperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DetailedInstaller } from './models/detailed-installer';
import { HasCompanyId, HasInstallerId, HasUserId, HasWorkOrderId, HasWorkOrderItemId } from './types';
import { User } from './users';
import { WorkOrderItem } from './work/work-order-item/work-order-item.model';
import { WorkOrder } from './work/work-order/work-order.model';

export const ifAnyOfHaveWorkOrder = (haves: HasWorkOrderItemId[]) => (item: WorkOrderItem) =>
  !!haves.find(has => has.workOrderItemId === item.id);

export const hasSomeChildItems =
  <T, A, C extends Array<A>>(childItems: (item?: T) => C | undefined) =>
  (items?: T[]): boolean =>
    items?.some((item: T) => (childItems(item)?.length ?? 0) > 0) ?? false;

export const chooseCurrentOrAll = <T>(all: T[], current: T): T[] => (current ? [current] : all);

export const companyIdsMatch = (a: HasCompanyId, b: HasCompanyId): boolean => a?.companyId === b?.companyId;

export const whereMatchesUser = (user?: User) => (has: HasUserId) => user?.id === has.userId;

export const whereMatchesWorkOrder = (workOrder: WorkOrder) => (has: HasWorkOrderId) =>
  workOrder?.id === has.workOrderId;

export const whereMatchesWorkOrderItem = (workOrderItem: WorkOrderItem) => (has: HasWorkOrderItemId) =>
  workOrderItem?.id === has.workOrderItemId;

export const whereWorkOrderItemMatches = (has: HasWorkOrderItemId) => (workOrderItem: WorkOrderItem) =>
  has?.workOrderItemId === workOrderItem.id;

export const whereMatchesInstallers = (installers: DetailedInstaller[]) => (has: HasInstallerId) =>
  installers.some(installer => installer.id === has?.installerId);

export const matchingInstallerFor = (has: HasInstallerId) => (installer: DetailedInstaller) =>
  installer.id === has?.installerId;

export const pickOrDefault =
  <T extends Array<any>, R>(index: number, def: R) =>
  (source?: T): R =>
    source ? (source[index] as R) : def;

export const pluckOrDefault =
  <T, K extends keyof T, R extends T[K]>(prop: K, def: R) =>
  (source: T): R =>
    source ? (source[prop] as R) : def;

export interface HasNegotiableDate {
  currentBidStartDate?: string;
  currentAskStartDate: string;
  isAcceptedByInstaller: boolean;
}

export const isViableDateNegotiation = (
  negotiation: HasNegotiableDate | undefined,
  workOrder: WorkOrder | undefined,
): boolean =>
  !!negotiation?.currentBidStartDate ||
  negotiation?.isAcceptedByInstaller ||
  (!!negotiation?.currentAskStartDate && negotiation?.currentAskStartDate !== workOrder?.scheduledStartDate);

export interface HasName {
  name: string;
}

export const byNameAscending = (a: HasName, b: HasName) =>
  (a.name ?? '').localeCompare(b.name ?? '', undefined, { numeric: true });

export const onNativePlatformOnly = <T>(): MonoTypeOperatorFunction<T> =>
  filter((value: T) => Capacitor.isNativePlatform());

export const removeProperty = <T extends Record<string, any>>(obj: T, property: keyof T) =>
  obj[property] !== undefined
    ? Object.keys(obj)
        .filter(key => key !== property)
        .reduce(
          (edited, key) => ({
            ...edited,
            [key]: obj[key],
          }),
          {},
        )
    : obj;
