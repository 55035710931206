import { Injectable } from '@angular/core';
import { getKeyFromModel, LoadAll, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { excludeRoles } from '../../../shared/utils/roles.operators';
import { authenticatedRole } from '../../app/auth/auth.selectors';
import { EmptyKey } from '../../entity.service.utils';
import { AppState } from '../../state';
import { viewWorkOrder, viewWorkOrderFromModal } from '../../work/work-order-ui.actions';
import { WorkOrder } from '../../work/work-order/work-order.model';
import { currentWorkOrder } from '../../work/work-order/work-order.state';
import { addNewInvoice } from '../invoice-and-payment.actions';
import { Invoice, InvoiceStatus } from '../invoice/invoice.model';
import { updateInvoiceSuccess } from '../invoice/invoices.state';
import { InvoiceItem } from './invoice-item.model';
import { deleteInvoiceItemSuccess } from './invoice-item.state';

@Injectable()
export class InvoiceItemEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  loadWorkOrderInvoiceItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrder, viewWorkOrderFromModal),
      excludeRoles(this.store.select(authenticatedRole), 'installerMember', 'jumpstarter'),
      map(
        ({ workOrder }) =>
          new LoadAll(InvoiceItem, {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
              [uriNameOfEntityOrEmpty(Invoice)]: EmptyKey,
            },
          }),
      ),
    ),
  );

  loadInvoiceItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNewInvoice),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(
        ([action, workOrder]) =>
          new LoadAll(InvoiceItem, {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: getKeyFromModel(WorkOrder, workOrder),
              [uriNameOfEntityOrEmpty(Invoice)]: EmptyKey,
            },
          }),
      ),
    ),
  );

  removeInvoiceItemsOnVoid$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(updateInvoiceSuccess),
      filter(({ entity }) => entity.status === InvoiceStatus.Voided),
      switchMap(
        ({ entity }) =>
          // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
          entity.invoiceItems?.map(item => deleteInvoiceItemSuccess({ entity: item as InvoiceItem })) ?? [],
      ),
    ),
  );
}
