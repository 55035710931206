import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { ItemChooserOption } from './item-chooser-option.model';

@Component({
  selector: 'gc-item-chooser-list',
  templateUrl: './item-chooser-list.component.html',
  styleUrls: ['./item-chooser-list.component.scss'],
})
export class ItemChooserListComponent<T> {
  @ViewChildren('addButton') addButtons!: QueryList<ElementRef>;
  @Input() items!: ItemChooserOption<T>[];

  @Input() noItemsMessage?: string;

  @Output() added = new EventEmitter<{ value: T; $event: MouseEvent }>();

  add(item: ItemChooserOption<any>, $event: MouseEvent): void {
    this.added.emit({ value: item.value, $event: $event });
    const clickedButton = this.addButtons.find(
      button => button && button.nativeElement && button.nativeElement.contains($event.target),
    );
    if (clickedButton) {
      clickedButton.nativeElement.focus();
    }
  }
}
