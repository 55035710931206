import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'gc-default-image,[gcDefaultImage]',
})
export class DefaultImageDirective {
  constructor(private el: ElementRef) {}

  // tslint:disable-next-line:no-input-rename
  @Input() defaultImage = '/assets/images/person-circle.svg';

  @HostListener('error')
  private onError() {
    this.el.nativeElement.src = this.defaultImage;
  }
}
