import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons/faCircleCheck';
import { ChangeOrder, ChangeOrderStatus } from '~gc/domains/change-orders/change-order/change-order.model';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { DisplayGroupAllowance, DisplayOptions } from '../../../../domains/work-order-item-group.selectors';
import { WorkOrder } from '../../../../domains/work/work-order/work-order.model';


@Component({
  selector: 'gc-item-change-orders-detail',
  templateUrl: './item-change-orders-detail.component.html',
  styleUrls: ['./item-change-orders-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemChangeOrdersDetailComponent {
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];
  @Input() userType!: string;
  @Input() workOrder!: WorkOrder;
  @Input() item!: DetailedWorkOrderItem;
  @Input() expanded!: DetailedWorkOrderItem;
  @Input() role!: string;
  @Output() collapsed = new EventEmitter();
  @Output() voided = new EventEmitter<ChangeOrder>();
  @Output() accepted = new EventEmitter<ChangeOrder>();
  @Output() pmCalled = new EventEmitter<WorkOrder>();

  trackChangeOrderBy(index: number, item: ChangeOrder) {
    return item.id;
  }

  protected readonly faCircleCheck = faCircleCheck;
  protected readonly ChangeOrderStatus = ChangeOrderStatus;
}
