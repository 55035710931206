import { Component, Input } from '@angular/core';
import { State } from '../../../../domains/locations';
import { AddressForm } from '../../../utils/typed-form-group';

@Component({
  selector: 'gc-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent {
  @Input() compact = false;
  @Input() validated = false;
  @Input() disabled = false;
  @Input() addressForm!: AddressForm;
  @Input() states!: State[];
}
