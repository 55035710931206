import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LetModule } from '@rx-angular/template/let';
import { ItemChooserListModule } from '../../shared/components/item-chooser-list/item-chooser-list.module';
import { ClaimProductInstallationSubtypesModalComponent } from './claim-product-installation-subtypes-modal.component';
import { ClaimProductInstallationSubtypesModalEffects } from './claim-product-installation-subtypes-modal.effects';
import { MODAL_NAME } from './store/modal.name';
import { reducer } from './store/modal.state';



@NgModule({
  declarations: [
    ClaimProductInstallationSubtypesModalComponent
  ],
  imports: [
    CommonModule,
    ItemChooserListModule,
    IonicModule,
    LetModule,
    StoreModule.forFeature(MODAL_NAME, reducer),
    EffectsModule.forFeature([ClaimProductInstallationSubtypesModalEffects])
  ]
})
export class ClaimProductInstallationSubtypesModalModule { }
