export const ROLES_CLAIM_KEY = 'https://app.gocarrera.com/user_authorization';

export interface Claims {
  [ROLES_CLAIM_KEY]: {
    roles: string[];
  };
  permissions: string[];
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  nbf: number;
  at_hash: string;
  nonce: string;
}
