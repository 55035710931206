import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Payment',
  uriName: 'payments',
})
export class Payment {
  @Key id?: number;
  bankAccountId?: string;
  invoiceId!: string | number;
  stripeTransactionId?: string;
  status!: PaymentStatus;
  createdOn!: string; // (Date)
  error?: {
    code: string;
    message: string;
    createdOn: string; // (Date)
  };
}

export enum PaymentStatus {
  processing = 'PROCESSING',
  paid = 'PAID',
  completed = 'COMPLETED',
  failed = 'FAILED',
}
