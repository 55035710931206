import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { ProductInstallationType } from './product-installation-types.model';

export const {
  initialState: initialProductInstallationTypeState,
  facade: ProductInstallationTypeFacadeBase,
  selectors: {
    selectAll: allProductInstallationTypes,
    selectEntities: productInstallationTypeEntities,
    selectCurrentEntity: currentProductInstallationType,
  },
  actions: {
    loadAllIfNecessary: loadAllProductInstallationTypesIfNecessary,
    select: selectProductInstallType,
    deselect: deselectProductInstallType,
  },
} = buildState(ProductInstallationType);

export function productInstallationTypeReducer(
  state = initialProductInstallationTypeState,
): IEntityState<ProductInstallationType> {
  return state;
}
