import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { applicableInvoiceableWorkOrderItems } from '~gc/domains/invoices/invoice-and-payment.selectors';
import { installerNegotiation } from '~gc/domains/work-order-item-negotiation.selectors';
import { AppState } from './state';
import {
  detailedInvoiceItemsForCurrentInvoice,
  invoiceBadgeMap,
  sortedTotalledInvoicesForWorkOrder,
} from './work-order-invoices.selectors';
import { workOrderItemDisplayGroupAllowance, workOrderItemDisplayMode } from './work-order-item-group.selectors';
import { userWorkOrderQualifications } from './work-order-qualifications.selectors';
import {
  currentWorkOrderCompany,
  currentWorkOrderInstaller,
  currentWorkOrderMatchedInterestedInstallerCount,
  currentWorkOrderMatchedInterestedInstallers,
  currentWorkOrderMatchedInterestedNonPendingInstallerCount,
  currentWorkOrderMatchedInterestedNonPendingInstallerDetails,
  userAllowedPrivateDetails,
} from './work-order-users.selectors';

@Injectable()
export class WorkOrderRelationsFacade {
  userWorkOrderQualifications$ = this.store.select(userWorkOrderQualifications);
  totalledInvoicesForWorkOrder$ = this.store.select(sortedTotalledInvoicesForWorkOrder);
  invoiceBadgeMap$ = this.store.select(invoiceBadgeMap);
  // negotiatingInstallersForCurrentWorkOrder$ = this.store.select(negotiatingInstallersForCurrentWorkOrder);
  // managedWorkOrderHeaders$ = this.store.select(managedWorkOrderHeaders);
  currentInvoiceableWorkOrderItems$ = this.store.select(applicableInvoiceableWorkOrderItems);
  currentWorkOrderCompany$ = this.store.select(currentWorkOrderCompany);

  detailedInvoiceItemsForCurrentInvoice$ = this.store.select(detailedInvoiceItemsForCurrentInvoice);
  // currentWorkOrderInstallType$ = this.store.select(currentWorkOrderInstallType);
  // currentWorkOrderFacilityType$ = this.store.select(currentWorkOrderFacilityType);
  // currentWorkOrderMatchedInstallers$ = this.store.select(currentWorkOrderMatchedInstallers);
  currentWorkOrderInterestedMatchedInstallerCount$ = this.store.select(currentWorkOrderMatchedInterestedInstallerCount);
  currentWorkOrderMatchedInterestedNonPendingInstallerCount$ = this.store.select(
    currentWorkOrderMatchedInterestedNonPendingInstallerCount,
  );

  currentWorkOrderMatchedInterestedNonPendingInstallerDetails$ = this.store.select(
    currentWorkOrderMatchedInterestedNonPendingInstallerDetails,
  );
  currentWorkOrderMatchedInterestedInstallers$ = this.store.select(currentWorkOrderMatchedInterestedInstallers);
  currentWorkOrderInstaller$ = this.store.select(currentWorkOrderInstaller);
  userAllowedPrivateDetails$: Observable<boolean> = this.store.select(userAllowedPrivateDetails);
  installerNegotiation$ = this.store.select(installerNegotiation);
  // workOrderNegotiationItems$ = this.store.select(workOrderNegotiationItems);

  workOrderItemDisplayMode$ = this.store.select(workOrderItemDisplayMode);

  workOrderItemDisplayGroups$ = this.store.select(workOrderItemDisplayGroupAllowance);

  constructor(private store: Store<AppState>) {}
}
