import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { showSettlementTimingExplainModal } from '~gc/domains/invoices/settlement-timing/settlement-timing.actions';
import {
  applicableDetailedSettlementTimings,
  currentDetailedSettlementTiming,
  detailedSettlementTimings, detailedSettlementTimingsForPrivateConnections, detailedSettlementTimingsForPublicConnections,
} from '~gc/domains/invoices/settlement-timing/settlement-timing.selectors';
import { SettlementTiming } from './settlement-timing.entity';
import { SettlementTimingFacadeBase } from './settlement-timing.state';

@Injectable()
export class SettlementTimingFacade extends SettlementTimingFacadeBase {
  currentDetailedSettlementTiming$ = this.store.select(currentDetailedSettlementTiming);
  detailedSettlementTimings$ = this.store.select(applicableDetailedSettlementTimings);

  constructor(private store: Store) {
    super(SettlementTiming, store);
  }

  showSettlementTimingExplainModal() {
    this.store.dispatch(showSettlementTimingExplainModal());
  }
}
