<form
  (ngSubmit)="added.emit({ tag: { value: tagName.value, name: 'workorder' }, workOrder: workOrder }); tagName.reset()"
>
  <div class="ion-margin">
    <ion-item *ngIf="permissions | hasPermissions: 'work-order.tags:manage'" style="margin-bottom: 10px">
      <ion-input [formControl]="tagName" placeholder="Add Tag(s)"></ion-input>
      <ion-button
        fill="clear"
        color="dark"
        (click)="
          added.emit({ tag: { value: tagName.value, name: 'workorder' }, workOrder: workOrder }); tagName.reset()
        "
        [disabled]="tagName.invalid"
      >
        Apply
      </ion-button>
    </ion-item>

    <ion-chip *ngFor="let tag of tags">
      {{ tag.value }}
      <ion-icon *ngIf="permissions | hasPermissions: 'work-order.tags:manage'" name="close" (click)="deleted.emit({ tag: tag, workOrder: workOrder })"></ion-icon>
    </ion-chip>
  </div>
</form>
