import { createAction, props } from '@ngrx/store';
import { InvitedUser } from '../../domains/users';
import { UserQuery } from './models/user-query.model';

export const inviteCompanyPublicConnection = createAction('[CORE: Invites] (Company Public Connection) Invite');
export const inviteCompanyPrivateConnection = createAction('[CORE: Invites] (Company Private Connection) Invite');

export const inviteCompanyEmployeeInstaller = createAction('[CORE: Invites] (Company Employee Installer) Invite');
export const inviteCompanyProjectManager = createAction('[CORE: Invites] (Company Project Manager) Invite');

export const inviteProcessInitiated = createAction(
  '[CORE: Invites] (Process) Initiated',
  props<{ invite: InvitedUser; user?: UserQuery }>(),
);
export const inviteProcessAborted = createAction('[CORE: Invites] (Process) Aborted');
export const inviteProcessCompleted = createAction('[CORE: Invites] (Process) Completed');
