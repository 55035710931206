import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { Training } from './training.model';
import { TrainingFacadeBase } from './training.state';

@Injectable()
export class TrainingFacade extends TrainingFacadeBase {
  constructor(store: Store<AppState>) {
    super(Training, store);
  }
}
