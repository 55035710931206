import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Address } from '../../domains/locations';
import { AsyncAddressValidators } from '../validators/async-address.validators';
import { coerceEmptyToNull } from './utils';

interface FormConfig {
  controls: {
    [key: string]: any;
  };
  validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null;
  asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null;
}

export interface ValueChangeOptions {
  onlySelf?: boolean;
  emitEvent?: boolean;
  emitModelToViewChange?: boolean;
  emitViewToModelChange?: boolean;
}

export abstract class TypedFormGroup extends UntypedFormGroup {
  protected constructor({ controls, validatorOrOpts, asyncValidator }: FormConfig, builder = new UntypedFormBuilder()) {
    const group = builder.group(controls);
    super(group.controls, validatorOrOpts, asyncValidator);
  }

  protected getControl(name: string): AbstractControl | undefined {
    return this.get(name) ?? undefined;
  }

  protected getFormControl(name: string): UntypedFormControl {
    return this.get(name) as UntypedFormControl;
  }

  protected getFormArray(name: string): UntypedFormArray {
    return this.get(name) as UntypedFormArray;
  }
}

export type ConstructableItem<T, U> = new (item?: Partial<U>) => T;

export class ItemFormArray<T extends TypedFormGroup, U> extends UntypedFormArray {
  readonly itemForms = this.controls as T[];

  isAddingItem = false;
  workingItem: T;
  originalEditedItem?: U | null;

  constructor(
    private formClass: ConstructableItem<T, U>,
    controls: T[],
  ) {
    super(controls, [Validators.required]);
    this.workingItem = new formClass();
  }

  override patchValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
    value.slice(this.controls.length).forEach(item => this.push(new this.formClass(item)));
  }

  cancelItemAddEdit() {
    this.originalEditedItem ? this.addItem(this.originalEditedItem) : void 0;
    this.originalEditedItem = null;
    this.workingItem.reset();
    this.isAddingItem = false;
  }

  beginAddItem() {
    this.workingItem.reset();
    this.isAddingItem = true;
  }

  endAddItem() {
    if (this.workingItem.invalid) {
      return;
    }
    this.addItem(this.workingItem.value);
    this.originalEditedItem = null;
    this.workingItem.reset();
    this.isAddingItem = false;
  }

  private addItem(item: Partial<U>): void {
    this.push(new this.formClass(item));
    this.updateValueAndValidity();
  }

  editItem(item: U, index: number): void {
    this.removeItemAt(index);
    this.originalEditedItem = { ...item };
    this.workingItem.reset(item);
    this.isAddingItem = true;
  }

  removeItemAt(index: number): void {
    this.removeAt(index);
    this.updateValueAndValidity();
  }
}

export class AddressForm extends TypedFormGroup {
  readonly addressLine1 = this.getFormControl('addressLine1');
  readonly addressLine2 = this.getFormControl('addressLine2');
  readonly city = this.getFormControl('city');
  readonly postalCode = this.getFormControl('postalCode');
  readonly stateCode = this.getFormControl('stateCode');
  readonly stateOrProvince = this.getFormControl('stateOrProvince');
  readonly addressTypeId = this.getFormControl('addressTypeId');

  private stateCodeAutoSet = null;
  private cityAutoSet = null;

  get isEmpty(): boolean {
    return (
      this.addressLine1.value == null &&
      this.addressLine2.value == null &&
      this.city.value == null &&
      this.postalCode.value == null &&
      this.stateCode.value == null &&
      (this.addressTypeId.value == null || this.addressTypeId.value === 1)
    );
  }

  get cityOptions(): string[] {
    return (this.city as any).availableOptions ?? [];
  }

  get stateOptions(): string[] {
    return (this.stateCode as any).availableOptions ?? [];
  }

  get updatedModel(): Partial<Address> {
    return coerceEmptyToNull({ ...this.model, ...this.value });
  }

  constructor(
    private model?: Partial<Address>,
    builder = new UntypedFormBuilder(),
  ) {
    super(
      {
        controls: {
          addressLine1: model?.addressLine1 ?? null,
          addressLine2: model?.addressLine2 ?? null,
          city: model?.city ?? null,
          postalCode: [model?.postalCode ?? null],
          stateCode: model?.stateCode ?? null,
          stateOrProvince: model?.stateOrProvince ?? null,
          addressTypeId: model?.addressTypeId ?? 1,
          location: model?.location ?? {
            type: 'Point',
            coordinates: [null, null],
          },
        },
      },
      builder,
    );
  }

  enableValidators(): void {
    this.addressLine1.setValidators(Validators.required);
    this.city.setValidators(Validators.required);
    this.city.setAsyncValidators(AsyncAddressValidators.cityMatchesZip('postalCode'));
    this.stateCode.setValidators(Validators.required);
    this.stateCode.setAsyncValidators([
      AsyncAddressValidators.stateMatchesZip('postalCode'),
      AsyncAddressValidators.stateMatchesStateCode(),
    ]);
    this.postalCode.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(10)]);
    this.postalCode.setAsyncValidators(AsyncAddressValidators.knownCrossValidZipCode('city', 'stateCode'));
    this.setAsyncValidators(AsyncAddressValidators.latLng('city', 'stateCode', 'addressLine1', 'postalCode'));
  }

  disableValidators(): void {
    this.addressLine1.clearValidators();
    this.city.clearValidators();
    this.city.clearAsyncValidators();
    this.stateCode.clearValidators();
    this.stateCode.clearAsyncValidators();
    this.postalCode.clearValidators();
    this.postalCode.clearAsyncValidators();
    this.clearAsyncValidators();
  }

  updateModel(model: Partial<Address>): void {
    this.patchValue({
      ...model,
      city: model.city || this.city.value,
      stateCode: model.stateCode || this.stateCode.value,
    });
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    if (value) {
      this.model = value as Partial<Address>;
      super.patchValue(value, options);
    }
  }
}
