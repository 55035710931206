import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { StreamI18nService } from 'stream-chat-angular';
import { ensureExists } from '../../../shared/utils/rxjs';
import { authenticatedUserMetadataRetrieved } from '../../app/auth/auth.actions';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { AppState } from '../../state';
import {
  channelInitializationFailed,
  channelInitialized,
  chatInitializationFailed,
  chatInitializationFinalized,
  chatInitialized,
  chatLanguageSet,
} from './chat-initialization.actions';
import { WorkOrderChatService } from './work-order-chat.service';

@Injectable()
export class ChatInitializationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly chat: WorkOrderChatService,
    private readonly i18n: StreamI18nService,
  ) {}

  finalizeInitialization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatInitialized),
      switchMap(() =>
        this.actions$.pipe(
          ofType(channelInitialized),
          first(),
          withLatestFrom(this.store.select(authenticatedUser)),
          map(([, user]) => chatInitializationFinalized({ user })),
        ),
      ),
    ),
  );

  setTranslation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticatedUserMetadataRetrieved),
      tap(() => this.i18n.setTranslation()), // TODO: Set translation based on user language
      map(token => chatLanguageSet()),
      tap({ error: console.error }),
    ),
  );

  // retrieveChatTokenForLoggedInUser$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(authenticatedUserMetadataRetrieved),
  //     switchMap(({ user }) => this.chat.getToken(user)),
  //     map(token => chatTokenRetrieved({ token })),
  //     tap({ error: console.error }),
  //   ),
  // );

  initializeChatService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatLanguageSet),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) => user),
      ensureExists(user => !!user),
      switchMap(user =>
        from(this.chat.initializeChat(user)).pipe(
          map(() => chatInitialized()),
          tap({ error: console.error }),
          catchError(error => of(chatInitializationFailed({ error }))),
        ),
      ),
    ),
  );

  initializeChannelService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatInitialized),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) => user),
      ensureExists(user => !!user),
      switchMap(user =>
        from(this.chat.initializeChannel(user)).pipe(
          map(() => channelInitialized()),
          tap({ error: console.error }),
          catchError(error => of(channelInitializationFailed({ error }))),
        ),
      ),
    ),
  );
}
