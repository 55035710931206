import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ChannelService, ChatClientService } from 'stream-chat-angular';
import { logout } from '../../app/auth/auth.actions';
import { chatChannelsReset, chatUserDisconnected, chatUserDisconnectionFailed } from './chat-disconnection.actions';

@Injectable()
export class ChatDisconnectionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly chat: ChatClientService,
    private readonly channels: ChannelService,
  ) {}

  disconnectUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => console.warn('Disconnecting User from Chat on Logout')),
      switchMap(() =>
        from(this.chat.disconnectUser()).pipe(
          map(() => chatUserDisconnected()),
          tap({ next: () => console.trace('[GC] [Stream-Chat] User disconnected'), error: console.error }),
          catchError(error => of(chatUserDisconnectionFailed({ error }))),
        ),
      ),
    ),
  );

  resetChannels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chatUserDisconnected),
      tap(() => this.channels.reset()),
      map(() => chatChannelsReset()),
      tap({ next: () => console.trace('[GC] [Stream-Chat] Channels reset'), error: console.error }),
    ),
  );
}
