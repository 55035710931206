import * as am5 from '@amcharts/amcharts5';
import { Component, Input, NgZone, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { ExtendedHammerRatingDetails } from '../../../../core/installer-profile';
import { buildHammerRatingCharts, renderHammerRatingCharts } from '../../index';


@Component({
  selector: 'gc-hammer-rating-chart',
  templateUrl: './hammer-rating-chart.component.html',
  styleUrls: ['./hammer-rating-chart.component.scss']
})
export class HammerRatingChartComponent implements OnDestroy, OnChanges {

  @Input() chart!: ExtendedHammerRatingDetails;
  @Input() title!: string;

  private root?: am5.Root;

  constructor(private zone: NgZone) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chart && changes.chart.currentValue && changes.chart.currentValue !== changes.chart.previousValue) {
      this.root?.dispose();
      this.hammerRatingChart(this.chart);
    }
  }

  hammerRatingChart(hammerRatingDetails: ExtendedHammerRatingDetails) {
    const charts = buildHammerRatingCharts([hammerRatingDetails]);
    renderHammerRatingCharts(charts, { seriesAnimateDuration: 2000, seriesAppearTime: 1000, chartAppearTime: 500 });
    this.root = charts?.[0]?.root;
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.root?.dispose();
    });
  }
}
