import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'BankAccount',
  uriName: 'bank-accounts',
})
export class BankAccount {
  @Key id?: string;
  accountHolderName!: string;
  accountHolderType!: string;
  last4!: string;
  routingNumber!: string;
  bankName!: string;
  country!: string;
  currency!: string;
  object!: string;
}
