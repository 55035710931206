import { WorkOrderStatus } from '../work-order/work-order.model';

export const DisplayableWorkOrderStatuses: WorkOrderStatus[] = [
  WorkOrderStatus.PublishedAwaitingResponse,
  WorkOrderStatus.PublishedWithResponses,
  WorkOrderStatus.AwardedNotStarted,
  WorkOrderStatus.InProgressNoInvoices,
  WorkOrderStatus.InProgressHasInvoices,
  WorkOrderStatus.Done,
];
