import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../../environments/environment';
import { User } from '../users';

export enum AnalyticsEvents {
  sessionStart = 'session_start',
}

@Injectable()
export class AnalyticsService {
  async initialize() {
    if (!Capacitor.isNativePlatform()) {
      await FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    }

    await FirebaseAnalytics.setCollectionEnabled({ enabled: true });
  }

  async setUserData(user: Required<User>) {
    await FirebaseAnalytics.setUserId({ userId: user.id! });
    await FirebaseAnalytics.setUserProperty({ name: 'type', value: user.type });
  }

  logEvent(name: AnalyticsEvents, params: object) {
    return FirebaseAnalytics.logEvent({ name, params });
  }
}
