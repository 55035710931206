import { createSelector } from '@ngrx/store';
import { parseISO } from 'date-fns';
import { CustomDate, CustomDateType } from '~gc/domains/custom-dates/custom-date/custom-date.model';
import { allSortedCustomDates } from '~gc/domains/custom-dates/custom-date/custom-date.store';

export const byType =
  (...types: CustomDateType[]) =>
  (customDates: CustomDate[]): CustomDate[] =>
    customDates.filter(date => (date?.type ? types.includes(date.type) : false));

export const parsedCustomDates = createSelector(allSortedCustomDates, (dates: CustomDate[]): CustomDate[] =>
  dates.map(date => ({
    ...date,
    startDateValue: parseISO(date.startDate).valueOf(),
    endDateValue: parseISO(date.endDate).valueOf(),
  })),
);

export const workOrderCustomDates = createSelector(parsedCustomDates, byType('workorder'));
export const changeOrderCustomDates = createSelector(parsedCustomDates, byType('changeorder'));
export const userEventCustomDates = createSelector(parsedCustomDates, byType('userevent'));
