<ng-container *ngIf="!!form">
  <mat-form-field appearance="fill" class="date-range-picker-form" [ngClass]="{ 'cta-buttons-shown': showCtaButtons }">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="form!" [rangePicker]="picker" [min]="minDate">
      <input matStartDate formControlName="startDate" placeholder="Start date" readonly />
      <input matEndDate formControlName="endDate" placeholder="End date" readonly />
    </mat-date-range-input>

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <!-- TODO: picker.datePickerInput.value?.start and end weren't accessible in prod build so I removed for now -->
    <!--  Those should be checked as non-null before firing the closed event -->
    <mat-date-range-picker #picker (closed)="save(form)"></mat-date-range-picker>
    <ion-icon matSuffix slot="icon-only" name="close" size="large" color="gc-gray" (click)="canceled.emit()"></ion-icon>

    <mat-error *ngIf="form | fieldInvalid: 'startDate'">
      {{ form.startDate?.getError('invalidDate') || 'Invalid start date' }}
    </mat-error>
    <mat-error *ngIf="form | fieldInvalid: 'endDate'">
      {{ form.endDate?.getError('invalidDate') || 'Invalid end date' }}
    </mat-error>
  </mat-form-field>
</ng-container>
