import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { facilityTypeEntities } from '~gc/domains/locations/facility-type/facility-type.state';
import { productInstallationTypeEntities } from '~gc/domains/training/product-installation-types/product-installation-types.state';
import { countAll } from '../../../shared/utils/func';
import { view } from '../../../shared/utils/ngrx';
import { FacilityType } from '../../locations';
import { ProductInstallationType } from '../../training/product-installation-types/product-installation-types.model';
import { Installer, ProjectManager, User } from '../../users';
import { userEntities } from '../../users/user/user.state';
import { InstallerResponse, MatchedInstaller } from '../matched-installer/matched-installer.model';
import { allMatchedInstallers } from '../matched-installer/matched-installer.state';
import { DetailedWorkOrder } from '../models/detailed-work-order';
import { WorkOrder, WorkOrderStatus } from './work-order.model';
import { allWorkOrders, currentWorkOrder, editedWorkOrder, workOrdersState } from './work-order.state';

export const unsavedEditedWorkOrders = createSelector(workOrdersState, state => state.editedWorkOrders || {});
export const isLoadingFresh = createSelector(workOrdersState, state => state.isLoadingFresh);

export const isPendingInitialLoad = createSelector(
  workOrdersState,
  state => state.isLoadingFresh && state.ids.length === 0,
);

// TODO: Figure out how this is used, and eliminate the non-nullish assertions used below!!!
export const resolveRelatedChannels = (workOrder: WorkOrder, matchedInstallers: MatchedInstaller[]): string[] =>
  !!workOrder.chatChannelId
    ? [workOrder.chatChannelId]
    : matchedInstallers.length
    ? matchedInstallers.map(matched => matched.chatChannelId!)
    : [];

export const findAssociatedEntity =
  <T, U>(associationKey: keyof T) =>
  (associated: IEntityDictionary<U>, reference?: T): U | undefined =>
    reference?.[associationKey] ? associated[reference[associationKey] as keyof IEntityDictionary<U>] : undefined;

export const findEntitiesAssociated =
  <T, U>(entityKey: keyof U, referenceKey: keyof T) =>
  (entities?: U[], reference?: T): U[] =>
    !!reference ? entities?.filter(entity => (entity[entityKey] as any) === reference[referenceKey]) ?? [] : [];

export const currentWorkOrderAwardedInstaller = createSelector(
  userEntities,
  currentWorkOrder,
  view('currentWorkOrderAwardedInstaller', findAssociatedEntity('awardedToInstallerId'), false),
);

export const currentWorkOrderProjectManager = createSelector(
  userEntities,
  currentWorkOrder,
  view('currentWorkOrderProjectManager', findAssociatedEntity('projectManagerId'), false),
);

export const currentWorkOrderFacilityType = createSelector(
  facilityTypeEntities,
  currentWorkOrder,
  view('currentWorkOrderFacilityType', findAssociatedEntity('facilityTypeId'), false),
);

export const currentWorkOrderInstallType = createSelector(
  productInstallationTypeEntities,
  currentWorkOrder,
  view('currentWorkOrderInstallType', findAssociatedEntity('productInstallationTypeId'), false),
);

export const currentWorkOrderMatchedInstallers = createSelector(
  allMatchedInstallers,
  currentWorkOrder,
  view('currentWorkOrderMatchedInstallers', findEntitiesAssociated('workOrderId', 'id'), false),
);

export const findMatchesWithResponses =
  (...responses: InstallerResponse[]) =>
  (matchedInstaller: MatchedInstaller): boolean =>
    responses.includes(matchedInstaller.response);

export const calculateCountOfResponses = (matchedInstallers: MatchedInstaller[], ...responses: InstallerResponse[]) =>
  matchedInstallers.filter(findMatchesWithResponses(...responses)).reduce(countAll, 0);

export const makeDetailedWorkOrder = (
  workOrder: WorkOrder,
  installer: Installer | undefined,
  matchedInstallers: MatchedInstaller[],
  projectManager?: ProjectManager,
  facilityType?: FacilityType,
  installType?: ProductInstallationType,
): DetailedWorkOrder => ({
  _isAwardedWorkOrder: true,
  _isDetailedWorkOrder: true,
  ...workOrder,
  awardedToInstaller: installer,
  projectManager: projectManager,
  facilityType: facilityType,
  productInstallationType: installType,
  matchedInstallers: matchedInstallers,
  channels: resolveRelatedChannels(workOrder, matchedInstallers),
  totalBidResponseCount: calculateCountOfResponses(
    matchedInstallers,
    InstallerResponse.Accepted,
    InstallerResponse.Negotiating,
  ),
  acceptedBidResponseCount: calculateCountOfResponses(matchedInstallers, InstallerResponse.Accepted),
  declineResponseCount: calculateCountOfResponses(matchedInstallers, InstallerResponse.Declined),
  negotiatedResponseCount: calculateCountOfResponses(matchedInstallers, InstallerResponse.Negotiating),
});

export const hasKeys = <T>(value?: T): boolean => !!value && !!Object.keys(value)?.length;

export const tryMakeDetailedWorkOrder = (
  workOrder: WorkOrder | undefined,
  installer: Installer | undefined,
  projectManager: ProjectManager | undefined,
  facilityType: FacilityType | undefined,
  installType: ProductInstallationType | undefined,
  matchedInstallers: MatchedInstaller[],
): DetailedWorkOrder | undefined =>
  workOrder &&
  (!workOrder.awardedToInstallerId || (workOrder.awardedToInstallerId && installer)) &&
  (!workOrder.projectManagerId || projectManager) &&
  (workOrder.status === WorkOrderStatus.Draft || !workOrder.facilityTypeId || facilityType) &&
  (workOrder.status === WorkOrderStatus.Draft || !workOrder.productInstallationTypeId || installType)
    ? makeDetailedWorkOrder(workOrder, installer, matchedInstallers, projectManager, facilityType, installType)
    : undefined;

export const tryKeepDetailedWorkOrder = (
  workOrders: DetailedWorkOrder[],
  workOrder: WorkOrder,
  matchedInstallers: MatchedInstaller[],
  installer?: Installer,
  projectManager?: ProjectManager,
  facilityType?: FacilityType,
  installType?: ProductInstallationType,
) =>
  (!workOrder.awardedToInstallerId || (workOrder.awardedToInstallerId && installer)) &&
  (!workOrder.projectManagerId || projectManager)
    ? (workOrders.push(
        makeDetailedWorkOrder(workOrder, installer, matchedInstallers, projectManager, facilityType, installType),
      ),
      workOrders)
    : workOrders;

export const makeDetailedWorkOrders = (
  workOrders: WorkOrder[] = [],
  users: IEntityDictionary<User>,
  facilityTypes: IEntityDictionary<FacilityType>,
  installTypes: IEntityDictionary<ProductInstallationType>,
  matchedInstallers: MatchedInstaller[],
): DetailedWorkOrder[] =>
  hasKeys(users) && hasKeys(facilityTypes) && hasKeys(installTypes)
    ? workOrders?.reduce(
        (workOrders: DetailedWorkOrder[], workOrder: WorkOrder) =>
          tryKeepDetailedWorkOrder(
            workOrders,
            workOrder,
            findEntitiesAssociated<WorkOrder, MatchedInstaller>('workOrderId', 'id')(matchedInstallers, workOrder),
            findAssociatedEntity<WorkOrder, User>('awardedToInstallerId')(users, workOrder) as Installer,
            findAssociatedEntity<WorkOrder, User>('projectManagerId')(users, workOrder) as ProjectManager,
            findAssociatedEntity<WorkOrder, FacilityType>('facilityTypeId')(facilityTypes, workOrder),
            findAssociatedEntity<WorkOrder, ProductInstallationType>('productInstallationTypeId')(
              installTypes,
              workOrder,
            ),
          ),
        [],
      )
    : [];

export const detailedWorkOrder = createSelector(
  currentWorkOrder,
  currentWorkOrderAwardedInstaller,
  currentWorkOrderProjectManager,
  currentWorkOrderFacilityType,
  currentWorkOrderInstallType,
  currentWorkOrderMatchedInstallers,
  view('detailedWorkOrder', tryMakeDetailedWorkOrder, false),
);

export const detailedWorkOrders = createSelector(
  allWorkOrders,
  userEntities,
  facilityTypeEntities,
  productInstallationTypeEntities,
  allMatchedInstallers,
  view('detailedWorkOrders', makeDetailedWorkOrders, false),
);

export const hasUnsavedEdits = createSelector(
  unsavedEditedWorkOrders,
  editedWorkOrder,
  (workOrders: Record<string, Partial<WorkOrder>>, edited?: Partial<WorkOrder>) =>
    !!edited?.id && !!workOrders[edited.id],
);
