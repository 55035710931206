import { createSelector } from '@ngrx/store';
import { compose } from '../../../shared/utils/compose.util';
import { atIndex, get, replace, split, tryGet } from '../../../shared/utils/func';
import { RequireId } from '../../../shared/utils/types';
import { AppState } from '../../state';
import { User } from '../../users';
import { ROLE_NAMES_MAP, RoleKeys } from './auth.maps';
import { IAuthState } from './auth.state';
import { Claims, ROLES_CLAIM_KEY } from './claims.model';
import { validateAccessToken } from './token-validation.utils';

export const getAuthState = (state: AppState): IAuthState => state.auth;
export const mapToRole = (claims: any): RoleKeys | undefined => claims?.[ROLES_CLAIM_KEY]?.roles?.[0];
export const mapToRoleKey = (role: string): string => ROLE_NAMES_MAP[role];
export const parseJwt = (token?: string): Claims | undefined =>
  !token
    ? undefined
    : compose(
        split('.'),
        atIndex(1),
        replace(/-/g, '+'),
        replace(/_/g, '/'),
        atob,
        // (str: string) => split('')(str),
        // (parts: string[]) => mapEach((c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))(parts),
        // (parts: string[]) => join('')(parts),
        JSON.parse,
      )(token);
export const verifyAuthentication = (claims?: Claims) => !!claims && validateAccessToken(claims);
export const reverifiedAt = createSelector(getAuthState, get('reverifiedAt'));
export const authenticationError = createSelector(getAuthState, tryGet('error'));
export const authenticatedToken = createSelector(getAuthState, tryGet('token'));
export const hasToken = createSelector(authenticatedToken, (token?: string) => !!token);
export const authConnectClaims = createSelector(getAuthState, tryGet('claims'));
export const parsedAccessToken = createSelector(authenticatedToken, parseJwt);
export const authenticatedClaims = parsedAccessToken;
export const authenticatedUserPicture = createSelector(authenticatedClaims, tryGet('picture'));
export const authenticatedTokenExpiration = createSelector(authenticatedClaims, tryGet('exp'));
export const authenticatedUser = createSelector(getAuthState, (state: IAuthState): RequireId<User> | undefined => {
  return state.user;
});
export const authenticatedRole = createSelector(authenticatedClaims, mapToRole);
export const authenticatedRoleKey = createSelector(authenticatedRole, mapToRoleKey);
export const queuedAuthRequest = createSelector(getAuthState, tryGet('pendingAuthUrl'));
export const isAuthenticated = createSelector(authenticatedClaims, reverifiedAt, verifyAuthentication);
export const tokenPermissions = createSelector(parsedAccessToken, (token): string[] => token?.permissions || []);
