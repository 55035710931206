import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs/operators';
import { IStatusGroup } from '../models/status-group';
import { AppState } from '../state';
import { showUserProfile } from './work-order-ui.actions';
import {
  clearWorkOrderSearchCriteria,
  exitStatusGroup,
  searchWorkOrders,
  selectDetailArea,
  selectWorkArea,
  viewOrdersByStatus,
  viewPendingWorkOrders,
  viewSearchWorkOrders,
  viewWorkOrders,
  workPageInitialize,
} from './work.actions';
import {
  currentSearchCriteria,
  currentStatusColor,
  currentStatusGroup,
  currentStatusLabel,
  detailAreasByRoleAndOrderState, detailAreasByRoleOrderStateAndAssignment,
  selectedDetailArea,
  selectedWorkArea,
  shouldShowAvatars,
  shouldShowProgressAreas,
  workAreaOrMonth,
  workAreasByRole,
} from './work.selectors';
import { DetailArea, SelectedArea } from './work.state';

// -----===[ FACADES ]===-----
// Facades represent the bridge between the stateful domains of your
// application, and the UI layer. With facades, we can abstract away
// all of the complexity of managing state (selecting data, dispatching
// actions, etc.), allowing our components to focus on what they do best:
// User interface rendering and user interactions
//
// The UI layer consumes stateful domains by using selections and
// activities exposed by facades.

@Injectable()
export class WorkFacade {
  // -----===[ SELECTIONS ]===-----
  // Selections represent the data we are selecting from state,
  // using the store, via selectors.

  workAreasByRole$ = this.store.select(workAreasByRole);
  workArea$ = this.store.select(workAreaOrMonth);
  selectedWorkArea$ = this.store.pipe(select(selectedWorkArea), distinctUntilChanged());
  selectedDetailArea$ = this.store.pipe(select(selectedDetailArea), distinctUntilChanged());
  detailAreasByRoleAndOrderState$ = this.store.select(detailAreasByRoleAndOrderState);
  detailAreasByRoleOrderStateAndAssignment$ = this.store.select(detailAreasByRoleOrderStateAndAssignment);

  currentStatusGroup$ = this.store.select(currentStatusGroup);
  currentStatusLabel$ = this.store.select(currentStatusLabel);
  currentStatusColor$ = this.store.select(currentStatusColor);
  currentSearchCriteria$ = this.store.select(currentSearchCriteria);
  shouldShowProgressAreas$ = this.store.select(shouldShowProgressAreas);
  shouldShowAvatars$ = this.store.select(shouldShowAvatars);

  constructor(private store: Store<AppState>) {}

  // -----===[ ACTIVITIES ]===-----
  // Activities represent the things we need to do, the events
  // occurring, the commands issued, within our application.

  initialize() {
    this.store.dispatch(workPageInitialize());
  }

  selectArea(area: SelectedArea) {
    this.store.dispatch(selectWorkArea({ area }));
  }

  selectDetailArea(area: DetailArea) {
    this.store.dispatch(selectDetailArea({ area }));
  }

  viewWorkOrders() {
    this.store.dispatch(viewWorkOrders());
  }

  viewPendingWorkOrders() {
    this.store.dispatch(viewPendingWorkOrders());
  }

  viewByStatus(status: IStatusGroup) {
    this.store.dispatch(viewOrdersByStatus({ status }));
  }

  exitStatusGroup() {
    this.store.dispatch(exitStatusGroup());
  }

  viewSearchWorkOrders() {
    this.store.dispatch(viewSearchWorkOrders());
  }

  showUserProfile(id: string) {
    this.store.dispatch(showUserProfile({ id }));
  }

  searchWorkOrders(criteria: string) {
    this.store.dispatch(searchWorkOrders({ criteria }));
  }

  clearSearchCriteria() {
    this.store.dispatch(clearWorkOrderSearchCriteria());
  }
}
