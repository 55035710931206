import { createAction, props } from '@ngrx/store';
import { WorkOrderDate } from '../../core/calendar';

export const pageInitialized = createAction('[PAGE: Work Order Detail] Initialized');

export const schedulerToggleIconClicked = createAction('[PAGE: Work Order Detail] (Scheduler Toggle Icon) Clicked');

export const workOrderDateAdded = createAction(
  '[PAGE: Work Order Detail] (Scheduler Date) Added',
  props<{ date: WorkOrderDate }>(),
);

export const workOrderDateSaved = createAction(
  '[PAGE: Work Order Detail] (Scheduler Date) Saved',
  props<{ date: WorkOrderDate }>(),
);

export const workOrderDateDeleted = createAction(
  '[PAGE: Work Order Detail] (Scheduler Date) Deleted',
  props<{ date: WorkOrderDate }>(),
);
