import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { ChangeOrder } from './change-order.model';
import { ChangeOrderFacadeBase } from './change-order.state';

@Injectable()
export class ChangeOrderFacade extends ChangeOrderFacadeBase {
  constructor(private store: Store<AppState>) {
    super(ChangeOrder, store);
  }
}
