import { createAction, props } from '@ngrx/store';
import { Plaid } from 'plaid-link';
import { PlaidAccountDetails } from './models/plaid-account-details.model';
import PlaidError = Plaid.Error;
import OnExitMetaData = Plaid.OnExitMetaData;
import OnSuccessMetaData = Plaid.OnSuccessMetaData;
import VerificationStatus = Plaid.VerificationStatus;

export const openPlaid = createAction('[Dwolla] (Plaid) Open Plaid', props<{ redirectUri?: string }>());
export const openPlaidSuccess = createAction('[Dwolla] (Plaid) Open Plaid Success');
export const openPlaidError = createAction('[Dwolla] (Plaid) Open Plaid Error', props<{ error: any }>());
export const plaidLoad = createAction('[Dwolla] (Plaid) Loaded');
export const plaidExit = createAction(
  '[Dwolla] (Plaid) Exited',
  props<{ error: PlaidError | null; metadata: OnExitMetaData }>(),
);
export const plaidSuccess = createAction(
  '[Dwolla] (Plaid) Link Success',
  props<{ publicToken: string; metadata: OnSuccessMetaData }>(),
);

export const plaidTokenExchangeSuccess = createAction(
  '[Dwolla] (Plaid) Token Exchange Success',
  props<{ status: VerificationStatus | null }>(),
);

export const plaidTokenExchangeFailure = createAction(
  '[Dwolla] (Plaid) Token Exchange Failure',
  props<{ error: any }>(),
);

export const plaidMicroDepositVerificationFailure = createAction('[Dwolla] (Plaid) Micro Deposit Verification Failure');

export const deletePlaidAccountDetailsSuccess = createAction('[Dwolla] (Plaid) Delete Account Details Success');

export const loadPlaidAccountDetails = createAction('[Dwolla] (Plaid) Load Account Details');
export const loadPlaidAccountDetailsSuccess = createAction(
  '[Dwolla] (Plaid) Load Account Details Success',
  props<{ accountDetails: PlaidAccountDetails }>(),
);
export const loadPlaidAccountDetailsFailure = createAction(
  '[Dwolla] (Plaid) Load Account Details Failure',
  props<{ error: any }>(),
);
