import { createReducer, on } from '@ngrx/store';
import { CustomDateType } from '~gc/domains/custom-dates';
import { authenticatedUserMetadataRetrieved } from '../../../domains/app/auth/auth.actions';
import { restoreState } from './filters.state';
import { calendarSettingsRestored, calendarToggled, calendarViewChanged } from './settings.actions';

export type CalendarView = 'timelineWeek' | 'resourceTimelineWeek' | 'dayGridMonth' | 'listWeek';

export type CalendarName = CustomDateType | string;

export type KeyType = string | number | symbol;
export type FlagsMap<T extends KeyType> = {
  [key in T]: boolean;
};

export type Calendars = FlagsMap<CalendarName>;

export interface SettingsState {
  view?: CalendarView;
  calendars: Calendars;
}

export const settingsInitialState: SettingsState = {
  calendars: { workorder: true, changeorder: false, userevent: true },
};

export const setView = (state: SettingsState, { view }: { view: CalendarView }): SettingsState => ({
  ...state,
  view,
});

export const toggleNamedFlag =
  <TNames extends KeyType, TState extends { [key: string]: FlagsMap<TNames> | any }>(key: keyof TState) =>
  (state: TState, { name }: { name: TNames }): TState => ({
    ...state,
    [key]: { ...state[key], [name]: !(state[key][name] ?? true) },
  });

export const settingsReducer = createReducer(
  settingsInitialState,
  on(calendarSettingsRestored, restoreState),
  on(calendarViewChanged, setView),
  on(calendarToggled, toggleNamedFlag('calendars')),
  on(authenticatedUserMetadataRetrieved, (state, { user }) => ({
    ...state,
    view:
      state.view ??
      (user.type === 'COMPANY_ADMIN' || user.type === 'COMPANY_PROJECT_MANAGER'
        ? 'resourceTimelineWeek'
        : 'timelineWeek'),
  })),
);
