import { createReducer, on } from '@ngrx/store';
import {
  claimProductInstallationSubtypeModalCancelled,
  claimProductInstallationSubtypeModalDone,
  subtypeClaimed,
} from '../../../core/installer-profile';


export interface UIState {
  added: number[];
}

export const initialState: UIState = {
  added: [],
};

export const uiReducer = createReducer(
  initialState,
  on(
    subtypeClaimed,
    (state: UIState, { productInstallationSubtype }): UIState => ({
      ...state,
      added: [...state.added, productInstallationSubtype.id!],
    }),
  ),
  on(claimProductInstallationSubtypeModalCancelled, claimProductInstallationSubtypeModalDone, () => initialState),
);
