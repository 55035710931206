import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { HammerRating } from './hammer-rating.model';

export const {
  initialState: initialHammerRatingState,

  selectors: { selectAll: allHammerRatings },

  facade: HammerRatingFacadeBase,
} = buildState(HammerRating);

export function hammerRatingReducer(state = initialHammerRatingState): IEntityState<HammerRating> {
  return state;
}
