import { Pipe, PipeTransform } from '@angular/core';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { isInstallerLead, isUser, User } from '../../domains/users';

export interface MayHaveInstallerTeamLeadId {
  installerTeamLeadId?: string;
}

export const isAwardedToInstaller = <T extends WorkOrder, U extends User | MayHaveInstallerTeamLeadId>(
  workOrder: T,
  user?: U,
): boolean =>
  isUser(user) && isInstallerLead(user)
    ? workOrder.awardedToInstallerId === user.installerTeamLeadId
    : workOrder.awardedToInstallerId != undefined;

@Pipe({ name: 'isAwardedToInstaller' })
export class IsAwardedToInstallerPipe implements PipeTransform {
  transform<T extends WorkOrder, U extends User | MayHaveInstallerTeamLeadId>(workOrder: T, user?: U): boolean {
    return isAwardedToInstaller(workOrder, user);
  }
}
