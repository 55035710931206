import { addYears, format } from 'date-fns';
import { BasicIdentity, HasId, MayHaveId } from './types';

export const matchById =
  (expected: BasicIdentity) =>
  <T extends HasId>(entry: T): boolean =>
    entry.id == expected;

export const matchOptionalById =
  (expected?: BasicIdentity) =>
  <T extends HasId>(entry: T): boolean =>
    expected ? entry.id == expected : false;

export const matchByOptionalId =
  (expected: BasicIdentity) =>
  <T extends MayHaveId>(entry: T): boolean =>
    entry.id == expected;

export const matchOptionalByOptionalId =
  (expected?: BasicIdentity) =>
  <T extends MayHaveId>(entry: T): boolean =>
    expected ? entry.id == expected : false;

export const matchByKey =
  <TEntry>(expected: any, key: keyof TEntry) =>
  (entry: TEntry): boolean =>
    entry[key] === expected;

export const matchOptionsToKey =
  <TEntry, TKey extends keyof TEntry>(expected: TEntry[TKey][] | undefined, key: TKey) =>
  (entry: TEntry): boolean =>
    expected?.some(expect => expect === entry[key]) ?? false;

export const assign =
  <TObj, TKey extends keyof TObj = any, TValue extends TObj[TKey] = any>(prop: TKey, value: TValue) =>
  (obj: TObj): TObj => ((obj[prop] = value), obj);

export const map =
  <T, R>(project: (value: T) => R) =>
  (value: T): R =>
    project(value);

export const tap =
  <T, R>(handle: (value: T) => void) =>
  (value: T): T => (handle(value), value);

export const trace = <T>(message: string) => tap((value: T) => console.log(message, value));

export const filter = <T>(condition: (value: T) => boolean): ((values: T[]) => T[]) =>
  map<T[], T[]>(value => value.filter(condition));

export const nonNullish = (value: unknown): boolean => value !== null;

export const isTruthy = (value: unknown): boolean => !!value;

export const and =
  (...predicates: Array<(value: any) => boolean>) =>
  (value: any): boolean =>
    predicates.reduce((result: boolean, predicate) => result && predicate(value), true);

export const or =
  (...predicates: Array<(value: any) => boolean>) =>
  (value: any): boolean =>
    predicates.reduce((result: boolean, predicate) => result || predicate(value), false);

export const reserialize = (value: unknown) => JSON.parse(JSON.stringify(value));

// Returns a date for ion-datepicker. Uses today's date if none is provided.
export const createDatePickerTime = (date?: number | string | Date) =>
  format(date ? new Date(date) : new Date(), 'yyyy-MM-dd');

export const createDefaultMaxDate = (years?: number) => createDatePickerTime(addYears(new Date(), years || 2));

export const coerceEmptyToNull = <T extends Record<string, any>>(record: T): T =>
  Object.entries(record).reduce(
    (co, [key, value]) => ({
      ...co,
      [key]: value === '' ? null : value,
    }),
    record,
  );
