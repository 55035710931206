import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { showEnhancedInstallerProfile } from '../../core/installer-profile';
import { editMyBasicProfileSelected } from '../../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.actions';
import { ensureChildrenRequired, ensureExists } from '../../shared/utils/rxjs';
import { authenticatedUser } from '../app/auth/auth.selectors';
import { projectManagerCallRequested } from '../change-orders/change-orders.actions';
import { AppState } from '../state';
import { ProjectManager, User } from '../users';
import { editUserProfile, loadUserProfile, profileEditingCompleted } from '../users/profile/profile.actions';
import { userEntities } from '../users/user/user.state';
import { selectedEmployeeInstallerProfile } from '../users/users-ui.actions';
import { loadAllPhoneNumbers } from './phone-number.state';

@Injectable()
export class PhoneNumberEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  loadCurrentUsersPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editUserProfile, editMyBasicProfileSelected, profileEditingCompleted),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) => user),
      ensureExists(user => !!user),
      map(user =>
        loadAllPhoneNumbers({
          criteria: {
            parents: { [uriNameOfEntityOrEmpty(User)]: user.id },
          },
        }),
      ),
    ),
  );

  loadPhoneNumbersForDashboardWizard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserProfile),
      map(({ user }) =>
        loadAllPhoneNumbers({
          criteria: {
            parents: { [uriNameOfEntityOrEmpty(User)]: user.id },
          },
        }),
      ),
    ),
  );

  loadProjectManagerPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectManagerCallRequested),
      withLatestFrom(this.store.select(userEntities)),
      map(([{ workOrder }, users]) => ({ workOrder, users })),
      ensureChildrenRequired(value => !!value?.workOrder?.projectManagerId && !!value?.users),
      map(({ workOrder, users }) => users[workOrder.projectManagerId] as ProjectManager),
      map(user =>
        loadAllPhoneNumbers({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
            },
          },
        }),
      ),
    ),
  );

  loadInstallersPhoneNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectedEmployeeInstallerProfile, showEnhancedInstallerProfile),
      map(({ installer }) =>
        loadAllPhoneNumbers({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: installer.id,
            },
          },
        }),
      ),
    ),
  );
}
