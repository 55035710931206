import { IEntityState } from '@briebug/ngrx-auto-entity';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { Connection } from '~gc/domains/users/connection/connection.model';
import { connectionReducer } from '~gc/domains/users/connection/connection.state';
import { MatchedInstaller } from '~gc/domains/work/matched-installer/matched-installer.model';
import { matchedInstallerReducer } from '~gc/domains/work/matched-installer/matched-installer.state';
import { environment } from '../../environments/environment';
import { storageSyncReducer } from '../shared/storage-sync/storage-sync.reducer';
import { appReducer, AppStateSlice } from './app/app.state';
import { logout } from './app/auth/auth.actions';
import { authReducer, IAuthState } from './app/auth/auth.state';
import { environmentReducer, EnvironmentState } from './app/environment/environment.state';
import { loginReducer, LoginState } from './app/login.state';
import { ChangeOrder } from './change-orders/change-order/change-order.model';
import { changeOrderReducer } from './change-orders/change-order/change-order.state';
import { BankAccount } from './invoices/bank-account/bank-account.model';
import { bankAccountReducer } from './invoices/bank-account/bank-account.state';
import { IInvoicePaymentState, invoicePaymentReducer } from './invoices/invoice-and-payment.state';
import { InvoiceCount } from './invoices/invoice-count/invoice-count.model';
import { invoiceCountReducer } from './invoices/invoice-count/invoice-count.state';
import { invoiceFileReducer, InvoiceFileState } from './invoices/invoice-file/invoice-files.state';
import { InvoiceItem } from './invoices/invoice-item/invoice-item.model';
import { invoiceItemReducer } from './invoices/invoice-item/invoice-item.state';
import { invoiceReducer, InvoicesState } from './invoices/invoice/invoices.state';
import { LienReleaseFile } from './invoices/lien-release-file/lien-release-file.model';
import { lienReleaseFileReducer } from './invoices/lien-release-file/lien-release-file.state';
import { LienReleaseTemplate } from './invoices/lien-release-template/lien-release-template.model';
import { lienReleaseTemplateReducer } from './invoices/lien-release-template/lien-release-template.state';
import { LienRelease } from './invoices/lien-releases/lien-release.model';
import { lienReleaseReducer } from './invoices/lien-releases/lien-release.state';
import { Payment } from './invoices/payment/payment.model';
import { paymentReducer } from './invoices/payment/payment.state';
import { AddressType } from './locations/address-type/address-type.model';
import { addressTypeReducer } from './locations/address-type/address-type.state';
import { Address } from './locations/address/address.model';
import { addressReducer } from './locations/address/address.state';
import { Country } from './locations/country/country.model';
import { countryReducer } from './locations/country/country.state';
import { FacilityType } from './locations/facility-type/facility-type.model';
import { facilityTypeReducer } from './locations/facility-type/facility-type.state';
import { State } from './locations/state/state.model';
import { stateReducer } from './locations/state/state.state';
import { NegotiationItem } from './negotiations/negotiation-item/negotiation-item.model';
import { negotiationItemReducer } from './negotiations/negotiation-item/negotiation-item.state';
import { Negotiation } from './negotiations/negotiation/negotiation.model';
import { negotiationReducer } from './negotiations/negotiation/negotiation.state';
import { INegotiationsState, NEGOTIATIONS_FEATURE_KEY, negotiationsReducer } from './negotiations/negotiations.state';
import { PhoneNumber } from './phone-number/phone-number.model';
import { phoneNumberReducer } from './phone-number/phone-number.state';
import { TermsAndConditionsAccepted } from './terms-and-conditions/terms-and-conditions-accepted/terms-and-conditions-accepted.model';
import { termsAndConditionsAcceptedReducer } from './terms-and-conditions/terms-and-conditions-accepted/terms-and-conditions-accepted.state';
import { TermsAndConditions } from './terms-and-conditions/terms-and-conditions/terms-and-conditions.model';
import { termsAndConditionsReducer } from './terms-and-conditions/terms-and-conditions/terms-and-conditions.state';
import { Category } from './training/category/category.model';
import { categoryReducer } from './training/category/category.state';
import {
  ProductInstallationSubtypesClaimed
} from './training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import {
  productInstallationSubtypeClaimedReducer
} from './training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.state';
import { ProductInstallationSubtype } from './training/product-installation-subtypes/product-installation-subtypes.model';
import { productInstallationSubtypeReducer } from './training/product-installation-subtypes/product-installation-subtypes.state';
import { ProductInstallationTypeClaimed } from './training/product-installation-types-claimed/product-installation-types-claimed.model';
import { productInstallationTypeClaimedReducer } from './training/product-installation-types-claimed/product-installation-types-claimed.state';
import { ProductInstallationType } from './training/product-installation-types/product-installation-types.model';
import { productInstallationTypeReducer } from './training/product-installation-types/product-installation-types.state';
import { TrainingFile } from './training/training-files/training-file.model';
import { trainingFileReducer } from './training/training-files/training-file.state';
import { Training } from './training/training/training.model';
import { trainingReducer } from './training/training/training.state';
import { TrainingClaimed } from './training/trainings-claimed/trainings-claimed.model';
import { trainingClaimedReducer } from './training/trainings-claimed/trainings-claimed.state';
import { UnitOfMeasure } from './unit-of-measure/unit-of-measure.model';
import { unitOfMeasuresReducer } from './unit-of-measure/unit-of-measure.state';
import { Company } from './users/companies/company.model';
import { companyReducer } from './users/companies/company.state';
import { HammerRating } from './users/hammer-rating/hammer-rating.model';
import { hammerRatingReducer } from './users/hammer-rating/hammer-rating.state';
import { JumpstarterFavorite } from './users/jumpstarter-favorites/jumpstarter-favorite.model';
import { jumpstarterFavoriteReducer } from './users/jumpstarter-favorites/jumpstarter-favorites.state';
import { KudosReason } from './users/kudos-reason/kudos-reason.model';
import { kudosReasonReducer } from './users/kudos-reason/kudos-reason.state';
import { profileReducer, ProfileState } from './users/profile/profile.state';
import { teamReducer, TeamState } from './users/team/team.state';
import { UserFile } from './users/user-files/user-file.model';
import { userFileReducer } from './users/user-files/user-files.state';
import { User } from './users/user/user.model';
import { userReducer } from './users/user/user.state';
import { calendarReducer, ICalendarState } from './work/calendar/calendar.state';
import { Contact } from './work/contact/contact';
import { contactReducer } from './work/contact/contact.state';
import { installerReviewReducer, InstallerReviewState } from './work/installer-review/installer-review.state';
import { Kudos } from './work/kudos/kudos.model';
import { kudosReducer } from './work/kudos/kudos.state';
import { WorkOrderCount } from './work/work-order-count/work-order-count.model';
import { workOrderCountReducer } from './work/work-order-count/work-order-count.state';
import { WorkOrderDraft } from './work/work-order-draft/work-order-draft.model';
import { workOrderDraftReducer } from './work/work-order-draft/work-order-draft.state';
import { WorkOrderFile } from './work/work-order-files/work-order-files.model';
import { workOrderFileReducer } from './work/work-order-files/work-order-files.state';
import { WorkOrderItem } from './work/work-order-item/work-order-item.model';
import { workOrderItemReducer } from './work/work-order-item/work-order-item.state';
import { workOrderMapReducer, WorkOrderMapState } from './work/work-order-map/work-order-map.state';
import { WorkOrderTag } from './work/work-order-tag/work-order-tag.model';
import { workOrderTagReducer } from './work/work-order-tag/work-order-tag.state';
import { workOrderReducer, WorkOrderState } from './work/work-order/work-order.state';
import { workPollingReducer, WorkPollingState } from './work/work-polling.state';
import { workReducer, WorkState } from './work/work.state';

export interface AppState {
  address: IEntityState<Address>;
  addressType: IEntityState<AddressType>;
  app: AppStateSlice;
  auth: IAuthState;
  calendar: ICalendarState;
  category: IEntityState<Category>;
  changeOrder: IEntityState<ChangeOrder>;
  company: IEntityState<Company>;
  contact: IEntityState<Contact>;
  country: IEntityState<Country>;
  environment: EnvironmentState;
  facilityType: IEntityState<FacilityType>;
  hammerRating: IEntityState<HammerRating>;
  installerReview: InstallerReviewState;
  invoice: InvoicesState;
  invoiceCount: IEntityState<InvoiceCount>;
  invoiceItem: IEntityState<InvoiceItem>;
  invoiceFile: InvoiceFileState;
  invoiceAndPayment: IInvoicePaymentState;
  jumpstarterFavorite: IEntityState<JumpstarterFavorite>;
  kudos: IEntityState<Kudos>;
  kudosReason: IEntityState<KudosReason>;
  bankAccount: IEntityState<BankAccount>;
  lienRelease: IEntityState<LienRelease>;
  lienReleaseFile: IEntityState<LienReleaseFile>;
  lienReleaseTemplate: IEntityState<LienReleaseTemplate>;
  login: LoginState;
  matchedInstaller: IEntityState<MatchedInstaller>;
  connection: IEntityState<Connection>;
  negotiation: IEntityState<Negotiation>;
  [NEGOTIATIONS_FEATURE_KEY]: INegotiationsState;
  negotiationItem: IEntityState<NegotiationItem>;
  payment: IEntityState<Payment>;
  phoneNumber: IEntityState<PhoneNumber>;
  productInstallationType: IEntityState<ProductInstallationType>;
  productInstallationTypeClaimed: IEntityState<ProductInstallationTypeClaimed>;
  productInstallationSubtype: IEntityState<ProductInstallationSubtype>;
  productInstallationSubtypesClaimed: IEntityState<ProductInstallationSubtypesClaimed>;
  profile: ProfileState;
  router: RouterReducerState;
  state: IEntityState<State>;
  team: TeamState;
  training: IEntityState<Training>;
  trainingClaimed: IEntityState<TrainingClaimed>;
  trainingFile: IEntityState<TrainingFile>;
  termsAndConditions: IEntityState<TermsAndConditions>;
  termsAndConditionsAccepted: IEntityState<TermsAndConditionsAccepted>;
  unitOfMeasure: IEntityState<UnitOfMeasure>;
  user: IEntityState<User>;
  userFile: IEntityState<UserFile>;
  work: WorkState;
  workOrder: WorkOrderState;
  workOrderCount: IEntityState<WorkOrderCount>;
  workOrderDraft: IEntityState<WorkOrderDraft>;
  workOrderFile: IEntityState<WorkOrderFile>;
  workOrderItem: IEntityState<WorkOrderItem>;
  workOrderMap: WorkOrderMapState;
  workOrderTag: IEntityState<WorkOrderTag>;
  workPolling: WorkPollingState;
}

export const reducers: ActionReducerMap<AppState> = {
  address: addressReducer,
  addressType: addressTypeReducer,
  app: appReducer,
  auth: authReducer,
  bankAccount: bankAccountReducer,
  calendar: calendarReducer,
  category: categoryReducer,
  changeOrder: changeOrderReducer,
  company: companyReducer,
  contact: contactReducer,
  country: countryReducer,
  environment: environmentReducer,
  facilityType: facilityTypeReducer,
  hammerRating: hammerRatingReducer,
  installerReview: installerReviewReducer,
  invoice: invoiceReducer,
  invoiceCount: invoiceCountReducer,
  invoiceItem: invoiceItemReducer,
  invoiceFile: invoiceFileReducer,
  invoiceAndPayment: invoicePaymentReducer,
  jumpstarterFavorite: jumpstarterFavoriteReducer,
  kudos: kudosReducer,
  kudosReason: kudosReasonReducer,
  login: loginReducer,
  lienRelease: lienReleaseReducer,
  lienReleaseFile: lienReleaseFileReducer,
  lienReleaseTemplate: lienReleaseTemplateReducer,
  matchedInstaller: matchedInstallerReducer,
  connection: connectionReducer,
  negotiation: negotiationReducer,
  [NEGOTIATIONS_FEATURE_KEY]: negotiationsReducer,
  negotiationItem: negotiationItemReducer,
  payment: paymentReducer,
  phoneNumber: phoneNumberReducer,
  productInstallationType: productInstallationTypeReducer,
  productInstallationTypeClaimed: productInstallationTypeClaimedReducer,
  productInstallationSubtype: productInstallationSubtypeReducer,
  productInstallationSubtypesClaimed: productInstallationSubtypeClaimedReducer,
  profile: profileReducer,
  router: routerReducer,
  state: stateReducer,
  team: teamReducer,
  termsAndConditions: termsAndConditionsReducer,
  termsAndConditionsAccepted: termsAndConditionsAcceptedReducer,
  training: trainingReducer,
  trainingClaimed: trainingClaimedReducer,
  trainingFile: trainingFileReducer,
  unitOfMeasure: unitOfMeasuresReducer,
  user: userReducer,
  userFile: userFileReducer,
  work: workReducer,
  workOrder: workOrderReducer,
  workOrderCount: workOrderCountReducer,
  workOrderDraft: workOrderDraftReducer,
  workOrderFile: workOrderFileReducer,
  workOrderItem: workOrderItemReducer,
  workOrderMap: workOrderMapReducer,
  workOrderTag: workOrderTagReducer,
  workPolling: workPollingReducer,
};

// -----===[ METAREDUCERS ]===-----

export const clearState: MetaReducer<AppState> =
  (reducer: ActionReducer<AppState, Action>) =>
  (state: AppState | undefined, action: Action): AppState =>
    action.type === logout.type ? reducer(undefined as unknown as AppState, action) : reducer(state, action);

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [clearState, storageSyncReducer] // Development Mode
  : [clearState, storageSyncReducer]; // Production Mode
