import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService implements OnDestroy {
  private hideSidebarSubject = new BehaviorSubject<boolean>(false);

  hideSidebar$ = this.hideSidebarSubject.asObservable();

  constructor() {}

  setHideSidebar(hide: boolean) {
    this.hideSidebarSubject.next(hide);
  }

  ngOnDestroy() {
    this.hideSidebarSubject.unsubscribe();
  }
}
