<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss(undefined, 'cancel'); clearSelection()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Choose Connection(s)</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <gc-search
    (criteriaChanged)="search($event)"
    (cleared)="searchCleared()"
    placeholder="Search Connection Name"
    [value]="criteria$ | push"
  ></gc-search>
  <gc-select-installer
    [workOrder]="workOrder"
    [installers]="installers$ | push"
    [selected]="selected$ | push"
    (selectedChange)="selectionChanged($event)"
  ></gc-select-installer>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-button
          [disabled]="!(selected$ | push)?.length"
          mode="ios"
          expand="block"
          color="gc-green"
          (click)="publish()"
        >
          Publish to Connection(s)
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
