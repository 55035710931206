import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faFileInvoiceDollar, faHandHolding, faPlus, faRuler } from '@fortawesome/free-solid-svg-icons';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AuthFacade } from '~gc/domains/app/auth/auth.facade';
import { InvoiceAndPaymentFacade } from '~gc/domains/invoices';
import { WorkOrderRelationsFacade } from '~gc/domains/work-order-relations.facade';
import { InstallerReviewFacade } from '~gc/domains/work/installer-review/installer-review.facade';
import { WorkOrderFacade } from '~gc/domains/work/work-order/work-order.facade';
import { WorkFacade } from '~gc/domains/work/work.facade';
import { tokenPermissions } from '../../domains/app/auth/auth.selectors';
import { ChangeOrdersFacade } from '../../domains/change-orders/change-orders.facade';
import { CustomDate } from '../../domains/custom-dates';
import { setBODTime, setEODTime } from '../../domains/custom-dates/custom-date/custom-date.model';
import { reportsProgressForCurrentWorkOrder } from '../../domains/invoices/invoice-ui.selectors';
import { NegotiationsFacade } from '../../domains/negotiations/negotiations.facade';
import { Installer, ProfileFacade, User, UsersUIFacade } from '../../domains/users';
import { awardedInstallerProfileShown } from '../../domains/users/users-ui.actions';
import { KudosFacade } from '../../domains/work/kudos/kudos.facade';
import { MatchedInstallersFacade } from '../../domains/work/matched-installer/matched-installers.facade';
import { WorkOrderChatFacade } from '../../domains/work/work-order-chat/work-order-chat.facade';
import { isChatOpen } from '../../domains/work/work-order-chat/work-order-chat.state';
import { WorkOrderEditFacade } from '../../domains/work/work-order-edit/work-order-edit.facade';
import { WorkOrderItemFacade } from '../../domains/work/work-order-item/work-order-item.facade';
import { WorkOrder } from '../../domains/work/work-order/work-order.model';
import { schedulerOpen } from './store/ui.selectors';
import {
  schedulerToggleIconClicked,
  workOrderDateAdded,
  workOrderDateDeleted,
  workOrderDateSaved,
} from './work-order-detail.actions';
import { currentWorkOrderDatesSorted } from './work-order-detail.selectors';
import { appendMatchedInstaller } from './work-order-installer-options.actions';

@Component({
  selector: 'gc-work-order-detail',
  templateUrl: './work-order-detail.page.html',
  styleUrls: ['./work-order-detail.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class WorkOrderDetailPage {
  @Input() showAsModal = false;
  @Output() closeModal = new EventEmitter();
  activeView = 'details';

  schedulerOpen$ = this.store.select(schedulerOpen);
  dates$ = this.store.select(currentWorkOrderDatesSorted);
  permissions$ = this.store.select(tokenPermissions);
  isChatOpen$ = this.store.select(isChatOpen);
  reportsProgress$ = this.store.select(reportsProgressForCurrentWorkOrder);

  readonly faFileInvoiceDollar = faFileInvoiceDollar;
  readonly faHandHolding = faHandHolding;
  readonly faRuler = faRuler;
  readonly faPlus = faPlus;

  constructor(
    public auth: AuthFacade,
    public installerReview: InstallerReviewFacade,
    public invoiceAndPayment: InvoiceAndPaymentFacade,
    public nav: NavController,
    public work: WorkFacade,
    public workOrders: WorkOrderFacade,
    public workOrderItems: WorkOrderItemFacade,
    public workOrderEdit: WorkOrderEditFacade,
    public workOrderRelations: WorkOrderRelationsFacade,
    public matchedInstallers: MatchedInstallersFacade,
    public changeOrders: ChangeOrdersFacade,
    public kudos: KudosFacade,
    public modals: ModalController,
    public workOrderChat: WorkOrderChatFacade,
    public negotiationsFacade: NegotiationsFacade,
    public usersUI: UsersUIFacade,
    public profile: ProfileFacade,
    private readonly store: Store,
  ) {}

  toggleScheduler() {
    this.store.dispatch(schedulerToggleIconClicked());
  }

  addDate(date: CustomDate) {
    this.store.dispatch(
      workOrderDateAdded({
        date: {
          ...date,
          startDate: setBODTime(date.startDate),
          endDate: setEODTime(date.endDate),
        },
      }),
    );
  }

  saveDate(date: CustomDate) {
    this.store.dispatch(
      workOrderDateSaved({
        date: {
          ...date,
          startDate: setBODTime(date.startDate),
          endDate: setEODTime(date.endDate),
        },
      }),
    );
  }

  deleteDate(date: CustomDate) {
    this.store.dispatch(
      workOrderDateDeleted({
        date: {
          ...date,
          startDate: setBODTime(date.startDate),
          endDate: setEODTime(date.endDate),
        },
      }),
    );
  }

  appendMatchedInstaller(user: User, workOrder: WorkOrder) {
    this.store.dispatch(appendMatchedInstaller({ user, workOrder }));
  }

  showAwardedInstallerProfile(installer: Installer) {
    this.store.dispatch(awardedInstallerProfileShown({ installer: installer as Installer }));
  }

}
