import { createAction, createReducer, props } from '@ngrx/store';
import { User } from 'src/app/domains/users/user/user.model';
import { Claims } from './claims.model';

export const login = createAction('[User] Login'); // , props<{ credentials: Credentials }>());

export const authenticationFailed = createAction('[User] Authentication Failed', props<{ error: any }>());

export const authenticated = createAction('[User] Authenticated', props<{ claims: any; token: any }>());

export const authenticatedUserMetadataRetrieved = createAction(
  '[User] Authenticated User Metadata Retrieved',
  props<{ user: User }>(),
);

export const authenticatedUserMetadataUpdated = createAction(
  '[User] Authenticated User Metadata Updated',
  props<{ user: User }>(),
);

export const updateAuthenticatedUser = createAction('[User] Update Authenticated User', props<{ user: User }>());

export const logout = createAction('[User] Logout');

export const loggedOut = createAction('[User] Logged out');

export const clear = createAction('[User] Clear Auth Token');

export const tokenRefreshBeforeExpirationInitiated = createAction('[User] (Token Refresh Before Expiration) Initiated');

export const claimsReverifying = createAction('[AUTH] (Claims) Reverifying');

export const claimsInvalidated = createAction('[AUTH] (Claims) Invalidated');

export const authSubjectChecked = createAction('[AUTH] (Subject) Checked', props<{ claims: Claims, changed: boolean }>());

export const authSubjectChanged = createAction('[AUTH] (Subject) Changed', props<{ claims: Claims }>());
