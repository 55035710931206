import { Pipe, PipeTransform } from '@angular/core';
import { CONNECTION_STATUS_COLOR } from '~gc/domains/users/connection/connection-status.maps';
import { ConnectionStatus } from '~gc/domains/users/connection/connection.model';

@Pipe({ name: 'networkStatusColor' })
export class NetworkStatusColorPipe implements PipeTransform {
  transform<T extends ConnectionStatus | string>(status: T): string {
    return CONNECTION_STATUS_COLOR[status as ConnectionStatus] ?? 'medium';
  }
}
