import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  currentInstallerProductInstallationTypesClaimed,
  DetailedProductInstallationTypeClaimed,
  educationAndTrainingContext,
} from '../../../../core/installer-profile';
import { Category } from '../../../../domains/training/category/category.model';
import { ProductInstallationSubtypesClaimed } from '../../../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import { ProductInstallationTypeClaimed } from '../../../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { ProductInstallationType } from '../../../../domains/training/product-installation-types/product-installation-types.model';
import { TrainingClaimed } from '../../../../domains/training/trainings-claimed/trainings-claimed.model';
import { selectedInstallTypeClaim } from '../../view-installer-profile-enhanced.selectors';

@Component({
  selector: 'gc-training-and-education-area',
  templateUrl: './training-and-education-area.component.html',
  styleUrls: ['./training-and-education-area.component.scss'],
})
export class TrainingAndEducationAreaComponent {
  @Input() canEditProfile!: boolean;
  @Output() installTypeAreaTabSelected = new EventEmitter<DetailedProductInstallationTypeClaimed>();
  @Output() installTypeClaimAdded = new EventEmitter();
  @Output() installTypeClaimRemoved = new EventEmitter<ProductInstallationTypeClaimed>();
  @Output() trainingClaimAdded = new EventEmitter<Category>();
  @Output() trainingClaimRemoved = new EventEmitter<TrainingClaimed>();
  @Output() subtypeAdded = new EventEmitter<ProductInstallationType>();
  @Output() subtypeRemoved = new EventEmitter<ProductInstallationSubtypesClaimed>();

  installationTypesClaimed$ = this.store.select(currentInstallerProductInstallationTypesClaimed);
  selectedProductInstallationTypeClaim$ = this.store.select(selectedInstallTypeClaim);
  educationAndTrainingContext$ = this.store.select(educationAndTrainingContext).pipe();

  constructor(private readonly store: Store) {}
}
