<form [formGroup]="form">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size-lg="4" class="ion-padding">
        <h2><b>Create a Work Order</b></h2>
        <p>
          These basics will be shared with all the installers we match to your Work Order. Include enough detail that
          the Installer can make an informed decision&mdash;we'll provide a separate area to add private details for the
          Installer to whom you award this Work Order.
        </p>
        <ion-button fill="clear" (click)="showKnowledgeBase()">
          Open Knowledge Base
          <ion-icon name="exit-outline" style="margin-left: 5px"></ion-icon>
        </ion-button>
      </ion-col>

      <ion-col size="12" size-sm>
        <ion-list lines="full" class="ion-no-margin">
          <ion-list-header data-cy="work-order-page-one-header" lines="full">
            <h2><b>Basics</b></h2>
          </ion-list-header>
        </ion-list>

        <ion-item>
          <ion-input
            data-cy="public-job-name-input"
            formControlName="publicJobName"
            clearInput
            [color]="
              (form.validated && form.publicJobName.invalid) || (!form.isDraftValid && form.publicJobName.touched)
                ? 'danger'
                : null
            "
            labelPlacement="floating"
            [label]="
              (form.validated && form.publicJobName.invalid) || (!form.isDraftValid && form.publicJobName.touched)
                ? 'This field is required to save a draft'
                : 'Public Job Name'
            "
          >
          </ion-input>
        </ion-item>

        <ion-item lines="full">
          <ion-select data-cy="facility-type-select" formControlName="facilityTypeId">
            <div slot="label">
              <ion-text [color]="form.validated && form.facilityTypeId.invalid ? 'danger' : 'dark'">
                Facility Type
              </ion-text>
            </div>
            <ion-select-option
              *ngFor="let facility of facilityTypes"
              [value]="facility.id"
              [attr.data-cy]="facility.type"
            >
              {{ facility.type }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item lines="full">
          <ion-select data-cy="product-type-select" formControlName="productInstallationTypeId">
            <div slot="label">
              <ion-text [color]="form.validated && form.facilityTypeId.invalid ? 'danger' : 'dark'">
                Product Installation Type
              </ion-text>
            </div>
            <ion-select-option
              *ngFor="let discipline of productInstallationTypes"
              [value]="discipline.id"
              [attr.data-cy]="'product-type-' + discipline.type"
            >
              {{ discipline.type }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-textarea
          formControlName="publicJobSummary"
          rows="6"
          fill="solid"
          placeholder="Add your Public Job Summary here."
          labelPlacement="floating"
          class="ion-no-padding"
          [color]="form.validated && form.publicJobSummary.invalid ? 'danger' : null"
        >
          <div slot="label">
            <ion-text [color]="form.validated && form.publicJobSummary.invalid ? 'danger' : null">
              Public Job Summary
            </ion-text>
          </div>
        </ion-textarea>
        <ion-item *ngIf="form.validated && form.publicJobSummary.hasError('required')">
          <ion-label color="danger">A Public Job Summary is required.</ion-label>
        </ion-item>

        <ion-list lines="full" class="ion-no-margin">
          <ion-list-header data-cy="work-order-page-one-header" lines="full">
            <h2><b>Schedule</b></h2>
          </ion-list-header>
        </ion-list>

        <gc-schedule
          [enableCommands]="true"
          [enableAdd]="form.workOrderStatus === 'DRAFT'"
          [formArray]="form.dates"
          [showHeader]="false"
          (added)="dateAdded.emit($event)"
          (saved)="dateSaved.emit($event)"
          (deleted)="dateDeleted.emit($event)"
        ></gc-schedule>

        <ion-item *ngIf="form.validated && form.dates.hasError('atLeastOneScheduleDate')" lines="none">
          <ion-label color="danger">{{ form.dates.errors?.['atLeastOneScheduleDate'] }}</ion-label>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
