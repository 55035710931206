import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { Payment } from './payment.model';

export const {
  initialState: initialPaymentState,
  actions: { create: createPayment, createSuccess: createPaymentSuccess, createFailure: createPaymentFailure },
} = buildState(Payment);

const reduce = createReducer(initialPaymentState);

export function paymentReducer(state = initialPaymentState, action: Action): IEntityState<Payment> {
  return reduce(state, action);
}
