<ng-container *ngIf="workOrderEdit.currentEditingStep$ | push as currentStep">
  <ion-header class="nav-header">
    <ion-toolbar mode="md">
      <ion-buttons slot="start" [ngSwitch]="currentStep">
        <ion-button (click)="cancelAddEdit()" *ngSwitchCase="'basics'">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
        <ion-button (click)="back()" *ngSwitchDefault shape="round" fill="clear">
          <ion-icon name="chevron-back-outline" slot="icon-only"></ion-icon>
        </ion-button>

        <ion-button *ngIf="isSaving$ | push; else saved">
          <ion-spinner slot="icon-only"></ion-spinner>
        </ion-button>
        <ng-template #saved>
          <ion-button *ngIf="workOrderEdit.hasUnsavedEdits$ | push; else cloud" color="medium">
            <gc-tooltip color="medium" icon="checkmark-circle-outline" slot="icon-only">
              Changes saved locally.
            </gc-tooltip>
          </ion-button>
          <ng-template #cloud>
            <ion-button color="success">
              <gc-tooltip color="success" icon="checkmark-circle" slot="icon-only">
                Changes saved to cloud.
              </gc-tooltip>
            </ion-button>
          </ng-template>
        </ng-template>
      </ion-buttons>
      <ion-buttons *ngIf="currentStep !== 'publishing' && currentStep !== 'items'" slot="end">
        <ion-button (click)="next()" [disabled]="!form.isDraftValid" fill="clear" shape="round">
          <ion-icon name="chevron-forward-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-buttons *ngIf="currentStep === 'items'" slot="end">
        <ion-button (click)="next()" [disabled]="form.workOrderItems.invalid" fill="clear" shape="round">
          <ion-icon name="chevron-forward-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ng-container [ngSwitch]="currentStep" *ngIf="workOrders.edited$ | push">
      <gc-basics-step
        *ngSwitchCase="'basics'"
        [form]="form"
        [facilityTypes]="facilityTypes.sorted$ | push"
        [productInstallationTypes]="productInstallationTypes.all$ | push"
        (dateAdded)="addDate($event)"
        (dateSaved)="saveDate($event)"
        (dateDeleted)="deleteDate($event)"
      ></gc-basics-step>
      <gc-details-step
        *ngSwitchCase="'details'"
        [form]="form"
        [states]="states.all$ | push"
        (fileAdded)="workOrderFiles.upload($event, form.updatedModel)"
        (fileRetried)="workOrderFiles.retry($event, form.updatedModel)"
        (fileRemoved)="workOrderFiles.remove($event, form.updatedModel)"
        (initializing)="stepInitialized()"
        (ready)="stepReady()"
      ></gc-details-step>
      <gc-items-step
        *ngSwitchCase="'items'"
        [form]="form"
        [workOrderItems]="workOrderItems.all$ | push"
        [unitsOfMeasure]="unitOfMeasures.all$ | push"
      ></gc-items-step>
      <gc-publishing-step
        *ngSwitchCase="'publishing'"
        [form]="form"
        [lienReleaseTemplates]="lienReleaseTemplates.all$ | push"
      ></gc-publishing-step>
    </ng-container>
  </ion-content>

  <ion-footer>
    <gc-save-next
      [form]="form"
      [step]="currentStep"
      [isSaving]="isSaving$ | push"
      [isStepInitializing]="isStepInitializing$ | push"
      (next)="next()"
      (save)="save()"
      (publish)="publish()"
      (republish)="republish()"
    >
    </gc-save-next>
  </ion-footer>
</ng-container>
