import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NetworkFacade } from '../../../domains/users/network.facade';

@Component({
  selector: 'gc-network-connection-modal',
  templateUrl: './network-connection-modal.component.html',
  styleUrls: ['./network-connection-modal.component.scss'],
})
export class NetworkConnectionModalComponent {
  constructor(
    public modalGC: ModalController,
    public network: NetworkFacade,
  ) {}
}
