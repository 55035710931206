import { Entity } from '@briebug/ngrx-auto-entity';
import { FileType, GenericFile } from '../../models/generic-file';

@Entity({
  modelName: 'LienReleaseFile',
  uriName: 'files',
})
export class LienReleaseFile extends GenericFile {
  override type = FileType.LienRelease;
}
