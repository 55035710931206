<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button data-cy="manager-profile-modal-close" color="dark" (click)="usersUI.closeProfile()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ng-container *ngIf="auth.tokenPermissions$ | push as permissions">
      <ng-container *ngIf="auth.user$ | push as authUser">
        <ng-container *ngIf="profile.currentProjectManagerProfile$ | push as user">
          <ion-buttons slot="end" *ngIf="user.id !== authUser.id">
            <ion-button
              data-cy="manager-profile-modal-delete-button"
              *ngIf="
                (permissions | hasPermissions: 'team:delete') &&
                ((user | isMyCompanyEmployee: authUser) || (user | isMyTeamMember: authUser))
              "
              color="danger"
              mode="md"
              (click)="usersUI.removeUser(user)"
            >
              <fa-icon slot="icon-only" [icon]="faTrashCan" style="color: var(--ion-color-danger)"></fa-icon>
            </ion-button>

            <ion-button
              data-cy="manager-profile-modal-delete-button"
              *ngIf="(permissions | hasPermissions: 'team.admin:delete') && (user | isMyCompanyAdmin: authUser)"
              color="danger"
              (click)="usersUI.removeUser(user)"
            >
              <fa-icon slot="icon-only" [icon]="faTrashCan" style="color: var(--ion-color-danger)"></fa-icon>
            </ion-button>
          </ion-buttons>
        </ng-container>
      </ng-container>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="profile.currentProjectManagerProfile$ | push as projectManager">
  <gc-user-header [user]="projectManager" [centered]="false"></gc-user-header>

  <ion-list lines="none">
    <ion-list-header>
      <ion-label color="medium">BASIC INFO</ion-label>
    </ion-list-header>

    <ion-item lines="none">
      <ion-label class="ion-text-wrap"> Calendar Color</ion-label>
      <ion-icon slot="end" size="large" name="ellipse" [style]="'color: ' + projectManager.color"></ion-icon>
    </ion-item>

    <ion-list-header>
      <ion-label color="medium">EMAIL</ion-label>
    </ion-list-header>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap">
        {{ projectManager.email }}
      </ion-label>
    </ion-item>

    <ion-list-header>
      <ion-label color="medium">PHONE NUMBER</ion-label>
    </ion-list-header>
    <ion-item lines="none">
      <ion-label class="ion-text-wrap">
        {{ projectManager.phoneNumber }}
      </ion-label>
    </ion-item>

    <ion-list-header>
      <ion-label color="medium">WORK ADDRESS</ion-label>
    </ion-list-header>
    <ng-container *ngFor="let address of projectManager.addresses">
      <ion-item>
        <ion-label class="ion-text-wrap">
          {{ address.addressLine1 }} <br />
          {{ address.city }}, {{ address.stateOrProvince }}
          {{ address.postalCode }}
        </ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>
