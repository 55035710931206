import { Injectable } from '@angular/core';
import { LoadAllIfNecessary } from '@briebug/ngrx-auto-entity';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { defer, EMPTY, from, iif, Observable, of } from 'rxjs';
import { catchError, delay, exhaustMap, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { loadAllUsers } from '~gc/domains/users/user/user.state';
import { excludeRoles } from '../../../shared/utils/roles.operators';
import { ensureExists } from '../../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedRole } from '../../app/auth/auth.selectors';
import { AppState } from '../../state';
import { User } from '../user/user.model';
import { TeamUIService } from './team-ui.service';
import {
  addUserFailure,
  addUserSubmitted,
  addUserSuccess,
  cancelInviteUser,
  inviteEmailUpdated,
  inviteInstaller,
  inviteUserSubmitted,
  sendUserInvite,
  sendUserInviteFailure,
  sendUserInviteSuccess,
  showInviteUser,
  teamPageInitialize,
  userFoundByEmail,
  userNotFoundByEmail,
  viewProjectManagers,
  viewTeamInstallers,
} from './team.actions';
import { TeamService } from './team.service';

@Injectable()
export class TeamEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private nav: NavController,
    private team: TeamService,
    private teamUI: TeamUIService,
    private store: Store<AppState>,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  initializeTeamPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(teamPageInitialize),
      map(() => new LoadAllIfNecessary(User)),
    ),
  );

  showInviteUserModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showInviteUser),
        exhaustMap(({ userType }) => from(this.teamUI.showInviteUserModal(userType)).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  lookupByUserEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteEmailUpdated),
      switchMap(({ email }) =>
        iif(
          () => !!email,
          defer(() =>
            this.team.lookupUserByEmail(email).pipe(
              map(user => userFoundByEmail({ user })),
              catchError(() => of(userNotFoundByEmail())),
            ),
          ),
          of(undefined),
        ),
      ),
      ensureExists(val => !!val),
    ),
  );

  inviteInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteInstaller),
      map(({ installer }) =>
        inviteUserSubmitted({
          user: {
            role: installer.type,
            email: installer.email,
            fullName: '',
          },
        }),
      ),
    ),
  );

  submitInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteUserSubmitted),
      exhaustMap(({ user }) =>
        from(this.teamUI.confirmUserInvite()).pipe(
          filter(role => role === 'ok'),
          switchMap(() => this.teamUI.showProcessing()),
          map(() => sendUserInvite({ user })),
        ),
      ),
    ),
  );

  addNewUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserSubmitted),
      exhaustMap(({ user }) =>
        from(this.team.addNewUser(user)).pipe(
          map(({ userId }) => addUserSuccess({ userId, user })),
          take(1),
          catchError(error => of(addUserFailure({ error }))),
        ),
      ),
    ),
  );

  reloadToCaptureNewlyAddedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserSuccess),
      delay(500),
      map(() => loadAllUsers()),
    ),
  );

  sendUserInviteOnAddTeamLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addUserSuccess),
      excludeRoles(this.store.select(authenticatedRole), 'installerLead', 'installerMember'),
      filter(({ user }) => user.role === 'INSTALLER_TEAM_LEAD'),
      map(({ user }) => sendUserInvite({ user })),
    ),
  );

  sendInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendUserInvite),
      switchMap(({ user }) =>
        this.team.sendUserInvite(user.email).pipe(
          map(connection => sendUserInviteSuccess({ connection })),
          catchError(error => of(sendUserInviteFailure({ error }))),
        ),
      ),
    ),
  );

  closeLoadingOnResult$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendUserInviteSuccess, sendUserInviteFailure),
        exhaustMap(() => this.teamUI.closeProcessing().pipe(catchError(error => EMPTY))),
      ),
    { dispatch: false },
  );

  handleInviteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendUserInviteFailure),
      switchMap(() => this.teamUI.showUserInviteFailed()),
      filter(role => role === 'cancel'),
      map(() => cancelInviteUser()),
    ),
  );

  toastInviteUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendUserInviteSuccess),
        exhaustMap(() => this.teamUI.toastResult('User Invited Successfully!')),
      ),
    { dispatch: false },
  );

  toastAddUserSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addUserSuccess),
        exhaustMap(() => this.teamUI.toastResult('User Added Successfully!')),
      ),
    { dispatch: false },
  );

  toastAddUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addUserFailure),
        exhaustMap(({ error }) => this.teamUI.toastResult('Failed to Add User', true)),
      ),
    { dispatch: false },
  );

  closeInviteUserModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelInviteUser, addUserSuccess, sendUserInviteSuccess),
        tap(() => this.teamUI.closeInviteUserModal()),
      ),
    { dispatch: false },
  );

  viewProjectManagersPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewProjectManagers),
        tap(() => this.nav.navigateForward(['app/team/managers'])),
      ),
    { dispatch: false },
  );

  viewTeamInstallersPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewTeamInstallers),
        tap(() => this.nav.navigateForward(['app/team/installers'])),
      ),
    { dispatch: false },
  );
}
