import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceableWorkOrderItem } from '../../domains/models/invoiceable-work-order-item';
import { WorkOrderItem } from '../../domains/work/work-order-item/work-order-item.model';
import { totalNormalizedUnits } from './total-normalized-units.pipe';

export const numberOfUnitsStillPending = (item?: WorkOrderItem | InvoiceableWorkOrderItem) =>
  (item?.numberOfUnits ?? 0) - (item?.numberOfUnitsAlreadyInvoiced ?? 0);

export const numberOfUnitsStillRemaining = (item?: InvoiceableWorkOrderItem) =>
  (item?.numberOfUnits ?? 0) -
  (item?.numberOfUnitsAlreadyInvoiced ?? 0) -
  (item?.applicableChangeOrders != null ? totalNormalizedUnits(item.applicableChangeOrders, item.pricePerUnit) : 0);

@Pipe({ name: 'numberOfUnitsStillPending' })
export class NumberOfUnitsStillPendingPipe implements PipeTransform {
  transform(item: WorkOrderItem): number {
    return numberOfUnitsStillPending(item);
  }
}
