import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LetModule } from '@rx-angular/template/let';
import { ItemChooserListModule } from '../../shared/components/item-chooser-list/item-chooser-list.module';
import { ClaimProductInstallationTypeModalComponent } from './claim-product-installation-type-modal.component';
import { ClaimProductInstallationTypeModalEffects } from './claim-product-installation-type-modal.effects';
import { MODAL_NAME } from './store/modal.name';
import { reducer } from './store/modal.state';



@NgModule({
  declarations: [
    ClaimProductInstallationTypeModalComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    ItemChooserListModule,
    LetModule,
    StoreModule.forFeature(MODAL_NAME, reducer),
    EffectsModule.forFeature([ClaimProductInstallationTypeModalEffects])
  ],
  exports: [
    ClaimProductInstallationTypeModalComponent
  ]
})
export class ClaimProductInstallationTypeModalModule { }
