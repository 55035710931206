import { createAction, props } from '@ngrx/store';
import { EmployeeInstaller, Installer, InstallerTeamLead } from '../../users';
import { PendingFullWorkOrder } from '../models/pending-full-work-order';
import { WorkOrder } from '../work-order/work-order.model';

export const addNewWorkOrder = createAction('[Work] Add New Work Order');

export const editCurrentWorkOrder = createAction('[Work] Edit Current Work Order');

export const duplicateAsDraft = createAction('[Work] Duplicate Work Order');

export const closeWorkOrder = createAction('[Work] Close Work Order');

export const deletePendingWorkOrder = createAction(
  '[Work] Delete Pending Work Order',
  props<{ workOrder: WorkOrder }>(),
);

export const editWorkOrderInitiated = createAction(
  '[Work] Edit Work Order Initiated',
  props<{ correlationId: string }>(),
);
export const editLoadedWorkOrder = createAction('[Work] Edit Loaded Work Order', props<{ workOrder: WorkOrder }>());

export const pendingWorkOrderSaved = createAction(
  '[Work] Pending Work Order Saved',
  props<{ workOrder: Partial<PendingFullWorkOrder> }>(),
);

export const updateEditedWorkOrder = createAction(
  '[Work] UpdateEditedWorkOrder',
  props<{ workOrder: Partial<PendingFullWorkOrder> }>(),
);

export const saveInProgressWorkOrder = createAction(
  '[Work] Save In Progress Work Order',
  props<{ workOrder: PendingFullWorkOrder }>(),
);

export const saveInProgressWorkOrderSuccess = createAction(
  '[Work] Save In Progress Work Order Success',
  props<{ workOrder: WorkOrder }>(),
);

export const saveInProgressWorkOrderFailure = createAction(
  '[Work] Save In Progress Work Order Failure',
  props<{ workOrder: PendingFullWorkOrder; error?: any }>(),
);

export const reloadDataOnWorkOrderSaved = createAction(
  '[Work] Reload Data on Saved',
  props<{ correlationId: string }>(),
);

export const updateEditedWorkOrderWithLatest = createAction(
  '[Work] Update Edited with Latest',
  props<{ correlationId: string }>(),
);

export const cancelEditWorkOrder = createAction(
  '[Work] Cancel Edit Work Order',
  props<{ workOrder: Partial<WorkOrder> }>(),
);

export const deleteDraftWorkOrder = createAction(
  '[Work] Delete Draft Work Order',
  props<{ workOrder: Partial<WorkOrder> }>(),
);

export const saveEditedWorkOrder = createAction(
  '[Work] Save Edited Work Order',
  props<{ workOrder: Partial<WorkOrder> }>(),
);

export const saveAndExitEditingWorkOrder = createAction(
  '[Work] Save And Exit Editing Work Order',
  props<{ workOrder: Partial<WorkOrder> }>(),
);

export const showSelectProjectManager = createAction(
  '[Work] Show Select Project Manager',
  props<{ workOrder: WorkOrder; event: Event }>(),
);


export const showSelectEmployee = createAction(
  '[Work] Show Select Employee',
  props<{ workOrder: WorkOrder; event: Event }>(),
);

export const confirmCancelWorkOrder = createAction(
  '[Work] Confirm Cancel Work Order',
  props<{ workOrder: Partial<WorkOrder> }>(),
);

export const cancelWorkOrder = createAction('[Work] Cancel Work Order', props<{ workOrder: Partial<WorkOrder> }>());

export const republishEditedWorkOrder = createAction(
  '[Work] Republish Work Order',
  props<{ workOrder: Partial<PendingFullWorkOrder> }>(),
);

export const continueToNextEditStep = createAction(
  '[Work] Continue to Next Edit Step',
  props<{ workOrder: Partial<WorkOrder> }>(),
);

export const goToEditStep = createAction('[Work] Go To Edit Step', props<{ step: string }>());

export const backOneEditStep = createAction('[Work] Go Back an Edit Step');

export const publishWorkOrder = createAction(
  '[Work] Publish Work Order',
  props<{ workOrder: Partial<PendingFullWorkOrder> }>(),
);
export const publishToPublicNetwork = createAction(
  '[Work] Publish To Public Network',
  props<{ workOrder: Partial<WorkOrder> }>(),
);
export const publishToEmployeeInstallers = createAction(
  '[Work] Publish To Employee Installers',
  props<{ workOrder: Partial<WorkOrder> }>(),
);
export const publishToConnections = createAction(
  '[Work] Publish To Connections',
  props<{ workOrder: Partial<WorkOrder>; installers?: InstallerTeamLead[] }>(),
);
export const publishedWorkOrder = createAction(
  '[Work] Published Work Order',
  props<{ workOrder: Partial<WorkOrder> }>(),
);

export const publishedToEmployeeInstaller = createAction(
  '[Work] Published To Employee Installer ',
  props<{ workOrder: Partial<WorkOrder>; installer: EmployeeInstaller }>(),
);

export const publishedToConnections = createAction(
  '[Work] Published To Connections ',
  props<{ workOrder: Partial<WorkOrder>; installers: string[] }>(),
);

export const publishAssumedWorkOrder = createAction(
  '[Work] Published Assumed Work Order',
  props<{ workOrder: Partial<WorkOrder>; installer: Installer }>(),
);

export const publishUnAssumedWorkOrder = createAction(
  '[Work] Published UnAssumed Work Order',
  props<{ workOrder: Partial<WorkOrder> }>(),
);

export const publishCompleted = createAction('[Work] Publish Completed', props<{ workOrder: WorkOrder }>());

export const workOrderInvalid = createAction('[Work] Work Order Invalid', props<{ errors: any }>());

export const installerInfered = createAction(
  '[Work] Installer Infered For WorkOrder',
  props<{ installerId: string; workOrderId: string }>(),
);
