import { Observable } from 'rxjs';
import { Channel, Event } from 'stream-chat';
import { DefaultStreamChatGenerics } from 'stream-chat-angular';
import { EventTypes } from 'stream-chat/src/types';

export const createChannelEventListener = (channel: Channel<DefaultStreamChatGenerics>, eventType?: EventTypes) =>
  new Observable<Event>(subscriber =>
    subscriber.add(
      eventType ? channel.on(eventType, ev => subscriber.next(ev)) : channel.on(ev => subscriber.next(ev)),
    ),
  );
