import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { CustomListPopoverComponent } from '../../../shared/popovers/custom-list-popover/custom-list-popover.component';
import { ListOption } from '../../../shared/popovers/types/list-options';
import { User } from '../../users';
import { KudosReason } from './kudos.model';

@Injectable()
export class KudosUIService {
  constructor(private readonly popovers: PopoverController) {}

  async promptKudosReason(installer: User): Promise<OverlayEventDetail<ListOption[]>> {
    const popover = await this.popovers.create({
      component: CustomListPopoverComponent,
      cssClass: 'multi-select-popover',
      componentProps: {
        title: 'Give Kudos',
        subtitle: `How did ${installer.firstName} go above and beyond? Select all that apply.`,
        multi: true,
        doneText: 'Give Kudos',
        listOptions: [
          {
            name: KudosReason.Dependability,
            label: 'Dependable',
          },
          {
            name: KudosReason.Professionalism,
            label: 'Professionalism',
          },
          {
            name: KudosReason.Quality,
            label: 'Exceptional Quality',
          },
          {
            name: KudosReason.Punctuality,
            label: 'Punctuality',
          },
          {
            name: KudosReason.Attitude,
            label: 'Attitude',
          },
        ],
      },
    });

    await popover.present();
    return await popover.onDidDismiss();
  }
}
