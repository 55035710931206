import { createAction, props } from '@ngrx/store';
import { CalendarName, CalendarView, SettingsState } from './settings.state';

export const calendarSettingsRestored = createAction(
  '[CORE: Calendar] (Settings) Restored',
  props<{ restored: SettingsState }>(),
);

export const calendarViewChanged = createAction('[CORE: Calendar] (View) Changed', props<{ view: CalendarView }>());

export const calendarToggled = createAction('[CORE: Calendar] (Calendar) Toggled', props<{ name: CalendarName }>());
