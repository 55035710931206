import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { WorkOrderItem } from './work-order-item.model';

export const {
  initialState: initialWorkOrderItemState,
  facade: WorkOrderItemFacadeBase,
  selectors: {
    selectEntities: workOrderItemEntities,
    selectAll: allWorkOrderItems,
    selectAllSorted: sortedWorkOrderItems,
  },
  actions: {
    loadAll: loadAllWorkOrderItems,
    loadAllSuccess: loadAllWorkOrderItemsSuccess,
    loadManySuccess: loadManyWorkOrderItemsSuccess,
    create: createWorkOrderItem,
    replace: replaceWorkOrderItem,
    replaceSuccess: replaceWorkOrderItemSuccess,
    clear: clearWorkOrderItems,
  },
} = buildState(WorkOrderItem);

export function workOrderItemReducer(state = initialWorkOrderItemState): IEntityState<WorkOrderItem> {
  return state;
}
