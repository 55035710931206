import { createAction, props } from '@ngrx/store';
import { DetailedInstaller } from '../../models/detailed-installer';
import { WorkOrder } from '../work-order/work-order.model';

export const reviewInstallers = createAction('[Work] Review Installers', props<{ workOrder: WorkOrder }>());

export const reviewInstaller = createAction(
  '[Installer Review] Review Installer',
  props<{ installer: DetailedInstaller }>(),
);

export const installerReviewModalAutoClosed = createAction('[Installer Review] (Installer Review Modal) Auto Closed');

export const installerReviewCompleted = createAction('[Installer Review] (Installer Review Modal) Completed');

export const installersReviewCompleted = createAction('[Installer Review] (Installers Review Modal) Completed');

export const deselectCurrentInstaller = createAction('[Installer Review] Deselect Current Installer');
