import { AfterContentChecked, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NegotiationsFacade } from '~gc/domains/negotiations/negotiations.facade';
import { WorkOrderRelationsFacade } from '~gc/domains/work-order-relations.facade';
import { DetailedInstaller } from '../../../../domains/models/detailed-installer';
import { Installer, UsersUIFacade } from '../../../../domains/users';
import { JumpstarterFavoritesFacade } from '../../../../domains/users/jumpstarter-favorites/jumpstarter-favorites.facade';
import { UserFacade } from '../../../../domains/users/user/user.facade';
import { InstallerReviewFacade } from '../../../../domains/work/installer-review/installer-review.facade';
import { WorkOrderItemFacade } from '../../../../domains/work/work-order-item/work-order-item.facade';
import { WorkOrderFacade } from '../../../../domains/work/work-order/work-order.facade';

@Component({
  selector: 'gc-review-installer-modal',
  templateUrl: './review-installer-modal.component.html',
  styleUrls: ['./review-installer-modal.component.scss'],
})
export class ReviewInstallerModalComponent {
  @Input() isJumpstarterOffer!: boolean;

  constructor(
    public user: UserFacade,
    public usersUI: UsersUIFacade,
    public installerReview: InstallerReviewFacade,
    public workOrder: WorkOrderFacade,
    public workOrderItems: WorkOrderItemFacade,
    public negotiations: NegotiationsFacade,
    public modalGC: ModalController,
    public workOrderRelations: WorkOrderRelationsFacade,
    public favorites: JumpstarterFavoritesFacade,
  ) {}

  showInstallerProfile(installer: DetailedInstaller) {
    this.usersUI.showInstallerProfile(installer as Installer);
  }
}
