import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { Company } from '~gc/domains/users/companies/company.model';
import { MatchedInstaller } from '~gc/domains/work/matched-installer/matched-installer.model';
import { compose } from '../shared/utils/compose.util';
import { countOf } from '../shared/utils/func';
import { assign, matchByKey, matchOptionalByOptionalId } from '../shared/utils/utils';
import { authenticatedUser } from './app/auth/auth.selectors';
import { DetailedInstaller } from './models/detailed-installer';
import { ManagedWorkOrderHeader } from './models/managed-work-order-header';
import { NegotiationItem } from './negotiations/negotiation-item/negotiation-item.model';
import { Negotiation } from './negotiations/negotiation/negotiation.model';
import { ProductInstallationType } from './training/product-installation-types/product-installation-types.model';
import { allProductInstallationTypes } from './training/product-installation-types/product-installation-types.state';
import { allCompanies } from './users/companies/company.state';
import { HammerRating } from './users/hammer-rating/hammer-rating.model';
import { Installer, InstallerTeamLead, isInstallerLead, Manager, sortUsers, User } from './users/user/user.model';
import { allInstallers, allManagers, combineHammerRatingsAndTypeNames } from './users/user/user.selectors';
import { userEntities } from './users/user/user.state';
import {
  negotiationItemsForCurrentWorkOrder,
  negotiationsForCurrentWorkOrder,
} from './work-order-negotiation.selectors';
import { currentInstaller } from './work/installer-review/installer-review.selectors';
import { currentMatchedInstallerForWorkOrder } from './work/matched-installer/matched-installer.selectors';
import { allMatchedInstallers } from './work/matched-installer/matched-installer.state';
import { WorkOrder } from './work/work-order/work-order.model';
import { allWorkOrders, currentWorkOrder } from './work/work-order/work-order.state';
import { isStatus } from './work/work.maps';

export const findKeyHammerRating = (hammerRatings: HammerRating[] = [], workOrder: WorkOrder): number =>
  (hammerRatings.find(rating => rating.productInstallationTypeId === workOrder?.productInstallationTypeId) || {})
    .value || 0;

// TODO: makeDetailedInstaller does not handle undefined installer well
// in the case where there is a matched installer but no user present
// this fails. We need to handle this case better (likely server side is better)
export const makeDetailedInstaller =
  (
    workOrder: WorkOrder,
    negotiations: Negotiation[],
    negotiationItems: NegotiationItem[],
    types: ProductInstallationType[],
  ) =>
  (installer: Installer) => ({
    ...installer,
    hammerRatings: combineHammerRatingsAndTypeNames(installer.hammerRatings, types),
    keyHammerRating: findKeyHammerRating(installer.hammerRatings, workOrder),
    negotiation: negotiations
      .filter(matchByKey(installer.id, 'installerId'))
      .find(matchByKey(workOrder.id, 'workOrderId')),
    negotiationItems: negotiationItems
      .filter(matchByKey(installer.id, 'installerId'))
      .filter(matchByKey(workOrder.id, 'workOrderId')),
  });

export const joinInstallerNegotiationsAndProductInstallTypes = (
  workOrder: WorkOrder | undefined,
  installers: Installer[],
  negotiations: Negotiation[],
  negotiationItems: NegotiationItem[],
  types: ProductInstallationType[],
): DetailedInstaller[] =>
  workOrder
    ? installers // TODO: Review matching criteria here!!
        .filter(
          installer =>
            negotiations.some(negotiation => negotiation.installerId === installer.id) ||
            negotiationItems.some(negotiationItems => negotiationItems.installerId === installer.id),
        )
        .map(makeDetailedInstaller(workOrder, negotiations, negotiationItems, types))
        .filter(user => {
          return user.keyHammerRating >= (workOrder?.minimumHammerRatingThreshold || 0);
        })
    : [];

export const makeManagedWorkOrder =
  (managers: Manager[]) =>
  (workOrder: WorkOrder): ManagedWorkOrderHeader =>
    compose(assign<WorkOrder>('projectManager', managers.find(matchOptionalByOptionalId(workOrder?.projectManagerId))))(
      workOrder,
    ) as ManagedWorkOrderHeader;

export const joinWorkOrdersToManagersAsHeaders = (
  workOrders: WorkOrder[] = [],
  managers: Manager[] = [],
): ManagedWorkOrderHeader[] => workOrders.map(makeManagedWorkOrder(managers));

export const findCurrentWorkOrderCompany = (workOrder?: WorkOrder, companies: Company[] = []): Company | undefined =>
  workOrder ? companies.find(company => company.id === workOrder?.companyId) : undefined;

export const negotiatingInstallersForCurrentWorkOrder = createSelector(
  currentWorkOrder,
  allInstallers,
  negotiationsForCurrentWorkOrder,
  negotiationItemsForCurrentWorkOrder,
  allProductInstallationTypes,
  joinInstallerNegotiationsAndProductInstallTypes,
);

export const managedWorkOrderHeaders = createSelector(allWorkOrders, allManagers, joinWorkOrdersToManagersAsHeaders);
export const currentWorkOrderCompany = createSelector(currentWorkOrder, allCompanies, findCurrentWorkOrderCompany);

export interface IHasWorkOrder {
  workOrderId: string;
}

export const byWorkOrder =
  (workOrder: WorkOrder) =>
  (has: IHasWorkOrder): boolean =>
    has.workOrderId === workOrder?.id;

export const isMatchedInstaller =
  (match: MatchedInstaller) =>
  (installer: Installer): boolean =>
    installer.id === match?.installerId;

export const toMatchedInstaller =
  (installers: Installer[] = []) =>
  (match: MatchedInstaller): Installer | undefined =>
    installers.find(isMatchedInstaller(match));

export const awardedToWorkOrder =
  (workOrder: WorkOrder) =>
  (installer: Installer): boolean =>
    installer.id === workOrder?.awardedToInstallerId;

export const findAwardedInstaller = (
  workOrder?: WorkOrder,
  installers: Installer[] = [],
): InstallerTeamLead | undefined =>
  workOrder ? (installers.find(awardedToWorkOrder(workOrder)) as InstallerTeamLead) : undefined;

export const findMatchedInstallers =
  (matchFilter: (matchedInstaller: MatchedInstaller) => boolean = () => true) =>
  (matchedInstallers: MatchedInstaller[] = [], installers: IEntityDictionary<User>): Installer[] =>
    sortUsers(matchedInstallers.filter(matchFilter).map(match => installers[match.installerId] as Installer));

export const findInterestedMatchedInstallers = (
  workOrder?: WorkOrder,
  matchedInstallers: MatchedInstaller[] = [],
): MatchedInstaller[] => (workOrder ? matchedInstallers.filter(byWorkOrder(workOrder)) : []);

export const findNonPendingMatchedInstallers = (matchedInstallers: MatchedInstaller[] = []): MatchedInstaller[] =>
  matchedInstallers.filter(installer => installer.response !== 'PENDING');

export const currentWorkOrderInstaller = createSelector(currentWorkOrder, allInstallers, findAwardedInstaller);

export const currentWorkOrderMatchedInterestedInstallers = createSelector(
  currentWorkOrder,
  allMatchedInstallers,
  findInterestedMatchedInstallers,
);

export const currentWorkOrderMatchedInterestedNonPendingInstallers = createSelector(
  currentWorkOrderMatchedInterestedInstallers,
  findNonPendingMatchedInstallers,
);

export const currentWorkOrderMatchedInstallers = (
  matchFilter: (matchedInstaller: MatchedInstaller) => boolean = () => true,
) => createSelector(currentWorkOrderMatchedInterestedInstallers, userEntities, findMatchedInstallers(matchFilter));

export const currentWorkOrderMatchedInterestedInstallerCount = createSelector(
  currentWorkOrderMatchedInterestedInstallers,
  countOf,
);

export const currentWorkOrderMatchedInterestedNonPendingInstallerCount = createSelector(
  currentWorkOrderMatchedInterestedNonPendingInstallers,
  countOf,
);

export const currentWorkOrderMatchedInterestedNonPendingInstallerDetails = createSelector(
  currentWorkOrderMatchedInstallers(matchedInstaller => matchedInstaller.response !== 'PENDING'),
  currentWorkOrder,
  negotiationsForCurrentWorkOrder,
  negotiationItemsForCurrentWorkOrder,
  allProductInstallationTypes,
  (
    installers: Installer[],
    workOrder: WorkOrder | undefined,
    negotiations: Negotiation[],
    negotiationItems: NegotiationItem[],
    types: ProductInstallationType[],
  ) => (workOrder ? installers.map(makeDetailedInstaller(workOrder, negotiations, negotiationItems, types)) : []),
);
export const currentOrAllNegotiatingInstallersForCurrentWorkOrder = createSelector(
  negotiatingInstallersForCurrentWorkOrder,
  currentInstaller,
  (all: any[], current?: any): Installer[] => (current ? [current] : all),
);

export const userAllowedPrivateDetails = createSelector(
  authenticatedUser,
  currentWorkOrder,
  currentMatchedInstallerForWorkOrder,
  (user?: User, workOrder?: WorkOrder, matchedInstaller?: MatchedInstaller): boolean =>
    !!workOrder && isStatus(workOrder.status, 'PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES')
      ? isInstallerLead(user)
        ? !!matchedInstaller
        : true
      : true,
);
