import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { environment } from '../../../../../environments/environment';
import { ConfigService } from '../../../services/config.service';
import { ModalService } from '../../../services/modal.service';
import { DwollaAccount } from './dwolla-account.entity';
import {
  dwollaAddBeneficialOwnerSuccess,
  dwollaBeneficialOwnershipCertified,
  dwollaCustomerCreateSuccess,
  dwollaDocumentUploadSuccess,
  dwollaDropInFailure,
  dwollaGenericSuccess,
  dwollaInitFailure,
} from './dwolla.actions';
import { DwollaCustomerDetails } from './models/customer-details.model';
import { dwollaResponseIsFailure, DwollaSuccessCallbackArg } from './models/dwolla.model';

@Injectable({ providedIn: 'root' })
export class DwollaService {
  customerCreate?: IonModal;
  initialized = false;

  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigService,
    private readonly modals: ModalService,
    private readonly store: Store,
  ) {
    this.init();
  }

  private init() {
    // TODO: We need to figure out what typing the dwolla object has on window here
    // tslint:disable:no-string-literal
    if ((window as any)['dwolla'] && !this.initialized) {
      (window as any)['dwolla'].configure({
        environment: environment.dwolla,
        styles: '/styles.css',
        token: (req: string) => (
          console.log(req), this.http.post(`${this.config.host}/dwolla/customers/token`, req).toPromise()
        ),
        success: (res: DwollaSuccessCallbackArg) => this.handleSuccess(res),
        error: (err: Error) => this.handleError(err),
      });
      this.initialized = true;
    }
    // tslint:enable:no-string-literal
  }

  private async handleSuccess(res: DwollaSuccessCallbackArg) {
    console.log('DwollaSuccess', res);
    if (dwollaResponseIsFailure(res)) {
      this.store.dispatch(dwollaDropInFailure({ code: res.response.code, message: res.response.message }));
    } else {
      const customerResource = res.resource.split('/').pop();

      const action =
        res.resource === 'customers'
          ? dwollaCustomerCreateSuccess({ location: res.response.location })
          : (
              {
                'beneficial-ownership': () => dwollaBeneficialOwnershipCertified({ status: res.response.status }),
                documents: () => dwollaDocumentUploadSuccess(),
                'beneficial-owners': () => dwollaAddBeneficialOwnerSuccess(),
              }[customerResource!] || (() => dwollaGenericSuccess({ resource: res.resource }))
            )(); // TODO: Figure out how to eliminate the non-nullish assertion here

      this.store.dispatch(action);
    }

    return res;
  }

  private async handleError(err: Error) {
    console.error('DwollaErr', err);
    this.store.dispatch(dwollaInitFailure());
    return err;
  }

  loadDwollaAccount() {
    return this.http.get<DwollaAccount>(`${this.config.host}/dwolla/accounts`);
  }

  getAccountDetails(id: string) {
    return this.http.get<DwollaCustomerDetails>(`${this.config.host}/dwolla/accounts/${id}`);
  }

  transferBalance() {
    return this.http.post<DwollaAccount>(`${this.config.host}/dwolla/accounts/transfer`, {});
  }

  async dismissModal() {
    return this.customerCreate ? this.customerCreate?.dismiss().then(() => true) : false;
  }

  async openModal(modalGC: IonModal) {
    this.init();
    this.customerCreate = modalGC;
    await modalGC.present();
    this.customerCreate.onWillDismiss().then(() => (this.customerCreate = undefined));
  }
}
