import { AbstractControl, ValidationErrors } from '@angular/forms';
import { InvoiceableWorkOrderItem } from '../../domains/models/invoiceable-work-order-item';

export const amountSurpassesChangeOrderTotal =
  (item?: InvoiceableWorkOrderItem) =>
  (target: AbstractControl): ValidationErrors | null =>
    !target.value ||
    (target.value ?? 0) * (item?.pricePerUnit ?? 0) >=
      (item?.applicableChangeOrders?.reduce(
        (sum, { adjustedNumberOfUnits, adjustedPricePerUnit }) => sum + adjustedNumberOfUnits * adjustedPricePerUnit,
        0,
      ) ?? 0)
      ? null
      : { minAmount: true };
