<ng-container *rxLet="context$; let context">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button
          color="dark"
          (click)="modalGC.dismiss(undefined, 'cancel')"
          data-cy="close-claim-product-installation-type-modal"
        >
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title data-cy="claim-product-installation-type-title">Product Type</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content data-cy="product-type-list">
    <gc-item-chooser-list
      [items]="context.items"
      noItemsMessage="No additional product types to claim."
      (added)="popover.present($event.$event); adding($event.value)"
    ></gc-item-chooser-list>
    <ion-popover side="left" #popover (didDismiss)="cancelled($event)" data-cy="product-type-confirmation-popover">
      <ng-template>
        <ion-datetime
          [max]="today"
          presentation="date"
          (ionChange)="addProductInstallationType(popover, $event)"
          data-cy="product-type-confirmation-date-picker"
        ></ion-datetime>
      </ng-template>
    </ion-popover>
  </ion-content>
  <ion-footer class="p-3">
    <ion-button
      mode="ios"
      color="dark"
      expand="block"
      (click)="modalGC.dismiss(undefined, 'ok')"
      data-cy="product-type-submit-button"
      >Submit
    </ion-button>
  </ion-footer>
</ng-container>
