import { createAction, props } from '@ngrx/store';
import { WorkOrder } from './work-order/work-order.model';

export const workRefreshIntervalTicked = createAction('[DOMAINS: Work - Polling] (Work Refresh Interval) Ticked');

export const workOrdersRefreshIntervalTicked = createAction(
  '[DOMAIN: Work - Polling] (Work Orders Refresh Interval) Ticked',
);

export const workOrdersSearchRefreshIntervalTicked = createAction(
  '[DOMAIN: Work - Polling] (Work Orders Search Refresh Interval) Ticked',
);

export const workOrderRefreshIntervalTicked = createAction(
  '[DOMAINS: Work - Polling] (Work Order Refresh Interval) Ticked',
  props<{ workOrder: WorkOrder }>(),
);
