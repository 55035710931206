import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { modalController } from '@ionic/core';
import { ProfileFacade } from '../../domains/users';

@Component({
  selector: 'gc-payment-provider-modal',
  templateUrl: './payment-provider-modal.component.html',
  styleUrls: ['./payment-provider-modal.component.scss'],
})
export class PaymentProviderModalComponent {
  @Input() imageSrc!: string;

  constructor(public readonly profile: ProfileFacade, private readonly modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }
}
