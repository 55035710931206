import { createAction, props } from '@ngrx/store';

export const todayShown = createAction('[CORE: Calendar] (Today) Shown');

export const previousDayShown = createAction('[CORE: Calendar] (Previous Day) Shown');

export const nextDayShown = createAction('[CORE: Calendar] (Next Day) Shown');

export const calendarTitleCleared = createAction('[CORE: Calendar] (Title) Cleared');

export const calendarTitleChanged = createAction('[CORE: Calendar] (Title) Changed', props<{ title: string }>());

export const calendarDateChanged = createAction('[CORE: Calendar] (Date) Changed', props<{ date: number }>());
