<div class="profile-content-section" data-cy="training-list-area">
  <div class="flex justify-content-between align-items-center" data-cy="training-list-title">
    <h2>Trainings</h2>
  </div>
</div>

<ng-container *ngIf="groups.length > 0; else noTrainings">
  <ion-card *ngFor="let group of groups" mode="ios" [attr.data-cy]="group.category.type + '-training-list'">
    <ion-card-header>
      <ion-label data-cy="training-list-category-title">{{ group.category.type }}</ion-label>
      <ion-button
        *ngIf="canEditProfile"
        class="add"
        fill="outline"
        size="small"
        mode="ios"
        color="dark"
        (click)="added.emit(group.category)"
        [attr.data-cy]="group.category.type + '-add-training-button'"
      >
        <ion-icon slot="start" name="add-sharp"></ion-icon>
        Add
      </ion-button>
    </ion-card-header>
    <ion-item *ngFor="let training of group.trainingClaims" lines="full" [attr.data-cy]="training.training.certification + 'training-item'">
      <fa-icon
        slot="start"
        [icon]="training.statusIcon"
        [style.color]="'var(--ion-color-' + training.statusColor + ')'"
        style="margin-right: 10px"
        [attr.data-cy]="'training-status-icon-' + training.statusIcon.iconName"
      ></fa-icon>
      <ion-text class="ion-text-wrap" slot="start" [color]="training.trainingClaim.isVerified ? 'dark' : 'medium'">{{ training.training.certification }}</ion-text>

      <ion-buttons *ngIf="!training.trainingClaim.isVerified && canEditProfile" slot="end">
        <ion-button mode="md" color="danger" (click)="removed.emit(training.trainingClaim)" data-cy="remove-training-button">
          <ion-icon name="trash-outline" slot="start" size="small" color="danger"></ion-icon>
          Remove
        </ion-button>
      </ion-buttons>
    </ion-item>
  </ion-card>
  <!-- Spacer below is required to create space for elements at the end of the list in this scrollable modal.  -->
  <div style="height: 20px;"></div>
</ng-container>

<ng-template #noTrainings>
  <ng-container *ngIf="!canEditProfile; else myProfileEmptyTrainingMessage">
    <div class="alert alert__warning text-center mr-20 ml-20" data-cy="company-empty-training-list">No trainings found.</div>
  </ng-container>
  <ng-template #myProfileEmptyTrainingMessage>
    <div class="alert alert__warning text-center mr-20 ml-20 mt-20" data-cy="installer-empty-training-list">
      <strong>{{ selectedProductInstallationTypeClaim?.type | titlecase }}</strong> does not currently have
      trainings available to add.
    </div>
  </ng-template>

</ng-template>
