import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { WorkOrderTag } from '../../../../domains/work/work-order-tag/work-order-tag.model';
import { WorkOrder } from '../../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-work-order-tags',
  templateUrl: './work-order-tags.component.html',
  styleUrls: ['./work-order-tags.component.scss'],
})
export class WorkOrderTagsComponent {
  @Input() tags!: WorkOrderTag[];
  @Input() workOrder!: WorkOrder;
  @Input() permissions!: string[];
  @Output() added = new EventEmitter<{ tag: WorkOrderTag; workOrder: WorkOrder }>();
  @Output() deleted = new EventEmitter<{ tag: WorkOrderTag; workOrder: WorkOrder }>();

  tagName = new UntypedFormControl(null, Validators.required);
}
