<ion-list>
  <ion-item-sliding *ngFor="let phoneNumber of phoneNumbers" #sliding (click)="toggleOpenState(sliding)">
    <ion-item>
      <ion-label>{{ phoneNumber.number | phoneFormat }}</ion-label>
      <!--<ion-note slot="end"> </ion-note>-->
      <ion-buttons>
        <ion-icon [name]="phoneNumber.type | phoneTypeIcon" size="large"></ion-icon>
        <ion-button fill="clear" color="dark" slot="end">
          <ion-icon name="ellipsis-vertical" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-item>
    <ion-item-options side="end" (ionSwipe)="remove.emit(phoneNumber)">
      <ion-item-option color="danger" (click)="remove.emit(phoneNumber)" expandable>
        <ion-icon name="trash-outline" slot="icon-only"></ion-icon>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>

  <form *ngIf="form" [formGroup]="form">
    <ion-item lines="none" [color]="submitted && form.invalid">
      <!--<ion-input
        formControlName="number"
        [gcMask]="mask"
        placeholder="Add a Phone Number"
        label="Add a Phone Number"
        labelPlacement="floating"
        (ionBlur)="add()"
        [color]="submitted && form.invalid ? 'danger' : undefined"
      ></ion-input>-->
      <ion-input
        formControlName="number"
        placeholder="(xxx) xxx-xxxx"
        label="Add a Phone Number"
        labelPlacement="floating"
        [maskito]="phoneMask"
        [maskitoElement]="maskPredicate"
        [color]="submitted && form.invalid ? 'danger' : undefined"
      ></ion-input>
      <ion-select
        formControlName="type"
        placeholder="Type"
        interface="action-sheet"
        label="Phone Number Type"
        labelPlacement="floating"
      >
        <ion-select-option value="MOBILE">
          <ion-icon name="phone-portrait"></ion-icon>
          Mobile
        </ion-select-option>
        <ion-select-option value="OFFICE">
          <ion-icon name="call"></ion-icon>
          Office
        </ion-select-option>
        <ion-select-option value="OTHER">
          <ion-icon name="call"></ion-icon>
          Other
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-button class="ion-margin" expand="block" color="light" (click)="add()" mode="ios"
      >Save Phone Number
    </ion-button>
  </form>
</ion-list>
