import { routerNavigatedAction } from '@ngrx/router-store';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addNewWorkOrder,
  backOneEditStep,
  continueToNextEditStep,
  editLoadedWorkOrder,
  goToEditStep,
  installerInfered,
  publishCompleted,
} from './work-order-edit/work-order-edit.actions';
import { viewWorkOrderFromModal, viewWorkOrderModalClosed } from './work-order-ui.actions';
import { createWorkOrderSuccess, selectWorkOrderById } from './work-order/work-order.state';
import {
  clearWorkOrderSearchCriteria,
  searchWorkOrders,
  selectDetailArea,
  selectWorkArea,
  viewOrdersByStatus,
  viewPendingWorkOrders,
  viewSearchWorkOrders,
} from './work.actions';
import {
  EDIT_STEPS,
  SELECTED_AREA_LABEL_MAP,
  SELECTED_DETAIL_AREA_LABEL_MAP,
  STATUS_GROUP_LABEL_MAP,
} from './work.maps';

// -----===[ SUPPORT TYPES ]===-----
export type SelectedArea = keyof typeof SELECTED_AREA_LABEL_MAP;
export type StatusGroup = keyof typeof STATUS_GROUP_LABEL_MAP;
export type DetailArea = keyof typeof SELECTED_DETAIL_AREA_LABEL_MAP;

// -----===[ STATE INTERFACE ]===-----
// A state interface defines the shape of a piece of state. This
// involves not only which properties exist, but also which ones
// MUST exist vs. those which may be optional and "filled in"
// at a later time.

export interface WorkState {
  selectedWorkArea: SelectedArea;
  selectedDetailArea: DetailArea;
  currentStatusGroup?: StatusGroup;
  searchCriteria?: string;
  currentEditingStep: string;
  isAdding: boolean;
  isShowingDetailModal: boolean;
  inferableInstallers: {
    [workOrderId: string]: string;
  };
}

// -----===[ INITIAL STATE ]===-----
// Initial state is the baseline state that will be populated when NgRx first
// initializes. This state is used to set defaults, which in turn may drive
// the default look, feel, location, and other "state" of the user interface,
// among other things.

export const initialWorkState: WorkState = {
  selectedWorkArea: 'workOrders',
  selectedDetailArea: 'items',
  currentEditingStep: 'basics',
  isAdding: true,
  isShowingDetailModal: false,
  inferableInstallers: {},
};

// -----===[ REDUCTIONS ]===-----
// Reductions are the operations on state in response to an
// action that has been dispatched. Reductions are how
// state is modified. State in NgRx should be immutable, so
// changes to state should be made in an immutable manner.
// If performance is critical, especially for larger data sets,
// it may be prudent to use Immer.js for its highly efficient
// and performant copy-on-write approach to data modification.

// NOTE: NgRx Auto-Entity, used for each if the low level entity
// states in this application, internally uses a highly efficient
// approach to state modification capable of handling up to
// millions of entities at a time. Immer.js would only be
// required for high volume custom state.

export const setSelectedWorkArea = (state: WorkState, { area }: { area: SelectedArea }): WorkState => ({
  ...state,
  selectedWorkArea: area,
});

export const setSelectedDetailArea = (state: WorkState, { area }: { area: DetailArea }): WorkState => ({
  ...state,
  selectedDetailArea: area,
});

export const resetSelectedDetailArea = (state: WorkState): WorkState => ({
  ...state,
  selectedDetailArea: 'items',
});

export const setWorkAreaToPendingWorkOrders = (state: WorkState): WorkState => ({
  ...state,
  selectedWorkArea: 'workOrders',
});

export const setCurrentStatusGroup = (
  state: WorkState,
  { status: { status } }: { status: { status: StatusGroup } },
): WorkState => ({
  ...state,
  currentStatusGroup: status,
});

export const resetCurrentStatusGroup = (state: WorkState): WorkState => ({
  ...state,
  currentStatusGroup: undefined,
});

export const setSearchCriteria = (state: WorkState, { criteria }: { criteria: string }): WorkState => ({
  ...state,
  searchCriteria: criteria,
});

export const clearSearchCriteria = (state: WorkState): WorkState => ({
  ...state,
  searchCriteria: undefined,
});

export const setNextEditStep = (state: WorkState): WorkState => ({
  ...state,
  currentEditingStep: EDIT_STEPS[EDIT_STEPS.indexOf(state.currentEditingStep) + 1],
});

export const setEditStepBack = (state: WorkState): WorkState => ({
  ...state,
  currentEditingStep: EDIT_STEPS[EDIT_STEPS.indexOf(state.currentEditingStep) - 1],
});

export const setEditStep = (state: WorkState, { step }: { step: string }): WorkState => ({
  ...state,
  currentEditingStep: step,
});

export const resetEditStep = (state: WorkState): WorkState => ({
  ...state,
  currentEditingStep: 'basics',
});

export const setIsAdding =
  (val: boolean) =>
  (state: WorkState): WorkState => ({ ...state, isAdding: val });

export const setInferedInstaller = (
  state: WorkState,
  {
    workOrderId,
    installerId,
  }: {
    workOrderId: string;
    installerId: string;
  },
): WorkState => ({
  ...state,
  inferableInstallers: {
    ...state.inferableInstallers,
    [workOrderId]: installerId,
  },
});

export const setShowingDetailModal =
  (flag: boolean) =>
  (state: WorkState): WorkState => ({
    ...state,
    isShowingDetailModal: flag,
  });

// -----===[ REDUCE ]===-----
// The reduce function is where actions are mapped to reductions.
// Reduce functions are created by calling the NgRx createReducer() function.
//
// While reductions may be implemented inline, without breaking out
// a separate function, that can often lead to large and complex "reducers"
// that are harder to reason about, harder to understand, often harder to
// unit test (technically, all that really needs to be verified with a
// reducer is that the right reduction is performed for a given action...
// something the NgRx team has already verified with  their own unit tests!!)
// By breaking out reductions into their own functions, each individual
// reduction is easier to reason, understand, test and maintain.
//
// The true nature of a reducer is, as is quite common with more functional
// code, a map! Reducers are a map between actions, and reductions.
// actionA, actionC -> reduction1
// actionB -> reduction2
const reduce = createReducer(
  initialWorkState,
  on(selectWorkArea, setSelectedWorkArea),
  on(
    routerNavigatedAction,
    (
      state: WorkState,
      {
        payload: {
          routerState: { url },
        },
      },
    ) => (url.includes('work/custom-calendar') ? { ...state, selectedWorkArea: 'customCalendar' } : state),
  ),
  on(
    routerNavigatedAction,
    (
      state: WorkState,
      {
        payload: {
          routerState: { url },
        },
      },
    ) => (url.includes('work/work-orders') ? { ...state, selectedWorkArea: 'workOrders' } : state),
  ),

  on(addNewWorkOrder, setIsAdding(true)),
  on(editLoadedWorkOrder, setIsAdding(false)),
  on(selectDetailArea, setSelectedDetailArea),
  on(selectWorkOrderById, viewWorkOrderFromModal, resetSelectedDetailArea),
  on(viewPendingWorkOrders, setWorkAreaToPendingWorkOrders),
  on(viewOrdersByStatus, setCurrentStatusGroup),
  on(viewSearchWorkOrders, resetCurrentStatusGroup),
  on(searchWorkOrders, setSearchCriteria),
  on(clearWorkOrderSearchCriteria, clearSearchCriteria),
  on(continueToNextEditStep, setNextEditStep),
  on(goToEditStep, setEditStep),
  on(backOneEditStep, setEditStepBack),
  on(createWorkOrderSuccess, publishCompleted, resetEditStep),
  on(installerInfered, setInferedInstaller),
  on(viewWorkOrderFromModal, setShowingDetailModal(true)),
  on(viewWorkOrderModalClosed, setShowingDetailModal(false)),
);

// -----===[ Reducer Function ]===-----
// A reducer is simply a wrapper around the reduce function created by
// calling the reduce function previously created. Technically, these
// two function names should probably be reduced, but long-standing
// nomenclature with NgRx calls the functions mapped to areas of state
// 'reducers', hence the reason the function below is called 'workReducer'
// and the function above is simply called 'reduce'.
//
// Reducer functions are linked to particular areas of state via an
// ActionReducerMap, which you can find in the state.ts file in the /domains
// directory.
export function workReducer(state = initialWorkState, action: Action): WorkState {
  return reduce(state, action);
}
