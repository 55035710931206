import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DetailedProductInstallationTypeClaimed,
  InstallationTypeSubtypesClaimed,
} from '../../../../core/installer-profile';
import { ProductInstallationSubtypesClaimed } from '../../../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import { ProductInstallationType } from '../../../../domains/training/product-installation-types/product-installation-types.model';
import { Installer } from '../../../../domains/users';

@Component({
  selector: 'gc-installer-product-installation-subtypes',
  templateUrl: './installer-product-installation-subtypes.component.html',
  styleUrls: ['./installer-product-installation-subtypes.component.scss'],
})
export class InstallerProductInstallationSubtypesComponent {
  @Input() productInstallationSubtypes?: InstallationTypeSubtypesClaimed[];
  @Input() installType!: DetailedProductInstallationTypeClaimed;
  @Input() installer!: Installer;
  @Input() canEditProfile!: boolean;
  @Output() subtypeAdded = new EventEmitter<ProductInstallationType>();
  @Output() subtypeRemoved = new EventEmitter<ProductInstallationSubtypesClaimed>();
}
