import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { createSelector } from '@ngrx/store';
import { Category } from '../../domains/training/category/category.model';
import { allCategories } from '../../domains/training/category/category.state';
import { Training } from '../../domains/training/training/training.model';
import { allTrainings } from '../../domains/training/training/training.state';
import { TrainingClaimed } from '../../domains/training/trainings-claimed/trainings-claimed.model';
import { allTrainingsClaimed } from '../../domains/training/trainings-claimed/trainings-claimed.state';
import { selectedInstallTypeClaim } from '../../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.selectors';
import { view } from '../../shared/utils/ngrx';
import { currentInstallerHammerRatingDetails } from './hammer-rating-details.selectors';
import { DetailedProductInstallationSubtypeClaimed } from './models/detailed-product-installation-subtype-claimed.model';
import { DetailedProductInstallationTypeClaimed } from './models/detailed-product-installation-type-claimed.model';
import {
  EducationAndTrainingContext,
  InstallerTrainingsByCategory,
  TrainingClaimedItem
} from './models/education-and-training-context.model';
import { ExtendedHammerRatingDetails } from './models/extended-hammer-rating.details.model';
import { InstallationTypeSubtypesClaimed } from './models/installation-type-subtypes-claimed.model';
import {
  currentInstallerProductInstallationSubtypes,
  detailedProductInstallationTypesClaimed,

} from './product-installation-type-and-subtype.selectors';
import { isCurrentInstallerAlsoCurrentUser } from './store/feature.selectors';
import { currentInstaller } from './store/profile.selectors';


export const selectedHammerRatingChart = createSelector(
  currentInstallerHammerRatingDetails,
  selectedInstallTypeClaim,
  (details, installTypeClaim): ExtendedHammerRatingDetails | undefined =>
    details?.find(detail => detail.productInstallationTypeId === installTypeClaim?.productInstallationTypeId),
);

export const selectedInstallerProductType = createSelector(
  detailedProductInstallationTypesClaimed,
  selectedInstallTypeClaim,

  (
    claims: DetailedProductInstallationTypeClaimed[],
    claim: DetailedProductInstallationTypeClaimed | undefined,
  ): DetailedProductInstallationTypeClaimed | undefined =>
    claims?.find(
      (c: DetailedProductInstallationTypeClaimed) => c.productInstallationTypeId === claim?.productInstallationTypeId,
    ),
);

export const currentInstallerTrainingsClaimed = createSelector(
  currentInstaller,
  allTrainingsClaimed,
  (installer, trainingsClaimed): TrainingClaimed[] | undefined =>
    installer ? trainingsClaimed.filter(claim => claim.userId === installer.id) : undefined,
);

export const currentInstallerSelectedTypeTrainingsClaimed = createSelector(
  currentInstallerTrainingsClaimed,
  selectedInstallerProductType,
  view(
    'currentInstallerSelectedTypeTrainingsClaimed',
    (
      trainingsClaimed: TrainingClaimed[],
      type: DetailedProductInstallationTypeClaimed,
    ): TrainingClaimed[] | undefined =>
      trainingsClaimed?.filter(claim => claim.productInstallationTypeId === type.productInstallationTypeId),
  ),
);

export const installerClaimedTrainings = createSelector(
  currentInstallerSelectedTypeTrainingsClaimed,
  selectedInstallTypeClaim,
  allTrainings,
  view(
    'installerClaimedTrainings',
    (
      trainingsClaimed: TrainingClaimed[],
      installTypeClaim: DetailedProductInstallationTypeClaimed,
      trainings: Training[],
    ): TrainingClaimedItem[] | undefined =>
      trainingsClaimed?.map(claim => (
        {
          training: (
            trainings || []
          ).find(training => training.id === claim.trainingId),
          trainingClaim: claim,
          statusIcon: claim.isVerified ? faCircleCheck : faCircleQuestion,
          statusLabel: claim.isVerified ? 'Verified' : 'Claimed',
          statusColor: claim.isVerified ? 'gc-green' : 'medium',
        }
      )),
    true,
    true,
  ),
);

export const selectedTypeCategories = createSelector(
  selectedInstallerProductType,
  allCategories,
  view(
    'selectedTypeCategories',
    (installType: DetailedProductInstallationTypeClaimed, categories: Category[]): Category[] | undefined =>
      categories?.filter(category => category.productInstallationTypeId === installType.productInstallationTypeId),
  ),
);

export const buildInstallerTrainingsByCategory = (
  category: Category,
  claims: TrainingClaimedItem[],
): InstallerTrainingsByCategory => (
  {
    category,
    trainingClaims: claims.filter(claim => claim.training?.categoryId === category.id),
  }
);

export const keepCategoryWithClaims = (
  category: InstallerTrainingsByCategory,
  keptCategories: InstallerTrainingsByCategory[],
) => (
  category.trainingClaims.length > 0 ? [...keptCategories, category] : keptCategories
);

export const installerClaimedTrainingsByCategoriesWithClaims = createSelector(
  selectedTypeCategories,
  installerClaimedTrainings,
  view(
    'installerClaimedTrainingsByCategory',
    (categories: Category[], trainings: TrainingClaimedItem[]): InstallerTrainingsByCategory[] =>
      categories?.reduce(
        (kept: InstallerTrainingsByCategory[], category: Category) =>
          keepCategoryWithClaims(buildInstallerTrainingsByCategory(category, trainings), kept),
        [],
      ) ?? [],
    true,
    true,
  ),
);

export const installerClaimedTrainingsByCategories = createSelector(
  selectedTypeCategories,
  installerClaimedTrainings,
  view(
    'installerClaimedTrainingsByCategory',
    (categories: Category[], trainings: TrainingClaimedItem[]): InstallerTrainingsByCategory[] =>
      categories?.map(category => buildInstallerTrainingsByCategory(category, trainings)) ?? [],
    true,
    true,
  ),
);

export const chooseClaimedTrainingsByCategoryByUser = createSelector(
  isCurrentInstallerAlsoCurrentUser,
  installerClaimedTrainingsByCategoriesWithClaims,
  installerClaimedTrainingsByCategories,
  view(
    'selectClaimedTrainingsByCategoryByUser',
    (
      isCurrentUser: boolean,
      claimedTrainingsByCategoryWithClaims: InstallerTrainingsByCategory[],
      claimedTrainingsByCategory: InstallerTrainingsByCategory[],
    ): InstallerTrainingsByCategory[] =>
      isCurrentUser ? claimedTrainingsByCategory : claimedTrainingsByCategoryWithClaims,
    true,
    true,
  ),
);

export const selectedInstallerProductTypeWithSubtypesClaimed = createSelector(
  currentInstallerProductInstallationSubtypes,
  selectedInstallerProductType,
  (
    productInstallationSubtypesClaimed: InstallationTypeSubtypesClaimed[],
    selectedType: DetailedProductInstallationTypeClaimed,
  ): DetailedProductInstallationSubtypeClaimed[] | undefined =>
    productInstallationSubtypesClaimed?.find(
      subtype => subtype.installTypeClaimed.productInstallationTypeId === selectedType.productInstallationTypeId,
    )?.subtypesClaimed,
);

export const educationAndTrainingContext = createSelector(
  isCurrentInstallerAlsoCurrentUser,
  selectedHammerRatingChart,
  selectedInstallerProductType,
  chooseClaimedTrainingsByCategoryByUser,
  selectedInstallerProductTypeWithSubtypesClaimed,
  view(
    'educationAndTrainingContext',
    (
      showRemove: boolean,
      chart: ExtendedHammerRatingDetails,
      installTypeClaimed: DetailedProductInstallationTypeClaimed,
      trainingsByCategory: InstallerTrainingsByCategory[],
      subtypesClaimed: DetailedProductInstallationSubtypeClaimed[],
    ): EducationAndTrainingContext | undefined =>
      installTypeClaimed
        ? {
          chart,
          installType: installTypeClaimed,
          subtype: {
            installTypeClaimed: installTypeClaimed,
            subtypesClaimed: subtypesClaimed,
            showRemove,
          },
          trainingsByCategory,
        }
        : undefined,
  ),
);
