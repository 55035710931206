import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap } from 'rxjs';
import { exhaustMap, filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedUser } from '../../domains/app/auth/auth.selectors';
import {
  createProductInstallationTypeClaim,
  deleteProductInstallationTypeClaim,
} from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.state';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';
import { User } from '../../domains/users';
import { defaultClaimSelected } from '../../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.actions';
import { ensureChildrenRequired, ensureExists } from '../../shared/utils/rxjs';
import { WithId } from '../../shared/utils/types';
import { currentInstallerProductInstallationTypesClaimed } from './product-installation-type-and-subtype.selectors';
import { ProductInstallationTypeUIService } from './product-installation-type-ui.service';
import {
  claimProductInstallationTypeModalCancelled,
  claimProductInstallationTypeModalDone,
  productInstallationTypeClaimed,
  productInstallationTypeClaimRemovalConfirmed,
  productInstallationTypeClaimRemoved,
  showClaimProductInstallationTypeModal,
} from './product-installation-type.actions';

@Injectable()
export class ProductInstallationTypeEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly ui: ProductInstallationTypeUIService,
  ) {}

  claimProductInstallationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallationTypeClaimed),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ date, installType }, user]) => ({
        date,
        installType,
        user,
      })),
      ensureChildrenRequired<
        { date: string; installType?: ProductInstallationType; user?: User },
        { date: string; installType: WithId<ProductInstallationType>; user: WithId<User> }
      >(value => !!value?.installType && !!value.date && !!value.user),
      map(({ date, installType, user }) =>
        createProductInstallationTypeClaim({
          entity: {
            userId: user.id,
            productInstallationTypeId: installType.id,
            dateClaimed: new Date(date),
            isVerified: false,
          },
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
            },
          },
        }),
      ),
    ),
  );

  removeProductInstallationTypeClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallationTypeClaimRemoved),
      switchMap(({ claim }) =>
        this.ui.confirmRemoveProductInstallationTypeClaim().pipe(
          filter(({ role }) => role === 'ok'),
          concatMap(() => [
            deleteProductInstallationTypeClaim({
              entity: claim,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: claim.userId,
                },
              },
            }),
            productInstallationTypeClaimRemovalConfirmed({ claim }),
          ]),
        ),
      ),
    ),
  );

  resetSelectedProductInstallationTypeOnRemoval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallationTypeClaimRemovalConfirmed),
      switchMap(({ claim }) =>
        this.store.select(currentInstallerProductInstallationTypesClaimed).pipe(
          ensureExists(value => !!value?.length),
          map(claims => claims[0]),
          map(claim => defaultClaimSelected({ claim })),
          first(),
        ),
      ),
    ),
  );

  showClaimCategorizedProductInstallationTypeModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showClaimProductInstallationTypeModal),
      exhaustMap(() =>
        this.ui
          .showClaimProductInstallationTypeModal()
          .pipe(
            map(({ role }) =>
              role === 'ok' ? claimProductInstallationTypeModalDone() : claimProductInstallationTypeModalCancelled(),
            ),
          ),
      ),
    ),
  );
}
