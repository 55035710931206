// Entity model -- class (not an interface)
// Need information at runtime about the entity -- takes care of effort in writing code at runtime
// Interface is a compile time only feature
// Class -- javaScript feature, included in compilation, available at runtime

import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'KudosReason',
  uriName: 'kudos-reasons',
})
export class KudosReason {
  @Key userId!: string;
  attitudeCount!: number;
  punctualityCount!: number;
  qualityCount!: number;
  professionalismCount!: number;
  dependabilityCount!: number;
  notProvidedCount!: number;
}
