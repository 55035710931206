import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Plaid } from 'plaid-link';
import { iif, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfigService } from '~gc/shared/services/config.service';
import { environment } from '../../../../../environments/environment';
import { resolveRetryCriteria } from '../../../../domains/entity.service.utils';
import { PlaidAccountDetails } from './models/plaid-account-details.model';
import { plaidExit, plaidLoad, plaidSuccess } from './plaid.actions';
import LinkHandler = Plaid.LinkHandler;
import VerificationStatus = Plaid.VerificationStatus;

export interface PlaidTokenExchange {
  publicToken: string;
  accountId: string;
  accountName: string;
  status: VerificationStatus | null;
}

export const PLAID_TOKEN_STORAGE_KEY = 'GC_PLAID_TOKEN';

@Injectable({ providedIn: 'root' })
export class PlaidService {
  linkHandler?: LinkHandler;

  constructor(
    private readonly http: HttpClient,
    private readonly config: ConfigService,
    private readonly store: Store,
    private readonly toasts: ToastController,
  ) {}

  createPlaidLink(redirectUri?: string) {
    return iif(
      () => !!redirectUri,
      of({ token: localStorage.getItem(PLAID_TOKEN_STORAGE_KEY)! }),
      this.http.post<{ token: string }>(`${this.config.host}/plaid/link-token`, {}),
    ).pipe(
      tap(({ token }) => localStorage.setItem(PLAID_TOKEN_STORAGE_KEY, token)),
      map(({ token }) =>
        window.Plaid.create({
          token,
          env: environment.plaid,
          isWebView: Capacitor.isNativePlatform(),
          ...(redirectUri ? { receivedRedirectUri: window.location.origin + redirectUri } : {}),
          onEvent: (eventName, metadata) => console.log('Plaid Event', eventName, metadata),
          onLoad: () => this.store.dispatch(plaidLoad()),
          onExit: (error, metadata) => {
            localStorage.removeItem(PLAID_TOKEN_STORAGE_KEY);
            this.store.dispatch(plaidExit({ error, metadata }));
          },
          onSuccess: (publicToken, metadata) => {
            localStorage.removeItem(PLAID_TOKEN_STORAGE_KEY);
            this.store.dispatch(plaidSuccess({ publicToken, metadata }));
          },
        }),
      ),
      tap(link => (this.linkHandler = link)),
    );
  }

  async toastPlaidError(message: string) {
    const toast = await this.toasts.create({
      header: 'Payment Error',
      message,
      buttons: [
        {
          text: 'Dismiss',
          side: 'end',
        },
      ],
      color: 'danger',
      duration: 5000,
      position: 'bottom',
    });
    await toast.present();
    return toast.onDidDismiss();
  }

  exchangeLinkToken(exchange: PlaidTokenExchange) {
    // TODO: Test this return to ensure it fires as expected outside of an effect.
    return resolveRetryCriteria(this.http.post(`${this.config.host}/plaid/link-token/exchange`, exchange), true);
  }

  getAccountDetails() {
    return this.http.get<PlaidAccountDetails>(`${this.config.host}/plaid/details`);
  }

  deleteAccountDetails() {
    return this.http.delete<PlaidAccountDetails>(`${this.config.host}/plaid/details`);
  }
}
