import { createSelector } from '@ngrx/store';
import { foundUserInfo, inviteLookupPending } from '../../../core/invites';
import { UserQuery } from '../../../core/invites/models/user-query.model';
import { authenticatedUser } from '../../../domains/app/auth/auth.selectors';
import { isCompanyUser, User } from '../../../domains/users';
import { Connection } from '../../../domains/users/connection/connection.model';
import { connectionsForAuthenticatedCompany } from '../../../domains/users/network.selectors';
import { allUsers } from '../../../domains/users/user/user.state';
import { matchByKey } from '../../../shared/utils/utils';

export interface InviteView {
  isPending: boolean;
  existingConnection?: Connection;
  existingEmployee?: User;
  foundUser?: UserQuery;
  authenticatedUser?: User;
}

export const findExistingConnection = (connections: Connection[], info?: UserQuery): Connection | undefined =>
  info ? connections.find(matchByKey(info.id, 'installerTeamLeadId')) : undefined;

export const existingConnection = createSelector(
  connectionsForAuthenticatedCompany,
  foundUserInfo,
  findExistingConnection,
);

export const findExistingEmployee = (users: User[], info?: UserQuery, authUser?: User) =>
  !!authUser && isCompanyUser(authUser) && !!info
    ? users.find(user => isCompanyUser(user) && user.id === info.id && user.companyId === authUser.companyId)
    : undefined;

export const existingEmployee = createSelector(allUsers, foundUserInfo, authenticatedUser, findExistingEmployee);

export const inviteViewModel = createSelector(
  inviteLookupPending,
  existingConnection,
  existingEmployee,
  foundUserInfo,
  authenticatedUser,
  (isPending, connection, employee, found, authUser): InviteView => ({
    isPending,
    existingConnection: connection,
    existingEmployee: employee,
    foundUser: found,
    authenticatedUser: authUser,
  }),
);
