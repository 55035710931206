import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';
import { Installer } from '../../../domains/users';
import { DetailedWorkOrder } from '../../../domains/work/models/detailed-work-order';

@Component({
  selector: 'gc-work-order-installer-chip',
  templateUrl: './work-order-installer-chip.component.html',
  styleUrls: ['./work-order-installer-chip.component.scss'],
})
export class WorkOrderInstallerChipComponent {
  @Input() workOrder!: DetailedWorkOrder;
  @Input() matchedInstallerCount!: number;
  @Input() logoUrl: string = 'https://gocarrera.com/logo.jpg';

  @Output() viewInstaller = new EventEmitter<Installer>();
  @Output() reviewInstallers = new EventEmitter<DetailedWorkOrder>();

  constructor(public auth: AuthFacade) {}
}
