import { Component, Input } from '@angular/core';
import { PendingInvoice } from '../../../domains/models/pending-invoice';

@Component({
  selector: 'gc-review-progress-list',
  templateUrl: './review-progress-list.component.html',
  styleUrls: ['./review-progress-list.component.scss'],
})
export class ReviewProgressListComponent {
  @Input() pendingInvoice!: PendingInvoice;
  @Input() role?: string;
  @Input() permissions!: string[];
}
