import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Country',
  uriName: 'countries',
})
export class Country {
  @Key code!: string;
  description?: string;
}
