<ng-container *ngIf="area | isArea: 'items'">
  <gc-add-fab
    *ngIf="
      (displayGroups | itemGroupsAllowed: 'change-add' : 'installer-add') &&
      (permissions | hasPermissions: 'work-order.change-order-items:view' : 'work-order.change-order-item:create')
    "
    [color]="(userType | isRole: 'installerLead') ? 'gc-blue' : 'gc-blue'"
    [iconTemplate]="changeOrderAdd"
    (clicked)="changeOrderAdded.emit(workOrder)"
  >
    <ng-template #changeOrderAdd>
      <fa-layers>
        <fa-icon
          [icon]="faPlus"
          [style]="'color: white;'"
          transform="grow-7"
        ></fa-icon>
      </fa-layers>
    </ng-template>
  </gc-add-fab>
</ng-container>
