export interface ResponseHeaders {
  [key: string]: string;
}

export interface ResponseMetadata {
  status: number;
  url: string;
  receivedAt: string;
  headers: ResponseHeaders;
}

export const HTTP_DATE_FORMAT = "EEE, dd MMM yyyy HH:mm:ss 'GMT'";
