<ng-template #view>
  <ion-grid data-cy="user-profile-header" *ngIf="user">
    <ion-row>
      <ion-col>
        <ion-chip color="dark" *ngIf="user | isInstallerTeamLead">
          <ion-label>TEAM</ion-label>
        </ion-chip>
      </ion-col>
      <ion-col class="ion-text-center">
        <img
          alt="{{ user | fullName }}"
          [class.user-avatar]="centered"
          [src]="user | profilePhoto"
          class="profile-picture"
        />
      </ion-col>
      <ion-col class="ion-text-end">
        <ng-content></ng-content>
        <ion-buttons class="ion-justify-content-end">
          <ion-button target="_blank" href="mailto:{{ user.email }}">
            <ion-icon color="dark" slot="icon-only" name="mail-open-outline"></ion-icon>
          </ion-button>
          <ion-button *ngIf="user | isInstallerTeamLead" (click)="showInstallerTeamLeadProfile(user)" id="profile-btn">
            <ion-icon color="dark" name="person-circle-outline" slot="icon-only"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center">
        <h1>{{ user | fullName }}</h1>
        <gc-hammer-rating
          *ngIf="isDetailedInstaller(user) && user.keyHammerRating"
          [hammerRating]="user.keyHammerRating"
        ></gc-hammer-rating>
        <ng-container *ngIf="user | isInstallerTeamLead">
          <gc-user-rating [user]="user"></gc-user-rating>
          <gc-user-completed-work [user]="user"></gc-user-completed-work>
        </ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="user | isInstallerTeamLead" class="ion-text-center display-grid">
      <ion-col
        size="6"
        [attr.size-md]="!!$any(user).keyHammerRating ? 3 : 4"
        class="flex flex-column ion-justify-content-center"
      >
        <ion-icon name="checkmark-circle" color="gc-green" size="large"></ion-icon>
        <ion-text color="dark" style="margin-top: 15px">W9</ion-text>
        <ion-text color="gc-green">Passed</ion-text>
      </ion-col>

      <ion-col
        size="6"
        [attr.size-md]="!!$any(user).keyHammerRating ? 3 : 4"
        class="flex flex-column ion-justify-content-center"
      >
        <ion-icon
          [name]="validInsurance(user) ? 'checkmark-circle' : 'warning'"
          [color]="validInsurance(user) ? 'gc-green' : 'danger'"
          size="large"
        ></ion-icon>
        <ion-text color="dark" style="margin-top: 15px">Certificate of Insurance</ion-text>
        <ion-text [color]="validInsurance(user) ? 'gc-green' : 'danger'"
          >{{ validInsurance(user) ? 'Valid' : 'Expired' }}
        </ion-text>
      </ion-col>

      <ion-col
        size="6"
        [attr.size-md]="!!$any(user).keyHammerRating ? 3 : 4"
        class="flex flex-column ion-justify-content-center"
      >
        <ion-text class="value value-bronze">BRONZE</ion-text>
        <ion-text color="dark" style="margin-top: 15px">Verification Level</ion-text>
        <ion-text
          (click)="
            openBrowser('https://gocarrera.com/resources/knowledge-base/what-do-each-of-the-verification-levels-mean/')
          "
          color="gc-blue"
          class="pointable"
          >What's this?
          <ion-icon name="arrow-forward-circle-outline" style="transform: translateY(2px)"></ion-icon>
        </ion-text>
      </ion-col>

      <ion-col
        size="6"
        size-md="3"
        class="flex flex-column ion-justify-content-center"
        *ngIf="isDetailedInstaller(user) && $any(user).keyHammerRating"
      >
        <ion-text class="value" color="dark">
          <ion-icon
            *ngIf="!meetsHammerRatingThreshold(user)"
            name="warning"
            color="warning"
            size="medium"
            style="transform: translateY(3px)"
          ></ion-icon>
          {{ $any(user).keyHammerRating }}
        </ion-text>
        <ion-text color="dark" style="margin-top: 15px">Hammer Rating</ion-text>
        <ion-text
          (click)="
            openBrowser(
              'https://gocarrera.com/resources/knowledge-base/why-am-i-seeing-a-hammer-rating-alert-when-reviewing-labor-providers-who-have-bid-on-my-work-order/'
            )
          "
          class="pointable"
          [color]="meetsHammerRatingThreshold(user) ? 'gc-green' : 'gc-blue'"
          >{{ meetsHammerRatingThreshold(user) ? 'Passed' : 'Why the warning?' }}
          <ion-icon
            *ngIf="!meetsHammerRatingThreshold(user)"
            name="arrow-forward-circle-outline"
            style="transform: translateY(2px)"
          ></ion-icon>
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-template>

<ng-container *ngIf="isLoading; else view">
  <ion-grid data-cy="user-profile-header">
    <ion-row>
      <ion-col>
        <ion-chip color="dark">
          <ion-skeleton-text></ion-skeleton-text>
        </ion-chip>
      </ion-col>
      <ion-col class="ion-text-center">
        <ion-skeleton-text></ion-skeleton-text>
      </ion-col>
      <ion-col class="ion-text-end"></ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center">
        <ion-skeleton-text></ion-skeleton-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>
