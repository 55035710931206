import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { DisplayGroupAllowance, DisplayOptions, WorkOrderItemGroup } from '../../../../domains/work-order-item-group.selectors';

@Component({
  selector: 'gc-change-order-pending-acceptance',
  templateUrl: './change-order-pending-acceptance.component.html',
  styleUrls: ['./change-order-pending-acceptance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderPendingAcceptanceComponent {
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];
  @Input() userType!: string;
  @Input() group!: WorkOrderItemGroup;
  @Input() item!: DetailedWorkOrderItem;
  @Output() voided = new EventEmitter<DetailedWorkOrderItem>();
}
