import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { GenericFile } from '~gc/domains/models/generic-file';
import { PendingFile } from '~gc/domains/models/pending-file';
import { PendingFullWorkOrder } from '~gc/domains/work/models/pending-full-work-order';
import { WorkOrderItem } from '~gc/domains/work/work-order-item/work-order-item.model';
import { WorkOrderTag } from '~gc/domains/work/work-order-tag/work-order-tag.model';
import { AddressForm, TypedFormGroup, ValueChangeOptions } from '~gc/shared/utils/typed-form-group';
import { WorkOrderStatus } from '../../domains/work/work-order/work-order.model';
import { CustomDateForm, CustomDateFormArray } from '../../shared/components/schedule/schedule.form';
import { GenericValidators } from '../../shared/validators/generic.validators';
import { removeFromErrors } from '../../shared/validators/util';

export const WorkOrderFormFields = [
  // basics step
  'publicJobName',
  'facilityTypeId',
  'publicJobSummary',
  'productInstallationTypeId',
  // 'scheduledStartDate',
  // 'scheduledEndDate',
  'dates',
  // details step
  'privateJobName',
  'installationDetails',
  'files',
  'address',
  // items step
  'workOrderItems',
  // publishing step
  'radius',
  'minimumHammerRatingThreshold',
  'lienReleaseTemplateId',
  'tags',
] as const;

export type Field = (typeof WorkOrderFormFields)[number];

export type ErrorMap = {
  [p in Field]: ValidationErrors;
};

const atLeastOneScheduleDate = 'Schedule must have at least one date range';

export const validateSchedule: ValidatorFn = (control: AbstractControl) =>
  (control as WorkOrderForm).dates?.length > 0
    ? (removeFromErrors(control, 'atLeastOneScheduleDate'),
      removeFromErrors((control as WorkOrderForm).dates, 'atLeastOneScheduleDate'),
      null)
    : ((control as WorkOrderForm).dates?.setErrors({ atLeastOneScheduleDate }), { atLeastOneScheduleDate });

export class InstallationDetailsControl extends UntypedFormControl {
  constructor(details = '') {
    super(details, Validators.required);
  }

  override setValue(value: any, options: ValueChangeOptions = {}) {
    const defaults = { emitModelToViewChange: false };
    super.setValue(value, { ...defaults, ...options });
  }

  override patchValue(value: any, options: ValueChangeOptions = {}) {
    const defaults = { emitModelToViewChange: false };
    super.patchValue(value, { ...defaults, ...options });
  }
}

export class WorkOrderForm extends TypedFormGroup {
  readonly publicJobName = this.getFormControl('publicJobName');
  readonly privateJobName = this.getFormControl('privateJobName');
  readonly facilityTypeId = this.getFormControl('facilityTypeId');
  readonly publicJobSummary = this.getFormControl('publicJobSummary');
  readonly installationDetails = this.getFormControl('installationDetails');
  readonly productInstallationTypeId = this.getFormControl('productInstallationTypeId');
  readonly dates = this.getFormArray('dates') as CustomDateFormArray;
  readonly minimumHammerRatingThreshold = this.getFormControl('minimumHammerRatingThreshold');
  readonly radius = this.getFormControl('radius');
  readonly lienRelease = this.getFormControl('lienReleaseTemplateId');
  readonly address = this.getControl('address') as AddressForm;
  readonly workOrderItems = this.getFormArray('workOrderItems') as WorkOrderItemFormArray;
  readonly files = this.getFormArray('files') as WorkOrderFileFormArray;
  readonly tags = this.getFormArray('tags') as WorkOrderTagFormArray;

  validated = false;
  isAddingItem = false;
  workingItem = new WorkOrderItemForm();
  originalEditedItem?: WorkOrderItem;

  get workOrderStatus(): WorkOrderStatus {
    return this.model?.status || WorkOrderStatus.Draft;
  }

  get isDraftValid(): boolean {
    return this.publicJobName.valid;
  }

  get isValid(): boolean {
    this.validated = true;
    return this.valid;
  }

  get rawItems(): any[] {
    return this.workOrderItems.itemForms.map(i => i.value);
  }

  get updatedModel(): Partial<PendingFullWorkOrder> {
    return {
      ...this.model,
      ...this.value,
      address: this.address.isEmpty ? null : this.address.updatedModel,
      workOrderItems: this.workOrderItems.itemForms.map(form => form.updatedModel),
      tags: this.tags.tagForms.map(form => form.updatedModel),
      pendingFiles: this.files.fileForms.filter(form => !!form.fileBlob.value).map(form => form.updatedModel),
      dates: undefined,
    };
  }

  get orderedErrors(): Partial<ErrorMap> {
    return WorkOrderFormFields.filter(field => this.getFormControl(field).invalid).reduce(
      (curr, field) => ({
        ...curr,
        [field]: this.getFormControl(field).errors,
      }),
      {},
    );
  }

  constructor(
    private model?: Partial<PendingFullWorkOrder>,
    builder = new UntypedFormBuilder(),
  ) {
    super(
      {
        controls: {
          publicJobName: [model?.publicJobName ?? null, Validators.required],
          privateJobName: [model?.privateJobName ?? null, Validators.required],
          facilityTypeId: [model?.facilityTypeId ?? null, Validators.required],
          publicJobSummary: [model?.publicJobSummary ?? '', Validators.required],
          installationDetails: new InstallationDetailsControl(model?.installationDetails),
          productInstallationTypeId: [model?.productInstallationTypeId ?? null, Validators.required],
          dates: new CustomDateFormArray(model?.dates?.map(date => new CustomDateForm(date)) ?? []),
          minimumHammerRatingThreshold: [model?.minimumHammerRatingThreshold ?? 0, Validators.required],
          radius: [model?.radius ?? 10, Validators.required],
          lienReleaseTemplateId: [model?.lienReleaseTemplateId ?? null, Validators.required],
          address: new AddressForm(model?.address, builder),
          workOrderItems: new WorkOrderItemFormArray(
            model?.workOrderItems?.map(item => new WorkOrderItemForm(item)) ?? [],
          ),
          files: new WorkOrderFileFormArray(model?.files?.map(file => new WorkOrderFileForm(file)) ?? []),
          tags: new WorkOrderTagFormArray(model?.tags?.map(tag => new WorkOrderTagForm(tag)) ?? []),
        },
        validatorOrOpts: {
          validators: validateSchedule,
        },
      },
      builder,
    );

    this.address.enableValidators();
  }

  updateModel(model: Partial<PendingFullWorkOrder>): void {
    this.patchValue(model);
    model?.address && this.address.updateModel(model.address);
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = {
      ...value,
      // Default Values
      minimumHammerRatingThreshold: value?.['minimumHammerRatingThreshold'] ?? 0,
      radius: value?.['radius'] ?? 10,
    } as Partial<PendingFullWorkOrder>;
    super.patchValue(this.model, options);
  }

  cancelItemAddEdit() {
    this.originalEditedItem ? this.addItem(this.originalEditedItem) : void 0;
    this.originalEditedItem = undefined;
    this.workingItem.reset();
    this.isAddingItem = false;
  }

  beginAddItem() {
    this.workingItem.reset();
    this.isAddingItem = true;
  }

  endAddItem() {
    this.addItem(this.workingItem.value);
    this.originalEditedItem = undefined;
    this.workingItem.reset();
    this.isAddingItem = false;
  }

  private addItem(item: Partial<WorkOrderItem>): void {
    this.workOrderItems.push(new WorkOrderItemForm(item));
    this.updateValueAndValidity();
  }

  editItem(item: WorkOrderItem, index: number): void {
    this.removeItemAt(index);
    this.originalEditedItem = { ...item };
    this.workingItem.reset(item);
    this.isAddingItem = true;
  }

  removeItemAt(index: number): void {
    this.workOrderItems.removeAt(index);
    this.updateValueAndValidity();
  }

  addFile(file: PendingFile | GenericFile): void {
    this.files.push(new WorkOrderFileForm({ ...file }));
    this.updateValueAndValidity();
  }

  removeFileAt(index: number): void {
    this.files.removeAt(index);
    this.updateValueAndValidity();
  }

  addTag(tag: Partial<WorkOrderTag>): void {
    this.tags.push(new WorkOrderTagForm(tag));
    this.updateValueAndValidity();
  }

  removeTagAt(index: number): void {
    this.tags.removeAt(index);
    this.updateValueAndValidity();
  }
}

export class WorkOrderFileFormArray extends UntypedFormArray {
  readonly fileForms = this.controls as WorkOrderFileForm[];

  get fileModels() {
    return this.fileForms.map(form => form.updatedModel);
  }

  constructor(controls: AbstractControl[]) {
    super(controls);
  }

  override patchValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    value.slice(this.controls.length).forEach(file => this.push(new WorkOrderFileForm(file)));
    this.fileForms.forEach((form, i) => value.some(file => file?.filename === form.filename.value) || this.removeAt(i));
    super.patchValue(value, options);
  }
}

export class WorkOrderFileForm extends TypedFormGroup {
  readonly filename = this.getFormControl('filename');
  readonly url = this.getFormControl('url');
  readonly contentType = this.getFormControl('contentType');
  readonly fileBlob = this.getFormControl('fileBlob');

  get isPending(): boolean {
    return !!this.fileBlob.value;
  }

  get updatedModel(): Partial<PendingFile> & { processing?: boolean } {
    return { ...this.model, ...this.value };
  }

  constructor(private model?: Partial<PendingFile | GenericFile>) {
    super({
      controls: {
        filename: [model?.filename ?? '', Validators.required],
        url: [model?.url ?? ''],
        contentType: [model?.contentType ?? '', Validators.required],
        fileBlob: (model as PendingFile)?.fileBlob,
      },
    });
  }

  updateModel(model: Partial<PendingFile | GenericFile>): void {
    this.model = model;
    this.patchValue(model);
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = value as Partial<PendingFile>;
    super.patchValue(value, options);
  }
}

export class WorkOrderItemFormArray extends UntypedFormArray {
  readonly itemForms = this.controls as WorkOrderItemForm[];

  constructor(controls: AbstractControl[]) {
    super(controls, [Validators.required]);
  }

  override patchValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
    value.slice(this.controls.length).forEach(ctrl => this.push(new WorkOrderItemForm(ctrl)));
  }
}

export class WorkOrderItemForm extends TypedFormGroup {
  readonly name = this.getFormControl('name');
  readonly pricePerUnit = this.getFormControl('pricePerUnit');
  readonly unitOfMeasureSymbol = this.getFormControl('unitOfMeasureSymbol');
  readonly numberOfUnits = this.getFormControl('numberOfUnits');

  validated = false;

  get isValid(): boolean {
    this.validated = true;
    return this.valid;
  }

  get updatedModel(): Partial<WorkOrderItem> {
    return { ...this.model, ...this.value };
  }

  constructor(private model?: Partial<WorkOrderItem>) {
    super({
      controls: {
        name: [model?.name ?? '', Validators.required],
        pricePerUnit: [model?.pricePerUnit ?? 0, [Validators.required, GenericValidators.isPositiveOrZero]],
        unitOfMeasureSymbol: [model?.unitOfMeasureSymbol ?? '', Validators.required],
        numberOfUnits: [model?.numberOfUnits ?? 0, [Validators.required, GenericValidators.isPositive]],
      },
      validatorOrOpts: GenericValidators.formGroupIncrementAmount,
    });
  }

  updateModel(model: Partial<WorkOrderItem>): void {
    this.patchValue(model);
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = value as Partial<WorkOrderItem>;
    super.patchValue(value, options);
  }
}

export class WorkOrderTagFormArray extends UntypedFormArray {
  readonly tagForms = this.controls as WorkOrderTagForm[];

  constructor(controls: AbstractControl[]) {
    super(controls);
  }

  override patchValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
    value.slice(this.controls.length).forEach(ctrl => this.push(new WorkOrderTagForm(ctrl)));
  }
}

export class WorkOrderTagForm extends TypedFormGroup {
  readonly tagValue = this.getFormControl('value');

  get updatedModel(): Partial<WorkOrderTag> {
    return { ...this.model, value: this.tagValue.value, name: 'workorder' };
  }

  constructor(private model?: Partial<WorkOrderTag>) {
    super({
      controls: {
        value: [model?.value ?? '', Validators.required],
      },
    });
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = value as Partial<WorkOrderTag>;
    super.patchValue(value, options);
  }
}
