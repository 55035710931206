import { type } from '@amcharts/amcharts5';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DetailedProductInstallationTypeClaimed
} from '../../../../core/installer-profile';

@Component({
  selector: 'gc-training-tabs',
  templateUrl: './training-tabs.component.html',
  styleUrls: ['./training-tabs.component.scss']
})
export class TrainingTabsComponent {
  @Input() types?: DetailedProductInstallationTypeClaimed[];
  @Input() selectedProductInstallationTypeClaim?: DetailedProductInstallationTypeClaimed;
  @Input() canEditProfile?: boolean;

  @Output() selected = new EventEmitter<DetailedProductInstallationTypeClaimed>();
  @Output() added = new EventEmitter();

}
