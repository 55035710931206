<ion-card *ngIf="!pendingNegotiation?.isUnavailable && !!pendingNegotiation.negotiation">
  <ion-card-header color="light">
    <div class="corner-badge corner-badge-yellow"><strong>Date</strong></div>
    <ion-card-subtitle>Work Order</ion-card-subtitle>
    <ion-card-title> Start Date</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <p style="margin-top: 16px">
      Based on your install schedule, if you need to start this project later than proposed and still feel like you can
      get the project done, suggest a later start date.
    </p>
    <ion-grid>
      <ion-row *ngIf="!(role | isRole: 'installerLead') || !form.negotiation.isAcceptedByInstaller.value">
        <ion-col>
          <gc-datepicker-item
            [control]="form.negotiation.startDate"
            [min]="today"
            [max]="getEndDate()"
            closeOn="select"
          >
            <ion-label position="fixed">Start Date</ion-label>
          </gc-datepicker-item>

          <ion-item lines="none" *ngIf="form.negotiation?.errors?.invalidDate">
            <ion-label color="danger" class="ion-text-wrap">
              Make sure to choose a date after the Scheduled Start Date and before the Scheduled End Date of the Work
              Order.
            </ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-text [color]="form.negotiation.isAcceptedByInstaller.value ? 'dark' : undefined"
            >{{ form.mode | negotiationModeFormat: 'Current %mode%:' : true }}
            <strong>{{ form.negotiation.opposingStartDate.value | date: 'mediumDate' }}</strong></ion-text
          >
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="
          !pendingNegotiation.negotiation.isAcceptedByInstaller &&
          !form.negotiation.isAcceptedByInstaller.value &&
          (role | isRole: 'installerLead')
        "
      >
        <ion-col>
          <ion-button color="light" size="large" expand="block" (click)="form.negotiation.accept()">
            Accept Current Ask
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="
          !pendingNegotiation.negotiation.isAcceptedByInstaller &&
          form.negotiation.isAcceptedByInstaller.value &&
          (role | isRole: 'installerLead')
        "
      >
        <ion-col>
          <ion-button color="light" size="large" expand="block" (click)="form.negotiation.unaccept()">
            <ion-icon name="arrow-undo-outline"></ion-icon>
            Undo Acceptance
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="pendingNegotiation.negotiation.isAcceptedByInstaller && (role | isRole: 'installerLead')">
        <ion-col>
          <ion-button color="light" size="large" expand="block"> Asking Start Date Accepted</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
