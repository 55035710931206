import { FormControl, Validators } from '@angular/forms';
import moize from 'moize';
import { PhoneNumber, PhoneNumberType } from '../../../../domains/phone-number';
import { buildPhoneNumber } from '../../../../domains/phone-number/utils';
import { TypedFormGroup } from '../../../utils/typed-forms';

export const normalize = moize((number: string): string => number.replace(/[()\- ]/g, ''));

export const formatPhoneNumber = moize((number?: string): string | undefined =>
  !!number
    ? `${normalize(number).slice(0, 3)} ${normalize(number).slice(3, 6)} ${normalize(number).slice(6)}`
    : undefined,
);

export class NewPhoneNumberForm extends TypedFormGroup<PhoneNumber> {
  readonly number: FormControl<string> = this.getTypedControl<string>('number');
  readonly type: FormControl<PhoneNumberType> = this.getTypedControl<PhoneNumberType>('type');
  readonly consent: FormControl<boolean> = this.getTypedControl<boolean>('consent');
  readonly user?: string = this.model?.userId;

  get isEmpty(): boolean {
    return !!this.number.value && !!this.type.value && this.consent.value != undefined;
  }

  override get updatedModel(): Partial<PhoneNumber> {
    return buildPhoneNumber(
      this.model,
      this.user,
      this.value.number,
      this.value.type,
      this.consent.value,
    ) as Partial<PhoneNumber>;
  }

  constructor(model?: Partial<PhoneNumber>) {
    super({
      config: {
        controls: {
          number: [model?.number, Validators.required],
          type: [model?.type ?? PhoneNumberType.Mobile, Validators.required],
          consent: [false],
        },
      },
      model,
    });
  }

  resetForNewEntry(): void {
    this.reset();
    this.consent.setValue(false);
    this.type.setValue(PhoneNumberType.Mobile);
  }
}
