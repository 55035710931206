import { buildState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer, createSelector } from '@ngrx/store';
import { authenticatedRoleKey } from '../../app/auth/auth.selectors';
import { WorkOrderStatusGroup } from '../models/work-order-status-group';
import { sortStatusGroups } from '../work-order-header.selectors';
import {
  STATUS_GROUP_ALLOWED_BY_ROLE,
  STATUS_GROUP_LABEL_MAP,
  STATUS_GROUP_ORDER_MAP,
  STATUS_GROUP_STATUS_MAP,
} from '../work.maps';
import { WorkOrderCount } from './work-order-count.model';

export const {
  initialState: initialWorkOrderCountState,
  facade: WorkOrderCountFacadeBase,
  actions: { loadAll: loadAllWorkOrderCounts },
  selectors: { selectEntities: workOrderCountEntities },
} = buildState(WorkOrderCount);

const reduce = createReducer(initialWorkOrderCountState);

export function workOrderCountReducer(state = initialWorkOrderCountState, action: Action) {
  return reduce(state, action);
}

export const createStatusGroupCounts = createSelector(workOrderCountEntities, (entities): WorkOrderStatusGroup[] =>
  Object.keys(STATUS_GROUP_STATUS_MAP).map(key => ({
    status: key,
    group: key,
    label: STATUS_GROUP_LABEL_MAP[key],
    order: STATUS_GROUP_ORDER_MAP[status],
    count: STATUS_GROUP_STATUS_MAP[key].map(status => +entities[status]?.count || 0).reduce((acc, cur) => acc + cur, 0),
  })),
);

export const roleFilteredStatusGroups = createSelector(authenticatedRoleKey, createStatusGroupCounts, (role, groups) =>
  groups.filter(({ group }) => STATUS_GROUP_ALLOWED_BY_ROLE[role].includes(group)),
);

export const sortedStatusGroupCounts = createSelector(roleFilteredStatusGroups, sortStatusGroups);
