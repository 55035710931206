import { NgModule } from '@angular/core';
import { EntityService } from '../entity.service';
import { AddressTypeFacade } from './address-type/address-type.facade';
import { AddressType } from './address-type/address-type.model';
import { AddressFacade } from './address/address.facade';
import { Address } from './address/address.model';
import { CountryFacade } from './country/country.facade';
import { Country } from './country/country.model';
import { FacilityTypeFacade } from './facility-type/facility-type.facade';
import { FacilityType } from './facility-type/facility-type.model';
import { StateFacade } from './state/state.facade';
import { State } from './state/state.model';

@NgModule({
  providers: [
    AddressFacade,
    { provide: Address, useClass: EntityService },
    AddressTypeFacade,
    { provide: AddressType, useClass: EntityService },
    StateFacade,
    { provide: State, useClass: EntityService },
    CountryFacade,
    { provide: Country, useClass: EntityService },
    FacilityTypeFacade,
    { provide: FacilityType, useClass: EntityService },
  ],
})
export class LocationsModule {}
