import { parse as cacheControlParse } from 'cache-control-parser';
import { CacheControl } from 'cache-control-parser/dist/types';
import { add, isAfter, parse } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { compose } from '../shared/utils/compose.util';
import { split } from '../shared/utils/func';
import { HTTP_DATE_FORMAT } from './models/response-metadata.model';

export const getCacheControlDirectives = (cacheControl?: string): CacheControl =>
  cacheControl ? cacheControlParse(cacheControl) : {};

export interface AppCacheControl {
  'max-stale-age'?: number;
  'no-cache'?: boolean;
}

export const getAppCacheControlDirectives = (appCacheControl?: string): AppCacheControl =>
  appCacheControl
    ? compose(split(','), directives =>
        directives
          .map(directive => directive.split('='))
          .reduce(
            (all: AppCacheControl, [key, value]: [keyof AppCacheControl, any | undefined]) => (
              (all[key] = value ?? true), all
            ),
            {},
          ),
      )(appCacheControl)
    : {};

export const getMaxAge = (cacheControl?: string): number =>
  cacheControl ? +(getCacheControlDirectives(cacheControl)['max-age'] || Number.MAX_SAFE_INTEGER) : Number.MAX_SAFE_INTEGER;

export const getMaxStaleAge = (appCacheControl?: string): number =>
  appCacheControl ? +(getAppCacheControlDirectives(appCacheControl)['max-stale-age'] || Number.MAX_SAFE_INTEGER) : Number.MAX_SAFE_INTEGER;

export const parseHttpTime = (dt: string): Date => zonedTimeToUtc(parse(dt, HTTP_DATE_FORMAT, new Date()), 'UTC');

export const maxAged = (receivedAt: string, maxAge: number): Date =>
  add(parseHttpTime(receivedAt), { seconds: maxAge });

export const isExpired = (referenceDate: Date, receivedAt: string, maxAge: number): boolean =>
  isAfter(referenceDate, maxAged(receivedAt, maxAge));
