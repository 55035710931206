import { createSelector } from '@ngrx/store';
import { Invoice } from '../invoice/invoice.model';
import { currentInvoice } from '../invoice/invoices.state';
import { LienRelease } from './lien-release.model';
import { allLienReleases } from './lien-release.state';

export const findLienReleaseForInvoice = (lienReleases: LienRelease[], invoice?: Invoice): LienRelease | undefined =>
  invoice ? lienReleases.find(lienRelease => lienRelease.invoiceId === invoice.id) : undefined;

export const lienReleaseForCurrentInvoice = createSelector(allLienReleases, currentInvoice, findLienReleaseForInvoice);
