<ion-header>
  <gc-change-order-modal-header (cancel)="cancelAddEdit()">Change Order</gc-change-order-modal-header>
</ion-header>

<ion-content>
  <ion-item lines="full" class="ion-align-items-start">
    <ion-label>
      <h2>
        <strong>
          {{ item.name }}
        </strong>
      </h2>

      <ng-container>
        <p>
          <span> {{ item.numberOfUnits }} @ {{ item.pricePerUnit | currency }} </span>
          /
          <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
        </p>
      </ng-container>
      <gc-units-pending [item]="item"></gc-units-pending>
    </ion-label>
    <ion-text class="ion-no-padding ion-text-end" slot="end" style="margin-top: 10px">
      <ion-text [color]="item.isChangeOrder ? 'gc-blue' : 'gc-green'" [class.muted]="item.invoiceInfo.hasBeenInvoiced">
        <strong>{{ item.invoiceInfo.originalTotal | currency }}</strong>
      </ion-text>

      <br *ngIf="item.invoiceInfo.hasBeenInvoiced" />
      <ion-text *ngIf="item.invoiceInfo.hasBeenInvoiced" [color]="item.isChangeOrder ? 'gc-blue' : 'gc-green'">
        <strong>{{ item.invoiceInfo.amountToInvoice | currency }}</strong></ion-text
      >
    </ion-text>
  </ion-item>

  <!-- Uninvoiced Change Orders Display -->
  <ion-list *ngIf="item.uninvoicedChangeOrders?.length" class="pt-0">
    <ion-list-header color="gc-blue">
      <strong>Changes Not Yet Invoiced</strong>
    </ion-list-header>
    <ion-item
      *ngFor="let changeOrder of item.uninvoicedChangeOrders; last as last; trackBy: trackChangeOrderBy"
      [lines]="last ? 'none' : 'full'"
      class="bg-faded-blue"
    >
      <ion-row style="width: 100%">
        <ion-col size="6">
          <ion-label style="white-space: normal">
            <strong>{{ changeOrder.name }}</strong>
            <p class="current-ask">
              <strong>
                {{ changeOrder.adjustedNumberOfUnits }}
                @
                {{ changeOrder.adjustedPricePerUnit | currency }}
              </strong>
            </p>
          </ion-label>

          <ng-container slot="end">
            <ion-text *ngIf="changeOrder.acceptedById" color="gc-green">
              <ion-icon name="checkmark-circle" size="medium" style="transform: translateY(2px)"></ion-icon>
              Accepted
            </ion-text>
          </ng-container>
        </ion-col>

        <ion-col size="6" style="justify-content: end; align-items: center; display: flex">
          <ion-text
            color="danger"
            style="font-weight: bold"
            [style.font-style]="changeOrder.acceptedById ? 'inherit' : 'italic'"
          >
            ({{ changeOrder.adjustedNumberOfUnits * changeOrder.adjustedPricePerUnit | currency }})
          </ion-text>
        </ion-col>
      </ion-row>
    </ion-item>
    <ion-item class="change-summary" color="gc-dark-blue height-50">
      <ion-label style="white-space: normal">
        <h2>
          <strong>Item Total Remaining</strong>
        </h2>
      </ion-label>
      <div slot="end" class="px-10"
           [class.slotted-green]="item.uninvoicedChangeInfo.amountRemainingAfterAll > 0"
           [class.slotted-red]="item.uninvoicedChangeInfo.amountRemainingAfterAll <= 0"
      >
        <span style="font-weight: bold">{{ item.uninvoicedChangeInfo.amountRemainingAfterAll | currency }}</span>
      </div>
    </ion-item>
  </ion-list>

  <form [formGroup]="form">
    <ion-list>
      <div *ngIf="form!.get('adjustedNumberOfUnits')?.dirty && form!.get('adjustedNumberOfUnits')?.invalid">
        <ion-text
          class="ion-text-wrap ion-padding"
          color="danger"
          *ngIf="form!.get('adjustedNumberOfUnits')?.errors?.['adjustmentMustBeLessOrEqual']"
        >You cannot deduct more than the Total Remaining Units.
        </ion-text>
        <!--        <div-->
        <!--          *ngIf="form!.get('adjustedNumberOfUnits')?.errors?.['adjustmentMustBeLessOrEqual']"-->
        <!--          class="alert alert__warning"-->
        <!--        >You cannot deduct more than the Total Remaining Units.-->
        <!--        </div>-->
        <ion-text
          class="ion-text-wrap ion-padding"
          color="danger"
          *ngIf="form!.get('adjustedNumberOfUnits')?.errors?.['max']"
        >You cannot deduct more than the Remaining Units.
        </ion-text>
        <!--        <div-->
        <!--          *ngIf="form!.get('adjustedNumberOfUnits')?.errors?.['max']"-->
        <!--          class="alert alert__warning"-->
        <!--        >You cannot deduct more than the Remaining Units.-->
        <!--        </div>-->
        <ion-text
          *ngIf="
            form!.get('adjustedNumberOfUnits')?.errors?.['isPositive'] === false ||
            form!.get('adjustedNumberOfUnits')?.errors?.['incrementAmount']
          "
          color="danger"
          class="ion-text-wrap ion-padding"
        >Must enter a positive, whole number.
        </ion-text>
        <!--        <div class="alert alert__warning ion-margin"-->
        <!--             *ngIf="-->
        <!--            form!.get('adjustedNumberOfUnits')?.errors?.['isPositive'] === false ||-->
        <!--            form!.get('adjustedNumberOfUnits')?.errors?.['incrementAmount']"-->
        <!--        >Must enter a positive, whole number.-->
        <!--        </div>-->
        <!--        <div-->
        <!--          class="alert alert__warning"-->
        <!--          *ngIf="form!.get('adjustedPricePerUnit')?.errors?.isPositive === false"-->
        <!--        >Must enter a positive dollar amount.-->
        <!--        </div>-->

      </div>
      <div>
        <ion-text
          class="ion-text-wrap ion-padding"
          color="danger"
          *ngIf="form!.get('adjustedPricePerUnit')?.errors?.isPositive === false"
        >
          Must enter a positive dollar amount.
        </ion-text>
      </div>
      <ion-item lines="none">
        <ion-input
          formControlName="name"
          label="Name"
          errorText="You must provide a name for your deduction."
          labelPlacement="stacked"
          clearInput
          [color]="form!.get('name')?.invalid ? 'danger' : undefined"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-input
          label="Number of Units to Deduct"
          labelPlacement="floating"
          formControlName="adjustedNumberOfUnits"
          errorText="You must enter a number to deduct."
          [color]="form!.get('adjustedNumberOfUnits')?.invalid ? 'danger' : undefined"
          type="number"
          inputmode="numeric"
          step="1"
        ></ion-input>
      </ion-item>
      <ion-item lines="none">
        <ion-label position="floating">Price Adjustment</ion-label>
        <input
          placeholder="$0.00 per unit"
          currencyMask
          formControlName="adjustedPricePerUnit"
          inputmode="numeric"
          name="cost-per-unit"
          type="text"
          contenteditable="true"
          class="adjusted-price-input"
          style="padding: 0 20px; box-sizing: border-box; border: none; width: 100%; height: 100%"
        />
      </ion-item>
      <ion-item class="change-summary" color="gc-dark-blue height-50">
        <ion-label style="white-space: normal">
          <h2>
            <strong>Total After Current Change</strong>
          </h2>
        </ion-label>
        <div
          slot="end"
          class="slotted-green slotted-box-right px-10"
          [class.slotted-green]="item.uninvoicedChangeInfo.amountRemainingAfterAll - newAmount >= 0"
          [class.slotted-red]="item.uninvoicedChangeInfo.amountRemainingAfterAll - newAmount < 0"
        >
          <span style="font-weight: bold">{{ item.uninvoicedChangeInfo.amountRemainingAfterAll - newAmount | currency }}</span>
        </div>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer class="ion-padding">
  <gc-change-order-modal-footer (submitChangeOrder)="submit()" [disabled]="form!.invalid"
  >Submit Deduct
  </gc-change-order-modal-footer>
</ion-footer>
