<ion-list>
  <ng-container *ngIf="!!items?.length; else noItems" data-cy="item-chooser-list">
    <ion-item *ngFor="let item of items" [attr.data-cy]="item.label + '-item'">
      <ion-label [color]="item.added ? 'muted' : undefined" data-cy="item-name">
        {{ item.label }}
      </ion-label>
      <ion-button
        mode="ios"
        slot="end"
        size="small"
        color="gc-grey"
        fill="clear"
        *ngIf="item.adding"
        data-cy="item-adding-spinner"
      >
        Adding
        <ion-spinner name="dots"></ion-spinner>
      </ion-button>
      <ion-button
        mode="ios"
        slot="end"
        size="small"
        color="dark"
        fill="clear"
        *ngIf="item.added"
        data-cy="item-added-label"
      >
        Added!
      </ion-button>
      <ion-button
        #addButton
        mode="ios"
        slot="end"
        color="dark"
        size="small"
        *ngIf="!item.added && !item.adding"
        (click)="add(item, $event)"
        data-cy="add-item-button"
      >
        Add
        <ion-icon slot="start" name="add"></ion-icon>
      </ion-button>
    </ion-item>
  </ng-container>
  <ng-template #noItems>
    <div class="alert alert__warning text-center" data-cy="empty-item-list">
      {{ noItemsMessage }}
    </div>
  </ng-template>
</ion-list>
