<div class="modal-sidebar-menu-mobile">
  <ion-segment mode="ios" [scrollable]="true">
    <ion-segment-button value="overview" (click)="overviewSelected.emit()" data-cy="overview-menu-tab-mobile">
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faGridRound"
          [style.color]="('overview' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      Overview
    </ion-segment-button>
    <ion-segment-button
      *ngIf="installer.type === 'INSTALLER_TEAM_LEAD'"
      value="background"
      (click)="backgroundSelected.emit()"
      data-cy="background-menu-tab-mobile"
    >
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faBookOpenCover"
          [style.color]="('background' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      Background
    </ion-segment-button>
    <ion-segment-button
      *ngIf="installer.type === 'INSTALLER_TEAM_LEAD'"
      value="compliance"
      (click)="complianceSelected.emit()"
      data-cy="compliance-menu-tab-mobile"
    >
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faShieldCheck"
          [style.color]="('compliance' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      Compliance
    </ion-segment-button>
    <ion-segment-button value="training" (click)="trainingSelected.emit()" data-cy="training-menu-tab-mobile">
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faGraduationCap"
          [style.color]="('training' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      Training
    </ion-segment-button>
    <ion-segment-button value="details" (click)="detailsSelected.emit()" data-cy="details-menu-tab-mobile">
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faSparkles"
          [style.color]="('details' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      Details
    </ion-segment-button>
  </ion-segment>
</div>

<div class="modal-sidebar-menu-desktop">
  <ion-list lines="none">
    <ion-item
      (click)="overviewSelected.emit()"
      button
      lines="none"
      mode="ios"
      detail="false"
      data-cy="overview-menu-tab"
    >
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faGridRound"
          [style.color]="('overview' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      <ion-label class="menu-label" [color]="('overview' | isSelectedArea | push) ? 'gc-green' : 'dark'"
        >Overview
      </ion-label>
    </ion-item>

    <ion-item
      *ngIf="installer.type === 'INSTALLER_TEAM_LEAD'"
      (click)="backgroundSelected.emit()"
      button
      lines="none"
      mode="ios"
      detail="false"
      data-cy="background-menu-tab"
    >
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faBookOpenCover"
          [style.color]="('background' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      <ion-label class="menu-label" [color]="('background' | isSelectedArea | push) ? 'gc-green' : 'dark'"
        >Background
      </ion-label>
    </ion-item>

    <ion-item
      *ngIf="installer.type === 'INSTALLER_TEAM_LEAD'"
      (click)="complianceSelected.emit()"
      button
      lines="none"
      mode="ios"
      detail="false"
      data-cy="compliance-menu-tab"
    >
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faShieldCheck"
          [style.color]="('compliance' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      <ion-label class="menu-label" [color]="('compliance' | isSelectedArea | push) ? 'gc-green' : 'dark'"
        >Compliance
      </ion-label>
    </ion-item>

    <ion-item
      (click)="trainingSelected.emit()"
      button
      lines="none"
      mode="ios"
      detail="false"
      data-cy="training-menu-tab"
    >
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faGraduationCap"
          [style.color]="('training' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      <ion-label class="menu-label" [color]="('training' | isSelectedArea | push) ? 'gc-green' : 'dark'"
        >Training
      </ion-label>
    </ion-item>

    <ion-item (click)="detailsSelected.emit()" button lines="none" mode="ios" detail="false" data-cy="details-menu-tab">
      <div class="icon-div">
        <fa-icon
          slot="start"
          [icon]="faSparkles"
          [style.color]="('details' | isSelectedArea | push) ? '#7ac143' : 'black'"
        ></fa-icon>
      </div>
      <ion-label class="menu-label" [color]="('details' | isSelectedArea | push) ? 'gc-green' : 'dark'"
        >Details
      </ion-label>
    </ion-item>
  </ion-list>
</div>
