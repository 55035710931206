import { createSelector } from '@ngrx/store';
import {
  dwollaAccountInUnexpectedStatus,
  initialDwollaLoadComplete,
} from '~gc/shared/dwolla/feature/dwolla-account/dwolla.selectors';
import {
  initialPlaidLoadComplete,
  plaidAccountInUnexpectedStatus,
} from '~gc/shared/dwolla/feature/plaid/plaid.selectors';

export const hasInitialized = createSelector(
  initialDwollaLoadComplete,
  initialPlaidLoadComplete,
  (dwollaInit, plaidInit) => dwollaInit && plaidInit,
);

export const shouldContactSupport = createSelector(
  dwollaAccountInUnexpectedStatus,
  plaidAccountInUnexpectedStatus,
  (dwollaStatus, plaidStatus) => dwollaStatus || plaidStatus,
);
