<ion-header class="header-outline">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-toolbar class="ion-padding" style="padding-bottom: 0">
    <h1 class="ion-text-center">Get paid at the speed of your business.</h1>
    <ion-img src="/assets/images/settlement-timing/settlement-icon-group.svg"></ion-img>
    <h2 class="ion-text-center">Choose a settlement timing based on your real-time business needs.</h2>
    <div class="ion-text-center">
      <ion-text>
        With three (3) options to suit needs of all shapes and sizes, we put the power in your hands to run your
        business and manage your money.
      </ion-text>
    </div>
  </ion-toolbar>

  <ion-list class="ion-padding" style="padding-top: 0">
    <ion-item class="timing-info" lines="none" *ngFor="let timing of settlementTimings.all$ | push">
      <ion-note slot="start" class="percent-label" color="dark">
        <strong>{{ timing.percentage }}%</strong>
      </ion-note>
      <ion-label class="ion-text-wrap">
        <span [innerHTML]="timing.description"></span>
      </ion-label>
    </ion-item>

    <p class="text-small ion-text-center">
      *Lien Release must be signed before 9:00 a.m. CT in order to receive funds within the same business day.
      <a (click)="openUrl('https://gocarrera.com/features/preferred-settlement-timing/')"
      ><br />Read more about Settlement Timing</a
      >
    </p>
  </ion-list>
</ion-content>

<ion-footer class="ion-padding">
  <ion-toolbar>
    <ion-button color="gc-green" expand="block" mode="ios" class="ion-margin" (click)="dwolla.openPlaid()">
      Verify Bank with Plaid
    </ion-button>
  </ion-toolbar>
</ion-footer>
