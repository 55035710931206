<ion-list lines="none">
  <ion-item>
    <ion-label class="ion-text-wrap">
      <h2>
        <strong>
          {{ workOrder.privateJobName }}
        </strong>
      </h2>
    </ion-label>
    <span *ngIf="workOrder.productInstallationType" slot="end" class="gc-badge">
      {{ workOrder.productInstallationType.type }}
    </span>
  </ion-item>
  <ion-item>
    <ion-icon name="calendar" slot="start" color="dark"></ion-icon>
    <ion-text color="dark" >
      {{ workOrder.scheduledStartDate | date: 'longDate' }}
      - {{ workOrder.scheduledEndDate | date: 'longDate' }}
    </ion-text>
  </ion-item>
  <ion-item *ngIf="!(workOrder | isStatus: 'DRAFT')">
    <gc-work-order-installer-chip
      [workOrder]="workOrder"
      [matchedInstallerCount]="matchedInstallerCount"
      (reviewInstallers)="simple ? reviewInstallers.emit(workOrder) : popoverGC.dismiss(workOrder, 'review')"
    ></gc-work-order-installer-chip>
  </ion-item>
  <ion-item button="false" class="mt-5">
    <ion-button
      data-cy="select-jump-to-button"
      mode="ios"
      size="medium"
      color="dark"
      (click)="popoverGC.dismiss(workOrder, 'jump')"
      >View Work Order
    </ion-button>
    <ion-button
      data-cy="select-cancel-preview-popover-button"
      mode="ios"
      size="medium"
      fill="clear"
      color="dark"
      (click)="popoverGC.dismiss()"
      >Cancel
    </ion-button>
  </ion-item>
</ion-list>
