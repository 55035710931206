import { buildState } from '@briebug/ngrx-auto-entity';
import { createReducer } from '@ngrx/store';
import { KudosReason } from './kudos-reason.model';


export const {
  initialState: initialKudosReasonState,
  selectors: {
    selectAll: allKudosReasons,
  },
  actions: {
    load: loadKudosReason,
  }
} = buildState(KudosReason);

export const kudosReasonReducer = createReducer(initialKudosReasonState);
