<ion-content *ngIf="!(auth.isAuthenticated$ | push)" data-e2e="login-page">
  <ion-grid>
    <ion-card mode="ios">
      <ion-card-header>
        <ion-card-subtitle>
          <gc-custom-banner
            *ngIf="login.showAlert$ | push"
            (closed)="login.hideAlert()"
            [text]="auth.error$ | push"
            color="gc-alert"
            type="alert"
          >
          </gc-custom-banner>
        </ion-card-subtitle>
        <ion-card-title>
          <svg
            id="go-carrera-logo"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 263.94 87.74"
            style="enable-background: new 0 0 263.94 87.74"
            xml:space="preserve"
          >
            <style type="text/css">
              .st0 {
                fill: url(#SVGID_1_);
              }

              .st1 {
                fill: url(#SVGID_2_);
              }
            </style>
            <g>
              <g>
                <g>
                  <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="27.5976"
                    y1="13.8887"
                    x2="54.2951"
                    y2="72.6952"
                  >
                    <stop offset="0" style="stop-color: #fff33b" />
                    <stop offset="0.0307" style="stop-color: #fcef3c" />
                    <stop offset="0.1836" style="stop-color: #d6e03e" />
                    <stop offset="0.34" style="stop-color: #b6d43f" />
                    <stop offset="0.4982" style="stop-color: #9ccb41" />
                    <stop offset="0.6591" style="stop-color: #89c542" />
                    <stop offset="0.8242" style="stop-color: #7ec242" />
                    <stop offset="1" style="stop-color: #7ac143" />
                  </linearGradient>
                  <path
                    class="st0"
                    d="M56.22,58.36c-8.78,0-15.31-5.35-15.31-14.54c0-9.19,6.53-14.58,15.31-14.58c4.83,0,8.97,1.63,11.72,4.63
				c0.59,0.64,1.65,0.22,1.65-0.65v-0.95c0-2.97-1.58-5.71-4.15-7.19L44.64,13.07c-2.12-1.22-4.73-1.22-6.85,0L16.98,25.08
				c-2.57,1.48-4.15,4.23-4.15,7.19v2.24c0,0.76,0.97,1.08,1.41,0.47c2.82-3.88,7.57-5.7,12.5-5.7c7.76,0,11.02,4.08,11.84,4.9
				l-6.12,5.1c-0.61-0.61-2.45-2.45-5.72-2.45s-5.84,2.45-5.84,6.94c0,4.49,2.57,6.94,5.84,6.94s3.88-1.02,3.88-1.02v-2.04h-4.29
				v-6.82h9.63c2.01,0,3.64,1.63,3.64,3.64v8.69c-2.59,2.36-6.54,6.13-16.88,4.91c-3.52-0.42-6.32-2.7-8.5-5.5
				c-0.45-0.58-1.4-0.26-1.4,0.49v2.39c0,2.97,1.58,5.71,4.15,7.19l20.81,12.01c2.12,1.22,4.73,1.22,6.85,0l20.81-12.01
				c2.57-1.48,4.15-4.23,4.15-7.19v-1.19c0-0.82-1.01-1.22-1.56-0.62C65.28,56.7,61.1,58.36,56.22,58.36z"
                  />
                  <linearGradient
                    id="SVGID_2_"
                    gradientUnits="userSpaceOnUse"
                    x1="40.0289"
                    y1="7.9848"
                    x2="66.9185"
                    y2="67.2145"
                  >
                    <stop offset="0" style="stop-color: #fff33b" />
                    <stop offset="0.0307" style="stop-color: #fcef3c" />
                    <stop offset="0.1836" style="stop-color: #d6e03e" />
                    <stop offset="0.34" style="stop-color: #b6d43f" />
                    <stop offset="0.4982" style="stop-color: #9ccb41" />
                    <stop offset="0.6591" style="stop-color: #89c542" />
                    <stop offset="0.8242" style="stop-color: #7ec242" />
                    <stop offset="1" style="stop-color: #7ac143" />
                  </linearGradient>
                  <path
                    class="st1"
                    d="M50.18,43.62c0,3.88,2.37,6.57,6.04,6.57c3.67,0,6-2.7,6-6.57c0-3.88-2.33-6.57-6-6.57
				C52.55,37.04,50.18,39.74,50.18,43.62z"
                  />
                </g>
                <path
                  d="M146.9,57.79h-10.56l-4.47-9.34h-1.18v9.34h-9.18V33.17c0-1.88,1.52-3.4,3.4-3.4h8.79c3.45,0,6.09,0.61,8.12,2.03
			c2.03,1.42,3.45,3.86,3.45,6.9c0,5.69-3.45,7.72-4.67,8.45L146.9,57.79z M135,37.21c-0.41-0.32-1.01-0.53-2.03-0.53h-2.31v4.87
			h2.31c1.01,0,1.66-0.2,2.03-0.49c0.45-0.32,1.01-0.73,1.01-1.95C136.02,37.9,135.41,37.49,135,37.21z"
                />
                <path
                  d="M177.28,63.89h-10.06l-8.54-15.43h-1.18v9.34h-9.18V33.17c0-1.88,1.52-3.4,3.4-3.4h8.79c3.45,0,6.09,0.61,8.12,2.03
			c2.03,1.42,3.45,3.86,3.45,6.9c0,5.69-3.45,7.72-4.67,8.45L177.28,63.89z M161.8,37.21c-0.41-0.32-1.01-0.53-2.03-0.53h-2.31v4.87
			h2.31c1.01,0,1.66-0.2,2.03-0.49c0.45-0.32,1.01-0.73,1.01-1.95C162.81,37.9,162.2,37.49,161.8,37.21z"
                />
                <path
                  d="M195.32,57.79h-20.3V33.25c0-1.92,1.55-3.47,3.47-3.47h16.43V37h-10.76v3.05h10.15v7.19h-10.15v3.25h11.17V57.79z"
                />
                <path
                  d="M223.69,57.79h-10.56l-4.47-9.34h-1.18v9.34h-9.18V33.17c0-1.88,1.52-3.4,3.4-3.4h8.79c3.45,0,6.09,0.61,8.12,2.03
			c2.03,1.42,3.45,3.86,3.45,6.9c0,5.69-3.45,7.72-4.67,8.45L223.69,57.79z M211.79,37.21c-0.41-0.32-1.01-0.53-2.03-0.53h-2.31
			v4.87h2.31c1.01,0,1.66-0.2,2.03-0.49c0.45-0.32,1.01-0.73,1.01-1.95C212.8,37.9,212.2,37.49,211.79,37.21z"
                />
                <path
                  d="M251.12,57.79h-9.95l-1.1-3.94h-8l-1.06,3.94h-9.54l8.72-25.06c0.62-1.77,2.29-2.96,4.17-2.96h3.88
			c1.88,0,3.55,1.19,4.17,2.96L251.12,57.79z M238.16,46.83l-2.07-7.51l-2.07,7.51H238.16z"
                />
                <path
                  d="M108.85,29.8h-3.88c-1.88,0-3.55,1.19-4.17,2.96l-6,17.24c-2.74,0-9.18,0.35-12.5-0.13c-3.45-0.5-5.57-2.02-5.57-6.14
			c0-4.39,2.96-6.42,6.42-6.42c3.45,0,6.29,2.44,6.29,2.44l2.44-7.72c0,0-3.05-2.64-9.34-2.64c-8.32,0-15.02,5.12-15.02,14.46
			c0,9.34,6.7,14.46,15.02,14.46c0.04,0,0.08,0,0.11,0l12.07-0.25l6.89-0.23l0.09,0l4.99-18.48l5.7,18.48h9.32l-8.72-25.06
			C112.4,30.99,110.73,29.8,108.85,29.8z"
                />
              </g>
            </g>
          </svg>
        </ion-card-title>
      </ion-card-header>

      <ion-card-content class="ion-no-padding">
        <ion-button
          data-e2e="login-submit"
          size="large"
          mode="ios"
          expand="block"
          color="gc-alt-green"
          (click)="auth.logIn();"
        >
          Sign In
        </ion-button>
        <ion-button
          data-e2e="start-installer-profile-button"
          size="medium"
          fill="outline"
          mode="ios"
          expand="block"
          color="dark"
          class="ion-align-self-center"
          (click)="login.signUp()"
        >
          Start FREE Profile
        </ion-button>
      </ion-card-content>
    </ion-card>
  </ion-grid>
</ion-content>
