import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MatchedInstaller } from '~gc/domains/work/matched-installer/matched-installer.model';
import { EntityService } from '../entity.service';
import { NegotiationItemFacade } from '../negotiations/negotiation-item/negotiation-item.facade';
import { NegotiationFacade } from '../negotiations/negotiation/negotiation.facade';
import { UnitOfMeasureFacade } from '../unit-of-measure/unit-of-measure.facade';
import { UnitOfMeasure } from '../unit-of-measure/unit-of-measure.model';
import { WorkOrderRelationsFacade } from '../work-order-relations.facade';
import { CalendarService } from './calendar/calendar.service';
import { Contact } from './contact/contact';
import { InstallerReviewFacade } from './installer-review/installer-review.facade';
import { KudosUIService } from './kudos/kudos-ui.service';
import { KudosFacade } from './kudos/kudos.facade';
import { Kudos } from './kudos/kudos.model';
import { MatchedInstallerEffects } from './matched-installer/matched-installer.effects';
import { MatchedInstallersFacade } from './matched-installer/matched-installers.facade';
import { WorkAssignmentEffects } from './work-assignment.effects';
import { ChatDisconnectionEffects } from './work-order-chat/chat-disconnection.effects';
import { ChatInitializationEffects } from './work-order-chat/chat-initialization.effects';
import { WorkOrderChatEffects } from './work-order-chat/work-order-chat.effects';
import { WorkOrderChatFacade } from './work-order-chat/work-order-chat.facade';
import { WorkOrderChatService } from './work-order-chat/work-order-chat.service';
import { WORK_ORDER_CHAT_FEATURE_KEY, workOrderChatReducer } from './work-order-chat/work-order-chat.state';
import { WorkOrderCountFacade } from './work-order-count/work-order-count.facade';
import { WorkOrderCount } from './work-order-count/work-order-count.model';
import { WorkOrderDraftFacade } from './work-order-draft/work-order-draft.facade';
import { WorkOrderDraft } from './work-order-draft/work-order-draft.model';
import { WorkOrderEditFacade } from './work-order-edit/work-order-edit.facade';
import { WorkOrderPublishUIService } from './work-order-edit/work-order-publish-ui.service';
import { WorkOrderPublishEffects } from './work-order-edit/work-order-publish.effects';
import { WorkOrderFileEffects } from './work-order-files/work-order-file.effects';
import { WorkOrderFilesFacade } from './work-order-files/work-order-files.facade';
import { WorkOrderFile } from './work-order-files/work-order-files.model';
import { WorkOrderItemFacade } from './work-order-item/work-order-item.facade';
import { WorkOrderItem } from './work-order-item/work-order-item.model';
import { WorkOrderMapEffects } from './work-order-map/work-order-map.effects';
import { WorkOrderMapFacade } from './work-order-map/work-order-map.facade';
import { WorkOrderTagEffects } from './work-order-tag/work-order-tag.effects';
import { WorkOrderTagFacade } from './work-order-tag/work-order-tag.facade';
import { WorkOrderTag } from './work-order-tag/work-order-tag.model';
import { WorkOrderLoadingEffects } from './work-order/work-order-loading.effects';
import { WorkOrderFacade } from './work-order/work-order.facade';
import { WorkOrder } from './work-order/work-order.model';
import { WorkOrderService } from './work-order/work-order.service';
import { WorkUIService } from './work-ui.service';
import { WorkFacade } from './work.facade';
import { WorkService } from './work.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(WORK_ORDER_CHAT_FEATURE_KEY, workOrderChatReducer),
    EffectsModule.forFeature([
      WorkOrderLoadingEffects,
      WorkOrderTagEffects,
      MatchedInstallerEffects,
      WorkOrderFileEffects,
      WorkOrderPublishEffects,
      WorkOrderChatEffects,
      ChatInitializationEffects,
      ChatDisconnectionEffects,
      WorkOrderMapEffects,
      WorkAssignmentEffects,
    ]),
  ],
  providers: [
    CalendarService,
    InstallerReviewFacade,
    KudosFacade,
    MatchedInstallersFacade,
    NegotiationFacade,
    NegotiationItemFacade,
    UnitOfMeasureFacade,
    WorkFacade,
    WorkOrderCountFacade,
    WorkOrderDraftFacade,
    WorkOrderTagFacade,
    WorkOrderFacade,
    WorkOrderItemFacade,
    WorkOrderEditFacade,
    WorkOrderRelationsFacade,
    WorkOrderFilesFacade,
    WorkOrderChatFacade,
    WorkOrderMapFacade,

    WorkOrderChatService,
    WorkOrderService,
    WorkUIService,
    WorkService,
    WorkOrderPublishUIService,
    KudosUIService,

    { provide: Contact, useClass: EntityService },
    { provide: Kudos, useClass: EntityService },
    { provide: MatchedInstaller, useClass: EntityService },
    { provide: WorkOrder, useClass: EntityService },
    { provide: WorkOrderCount, useClass: EntityService },
    { provide: WorkOrderDraft, useClass: EntityService },
    { provide: WorkOrderFile, useClass: EntityService },
    { provide: WorkOrderItem, useClass: EntityService },
    { provide: WorkOrderTag, useClass: EntityService },
    { provide: UnitOfMeasure, useClass: EntityService },
  ],
})
export class WorkModule {}
