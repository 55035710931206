import { Action, createReducer, on } from '@ngrx/store';
import { calendarDateChanged, calendarTitleChanged } from '../calendar.actions';

export interface CalendarState {
  title: string;
  date: number;
}

export const initialCalendarState: CalendarState = {
  title: 'Calendar',
  date: Date.now(),
};

export const setOrKeep =
  <T, K extends keyof T, A extends Action>(prop: K) =>
  (state: T, action?: Action) => ({
    ...state,
    [prop]: action?.[prop as unknown as keyof Action] ?? state[prop],
  });

export const calendarReducer = createReducer(
  initialCalendarState,
  on(calendarTitleChanged, setOrKeep('title')),
  on(calendarDateChanged, setOrKeep('date')),
);
