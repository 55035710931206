import { Pipe, PipeTransform } from '@angular/core';
import { ChangeOrder } from '../../domains/change-orders/change-order/change-order.model';

export const getChangeOrdersTotal = (changeOrders: ChangeOrder[]) =>
  changeOrders?.reduce((sum, co) => sum + co.adjustedPricePerUnit * co.adjustedNumberOfUnits, 0) ?? '';

@Pipe({ name: 'changeOrdersTotal' })
export class ChangeOrdersTotalPipe implements PipeTransform {
  transform(changeOrders: ChangeOrder[]): number | '' {
    return getChangeOrdersTotal(changeOrders);
  }
}
