import { createSelector } from '@ngrx/store';
import { combinedTeamMembers } from '../../domains/users/user/user.selectors';
import { makeSelectables, view } from '../../shared/utils/ngrx';
import { selectedInstallerIds } from './store/ui.selectors';

export const selectableTeamMemberInstallers = createSelector(
  combinedTeamMembers,
  selectedInstallerIds,
  view('selectableTeamMemberInstallers', makeSelectables, false),
);
