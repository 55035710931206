import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { incrementAmount } from './invoice-amount-increment.validator';
import { addToErrors } from './util';

export const isPositive: ValidatorFn = (target: AbstractControl) =>
  target?.value != null ? (target.value > 0 ? null : { isPositive: false }) : null;

export const isPositiveOrZero: ValidatorFn = (target: AbstractControl) =>
  target?.value != null ? (target.value >= 0 ? null : { isPositive: false }) : null;

export const formGroupIncrementAmount: ValidatorFn = (control: AbstractControl) =>
  incrementAmount(control.get('unitOfMeasureSymbol')?.value)(control.get('numberOfUnits'));

export const maxProductOf = (count: number, perEach: number) => count * perEach;

export const maxItemTotalAmountAllowed: ValidatorFn = (control: AbstractControl) =>
  maxProductOf(
    (control as UntypedFormGroup).get('numberOfUnits')?.value ?? 0,
    (control as UntypedFormGroup).get('pricePerUnit')?.value ?? 0,
  ) <= environment.maximumAllowableItemAmount
    ? null
    : (addToErrors(control, 'maxItemTotalAmountAllowed'), { maxItemTotalAmountAllowed: true });

export class GenericValidators {
  static incrementAmount = incrementAmount;
  static isPositive = isPositive;
  static isPositiveOrZero = isPositiveOrZero;
  static formGroupIncrementAmount = formGroupIncrementAmount;
  static maxItemTotalAmountAllowed = maxItemTotalAmountAllowed;
}
