import { Action, createReducer, on } from '@ngrx/store';
import { RequireId } from '../../../shared/utils/types';
import { User } from '../../users';
import { claimsAndTokenRetrieved, queueAuthRequest } from './auth-connect.actions';
import {
  authenticated,
  authenticatedUserMetadataRetrieved,
  authenticatedUserMetadataUpdated,
  authenticationFailed,
  claimsReverifying,
  clear,
  loggedOut,
  login,
  updateAuthenticatedUser,
} from './auth.actions';
import { Claims } from './claims.model';

export interface IAuthState {
  error?: any;
  claims?: Claims;
  token?: string;
  user?: RequireId<User>;
  pendingAuthUrl?: string;
  reverifiedAt: number;
}

export const initialAuthState: IAuthState = {
  reverifiedAt: Date.now(),
};

export const wipeAuthState = () => initialAuthState;

export const clearToken = (state: IAuthState) => ({ ...state, token: undefined });

export const startLogin = (state: IAuthState) => ({
  ...state,
  claims: undefined,
  error: undefined,
  token: undefined,
  user: undefined,
});

export const storeAuthenticatedClaimsAndToken = (
  state: IAuthState,
  { claims, token }: { claims: Claims; token: string },
): IAuthState => ({
  ...state,
  claims,
  token,
});

export const storeAuthenticationError = (state: IAuthState, { error }: { error: any }): IAuthState => ({
  ...state,
  error,
});

export const storeUserMetadata = (state: IAuthState, { user }: { user: User }): IAuthState => ({
  ...state,
  user: user as RequireId<User>,
});

export const updateUserMetadata = (state: IAuthState, { user }: { user: User }): IAuthState => ({
  ...state,
  user: user as RequireId<User>,
});

export const trackPendingAuthUrl = (state: IAuthState, { url }: { url: string }): IAuthState => ({
  ...state,
  pendingAuthUrl: url,
});

const reduce = createReducer(
  initialAuthState,
  on(login, startLogin),
  on(loggedOut, wipeAuthState),
  on(clear, clearToken),
  on(authenticated, claimsAndTokenRetrieved, storeAuthenticatedClaimsAndToken),
  on(authenticationFailed, storeAuthenticationError),
  on(authenticatedUserMetadataRetrieved, authenticatedUserMetadataUpdated, storeUserMetadata),
  on(updateAuthenticatedUser, updateUserMetadata),
  on(queueAuthRequest, trackPendingAuthUrl),
  on(claimsReverifying, state => ({ ...state, reverifiedAt: Date.now() })),
);

export function authReducer(state = initialAuthState, action: Action): IAuthState {
  return reduce(state, action);
}
