import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { filterItems, whereNullish } from '../../../shared/utils/func';
import { matchByOptionalId } from '../../../shared/utils/utils';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { TypedHammerRating } from '../../models/detailed-installer';
import { ProductInstallationType } from '../../training/product-installation-types/product-installation-types.model';
import { allProductInstallationTypes } from '../../training/product-installation-types/product-installation-types.state';
import { companyEntities } from '../companies/company.state';
import { HammerRating } from '../hammer-rating/hammer-rating.model';
import { JumpstarterFavorite } from '../jumpstarter-favorites/jumpstarter-favorite.model';
import { selectAllJumpstarterFavorites } from '../jumpstarter-favorites/jumpstarter-favorites.state';
import { DetailedCompanyAdmin } from '../models/detailed-company-admin';
import {
  CompanyAdmin,
  EmployeeInstaller,
  Installer,
  InstallerTeamLead,
  InstallerTeamMember,
  isAdmin,
  isInstaller,
  isManager,
  isTeamMember,
  Jumpstarter,
  Manager,
  ProjectManager,
  sortUsers,
  User,
  UserType,
} from './user.model';
import { allUsers, userEntities } from './user.state';

export const filterInstallerUsers = (users: User[]): Installer[] => users.filter(isInstaller);

export const filterManagerUsers = (users: User[]): Manager[] => users.filter(isManager);

export const combineHammerRatingsAndTypeNames = (
  hammerRatings: HammerRating[] | undefined,
  types: ProductInstallationType[],
): TypedHammerRating[] | undefined =>
  hammerRatings?.map(rating => ({
    ...rating,
    type: (types.find(matchByOptionalId(rating.productInstallationTypeId)) || {}).type,
  }));

export const joinInstallerAndProductInstallTypes = (installers: Installer[], types: ProductInstallationType[]) =>
  installers.map(installer => ({
    ...installer,
    hammerRating: combineHammerRatingsAndTypeNames(installer.hammerRatings, types),
  }));

export const filterUsersByType =
  <R extends User>(userType: UserType) =>
  (users: User[]): R[] =>
    users.filter(user => user.type === userType) as R[];

export const allSortedUsers = createSelector(allUsers, sortUsers);

export const allInstallers = createSelector(allSortedUsers, filterInstallerUsers);

export const allManagers = createSelector(allSortedUsers, filterManagerUsers);

export const nonDeletedManagers = createSelector(allManagers, filterItems(whereNullish('deletedAt')));

export const installersWithRatingTypes = createSelector(
  allInstallers,
  allProductInstallationTypes,
  joinInstallerAndProductInstallTypes,
);

export const allEmployeeInstallers = createSelector(
  allSortedUsers,
  filterUsersByType<EmployeeInstaller>('EMPLOYEE_INSTALLER'),
);

export const nonDeletedEmployeeInstallers = createSelector(
  allEmployeeInstallers,
  filterItems(whereNullish('deletedAt')),
);

export const allProjectManagers = createSelector(
  allSortedUsers,
  filterUsersByType<ProjectManager>('COMPANY_PROJECT_MANAGER'),
);

export const nonDeletedProjectManagers = createSelector(allProjectManagers, filterItems(whereNullish('deletedAt')));

export const allCompanyAdmins = createSelector(allSortedUsers, filterUsersByType<CompanyAdmin>('COMPANY_ADMIN'));

export const allTeamLeads = createSelector(allSortedUsers, filterUsersByType<InstallerTeamLead>('INSTALLER_TEAM_LEAD'));

export const nonDeletedTeamLeads = createSelector(allTeamLeads, (leads: InstallerTeamLead[]) =>
  leads.filter(whereNullish('deletedAt')),
);

export const allTeamMembers = createSelector(
  allSortedUsers,
  filterUsersByType<InstallerTeamMember>('INSTALLER_TEAM_MEMBER'),
);

export const nonDeletedTeamMembers = createSelector(allTeamMembers, filterItems(whereNullish('deletedAt')));

export const allJumpstarters = createSelector(allSortedUsers, filterUsersByType<Jumpstarter>('JUMPSTARTER'));

export const jumpstartersOnTeam = createSelector(
  allJumpstarters,
  authenticatedUser,
  (jumpstarters, user): (Jumpstarter & { badges: string[] })[] =>
    jumpstarters
      .filter(({ installerTeamLeadId, id }) => installerTeamLeadId === user?.id || id === user?.id)
      .map(jumpstarter => ({ ...jumpstarter, badges: ['Jumpstarter'] })),
);

export const combinedTeamMembers = createSelector(allTeamMembers, jumpstartersOnTeam, (teamMembers, jumpstarters) =>
  sortUsers([...teamMembers, ...jumpstarters]),
);

export const combinedNonDeletedTeamMembers = createSelector(
  nonDeletedTeamMembers,
  jumpstartersOnTeam,
  (teamMembers, jumpstarters) => sortUsers([...teamMembers, ...jumpstarters]),
);

export const jumpstartersNotOnTeam = createSelector(
  allJumpstarters,
  authenticatedUser,
  (jumpstarters: Jumpstarter[], user?: User): Jumpstarter[] =>
    user ? jumpstarters.filter(({ installerTeamLeadId }) => installerTeamLeadId !== user.id) : [],
);

export const jumpstartersNotOnTeamFavoriteStatus = createSelector(
  jumpstartersNotOnTeam,
  selectAllJumpstarterFavorites,
  (jumpstarters, favorites): (Jumpstarter & { favorite?: JumpstarterFavorite })[] =>
    jumpstarters?.map(jumpstarter => ({
      ...jumpstarter,
      favorite: favorites.find(favorite => favorite.jumpstarterId === jumpstarter.id),
    })) ?? [],
);

export const usersTeamLead = createSelector(
  userEntities,
  authenticatedUser,
  (users: IEntityDictionary<User>, authUser?: User): InstallerTeamLead | undefined =>
    !!authUser && isTeamMember(authUser) ? (users[authUser.installerTeamLeadId] as InstallerTeamLead) : undefined,
);

export const detailedCurrentCompanyAdmin = createSelector(
  authenticatedUser,
  companyEntities,
  (user, companies): DetailedCompanyAdmin | undefined =>
    !!user && isAdmin(user)
      ? {
          ...user,
          company: companies?.[user.companyId],
        }
      : undefined,
);
