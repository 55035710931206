import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DetailedInstaller } from '../../models/detailed-installer';
import { AppState } from '../../state';
import { WorkOrder } from '../work-order/work-order.model';
import { reviewInstaller, reviewInstallers } from './installer-review.actions';
import { currentInstaller } from './installer-review.selectors';

@Injectable()
export class InstallerReviewFacade {
  currentInstaller$ = this.store.select(currentInstaller);

  constructor(private store: Store<AppState>) {}

  reviewInstallers<T extends WorkOrder>(workOrder: T) {
    this.store.dispatch(reviewInstallers({ workOrder }));
  }

  reviewInstaller(installer: DetailedInstaller) {
    this.store.dispatch(reviewInstaller({ installer }));
  }
}
