<ion-card mode="ios" class="ion-no-margin">
  <ion-card-header>
    <ion-card-title class="ion-text-center">
      {{ bank.bankName }}
    </ion-card-title>
    <ion-card-subtitle class="ion-text-center"> Account No. **{{ bank.last4 }} </ion-card-subtitle>
  </ion-card-header>
  <ion-card-content>
    <ion-button (click)="selected.emit(bank)" size="large" expand="block" color="gc-alt-green">
      Pay From this Account
      <ion-icon slot="end" name="logo-usd"></ion-icon>
    </ion-button>
  </ion-card-content>
</ion-card>
