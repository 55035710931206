import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Company',
  uriName: 'companies',
})
export class Company {
  @Key id?: number;
  name!: string;
  isActive!: boolean;
  website?: string;
  logoUrl?: string;
  logo?: {
    type: string;
    data: number[];
  };
}
