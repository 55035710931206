import { Pipe, PipeTransform } from '@angular/core';
import { isCompanyUser, isInstallerLead, User } from '../../domains/users';
import { Connection, ConnectionStatus } from '../../domains/users/connection/connection.model';

export const isConnectedTo = (user: User, authUser: User, connection?: Connection): boolean =>
  !!connection &&
  connection.status !== ConnectionStatus.Declined &&
  connection.status !== ConnectionStatus.Disconnected &&
  ((isCompanyUser(authUser) &&
    connection.companyId === authUser.companyId &&
    connection.installerTeamLeadId === user.id) ||
    (isInstallerLead(authUser) &&
      isCompanyUser(user) &&
      connection.installerTeamLeadId === authUser.id &&
      connection.companyId === user.companyId));

@Pipe({ name: 'isConnectedTo' })
export class IsConnectedToPipe implements PipeTransform {
  transform(user: User, authUser: User, connection: Connection): boolean {
    return isConnectedTo(user, authUser, connection);
  }
}
