import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Negotiation',
  uriName: 'negotiations',
})
export class Negotiation {
  @Key id?: number;
  installerId!: string;
  workOrderId!: string;
  currentAskStartDate!: string;
  currentAskStartDateLastUpdated?: string;
  currentBidStartDate?: string;
  currentBidStartDateLastUpdated?: string;
  finalNegotiatedStartDate?: string;
  isAcceptedByInstaller!: boolean;
}
