<ion-grid *ngIf="dateNegotiation">
  <ion-row>
    <ion-col *ngIf="!!dateNegotiation?.currentBidStartDate && !dateNegotiation?.isAcceptedByInstaller">
      <div class="date-alert">
        <ion-icon name="warning"></ion-icon>
        <span
          >{{ user.firstName }} has suggested a start date of
          <strong>{{ dateNegotiation?.currentBidStartDate | date }}</strong
          >. Your current ask is {{ (dateNegotiation.currentAskStartDate || workOrder?.scheduledStartDate) | date }}. <br /><small
            >Date range(s) already added to this Work Order may be altered/removed if you award this negotiated start
            date. Please double-check all dates to ensure this proposed start date works for your schedule.</small
          >
        </span>
      </div>
    </ion-col>
    <ion-col *ngIf="dateNegotiation?.isAcceptedByInstaller">
      <div class="date-alert">
        <ion-icon name="warning"></ion-icon>
        <span
          >{{ user.firstName }} has accepted the negotiated start date of:
          {{ dateNegotiation?.currentAskStartDate || workOrder?.scheduledStartDate | date }}
        </span>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
