import { Component } from '@angular/core';
import { faHandshakeSimpleSlash } from '@fortawesome/free-solid-svg-icons/faHandshakeSimpleSlash';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';
import { ProfileFacade, UsersUIFacade } from '../../../domains/users';

@Component({
  selector: 'gc-view-project-manager-profile-modal',
  templateUrl: './view-project-manager-profile-modal.component.html',
  styleUrls: ['./view-project-manager-profile-modal.component.scss'],
})
export class ViewProjectManagerProfileModalComponent {
  selectedArea = 'general';

  constructor(
    public auth: AuthFacade,
    public profile: ProfileFacade,
    public usersUI: UsersUIFacade,
  ) {}

  selectArea(area: string): void {
    this.selectedArea = area;
  }

  readonly faTrashCan = faTrashCan;
  readonly faHandshakeSimpleSlash = faHandshakeSimpleSlash;
}
