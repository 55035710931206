import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { IfModule } from '@rx-angular/template/if';
import { LetModule } from '@rx-angular/template/let';
import { PushModule } from '@rx-angular/template/push';
import { ProfileFacade } from '~gc/domains/users';
import { AreaSegmentsModule } from '~gc/shared/components/area-segments/area-segments.module';
import { HammerRatingDetailsModule } from '~gc/shared/components/hammer-rating-details/hammer-rating-details.module';
import { UserHeaderModule } from '~gc/shared/components/user-header/user-header.module';
import { PipesModule } from '~gc/shared/pipes/pipes.module';
import { PhoneNumberListModule } from '../../shared/components/phone-number-list/phone-number-list.module';
import { ComplianceAreaComponent } from './compliance-area/compliance-area.component';
import { GeneralAreaComponent } from './general-area/general-area.component';
import { HammerRatingAreaComponent } from './hammer-rating-area/hammer-rating-area.component';
import { ViewInstallerProfileModalComponent } from './view-installer-profile-modal/view-installer-profile.modal';

@NgModule({
  declarations: [
    ComplianceAreaComponent,
    GeneralAreaComponent,
    HammerRatingAreaComponent,
    ViewInstallerProfileModalComponent,
  ],
  imports: [
    AreaSegmentsModule,
    CommonModule,
    HammerRatingDetailsModule,
    IonicModule,
    PipesModule,
    UserHeaderModule,
    PhoneNumberListModule,
    FontAwesomeModule,
    PushModule,
    IfModule,
    LetModule,
  ],
  providers: [ProfileFacade],
  exports: [GeneralAreaComponent, ViewInstallerProfileModalComponent, ComplianceAreaComponent],
})
export class ViewInstallerProfileModalModule {}
