import { createAction, props } from '@ngrx/store';
import { WorkOrder } from '../work-order/work-order.model';

export const workOrderChatActivated = createAction('[WorkOrder] (Chat) Activated', props<{ workOrder: WorkOrder }>());

export const workOrderChatDeactivated = createAction('[WorkOrder] (Chat) Deactivated', props<{ workOrder: WorkOrder }>());

export const workOrderChatChannelActivated = createAction(
  '[WorkOrder] (Chat Channel) Activated',
  props<{ workOrderId: string; channelId: string }>(),
);

export const loadWorkOrderForChannel = createAction(
  '[WorkOrder] (Chat) Load WorkOrder For Channel',
  props<{ correlationId: string }>(),
);

export const switchToCurrentWorkOrderChat = createAction('[WorkOrder] (Chat) Switch to Current');

export const workOrderChatInitialized = createAction('[WorkOrder] (Chat) Initialized');

export const workOrderChatSidebarOpened = createAction('[WorkOrder] (Chat Sidebar) Opened', props<{ channels?: string[] }>());
export const workOrderChatError = createAction('[WorkOrder] (Chat) Error', props<{ channels: string[] }>());
export const workOrderChatSidebarClosed = createAction('[WorkOrder] (Chat Sidebar) Closed');

export const addMemberToChannel = createAction('[WorkOrder] (Chat) Add Member To Channel');
export const removeMemberFromChannel = createAction('[WorkOrder] (Chat) Remove Member From Channel');
