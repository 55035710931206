<ion-grid>
  <ion-row>
    <ion-col>
      <ion-item lines="none">
        <ion-spinner slot="start"></ion-spinner>
        <ion-label>{{ header }}</ion-label>
      </ion-item>
    </ion-col>
  </ion-row>

  <ion-row>
    <ion-col *ngIf="pendingFiles$ | push as uploads">
      <ng-container *ngFor="let file of uploads; trackBy: trackUploadsBy">
        <ion-item lines="none">
          <ion-label>{{ file.filename }}</ion-label>
          <ion-icon *ngIf="file.progressPercent == 1" slot="end" name="checkmark" color="gc-green"></ion-icon>
          <ion-note *ngIf="file.progressPercent < 1" slot="end">{{ file.progressPercent | percent }}</ion-note>
        </ion-item>
        <ion-progress-bar *ngIf="file.progressPercent < 1" [value]="file.progressPercent"></ion-progress-bar>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
