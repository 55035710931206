import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { appInitialize } from '~gc/domains/app/app.actions';
import { authenticatedUserMetadataRetrieved, authSubjectChanged } from '../../../domains/app/auth/auth.actions';
import { StorageRefService } from '../../../shared/services/storage-ref.service';
import { calendarSettingsRestored, calendarToggled, calendarViewChanged } from './settings.actions';
import { settingsState } from './settings.selectors';

export const CALENDAR_SETTINGS_STORAGE_KEY = 'gc__calendar_settings';

@Injectable()
export class SettingsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly storageRef: StorageRefService,
  ) {}

  clearSavedSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authSubjectChanged),
        switchMap(() => this.storageRef.storage()),
        tap(storage => storage.remove(CALENDAR_SETTINGS_STORAGE_KEY)),
      ),
    { dispatch: false },
  );

  restoreSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticatedUserMetadataRetrieved),
      delay(10),
      switchMap(() => this.storageRef.storage()),
      switchMap(storage => storage.get(CALENDAR_SETTINGS_STORAGE_KEY)),
      // map(json => json && JSON.parse(json)),
      filter(restored => !!restored),
      map(restored => calendarSettingsRestored({ restored })),
    ),
  );

  saveSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(calendarViewChanged, calendarToggled),
        withLatestFrom(this.store.select(settingsState)),
        switchMap(([, state]) => this.storageRef.storage().then(storage => [storage, state] as const)),
        tap(([storage, state]) => storage.set(CALENDAR_SETTINGS_STORAGE_KEY, state)),
      ),
    { dispatch: false },
  );
}
