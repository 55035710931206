import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DiscountCode } from './discount-code.model';
import { DiscountCodesFacadeBase } from './discount-codes.state';

@Injectable()
export class DiscountCodesFacade extends DiscountCodesFacadeBase {
  constructor(private store: Store) {
    super(DiscountCode, store);
  }
}
