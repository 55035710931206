import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { AlertController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { negotiationsCanceled } from '../../negotiations/negotiations.actions';
import { AppState } from '../../state';
import { User } from '../../users';
import { declineWorkOrder } from '../work-order-ui.actions';
import { WorkOrder } from '../work-order/work-order.model';
import { InstallerResponse, MatchedInstaller } from './matched-installer.model';
import {
  biddingMatchedInstallersForCurrentWorkOrder,
  currentMatchedInstallerForWorkOrder,
} from './matched-installer.selectors';
import { MatchedInstallersFacadeBase } from './matched-installer.state';

@Injectable()
export class MatchedInstallersFacade extends MatchedInstallersFacadeBase {
  currentMatchedInstallerForWorkOrder$ = this.store.select(currentMatchedInstallerForWorkOrder);
  biddingMatchedInstallersForCurrentWorkOrder$ = this.store.select(biddingMatchedInstallersForCurrentWorkOrder);

  constructor(
    private readonly store: Store<AppState>,
    private readonly alerts: AlertController,
  ) {
    super(MatchedInstaller, store);
  }

  addSelfAsMatchedInstaller(user: User, workOrder: WorkOrder) {
    this.create(
      {
        installerId: user.id,
        workOrderId: workOrder.id,
        response: InstallerResponse.Pending,
      } as MatchedInstaller,
      {
        parents: {
          [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
        },
      },
    );
  }

  async accept({
    matchedInstaller,
    workOrder,
  }: {
    matchedInstaller: MatchedInstaller;
    workOrder: WorkOrder;
  }): Promise<void> {
    const role = await this.confirmAction('Accepting');
    if (role === 'ok') {
      this.replace(
        {
          ...matchedInstaller,
          response: InstallerResponse.Accepted,
        },
        {
          parents: {
            [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
          },
        },
      );
    }
  }

  negotiate({ matchedInstaller, workOrder }: { matchedInstaller: MatchedInstaller; workOrder: WorkOrder }): void {
    this.replace(
      {
        ...matchedInstaller,
        response: InstallerResponse.Negotiating,
      },
      {
        parents: {
          [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
        },
      },
    );
  }

  async cancelNegotiation({
    matchedInstaller,
    workOrder,
  }: {
    matchedInstaller: MatchedInstaller;
    workOrder: WorkOrder;
  }): Promise<void> {
    const role = await this.confirmAction('Canceling', 'negotiation');
    if (role === 'ok') {
      this.replace(
        {
          ...matchedInstaller,
          response: InstallerResponse.Pending,
        },
        {
          parents: {
            [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
          },
        },
      );
      this.store.dispatch(negotiationsCanceled({ workOrder }));
    }
  }

  async decline({
    matchedInstaller,
    workOrder,
  }: {
    matchedInstaller: MatchedInstaller;
    workOrder: WorkOrder;
  }): Promise<void> {
    this.store.dispatch(declineWorkOrder({ matchedInstaller, workOrder }));
  }

  async confirmAction(action: string, thing: string = 'Work Order'): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header: 'Are you sure?',
      message: `${action} this ${thing} cannot be undone!`,
      buttons: [
        {
          text: 'Yes',
          role: 'ok',
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }
}
