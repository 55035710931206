import { EntityActions, isEntityActionInstance, stateNameFromAction } from '@briebug/ngrx-auto-entity';
import { IEntityAction } from '@briebug/ngrx-auto-entity/lib/actions/entity-action';
import { Action, ActionCreator, ActionReducer } from '@ngrx/store';
import { OnReducer, ReducerTypes } from '@ngrx/store/src/reducer_creator';
import { GenericFile } from '../../domains/models/generic-file';
import { PendingFile } from '../../domains/models/pending-file';
import { initialFileState } from './file-state';
import {
  deleteFactory,
  deleteFailureFactory,
  deleteSuccessFactory,
  FILE_ACTION_TYPES,
  GenericActionCreator,
  removePendingFactory,
  uploadFactory,
  uploadFailureFactory,
  uploadProgressFactory,
  uploadRetryFactory,
  uploadSuccessFactory,
} from './file.actions';
import { GenericAction } from './file.effects';

export const FEATURE_AFFINITY = '__ngrxae_feature_affinity';

export function featureNameFromAction(action: EntityActions<any>): string {
  return (action.info.modelType as any)[FEATURE_AFFINITY];
}

export const updatePendingFile = (
  pendingUploads: PendingFile[],
  pendingFile: PendingFile,
  update: (pendingFile: PendingFile) => PendingFile,
): PendingFile[] => {
  const index = pendingUploads.findIndex(upload => upload.correlationId === pendingFile.correlationId);
  return pendingUploads.length === 1
    ? [update(pendingUploads[index])]
    : [...pendingUploads.slice(0, index), update(pendingUploads[index]), ...pendingUploads.slice(index + 1)];
};

export const filterPendingUploads = (
  pendingUploads: PendingFile[] | undefined,
  entity: GenericFile & { correlationId?: string },
): PendingFile[] | undefined =>
  pendingUploads
    ? pendingUploads.length === 1 && pendingUploads[0].correlationId !== entity.correlationId
      ? undefined
      : pendingUploads.filter(pending => pending.correlationId !== entity.correlationId)
    : undefined;

declare type GenericExtractActionTypes<Creators extends readonly ActionCreator[]> = {
  [Key in keyof Creators]: Creators[Key] extends GenericActionCreator<infer T> ? T : never;
};

export const onGeneric = <State, Creators extends readonly GenericActionCreator[], InferredState = State>(
  ...args: [...creators: Creators, reducer: OnReducer<State extends infer S ? S : never, Creators, InferredState>]
): ReducerTypes<unknown extends State ? InferredState : State, Creators> => {
  const reducer = args.pop() as unknown as OnReducer<unknown extends State ? InferredState : State, Creators>;
  const types = (args as unknown as Creators).map(
    creator => creator.genericType,
  ) as unknown as GenericExtractActionTypes<Creators>;
  return {
    reducer,
    types,
  } as any;
};

export function createGenericReducer<
  S,
  A extends GenericAction = GenericAction,
  R extends ActionReducer<S, A> = ActionReducer<S, A>,
>(initialState: S, ...ons: ReducerTypes<S, GenericActionCreator[]>[]): R {
  const map = new Map<string, OnReducer<S, GenericActionCreator[]>>();
  for (const on of ons) {
    for (const type of on.types) {
      const existingReducer = map.get(type);
      if (existingReducer) {
        const newReducer: typeof existingReducer = (state, action) =>
          on.reducer(existingReducer(state, action), action);
        map.set(type, newReducer);
      } else {
        map.set(type, on.reducer);
      }
    }
  }

  return function (state: S = initialState, action: A): S {
    const reducer = map.get(action.genericType);
    return reducer ? reducer(state, action) : state;
  } as R;
}

const reduce = createGenericReducer(
  initialFileState,
  onGeneric(
    uploadFactory(null),
    (
      state: any,
      {
        pendingFile,
        correlationId,
      }: {
        pendingFile: PendingFile;
        correlationId: string;
      },
    ) => ({
      ...state,
      pendingUploads: [
        ...(filterPendingUploads(state.pendingUploads, pendingFile) ?? []),
        { ...pendingFile, correlationId, failed: false, progress: {} },
      ],
    }),
  ),
  onGeneric(uploadRetryFactory(null), (state: any, { pendingFile }: { pendingFile: PendingFile }) => ({
    ...state,
    pendingUploads: [
      ...(filterPendingUploads(state.pendingUploads, pendingFile) ?? []),
      { ...pendingFile, failed: false },
    ],
  })),
  onGeneric(
    uploadProgressFactory(null),
    (state: any, { pendingFile, progress }: { pendingFile: PendingFile; progress: any }) => ({
      ...state,
      pendingUploads: updatePendingFile(state.pendingUploads ?? [], pendingFile, file => ({ ...file, progress })),
    }),
  ),
  onGeneric(
    uploadSuccessFactory(null),
    removePendingFactory(null),
    (state: any, { pendingFile }: { pendingFile: PendingFile }) => ({
      ...state,
      pendingUploads: filterPendingUploads(state.pendingUploads, pendingFile),
    }),
  ),
  onGeneric(uploadFailureFactory(null), (state: any, { pendingFile }: { pendingFile: PendingFile }) => ({
    ...state,
    pendingUploads: updatePendingFile(state.pendingUploads ?? [], pendingFile, file => ({ ...file, failed: true })),
  })),
  onGeneric(deleteFactory(null), (state: any, { file }) => ({
    ...state,
    pendingDelete: file?.id ? [...(state.pendingDelete ?? []), file.id] : state.pendingDelete,
  })),
  onGeneric(deleteSuccessFactory(null), deleteFailureFactory(null), (state: any, { file }: { file: GenericFile }) => ({
    ...state,
    pendingDelete: state.pendingDelete ? reducePendingDelete(state.pendingDelete, file) : undefined,
  })),
);

export const reducePendingDelete = (pendingDelete: any[], file: GenericFile) =>
  pendingDelete.length === 1 && pendingDelete[0] === file.id ? undefined : pendingDelete.filter(id => id !== file.id);

export const shouldReduceAction = (action: any): action is GenericAction =>
  Object.values(FILE_ACTION_TYPES).includes(action.genericType as any);

export const setNewState = (featureName: string, stateName: string, state: any, newState: any) =>
  featureName
    ? { ...state, [featureName]: { ...state[featureName], [stateName]: newState } }
    : { ...state, [stateName]: newState };

export function fileEntityReducer(reducer: ActionReducer<any>, state: any, action: Action) {
  let stateName: string;
  let featureName: string;
  let entityState: any;
  let nextState: any;

  if (shouldReduceAction(action)) {
    stateName = stateNameFromAction(action as unknown as IEntityAction);
    featureName = featureNameFromAction(action as unknown as IEntityAction);
    entityState = featureName ? state[featureName][stateName] : state[stateName];

    const newState = reduce(entityState, action);

    nextState = setNewState(featureName, stateName, state, newState);
  }

  return reducer(nextState || state, action);
}

export function fileEntityMetaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action: Action) => {
    const shouldBeHandled = isEntityActionInstance(action as IEntityAction) || shouldReduceAction(action);
    return shouldBeHandled ? fileEntityReducer(reducer, state, action as IEntityAction) : reducer(state, action);
  };
}
