import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { authenticatedUser } from '../../domains/app/auth/auth.selectors';
import { DetailedWorkOrderItem } from '../../domains/models/detailed-work-order-item';

@Component({
  selector: 'gc-confirm-decline-modal',
  templateUrl: './confirm-decline-modal.component.html',
  styleUrls: ['./confirm-decline-modal.component.scss'],
})
export class ConfirmDeclineModalComponent {
  @Input() item!: DetailedWorkOrderItem;
  user$ = this.store.select(authenticatedUser);

  form: UntypedFormGroup;

  constructor(
    private readonly store: Store,
    private readonly builder: UntypedFormBuilder,
    protected readonly modalGC: ModalController,
  ) {
    this.form = this.builder.group({
      reason: [undefined, Validators.required],
    });
  }
}
