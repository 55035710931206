<ion-grid>
  <ion-row>
    <ion-col>
      <h1>Compliance</h1>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <gc-insurance-info-form
        [validated]="validated"
        [form]="form"
        [user]="user"
        [files]="files"
        (fileAdded)="fileAdded.emit($event)"
      ></gc-insurance-info-form>
    </ion-col>
  </ion-row>
</ion-grid>
