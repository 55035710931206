import { Component, Input } from '@angular/core';
import { UserFile } from '../../../domains/users/user-files/user-file.model';

@Component({
  selector: 'gc-compliance-area',
  templateUrl: './compliance-area.component.html',
  styleUrls: ['./compliance-area.component.scss'],
})
export class ComplianceAreaComponent {
  @Input() isLoading = false;
  @Input() files!: UserFile[];
}
