import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Training } from './training.model';

export const {
  initialState: initialTrainingState,
  facade: TrainingFacadeBase,
  selectors: { selectAll: allTrainings },
  actions: { loadAllIfNecessary: loadAllTrainingsIfNecessary, clear: clearTrainingFiles },
} = buildState(Training);

export function trainingReducer(state = initialTrainingState): IEntityState<Training> {
  return state;
}
