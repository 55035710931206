import { createSelector } from '@ngrx/store';
import { wherePropMatches } from '../shared/utils/func';
import { authenticatedUser } from './app/auth/auth.selectors';
import { ProductInstallationTypeClaimed } from './training/product-installation-types-claimed/product-installation-types-claimed.model';
import { approvedAuthenticatedUserInstallationTypes } from './training/skills-and-experience.selectors';
import { HammerRating, isInstallerLead, User } from './users';
import { DetailedWorkOrder } from './work/models/detailed-work-order';
import { detailedWorkOrder } from './work/work-order/work-order.selectors';

export interface UserWorkOrderQualifications {
  approvedQualification: boolean;
  trainingQualification: boolean;
  hammerRatingQualification: boolean;
}

export type UnqualifiedReason = keyof UserWorkOrderQualifications;

export const userMatchedProductInstallationType = createSelector(
  approvedAuthenticatedUserInstallationTypes,
  detailedWorkOrder,
  (installTypes, workOrder): ProductInstallationTypeClaimed | undefined =>
    installTypes.find(wherePropMatches('productInstallationTypeId', workOrder?.productInstallationTypeId)),
);

export const userMatchedHammerRating = createSelector(
  authenticatedUser,
  detailedWorkOrder,
  (user, workOrder): HammerRating | undefined =>
    isInstallerLead(user)
      ? user.hammerRatings?.find(wherePropMatches('productInstallationTypeId', workOrder?.productInstallationTypeId))
      : undefined,
);
const maxHammerRating = (
  productInstallationTypeId: number,
  installerTeamLeadHammerRating: number,
  teamsMaximumHammerRating: any[],
) => {
  return Math.max(
    installerTeamLeadHammerRating,
    teamsMaximumHammerRating.find(teamMember => teamMember.productInstallationTypeId === productInstallationTypeId) ??
      0,
  );
};
export const userWorkOrderQualifications = createSelector(
  authenticatedUser,
  detailedWorkOrder,
  userMatchedProductInstallationType,
  userMatchedHammerRating,
  (
    user?: User,
    workOrder?: DetailedWorkOrder,
    matchedInstallType?: ProductInstallationTypeClaimed,
    matchedHammerRating?: HammerRating,
  ): UserWorkOrderQualifications => ({
    approvedQualification: isInstallerLead(user) ? user.isApproved ?? false : false,
    trainingQualification: !!matchedInstallType,
    hammerRatingQualification: matchedHammerRating
      ? // TODO: Logic for determining if a team member's Hammer Rating will meet the minimum requirement.
        // ? (maxHammerRating(matchedInstallType.productInstallationTypeId, matchedHammerRating?.value ?? 0, installerTeamLead.teamsMaxHammerRating )) >= workOrder?.minimumHammerRatingThreshold
        (matchedHammerRating?.value ?? 0) >= (workOrder?.minimumHammerRatingThreshold ?? 0)
      : workOrder?.minimumHammerRatingThreshold === 0,
  }),
);
