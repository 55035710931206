<form [formGroup]="form" novalidate>
  <ng-container formArrayName="items" *ngFor="let itemForm of itemForms">
    <ion-card
      *ngIf="itemForm.updatedModel as item"
      [disabled]="!(item | hasRemaining)"
      mode="ios"
      class="ion-no-margin gc-mb-3"
    >
      <ion-card-content>
        <ion-button
          fill="clear"
          color="dark"
          class="ion-no-padding ion-no-margin gc-mb-1"
          style="height: auto"
          (click)="itemForm.toggleFilled()"
        >
          <ion-icon slot="icon-only" [name]="itemForm.filled ? 'checkbox-outline' : 'square-outline'"></ion-icon>
        </ion-button>
        <ion-text color="dark">
          <h1>
            <strong
            >{{ item.name }}
              <span *ngIf="itemForm.hasValue">{{ itemForm.additionalInvoicedTotalAmount | currency }}</span></strong
            >
          </h1>
        </ion-text>
        <ion-text>
          <h2>
            <strong>{{ item.numberOfUnits }}</strong> {{ item.unitOfMeasureSymbol }} @
            <strong>{{ item.pricePerUnit | currency }}</strong> /
            <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
          </h2>
        </ion-text>
        <ion-text color="gc-green">
          <strong
          >{{
              (item | numberOfUnitsStillPending) -
              (item.applicableChangeOrders | totalNormalizedUnits: item.pricePerUnit)
            }}
            {{ item.applicableChangeOrders?.length ? 'REMAINING AFTER DEDUCTS' : 'REMAINING' }}
          </strong>
        </ion-text>

        <gc-invoice-item-applicable-change-orders
          [changeOrders]="item.applicableChangeOrders"
          [item]="item"
        ></gc-invoice-item-applicable-change-orders>

        <ion-range
          *ngIf="item.unitOfMeasureSymbol === 'HR'"
          [formControl]="itemForm.amountToInvoice"
          min="0"
          [max]="item | numberOfUnitsStillPending"
          step="0.25"
          snaps="true"
          ticks="true"
          pin="true"
          color="medium"
        >
          <ion-label slot="start">0</ion-label>
          <ion-label slot="end">{{ item | numberOfUnitsStillPending }}</ion-label>
        </ion-range>

        <ion-input
          [max]="item | numberOfUnitsStillPending"
          type="number"
          fill="solid"
          mode="md"
          class="ion-margin-top"
          [formControl]="itemForm.amountToInvoice"
          [label]="!!item.applicableChangeOrders?.length ? 'Additional to Invoice' : 'Amount to Invoice'"
          labelPlacement="floating"
        ></ion-input>

        <div *ngIf="itemForm | hasItemFormError: 'minAmount'" class="alert alert__warning">
          Please invoice for more than the Change Order total
        </div>
        <div *ngIf="itemForm | hasItemFormError: 'maxAmount'" class="alert alert__warning">
          You must report progress within the number of units remaining.
        </div>
        <div *ngIf="itemForm | hasItemFormError: 'max'" class="alert alert__warning">
          You must report progress within the number of units remaining.
        </div>
        <div *ngIf="itemForm | hasItemFormError: 'incrementAmount'" class="alert alert__warning">
          You must report progress in {{ itemForm.amountToInvoice.errors!.incrementAmount }}
          {{ itemForm.updatedModel.unitOfMeasureSymbol }} increments.
        </div>

        <!--        TODO: This design needs to be cleaned up -->
        <ion-grid>
          <ion-row *ngIf="item.applicableChangeOrders?.length && itemForm.amountToInvoice.value > 0">
            <ion-col>
              <strong>
                Total Invoiced Units:
                <ion-text *ngIf="!itemForm.amountToInvoice.value" color="gc-blue"
                >{{ item.applicableChangeOrders | totalNormalizedUnits: item.pricePerUnit }}
                </ion-text>
                <ion-text *ngIf="!!itemForm.amountToInvoice.value" color="gc-green"
                >{{
                    +itemForm.amountToInvoice.value +
                    (item.applicableChangeOrders | totalNormalizedUnits: item.pricePerUnit)
                  }}
                </ion-text>
              </strong>
            </ion-col>
            <ion-col class="ion-text-right">
              <strong>
                Invoice Total:
                <ion-text *ngIf="!itemForm.amountToInvoice.value" color="gc-blue"
                >{{ itemForm.totaledAmountInclusiveOfApplicableChangeOrders | currency }}
                </ion-text>
                <ion-text *ngIf="!!itemForm.amountToInvoice.value" color="gc-green"
                >{{ itemForm.totaledAmountInclusiveOfApplicableChangeOrders | currency }}
                </ion-text>
              </strong>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="item.applicableChangeOrders?.length && itemForm.amountToInvoice.value > 0">
            <ion-col class="ion-text-right">
              <strong
              >Total after Deductions:
                <ion-text color="gc-blue"
                >{{
                    itemForm.additionalInvoicedTotalAmount +
                    (item.applicableChangeOrders | totalNormalizedPrice: item.pricePerUnit) -
                    (item.applicableChangeOrders | totalDeductions) | currency
                  }}
                </ion-text>
              </strong>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>
  </ng-container>
</form>
