<ion-button
  mode="ios"
  color="gc-green"
  expand="block"
  size="large"
  (click)="submitChangeOrder.emit()"
  [disabled]="disabled"
>
  <strong>
    <ng-content></ng-content>
  </strong>
</ion-button>
