import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { environment } from '../../../../environments/environment';

am5.addLicense(environment.apiKeys.amCharts);

export const createAm5Root = (name: string) => {
  let root = am5.Root.new(name);
  root.setThemes([am5themes_Animated.new(root)]);
  return root;
};
