import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { PushNotificationSchema, Token } from '@capacitor/push-notifications/dist/esm/definitions';
import { ReplaySubject } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  deviceToken$ = new ReplaySubject<string>(1);
  rawToken$ = new ReplaySubject<string>(1);
  notificationReceived$: ReplaySubject<PushNotificationSchema> = new ReplaySubject<PushNotificationSchema>();
  notificationAction$: ReplaySubject<ActionPerformed> = new ReplaySubject<ActionPerformed>();

  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
  ) {
    if (Capacitor.isNativePlatform()) {
      PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) =>
        this.notificationReceived$.next(notification),
      );

      PushNotifications.addListener('pushNotificationActionPerformed', notification =>
        this.notificationAction$.next(notification),
      );

      PushNotifications.addListener('registration', (standardToken: Token) => {
        // Get FCM token instead to prevent using apns token.
        this.rawToken$.next(standardToken.value);
        // tslint:disable-next-line:no-console
        console.debug(`PushNotifications 'registration' listener => ${standardToken.value}`);
        // tslint:disable-next-line:no-console
        FCM.getToken().then(({ token }) => (console.debug(`FCM.getToken => ${token}`), this.deviceToken$.next(token)));
      });

      PushNotifications.addListener('registrationError', (error: any) => {
        console.log('[Firebase] Push Notification Registration Token Failed', error);
        this.deviceToken$.error(error);
      });
    }
  }

  requestPermission() {
    return PushNotifications.requestPermissions();
  }

  async initialize() {
    await PushNotifications.register();
    const { enabled } = await FCM.isAutoInitEnabled();
    return enabled || FCM.setAutoInit({ enabled: true });
  }

  subscribeToTopic(topic: string) {
    return FCM.subscribeTo({ topic });
  }

  async saveToken(userId: string, token: string) {
    console.log('[Firebase] before the POST', token, this.config.host);
    await this.http.post(`${this.config.host}/users/${userId}/devices`, { deviceId: token });
  }
}
