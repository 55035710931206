import { Pipe, PipeTransform } from '@angular/core';
import { WorkOrderStatus } from '~gc/domains/work/work-order/work-order.model';

export type EditMode = 'full' | 'partial' | 'republish';

export const getEditMode = (status: WorkOrderStatus): EditMode =>
  status === WorkOrderStatus.Draft
    ? 'full'
    : status === WorkOrderStatus.PublishedAwaitingResponse || status === WorkOrderStatus.PublishedWithResponses
    ? 'republish'
    : 'partial';

@Pipe({ name: 'editMode' })
export class EditModePipe implements PipeTransform {
  transform(status: WorkOrderStatus, ...modes: EditMode[]): boolean;
  transform(status: WorkOrderStatus): EditMode;
  transform(status: WorkOrderStatus, ...modes: EditMode[]): boolean | EditMode {
    return modes ? modes.includes(getEditMode(status)) : getEditMode(status);
  }
}
