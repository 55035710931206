import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';


@Component({
  selector: 'gc-item-change-orders-expander',
  templateUrl: './item-change-orders-expander.component.html',
  styleUrls: ['./item-change-orders-expander.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemChangeOrdersExpanderComponent {
  @Input() item!: DetailedWorkOrderItem;
  @Input() expanded!: DetailedWorkOrderItem;
  @Output() expand = new EventEmitter<DetailedWorkOrderItem>();
}
