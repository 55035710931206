import { Component, Input } from '@angular/core';
import { ChangeOrder } from '../../../domains/change-orders/change-order/change-order.model';
import { InvoiceableWorkOrderItem } from '../../../domains/models/invoiceable-work-order-item';

@Component({
  selector: 'gc-invoice-item-applicable-change-orders',
  templateUrl: './invoice-item-applicable-change-orders.component.html',
  styleUrls: ['./invoice-item-applicable-change-orders.component.scss'],
})
export class InvoiceItemApplicableChangeOrdersComponent {
  @Input() changeOrders!: ChangeOrder[];
  @Input() item!: InvoiceableWorkOrderItem

  trackChangeOrderBy(index: number, item: ChangeOrder) {
    return item.id;
  }
}
