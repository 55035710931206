import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InvoiceItem } from '~gc/domains/invoices/invoice-item/invoice-item.model';
import { AppState } from '~gc/domains/state';
import { InvoiceItemsFacadeBase } from './invoice-item.state';

@Injectable()
export class InvoiceItemsFacade extends InvoiceItemsFacadeBase {
  constructor(private store: Store<AppState>) {
    super(InvoiceItem, store);
  }
}
