import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { PlaidAccountDetails } from '~gc/shared/dwolla/feature/plaid/models/plaid-account-details.model';
import {
  deletePlaidAccountDetailsSuccess,
  loadPlaidAccountDetails,
  loadPlaidAccountDetailsFailure,
  loadPlaidAccountDetailsSuccess,
  plaidMicroDepositVerificationFailure,
} from './plaid.actions';

export interface PlaidAccountState {
  accountDetails?: PlaidAccountDetails;
  loading: boolean;
  initialLoadComplete: boolean;
}

export const initialPlaidAccountState: PlaidAccountState = {
  loading: false,
  initialLoadComplete: false,
};

const reduce = createReducer(
  initialPlaidAccountState,
  on(loadPlaidAccountDetails, plaidMicroDepositVerificationFailure, state => ({ ...state, loading: true })),
  on(loadPlaidAccountDetailsSuccess, loadPlaidAccountDetailsFailure, deletePlaidAccountDetailsSuccess, state => ({
    ...state,
    loading: false,
  })),
  on(loadPlaidAccountDetailsFailure, loadPlaidAccountDetailsSuccess, state => ({
    ...state,
    initialLoadComplete: true,
  })),
  on(loadPlaidAccountDetailsSuccess, (state, { accountDetails }) => ({ ...state, accountDetails })),
  on(deletePlaidAccountDetailsSuccess, ({ accountDetails, ...state }) => ({ ...state })),
);

export function plaidAccountReducer(state: PlaidAccountState = initialPlaidAccountState, action: Action) {
  return reduce(state, action);
}

export const PLAID_ACCOUNT_FEATURE_KEY = 'plaidAccount';

export const plaidAccountState = createFeatureSelector<PlaidAccountState>(PLAID_ACCOUNT_FEATURE_KEY);
