<ng-container *ngFor="let item of items | sortBy: 'name'">
  <ion-list>
    <ion-item>
      <ion-label>
        <h2>{{ item.name }}</h2>
        <p>
          <span [class.negotiated]="showNegotiations && (item | hasNegotiations)">
            {{ item.numberOfUnits }} @ {{ item.pricePerUnit | currency }}
          </span>
          /<span class="uom">{{ item.unitOfMeasureSymbol }}</span>
        </p>
        <ion-chip
          color="danger"
          *ngIf="showNegotiations && (item | hasNegotiations) && item !== expandedNegotiationItem"
          (click)="expandedNegotiationItem = item"
        >
          <ion-label>Negotiations</ion-label>
          <ion-icon name="caret-down"></ion-icon>
        </ion-chip>
      </ion-label>
      <ion-note class="ion-no-margin" slot="end" [color]="item | negotiablePriceColor: showNegotiations">
        <strong style="font-size: 1rem">{{ item.pricePerUnit * item.numberOfUnits | currency }}</strong>
      </ion-note>
    </ion-item>
  </ion-list>

  <div class="red-caret" *ngIf="showNegotiations && (item | hasNegotiations) && item === expandedNegotiationItem">
    <ion-card color="danger">
      <ion-card-header class="ion-no-padding">
        <ion-toolbar color="danger">
          <ion-buttons slot="end">
            <ion-button (click)="expandedNegotiationItem = undefined">
              <ion-icon slot="icon-only" ios="close-circle" md="close-circle"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title style="padding-left: 15px; text-align: left; font-size: 1.5em; font-weight: bold"
            >Negotiations
          </ion-title>
        </ion-toolbar>
      </ion-card-header>

      <ion-list
        *ngIf="showNegotiations && (item | hasNegotiations) && item === expandedNegotiationItem"
        class="negotiation-list"
      >
        <ion-item *ngFor="let negotiation of item.negotiations; last as last" [lines]="last ? 'none' : undefined">
          <ion-label>
            <h6>{{ negotiation.installer!.firstName }} {{ negotiation.installer!.lastName }}</h6>
            <p class="current-bid">
              <span *ngIf="negotiation.currentAskPrice"
                >Bid:
                {{ negotiation.currentBidPrice | currency }}
                / {{ item.unitOfMeasureSymbol }}</span
              >
            </p>
            <p class="current-ask">
              <span *ngIf="negotiation.currentAskPrice"
                >Current Ask:
                {{ negotiation.currentAskPrice | currency }}
                / {{ item.unitOfMeasureSymbol }}</span
              >
            </p>
          </ion-label>
          <ion-note class="ion-no-margin ion-text-right" slot="end">
            <span *ngIf="showTotals" style="font-size: 1rem">
              <strong [class.strike]="negotiation.isAcceptedByInstaller"
                ><ion-icon name="close" *ngIf="negotiation.isAcceptedByInstaller"></ion-icon
                ><ion-icon name="checkmark" color="gc-alt-green" *ngIf="!negotiation.isAcceptedByInstaller"></ion-icon>
                {{ negotiation.currentBidPrice * item.numberOfUnits | currency
                }}<span *ngIf="negotiation.currentAskPrice"> / bid</span></strong
              >
              <br *ngIf="negotiation.currentAskPrice" />
              <strong [class.strike]="!negotiation.isAcceptedByInstaller"
                ><ion-icon name="close" *ngIf="!negotiation.isAcceptedByInstaller"></ion-icon
                ><ion-icon name="checkmark" color="gc-alt-green" *ngIf="negotiation.isAcceptedByInstaller"></ion-icon>
                {{ negotiation.currentAskPrice * item.numberOfUnits | currency }} / ask</strong
              >
            </span>
            <ion-button
              fill="outline"
              color="danger"
              (click)="reviewed.emit(negotiation.installer)"
              *ngIf="!showTotals"
            >
              Review
            </ion-button>
          </ion-note>
        </ion-item>
      </ion-list>
    </ion-card>
  </div>
</ng-container>
