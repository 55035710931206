<ion-grid *ngIf="workOrder | exists">
  <ion-row>
    <ion-col
      id="kudos-earned-banner"
      *ngIf="workOrder.kudos?.length && (currentRole | isRole: 'installerLead')"
      class="ion-padding kudos-banner"
    >
      <ion-icon name="thumbs-up-outline" size="large" color="gc-green"></ion-icon>
      <span>You got kudos. Nice work!</span>
    </ion-col>
  </ion-row>

  <ion-row style="border-bottom: 1px solid #e0e0e0">
    <ion-col>
      <ion-grid class="header-sections">
        <ion-row>
          <ion-col
            size="12"
            push-lg="7"
            size-lg="5"
            *ngIf="scheduleOpen"
            [ngClass]="{ 'schedule-border': scheduleOpen }"
            class="inner-column"
          >
            <gc-schedule
              *ngIf="scheduleOpen && allowedPrivateDetails; else installerDetailView"
              [enableCommands]="
                workOrder
                  | isStatus
                    : 'DRAFT'
                    : 'PUBLISHED_WITH_RESPONSES'
                    : 'PUBLISHED_AWAITING_RESPONSE'
                    : 'AWARDED_NOT_STARTED'
                    : 'IN_PROGRESS_NO_INVOICES'
                    : 'IN_PROGRESS_HAS_INVOICES'
              "
              [formArray]="datesForm"
              [showHeader]="true"
              [compactMode]="isChatOpen"
              (added)="addDate($event)"
              (saved)="saveDate($event)"
              (deleted)="deleteDate($event)"
            ></gc-schedule>
            <ng-template #installerDetailView>
              <h3>Review the schedule by tapping View Details.</h3>
            </ng-template>
          </ion-col>
          <ion-col size="12" [attr.pull-lg]="scheduleOpen ? 5 : 0" size-lg="7" class="inner-column">
            <ion-item
              *ngIf="workOrder | isStatus: 'DONE_AWAITING_SETTLEMENT'"
              lines="none"
              class="ion-no-padding ion-margin-bottom"
              color="clear"
            >
              <ion-icon color="gc-yellow" size="small" name="rocket"></ion-icon>
              <ion-text style="font-weight: bold" color="gc-yellow">Payment Processing</ion-text>
            </ion-item>

            <ion-badge
              [color]="workOrder.status | statusToStatusGroup | statusGroupColor"
              mode="ios"
              class="status-badge"
            >
              {{ workOrder.status | statusToStatusGroup | statusGroupLabel }}
            </ion-badge>

            <ion-item lines="none" class="ion-no-padding" color="clear">
              <ion-label class="ion-no-margin work-order-name ion-align-items-center ion-text-wrap">
                <h2>
                  <strong>{{
                    (currentRole | isRole: 'companyAdmin' : 'companyManager') ||
                    ((currentRole | isRole: 'installerLead') && (workOrder | isAwardedToInstaller: currentUser))
                      ? workOrder.privateJobName || workOrder.publicJobName || workOrder.jobName
                      : workOrder.publicJobName || workOrder.jobName || '[Job Name Not Specified]'
                  }}</strong>
                </h2>
              </ion-label>
            </ion-item>

            <ion-item *ngIf="allowedPrivateDetails" lines="none" class="ion-no-padding badge-container" color="clear">
              <h3 *ngIf="workOrder.facilityType" class="facility-type">
                {{ workOrder.facilityType.description }}
              </h3>
              <span *ngIf="workOrder.productInstallationType" class="gc-badge">
                {{ workOrder.productInstallationType.type }}
              </span>
            </ion-item>

            <ion-item *ngIf="allowedPrivateDetails" lines="none" class="ion-no-padding" color="clear">
              <ion-text *ngIf="allowedPrivateDetails && workOrder?.projectManager">
                <small>
                  Issued by:
                  {{ workOrder?.projectManager.firstName }} {{ workOrder?.projectManager.lastName }}
                </small>
              </ion-text>
            </ion-item>

            <ng-container *ngIf="currentRole | isRole: 'companyAdmin' : 'companyManager'; else installerView">
              <gc-work-order-installer-chip
                [workOrder]="workOrder"
                [matchedInstallerCount]="matchedInstallerCount"
                (reviewInstallers)="reviewInstallers.emit(workOrder)"
                (viewInstaller)="viewInstaller.emit($event)"
              ></gc-work-order-installer-chip>
            </ng-container>

            <ng-template #installerView>
              <ion-chip *ngIf="allowedPrivateDetails" class="company-avatar-chip">
                <ion-avatar>
                  <img [src]="company | logo" [alt]="workOrder.companyName" />
                </ion-avatar>
                <ion-label>{{ workOrder.companyName }}</ion-label>
              </ion-chip>
            </ng-template>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>
