import { HttpClient } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { defer, iif, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';
import { ZipValidResponse } from './async-zipcode.validator';
import { addToErrors, fieldValueMatchesAcceptable, removeFromErrors } from './util';

export const matchesZip =
  (http: HttpClient, acceptableProp: 'city' | 'state' = 'city') =>
  (zipField = 'postalCode'): AsyncValidatorFn =>
  (target: AbstractControl): Observable<ValidationErrors | null> =>
    of(target).pipe(
      map(control => control.value),
      map(value => ({
        value,
        host: ConfigService.getHost(),
      })),
      switchMap(({ value, host }) =>
        iif(
          () => !!value?.length && target.parent?.get(zipField)?.value?.length === 5,
          defer(() =>
            http.get<ZipValidResponse>(`${host}/zip-code/${target.parent?.get(zipField)?.value}`).pipe(
              map(response => [{ city: response.city, state: response.state }, ...response.acceptableCityNames]),
              map(acceptable =>
                fieldValueMatchesAcceptable(
                  target,
                  acceptable.map(_ => _[acceptableProp]),
                ),
              ),
              tap(isValid =>
                isValid
                  ? removeFromErrors(target.parent?.get(zipField), `zipMatches${acceptableProp}`)
                  : addToErrors(target.parent?.get(zipField), `zipMatches${acceptableProp}`),
              ),
              map(isValid => (isValid ? null : { [`${acceptableProp}MatchesZip`]: true })),
              catchError(() => of(null)),
            ),
          ),
          of(null),
        ),
      ),
      first(),
    );
