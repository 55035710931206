import { createSelector } from '@ngrx/store';
import { PhoneNumber } from '../../domains/phone-number';
import { allPhoneNumbers } from '../../domains/phone-number/phone-number.state';
import { whereMatchesUser } from '../../domains/utils';
import { PhoneNumberEntry } from '../../shared/components/advanced-phone-number-manager/models/phone-number-entry.model';
import { verifyingPhoneNumberDetails } from '../profile/store/phone-number-management.selectors';
import { VerifyingPhoneNumberDetails } from '../profile/store/phone-number-management.state';
import { makePhoneNumberEntry } from '../profile/utils';
import { currentInstaller } from './store/profile.selectors';


export const currentInstallerPhoneNumbers = createSelector(
  allPhoneNumbers,
  currentInstaller,
  (phoneNumbers, installer): PhoneNumber[] | undefined =>
    installer ? phoneNumbers.filter(whereMatchesUser(installer)) : undefined,
);

export const currentInstallerPhoneNumberEntries = createSelector(
  verifyingPhoneNumberDetails,
  currentInstallerPhoneNumbers,
  (details: VerifyingPhoneNumberDetails, phoneNumbers?: PhoneNumber[]): PhoneNumberEntry[] | undefined =>
    phoneNumbers ? phoneNumbers.map(makePhoneNumberEntry(details)) : undefined,
);
