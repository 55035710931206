<ion-header>
  <ion-toolbar>
    <ion-title>Report Progress</ion-title>
    <ion-buttons slot="start">
      <ion-button slot="start" fill="clear" color="dark" (click)="modalGC.dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button slot="end" fill="clear" color="dark" (click)="form.toggleFilled()">
        Report 100%
        <ion-icon slot="start" [name]="form.filled ? 'checkbox-outline' : 'square-outline'"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="ion-padding">
    <gc-invoice-header
      [workOrder]="workOrders.currentDetailedWorkOrder$ | push"
      [company]="workOrderRelations.currentWorkOrderCompany$ | push"
      [date]="todaysDate"
      invoiceNumberLabel="Progress Report: "
    >
    </gc-invoice-header>
    <gc-report-progress-form
      [workOrder]="workOrders.current$ | push"
      [items]="workOrderRelations.currentInvoiceableWorkOrderItems$ | push"
      [form]="form"
    ></gc-report-progress-form>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col>
        <ng-container *ngIf="workOrders.current$ | push as workOrder">
          <ion-button expand="block" color="dark" mode="ios" [disabled]="form.invalid" (click)="save(workOrder)"
            >Review Progress Report
          </ion-button>
        </ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
