import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'ProductInstallationSubtypesClaimed',
  uriName: 'product-installation-subtypes-claimed',
})
export class ProductInstallationSubtypesClaimed {
  @Key id?: number;
  productInstallationSubtypeId!: number;
  productInstallationTypeId!: number;
  userId!: string;
}
