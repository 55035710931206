import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { Address } from '../../locations/address/address.model';
import { KeyValueMap } from '../../models/key-value-map';
import { Kudos } from '../kudos/kudos.model';
import { WorkOrderTag } from '../work-order-tag/work-order-tag.model';
// import {isoDateStringToDate} from '../../transforms';

// -----===[ ENTITY ]===-----
// An entity represents data that comes from
// a remote API of one kind or another. Entities,
// specifically those used by NgRx Auto-Entity,
// have specific semantics such as keys, common,
// plural, and uri names, possibly default sort
// criteria, and perhaps data transformations to
// re-shape data as it goes to and from the API.

@Entity({
  modelName: 'WorkOrder',
  uriName: 'work-orders',
  comparer: (a, b) => a.scheduledStartDate.localeCompare(b.scheduledStartDate),
  // transform: [
  //   isoDateStringToDate('scheduledStartDate'),
  //   isoDateStringToDate('scheduledEndDate'),
  //   isoDateStringToDate('expirationDateWhenNotAccepted')
  // ]
})
export class WorkOrder {
  @Key id?: string;
  publicJobName?: string;
  publicJobSummary?: string;
  privateJobName?: string;
  installationDetails?: string;
  scheduledStartDate!: string;
  scheduledEndDate!: string;
  minimumHammerRatingThreshold!: number;
  awardedToInstallerId!: string;
  metadata?: KeyValueMap;
  productInstallationTypeId!: number;
  facilityTypeId!: number;
  termsAndConditionsAcceptedId!: number;
  companyId?: number;
  companyName?: string;
  chatChannelId?: string;
  projectManagerId?: string;
  lienReleaseTemplateId!: number;
  radius!: number;
  republishedId?: string;
  duplicatedId?: string;
  hasUnapprovedChangeOrders!: boolean;
  companyHasNegotiationForReview!: boolean;
  installerHasNegotiationForReview!: boolean;
  address?: Address;
  status!: WorkOrderStatus;
  publishMethod?: WorkOrderPublishMethod;
  kudos?: Kudos[];
  tags?: WorkOrderTag[];
  tagValues?: string[];
  sharedWith?: string[];
  createdOn!: string;
  updatedOn!: string;

  // These Values exist when loading from proximity endpoint.
  amount?: number;
  jobName?: string;
  distanceInMeters?: number;
  // coords are [lng, lat]
  location?: { type: 'Point'; coordinates: [number, number] };
  isMatchedInstaller?: boolean;
  isAwardedToMe?: boolean;
  matchedInstallerCount?: number;
}

export type DraftWorkOrder = Partial<WorkOrder>;

export enum WorkOrderPublishMethod {
  Public = 'PUBLIC',
  Connections = 'MY_NETWORK',
  ConnectionsWithMap = 'MY_NETWORK_WITH_MAP',
  Internal = 'INTERNAL',
}

export enum WorkOrderStatus {
  Draft = 'DRAFT',
  PublishedAwaitingResponse = 'PUBLISHED_AWAITING_RESPONSE',
  PublishedWithResponses = 'PUBLISHED_WITH_RESPONSES',
  AwardedNotStarted = 'AWARDED_NOT_STARTED',
  InProgressNoInvoices = 'IN_PROGRESS_NO_INVOICES',
  InProgressHasInvoices = 'IN_PROGRESS_HAS_INVOICES',
  DoneAwaitingSettlement = 'DONE_AWAITING_SETTLEMENT',
  Done = 'DONE',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
}

export const workOrderIsStatus = (workOrder?: { status?: WorkOrderStatus }, ...statuses: WorkOrderStatus[]) =>
  workOrder ? statuses.some(status => workOrder.status === status) : false;

export const workOrderIsPending = (workOrder?: { status?: WorkOrderStatus }) =>
  workOrderIsStatus(workOrder, WorkOrderStatus.PublishedAwaitingResponse, WorkOrderStatus.PublishedWithResponses);

export const workOrderIsInProgress = (workOrder?: { status?: WorkOrderStatus }) =>
  workOrderIsStatus(
    workOrder,
    WorkOrderStatus.InProgressNoInvoices,
    WorkOrderStatus.InProgressHasInvoices,
    WorkOrderStatus.AwardedNotStarted,
  );
