import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { excludeRoles } from '../../../shared/utils/roles.operators';
import { ensureRequired } from '../../../shared/utils/rxjs';
import { authenticatedRole } from '../../app/auth/auth.selectors';
import { AppState } from '../../state';
import { loadAllWorkOrderItems } from '../work-order-item/work-order-item.state';
import { viewWorkOrder } from '../work-order-ui.actions';
import { awardToInstallerCompleted } from '../work-order/work-order.actions';
import { WorkOrder } from '../work-order/work-order.model';
import { currentWorkOrder, workOrderDeselected } from '../work-order/work-order.state';
import { workOrderRefreshIntervalTicked } from '../work-polling.actions';
import { clearWorkOrderFiles, loadAllWorkOrderFiles } from './work-order-files.state';


@Injectable()
export class WorkOrderFileEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  loadWorkOrderFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrder),
      excludeRoles(this.store.select(authenticatedRole), 'installerMember', 'jumpstarter'),
      map(({ workOrder }) =>
        loadAllWorkOrderFiles({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            },
          },
        }),
      ),
    ),
  );

  unloadWorkOrderFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderDeselected),
      map(() => clearWorkOrderFiles()),
    ),
  );

  reloadWorkOrderFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderRefreshIntervalTicked),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureRequired(workOrder => !!workOrder?.id),
      map(workOrder =>
        loadAllWorkOrderFiles({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            },
          },
        }),
      ),
    ),
  );
}
