import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Negotiation } from './negotiation.model';
import { NegotiationFacadeBase } from './negotiation.state';

@Injectable()
export class NegotiationFacade extends NegotiationFacadeBase {
  constructor(private store: Store<boolean>) {
    super(Negotiation, store);
  }
}
