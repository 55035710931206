import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { PipesModule } from '../../pipes/pipes.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { WorkOrderItemTotalComponent } from './work-order-item-total.component';

@NgModule({
  declarations: [WorkOrderItemTotalComponent],
  imports: [CommonModule, IonicModule, PipesModule, TooltipModule, PushModule],
  exports: [WorkOrderItemTotalComponent],
})
export class WorkOrderItemTotalModule {}
