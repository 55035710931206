import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Observable } from 'rxjs';
import { exhaustMap, map, takeUntil } from 'rxjs/operators';
import { routeStartsWithPath } from '../../../shared/utils/ngrx';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { loadWorkOrderSuccess } from '../../work/work-order/work-order.state';
import { showAddToConnections } from '../team/team.actions';
import { loadAllCompanies, loadCompany } from './company.state';

@Injectable()
export class CompanyEffects implements OnRunEffects {
  constructor(private actions$: Actions) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  listenForRouteVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeStartsWithPath('/app/dashboard', '/app/team'),
      map(() => loadAllCompanies()),
    ),
  );

  loadCompanyForWorkOrder$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(loadWorkOrderSuccess),
      map(({ entity }) => loadCompany({ keys: entity.companyId })),
    ),
  );

  loadAllCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showAddToConnections),
      map(() => loadAllCompanies()),
    ),
  );
}
