import { IEntityInfo } from '@briebug/ngrx-auto-entity';
import { MonoTypeOperatorFunction, Observable, retry, throwError, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ConfigService } from '../shared/services/config.service';

export interface EntityCriteria {
  parents?: EntityParent;
  query?: QueryCriteria;
  param?: string | number | string[] | number[];
  params?: {
    [key: string]: string | number;
  };
  // @Default false
  // Set to true for default values or provide options.
  // ###Only applies to load methods.
  retry?: boolean | RetryCriteria;
  // @Default false
  // Set to true to add an idempotency key. By default, sets retry option to true.
  // Use the retry option to configure request behavior. Key will be identical across retried requests.
  // ###Only applies to POST requests for creation currently.
  idempotent?: boolean;
}

export const IDEMPOTENCY_KEY_HEADER = 'Idempotency-Key';

export interface EntityParent {
  [key: string]: string | number | null | undefined;
}

export interface RetryCriteria {
  // Defaults to 1000 (ms)
  delay?: number;
  // Defaults to 3 max retries
  maxRetries?: number;
}

export interface QueryCriteria {
  [key: string]: string | number | string[] | number[] | null | undefined;
}

export type EntityKey = string | number;

export const EmptyKey = null;

export const buildParentPaths = (criteria?: EntityCriteria): string =>
  Object.keys((criteria && criteria.parents) || {})
    .map(parent => `/${parent}${criteria?.parents?.[parent] == EmptyKey ? '' : `/${criteria.parents[parent]}`}`)
    .reduce((path, parent) => path + parent, '');

export const buildEntityPath = (info: IEntityInfo, key?: any, criteria?: EntityCriteria): string =>
  `/${info.uriName || info.pluralName || info.modelName.toLowerCase()}${
    key
      ? `/${key}`
      : criteria?.param && !Array.isArray(criteria.param)
      ? `/${criteria.param}`
      : criteria?.param && Array.isArray(criteria.param)
      ? `${(criteria.param as any[]).reduce((path, param) => `${path}/${param}`, '')}`
      : criteria?.params
      ? Object.keys(criteria.params).reduce((path, param) => `${path}/${criteria.params![param]}`, '')
      : ''
  }`;

export const buildSimpleQueryParam = (criteria: EntityCriteria, param: string) =>
  `${param}${!!criteria.query?.[param] ? `=${criteria.query[param]}` : ''}`;

export const renderJoinedArrayQueryParams = (values: any[], param: string) =>
  values.map(value => `${param}=${value}`).join('&');

export const buildJoinedArrayQueryParamSet = (criteria: EntityCriteria, param: string) =>
  Array.isArray(criteria.query?.[param])
    ? renderJoinedArrayQueryParams(criteria.query![param] as any[], param.substring(1))
    : typeof criteria.query?.[param] === 'string'
    ? renderJoinedArrayQueryParams((criteria.query[param] as string).split(','), param.substring(1))
    : buildSimpleQueryParam(criteria, param.substring(1));

export const buildQueryString = (criteria?: EntityCriteria): string =>
  criteria && criteria.query
    ? (Object.keys(criteria.query) as unknown as string[])
        .map((param: string) =>
          param.startsWith('&')
            ? buildJoinedArrayQueryParamSet(criteria, param)
            : buildSimpleQueryParam(criteria, param),
        )
        .join('&')
    : '';

export const buildUrl = (info: IEntityInfo, criteria?: EntityCriteria, key: any = null): string => {
  const parentPaths = buildParentPaths(criteria);
  const entityPath = buildEntityPath(info, key, criteria);
  const query = buildQueryString(criteria);

  return `${ConfigService.getHost()}${parentPaths}${entityPath}${query ? `?${query}` : ''}`;
};

export const delayedRetry = <T>(delay = 1000, maxRetries = 3): MonoTypeOperatorFunction<T> =>
  retry({
    delay: errors =>
      errors.pipe(
        mergeMap(error =>
          maxRetries-- ? timer(delay) : throwError(() => ({ message: `Failed after max retries.`, lastError: error })),
        ),
      ),
  });

export const resolveRetryCriteria = <T>(obs: Observable<T>, retryCriteria?: boolean | RetryCriteria) =>
  !!retryCriteria
    ? typeof retryCriteria === 'boolean'
      ? obs.pipe(delayedRetry())
      : obs.pipe(delayedRetry(retryCriteria.delay, retryCriteria.maxRetries))
    : obs;
