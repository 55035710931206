import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';


@Injectable()
export class PhoneNumberService {
  constructor(private readonly http: HttpClient) {
  }

  refreshCode(userId: string, number: string): Observable<any> {
    return this.http.get(`${ConfigService.getHost()}/users/${userId}/phone-numbers/${number}/code`);
  }
}
