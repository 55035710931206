// Selectors for each type of SOURCE

import { createSelector } from '@ngrx/store';
import { Connection, ConnectionStatus, Source } from '~gc/domains/users/connection/connection.model';
import { allConnections } from '~gc/domains/users/connection/connection.state';
import { hasPermissions } from '../../../shared/pipes/has-permissions.pipe';
import { joinArrays } from '../../../shared/utils/func';
import { tokenPermissions } from '../../app/auth/auth.selectors';

export const filterConnectionsBySource = (source: Source) => (connections: Connection[]) =>
  connections.filter(connection => connection.source === source);

export const installerSourceConnections = createSelector(
  // provide selector
  allConnections,
  filterConnectionsBySource(Source.Installer),
);

export const companySourceConnections = createSelector(allConnections, filterConnectionsBySource(Source.Company));

export const companyPrivateSourceConnections = createSelector(
  allConnections,
  filterConnectionsBySource(Source.CompanyPrivate),
);

export const pendingConnections = createSelector(allConnections, (connections: Connection[]) =>
  connections.filter(network => network.status === ConnectionStatus.Pending),
);

export const acceptedConnections = createSelector(allConnections, (connections: Connection[]) =>
  connections.filter(network => network.status === ConnectionStatus.Accepted),
);

export const approvedConnections = createSelector(allConnections, (connections: Connection[]) =>
  connections.filter(network => network.status === ConnectionStatus.Approved),
);

export const activeConnections = createSelector(
  pendingConnections,
  acceptedConnections,
  approvedConnections,
  joinArrays,
);

export const publicActiveConnections = createSelector(
  activeConnections,
  filterConnectionsBySource(Source.Company),
);

export const privateActiveConnections = createSelector(
  activeConnections,
  filterConnectionsBySource(Source.CompanyPrivate),
);

export const publicApprovedConnections = createSelector(
  approvedConnections,
  filterConnectionsBySource(Source.Company),
);

export const privateApprovedConnections = createSelector(
  approvedConnections,
  filterConnectionsBySource(Source.CompanyPrivate),
);

export const allowedApprovedConnections = createSelector(
  approvedConnections,
  tokenPermissions,
  (connections: Connection[], permissions: string[]) =>
    hasPermissions(permissions, 'work-order.public:publish', 'work-order.private:publish')
      ? connections
      : connections.filter(network =>
          (hasPermissions(permissions, 'work-order.private:publish')
            ? [Source.Company, Source.CompanyPrivate]
            : [Source.Company]
          ).includes(network.source),
        ),
);
