<ion-content scrollY="false">
  <ion-grid style="height: 100%; flex-direction: column" class="flex ion-justify-content-between ion-no-padding">
    <ion-row class="ion-justify-content-end">
      <ion-icon
        name="close-circle"
        (click)="modals.dismiss()"
        size="large"
        style="cursor: pointer"
        class="ion-text-right"
      ></ion-icon>
    </ion-row>
    <ion-row class="flex">
      <img
        id="hero"
        alt="Direct Invoices"
        src="../../../../assets/images/feature-announcement-heroes/ad_hoc_invoice_muted.jpg"
      />
      <p><strong>New Feature: Direct Invoices</strong></p>
      <ul>
        <li>Accept card payments from customers</li>
        <li>No setup fees, monthly fees, or hidden fees</li>
        <li>No Work Order required</li>
      </ul>
      <ion-col size="12" class="ion-text-center">
        <ion-button
          mode="ios"
          expand="block"
          color="gc-green"
          style="margin-bottom: auto"
          (click)="openBrowser(currentAnnouncementModalURL)"
        >
          Learn More
        </ion-button>
        <small style="align-self: center"
          >A flat, pay-as-you-go transaction fee does apply. Follow the link for details.</small
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
