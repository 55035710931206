import { createAction, props } from '@ngrx/store';
import { PhoneNumber } from '../../domains/phone-number';


export const phoneNumberAdding = createAction(
  '[MODAL: Basic Profile] (New Phone Number) Adding',
  props<{ phoneNumber: PhoneNumber }>(),
);

export const phoneNumberRemoving = createAction(
  '[MODAL: Basic Profile] (Phone Number) Removing',
  props<{ phoneNumber: PhoneNumber }>(),
);


export const phoneNumberVerifying = createAction(
  '[MODAL: Basic Profile] (Phone Number) Verifying',
  props<{ phoneNumber: PhoneNumber, code: string }>(),
);
