<form [formGroup]="form">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size-lg="4" class="ion-padding">
        <h2><b>Work Order Details</b></h2>
        <p>These details will only be shared with the installer to whom you award this Work Order.</p>
      </ion-col>

      <ion-col size="12" size-sm>
        <ion-list lines="full" class="ion-no-margin">
          <ion-list-header lines="full">
            <h2><b>Details</b></h2>
          </ion-list-header>
          <ion-item lines="full">
            <ion-input data-cy="private-job-name-input" formControlName="privateJobName" labelPlacement="floating" clearInput>
              <div slot="label">
                <ion-text [color]="form.validated && form.privateJobName.invalid ? 'danger' : null">
                  Private Job Name
                </ion-text>
              </div>
            </ion-input>
          </ion-item>
          <editor
            [apiKey]="tinyMceApiKey"
            formControlName="installationDetails"
            [init]="{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen spellchecker',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | bold italic bullist numlist backcolor fullscreen',
              contextmenu: 'cut copy',
              spellchecker_rpc_url: 'spellchecker.php',
              init_instance_callback: onReady
            }"
          ></editor>

          <gc-file-manager
            [fileForms]="form.files"
            [hideWarning]="true"
            (fileRetried)="fileRetried.emit($event)"
            (fileAdded)="fileAdded.emit($event)"
            (fileDeleted)="fileRemoved.emit($event)"
          ></gc-file-manager>

          <ion-list-header lines="full">
            <h2><b>Address</b></h2>
          </ion-list-header>

          <gc-address-form
            [addressForm]="form.address"
            [disabled]="form.workOrderStatus | editMode: 'partial'"
            [validated]="form.validated"
            [compact]="true"
            [states]="states"
          ></gc-address-form>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
