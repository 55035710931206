import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoginFacade } from '../../../domains/app/login.facade';
import { NewUserSignUpInfo } from '../../../domains/app/models/new-user-sign-up-info';


@Component({
  selector: 'gc-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent implements OnInit {
  form!: UntypedFormGroup;

  constructor(
    private login: LoginFacade,
    private fb: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      agreedToTermsAndPrivacy: [false, [Validators.requiredTrue]],
    });
  }

  signUp(form: any) {
    if (!form.valid) {
      return;
    }

    const formData = { ...form.value };

    console.log(formData);

    const sanitizedFormData: NewUserSignUpInfo = {
      firstName: this.sanitizeInput(formData.firstName),
      lastName: this.sanitizeInput(formData.lastName),
      email: formData.email.toLowerCase(),
      type: undefined,
    };

    console.log(sanitizedFormData);
    this.login.registerNewUser(sanitizedFormData);

  }

  sanitizeInput(input: string): string {
    return input
      .replace(/['"]/g, '')
      .trim()
      .replace(/\b\w/g, l => l.toUpperCase());
  }

}
