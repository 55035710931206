import { RoleKeys } from '../app/auth/auth.maps';
import { UserType } from './user/user.model';

export interface MightHaveUserId {
  userId?: string;
}

// Intersection between user types and auth roles.
export const USER_ROLES: {
  [p in UserType]: RoleKeys;
} = {
  INSTALLER_TEAM_MEMBER: 'installerMember',
  INSTALLER_TEAM_LEAD: 'installerLead',
  EMPLOYEE_INSTALLER: 'companyEmployee',
  COMPANY_PROJECT_MANAGER: 'companyManager',
  COMPANY_ADMIN: 'companyAdmin',
  JUMPSTARTER: 'jumpstarter',
};

export const USER_ROLES_TO_TYPES: {
  [p in RoleKeys]: UserType;
} = {
  installerMember: 'INSTALLER_TEAM_MEMBER',
  installerLead: 'INSTALLER_TEAM_LEAD',
  companyEmployee: 'EMPLOYEE_INSTALLER',
  companyManager: 'COMPANY_PROJECT_MANAGER',
  companyAdmin: 'COMPANY_ADMIN',
  jumpstarter: 'JUMPSTARTER',
};
