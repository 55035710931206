import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Camera, CameraResultType } from '@capacitor/camera';
import { IonInput, ModalController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { AuthFacade } from '../../../../domains/app/auth/auth.facade';
import { BrowserService } from '../../../../domains/browser.service';
import { PendingFile } from '../../../../domains/models/pending-file';
import { User } from '../../../../domains/users';
import { BasicProfileForm } from '../../../../modals/basic-profile/basic-profile.form';
import { InstallerProfileForm } from '../../../../modals/installer-profile/installer-profile.form';
import { convertBase64toBlob, getFileDataUrl, trimToOnlyBase64 } from '../../../utils/files';
import { HasTargetFiles, HasTargetValue } from '../../../utils/types';
import { ImageCropperPopoverComponent } from './image-cropper-popover/image-cropper-popover.component';

@Component({
  selector: 'gc-profile-picture-form',
  templateUrl: './profile-picture-form.component.html',
  styleUrls: ['./profile-picture-form.component.scss'],
})
export class ProfilePictureFormComponent implements OnDestroy {
  @Input() validated = false;
  @Input() form!: InstallerProfileForm | BasicProfileForm; // TODO: Don't support different kinds of forms
  @Input() user?: User;
  @Output() photoChanged = new EventEmitter<{ user: User; file: PendingFile }>();

  @ViewChild('fileInput') fileInput!: IonInput;

  pendingFile?: PendingFile;
  pendingBase64!: string;
  publicProfileUrl!: string;
  externalId: Subscription;

  constructor(
    public modals: ModalController,
    public popovers: PopoverController,
    public auth: AuthFacade,
    public browser: BrowserService,
  ) {
    // TODO: Figure out how to get rid of local subscriptions
    this.externalId = auth.user$
      .pipe(map(user => user?.externalId))
      .subscribe(externalId => (this.publicProfileUrl = `${environment.apiRoot.profiles}${externalId}`));
  }

  async chooseFiles(): Promise<void> {
    const input = await this.fileInput.getInputElement();
    input.click();
  }

  async addFiles($event: Event & HasTargetFiles & HasTargetValue): Promise<void> {
    const [file]: File[] = Array.from($event.target?.files);
    this.setChanges({
      filename: file.name,
      contentType: file.type,
      fileBlob: file,
    });

    const dataUrl = await getFileDataUrl(file);

    const result = await this.cropImage(dataUrl);
    $event.target!.value = null;
    if (result.role === 'ok') {
      this.imageCropped(result.data);
      this.save();
    }
  }

  imageCropped(base64: string) {
    this.pendingBase64 = base64;
    this.form.profilePhoto.setValue(base64);

    const blob = convertBase64toBlob(trimToOnlyBase64(base64), 'image/jpeg');

    this.setChanges({
      filename: this.user?.gcNumber || this.pendingFile?.filename,
      contentType: 'image/jpeg',
      fileBlob: blob,
    });
  }

  async cropImage(imageBase64: string) {
    const popover = await this.popovers.create({
      component: ImageCropperPopoverComponent,
      componentProps: {
        imageBase64,
      },
      cssClass: 'image-cropper-popover',
    });
    await popover.present();
    return popover.onDidDismiss();
  }

  save() {
    this.photoChanged.emit({
      user: this.user!,
      file: {
        ...this.pendingFile,
      },
    });
    this.pendingFile = undefined;
  }

  setChanges(file: PendingFile) {
    this.pendingFile = file;
  }

  async takePicture() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Base64,
    });

    this.form.profilePhoto.setValue(image.base64String);
    const blob = convertBase64toBlob(image.base64String, image.format);

    this.setChanges({
      filename: this.user!.gcNumber,
      contentType: 'image/jpeg',
      fileBlob: blob,
    });
  }

  ngOnDestroy() {
    this.externalId.unsubscribe();
  }
}
