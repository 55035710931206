import { createReducer, on } from '@ngrx/store';
import moize from 'moize';
import { hasKeys } from '../../domains/work/work-order/work-order.selectors';
import { cachedDataPurged, httpResponseReceived } from '../actions';
import { ResponseMetadata } from '../models/response-metadata.model';
import { getMaxStaleAge, isExpired } from '../utils';

export interface ResponseMetadataMapping {
  [url: string]: ResponseMetadata;
}

export interface HTTPTrafficState {
  metadata: ResponseMetadataMapping;
  urls: string[];
}

export const initialHTTPTrafficState: HTTPTrafficState = {
  metadata: {},
  urls: [],
};

export const trackHttpResponse = (
  state: HTTPTrafficState,
  { response }: { response: ResponseMetadata },
): HTTPTrafficState => ({
  ...state,
  metadata: {
    ...state.metadata,
    [response.url]: response,
  },
  urls: [...state.urls.filter(url => response.url !== url), response.url],
});

export const filterExpiredUrls = moize(
  (urls: string[], metadata: ResponseMetadataMapping, referenceDate: Date): string[] =>
    hasKeys(metadata) ? urls.filter(
      url =>
        !isExpired(
          referenceDate,
          metadata[url].receivedAt,
          getMaxStaleAge(metadata[url].headers['x-app-cache-control'])!,
        ),
    ) : [],
  { maxSize: 25 },
);

export const purgeExpiredMetadata = (
  state: HTTPTrafficState,
  { referenceDate }: { referenceDate: Date },
): HTTPTrafficState => ({
  ...state,
  urls: filterExpiredUrls(state.urls, state.metadata, referenceDate),
  metadata: filterExpiredUrls(state.urls, state.metadata, referenceDate).reduce(
    (metadata: ResponseMetadataMapping, url) => ((metadata[url] = state.metadata[url]), metadata),
    {},
  ),
});

export const httpTrafficReducer = createReducer(
  initialHTTPTrafficState,
  on(httpResponseReceived, trackHttpResponse),
  on(cachedDataPurged, purgeExpiredMetadata),
);
