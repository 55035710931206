import { Component, Input } from '@angular/core';

@Component({
  selector: 'gc-transaction-item',
  templateUrl: './transaction-item.component.html',
  styleUrls: ['./transaction-item.component.scss'],
})
export class TransactionItemComponent {
  @Input() avatar?: string;
  @Input() name?: string;
  @Input() amount?: number | string;
}
