import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { showEnhancedInstallerProfile } from '../../../core/installer-profile';
import { Address } from '../../locations';
import { PendingFile } from '../../models/pending-file';
import { StripeAccountType } from '../../models/stripe-responses';
import { PhoneNumber } from '../../phone-number';
import { AppState } from '../../state';
import { connectionForCurrentInstaller } from '../network.selectors';
import { AnyUser, Installer, User } from '../user/user.model';
import {
  addProfileFile,
  addProfilePhoneNumber,
  cancelEditProfile,
  editUserProfile,
  finishProfileEditing,
  loadUserProfile,
  managePayments,
  saveUserProfile,
  setProfilePhoto,
  setupPayment,
  unapprovedInstallerWizardSubmitted,
  vacationStatusToggled,
  viewProvider,
} from './profile.actions';
import {
  currentEditedUser,
  currentInstallerProfile,
  currentInstallerProfileAreas,
  currentInstallerProfileComplianceFiles,
  currentProjectManagerProfile,
  currentUserInsuranceFiles,
  currentUserPhoneNumbers,
  currentUserTaxFiles,
  dashboardWizardUser,
  loadingFlags,
  managePaymentsLoading,
  onVacationStatus,
  setupPaymentsLoading,
  toggleVacationLoading,
} from './profile.selectors';

@Injectable()
export class ProfileFacade {
  dashboardWizardUser$ = this.store.select(dashboardWizardUser);
  currentEditedUser$ = this.store.select(currentEditedUser);
  currentUserPhoneNumbers$ = this.store.select(currentUserPhoneNumbers);
  currentUserInsuranceFiles$ = this.store.select(currentUserInsuranceFiles);
  currentUserTaxFiles$ = this.store.select(currentUserTaxFiles);
  onVacationStatus$ = this.store.select(onVacationStatus);

  loadingFlags$ = this.store.select(loadingFlags);
  setupPaymentsLoading$ = this.store.select(setupPaymentsLoading);
  managePaymentsLoading$ = this.store.select(managePaymentsLoading);
  toggleVacationLoading$ = this.store.select(toggleVacationLoading);

  currentProjectManagerProfile$ = this.store.select(currentProjectManagerProfile);
  currentInstallerProfile$ = this.store.select(currentInstallerProfile);
  currentInstallerNetwork$ = this.store.select(connectionForCurrentInstaller);
  currentInstallerProfileAreas$ = this.store.select(currentInstallerProfileAreas);
  currentInstallerProfileComplianceFiles$ = this.store.select(currentInstallerProfileComplianceFiles);

  // eslint-disable-next-line @ngrx/no-typed-global-store
  constructor(private readonly store: Store<AppState>) {}

  loadUserProfile(user: User) {
    this.store.dispatch(loadUserProfile({ user }));
  }

  setupPayment(businessType: StripeAccountType) {
    this.store.dispatch(setupPayment({ businessType }));
  }

  viewProvider() {
    this.store.dispatch(viewProvider());
  }

  managePayments() {
    this.store.dispatch(managePayments());
  }

  editProfile(showEditPhoto = false): void {
    this.store.dispatch(editUserProfile({ showEditPhoto }));
  }

  showEnhancedInstallerProfile(installer: Installer): void {
    this.store.dispatch(showEnhancedInstallerProfile({ installer }));
  }

  addProfilePhoneNumber(phoneNumber: PhoneNumber): void {
    this.store.dispatch(addProfilePhoneNumber({ phoneNumber }));
  }

  addProfileFile(user: User, file: PendingFile): void {
    this.store.dispatch(addProfileFile({ user, file }));
  }

  setProfilePhoto(user: User, file: PendingFile): void {
    this.store.dispatch(setProfilePhoto({ user, file }));
  }

  saveProfile(user: Partial<AnyUser>): void {
    this.store.dispatch(saveUserProfile({ user }));
  }

  submitUnapprovedInstaller(phoneNumber: PhoneNumber, address: Partial<Address>) {
    this.store.dispatch(unapprovedInstallerWizardSubmitted({ phoneNumber, address }));
  }

  cancelEditProfile(hasChanges: boolean): void {
    this.store.dispatch(cancelEditProfile({ hasChanges }));
  }

  finishProfile(user: AnyUser): void {
    this.store.dispatch(finishProfileEditing({ user }));
  }

  toggleVacationStatus(): void {
    this.store.dispatch(vacationStatusToggled());
  }
}
