import { Component, Input } from '@angular/core';
import { InstallerTeamLead } from '../../../../domains/users';

@Component({
  selector: 'gc-user-completed-work',
  templateUrl: './user-completed-work.component.html',
  styleUrls: ['./user-completed-work.component.scss'],
})
export class UserCompletedWorkComponent {
  @Input() user!: InstallerTeamLead;
}
