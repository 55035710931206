import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PhoneNumber } from '../../domains/phone-number';

@Injectable()
export class PhoneNumbersUIService {
  constructor(
    private readonly toasts: ToastController,
    private readonly alerts: AlertController,
  ) {}

  toastVerificationTimeout(): Observable<void> {
    return from(
      this.toasts
        .create({
          message: 'Verification timed out. Please try again.',
          duration: 5000,
          color: 'danger',
          position: 'bottom',
        })
        .then(toast => toast.present()),
    );
  }

  toastVerificationError(error: any): Observable<void> {
    return from(
      this.toasts
        .create({
          message:
            error.error.reason === 'NOTSUP'
              ? 'You cannot verify this phone number at this time.'
              : error.error.reason === 'EXP'
              ? 'Your verification code has expired. Please request a new one.'
              : error.error.reason === 'INVALID'
              ? 'The verification code you entered is incorrect. Please try again.'
              : `There was an error verifying your phone number. ${error.error.message}`,
          duration: 5000,
          color: 'danger',
          position: 'bottom',
        })
        .then(toast => toast.present()),
    );
  }

  confirmRemovePhoneNumber(phoneNumber: PhoneNumber): Observable<boolean> {
    return from(
      this.alerts.create({
        header: 'Remove Phone Number',
        message: `Are you sure you want to remove ${phoneNumber.number}?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'button-text-dark',
            handler: () => false,
          },
          {
            text: 'Remove',
            role: 'ok',
            cssClass: 'button-text-red',
            handler: () => true,
          },
        ],
      }),
    ).pipe(
      switchMap(alert =>
        from(alert.present()).pipe(switchMap(() => from(alert.onDidDismiss()).pipe(map(({ role }) => role === 'ok')))),
      ),
    );
  }

  warnMinimumPhoneNumbersRequired(): Observable<any> {
    return from(
      this.alerts.create({
        header: 'Phone Number Required',
        message: 'You must have at least one phone number.',
        buttons: [
          {
            text: 'Ok',
            role: 'ok',
            handler: () => true,
          },
        ],
      }),
    ).pipe(switchMap(alert => from(alert.present()).pipe(switchMap(() => from(alert.onDidDismiss())))));
  }

  toastPhoneNumberYours(phoneNumber: PhoneNumber): Observable<void> {
    return from(
      this.toasts
        .create({
          message: `You have already added this phone number: ${phoneNumber.number}.`,
          duration: 5000,
          color: 'danger',
          position: 'bottom',
        })
        .then(toast => toast.present()),
    );
  }

  toastPhoneNumberExists(phoneNumber: PhoneNumber): Observable<void> {
    return from(
      this.toasts
        .create({
          message: `The number ${phoneNumber.number} appears to be in use already.`,
          duration: 5000,
          color: 'danger',
          position: 'bottom',
        })
        .then(toast => toast.present()),
    );
  }

  toastCodeRefreshed(phoneNumber: PhoneNumber): Observable<void> {
    return from(
      this.toasts
        .create({
          message: `Verification code for ${phoneNumber.number} has been refreshed.`,
          duration: 5000,
          color: 'success',
          position: 'bottom',
        })
        .then(toast => toast.present()),
    );
  }

  toastVerificationSuccess(phoneNumber: PhoneNumber): Observable<void> {
    return from(
      this.toasts
        .create({
          message: `Your phone number ${phoneNumber.number} has been verified.`,
          duration: 5000,
          color: 'dark',
          position: 'bottom',
        })
        .then(toast => toast.present()),
    );
  }

  toastPhoneNumberInvalid(phoneNumber: PhoneNumber): Observable<void> {
    return from(
      this.toasts
        .create({
          message: `Hmm, this phone number ${phoneNumber.number} does not look quite right.`,
          duration: 5000,
          color: 'danger',
          position: 'bottom',
        })
        .then(toast => toast.present()),
    );
  }

  toastPhoneNumberError(phoneNumber: PhoneNumber, error: any): Observable<void> {
    return from(
      this.toasts
        .create({
          header: `There was an issue adding your phone number ${phoneNumber.number}.`,
          message: error.error.message,
          duration: 5000,
          color: 'danger',
          position: 'bottom',
        })
        .then(toast => toast.present()),
    );
  }
}
