import { createAction, props } from '@ngrx/store';
import { Connection } from './connection/connection.model';
import { Installer, InstallerTeamLead, Jumpstarter, ProjectManager, User } from './user/user.model';

export const skillsAndExperienceViewed = createAction('[Users] Skills & Experience Viewed', props<{ user: User }>());

export const selectedEmployeeInstallerProfile = createAction(
  '[Users] Show Employee Installer Profile',
  props<{ installer: Installer }>(),
);

// TODO: What should we do about this being "review" versus "selected"
export const selectedInstallerTeamLeadProfile = createAction(
  '[Users] Review Installer Team Lead Profile',
  props<{
    installer: InstallerTeamLead;
  }>(),
);

export const awardedInstallerProfileShown = createAction(
  '[Users] (Awarded Installer Profile) Shown',
  props<{ installer: Installer }>(),
);

export const selectedInvoicingInstallerProfile = createAction(
  '[Users] Show Invoicing Installer Profile',
  props<{
    installer: Installer;
  }>(),
);

export const showJumpstarterProfile = createAction(
  '[Users] Show Jumpstarter Profile',
  props<{ jumpstarter: Jumpstarter }>(),
);

export const showProjectManagerProfile = createAction(
  '[Users] Show Project Manager Profile',
  props<{ projectManager: ProjectManager }>(),
);

export const closeProfileModal = createAction('[Users] Close Profile Modal');

export const removeUser = createAction('[Users] Remove User', props<{ user: User }>());

export const disconnectUser = createAction('[Users] Disconnect User', props<{ connection: Connection }>());
