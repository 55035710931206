import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CacheEffects } from './cache.effects';
import { ResponseCacheService } from './response-cache.service';
import { FEATURE_NAME } from './store/feature.name';
import { reducer } from './store/feature.state';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducer), EffectsModule.forFeature([CacheEffects])],
  providers: [ResponseCacheService],
})
export class HttpModule {}
