import { format } from 'date-fns';
import { formatPhoneNumber } from '../../shared/components/advanced-phone-number-manager/forms/new-phone-number.form';
import { PhoneNumber, PhoneNumberType } from './phone-number.model';

export const buildPhoneNumber = (
  phoneNumber: (PhoneNumber & any) | undefined,
  user: string | undefined,
  number: string | undefined,
  type: PhoneNumberType,
  consentGiven: boolean,
): PhoneNumber => ({
  ...phoneNumber,
  userId: user,
  number: formatPhoneNumber(number),
  type: type,
  consentMedium: 'app',
  consentAcquiredAt: consentGiven ? format(new Date(), "yyyy-MM-dd'T'hh':'mm':'ss") : undefined,
  consentAcquiredVersion: consentGiven ? 1 : undefined,
  consent: undefined,
});
