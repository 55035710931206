import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { clearFlag, setFlag } from '../../../../core/invites/store/invites.state';
import { DwollaAccount } from './dwolla-account.entity';
import {
  dwollaCustomerCreateSuccess,
  loadDwollaAccount,
  loadDwollaAccountFailure,
  loadDwollaAccountSuccess,
  loadDwollaCustomerDetails,
  loadDwollaCustomerDetailsFailure,
  loadDwollaCustomerDetailsSuccess,
  transferDwollaBalance,
  transferDwollaBalanceFailure,
  transferDwollaBalanceSuccess,
} from './dwolla.actions';
import { DwollaCustomerDetails } from './models/customer-details.model';

export interface DwollaAccountsState {
  dwollaAccount?: DwollaAccount;
  customerDetails?: DwollaCustomerDetails;
  transferringBalance: boolean;
  loading: boolean;
  initialLoadComplete: boolean;
}

export const initialDwollaAccountsState: DwollaAccountsState = {
  transferringBalance: false,
  loading: false,
  initialLoadComplete: false,
};

const reduce = createReducer(
  initialDwollaAccountsState,
  // on(loadDwollaAccount, loadDwollaCustomerDetails, state => ({ ...state, loading: true })),
  on(loadDwollaAccount, loadDwollaCustomerDetails, setFlag('loading')),
  on(
    loadDwollaAccountSuccess,
    loadDwollaAccountFailure,
    loadDwollaCustomerDetailsSuccess,
    loadDwollaCustomerDetailsFailure,
    clearFlag('loading'),
  ),
  on(
    loadDwollaAccountFailure,
    loadDwollaCustomerDetailsSuccess,
    loadDwollaCustomerDetailsFailure,
    setFlag('initialLoadComplete'),
  ),
  on(
    dwollaCustomerCreateSuccess,
    (state: DwollaAccountsState, { location }): DwollaAccountsState => ({
      ...state,
      dwollaAccount: {
        id: location.split('/').pop(),
        link: location,
      } as DwollaAccount,
    }),
  ),
  on(transferDwollaBalance, setFlag('transferringBalance')),
  on(
    loadDwollaAccountSuccess,
    transferDwollaBalanceSuccess,
    (state: DwollaAccountsState, { dwollaAccount }): DwollaAccountsState => ({
      ...state,
      dwollaAccount,
    }),
  ),
  on(transferDwollaBalanceSuccess, transferDwollaBalanceFailure, clearFlag('transferringBalance')),
  on(
    loadDwollaCustomerDetailsSuccess,
    (state: DwollaAccountsState, { customerDetails }): DwollaAccountsState => ({
      ...state,
      customerDetails,
    }),
  ),
);

export function dwollaAccountsReducer(
  state: DwollaAccountsState = initialDwollaAccountsState,
  action: Action,
): DwollaAccountsState {
  return reduce(state, action);
}

export const DWOLLA_ACCOUNTS_FEATURE_KEY = 'dwollaAccount';

export const dwollaAccountsState = createFeatureSelector<DwollaAccountsState>(DWOLLA_ACCOUNTS_FEATURE_KEY);
