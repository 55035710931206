import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { currentWorkOrder } from '~gc/domains/work/work-order/work-order.state';
import { WorkOrder } from '../../work/work-order/work-order.model';
import { LienReleaseTemplate } from './lien-release-template.model';
import { allLienReleaseEntities } from './lien-release-template.state';

export const templateByWorkOrderId = (
  lienReleaseEntities: IEntityDictionary<LienReleaseTemplate>,
  workOrder?: WorkOrder,
): LienReleaseTemplate | undefined =>
  workOrder?.lienReleaseTemplateId ? lienReleaseEntities[workOrder.lienReleaseTemplateId] : undefined;

export const currentWorkOrderLienReleaseTemplate = createSelector(
  allLienReleaseEntities,
  currentWorkOrder,
  templateByWorkOrderId,
);
