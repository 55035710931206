import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AuthFacade } from '~gc/domains/app/auth/auth.facade';
import { InvoiceAndPaymentFacade } from '~gc/domains/invoices/invoice-and-payment.facade';
import { InvoiceItemsFacade } from '~gc/domains/invoices/invoice-item/invoice-items.facade';
import { InvoicesFacade } from '~gc/domains/invoices/invoice/invoices.facade';
import { WorkOrderRelationsFacade } from '~gc/domains/work-order-relations.facade';
import { WorkOrderFacade } from '~gc/domains/work/work-order/work-order.facade';
import { InvoiceFilesFacade } from '../../domains/invoices/invoice-file/invoice-file.facade';
import {
  invoicingInstaller,
  reportsProgressForCurrentWorkOrder,
  ViewInvoiceContext,
} from '../../domains/invoices/invoice-ui.selectors';
import { PrintService } from '../../shared/print/print.service';

@Component({
  selector: 'gc-view-invoice-modal',
  templateUrl: './view-invoice-modal.component.html',
  styleUrls: ['./view-invoice-modal.component.scss'],
})
export class ViewInvoiceModalComponent {
  @Input() context!: ViewInvoiceContext;
  @ViewChild('printContent') content!: TemplateRef<any>;

  reportsProgress$ = this.store.select(reportsProgressForCurrentWorkOrder);
  installer$ = this.store.select(invoicingInstaller);

  constructor(
    public auth: AuthFacade,
    public modalGC: ModalController,
    public invoices: InvoicesFacade,
    public invoiceItems: InvoiceItemsFacade,
    public invoiceFiles: InvoiceFilesFacade,
    public invoiceAndPayment: InvoiceAndPaymentFacade,
    public workOrders: WorkOrderFacade,
    public workOrderRelations: WorkOrderRelationsFacade,
    public platform: Platform,
    public printer: PrintService,
    public readonly store: Store,
  ) {}
}
