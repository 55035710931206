import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface Area {
  name: string;
  label: string;
  icon?: string;
}

export interface BadgeMap {
  [p: string]: {
    color: string;
    label: string | number;
  };
}

@Component({
  selector: 'gc-area-segments',
  templateUrl: './area-segments.component.html',
  styleUrls: ['./area-segments.component.scss'],
})
export class AreaSegmentsComponent {
  @Input() areas?: Area[];
  @Input() badgeMap: BadgeMap = {};
  @Input() selectedArea!: string;
  @Output() selected = new EventEmitter<any>();
}
