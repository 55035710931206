import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { MatchedInstaller } from '~gc/domains/work/matched-installer/matched-installer.model';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { BrowserService } from '../../../domains/browser.service';
import { UnqualifiedReason, UserWorkOrderQualifications } from '../../../domains/work-order-qualifications.selectors';
import { DetailedWorkOrder } from '../../../domains/work/models/detailed-work-order';
import { ModalService } from '../../../shared/services/modal.service';

@Component({
  selector: 'gc-work-order-installer-options',
  templateUrl: './work-order-installer-options.component.html',
  styleUrls: ['./work-order-installer-options.component.scss'],
})
export class WorkOrderInstallerOptionsComponent {
  @Input() loading = false;
  @Input() savingMatchedInstaller = false;
  @Input() workOrder!: DetailedWorkOrder;
  @Input() matchedInstaller!: MatchedInstaller;
  @Input() qualifications!: UserWorkOrderQualifications;

  @Output() interested = new EventEmitter<{ matchedInstaller: MatchedInstaller; workOrder: WorkOrder }>();
  @Output() negotiated = new EventEmitter<{ matchedInstaller: MatchedInstaller; workOrder: WorkOrder }>();
  @Output() canceled = new EventEmitter<{ matchedInstaller: MatchedInstaller; workOrder: WorkOrder }>();
  @Output() declined = new EventEmitter<{ matchedInstaller: MatchedInstaller; workOrder: WorkOrder }>();
  @Output() appendMatchedInstaller = new EventEmitter<WorkOrder>();
  @Output() openSkillsAndExperience = new EventEmitter();
  @Output() openProfile = new EventEmitter();

  constructor(
    private readonly modals: ModalService,
    private browser: BrowserService,
  ) {}

  get reason(): UnqualifiedReason {
    return Object.keys(this.qualifications!).find(
      (key: string): key is UnqualifiedReason => !this.qualifications[key as keyof UserWorkOrderQualifications],
    ) as UnqualifiedReason;
  }

  // TODO: This method is poorly designed, and either returns a sheet modal, or nothing (bad!)
  attemptMarkInterested(unqualifiedModal: IonModal, override = false) {
    if (this.reason) {
      if (override) {
        this.appendMatchedInstaller.emit(this.workOrder);
      }
      return this.modals.openSheetModal(unqualifiedModal, { initialBreakpoint: 0.8, breakpoints: [0, 0.5, 0.8] });
    }
    this.appendMatchedInstaller.emit(this.workOrder);
    return Promise.resolve(undefined);
  }

  openModal(modalGC: IonModal) {
    return this.modals.openSheetModal(modalGC, { initialBreakpoint: 0.8, breakpoints: [0, 0.5, 0.8] });
  }

  async openBrowser(url: string): Promise<void> {
    await this.browser.openUrl(url);
  }
}
