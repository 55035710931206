<ion-card>
  <ion-card-header color="light">
    <div class="corner-badge corner-badge-green"><strong>Price</strong></div>
    <ion-card-subtitle
      >{{ item.workOrderItem?.numberOfUnits }} {{ item.workOrderItem?.unitOfMeasureSymbol }}</ion-card-subtitle
    >
    <ion-card-title>
      {{ item.workOrderItem?.name }}
    </ion-card-title>
  </ion-card-header>
  <ion-card-content *ngIf="!!form">
    <ion-grid>
      <ion-row *ngIf="!(role | isRole: 'installerLead') || !form.isAcceptedByInstaller.value">
        <ion-col>
          <ion-item>
            <ion-input
              labelPlacement="stacked"
              [label]="form.mode | negotiationModeFormat: 'Your %mode% Price'"
              [formControl]="form.yourPrice"
              [color]="
                form.mode
                  | negotiatedPriceColor
                    : form.yourPrice.value
                    : form.opposingPrice.value
                    : !form.isAcceptedByInstaller.value
              "
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-text [color]="form.isAcceptedByInstaller.value ? 'dark' : undefined"
            >{{ form.mode | negotiationModeFormat: 'Current %mode% Price: ' : true }}
            <strong>{{ form.opposingPrice.value | currency }}</strong></ion-text
          >
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="!item.isAcceptedByInstaller && !form.isAcceptedByInstaller.value && (role | isRole: 'installerLead')"
      >
        <ion-col>
          <ion-button color="light" size="large" expand="block" (click)="form.accept()">
            Accept Current Ask
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="!item.isAcceptedByInstaller && form.isAcceptedByInstaller.value && (role | isRole: 'installerLead')"
      >
        <ion-col>
          <ion-button color="light" size="large" expand="block" (click)="form.unaccept()">
            <ion-icon name="arrow-undo-outline"></ion-icon>
            Undo Acceptance
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="item.isAcceptedByInstaller && (role | isRole: 'installerLead')">
        <ion-col>
          <ion-button color="light" size="large" expand="block"> Asking Price Accepted</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
