<ng-container
  *ngIf="
    (group | typeIsIn: 'negotiable') &&
    (displayGroups | itemGroupsAllowed: 'negotiable') &&
    (permissions | hasPermissions: 'work-order.negotiations:view' : 'work-order.negotiation-items:view')
  "
>
  <gc-item-negotiations-expander
    [item]="item"
    [expanded]="expandedItem"
    (expand)="expanded.emit(item)"
  ></gc-item-negotiations-expander>
</ng-container>
<ng-container
  *ngIf="
    ((group | typeIsIn: 'original') &&
      (displayGroups | itemGroupsAllowed: 'original') &&
      (permissions | hasPermissions: 'work-order.items:view')) ||
    ((group | typeIsIn: 'change-add' : 'installer-add') &&
      (displayGroups | itemGroupsAllowed: 'change-add' : 'installer-add') &&
      (permissions | hasPermissions: 'work-order.change-order-items:view'))
  "
>
  <gc-item-change-orders-expander
    [item]="item"
    [expanded]="expandedItem"
    (expand)="expanded.emit(item)"
  ></gc-item-change-orders-expander>
</ng-container>
