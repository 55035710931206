import { Pipe, PipeTransform } from '@angular/core';

export type PhoneIconNames = 'radio' | 'call' | 'help-circle';

export type PhoneType = 'MOBILE' | 'OFFICE' | 'OTHER';

export const PHONE_TYPE_ICONS: Record<PhoneType, PhoneIconNames> = {
  MOBILE: 'radio',
  OFFICE: 'call',
  OTHER: 'help-circle',
};

@Pipe({ name: 'phoneTypeIcon' })
export class PhoneTypeIconPipe implements PipeTransform {
  transform(type: PhoneType): PhoneIconNames {
    return PHONE_TYPE_ICONS[type] || 'call';
  }
}
