<ion-button
  data-cy="invite-user-button"
  size="large"
  expand="block"
  mode="ios"
  color="gc-green"
  (click)="submitted.emit({ invite: form.updatedModel, user: view.foundUser })"
  [disabled]="
    form.invalid || (view.foundUser && view.foundUser.availability !== 'A') || view.isPending || view.existingConnection
  "
>
  <fa-icon *ngIf="processing" [icon]="faCircleNotch" [pulse]="false" [spin]="true" size="2x"></fa-icon>
  <strong *ngIf="!processing">{{
    type === 'INSTALLER_TEAM_LEAD' ? 'Invite Connection' : 'Add New ' + typeDisplay
  }}</strong>
</ion-button>
