import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Invoice, InvoiceStatus } from '~gc/domains/invoices';

@Component({
  selector: 'gc-decline-invoice-form',
  templateUrl: './decline-invoice-form.component.html',
  styleUrls: ['./decline-invoice-form.component.scss'],
})
export class DeclineInvoiceFormComponent implements OnInit {
  @Input() invoice!: Invoice;
  @Input() reportsProgress!: boolean;

  @Output() declined = new EventEmitter<Invoice>();

  form!: UntypedFormGroup;
  submitted = false;

  constructor(private builder: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.builder.group({
      declinedReason: ['', Validators.required],
    });
  }

  submit(form: UntypedFormGroup, invoice: Invoice): void {
    of(form)
      .pipe(
        tap(() => (this.submitted = true)),
        filter(form => form.valid),
        map(form => ({
          ...invoice,
          status: InvoiceStatus.Declined,
          ...form.value,
        })),
        tap(declinedInvoice => this.declined.emit(declinedInvoice)),
      )
      .subscribe();
  }
}
