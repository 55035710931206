import { createSelector } from '@ngrx/store';
import { Installer } from '../../domains/users';
import { KudosReason } from '../../domains/users/kudos-reason/kudos-reason.model';
import { allKudosReasons } from '../../domains/users/kudos-reason/kudos-reason.state';
import { KudosReasonsDetails } from './models/detailed-installer-profile-context.model';
import { KudosReasonsElement } from './models/kudos-reasons-element.model';
import { PrimaryKudosInfo } from './models/primary-kudos-info.model';
import { currentInstaller } from './store/profile.selectors';


export const currentInstallerKudosReason = createSelector(
  allKudosReasons,
  currentInstaller,
  (kudosReasons: KudosReason[], installer?: Installer): KudosReason | undefined =>
    installer ? kudosReasons.find(reason => reason.userId === installer.id) : undefined,
);

export type DescribedReason = Omit<KudosReason, 'userId' | 'notProvidedCount'>;

export type DescribedReasons = Record<keyof DescribedReason, string>;
export const kudosDisplayNames: DescribedReasons = {
  attitudeCount: 'Attitude',
  punctualityCount: 'Punctuality',
  qualityCount: 'Quality',
  professionalismCount: 'Professionalism',
  dependabilityCount: 'Dependability',
};

export const currentInstallerKudosReasons = createSelector(
  currentInstallerKudosReason,
  (kudosReason?: KudosReason): KudosReasonsElement[] | undefined =>
    kudosReason
      ? Object.keys(kudosReason).reduce(
          (acc: KudosReasonsElement[], key: keyof KudosReason): KudosReasonsElement[] =>
            key !== 'userId' && key !== 'notProvidedCount'
              ? [
                  ...acc,
                  {
                    name: key,
                    count: kudosReason[key],
                    description: kudosDisplayNames[key as unknown as keyof DescribedReason],
                  },
                ]
              : acc,
          [] as KudosReasonsElement[],
        )
      : undefined,
);

export const maxInstallerKudosReason = createSelector(
  currentInstallerKudosReasons,
  (kudosReasons?: KudosReasonsElement[]): KudosReasonsElement | undefined =>
    kudosReasons
      ? kudosReasons.reduce(
          (acc: KudosReasonsElement | undefined, reason: KudosReasonsElement): KudosReasonsElement | undefined =>
            acc && acc.count > reason.count ? acc : reason,
          undefined,
        )
      : undefined,
);

export const primaryKudosDescriptions: Record<keyof DescribedReason, (name: string) => string> = {
  attitudeCount: name => `${name} has received the majority of his kudos for his exceptional attitude.`,
  punctualityCount: name => `${name} has received the majority of his kudos for his punctuality.`,
  qualityCount: name => `${name} has received the majority of his kudos for his exceptional installation quality.`,
  professionalismCount: name => `${name} has received the majority of his kudos for his high level of professionalism.`,
  dependabilityCount: name => `${name} has received the majority of his kudos for his rock solid dependability.`,
};

export const currentInstallerKudosReasonsPrimary = createSelector(
  currentInstallerKudosReasons,
  maxInstallerKudosReason,
  currentInstaller,
  (
    kudosReasons?: KudosReasonsElement[],
    maxKudosReason?: KudosReasonsElement,
    installer?: Installer,
  ): PrimaryKudosInfo | undefined => {
    if ((!maxKudosReason || maxKudosReason.count === 0) && installer?.firstName) {
      return {
        title: 'No Kudos Yet',
        description: `It doesn't look like ${installer.firstName} has received any kudos yet. When they do, you'll see the breakdown here.`,
        url: `https://gocarrera.com/resources/knowledge-base/what-do-these-kudos-metrics-mean/`
      };
    }

    if (kudosReasons && maxKudosReason && maxKudosReason.count > 0 && installer?.firstName) {
      const maxCount = maxKudosReason.count;
      const maxCountReasons = kudosReasons.filter(reason => reason.count === maxCount);

      if (maxCountReasons.length > 1) {
        return {
          title: 'Well Balanced',
          description: `${installer.firstName} has received kudos in multiple areas. Take a look at the chart for additional detail.`,
          url: `https://gocarrera.com/resources/knowledge-base/what-do-these-kudos-metrics-mean/`
        };
      }

      return {
        title: `Exceptional ${kudosDisplayNames[maxKudosReason.name as keyof DescribedReason]}`,
        description:
          primaryKudosDescriptions[maxKudosReason.name as keyof DescribedReason]?.(installer.firstName) ?? '',
        url: `https://gocarrera.com/resources/knowledge-base/what-do-these-kudos-metrics-mean/`
      };
    }

    return undefined;
  },
);

export const currentInstallerKudosReasonDetails = createSelector(
  currentInstallerKudosReasons,
  currentInstallerKudosReasonsPrimary,
  (dataPoints?: KudosReasonsElement[], primaryKudos?: PrimaryKudosInfo): KudosReasonsDetails | undefined =>
    dataPoints && primaryKudos
      ? {
          dataPoints,
          primaryKudos,
        }
      : undefined,
);
