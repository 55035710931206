import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'AddressType',
  uriName: 'address-type',
})
export class AddressType {
  @Key id?: number;
  name!: string;
  description?: string;
  isActive!: boolean;
}
