import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest, of } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, first, map, tap, withLatestFrom } from 'rxjs/operators';
import { AuthFacade } from '~gc/domains/app/auth/auth.facade';
import { isRole } from '~gc/domains/app/auth/auth.maps';
import { PendingNegotiationDetails } from '~gc/domains/negotiations/models/pending-negotiation.model';
import { NegotiationsFacade } from '~gc/domains/negotiations/negotiations.facade';
import { NegotiationMode } from '~gc/modals/bid-ask-negotiation/negotiation-mode';
import { filterUntilPredicatePassed } from '~gc/shared/utils/operators';
import { WorkOrderFacade } from '../../../domains/work/work-order/work-order.facade';
import { NegotiationForm } from '../negotiation.form';

@Component({
  selector: 'gc-bid-ask-negotiation-modal',
  templateUrl: './bid-ask-negotiation-modal.component.html',
  styleUrls: ['./bid-ask-negotiation-modal.component.scss'],
})
export class BidAskNegotiationModalComponent implements OnInit {
  form!: NegotiationForm;

  constructor(
    public auth: AuthFacade,
    public negotiations: NegotiationsFacade,
    private modalGC: ModalController,
    public work: WorkOrderFacade,
  ) {}

  ngOnInit() {
    combineLatest([this.negotiations.pendingNegotiationDetails$, this.work.current$])
      .pipe(
        filterUntilPredicatePassed(([edited]) => !!edited),
        distinctUntilChanged(),
        withLatestFrom(this.auth.role$),
        concatMap(([[edited, workOrder], role]) =>
          of(this.form).pipe(
            map(
              form =>
                (this.form =
                  form ??
                  new NegotiationForm(
                    isRole(role, 'installerLead') ? NegotiationMode.Installer : NegotiationMode.Company,
                    workOrder,
                  )),
            ),
            map(() => edited as Partial<PendingNegotiationDetails>),
          ),
        ),
        tap(edited => (edited ? this.form.updateModel(edited) : void 0)),
        filter(edited => !edited),
        first(),
      )
      .subscribe(() => this.modalGC.dismiss());
  }

  async cancelAddEdit() {
    await this.modalGC.dismiss(null, 'cancel');
  }

  async submit() {
    if (this.form.isValid) {
      await this.modalGC.dismiss(this.form.updatedModel, 'ok');
    }
  }

  updateNegotiationFilter(val: boolean | 'true' | 'false') {
    this.negotiations.updateNegotiationFilter(val === 'true' || val === true);
  }
}
