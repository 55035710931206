import { createSelector } from '@ngrx/store';
import { allProductInstallationTypeClaimed } from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.state';
import { selectedInstallTypeClaimedId } from './store/profile.selectors';

export const selectedInstallTypeClaim = createSelector(
  selectedInstallTypeClaimedId,
  allProductInstallationTypeClaimed,
  (selectedInstallTypeClaimedId, allProductInstallationTypeClaimed) =>
    allProductInstallationTypeClaimed.find(
      productInstallationTypeClaimed => productInstallationTypeClaimed.id === selectedInstallTypeClaimedId,
    ),
);
