import { InjectionToken, Provider } from '@angular/core';
import { ActionCreator, Creator } from '@ngrx/store';

export const STORE_SYNC_CONFIG = new InjectionToken('storeSyncConfig');

export type SyncKeyMap<T> = {
  [p in keyof T]?: boolean | (T[p] extends any[] ? never : T[p] extends object ? SyncKeyMap<T[p]> : never);
};

export const DEFAULT_STORAGE_KEY = '__StorageSync';

export interface StoreSyncConfig<T> {
  ignoredActions: Array<string | ActionCreator<string, Creator>>;
  storageKey: string;
  keys: SyncKeyMap<T>;
  hydrationKey: string;
}

export const storageSyncConfig = Symbol('StorageSyncConfig');

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function storageSyncConfigFactory<T>(config: Partial<StoreSyncConfig<T>>) {
  (storageSyncConfigFactory as any)[storageSyncConfig] = config;
  return () => config;
}

export const createStorageSyncConfig = <T>(config: Partial<StoreSyncConfig<T>>): Provider => ({
  provide: STORE_SYNC_CONFIG,
  useFactory: storageSyncConfigFactory(config),
});
