import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BrowserService } from '~gc/domains/browser.service';

@Component({
  selector: 'gc-announcement-modal',
  templateUrl: './announcement-modal.component.html',
  styleUrls: ['./announcement-modal.component.scss'],
})
export class AnnouncementModalComponent {
  constructor(
    public modals: ModalController,
    private browser: BrowserService,
  ) {}

  currentAnnouncementModalURL = 'https://gocarrera.com/features/ad-hoc-invoices/';

  async openBrowser(url: string): Promise<void> {
    await this.browser.openUrl(url);
  }
}
