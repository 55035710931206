import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AppUIService } from '../app-ui.service';
import { currentRouteUrl } from '../router.selectors';
import { authenticationFailed } from './auth/auth.actions';
import { LoginService } from './login.service';
import { registerNewUser, registerNewUserFailure, registerNewUserSuccess, showLoginAlert, signup } from './login.state';

@Injectable()
export class LoginEffects {
  constructor(
    private actions$: Actions,
    private appUI: AppUIService,
    private nav: NavController,
    private login: LoginService,
    private store: Store,
  ) {}

  invalidLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationFailed),
      map(() => showLoginAlert()),
    ),
  );

  signup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signup),
        tap(() => this.nav.navigateForward(['/signup/installer'])),
      ),
    { dispatch: false },
  );

  registerNewUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(registerNewUser),
      withLatestFrom(this.store.select(currentRouteUrl)),
      switchMap(([{ userInfo }, url]) =>
        this.login
          .register({ ...userInfo, type: url.includes('installer') ? 'INSTALLER_TEAM_LEAD' : 'JUMPSTARTER' })
          .pipe(
            // take(1) solves issue with sign in causing alert reappear.
            take(1),
            map(() => registerNewUserSuccess()),
            catchError(error => of(registerNewUserFailure({ error }))),
          ),
      ),
    ),
  );

  onRegistrationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerNewUserSuccess),
        switchMap(() => this.login.confirmRegistration()),
        switchMap(() => this.nav.navigateRoot('/login')),
      ),
    { dispatch: false },
  );

  onRegistrationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(registerNewUserFailure),
        switchMap(({ error }) =>
          error instanceof HttpErrorResponse && error.status === 409
            ? from(
                this.appUI.confirm(
                  'Account Already Exists',
                  'It looks like this email is already associated with another Go Carrera account. Would you like to log in instead?',
                  { confirmText: 'Login', cssClass: 'ion-color-gc-green' },
                ),
              ).pipe(
                filter(confirmed => confirmed),
                switchMap(() => this.nav.navigateRoot('/login')),
              )
            : from(this.login.warnFailedRegistration(error)),
        ),
      ),
    { dispatch: false },
  );
}
