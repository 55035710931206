import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InvoiceableWorkOrderItem } from '~gc/domains/models/invoiceable-work-order-item';
import { PendingInvoice } from '~gc/domains/models/pending-invoice';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { ChangeOrder, ChangeOrderStatus } from '../../../domains/change-orders/change-order/change-order.model';
import { PendingInvoiceForm } from '../../invoicing/invoice.form';

@Component({
  selector: 'gc-create-invoice-form',
  templateUrl: './create-invoice-form.component.html',
  styleUrls: ['./create-invoice-form.component.scss'],
})
export class CreateInvoiceFormComponent {
  @Input() items!: InvoiceableWorkOrderItem[];
  @Input() workOrder!: WorkOrder;
  @Output() saved = new EventEmitter<PendingInvoice>();
  @Input() form!: PendingInvoiceForm;

  get itemForms() {
    return this.form.items.itemForms;
  }

  protected readonly ChangeOrderStatus = ChangeOrderStatus;

  trackChangeOrderBy(index: number, item: ChangeOrder) {
    return item.id;
  }
}
