import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SelectionIconComponent } from './selection-icon.component';

@NgModule({
  declarations: [SelectionIconComponent],
  exports: [SelectionIconComponent],
  imports: [CommonModule, IonicModule],
})
export class SelectionIconModule {}
