import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { FullTypedFormConfig, TModelControls } from './types';

export abstract class TypedFormGroup<TModel> extends FormGroup<TModelControls<TModel>> {
  protected model?: Partial<TModel>; // Internally track the original or latest model that populated/patched the form

  private listenerSubscription?: Subscription;
  private onChangesSubscription?: Subscription;
  private changesOnSubscription?: Subscription;

  get originalModel(): Partial<TModel> {
    return {
      ...(this.model ?? {}),
    };
  }

  get updatedModel(): Partial<TModel> {
    return {
      ...this.model, // Copy original model FIRST!! This copies in any properties not part of the form!! (i.e. ids, server-generated fields, etc.)
      ...(this.value as Partial<TModel>), // Copy over the original model any properties managed by the form, thus overwriting those values with the latest from the form
    };
  }

  abstract get isEmpty(): boolean;

  protected constructor({
    config: { controls, validatorOrOpts, asyncValidator },
    onChanges,
    changesOf,
    model,
    builder,
    listener,
  }: FullTypedFormConfig<TModel>) {
    builder = builder ?? new FormBuilder();
    const group = builder.group(controls);
    super(group.controls as TModelControls<TModel>, validatorOrOpts, asyncValidator);
    this.model = model;

    if (onChanges) {
      this.onChangesSubscription = this.valueChanges.subscribe(onChanges as any); // TODO: Figure out how to use new typed forms typing!!
    }

    if (changesOf) {
      this.changesOnSubscription = this.changesOnSubscription ?? new Subscription();
      (Object.keys(changesOf) as unknown as string[]).forEach((key: string): void =>
        this.changesOnSubscription!.add(
          (
            (this.controls as TModelControls<TModel>)[key as keyof TModelControls<TModel>] as AbstractControl
          )?.valueChanges?.subscribe(changesOf[key as keyof TModel]),
        ),
      );
    }

    this.setListener(listener);
  }

  destroy() {
    this.unsubscribeListener();
    this.unsubscribeOnChanges();
    this.unsubscribeChangesOn();
  }

  protected setListener(listener?: Observable<Partial<TModel> | undefined | null>) {
    this.unsubscribeListener();

    if (listener) {
      this.listenerSubscription = listener.subscribe();
    }
  }

  protected getControl(name: keyof TModel | string): AbstractControl | null {
    return this.get(name as string);
  }

  protected getFormControl(name: keyof TModel | string): UntypedFormControl {
    return this.getControl(name) as UntypedFormControl;
  }

  protected getFormGroup(name: keyof TModel | string): UntypedFormGroup {
    return this.getControl(name) as UntypedFormGroup;
  }

  protected getFormArray(name: keyof TModel | string): UntypedFormArray {
    return this.getControl(name) as UntypedFormArray;
  }

  protected getTypedControl<T = any, TControl extends FormControl<T> = any>(name: string): TControl {
    return this.getFormControl(name) as TControl;
  }

  protected getTypedGroup<T = any, TGroup extends FormGroup = any>(name: string): TGroup {
    return this.getFormGroup(name) as TGroup;
  }

  protected getTypedArray<T, TArray extends FormArray>(name: string): TArray {
    return this.getFormArray(name) as TArray;
  }

  protected transformModel(model: Partial<TModel>): any {
    return model;
  }

  patchModel(model: Partial<TModel>): void {
    this.patchValue(model);
    this.updateValueAndValidity();
  }

  override patchValue(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: { [p: string]: any },
    options?: { onlySelf?: boolean; emitEvent?: boolean },
  ): void {
    this.model = {
      ...this.updatedModel,
      ...(value as Partial<TModel>),
    };
    const txvalue = this.transformModel(value as Partial<TModel>);
    super.patchValue(txvalue, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  enableValidators(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disableValidators(): void {}

  private unsubscribeListener() {
    if (this.listenerSubscription) {
      this.listenerSubscription.unsubscribe();
      this.listenerSubscription = undefined;
    }
  }

  private unsubscribeOnChanges() {
    if (this.onChangesSubscription) {
      this.onChangesSubscription.unsubscribe();
      this.onChangesSubscription = undefined;
    }
  }

  private unsubscribeChangesOn() {
    if (this.changesOnSubscription) {
      this.changesOnSubscription.unsubscribe();
      this.changesOnSubscription = undefined;
    }
  }
}
