import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { stateEntities } from '../../domains/locations/state/state.state';

export type StateMatchesStateCodeValidator = (stateCode?: string, stateOrProvince?: string) => AsyncValidatorFn;

export const stateMatchesStateCode =
  (store: Store): StateMatchesStateCodeValidator =>
  (stateCode = 'stateCode', stateOrProvince = 'stateOrProvince'): AsyncValidatorFn =>
  (target: AbstractControl): Observable<ValidationErrors | null> =>
    of(target.parent).pipe(
      withLatestFrom(store.select(stateEntities)),
      map(([form, states]) => ({
        state: states[form?.get(stateCode)?.value],
        form,
      })),
      tap(({ state, form }) => state && form?.get(stateOrProvince)?.setValue(state.stateOrProvince)),
      map(v => null),
      // map(({ state, form }) => state.stateOrProvince === form.get(stateOrProvince).value),
      // map((isValid) => isValid ? null : { stateMatchesStateCode: `State name does not match state code` })
    );
