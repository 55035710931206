import { Pipe, PipeTransform } from '@angular/core';
import { ChangeOrder, ChangeOrderStatus } from '../../domains/change-orders/change-order/change-order.model';

@Pipe({
  name: 'isChangeOrderStatus'
})
export class IsChangeOrderStatusPipe implements PipeTransform {

  transform(changeOrder: ChangeOrder, ...changeOrderStatus: ChangeOrderStatus[]): boolean {
    return changeOrderStatus.includes(changeOrder.status);
  }

}
