<div class="modal-main-content">
  <ng-container [ngSwitch]="selectedArea">
    <gc-overview-area
      *ngSwitchCase="'overview'"
      [kudosReasonsDetails]="context.kudosReasonsDetails"
      [hammerRatingsDetails]="context.hammerRatingDetails"
      [productInstallationSubtypes]="context.productInstallationSubtypesClaimed"
      [installer]="context.installer"
      [canEditProfile]="context.canEditProfile"
      (subtypeRemoved)="subtypeRemoved.emit($event)"
      (subtypeAdded)="subtypeAddedFromOverview.emit($event)"
    >
    </gc-overview-area>
    <gc-background-area *ngSwitchCase="'background'" [canEditProfile]="context.canEditProfile"></gc-background-area>
    <gc-compliance-area
      *ngSwitchCase="'compliance'"
      [complianceRecords]="context.complianceRecords"
    ></gc-compliance-area>
    <gc-training-and-education-area
      *ngSwitchCase="'training'"
      [canEditProfile]="context.canEditProfile"
      (installTypeAreaTabSelected)="educationAreaSelected.emit($event)"
      (installTypeClaimAdded)="installTypeClaimAdded.emit()"
      (installTypeClaimRemoved)="installTypeClaimRemoved.emit($event)"
      (trainingClaimAdded)="trainingClaimAdded.emit($event)"
      (trainingClaimRemoved)="trainingClaimRemoved.emit($event)"
      (subtypeAdded)="subtypeAddedFromEducationAndTraining.emit($event)"
      (subtypeRemoved)="subtypeRemoved.emit($event)"
    ></gc-training-and-education-area>
    <gc-details-area *ngSwitchCase="'details'"></gc-details-area>
  </ng-container>
</div>
