import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { ProductInstallationType } from './product-installation-types.model';
import { currentProductInstallationTypeTitle } from './product-installation-types.selectors';
import { ProductInstallationTypeFacadeBase } from './product-installation-types.state';

@Injectable()
export class ProductInstallationTypeFacade extends ProductInstallationTypeFacadeBase {
  currentTitle$ = this.store.select(currentProductInstallationTypeTitle);

  constructor(private store: Store<AppState>) {
    super(ProductInstallationType, store);
  }
}
