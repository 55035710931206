import { Position } from '@capacitor/geolocation';
import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { geoLocationUpdated, viewedAnnouncement } from './app.actions';

// Named to prevent conflicts with root app state
export interface AppStateSlice {
  viewedAnnouncementForVersion: number;
  position?: Position;
}

export const initialAppStateSlice: AppStateSlice = {
  viewedAnnouncementForVersion: environment.announcementConfig.version,
};

const reduce = createReducer(
  initialAppStateSlice,
  on(
    viewedAnnouncement,
    (state): AppStateSlice => ({ ...state, viewedAnnouncementForVersion: environment.announcementConfig.version }),
  ),
  on(geoLocationUpdated, (state, { position }): AppStateSlice => ({ ...state, position: {
    ...position,
    } })),
);

export function appReducer(state = initialAppStateSlice, action: Action) {
  return reduce(state, action);
}

export const appState = createFeatureSelector<AppStateSlice>('app');

export const viewedAnnouncementForVersion = createSelector(appState, state => state.viewedAnnouncementForVersion);
export const currentPosition = createSelector(appState, state => state.position);
