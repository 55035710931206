import { Component, Input } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { WorkOrderNegotiationItem } from '~gc/domains/negotiations/models/work-order-negotiation-item.model';
import { NegotiationItemForm } from '../negotiation.form';

@Component({
  selector: 'gc-bid-ask-negotiation-item',
  templateUrl: './bid-ask-negotiation-item.component.html',
  styleUrls: ['./bid-ask-negotiation-item.component.scss'],
})
export class BidAskNegotiationItemComponent {
  @Input() role!: string;
  @Input() item!: WorkOrderNegotiationItem;
  @Input() form?: NegotiationItemForm;

  constructor(private readonly alerts: AlertController) {}

  async accept() {
    const alert = await this.alerts.create({
      message: 'Are you sure you wish to accept this? This cannot be undone.',
      buttons: [
        {
          text: 'Yes',
          role: 'ok',
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    if (role === 'ok') {
      this.form?.accept();
    }
  }
}
