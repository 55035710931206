import { Component, Input, OnDestroy } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { foundUserInfo, inviteProcessing } from '../../../core/invites';
import { UserQuery } from '../../../core/invites/models/user-query.model';
import { AppState } from '../../../domains/state';
import { InvitedUser, UserType } from '../../../domains/users';
import { onlyExisting } from '../../../shared/utils/rxjs';
import { InviteForm } from '../invite.form';
import {
  companyInviteModalCanceled,
  companyInviteModalSubmitted,
  emailAddressFieldChanged,
} from './company-invite.actions';
import { inviteViewModel } from './company-invite.selectors';

@Component({
  selector: 'gc-company-invite',
  templateUrl: './company-invite.modal.html',
  styleUrls: ['./company-invite.modal.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CompanyInviteModal implements OnDestroy {
  @Input() type!: UserType;
  @Input() typeDisplay!: string;

  faSpinner = faSpinner;

  vm$ = this.store.select(inviteViewModel).pipe(onlyExisting());
  processing$ = this.store.select(inviteProcessing);

  form = new InviteForm({
    emailChanges: {
      next: email => this.store.dispatch(emailAddressFieldChanged({ email, userType: this.type })),
    },
    listener: this.store.select(foundUserInfo),
  });

  constructor(
    private readonly store: Store<AppState>,
    public readonly modalGC: ModalController,
  ) {}

  ngOnDestroy() {
    this.form.destroy();
  }

  cancel() {
    this.store.dispatch(companyInviteModalCanceled());
  }

  submit({ invite, user }: { invite: Partial<InvitedUser>; user?: UserQuery }) {
    this.store.dispatch(companyInviteModalSubmitted({ invite, user }));
  }
}
