import { Component } from '@angular/core';
import { IonPopover, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Training } from '../../domains/training/training/training.model';
import { createDatePickerTime } from '../../shared/utils/utils';
import { claimDateChosen, claimTrainingCancelled, trainingClaiming } from './claim-training-modal.actions';
import { claimTrainingContext } from './claim-training-modal.selectors';

@Component({
  selector: 'gc-claim-training-modal',
  templateUrl: './claim-training-modal.component.html',
  styleUrls: ['./claim-training-modal.component.scss'],
})
export class ClaimTrainingModalComponent {
  context$ = this.store.select(claimTrainingContext);

  today = createDatePickerTime();

  constructor(
    public readonly modalGC: ModalController,
    private readonly store: Store,
  ) {}

  async addTraining(popoverGC: IonPopover, date: CustomEvent) {
    await popoverGC.dismiss(undefined, 'ok');
    this.store.dispatch(claimDateChosen({ date: date.detail.value }));
  }

  adding(training: Training): void {
    this.store.dispatch(trainingClaiming({ training }));
  }

  cancelled(event: any): void {
    if (event.detail.role === 'backdrop') {
      this.store.dispatch(claimTrainingCancelled());
    }
  }
}
