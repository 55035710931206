import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { WorkOrderDraft } from './work-order-draft.model';
import { WorkOrderDraftFacadeBase } from './work-order-draft.state';

@Injectable()
export class WorkOrderDraftFacade extends WorkOrderDraftFacadeBase {
  constructor(private store: Store<AppState>) {
    super(WorkOrderDraft, store);
  }
}
