import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { authenticatedUser } from '../../domains/app/auth/auth.selectors';
import {
  createProductInstallationSubtypeClaim,
  deleteProductInstallationSubtypeClaim
} from '../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.state';
import { ProductInstallationSubtype } from '../../domains/training/product-installation-subtypes/product-installation-subtypes.model';
import {
  ProductInstallationTypeClaimed
} from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { deleteTrainingClaimed } from '../../domains/training/trainings-claimed/trainings-claimed.state';
import { User } from '../../domains/users';
import { ensureChildrenRequired } from '../../shared/utils/rxjs';
import { WithId } from '../../shared/utils/types';
import { ProductInstallationSubtypesUiService } from './product-installation-subtypes-ui.service';
import {
  claimProductInstallationSubtypeModalCancelled,
  claimProductInstallationSubtypeModalDone,
  showClaimSubtypesModal,
  subtypeClaimed, subtypeClaimRemoved,
} from './product-installation-subtypes.actions';
import { trainingClaimRemoved } from './trainings.actions';

@Injectable()
export class ProductInstallationSubtypesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly ui: ProductInstallationSubtypesUiService,
  ) {}

  claimProductInstallationSubtype$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subtypeClaimed),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ productInstallationSubtype }, user]) => ({
        productInstallationSubtype,
        user,
      })),
      ensureChildrenRequired<
        { productInstallationSubtype: ProductInstallationSubtype; user?: User },
        { productInstallationSubtype: WithId<ProductInstallationSubtype>; user: WithId<User> }
      >(value => !!value?.productInstallationSubtype && !!value.user),
      map(({ productInstallationSubtype, user }) =>
        createProductInstallationSubtypeClaim({
          entity: {
            userId: user.id,
            productInstallationSubtypeId: productInstallationSubtype.id,
            productInstallationTypeId: productInstallationSubtype.productInstallationTypeId,
          },
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
            },
          },
        }),
      ),
    ),
  );

  removeClaimedSubtype$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subtypeClaimRemoved),
      switchMap(({ subtypeClaim }) =>
        this.ui.confirmRemoveSubtypeClaim().pipe(
          filter(({ role }) => role === 'ok'),
          map(() =>
            deleteProductInstallationSubtypeClaim({
              entity: subtypeClaim,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: subtypeClaim.userId,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  showClaimSubtypesModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showClaimSubtypesModal),
      exhaustMap(() =>
        this.ui
          .showClaimSubtypesModal()
          .pipe(
            map(({ role }) => (role === 'ok' ? claimProductInstallationSubtypeModalDone() : claimProductInstallationSubtypeModalCancelled())),
          ),
      ),
    ),
  );
}
