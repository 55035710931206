import { isAfter, isBefore } from 'date-fns';
import { compose } from '../../../shared/utils/compose.util';
import { mapValue, tapInto } from '../../../shared/utils/func/general';

import { Claims } from './claims.model';

export interface Evaluation<T> {
  isValid?: boolean;
  errors?: string[];
  value: T;
}

export const evaluate =
  <T>(checks: Array<(evaluation: Evaluation<T>) => boolean>) =>
  (value: T): Evaluation<T> =>
    checks.reduce((evaluation, condition: (evaluation: Evaluation<T>) => boolean) => check(condition)(evaluation), {
      value,
      isValid: true,
    } as Evaluation<T>);

export const check =
  <T>(condition: (evaluation: Evaluation<T>) => boolean) =>
  (evaluation: Evaluation<T>): Evaluation<T> =>
    compose(condition, result => ({
      value: evaluation.value,
      isValid: result && evaluation.isValid,
      errors: result ? evaluation.errors : [...(evaluation.errors ?? []), `${condition.name} failed`],
    }))(evaluation);

export const hasSubject = (evaluation: Evaluation<Claims>) => !!evaluation.value.sub;
export const hasCorrectIssuer = (evaluation: Evaluation<Claims>) =>
  evaluation.value.iss ? evaluation.value.iss === '' : true; // TODO: Figure out how to get from config
export const hasCorrectAudience = (evaluation: Evaluation<Claims>) =>
  evaluation.value.aud ? evaluation.value.aud === '' : true; // TODO: Figure out how to get from config
export const isNotExpired = (evaluation: Evaluation<Claims>) =>
  evaluation.value.exp ? isAfter(evaluation.value.exp * 1000, Date.now()) : true;
export const isNotBefore = (evaluation: Evaluation<Claims>) =>
  evaluation.value.nbf ? isBefore(evaluation.value.nbf * 1000, Date.now()) : true;

export const validateAccessToken = compose(
  evaluate([isNotExpired, isNotBefore, /*hasCorrectIssuer, hasCorrectAudience,*/ hasSubject]),
  tapInto(result => (result.errors?.length ? console.error(result.errors) : void 0)),
  mapValue(result => result?.isValid ?? true),
);
