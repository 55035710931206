import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { onlyRoles } from '../../../shared/utils/roles.operators';
import { ensureRequired } from '../../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedRole } from '../../app/auth/auth.selectors';
import { EmptyKey } from '../../entity.service.utils';
import { AppState } from '../../state';
import { Company } from '../../users';
import { currentWorkOrder, editWorkOrder } from '../../work/work-order/work-order.state';
import { workOrdersRouteVisit } from '../../work/work.actions';
import { invoiceSelected } from '../invoice/invoices.state';
import {
  loadAllLienReleaseTemplates,
  loadLienReleaseTemplate,
  selectLienReleaseTemplateByKey,
} from './lien-release-template.state';

@Injectable()
export class LienReleaseTemplateEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadLienReleaseTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoiceSelected),
      onlyRoles(this.store.select(authenticatedRole), 'installerLead', 'companyAdmin', 'companyManager'),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureRequired(workOrder => !!workOrder?.lienReleaseTemplateId),
      switchMap(workOrder => [
        loadLienReleaseTemplate({
          keys: workOrder.lienReleaseTemplateId,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(Company)]: EmptyKey,
            },
          },
        }),
        selectLienReleaseTemplateByKey({ key: workOrder.lienReleaseTemplateId }),
      ]),
    ),
  );

  loadLienReleaseTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrdersRouteVisit, editWorkOrder),
      onlyRoles(this.store.select(authenticatedRole), 'installerLead', 'companyAdmin', 'companyManager'),
      map(action =>
        loadAllLienReleaseTemplates({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(Company)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );
}
