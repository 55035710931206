<ion-list>
  <ng-template #multiList>
    <ion-item *ngFor="let installer of installers!" (click)="select(installer)">
      <ion-avatar slot="start">
        <img [src]="installer.item?.profilePhoto" alt="profile" />
        <fa-icon
          *ngIf="$any(installer.item).source === 'COMPANY_PRIVATE'"
          data-cy="private-connection-badge"
          class="private-network-user-icon"
          [icon]="faLock"
        ></fa-icon>
      </ion-avatar>
      <ion-label>{{ installer.item?.firstName }} {{ installer.item?.lastName }} </ion-label>
      <div class="flex justify-content-between" *ngIf="installer.item | isInstallerTeamLead">
        <div>
          <ion-badge data-cy="work-order-publish-itl-hammer-rating" color="light" mode="ios">
            <ion-icon src="/assets/images/icon-hammer.svg"></ion-icon>
            {{ (installer.item?.hammerRatings | matchingHammerRating: workOrder) ?? '0.0' }}
          </ion-badge>
          <ion-badge data-cy="work-order-publish-itl-kudos" color="light" mode="ios">
            <ion-icon name="thumbs-up"></ion-icon>
            {{ installer.item?.kudos }}
          </ion-badge>
        </div>
        <div>
          <ion-badge *ngIf="installer.item.onVacationSince" id="badge_vacation" color="warning" mode="ios">
            <fa-icon [icon]="faSun"></fa-icon>
            Vacation
          </ion-badge>
          <ion-badge
            *ngIf="$any(installer.item).source === 'COMPANY_PRIVATE'"
            data-cy="work-order-publish-itl-private-status"
            mode="ios"
            id="badge_private"
          >
            <fa-icon data-cy="private-connection-badge" [icon]="faLock"></fa-icon>
            Private
          </ion-badge>
        </div>
      </div>
      <ion-checkbox slot="end" [checked]="installer.selected" data-cy="work-order-publish-itl-checkbox">&nbsp;</ion-checkbox>
    </ion-item>
  </ng-template>
  <ion-radio-group *ngIf="!multi; else multiList">
    <ion-item *ngFor="let installer of installers!" (click)="select(installer)">
      <ion-avatar slot="start">
        <img [src]="installer.item?.profilePhoto" alt="profile" />
        <fa-icon
          *ngIf="$any(installer.item).source === 'COMPANY_PRIVATE'"
          data-cy="private-connection-badge"
          class="private-network-user-icon"
          [icon]="faLock"
        ></fa-icon>
      </ion-avatar>
      <ion-radio [value]="installer.item.id" justify="space-between">
        {{ installer.item?.firstName }} {{ installer.item?.lastName }}
        <ion-badge color="light" mode="ios">
          <ion-icon src="/assets/images/icon-hammer.svg"></ion-icon>
          {{ (installer.item?.hammerRatings | matchingHammerRating: workOrder) ?? '0.0' }}
        </ion-badge>
      </ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-list>
