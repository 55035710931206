<div class="profile-content-section" data-cy="hammer-rating-charts-area">
  <h2 data-cy="hammer-rating-charts-title">Hammer Ratings</h2>

  <ng-container *ngIf="(hammerRatingCount$ | push)! > 0; else noHammerRatings">
    <div class="chart-container">
      <div class="chart-item" *ngFor="let chart of hammerRatingsDetails; index as i">
        <div
          class="hammer-rating-chart"
          id="hammerRatingChart{{ i }}"
          [attr.data-cy]="chart.category + '-hammer-rating-chart'"
        ></div>
      </div>
    </div>
  </ng-container>

  <ng-template #noHammerRatings>
    <div class="alert alert__warning text-center" data-cy="empty-hammer-rating-charts">
      No Hammer Ratings found for this installer.
    </div>
  </ng-template>
</div>
