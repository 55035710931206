import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ConfigService } from '../shared/services/config.service';
import { GenericFile } from './models/generic-file';
import { PendingFile } from './models/pending-file';
import { ProductInstallationTypeClaimed } from './training/product-installation-types-claimed/product-installation-types-claimed.model';
import { TrainingFile } from './training/training-files/training-file.model';
import { User } from './users';
import { UserFile } from './users/user-files/user-file.model';

@Injectable()
export class FileUploadService {
  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
  ) {}

  // uploadWorkOrderFile(workOrder: Partial<WorkOrder>, pendingFile: PendingFile): Observable<WorkOrderFile> {
  //   return this.http
  //     .post<WorkOrderFile>(`${this.config.host}/work-orders/${workOrder.id}/uploads`, {
  //       filename: pendingFile.filename,
  //       contentType: pendingFile.contentType,
  //     })
  //     .pipe(
  //       switchMap(file =>
  //         this.http.put(file.uploadUrl, pendingFile.fileBlob).pipe(
  //           map(() => ({ ...file, isUploaded: true })),
  //           switchMap(uploadedFile =>
  //             this.http
  //               .put(`${this.config.host}/work-orders/${workOrder.id}/files/${file.id}`, uploadedFile)
  //               .pipe(map(() => uploadedFile))
  //           )
  //         )
  //       )
  //     );
  // }

  uploadTrainingFile(
    user: User,
    claim: ProductInstallationTypeClaimed,
    pendingFile: PendingFile,
  ): Observable<TrainingFile> {
    return this.http
      .post<TrainingFile>(
        `${this.config.host}/users/${user.id}/product-installation-types-claimed/${claim.id}/files/upload`,
        {
          filename: pendingFile.filename,
          contentType: pendingFile.contentType,
        },
      )
      .pipe(
        switchMap(file =>
          !!file.uploadUrl
            ? this.http.put(file.uploadUrl, pendingFile.fileBlob).pipe(
                map(() => ({ ...file, isUploaded: true })),
                switchMap(uploadedFile =>
                  this.http
                    .put(
                      `${this.config.host}/users/${user.id}/product-installation-types-claimed/${claim.id}/files/${file.id}`,
                      uploadedFile,
                    )
                    .pipe(map(() => uploadedFile)),
                ),
              )
            : throwError(() => new Error('No upload URL specified on file')),
        ),
      );
  }

  uploadProfileFile(user: User, pendingFile: PendingFile): Observable<UserFile> {
    return this.http
      .post<UserFile>(`${this.config.host}/installers/${user.id}/files/upload`, {
        type: pendingFile.type,
        filename: pendingFile.filename,
        contentType: pendingFile.contentType,
      })
      .pipe(
        switchMap(file =>
          !!file.uploadUrl
            ? this.http.put(file.uploadUrl, pendingFile.fileBlob).pipe(
                map(() => ({ ...file, isUploaded: true })),
                switchMap(uploadedFile =>
                  this.http
                    .put(`${this.config.host}/installers/${user.id}/files/${file.id}`, uploadedFile)
                    .pipe(map(() => uploadedFile)),
                ),
              )
            : throwError(() => new Error('No upload URL specified on file')),
        ),
      );
  }

  uploadProfilePhoto(user: User, pendingFile: PendingFile): Observable<GenericFile> {
    return this.http
      .post<GenericFile>(`${this.config.host}/users/${user.id}/profiles/uploads`, {
        filename: pendingFile.filename,
        contentType: pendingFile.contentType,
      })
      .pipe(
        switchMap(file =>
          !!file.uploadUrl
            ? this.http.put(file.uploadUrl, pendingFile.fileBlob).pipe(
                map(() => ({
                  ...file,
                  isUploaded: true,
                })),
              )
            : throwError(() => new Error('No upload URL specified on file')),
        ),
      );
  }
}
