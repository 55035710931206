import { createReducer, on } from '@ngrx/store';
import { inviteProcessAborted, inviteProcessCompleted, inviteProcessInitiated } from '../invites.actions';

export interface InvitesState {
  processing: boolean;
}

export const initialInvitesState: InvitesState = {
  processing: false,
};

export const toggleFlag =
  <T>(flag: keyof T) =>
  (state: T): T => ({ ...state, [flag]: !state[flag] });
export const setFlag =
  <T>(flag: keyof T) =>
  (state: T): T => ({ ...state, [flag]: true });
export const clearFlag =
  <T>(flag: keyof T) =>
  (state: T): T => ({ ...state, [flag]: false });

export const invitesReducer = createReducer(
  initialInvitesState,
  on(inviteProcessInitiated, setFlag('processing')),
  on(inviteProcessCompleted, inviteProcessAborted, clearFlag('processing')),
);
