import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Store } from '@ngrx/store';
import { PendingFile } from '../../models/pending-file';
import { WorkOrder } from '../../work/work-order/work-order.model';
import { Invoice } from '../invoice/invoice.model';
import { queueInvoiceFile, removeQueuedInvoiceFile } from './invoice-file.actions';
import { InvoiceFile } from './invoice-file.model';
import {
  deleteInvoiceFileUpload,
  InvoiceFilesFacadeBase,
  pendingInvoiceFiles,
  queuedProcessingInvoiceFiles,
  uploadInvoiceFile,
  uploadInvoiceFileRetry,
} from './invoice-files.state';

@Injectable()
export class InvoiceFilesFacade extends InvoiceFilesFacadeBase {
  allInvoiceFiles$ = this.store.select(pendingInvoiceFiles);
  queuedInvoiceFiles$ = this.store.select(queuedProcessingInvoiceFiles);

  constructor(private store: Store) {
    super(InvoiceFile, store);
  }

  queue(pendingFile: PendingFile) {
    this.store.dispatch(queueInvoiceFile({ pendingFile }));
  }

  removeQueuedFile(pendingFile: PendingFile) {
    this.store.dispatch(removeQueuedInvoiceFile({ pendingFile }));
  }

  upload(pendingFile: PendingFile, workOrder: Partial<WorkOrder>, invoice: Invoice): void {
    this.store.dispatch(
      uploadInvoiceFile({
        pendingFile,
        criteria: {
          parents: {
            [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            [uriNameOfEntityOrEmpty(Invoice)]: invoice.id,
          },
        },
      }),
    );
  }

  retry(pendingFile: PendingFile, workOrder: Partial<WorkOrder>, invoice: Invoice): void {
    this.store.dispatch(
      uploadInvoiceFileRetry({
        pendingFile,
        criteria: {
          parents: {
            [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            [uriNameOfEntityOrEmpty(Invoice)]: invoice.id,
          },
        },
      }),
    );
  }

  remove(file: PendingFile, workOrder: Partial<WorkOrder>, invoice: Invoice): void {
    this.store.dispatch(
      deleteInvoiceFileUpload({
        file,
        criteria: {
          parents: {
            [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            [uriNameOfEntityOrEmpty(Invoice)]: invoice.id,
          },
        },
      }),
    );
  }
}
