export function view<S1, Result>(name: string, projector: (s1: S1) => Result, enabled?: boolean): (s1: S1) => Result;
export function view<S1, S2, Result>(
  name: string,
  projector: (s1: S1, s2: S2) => Result,
  enabled?: boolean,
  logParams?: boolean,
): (s1: S1, s2: S2) => Result;
export function view<S1, S2, S3, Result>(
  name: string,
  projector: (s1: S1, s2: S2, s3: S3) => Result,
  enabled?: boolean,
  logParams?: boolean,
): (s1: S1, s2: S2, s3: S3) => Result;
export function view<S1, S2, S3, S4, Result>(
  name: string,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4) => Result,
  enabled?: boolean,
  logParams?: boolean,
): (s1: S1, s2: S2, s3: S3, s4: S4) => Result;
export function view<S1, S2, S3, S4, S5, Result>(
  name: string,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5) => Result,
  enabled?: boolean,
  logParams?: boolean,
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5) => Result;
export function view<S1, S2, S3, S4, S5, S6, Result>(
  name: string,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6) => Result,
  enabled?: boolean,
  logParams?: boolean,
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6) => Result;
export function view<S1, S2, S3, S4, S5, S6, S7, Result>(
  name: string,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7) => Result,
  enabled?: boolean,
  logParams?: boolean,
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7) => Result;
export function view<S1, S2, S3, S4, S5, S6, S7, S8, Result>(
  name: string,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result,
  enabled?: boolean,
  logParams?: boolean,
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result;
export function view<S1, S2, S3, S4, S5, S6, S7, S8, S9, Result>(
  name: string,
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8, s9: S9) => Result,
  enabled?: boolean,
  logParams?: boolean,
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8, s9: S9) => Result;

export function view(name: string, projector: (...params: unknown[]) => any, enabled = true, logParams = false) {
  // tslint:disable-next-line:only-arrow-functions
  return enabled
    ? function (...params: unknown[]) {
        if (logParams) {
          console.log(`${name}:params`, params);
        }
        const result = projector(...params);
        console.log(name, result);
        return result;
      }
    : projector;
}
