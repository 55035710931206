import { Injectable } from '@angular/core';
import { AlertController, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { BasicProfileModalComponent } from '../../../modals/basic-profile/basic-profile-modal/basic-profile-modal.component';
import { InstallerProfileModalComponent } from '../../../modals/installer-profile/installer-profile-modal/installer-profile-modal.component';
import { PaymentProviderModalComponent } from '../../../modals/payment-provider-modal/payment-provider-modal.component';
import { UnapprovedInstallerComponent } from '../../../modals/unapproved-installer/unapproved-installer.component';
import { ModalService } from '../../../shared/services/modal.service';
import { RouterOutletService } from '../../router-outlet.service';
import { InstallerTeamLead, isInstallerLead, User } from '../user/user.model';

@Injectable()
export class ProfilesUIService {
  private editProfileModal?: HTMLIonModalElement;
  private unapprovedModal?: HTMLIonModalElement;

  constructor(
    private readonly alerts: AlertController,
    private readonly toasts: ToastController,
    private readonly modals: ModalController,
    private modalService: ModalService,
    private readonly popovers: PopoverController,
    private readonly routerOutletService: RouterOutletService,
  ) {}

  async showUnapprovedInstallerModal(user: InstallerTeamLead) {
    this.unapprovedModal = await this.modalService.openSheetModal(UnapprovedInstallerComponent, {
      componentProps: {
        user,
      },
      backdropDismiss: false,
      backdropBreakpoint: 0.3,
      breakpoints: [0.5, 0.8, 1],
      initialBreakpoint: 0.8,
    });

    return this.unapprovedModal.onWillDismiss();
  }

  dismissUnapprovedInstallerModal() {
    this.unapprovedModal?.dismiss();
  }

  async confirmEditCancellation(): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header: 'Discard Changes?',
      message: 'Are you sure you wish to discard your changes? This cannot be undone!',
      buttons: [
        {
          text: 'Yes',
          role: 'ok',
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async notifySuccess(): Promise<void> {
    const alert = await this.alerts.create({
      header: 'Profile Information Saved',
      message: "We've successfully updated your profile.",
      buttons: [
        {
          text: 'OK',
          role: 'ok',
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async notifyFailure(): Promise<void> {
    const alert = await this.alerts.create({
      header: 'Error Saving Profile',
      message:
        "We've encountered an error saving your profile. Verify your information is valid and try again. If the error persists, contact support@gocarrera.com.",
      buttons: [
        {
          text: 'OK',
          role: 'ok',
        },
      ],
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async showEditProfileModal(user: User, showEditPhoto?: boolean): Promise<void> {
    if (this.editProfileModal) {
      return;
    }

    this.editProfileModal = await this.modals.create({
      component: isInstallerLead(user) ? InstallerProfileModalComponent : BasicProfileModalComponent,
      cssClass: 'rounded-modal',
      canDismiss: true,
      backdropDismiss: false,
      componentProps: {
        step: showEditPhoto ? (isInstallerLead(user) ? 5 : 3) : 1,
      },
    });
    await this.editProfileModal.present();
    await this.editProfileModal.onDidDismiss().then(() => {
      this.editProfileModal = undefined;
    });
  }

  async dismissEditProfileModal(role: string) {
    if (!this.editProfileModal) {
      return;
    }
    await this.editProfileModal.dismiss(null, role);
  }

  async toastStripeError(): Promise<void> {
    const toast = await this.toasts.create({
      message: 'Error Fetching Stripe Account',
      buttons: [
        {
          text: 'Dismiss',
          side: 'end',
        },
      ],
      color: 'danger',
      duration: 5000,
      position: 'bottom',
    });

    await toast.present();
  }

  async viewPaymentProvider() {
    const viewPaymentProviderModal = await this.modals.create({
      component: PaymentProviderModalComponent,
      componentProps: {
        imageSrc: 'assets/images/logos/go-carrera/go-carrera-pay-color.svg',
      },
      initialBreakpoint: 0.5,
      breakpoints: [0, 0.5],
    });
    await viewPaymentProviderModal.present();
    return viewPaymentProviderModal.onDidDismiss();
  }
}
