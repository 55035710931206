import { Action, createReducer, on } from '@ngrx/store';
import { DetailedInstaller } from '../../models/detailed-installer';
import { reviewJumpstarterHireOffer } from '../../users/jumpstarter-favorites/jumpstarter-favorites.actions';
import { deselectCurrentInstaller, installerReviewCompleted, reviewInstaller } from './installer-review.actions';

export interface InstallerReviewState {
  currentInstaller?: DetailedInstaller;
}

export const initialInstallerReviewState: InstallerReviewState = {};

export const setCurrentInstaller = (
  state: InstallerReviewState,
  {
    installer,
  }: {
    installer: DetailedInstaller;
  },
): InstallerReviewState => ({
  ...state,
  currentInstaller: installer,
});

export const clearCurrentInstaller = (state: InstallerReviewState): InstallerReviewState => ({
  ...state,
  currentInstaller: undefined,
});

const reduce = createReducer(
  initialInstallerReviewState,
  on(reviewInstaller, reviewJumpstarterHireOffer, setCurrentInstaller),
  on(deselectCurrentInstaller, installerReviewCompleted, clearCurrentInstaller),
);

export function installerReviewReducer(state = initialInstallerReviewState, action: Action): InstallerReviewState {
  return reduce(state, action);
}
