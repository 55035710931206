import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { appInitialize } from '~gc/domains/app/app.actions';
import { authenticatedUserMetadataRetrieved, authSubjectChanged } from '../../../domains/app/auth/auth.actions';
import { StorageRefService } from '../../../shared/services/storage-ref.service';
import { calendarFilterOptionsChanged, calendarFlterOptionsRestored } from './filters.actions';
import { filterState } from './filters.selectors';

const CALENDAR_FILTER_OPTIONS_STORAGE_KEY = 'gc__calendar_filter_options';

@Injectable()
export class FiltersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly storageRef: StorageRefService,
  ) {}

  clearSavedOptions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authSubjectChanged),
        switchMap(() => this.storageRef.storage()),
        tap(storage => storage.remove(CALENDAR_FILTER_OPTIONS_STORAGE_KEY)),
      ),
    { dispatch: false },
  );

  restoreFilterOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticatedUserMetadataRetrieved),
      delay(10),
      switchMap(() => this.storageRef.storage()),
      switchMap(storage => storage.get(CALENDAR_FILTER_OPTIONS_STORAGE_KEY)),
      // filter((raw: string | null): raw is string => !!raw),
      // map(raw => JSON.parse(raw)),
      filter(restored => !!restored),
      map(restored => calendarFlterOptionsRestored({ restored })),
    ),
  );

  saveFilterOptions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(calendarFilterOptionsChanged),
        withLatestFrom(this.store.select(filterState)),
        switchMap(([, state]) => this.storageRef.storage().then(storage => [storage, state] as const)),
        tap(([storage, state]) => storage.set(CALENDAR_FILTER_OPTIONS_STORAGE_KEY, state)),
      ),
    { dispatch: false },
  );
}
