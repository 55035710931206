import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Connection } from '~gc/domains/users/connection/connection.model';
import { AppState } from '../state';
import { Company } from './companies/company.model';
import {
  companyInvitedToConnections,
  confirmNetworkConnectionDeclined,
  networkConnectionSelected,
} from './network.actions';
import {
  enrichedCompanyConnections,
  filteredConnectionRequests,
  pendingConnectionRequest,
  receivedConnectionRequests,
  sentConnectionRequests,
} from './network.selectors';

@Injectable()
export class NetworkFacade {
  allDisplayConnections$ = this.store.select(filteredConnectionRequests);
  enrichedCompanyConnections$ = this.store.select(enrichedCompanyConnections);
  sentConnectionRequests$ = this.store.select(sentConnectionRequests);
  receivedConnectionRequests$ = this.store.select(receivedConnectionRequests);
  pendingConnectionRequest$ = this.store.select(pendingConnectionRequest);

  constructor(private store: Store<AppState>) {}

  inviteCompanyToConnections(company: Company) {
    this.store.dispatch(companyInvitedToConnections({ company }));
  }

  acceptRequest(connection: Connection) {
    this.store.dispatch(networkConnectionSelected({ connection }));
  }

  declineRequest(connection: Connection, received: boolean) {
    this.store.dispatch(confirmNetworkConnectionDeclined({ connection, received }));
  }
}
