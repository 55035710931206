import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { interval } from 'rxjs';
import { debounceTime, exhaustMap, map, takeUntil } from 'rxjs/operators';
import { routeEndsInPath, routeNotEndsInPath } from '../../shared/utils/ngrx';
import { selectWorkOrderById } from '../work/work-order/work-order.state';
import { workRefreshIntervalTicked } from '../work/work-polling.actions';
import { workOrderCustomDatesIntervalTicked } from './custom-date-polling.actions';

const POLLING_PERIOD = 20000;

@Injectable()
export class CustomDatePollingEffects {
  constructor(private readonly actions$: Actions) {}

  loadCustomDatesByWorkOrderSelection$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(selectWorkOrderById),
      debounceTime(25),
      exhaustMap(({ entityKey }) =>
        interval(POLLING_PERIOD).pipe(
          map(() => workOrderCustomDatesIntervalTicked({ entityKey: entityKey as string })),
          // eslint-disable-next-line @ngrx/avoid-cyclic-effects
          takeUntil(this.actions$.pipe(ofType(routerNavigationAction), routeNotEndsInPath('/work/orders/detail'))),
        ),
      ),
    ),
  );
}
