import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { delay, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { routeStartsWithPath } from '../../../shared/utils/ngrx';
import { onlyRoles } from '../../../shared/utils/roles.operators';
import { ensureChildrenRequired, ensureRequired } from '../../../shared/utils/rxjs';
import { AppUIService } from '../../app-ui.service';
import { authenticatedUserMetadataUpdated } from '../../app/auth/auth.actions';
import { authenticatedRole, authenticatedUser } from '../../app/auth/auth.selectors';
import { AppState } from '../../state';
import { User } from '../user/user.model';
import { currentUser, loadUser, loadUserSuccess } from '../user/user.state';
import { UsersUIService } from '../users-ui.service';
import { JumpstarterFavorite, TeamMatchStatus } from './jumpstarter-favorite.model';
import {
  confirmLeaveTeam,
  jumpstarterFavorited,
  jumpstarterHireOfferAccepted,
  jumpstarterHireOfferDeclined,
  jumpstarterHireOfferExtended,
  leaveTeamConfirmed,
  removeJumpstarter,
  reviewJumpstarterHireOffer,
} from './jumpstarter-favorites.actions';
import {
  jumpstarterFavoriteForCurrentUser,
  jumpstarterFavoriteForUsersTeamLead,
} from './jumpstarter-favorites.selectors';
import {
  createJumpstarterFavorite,
  deleteJumpstarterFavorite,
  deleteJumpstarterFavoriteSuccess,
  loadAllJumpstarterFavorites,
  replaceJumpstarterFavorite,
  replaceJumpstarterFavoriteSuccess,
} from './jumpstarter-favorites.state';

export const JUMPSTARTER_HIRE_STATUS_UPDATE_CORRELATION = 'JumpstarterHireStatusUpdateCorrelation';

@Injectable()
export class JumpstarterFavoriteEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly usersUI: UsersUIService,
    private readonly appUI: AppUIService,
  ) {}

  listenForRouteVisit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeStartsWithPath('/app/team'),
      onlyRoles(this.store.select(authenticatedRole), 'installerLead', 'jumpstarter'),
      map(() => loadAllJumpstarterFavorites()),
    ),
  );

  favoriteJumpstarter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(jumpstarterFavorited),
      filter(({ favorite }) => !favorite),
      withLatestFrom(this.store.select(authenticatedUser), this.store.select(currentUser)),
      map(([, authUser, currUser]) => ({ authUser, currUser })),
      ensureChildrenRequired(value => !!value?.authUser?.id && !!value.currUser?.id),
      map(({ authUser, currUser }) =>
        createJumpstarterFavorite({
          entity: {
            jumpstarterId: currUser?.id,
            installerTeamLeadId: authUser?.id,
          } as JumpstarterFavorite,
        }),
      ),
    ),
  );

  unFavoriteJumpstarter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(jumpstarterFavorited),
      filter(({ favorite }) => favorite),
      withLatestFrom(this.store.select(jumpstarterFavoriteForCurrentUser)),
      map(([, favorite]) => favorite),
      ensureRequired(favorite => !!favorite),
      map(favorite => deleteJumpstarterFavorite({ entity: favorite })),
    ),
  );

  removeJumpstarter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeJumpstarter),
      map(({ favorite }) => deleteJumpstarterFavorite({ entity: favorite })),
    ),
  );

  showReviewInstaller$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reviewJumpstarterHireOffer),
        switchMap(() => this.usersUI.showReviewInstallerModal()),
      ),
    { dispatch: false },
  );

  extendOfferToHire$ = createEffect(() =>
    this.actions$.pipe(
      ofType(jumpstarterHireOfferExtended),
      withLatestFrom(
        this.store.select(authenticatedUser),
        this.store.select(currentUser),
        this.store.select(jumpstarterFavoriteForCurrentUser),
      ),
      map(([, authUser, currUser, favorite]) => ({ authUser, currUser, favorite })),
      ensureChildrenRequired<
        { authUser?: User; currUser?: User; favorite?: JumpstarterFavorite },
        { authUser: User; currUser: User; favorite?: JumpstarterFavorite }
      >(value => !!value?.authUser?.id && !!value.currUser?.id),
      map(({ authUser, currUser, favorite }) =>
        !!favorite ? { ...favorite } : { jumpstarterId: currUser.id, installerTeamLeadId: authUser.id },
      ),
      map(favorite => ({ ...favorite, addToTeamStatus: TeamMatchStatus.INVITED }) as JumpstarterFavorite),
      map(entity => (!!entity.id ? replaceJumpstarterFavorite({ entity }) : createJumpstarterFavorite({ entity }))),
    ),
  );

  acceptOrDeclineHireOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(jumpstarterHireOfferAccepted, jumpstarterHireOfferDeclined),
      switchMap(action => from(this.usersUI.closeReviewInstallerModal()).pipe(map(() => action))),
      tap(() => this.appUI.showLoading()),
      withLatestFrom(this.store.select(jumpstarterFavoriteForCurrentUser)),
      map(
        ([{ type }, favorite]) =>
          ({
            ...favorite,
            id: 0,
            addToTeamStatus:
              type === jumpstarterHireOfferAccepted.type ? TeamMatchStatus.ACCEPTED : TeamMatchStatus.DECLINED,
          }) as JumpstarterFavorite,
      ),
      map(entity => replaceJumpstarterFavorite({ entity })),
    ),
  );

  refreshUserOnAccept$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replaceJumpstarterFavoriteSuccess, deleteJumpstarterFavoriteSuccess),
      filter(({ entity }) => entity.addToTeamStatus === TeamMatchStatus.ACCEPTED),
      map(({ entity }) =>
        loadUser({ keys: entity.jumpstarterId, correlationId: JUMPSTARTER_HIRE_STATUS_UPDATE_CORRELATION }),
      ),
    ),
  );

  setRefreshedUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserSuccess),
      filter(({ correlationId }) => correlationId === JUMPSTARTER_HIRE_STATUS_UPDATE_CORRELATION),
      delay(200),
      tap(() => this.appUI.hideLoading()),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ entity }, user]) => ({ entity, user })),
      ensureChildrenRequired<{ entity?: User; user?: User }, { entity: Required<User>; user: Required<User> }>(
        value => !!value?.entity?.id && !!value.user?.id,
      ),
      filter(({ entity, user }) => entity.id === user.id),
      map(({ entity }) => authenticatedUserMetadataUpdated({ user: entity })),
    ),
  );

  confirmRequestToLeaveTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmLeaveTeam),
      // tslint:disable-next-line:quotemark
      switchMap(() => this.appUI.confirm('Confirm Leave', "Are you sure you want to leave this installer's team?")),
      filter(confirmed => confirmed),
      map(() => leaveTeamConfirmed()),
    ),
  );

  leaveJumpstarterTeam$ = createEffect(() =>
    this.actions$.pipe(
      ofType(leaveTeamConfirmed),
      withLatestFrom(this.store.select(jumpstarterFavoriteForUsersTeamLead)),
      map(([, favorite]) => favorite),
      ensureRequired(favorite => !!favorite),
      tap(() => this.appUI.showLoading()),
      map(entity => deleteJumpstarterFavorite({ entity, correlationId: JUMPSTARTER_HIRE_STATUS_UPDATE_CORRELATION })),
    ),
  );
}
