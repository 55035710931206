import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, iif, Observable, of, throwError } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { synchronizeOn } from '../utils/operators';

type UrlLocks = {
  [zipCode: string]: boolean;
};

type ZipCodeCache = {
  [zipCode: string]: HttpEvent<any>;
};

type ZipCodeErrorCache = {
  [zipCode: string]: HttpErrorResponse;
};

const DELAYS: Record<number, number> = {
  5: 10,
  10: 20,
  20: 50,
  50: 200,
  200: 1000,
};

@Injectable()
export class ZipCodeValidationCacheInterceptor implements HttpInterceptor {
  private locks: UrlLocks = {};
  private cache: ZipCodeCache = {};
  private errorCache: ZipCodeErrorCache = {};

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return iif(
      () => req.url.includes('/zip-code/'),
      of(req.url).pipe(
        synchronizeOn(
          url => this.locks[url],
          url => (this.locks[url] = true),
          delay => (delay ? DELAYS[delay] : 5),
        ),
        map(() => this.cache[req.url] || this.errorCache[req.url]),
        concatMap(cached =>
          iif(
            () => cached !== undefined,
            defer(() =>
              (cached instanceof HttpErrorResponse ? throwError(cached) : of(cached)).pipe(
                tap(
                  () => (this.locks[req.url] = false),
                  () => (this.locks[req.url] = false),
                ),
              ),
            ),
            defer(() =>
              next.handle(req).pipe(
                tap(event => (event instanceof HttpResponse ? (this.cache[req.url] = event) : void 0)),
                tap({
                  error: err => (err instanceof HttpErrorResponse ? (this.errorCache[req.url] = err) : void 0),
                }),
                tap(
                  event => (event instanceof HttpResponse ? (this.locks[req.url] = false) : void 0),
                  () => (this.locks[req.url] = false),
                ),
              ),
            ),
          ),
        ),
      ),
      next.handle(req),
    );
  }
}
