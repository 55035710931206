import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from '../../../environments/environment';

export enum LoggingLevel {
  error = 50,
  warn = 40,
  info = 30,
  debug = 20,
  trace = 10,
}

export interface TaggedMessages {
  type: string;
  messages: any[];
  tags: string[];
}

export interface TaggedMessage {
  type: string;
  message: any;
  tags: string[];
}

const APP_TAG = '[GC] [v2]';

export const formatTags = (tags: string[]): string => (tags ? tags.map(tag => `[${tag}]`).join(' ') : '');

export const formatMessage = ({ type, message, tags }: TaggedMessage): string | any =>
  typeof message === 'string'
    ? `(${new Date().toISOString()}) ${type}: ${APP_TAG} ${formatTags(tags)} ${message}`
    : message;

export const formatMessages = ({ type, messages, tags }: TaggedMessages): any[] =>
  messages.map(message => formatMessage({ type, message, tags }));

export const logSingleString = (isDevice: boolean, level: LoggingLevel, message: string): void =>
  level === LoggingLevel.error
    ? console.error(message)
    : level === LoggingLevel.warn
    ? console[isDevice ? 'log' : 'warn'](message)
    : level === LoggingLevel.info
    ? console.log(message)
    : // tslint:disable-next-line:no-console
      console[isDevice ? 'log' : 'debug'](message);

export const logSingleObject = (isDevice: boolean, level: LoggingLevel, message: any): void =>
  level === LoggingLevel.error ? console.error(message) : console[isDevice ? 'log' : 'dir'](message);

export const logSingle = (isDevice: boolean, level: LoggingLevel, message: any): void =>
  typeof message === 'string' ? logSingleString(isDevice, level, message) : logSingleObject(isDevice, level, message);

export const writeLog = (isDevice: boolean, level: LoggingLevel, messages: any[]): void | false =>
  messages && messages.length ? messages.forEach(message => logSingle(isDevice, level, message)) : false;

export const isAllowedLevel = (allowedLevel: LoggingLevel, requiredLevel: LoggingLevel): boolean =>
  allowedLevel <= requiredLevel;
export const hasMessages = (messages: any[]): boolean => !!messages && !!messages.length;
export const levelToType = (level: LoggingLevel): string => {
  switch (level) {
    case LoggingLevel.trace:
      return 'TRACE';
    case LoggingLevel.debug:
      return 'DEBUG';
    case LoggingLevel.info:
      return ' INFO';
    case LoggingLevel.warn:
      return ' WARN';
    case LoggingLevel.error:
      return 'ERROR';
  }

  return 'ERROR';
};

export const OVERRIDE_LOGGING_LEVEL = new InjectionToken('Override Logging Level');

@Injectable()
export class LogService {
  private allowedLevel = LoggingLevel.error;
  private readonly isDevice: boolean;

  constructor(
    private platform: Platform,
    @Optional() @Inject(OVERRIDE_LOGGING_LEVEL) overrideLoggingLevel: LoggingLevel,
  ) {
    this.allowedLevel = (overrideLoggingLevel || LoggingLevel[environment.logging.level || 'error']) as LoggingLevel;
    this.isDevice = platform && platform.is('cordova') && (platform.is('ios') || platform.is('android'));
    console.log(
      `${APP_TAG} [Log] Log initialized. ${
        this.isDevice ? 'Currently running on a device' : 'Currently running in a browser'
      }`,
    );
  }

  log(level: LoggingLevel, tags: string[], ...messages: any[]) {
    try {
      if (isAllowedLevel(this.allowedLevel, level) && hasMessages(messages)) {
        const type = levelToType(level);
        const formattedMessages = formatMessages({ type, messages, tags });
        writeLog(this.isDevice, level, formattedMessages);
      }
    } catch (err) {
      console.error(`${APP_TAG} [Log] Error writing log entry: `, err);
    }
  }

  trace(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.trace, tags, ...message);
  }

  debug(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.debug, tags, ...message);
  }

  info(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.info, tags, ...message);
  }

  warn(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.warn, tags, ...message);
  }

  error(tags: string[], ...message: any[]) {
    this.log(LoggingLevel.error, tags, ...message);
  }
}
