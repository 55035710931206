import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { Country } from './country.model';
import { CountryFacadeBase } from './country.state';

@Injectable()
export class CountryFacade extends CountryFacadeBase {
  constructor(private store: Store<AppState>) {
    super(Country, store);
  }
}
