<ion-row *ngFor="let i of rows">
  <ion-col *ngIf="!showDetails" size="3">
    <p>
      <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
    </p>
  </ion-col>

  <ion-col *ngIf="showDetails" size="7">
    <strong>
      <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
    </strong>
    <p>
      <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
    </p>
  </ion-col>

  <ion-col *ngIf="!showDetails" size="4">
    <p>
      <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
    </p>
  </ion-col>
  <ion-col size="1"></ion-col>
  <ion-col class="ion-text-right ion-align-items-end" size="2">
    <ion-skeleton-text animated></ion-skeleton-text>
  </ion-col>
  <ion-col class="ion-text-right" size="2">
    <ion-button class="actions-button" fill="clear">
      <ion-icon color="dark" slot="end" name="chevron-down-circle-outline" class="popover-button-icon"></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>
