<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="save()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Profile Information</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container [ngSwitch]="step">
    <gc-installer-profile-part-one
      [validated]="validated"
      [form]="form"
      [states]="states.all$ | push"
      *ngSwitchCase="1"
    ></gc-installer-profile-part-one>
    <gc-installer-profile-part-two
      [validated]="validated"
      [form]="form"
      [user]="profile!.currentEditedUser$ | push"
      [phoneNumbers]="profile.currentUserPhoneNumbers$ | push"
      [phoneNumberForm]="phoneNumberForm"
      (added)="phoneNumbers.create($event, { parents: { users: $event.userId } })"
      (remove)="phoneNumbers.delete($event, { parents: { users: $event.userId } })"
      *ngSwitchCase="2"
    ></gc-installer-profile-part-two>
    <gc-installer-profile-part-three
      [validated]="validated"
      [form]="form"
      [user]="profile!.currentEditedUser$ | push"
      [files]="profile.currentUserInsuranceFiles$ | push"
      (fileAdded)="profile.addProfileFile($event.user, $event.file)"
      *ngSwitchCase="3"
    ></gc-installer-profile-part-three>
    <gc-installer-profile-part-four
      [validated]="validated"
      [form]="form"
      [user]="profile!.currentEditedUser$ | push"
      [files]="profile.currentUserTaxFiles$ | push"
      (fileAdded)="profile.addProfileFile($event.user, $event.file)"
      *ngSwitchCase="4"
    ></gc-installer-profile-part-four>
    <gc-installer-profile-part-five
      [validated]="validated"
      [form]="form"
      [user]="profile!.currentEditedUser$ | push"
      (photoChanged)="profile.setProfilePhoto($event.user, $event.file)"
      *ngSwitchCase="5"
    ></gc-installer-profile-part-five>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col size="3" class="flex justify-content-start">
        <ion-buttons>
          <ion-button
            *ngIf="step > 1"
            color="dark"
            size="large"
            mode="md"
            (click)="back()"
            data-cy="installer-editor-back-button"
          >
            <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
      <ion-col size="6">
        <ion-button
          color="gc-alt-green"
          expand="block"
          size="large"
          mode="ios"
          (click)="save()"
          data-cy="installer-editor-save-button"
        >
          Save Profile
        </ion-button>
      </ion-col>
      <ion-col size="3" class="flex justify-content-end">
        <ion-buttons>
          <ion-button
            *ngIf="step < 5"
            color="dark"
            size="large"
            mode="md"
            (click)="next()"
            data-cy="installer-editor-next-button"
          >
            <ion-icon slot="icon-only" name="chevron-forward"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
