import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '~gc/shared/pipes/pipes.module';
import { StartEndDateComponent } from '../start-end-date/start-end-date.component';

@NgModule({
  declarations: [StartEndDateComponent],
  exports: [StartEndDateComponent],
  imports: [CommonModule, IonicModule, MatDatepickerModule, MatFormFieldModule, PipesModule, ReactiveFormsModule],
})
export class StartEndDateModule {}
