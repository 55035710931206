import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { interval, merge } from 'rxjs';
import { exhaustMap, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { routeEndsInPath, routeNotEndsInPath } from '../../shared/utils/ngrx';
import { ensureExists } from '../../shared/utils/rxjs';
import { WorkOrder } from './work-order/work-order.model';
import { currentWorkOrder, workOrderDeselected } from './work-order/work-order.state';
import {
  workOrderRefreshIntervalTicked,
  workOrdersRefreshIntervalTicked,
  workRefreshIntervalTicked,
} from './work-polling.actions';
import { pollingPaused } from './work-polling.selectors';

const POLLING_PERIOD = 20000;

@Injectable()
export class WorkPollingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  pollWork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeEndsInPath('/work/work-orders'),
      exhaustMap(() =>
        interval(POLLING_PERIOD).pipe(
          withLatestFrom(this.store.select(pollingPaused)),
          filter(([, paused]) => !paused),
          map(() => workRefreshIntervalTicked()),
          // eslint-disable-next-line @ngrx/avoid-cyclic-effects
          takeUntil(this.actions$.pipe(ofType(routerNavigationAction), routeNotEndsInPath('/work/work-orders'))),
        ),
      ),
    ),
  );

  pollWorkOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeEndsInPath('/work/orders', '/work/orders/search'),
      exhaustMap(() =>
        interval(POLLING_PERIOD).pipe(
          withLatestFrom(this.store.select(pollingPaused)),
          filter(([, paused]) => !paused),
          map(() => workOrdersRefreshIntervalTicked()),
          takeUntil(
            // eslint-disable-next-line @ngrx/avoid-cyclic-effects
            this.actions$.pipe(
              ofType(routerNavigationAction),
              routeNotEndsInPath('/work/orders', '/work/orders/search'),
            ),
          ),
        ),
      ),
    ),
  );

  pollWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigationAction),
      routeEndsInPath('/work/orders/detail'),
      exhaustMap(() =>
        interval(POLLING_PERIOD).pipe(
          withLatestFrom(this.store.select(currentWorkOrder), this.store.select(pollingPaused)),
          filter(([, , paused]) => !paused),
          map(([, workOrder]) => workOrder),
          ensureExists(workOrder => !!workOrder?.id),
          map((workOrder: WorkOrder) => workOrderRefreshIntervalTicked({ workOrder })),
          takeUntil(
            merge(
              // eslint-disable-next-line @ngrx/avoid-cyclic-effects
              this.actions$.pipe(ofType(workOrderDeselected)),
              // eslint-disable-next-line @ngrx/avoid-cyclic-effects
              this.actions$.pipe(ofType(routerNavigationAction), routeNotEndsInPath('/work/orders/detail')),
            ),
          ),
        ),
      ),
    ),
  );
}
