import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';

@Injectable()
export class BrowserService {
  constructor() {}

  async openUrl(url: string): Promise<void> {
    await Browser.open({ url });
  }

  async openEmail(email: string): Promise<void> {
    await Browser.open({ url: `mailto:${email}` });
  }

}
