import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BidAskNegotiationModalComponent } from '~gc/modals/bid-ask-negotiation/bid-ask-negotiation-modal/bid-ask-negotiation-modal.component';
import { PendingNegotiationDetails } from './models/pending-negotiation.model';

@Injectable()
export class NegotiationUIService {
  constructor(private modals: ModalController) {}

  async showNegotiationModal(): Promise<Partial<PendingNegotiationDetails>> {
    const modal = await this.modals.create({
      component: BidAskNegotiationModalComponent,
      componentProps: {},
      cssClass: 'rounded-modal',
    });

    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return role === 'ok' ? data : null;
  }
}
