import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { ROUTER_NAVIGATED, routerNavigatedAction } from '@ngrx/router-store';
import { merge, Observable } from 'rxjs';
import { exhaustMap, map, takeUntil } from 'rxjs/operators';
import { routeIncludesPath, routeStartsWithPath } from '../../../shared/utils/ngrx';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { selectedEmployeeInstallerProfile, skillsAndExperienceViewed } from '../../users/users-ui.actions';
import { workOrderMapRouteVisit } from '../../work/work-order-map/work-order-map.state';
import { viewWorkOrder } from '../../work/work-order-ui.actions';
import { selectDetailArea, workOrdersRouteVisit } from '../../work/work.actions';
import { loadAllProductInstallationTypesIfNecessary } from './product-installation-types.state';

@Injectable()
export class ProductInstallationTypesEffects implements OnRunEffects {
  constructor(private readonly actions$: Actions) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadProductInstallationTypes$ = createEffect(() =>
    merge(
      this.actions$.pipe(
        ofType(
          claimsAndTokenRetrieved,
          selectDetailArea,
          selectedEmployeeInstallerProfile,
          skillsAndExperienceViewed,
          workOrdersRouteVisit,
          workOrderMapRouteVisit,
          viewWorkOrder,
        ),
      ),
      this.actions$.pipe(ofType(ROUTER_NAVIGATED), routeStartsWithPath('/app/')),
      this.actions$.pipe(ofType(routerNavigatedAction), routeIncludesPath('work/custom-calendar')),
    ).pipe(map(() => loadAllProductInstallationTypesIfNecessary({}))),
  );
}
