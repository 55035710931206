import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import { lienReleaseSuccessfullySigned } from '~gc/domains/invoices/lien-releases/lien-release.action';
import { compose } from '../../shared/utils/compose.util';
import { PendingInvoice } from '../models/pending-invoice';
import {
  confirmPendingInvoice, confirmPendingProgress,
  invoiceVoidConfirmed,
  pendingInvoiceDeclined,
  pendingInvoiceFailed, pendingProgressDeclined,
  reviewPendingInvoice, reviewPendingProgress,
  searchCriteriaUpdated, showInvoiceOptions,
  signLienRelease,
  signLienReleaseClosed,
  updateInvoiceArea,
  viewInvoicesByStatus,
} from './invoice-and-payment.actions';
import { INVOICE_AREAS, INVOICE_STATUS_GROUP_LABEL_MAP } from './invoice-and-payment.maps';
import { Invoice } from './invoice/invoice.model';
import {
  createInvoiceFailure,
  createInvoiceSuccess,
  loadInvoice,
  loadInvoiceFailure,
  loadInvoiceSuccess,
  updateInvoice,
  updateInvoiceFailure,
  updateInvoiceSuccess,
} from './invoice/invoices.state';
import { createPayment, createPaymentFailure } from './payment/payment.state';

export type StatusGroup = keyof typeof INVOICE_STATUS_GROUP_LABEL_MAP;
export type InvoiceArea = (typeof INVOICE_AREAS)[keyof typeof INVOICE_AREAS];

export interface IInvoicePaymentState {
  currentStatusGroup?: StatusGroup;
  pendingInvoice?: PendingInvoice;
  interactingInvoice?: Invoice;
  creatingInvoice: boolean;
  searchCriteria?: string;
  invoiceArea: InvoiceArea;
  invoicesMutating: (string | number)[];
  invoicesFailedToLoad: (string | number)[];
  invoicesLoading: (string | number)[];
  invoiceInfoForSigningLienRelease?: {
    id: number;
    workOrderId: string;
  };
}

const initialWorkState: IInvoicePaymentState = {
  invoiceArea: 'work-order',
  invoicesMutating: [],
  invoicesFailedToLoad: [],
  invoicesLoading: [],
  creatingInvoice: false,
};

export const setCurrentStatusGroup = (
  state: IInvoicePaymentState,
  {
    status: { status },
  }: {
    status: { status: StatusGroup };
  },
): IInvoicePaymentState => ({
  ...state,
  currentStatusGroup: status,
});

export const clearCurrentStatusGroup = (state: IInvoicePaymentState): IInvoicePaymentState => ({
  ...state,
  currentStatusGroup: undefined,
});

export const trackPendingInvoice = (
  state: IInvoicePaymentState,
  {
    pendingInvoice,
  }: {
    pendingInvoice: PendingInvoice;
  },
): IInvoicePaymentState => ({
  ...state,
  pendingInvoice,
});

export const trackInteractingInvoice = (
  state: IInvoicePaymentState,
  {
    invoice,
  }: {
    invoice: Invoice;
  },
): IInvoicePaymentState => ({
  ...state,
  interactingInvoice: invoice,
});

export const setSearchCriteria = (
  state: IInvoicePaymentState,
  { criteria }: { criteria: string },
): IInvoicePaymentState => ({
  ...state,
  searchCriteria: criteria,
});

export const setInvoiceArea = (state: IInvoicePaymentState, { area }: { area: InvoiceArea }): IInvoicePaymentState => ({
  ...state,
  invoiceArea: area,
});

type StateIdList = Extract<keyof IInvoicePaymentState, 'invoicesLoading' | 'invoicesFailedToLoad' | 'invoicesMutating'>;
type InvoicePaymentReduction = (state: IInvoicePaymentState) => IInvoicePaymentState;
export const composeInvoicePaymentReductions: (...reductions: InvoicePaymentReduction[]) => InvoicePaymentReduction =
  compose;

export const appendKeyList =
  (id: string | number, list: StateIdList): InvoicePaymentReduction =>
  (state: IInvoicePaymentState): IInvoicePaymentState => ({
    ...state,
    [list]: [...state[list], id],
  });
export const appendKeyLists = (id: string | number, ...lists: StateIdList[]): InvoicePaymentReduction =>
  composeInvoicePaymentReductions(...lists.map(list => appendKeyList(id, list)));

export const removeFromKeyList =
  (id: string | number, list: StateIdList): InvoicePaymentReduction =>
  (state: IInvoicePaymentState): IInvoicePaymentState => ({
    ...state,
    [list]: state[list].filter(key => id !== key),
  });

export const removeFromKeyLists = (id: string | number, ...lists: StateIdList[]): InvoicePaymentReduction =>
  composeInvoicePaymentReductions(...lists.map(list => removeFromKeyList(id, list)));

export const reduce = createReducer(
  initialWorkState,
  on(viewInvoicesByStatus, setCurrentStatusGroup),
  on(reviewPendingInvoice, reviewPendingProgress, trackPendingInvoice),
  on(showInvoiceOptions, trackInteractingInvoice),
  on(searchCriteriaUpdated, setSearchCriteria),
  on(updateInvoiceArea, setInvoiceArea),
  on(loadInvoice, (state, { keys }) =>
    composeInvoicePaymentReductions(
      appendKeyList(keys, 'invoicesLoading'),
      removeFromKeyList(keys, 'invoicesFailedToLoad'),
    )(state),
  ),
  on(updateInvoice, (state, { entity }) => (entity.id ? appendKeyList(entity.id, 'invoicesLoading')(state) : state)),
  on(createPayment, (state, { entity }) => appendKeyList(entity.invoiceId, 'invoicesMutating')(state)),
  on(invoiceVoidConfirmed, (state, { invoice }) =>
    invoice.id ? appendKeyList(invoice.id, 'invoicesMutating')(state) : state,
  ),
  on(createPaymentFailure, (state, { entity }) => removeFromKeyList(entity.invoiceId, 'invoicesMutating')(state)),
  on(loadInvoiceSuccess, updateInvoiceSuccess, updateInvoiceFailure, (state, { entity }) =>
    entity.id
      ? removeFromKeyLists(entity.id, 'invoicesMutating', 'invoicesLoading', 'invoicesFailedToLoad')(state)
      : state,
  ),
  on(loadInvoiceFailure, (state, { keys }) =>
    composeInvoicePaymentReductions(
      removeFromKeyLists(keys, 'invoicesMutating', 'invoicesLoading'),
      appendKeyList(keys, 'invoicesFailedToLoad'),
    )(state),
  ),
  on(
    confirmPendingInvoice,
    confirmPendingProgress,
    (state: IInvoicePaymentState): IInvoicePaymentState => ({
      ...state,
      creatingInvoice: true,
    }),
  ),
  on(
    pendingInvoiceFailed,
    pendingInvoiceDeclined,
    pendingProgressDeclined,
    createInvoiceFailure,
    createInvoiceSuccess,
    (state: IInvoicePaymentState): IInvoicePaymentState => ({
      ...state,
      creatingInvoice: false,
    }),
  ),
  on(
    signLienRelease,
    (state: IInvoicePaymentState, { invoice }): IInvoicePaymentState =>
      invoice.id
        ? {
            ...state,
            invoiceInfoForSigningLienRelease: { id: invoice.id, workOrderId: invoice.workOrderId },
          }
        : state,
  ),
  on(
    lienReleaseSuccessfullySigned,
    signLienReleaseClosed,
    ({ invoiceInfoForSigningLienRelease, ...state }): IInvoicePaymentState => ({
      ...state,
    }),
  ),
);

export function invoicePaymentReducer(state = initialWorkState, action: Action): IInvoicePaymentState {
  return reduce(state, action);
}

export const invoicesAndPaymentState = createFeatureSelector<IInvoicePaymentState>('invoiceAndPayment');
