<ion-grid>
  <ion-row>
    <ion-col>
      <h1>Phone Numbers</h1>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <gc-phone-number-manager
        [validated]="validated"
        [user]="user"
        [form]="phoneNumberForm"
        [phoneNumbers]="phoneNumbers"
        (added)="added.emit($event)"
        (remove)="remove.emit($event)"
      ></gc-phone-number-manager>
    </ion-col>
  </ion-row>
</ion-grid>
