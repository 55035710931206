import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InstallerProfileEffects } from './installer-profile.effects';
import { PhoneNumbersUIService } from '../profile/phone-numbers-ui.service';
import { PhoneNumbersEffects } from '../profile/phone-numbers.effects';
import { ProductInstallationSubtypesUiService } from './product-installation-subtypes-ui.service';
import { ProductInstallationSubtypesEffects } from './product-installation-subtypes.effects';
import { ProductInstallationTypeUIService } from './product-installation-type-ui.service';
import { ProductInstallationTypeEffects } from './product-installation-type.effects';
import { FEATURE_NAME } from './store/feature.name';
import { reducer } from './store/feature.state';
import { TrainingsUIService } from './trainings-ui.service';
import { TrainingsEffects } from './trainings.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      InstallerProfileEffects,
      ProductInstallationTypeEffects,
      TrainingsEffects,
      ProductInstallationSubtypesEffects,
      PhoneNumbersEffects,
    ]),
  ],
  providers: [
    ProductInstallationTypeUIService,
    TrainingsUIService,
    ProductInstallationSubtypesUiService,
    PhoneNumbersUIService,
  ],
})
export class CoreInstallerProfileModule {}
