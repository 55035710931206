import { Pipe, PipeTransform } from '@angular/core';
import {
  COMPANY_CONNECTION_STATUS_LABEL,
  INSTALLER_CONNECTION_STATUS_LABEL,
} from '../../domains/users/connection/connection-status.maps';
import { ConnectionStatus } from '../../domains/users/connection/connection.model';

export interface ConnectionLike {
  status: ConnectionStatus;
}

export const connectionStatusLabel = (like: ConnectionLike, mode: 'company' | 'installer'): string =>
  like
    ? mode === 'company'
      ? COMPANY_CONNECTION_STATUS_LABEL[like.status]
      : INSTALLER_CONNECTION_STATUS_LABEL[like.status]
    : '...';

@Pipe({ name: 'connectionStatusLabel' })
export class ConnectionStatusLabelPipe implements PipeTransform {
  transform(like: ConnectionLike, mode: 'company' | 'installer'): string {
    return connectionStatusLabel(like, mode);
  }
}
