import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthFacade } from '../../../../domains/app/auth/auth.facade';
import { DetailedWorkOrder } from '../../../../domains/work/models/detailed-work-order';
import { WorkOrder } from '../../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-work-order-information',
  templateUrl: './work-order-information.component.html',
  styleUrls: ['./work-order-information.component.scss'],
})
export class WorkOrderInformationComponent {
  @Input() workOrder!: DetailedWorkOrder;
  @Input() currentRole!: string;
  @Input() permissions: string[] = [];
  @Output() navigate = new EventEmitter<WorkOrder>();

  constructor(private store: Store, public auth: AuthFacade) {}

}
