import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';
import { WorkOrderFilesFacade } from '../../../domains/work/work-order-files/work-order-files.facade';
import { WorkOrderFacade } from '../../../domains/work/work-order/work-order.facade';
import { areFilesPending } from '../store/ui.selectors';

@Component({
  selector: 'gc-work-order-files-detail',
  templateUrl: './work-order-files-detail.component.html',
  styleUrls: ['./work-order-files-detail.component.scss'],
})
export class WorkOrderFilesDetailComponent {
  isPending$ = this.store.select(areFilesPending);

  constructor(
    public readonly workOrderFiles: WorkOrderFilesFacade,
    public readonly workOrder: WorkOrderFacade,
    public readonly auth: AuthFacade,
    private readonly store: Store,
  ) {}
}
