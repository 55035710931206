import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'ProductInstallationTypeClaimed',
  uriName: 'product-installation-types-claimed',
})
export class ProductInstallationTypeClaimed {
  @Key id?: number;
  productInstallationTypeId!: number;
  userId!: string;
  dateClaimed!: Date;
  isVerified!: boolean;
}
