import { Pipe, PipeTransform } from '@angular/core';
import { WorkOrderStatus } from '../../domains/work/work-order/work-order.model';
import { STATUS_GROUP_MAP } from '../../domains/work/work.maps';
import { StatusGroup } from '../../domains/work/work.state';

@Pipe({ name: 'statusToStatusGroup' })
export class StatusToStatusGroupPipe implements PipeTransform {
  transform(status: WorkOrderStatus): StatusGroup {
    return STATUS_GROUP_MAP[status] as StatusGroup;
  }
}
