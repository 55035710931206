import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { WorkOrderDraft } from './work-order-draft.model';

export const {
  initialState: initialWorkOrderDraftState,
  facade: WorkOrderDraftFacadeBase,
  selectors: { selectAll: allWorkOrderDrafts },
} = buildState(WorkOrderDraft);

const reduce = createReducer(initialWorkOrderDraftState);

export function workOrderDraftReducer(
  state = initialWorkOrderDraftState,
  action: Action,
): IEntityState<WorkOrderDraft> {
  return reduce(state, action);
}
