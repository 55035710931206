<ng-container *ngIf="items?.length > 0; else noItems">
  <ng-container *ngIf="invoice && items">
    <ion-list *ngFor="let item of items! | sortBy: 'workOrderItem.name'; let i = index; let lastItem = last">
      <ion-item [lines]="lastItem && !item?.changeOrders?.length ? 'full' : 'none'">
        <ion-label>
          <h2>
            <strong>{{ item.workOrderItem?.name || '...' }}</strong>
            <ion-badge style="margin-bottom: -3px; margin-left: 3px; padding: 2px 8px 3px" color="medium" mode="ios"
              ><small
                >{{ item.workOrderItem.numberOfUnits }}
                <span class="uom">{{ item.workOrderItem?.unitOfMeasureSymbol }}</span></small
              >
            </ion-badge>
          </h2>
          <p>
            <span>
              {{ item.numberOfUnits }} <span class="uom">{{ item.workOrderItem?.unitOfMeasureSymbol }}</span> of
              {{ item.capturedUnitsRemaining }}
              <span class="uom">{{ item.workOrderItem?.unitOfMeasureSymbol }}</span> remaining
            </span>
          </p>
        </ion-label>
        <ion-badge
          [color]="(item | itemProgressPercentComplete) === 100 ? 'gc-green' : 'light'"
          mode="ios"
          class="ion-no-margin ion-text-right"
          slot="end"
        >
          {{ item | itemProgressPercentComplete }}% Complete
        </ion-badge>
      </ion-item>
    </ion-list>
  </ng-container>
</ng-container>
<ng-template #noItems> No items found.</ng-template>
