import { Injectable } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { WorkOrderPreviewPopoverComponent } from '../../../shared/popovers/work-order-preview-popover/work-order-preview-popover.component';
import { DetailedWorkOrder } from '../models/detailed-work-order';

@Injectable()
export class CalendarService {
  constructor(
    private modals: ModalController,
    private popovers: PopoverController,
    private alerts: AlertController,
  ) {}

  async showPreview(workOrder: DetailedWorkOrder, matchedInstallerCount: number, event: Event) {
    const popover = await this.popovers.create({
      component: WorkOrderPreviewPopoverComponent,
      componentProps: {
        workOrder,
        matchedInstallerCount,
      },
      event,
      size: 'auto',
      mode: 'md',
      animated: false,
      showBackdrop: true,
      reference: 'event',
      cssClass: 'preview-popover',
    });
    await popover.present();
    return await popover.onDidDismiss();
  }
}
