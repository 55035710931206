<div *ngIf="types?.length" class="education-and-training-menu" data-cy="education-and-training-menu">
  <ion-list class="flex horizontal-menu" lines="none">
    <div *ngFor="let type of types">
      <ion-item (click)="selected.emit(type)" button mode="ios" detail="false" [attr.data-cy]="type.type + '-menu-tab'">
        <ion-label
          class="product-type-menu-label"
          [color]="selectedProductInstallationTypeClaim?.id === type.id ? 'gc-green' : undefined"
          >{{ type.type }}
        </ion-label>
      </ion-item>
    </div>
    <ion-button
      *ngIf="canEditProfile"
      class="add"
      fill="outline"
      mode="ios"
      color="dark"
      size="small"
      (click)="added.emit()"
      data-cy="installer-add-product-installation-type-button"
    >
      <ion-icon slot="start" name="add-sharp"></ion-icon>
      Add
    </ion-button>
  </ion-list>
</div>
