<ion-grid>
  <ion-row class="ion-align-items-center">
    <ion-col class="ion-text-end">
      <ion-button mode="ios" *ngIf="isUnderNegotiation" color="danger" (click)="negotiated.emit()">
        Negotiate
      </ion-button>
      <ion-button
        mode="ios"
        *ngIf="workOrder"
        color="gc-green"
        (click)="awarded.emit({ workOrder: workOrder, installer: installer })"
      >
        Award
      </ion-button>

      <ng-container *ngIf="isJumpstarterOffer">
        <ion-button color="danger" size="large" (click)="declineInstallerOffer.emit(installer)"> Decline</ion-button>
        <ion-button color="gc-alt-green" size="large" (click)="acceptInstallerOffer.emit(installer)">
          Accept
        </ion-button>
      </ng-container>
    </ion-col>
  </ion-row>
</ion-grid>
