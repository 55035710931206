<form [formGroup]="form">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col size-lg="4" class="ion-padding">
        <h2><b>Work Order Items</b></h2>
        <p *ngIf="!(form.workOrderStatus | editMode: 'partial')">
          Break down the work and what you're paying for each item i.e. demolition, installation labor, per diem, etc.
        </p>
        <p *ngIf="form.workOrderStatus | editMode: 'partial'">
          Items must be modified as Change Orders now that the Work Order has been published.
        </p>
      </ion-col>

      <ion-col size="12" size-sm>
        <ion-list lines="full" class="ion-no-margin">
          <ion-list-header lines="full">
            <h2><b>Items</b></h2>
          </ion-list-header>

          <gc-work-order-item-sliding
            *ngFor="let item of form.workOrderItems.itemForms; index as i"
            [itemForm]="item"
            [disabled]="form.workOrderStatus | editMode: 'partial'"
            (edit)="form.editItem(item.value, i)"
            (remove)="form.removeItemAt(i)"
          >
          </gc-work-order-item-sliding>
        </ion-list>
        <div
          data-cy="item-required-error"
          class="alert alert__warning ion-margin" *ngIf="form.workOrderItems.invalid && !form.isAddingItem">
          Please add at least one item to continue publishing this Work Order.
        </div>

        <ion-button
          *ngIf="!form.isAddingItem && !(form.workOrderStatus | editMode: 'partial')"
          class="ion-margin"
          color="dark"
          mode="ios"
          (click)="form.beginAddItem()"
        >
          <ion-icon data-cy="work-order-add-item-button" name="add"></ion-icon>
          Add Item
        </ion-button>

        <ion-card *ngIf="form.isAddingItem" mode="ios" style="margin-top: 10px; margin-bottom: 10px">
          <ion-card-header color="dark">
            <ion-card-title>Add Item</ion-card-title>
          </ion-card-header>



          <form [formGroup]="form.workingItem">
            <div *ngIf="form.get('numberOfUnits')?.dirty && form!.get('numberOfUnits')?.invalid || form.get('numberOfUnits')?.errors?.['isPositive'] === false" class="alert alert__warning ion-margin">
              Change Order Item quantity must be positive and contain only numbers
            </div>
            <div *ngIf="form.get('pricePerUnit')?.errors?.['isPositive'] === false" class="alert alert__warning ion-margin">
              Change Order Item amount must be positive
            </div>
            <ion-item lines="full">
              <ion-input
                [color]="form.validated && form.workingItem.valid ? 'danger' : null"
                data-cy="item-name-input"
                formControlName="name"
                label="Item Name"
                labelPlacement="floating"
                clearInput
              >
              </ion-input>
            </ion-item>
            <ion-row>
              <ion-col size="5">
                <ion-item lines="full">
                  <ion-input
                    [color]="form.validated && form.workingItem.valid ? 'danger' : null"
                    data-cy="item-quantity-input"
                    formControlName="numberOfUnits"
                    label="# of Units"
                    labelPlacement="floating"
                    type="number"
                    inputmode="numeric"
                    clearInput
                  >
                  </ion-input>
                </ion-item>
              </ion-col>
              <ion-col size="7" style="border-bottom: 1px solid #d6d6d6">
                <input
                  data-cy="item-price-input"
                  placeholder="$0.00 per unit"
                  label="Dollars per Unit"
                  currencyMask
                  formControlName="pricePerUnit"
                  inputmode="numeric"
                  name="cost-per-unit"
                  type="text"
                  contenteditable="true"
                  class="money-input"
                  style="
                    padding: 0 20px;
                    box-sizing: border-box;
                    border: none;
                    background: #f6faf3;
                    width: 100%;
                    height: 100%;
                    text-align: left !important;
                  "
                />
              </ion-col>
            </ion-row>
            <ion-row *ngIf="form.workingItem.invalid && form.workingItem.numberOfUnits.errors?.incrementAmount">
              <ion-item lines="none">
                <ion-label color="danger" position="stacked"> Number of units must be a whole number.</ion-label>
              </ion-item>
            </ion-row>
            <ion-row *ngIf="form.workingItem.numberOfUnits.dirty && form.workingItem.numberOfUnits.invalid || (form.workingItem.invalid && form.workingItem.numberOfUnits.errors?.isPositive === false)">
              <ion-item lines="none">
                <ion-label color="danger" position="stacked"> Item quantity must be positive and contain only numbers</ion-label>
              </ion-item>
            </ion-row>
            <ion-row *ngIf="form.workingItem.pricePerUnit.dirty && form.workingItem.pricePerUnit.invalid || (form.workingItem.invalid && form.workingItem.pricePerUnit.errors?.isPositive === false)">
              <ion-item lines="none">
                <ion-label color="danger" position="stacked"> Item amount must be positive </ion-label>
              </ion-item>
            </ion-row>
            <ion-item lines="full">
              <ion-select
                data-cy="item-unit-of-measure-input"
                formControlName="unitOfMeasureSymbol"
                label="Unit of Measure"
                labelPlacement="floating"
                mode="md"
              >
                <ion-select-option *ngFor="let unit of unitsOfMeasure" [value]="unit.symbol">
                  {{ unit.description }}
                </ion-select-option>
              </ion-select>
            </ion-item>
            <ion-grid>
              <ion-row class="ion-text-end">
                <ion-col>
                  <ion-button fill="clear" color="dark" mode="ios" (click)="form.cancelItemAddEdit()">
                    Cancel
                  </ion-button>
                  <ion-button
                    fill="outline"
                    color="dark"
                    mode="ios"
                    (click)="createItem()"
                    [disabled]="form.workingItem.invalid"
                  >
                    <strong>Save</strong>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </form>
        </ion-card>

        <gc-work-order-item-total [workOrderItems]="form.rawItems"></gc-work-order-item-total>
      </ion-col>
    </ion-row>
  </ion-grid>
</form>
