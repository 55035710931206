import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { appInitialize, navigateRoot } from './app.actions';
import {
  backendVersion,
  frontendVersion,
  updateComplete,
  updateProgress,
  windowIsMobileSize,
} from './environment/environment.state';

@Injectable()
export class AppFacade {
  backendVersion$ = this.store.select(backendVersion);
  frontendVersion$ = this.store.select(frontendVersion);
  updateProgress$ = this.store.select(updateProgress);
  updateComplete$ = this.store.select(updateComplete);
  windowIsMobileSize$ = this.store.select(windowIsMobileSize);

  constructor(private store: Store<AppState>) {}

  initialize() {
    this.store.dispatch(appInitialize());
  }

  navigateRoot(url: string) {
    this.store.dispatch(navigateRoot({ url }));
  }
}
