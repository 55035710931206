import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import {
  Connection,
  ConnectionStatus,
  isOfConnectionStatus,
  Source,
} from '~gc/domains/users/connection/connection.model';
import { allConnections } from '~gc/domains/users/connection/connection.state';
import { hasPermissions } from '../../shared/pipes/has-permissions.pipe';
import { matchOptionalByOptionalId } from '../../shared/utils/utils';
import { authenticatedUser, tokenPermissions } from '../app/auth/auth.selectors';
import { FileType } from '../models/generic-file';
import { PhoneNumber } from '../phone-number';
import { allPhoneNumbers } from '../phone-number/phone-number.state';
import { Company } from './companies/company.model';
import { allCompanies, companyEntities } from './companies/company.state';
import { allowedApprovedConnections } from './connection/connections.selectors';
import { filterInstallersByConnections } from './models/connected-installer-team-lead.model';
import { DisplayConnection, EnrichedConnection } from './models/enriched-connection';
import { findFileTypeForUser, UserFile } from './user-files/user-file.model';
import { allUserFiles } from './user-files/user-files.state';
import {
  compareByLastName,
  InstallerTeamLead,
  isContractor,
  isInstallerLead,
  isManager,
  sortUsers,
  User,
} from './user/user.model';
import { currentUser, userEntities } from './user/user.state';

export const connectionForCurrentInstaller = createSelector(
  allConnections,
  currentUser,
  (connections, user) => connections?.find(network => network.installerTeamLeadId === user?.id),
);

export const connectionsForAuthenticatedInstaller = createSelector(
  allConnections,
  authenticatedUser,
  (connections, user?: User): Connection[] =>
    !!user && isInstallerLead(user) ? connections?.filter(network => network.installerTeamLeadId === user?.id) : [],
);

export const connectionsForAuthenticatedCompany = createSelector(
  allConnections,
  authenticatedUser,
  (connections: Connection[], user?: User): Connection[] =>
    !!user && isManager(user) ? connections?.filter(network => network.companyId === user?.companyId) : [],
);

export const approvedConnectionsRequests = createSelector(allConnections, connections =>
  connections.filter(connection => isOfConnectionStatus(connection, ConnectionStatus.Approved)),
);

export const permittedApprovedConnectionsRequests = createSelector(
  approvedConnectionsRequests,
  tokenPermissions,
  (connections, permissions) =>
    hasPermissions(permissions, 'connections.public:use', 'connections.private:use')
      ? connections
      : hasPermissions(permissions, 'connections.public:use')
      ? connections.filter(connection => connection.source === Source.Company)
      : hasPermissions(permissions, 'connections.private:use')
      ? connections.filter(connection => connection.source === Source.CompanyPrivate)
      : [],
);

export const enrichCompanies = (companies: Company[], connections: Connection[]) =>
  companies.map(company => ({
    ...company,
    networkConnection: connections.find(matchOptionalByOptionalId(company.id)),
  }));

export const enrichedCompanies = createSelector(allCompanies, allConnections, enrichCompanies);

export const mapToCompanyConnections = (companies: (Company & { networkConnection?: Connection })[]) =>
  companies.map(company => ({
    ...company,
    networkConnection: {
      ...company.networkConnection,
      image: company.logoUrl,
      name: company.name,
    },
  }));

export const enrichedCompanyConnections = createSelector(enrichedCompanies, mapToCompanyConnections);

//

export const enrichConnection =
  (
    companies: IEntityDictionary<Company>,
    users: IEntityDictionary<User>,
    phoneNumbers: PhoneNumber[],
    userFiles: UserFile[],
  ) =>
  (connection: Connection): EnrichedConnection => ({
    ...connection,
    company: companies[connection.companyId],
    installerTeamLead: users[connection.installerTeamLeadId] as InstallerTeamLead,
    // Possibly convert to selecting InstallerLeadProfile?
    installerPhoneNumber: phoneNumbers.find(phone => phone.userId === connection.installerTeamLeadId),
    insuranceFile: findFileTypeForUser(userFiles, FileType.Insurance, users[connection.installerTeamLeadId]),
    w9File: findFileTypeForUser(userFiles, FileType.W9, users[connection.installerTeamLeadId]),
  });

export const enrichConnections = (
  connections: Connection[],
  companies: IEntityDictionary<Company>,
  users: IEntityDictionary<User>,
  phoneNumbers: PhoneNumber[],
  userFiles: UserFile[],
): EnrichedConnection[] => connections.map(enrichConnection(companies, users, phoneNumbers, userFiles));

export const enrichedConnections = createSelector(
  allConnections,
  companyEntities,
  userEntities,
  allPhoneNumbers,
  allUserFiles,
  enrichConnections,
);

export const userSourceType = (user: User) => (isContractor(user) ? Source.Installer : Source.Company);

export const companyViewRequests =
  (user: User) =>
  (connection: EnrichedConnection): DisplayConnection => ({
    ...connection,
    image: connection.installerTeamLead?.profilePhoto,
    name: connection.installerTeamLead
      ? connection.installerTeamLead?.firstName + ' ' + connection.installerTeamLead?.lastName
      : 'Profile Incomplete',
    approvedInstaller: connection.installerTeamLead?.isApproved,
    // Could be pipe?
    isSource: connection.source === userSourceType(user),
  });

export const installerViewRequests =
  (user: User) =>
  (connection: EnrichedConnection): DisplayConnection => ({
    ...connection,
    image: connection.company?.logoUrl,
    name: connection.company?.name,
    isSource: connection.source === userSourceType(user),
  });

export const mapToConnectionRequests = (connections: EnrichedConnection[], user?: User) =>
  !!user
    ? isContractor(user)
      ? connections
          .map(installerViewRequests(user))
          .sort((a, b) =>
            (a.company?.name ?? '').localeCompare(b.company?.name ?? '', undefined, { sensitivity: 'accent' }),
          )
      : connections
          .map(companyViewRequests(user))
          .sort((a, b) => compareByLastName(a?.installerTeamLead, b?.installerTeamLead))
    : [];

export const connectionRequests = createSelector(enrichedConnections, authenticatedUser, mapToConnectionRequests);

export const filteredConnectionRequests = createSelector(connectionRequests, requests =>
  requests.filter(request =>
    isOfConnectionStatus(request, ConnectionStatus.Pending, ConnectionStatus.Accepted, ConnectionStatus.Declined),
  ),
);

//

export const filterSentConnectionRequests = (requests: DisplayConnection[]) => requests.filter(req => req.isSource);

export const sentConnectionRequests = createSelector(filteredConnectionRequests, filterSentConnectionRequests);

export const filterReceivedConnectionRequests = (requests: DisplayConnection[]) =>
  requests.filter(req => !req.isSource);

export const receivedConnectionRequests = createSelector(filteredConnectionRequests, filterReceivedConnectionRequests);

//

export const pendingConnectionRequest = createSelector(receivedConnectionRequests, requests =>
  requests.some(req => req.status === ConnectionStatus.Pending),
);

export const approvedTeamLeads = createSelector(
  userEntities,
  permittedApprovedConnectionsRequests,
  (users, requests) =>
    requests
      .map(request => users[request.installerTeamLeadId])
      .filter(user => user && isInstallerLead(user) && user.isApproved) as InstallerTeamLead[],
);

export const sortedApprovedTeamLeads = createSelector(approvedTeamLeads, sortUsers);

// export const toConnectedInstallerTeamLeads = (installers: InstallerTeamLead[], connections: Connection[]): (InstallerTeamLead | ConnectedInstallerTeamLead)[] =>
//   installers.map(installer => tryBuildConnectedInstaller(installer, connections.find(matchByKey(installer.id, 'installerTeamLeadId'))));

export const sortedApprovedConnectedTeamLeads = createSelector(
  sortedApprovedTeamLeads,
  allowedApprovedConnections,
  filterInstallersByConnections,
);
