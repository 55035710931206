import { createAction, props } from '@ngrx/store';
import { DetailedWorkOrderItem } from '../models/detailed-work-order-item';
import { ProjectManager } from '../users';
import { WorkOrderItem } from '../work/work-order-item/work-order-item.model';
import { WorkOrder } from '../work/work-order/work-order.model';
import { ChangeOrder } from './change-order/change-order.model';

export const changeOrderModificationCreated = createAction(
  '[Change Order] (Modification) Created',
  props<{ workOrderItem: DetailedWorkOrderItem }>(),
);

export const changeOrderItemAdded = createAction('[Change Order] (Item) Added', props<{ workOrder: WorkOrder }>());

export const changeOrderModificationAcceptanceRequested = createAction(
  '[Change Order] (Acceptance) Requested',
  props<{ changeOrder: ChangeOrder }>(),
);

export const changeOrderModificationAccepted = createAction(
  '[Change Order] Accepted',
  props<{ changeOrder: ChangeOrder }>(),
);

export const changeOrderModificationVoidRequested = createAction(
  '[Change Order] (Void) Requested',
  props<{ changeOrder: ChangeOrder }>(),
);

export const changeOrderModificationVoided = createAction(
  '[Change Order] Voided',
  props<{ changeOrder: ChangeOrder }>(),
);

export const changeOrderItemAcceptanceRequested = createAction(
  '[Change Order Item] (Acceptance) Requested',
  props<{ workOrderItem: WorkOrderItem }>(),
);

export const changeOrderItemApprovalRequested = createAction(
  '[Change Order Item] (Approval) Requested',
  props<{ workOrderItem: WorkOrderItem }>(),
);

export const changeOrderItemAccepted = createAction(
  '[Change Order Item] Accepted',
  props<{ workOrderItem: WorkOrderItem }>(),
);

export const changeOrderItemApproved = createAction(
  '[Change Order Item] Approved',
  props<{ workOrderItem: WorkOrderItem }>(),
);

export const changeOrderItemDeclineRequested = createAction(
  '[Change Order Item] (Decline) Requested',
  props<{ workOrderItem: DetailedWorkOrderItem }>(),
);

export const changeOrderItemDeclined = createAction(
  '[Change Order Item] Declined',
  props<{ workOrderItem: WorkOrderItem, reason: string }>(),
);

export const changeOrderItemVoidRequested = createAction(
  '[Change Order Item] (Void) Requested',
  props<{ workOrderItem: WorkOrderItem }>(),
);

export const changeOrderItemVoided = createAction(
  '[Change Order Item] Voided',
  props<{ workOrderItem: WorkOrderItem }>(),
);

export const projectManagerCallRequested = createAction(
  '[Work Order] (Project Manager, Call) Requested',
  props<{ workOrder: WorkOrder }>(),
);

export const projectManagerCalled = createAction(
  '[Work Order] (Project Manager) Called',
  props<{ user: ProjectManager }>(),
);
