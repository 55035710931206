import { createSelector } from '@ngrx/store';
import { currentPosition } from '../../app/app.state';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { WorkOrder } from '../work-order/work-order.model';
import { allWorkOrders } from '../work-order/work-order.state';
import { LocationCoords, workOrderMapState } from './work-order-map.state';

export const mapsApiLoaded = createSelector(workOrderMapState, state => state.mapsApiLoaded);
export const currentPlace = createSelector(workOrderMapState, state => state.place);
export const searchRadius = createSelector(workOrderMapState, state => state.searchRadius);
export const mapsLoading = createSelector(workOrderMapState, state => state.loading);

export const transformPointToCoords = (location: { coordinates: [number, number] }): LocationCoords => ({
  lat: location?.coordinates[1],
  lng: location?.coordinates[0],
});

export const currentLatLong = createSelector(
  currentPosition,
  currentPlace,
  authenticatedUser,
  (position, place, user): LocationCoords =>
    place ||
    (position?.coords && {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }) ||
    (user?.addresses?.[0]?.location && transformPointToCoords(user.addresses[0].location)) ||
    transformPointToCoords({ coordinates: [-97.337225, 37.6876388] }),
);

export const mapWorkOrders = createSelector(allWorkOrders, workOrders =>
  workOrders.map((wo): WorkOrder & { position?: { lat: number; lng: number } } => ({
    ...wo,
    ...(!!wo.location
      ? {
          position: transformPointToCoords(wo.location),
        }
      : {}),
  })),
);
