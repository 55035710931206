import { Injectable } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { Invoice } from '~gc/domains/invoices';
import { SettlementTiming } from '~gc/domains/invoices/settlement-timing/settlement-timing.entity';
import { ExplainSettlementTimingComponent } from '~gc/modals/select-settlement-timing/explain-settlement-timing/explain-settlement-timing.component';
import { SelectSettlementTimingComponent } from '~gc/modals/select-settlement-timing/select-settlement-timing.component';
import { ModalService } from '~gc/shared/services/modal.service';

@Injectable()
export class SettlementTimingUIService {
  explainModal?: HTMLIonModalElement;

  constructor(private modals: ModalService) {}

  async openSelectModal(invoice: Invoice): Promise<OverlayEventDetail<SettlementTiming>> {
    const modal = await this.modals.openSheetModal(SelectSettlementTimingComponent, {
      initialBreakpoint: 0.6,
      showBackdrop: true,
      breakpoints: [0, 0.6, 0.8],
      cssClass: 'rounded-modal',
      backdropBreakpoint: 0,
      componentProps: {
        invoice,
      },
    });

    return modal.onWillDismiss();
  }

  async openExplainModal() {
    if (!this.explainModal) {
      this.explainModal = await this.modals.open(ExplainSettlementTimingComponent, {
        cssClass: 'rounded-modal',
      });
      return this.explainModal.onWillDismiss().then(() => {
        this.explainModal = undefined;
      });
    }
  }

  async dismissExplainModal() {
    return this.explainModal?.dismiss();
  }
}
