import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of, pipe, timer } from 'rxjs';
import { catchError, delay, exhaustMap, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { LogService } from '~gc/shared/services/log.service';
import { ensureChildrenExist, ensureExists } from '~gc/shared/utils/rxjs';
import { AppUIService } from '../../app-ui.service';
import { currentRouteUrl } from '../../router.selectors';
import { AppState } from '../../state';
import { appInitialize } from '../app.actions';
import {
  accessTokenNearingExpiration,
  authenticationCompleted,
  authSessionExpired,
  authSessionRefreshed,
  authSessionRefreshFailed,
  claimsAndTokenRetrieved,
  expiredAccessTokenFound,
  offlineTokenFound,
  queueAuthRequest,
  unauthorizedUserIdentified,
  validAccessTokenFound,
} from './auth-connect.actions';
import { AuthConnectService } from './auth-connect.service';
import { claimsInvalidated, loggedOut, login, logout, tokenRefreshBeforeExpirationInitiated } from './auth.actions';
import { authenticatedRole, authenticatedTokenExpiration, hasToken } from './auth.selectors';
import { Claims } from './claims.model';

const TAGS = ['Effects', 'Auth', 'Web'];

const AUTH_CALLBACK_DELAY = 250;

@Injectable()
export class AuthConnectEffects {
  // ALERT: This function (getClaimsAndToken) MUST be at the TOP of this class to prevent build errors!!
  getClaimsAndToken = () =>
    pipe(
      switchMap(() => from(this.auth.getIdToken())),
      switchMap(claims =>
        from(this.auth.getAccessToken()).pipe(
          map(
            token =>
              ({
                claims,
                token,
              }) as { claims?: Claims; token?: string },
          ),
        ),
      ),
    );

  constructor(
    private actions$: Actions,
    private appUI: AppUIService,
    private auth: AuthConnectService,
    private location: Location,
    private nav: NavController,
    private log: LogService,
    private store: Store<AppState>,
  ) {}

  // NOTE: When the app starts, not logging out, try to restore the prior authenticated user...
  restoreAuthentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialize),
      map(() => this.location.path()),
      filter(url => !url.endsWith('/logout')),
      switchMap(() => this.auth.isAccessTokenAvailable()),
      filter(hasToken => !!hasToken),
      switchMap(() => this.auth.isAccessTokenExpired()),
      map(isExpired => (isExpired ? expiredAccessTokenFound() : validAccessTokenFound())),
    ),
  );

  getAuthenticationTokenAndClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(validAccessTokenFound, offlineTokenFound, authenticationCompleted, authSessionRefreshed),
      tap(({ type }) =>
        this.log.warn(
          TAGS,
          type === authenticationCompleted.type ? 'Authentication succeeded' : 'Already authenticated!',
        ),
      ),
      switchMap(action =>
        action.type === authenticationCompleted.type ? of(action).pipe(delay(AUTH_CALLBACK_DELAY)) : of(action),
      ),
      this.getClaimsAndToken(),
      ensureChildrenExist(value => !!value?.claims && !!value.token),
      map(claimsAndToken => claimsAndTokenRetrieved(claimsAndToken)),
    ),
  );

  refreshSessionOnExpiredTokenFound$ = createEffect(() =>
    this.actions$.pipe(
      ofType(expiredAccessTokenFound, accessTokenNearingExpiration),
      filter(() => navigator.onLine),
      switchMap(() =>
        from(this.auth.refreshSession()).pipe(
          map(() => authSessionRefreshed()),
          catchError(() => of(authSessionRefreshFailed())),
        ),
      ),
    ),
  );

  returnToLoginScreenOnExpiredTokenFound$ = createEffect(() =>
    this.actions$.pipe(
      ofType(expiredAccessTokenFound, accessTokenNearingExpiration),
      filter(() => !navigator.onLine),
      switchMap(() => this.auth.getRefreshToken()),
      map(token => (!!token ? offlineTokenFound() : unauthorizedUserIdentified())),
    ),
  );

  returnToLoginPageOnUnauthorized$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unauthorizedUserIdentified, authSessionRefreshFailed),
        tap(() => this.auth.clearStorage()),
        withLatestFrom(this.store.select(currentRouteUrl)),
        filter(url => url.includes('/app')),
        tap(() => this.nav.navigateRoot('/login')),
      ),
    { dispatch: false },
  );

  launchApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(claimsAndTokenRetrieved),
        tap(() => this.log.debug(TAGS, 'Authorization token and claims stored, redirecting to app...')),
        withLatestFrom(this.store.select(authenticatedRole), this.store.select(currentRouteUrl)),
        switchMap(([, role, route]) =>
          !!role
            ? route?.includes('/app/')
              ? of(null)
              : this.nav.navigateRoot('/app/dashboard')
            : this.nav.navigateRoot('/login-landing'),
        ),
      ),
    { dispatch: false },
  );

  checkShouldRefreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimsAndTokenRetrieved, claimsInvalidated),
      withLatestFrom(this.store.select(hasToken)),
      filter(([, hasToken]) => hasToken),
      map(() => tokenRefreshBeforeExpirationInitiated()),
    ),
  );

  refreshTokenBeforeExpiration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tokenRefreshBeforeExpirationInitiated),
      withLatestFrom(this.store.select(authenticatedTokenExpiration)),
      map(([, expiration]) => expiration),
      ensureExists(expiration => !!expiration),
      map(expiration => new Date((expiration - 60) * 1000)),
      tap(exp => this.log.debug(TAGS, `Creating expiration event timer with date: ${exp.toISOString()}`)),
      switchMap(expirationDate => timer(expirationDate).pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
      map(() => accessTokenNearingExpiration()),
    ),
  );

  // NOTE: This effect will ultimately result in a redirection to auth0's /authenticate page,
  // AWAY from the app...
  logIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(login),
        tap(() => this.log.debug(TAGS, 'Logging in...')),
        switchMap(() => this.auth.login()),
      ),
    { dispatch: false },
  );

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      tap(() => this.log.debug(TAGS, 'Logging out...')),
      tap(() => this.auth.clearStorage()),
      map(() => loggedOut()),
    ),
  );

  logOutAuthProvider$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loggedOut),
        tap(() => this.log.debug(TAGS, 'Logged out.')),
        switchMap(() => this.auth.logout()),
      ),
    { dispatch: false },
  );

  // NOTE: This effect will handle the redirection back to the app from auth0...
  completeAuthentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialize),
      map(() => this.location.path()),
      filter(url => url.endsWith('/login-success')),
      tap(() => this.log.info(TAGS, 'Completing authentication process...')),
      exhaustMap(() => this.auth.handleLoginCallback()),
      map(() => authenticationCompleted()),
    ),
  );

  completeQueuedAuthentication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(queueAuthRequest),
      tap(() => this.log.info(TAGS, 'Completing authentication process...')),
      exhaustMap(() => this.auth.handleLoginCallback()),
      map(() => authenticationCompleted()),
    ),
  );

  completeLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(queueAuthRequest, appInitialize),
      map(() => this.location.path()),
      filter(url => url.endsWith('/logout')),
      tap(() => this.log.info(TAGS, 'Completing logout process...')),
      exhaustMap(() => from(this.auth.handleLogoutCallback())),
      tap(() => this.nav.navigateRoot('/login')),
      map(() => loggedOut()),
    ),
  );

  handleSessionExpired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authSessionExpired),
      exhaustMap(() =>
        from(
          (console.warn('Attempting Refresh of user session after unauthorized request'), this.auth.refreshSession()),
        ).pipe(
          map(() => false),
          tap({
            error: err =>
              console.warn('Error Refreshing Session After Unauthorized Request.  Prompting User To Login.'),
          }),
          catchError(err =>
            from(
              this.appUI.alert(
                'Session Expired',
                `You're session has expired. You'll have to login to continue.`,
                true,
              ),
            ).pipe(map(() => true)),
          ),
        ),
      ),
      filter(requiresLogin => requiresLogin),
      map(() => login()),
    ),
  );
}
