import { createAction, props } from '@ngrx/store';
import { PhoneNumber } from '../../domains/phone-number';
import { User } from '../../domains/users';
import { WithId } from '../../shared/utils/types';


export const phoneNumberVerificationInitiated = createAction(
  '[CORE: Profile] (Phone Number Verification) Initiated',
  props<{ phoneNumber: PhoneNumber; code: string, user: User}>(),
);

export const phoneNumberVerificationTimedOut = createAction(
  '[CORE: Profile] (Phone Number Verification) Timed Out',
  props<{ phoneNumber: PhoneNumber }>(),
);

export const phoneNumberVerificationSuccess = createAction(
  '[CORE: Profile] (Phone Number Verification) Success',
  props<{ phoneNumber: PhoneNumber }>(),
);

export const phoneNumberVerificationFailure = createAction(
  '[CORE: Profile] (Phone Number Verification) Failure',
  props<{ phoneNumber: PhoneNumber; error: any }>(),
);

export const confirmPhoneNumberRemoval = createAction(
  '[CORE: Profile] (Confirm) Phone Number Removal',
  props<{ phoneNumber: PhoneNumber; user: WithId<User> }>(),
);

export const phoneNumberRemovalConfirmed = createAction(
  '[CORE: Profile] (Confirmed) Phone Number Removal',
  props<{ phoneNumber: PhoneNumber; user: WithId<User> }>(),
);

export const warnMinimumPhoneNumbersRequired = createAction(
  '[CORE: Profile] (Warn) Minimum Phone Numbers Required'
);

export const addPhoneNumber = createAction(
  '[CORE: Profile] (Add) Phone Number',
  props<{ phoneNumber: PhoneNumber; user: WithId<User> }>(),
);

export const refreshPhoneNumberCode = createAction(
  '[CORE: Profile] (Refresh) Phone Number Code',
  props<{ phoneNumber: PhoneNumber; user: WithId<User> }>(),
);
