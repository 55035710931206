import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { ProductInstallationTypeClaimed } from './product-installation-types-claimed.model';

export const {
  initialState,
  facade: ProductInstallationTypeClaimedFacadeBase,
  selectors: { selectAll: allProductInstallationTypeClaimed },
  actions: {
    loadAll: loadAllProductInstallationTypesClaimed,
    loadAllIfNecessary: loadAllProductInstallationTypesClaimedIfNecessary,
    replace: replaceProductInstallationTypeClaim,
    create: createProductInstallationTypeClaim,
    delete: deleteProductInstallationTypeClaim,
    deleteFailure: deleteProductInstallationTypeClaimedFailure,
  },
} = buildState(ProductInstallationTypeClaimed);

export function productInstallationTypeClaimedReducer(
  state = initialState,
): IEntityState<ProductInstallationTypeClaimed> {
  return state;
}
