import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import lzstring from 'lz-string';

export class CacheMissException extends Error {
  constructor(message: string = 'Not found in cache.') {
    super(message);
  }
}

@Injectable()
export class ResponseCacheService {
  private storage!: Storage;

  constructor() {
    this.init();
  }

  private async init() {
    this.storage = new Storage({
      name: 'gc__responseCache',
      driverOrder: ['sqlite', 'asyncStorage', 'webSQLStorage', 'localStorageWrapper'],
    });
    await this.storage.create();
  }

  async get(url: string): Promise<any> {
    const key = await encode(url);
    return this.storage.get(key).then(body => {
      // console.log('url=>key', url, key);
      if (!body) {
        throw new CacheMissException();
      }
      return JSON.parse(lzstring.decompress(body));
    });
  }

  async set(url: string, body: any): Promise<any> {
    const key = await encode(url);
    return this.storage.set(key, lzstring.compress(JSON.stringify(body)));
  }

  async remove(url: string): Promise<any> {
    const key = await encode(url);
    return this.storage.remove(key);
  }

  clear() {
    this.storage.clear();
  }
}

const toHexString = (bytes: Uint8Array) =>
  Array.from(bytes, byte => {
    return ('0' + (byte & 0xff).toString(16)).slice(-2);
  }).join('');

export const encode = async (data: string): Promise<string> => {
  const enc = new TextEncoder().encode(data);
  const arrbIn = enc.buffer;
  const arrbOut = await crypto.subtle.digest('SHA-256', arrbIn);
  const dec = new Uint8Array(arrbOut);
  const hash = toHexString(dec);
  // console.log('data->hash', data, hash);
  return hash;
};
