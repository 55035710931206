import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GeocodeResponse } from '../models/geocode-response.model';
import { ConfigService } from './config.service';

@Injectable()
export class GoogleMapsService {
  private apiKey?: string;

  constructor(
    private http: HttpClient,
    private readonly config: ConfigService,
  ) {}

  getApiKey() {
    return this.apiKey
      ? of(this.apiKey)
      : this.http.get<{ google: string }>(this.config.host + '/maps/keys').pipe(
          map(({ google }) => google),
          tap(google => (this.apiKey = google)),
        );
  }

  loadMapsSDK() {
    return this.getApiKey().pipe(
      switchMap(key =>
        this.http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`, 'callback'),
      ),
      map(() => true),
      catchError(() => of(false)),
    );
  }

  getLocation(address: string, city: string, state: string, zip: string) {
    return this.getApiKey().pipe(
      switchMap(key =>
        this.http.get<GeocodeResponse>('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            key,
            sensor: false,
            address: `${address} ${city}, ${state} ${zip}`,
          },
        }),
      ),
    );
  }
}
