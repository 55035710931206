import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { ChangeOrder } from '../../../domains/change-orders/change-order/change-order.model';
import { DetailedWorkOrderItem } from '../../../domains/models/detailed-work-order-item';
import { GenericValidators } from '../../../shared/validators/generic.validators';

export const newChangeTotalLessEqualRemaining =
  (remainingTotal: number): ValidatorFn =>
  (formGroup: UntypedFormGroup) =>
    +(
      (formGroup.get('adjustedNumberOfUnits')?.value ?? 0) * (formGroup.get('adjustedPricePerUnit')?.value ?? 0)
    ).toFixed(2) <= remainingTotal
      ? null
      : { newChangeTotalLessEqualRemaining: true };

@Component({
  selector: 'gc-change-order-deduct-modal',
  templateUrl: './change-order-deduct-modal.component.html',
  styleUrls: ['./change-order-deduct-modal.component.scss'],
})
export class ChangeOrderDeductModalComponent implements OnInit {
  @Input() item!: DetailedWorkOrderItem;

  form?: UntypedFormGroup;

  readonly dollarMask: MaskitoOptions = {
    mask: [],
  };
  readonly maskPredicate: MaskitoElementPredicateAsync = async el => (el as HTMLIonInputElement).getInputElement();

  get newAmount(): number {
    return +(
      (this.form?.get('adjustedNumberOfUnits')?.value ?? 0) * (this.form?.get('adjustedPricePerUnit')?.value ?? 0)
    ).toFixed(2);
  }

  constructor(
    private readonly builder: UntypedFormBuilder,
    private readonly modalGC: ModalController,
  ) {}

  ngOnInit() {
    this.form = this.builder.group(
      {
        name: [null, Validators.required],
        adjustedNumberOfUnits: [
          null,
          [
            Validators.required,
            GenericValidators.isPositive,
            GenericValidators.incrementAmount(this.item.unitOfMeasureSymbol),
          ],
        ],
        adjustedPricePerUnit: [null, [Validators.required, GenericValidators.isPositive]],
      },
      {
        validators: [newChangeTotalLessEqualRemaining(this.item.uninvoicedChangeInfo.amountRemainingAfterAll)],
      },
    );

    this.form.patchValue({ name: `Deduct from ${this.item.name}` });
  }

  async cancelAddEdit() {
    await this.modalGC.dismiss(null, 'cancel');
  }

  async submit() {
    if (this.form!.invalid) {
      return;
    }
    const updatedModel = {
      ...this.form!.value,
      workOrderId: this.item.workOrderId,
      workOrderItemId: this.item.id,
    };
    await this.modalGC.dismiss(updatedModel, 'ok');
  }

  trackChangeOrderBy(index: number, item: ChangeOrder) {
    return item.id;
  }
}
