import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, from, merge, Observable } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ensureChildrenRequired, ensureRequired } from '~gc/shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { authenticatedRoleKey, authenticatedUser } from '../app/auth/auth.selectors';
import { EmptyKey, EntityCriteria } from '../entity.service.utils';
import { PhoneNumberUIService } from '../phone-number/phone-number-ui.service';
import { clearPhoneNumbers } from '../phone-number/phone-number.state';
import { AppState } from '../state';
import { allUnitOfMeasures } from '../unit-of-measure/unit-of-measure.state';
import { ProjectManager } from '../users';
import { userEntities } from '../users/user/user.state';
import { ChangeOrderItemStatus, WorkOrderItem } from '../work/work-order-item/work-order-item.model';
import {
  allWorkOrderItems,
  createWorkOrderItem,
  replaceWorkOrderItem,
  replaceWorkOrderItemSuccess,
} from '../work/work-order-item/work-order-item.state';
import { viewWorkOrder } from '../work/work-order-ui.actions';
import { WorkOrder } from '../work/work-order/work-order.model';
import { currentWorkOrderKey, loadWorkOrder } from '../work/work-order/work-order.state';
import { workOrderRefreshIntervalTicked } from '../work/work-polling.actions';
import { STATUS_GROUP_MAP } from '../work/work.maps';
import {
  allChangeOrders,
  createChangeOrder,
  loadAllChangeOrders,
  replaceChangeOrder,
  replaceChangeOrderSuccess,
} from './change-order/change-order.state';
import { ChangeOrdersUiService } from './change-orders-ui.service';
import {
  changeOrderItemAcceptanceRequested,
  changeOrderItemAccepted,
  changeOrderItemAdded,
  changeOrderItemApprovalRequested,
  changeOrderItemApproved,
  changeOrderItemDeclined,
  changeOrderItemDeclineRequested,
  changeOrderItemVoided,
  changeOrderItemVoidRequested,
  changeOrderModificationAcceptanceRequested,
  changeOrderModificationAccepted,
  changeOrderModificationCreated,
  changeOrderModificationVoided,
  changeOrderModificationVoidRequested,
  projectManagerCalled,
  projectManagerCallRequested,
} from './change-orders.actions';


@Injectable()
export class ChangeOrdersEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly changeOrdersUI: ChangeOrdersUiService,
    private readonly phoneNumbersUI: PhoneNumberUIService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadWorkOrderOnItemUpdate$ = createEffect(() =>
    // eslint-disable-next-line @ngrx/avoid-cyclic-effects
    this.actions$.pipe(
      ofType(replaceWorkOrderItemSuccess, replaceChangeOrderSuccess),
      map(({ entity }) => loadWorkOrder({ keys: entity.workOrderId })),
    ),
  );

  loadChangeOrders$ = createEffect(() =>
    merge(
      this.actions$.pipe(
        ofType(viewWorkOrder),
        filter(
          action =>
            STATUS_GROUP_MAP[action.workOrder.status] !== 'pending' &&
            STATUS_GROUP_MAP[action.workOrder.status] !== 'draft',
        ),
        map(({ workOrder }) => workOrder.id),
      ),
      this.actions$.pipe(
        ofType(workOrderRefreshIntervalTicked),
        withLatestFrom(this.store.select(currentWorkOrderKey)),
        map(([, workOrderId]) => workOrderId),
      ),
      this.actions$.pipe(
        ofType(changeOrderModificationVoidRequested),
        map(({ changeOrder }) => changeOrder.workOrderId),
      ),
    ).pipe(
      map(workOrderId =>
        loadAllChangeOrders({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrderId,
              [uriNameOfEntityOrEmpty(WorkOrderItem)]: EmptyKey,
            },
          } as EntityCriteria,
        }),
      ),
    ),
  );

  confirmChangeOrderAccept$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderModificationAcceptanceRequested),
      exhaustMap(({ changeOrder }) =>
        from(
          this.changeOrdersUI.confirmAcceptance({
            message: 'Are you sure you want to accept this Change Order? This cannot be undone.',
            header: 'Accept Change Order',
          }),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => changeOrderModificationAccepted({ changeOrder })),
        ),
      ),
    ),
  );

  acceptChangeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderModificationAccepted),
      withLatestFrom(this.store.select(authenticatedUser).pipe(ensureRequired(user => !!user?.id))),
      map(([{ changeOrder }, user]) => ({
        ...changeOrder,
        acceptedById: user.id,
        acceptedAt: new Date().toISOString(),
      })),
      map(changeOrder =>
        replaceChangeOrder({
          entity: changeOrder,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: changeOrder.workOrderId,
              [uriNameOfEntityOrEmpty(WorkOrderItem)]: changeOrder.workOrderItemId,
            },
          },
        }),
      ),
    ),
  );

  confirmChangeOrderVoid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderModificationVoidRequested),
      exhaustMap(({ changeOrder }) =>
        from(
          this.changeOrdersUI.confirmVoid({
            message: 'Are you sure you want to void this Change Order? This cannot be undone.',
            header: 'Void Change Order',
          }),
        ).pipe(
          filter(role => role === 'ok'),

          withLatestFrom(this.store.select(allChangeOrders)),
          map(([, changeOrders]) => changeOrders.find(co => co.id === changeOrder.id)),
          map(co => changeOrderModificationVoided({ changeOrder: co ?? changeOrder })),
        ),
      ),
    ),
  );

  warnAlreadyAcceptedChangeOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeOrderModificationVoided),
        filter(({ changeOrder }) => !!changeOrder.acceptedById),
        exhaustMap(() => from(this.changeOrdersUI.alertAlreadyAccepted())),
      ),
    { dispatch: false },
  );

  voidChangeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderModificationVoided),
      filter(({ changeOrder }) => !changeOrder.acceptedById),
      withLatestFrom(this.store.select(authenticatedUser).pipe(ensureRequired(user => !!user?.id))),
      map(([{ changeOrder }, user]) => ({
        ...changeOrder,
        voidedById: user.id,
        voidedAt: new Date().toISOString(),
      })),
      map(changeOrder =>
        replaceChangeOrder({
          entity: changeOrder,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: changeOrder.workOrderId,
              [uriNameOfEntityOrEmpty(WorkOrderItem)]: changeOrder.workOrderItemId,
            },
          },
        }),
      ),
    ),
  );

  confirmChangeOrderItemAccepted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderItemAcceptanceRequested),
      exhaustMap(({ workOrderItem }) =>
        from(
          this.changeOrdersUI.confirmAcceptance({
            message: 'Are you sure you want to accept this Change Order Item? This cannot be undone.',
            header: 'Accept Change Order Item',
          }),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => changeOrderItemAccepted({ workOrderItem })),
        ),
      ),
    ),
  );

  confirmChangeOrderItemApproved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderItemApprovalRequested),
      exhaustMap(({ workOrderItem }) =>
        from(
          this.changeOrdersUI.confirmAcceptance({
            message: 'Are you sure you want to approve this Change Order Item? This cannot be undone.',
            header: 'Approve Change Order Item',
          }),
        ).pipe(
          filter(role => role === 'ok'),
          map(() => changeOrderItemApproved({ workOrderItem })),
        ),
      ),
    ),
  );

  acceptChangeOrderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderItemAccepted, changeOrderItemApproved),
      withLatestFrom(this.store.select(authenticatedUser).pipe(ensureRequired(user => !!user?.id))),
      map(([{ workOrderItem }, user]) => ({
        ...workOrderItem,
        changeStatus: ChangeOrderItemStatus.Accepted,
        acceptedById: user.id,
        acceptedAt: new Date().toISOString(),
      })),
      map(workOrderItem =>
        replaceWorkOrderItem({
          entity: workOrderItem,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrderItem.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  confirmChangeOrderItemDecline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderItemDeclineRequested),
      exhaustMap(({ workOrderItem }) =>
        from(this.changeOrdersUI.confirmDecline(workOrderItem)).pipe(
          filter(({ role }) => role === 'ok'),
          ensureChildrenRequired(result => !!result?.data?.item && !!result?.data?.reason),
          map(({ data }) => changeOrderItemDeclined({ workOrderItem: data.item, reason: data.reason })),
        ),
      ),
    ),
  );

  confirmChangeOrderItemVoid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderItemVoidRequested),
      exhaustMap(({ workOrderItem }) =>
        from(
          this.changeOrdersUI.confirmVoid({
            message: 'Are you sure you want to void this Change Order Item? This cannot be undone.',
            header: 'Void Change Order Item',
          }),
        ).pipe(
          filter(role => role === 'ok'),
          withLatestFrom(this.store.select(allWorkOrderItems)),
          map(([, workOrderItems]) => workOrderItems.find(item => item.id === workOrderItem.id)),
          map(item => changeOrderItemVoided({ workOrderItem: item ?? workOrderItem })),
        ),
      ),
    ),
  );

  warnAlreadyAcceptedChangeOrderItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeOrderItemVoided, changeOrderItemDeclined),
        filter(({ workOrderItem }) => !!workOrderItem.acceptedById),
        exhaustMap(() => from(this.changeOrdersUI.alertAlreadyAccepted())),
      ),
    { dispatch: false },
  );

  declineChangeOrderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderItemDeclined),
      filter(({ workOrderItem }) => !workOrderItem.acceptedById),
      withLatestFrom(this.store.select(authenticatedUser).pipe(ensureRequired(user => !!user?.id))),
      map(([{ workOrderItem, reason }, user]) => ({
        ...workOrderItem,
        changeStatus: ChangeOrderItemStatus.DeclinedWithReason,
        declinedReason: reason,
        declinedById: user.id,
        declinedAt: new Date().toISOString(),
      })),
      map(workOrderItem =>
        replaceWorkOrderItem({
          entity: workOrderItem,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrderItem.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  voidChangeOrderItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderItemVoided),
      filter(({ workOrderItem }) => !workOrderItem.acceptedById),
      withLatestFrom(this.store.select(authenticatedUser).pipe(ensureRequired(user => !!user?.id))),
      map(([{ workOrderItem }, user]) => ({
        ...workOrderItem,
        changeStatus: ChangeOrderItemStatus.Voided,
        voidedById: user.id,
        voidedAt: new Date().toISOString(),
      })),
      map(workOrderItem =>
        replaceWorkOrderItem({
          entity: workOrderItem,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrderItem.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  callProjectManager$ = createEffect(() =>
    this.actions$.pipe(
      ofType(projectManagerCallRequested),
      map(({ workOrder }) => workOrder),
      ensureRequired(workOrder => !!workOrder?.projectManagerId),
      withLatestFrom(this.store.select(userEntities)),
      map(([workOrder, users]) => users[workOrder.projectManagerId] as ProjectManager),
      map(user => projectManagerCalled({ user })),
    ),
  );

  callWorkOrderProjectManager$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectManagerCalled),
        filter(({ user }) => !!user),
        exhaustMap(() => from(this.phoneNumbersUI.showCallPhoneNumberModal()).pipe(map(() => clearPhoneNumbers()))),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  alertMissingProjectManagerOnCall$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(projectManagerCalled),
        filter(({ user }) => !user),
        exhaustMap(() => from(this.changeOrdersUI.alertMissingProjectManager()).pipe(map(() => clearPhoneNumbers()))),
        catchError(() => EMPTY),
      ),
    { dispatch: false },
  );

  showChangeOrderDeductModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderModificationCreated),
      switchMap(({ workOrderItem }) =>
        from(this.changeOrdersUI.showChangeOrderDeductModal(workOrderItem)).pipe(
          filter(({ role }) => role === 'ok'),
          map(({ data }) =>
            createChangeOrder({
              entity: data,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(WorkOrder)]: data.workOrderId,
                  [uriNameOfEntityOrEmpty(WorkOrderItem)]: data.workOrderItemId,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  showChangeOrderItemAddModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeOrderItemAdded),
      withLatestFrom(this.store.select(allUnitOfMeasures)),
      switchMap(([{ workOrder }, unitsOfMeasure]) =>
        from(this.changeOrdersUI.showChangeOrderItemAddModal(workOrder, unitsOfMeasure)).pipe(
          filter(({ role }) => role === 'ok'),
          withLatestFrom(this.store.select(authenticatedRoleKey)),
          map(([{ data }, role]) => ({
            ...data,
            changeStatus: ChangeOrderItemStatus.PendingAcceptance,
            createdByInstaller: role === 'installerLead',
          })),
          map(data =>
            createWorkOrderItem({
              entity: data,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(WorkOrder)]: data.workOrderId,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );
}
