import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faCheckCircle, faCircleQuestion, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { createSelector } from '@ngrx/store';
import { add, format, isBefore, parseISO } from 'date-fns';
import { FileType } from '../../domains/models/generic-file';
import { filterComplianceFilesForUser, UserFile } from '../../domains/users/user-files/user-file.model';
import { allUserFiles } from '../../domains/users/user-files/user-files.state';
import { ComplianceRecord, InsuranceComplianceRecord, InsuranceStatus } from './models/compliance-record.model';
import { currentInstaller } from './store/profile.selectors';


export const currentInstallerComplianceFiles = createSelector(
  allUserFiles,
  currentInstaller,
  filterComplianceFilesForUser,
);

export const INSURANCE_STATUS_TO_ICONS: Record<InsuranceStatus, IconDefinition> = {
  valid: faCheckCircle,
  expiring: faTriangleExclamation,
  expired: faTriangleExclamation,
  unknown: faCircleQuestion,
};

export const INSURANCE_STATUS_TO_COLORS: Record<InsuranceStatus, string> = {
  valid: 'gc-green',
  expiring: 'warning',
  expired: 'danger',
  unknown: 'gc-gray',
};

export const INSURANCE_STATUS_DESCRIPTIONS: Record<InsuranceStatus, (date?: string) => string> = {
  valid: date => `Valid. Expires ${date ? format(parseISO(date), 'MM-dd-yyyy') : ''}`,
  expiring: date => `Expiring ${date ? format(parseISO(date), 'MM-dd-yyyy') : ''}`,
  expired: date => `Expired ${date ? format(parseISO(date), 'MM-dd-yyyy') : ''}`,
  unknown: () => 'Expiration Unknown',
};
export const currentInstallerComplianceRecords = createSelector(
  currentInstallerComplianceFiles,
  (files: UserFile[]): ComplianceRecord[] =>
    files
      .map(
        (file: UserFile): Partial<ComplianceRecord> => ({
          file,
          ...(file.type === FileType.W9
            ? {
                taxStatus: 'passed',
              }
            : {
                insuranceStatus: !file.expirationDate
                  ? 'unknown'
                  : isBefore(parseISO(file.expirationDate), new Date())
                  ? 'expired'
                  : isBefore(parseISO(file.expirationDate), add(new Date(), { days: 30 }))
                  ? 'expiring'
                  : 'valid',
              }),
        }),
      )
      .map(
        (record: ComplianceRecord): ComplianceRecord => ({
          ...record,
          icon:
            record.file.type === FileType.W9
              ? faCheckCircle
              : INSURANCE_STATUS_TO_ICONS[(record as InsuranceComplianceRecord).insuranceStatus],
          color:
            record.file.type === FileType.W9
              ? 'gc-green'
              : INSURANCE_STATUS_TO_COLORS[(record as InsuranceComplianceRecord).insuranceStatus],
          label:
            record.file.type === FileType.W9
              ? 'Passed'
              : INSURANCE_STATUS_DESCRIPTIONS[(record as InsuranceComplianceRecord).insuranceStatus](
                  record.file.expirationDate,
                ),
        }),
      ),
);
