import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap, from, of } from 'rxjs';
import { catchError, exhaustMap, first, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { authenticatedUser } from '../../domains/app/auth/auth.selectors';
import { AppState } from '../../domains/state';
import { CompanyUser, isCompanyUser, User } from '../../domains/users';
import { ConnectionStatus, Source } from '../../domains/users/connection/connection.model';
import { connectionCreationSucceeded, createConnection } from '../../domains/users/connection/connection.state';
import { loadUser } from '../../domains/users/user/user.state';
import { ensureChildrenRequired } from '../../shared/utils/rxjs';
import {
  existingInstallerInvitedPrivately,
  existingInstallerInvitedPublicly,
  existingInstallerInvitedSuccessfully,
  existingInstallerInviteFailed,
  installerInviteProcessCompleting,
  newInstallerConnectionCreating,
  newInstallerInvitedPrivately,
  newInstallerInvitedPublicly,
  newInstallerInvitedSuccessfully,
  newInstallerInviteFailed,
} from './company-installer-invites.actions';
import { InvitesUIService } from './invites-ui.service';
import {
  inviteCompanyPrivateConnection,
  inviteCompanyPublicConnection,
  inviteProcessInitiated,
} from './invites.actions';
import { InvitesService } from './invites.service';

@Injectable()
export class CompanyInstallerInvitesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly ui: InvitesUIService,
    private readonly invites: InvitesService,
  ) {}

  showCompanyInviteModalForPublicConnection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(inviteCompanyPublicConnection),
        exhaustMap(() =>
          this.ui
            .showCompanyInviteModal('INSTALLER_TEAM_LEAD', 'Public Connection')
            .pipe(
              switchMap(modal =>
                of().pipe(
                  takeUntil(from(modal.onDidDismiss()).pipe(tap(() => console.log('Invite modal dismissed...')))),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );

  showCompanyInviteModalForPrivateConnection$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(inviteCompanyPrivateConnection),
        exhaustMap(() =>
          this.ui
            .showCompanyInviteModal('INSTALLER_TEAM_LEAD', 'Private Connection')
            .pipe(
              switchMap(modal =>
                of().pipe(
                  takeUntil(from(modal.onDidDismiss()).pipe(tap(() => console.log('Invite modal dismissed...')))),
                ),
              ),
            ),
        ),
      ),
    { dispatch: false },
  );

  waitForPublicInviteCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteCompanyPublicConnection),
      concatMap(() =>
        this.actions$.pipe(
          ofType(inviteProcessInitiated),
          first(),
          map(({ invite, user }) =>
            user ? existingInstallerInvitedPublicly({ user }) : newInstallerInvitedPublicly({ invite }),
          ),
        ),
      ),
    ),
  );

  waitForPrivateInviteCompletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(inviteCompanyPrivateConnection),
      concatMap(() =>
        this.actions$.pipe(
          ofType(inviteProcessInitiated),
          first(),
          map(({ invite, user }) =>
            user ? existingInstallerInvitedPrivately({ user }) : newInstallerInvitedPrivately({ invite }),
          ),
        ),
      ),
    ),
  );

  inviteExistingInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(existingInstallerInvitedPublicly, existingInstallerInvitedPrivately),
      switchMap(({ user, type }) =>
        this.invites
          .sendUserInvite({
            email: user.email,
            source: type === existingInstallerInvitedPrivately.type ? Source.CompanyPrivate : Source.Company,
          })
          .pipe(
            map(connection =>
              existingInstallerInvitedSuccessfully({
                connection,
                source: type === existingInstallerInvitedPrivately.type ? Source.CompanyPrivate : Source.Company,
              }),
            ),
            catchError(error => of(existingInstallerInviteFailed({ error }))),
          ),
      ),
    ),
  );

  trackConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(existingInstallerInvitedSuccessfully),
      map(({ connection }) => connectionCreationSucceeded({ entity: connection })),
    ),
  );

  loadExistingInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(existingInstallerInvitedSuccessfully),
      map(({ connection }) => loadUser({ keys: connection.installerTeamLeadId })),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(action => [installerInviteProcessCompleting({ correlationId: action.correlationId }), action]),
    ),
  );

  inviteNewInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newInstallerInvitedPublicly, newInstallerInvitedPrivately),
      switchMap(({ invite, type }) =>
        this.invites
          .addNewUser({
            ...invite,
            role: 'INSTALLER_TEAM_LEAD',
          })
          .pipe(
            map(({ userId }) =>
              newInstallerInvitedSuccessfully({
                userId,
                source: type === newInstallerInvitedPrivately.type ? Source.CompanyPrivate : Source.Company,
              }),
            ),
            catchError(error => of(newInstallerInviteFailed({ error }))),
          ),
      ),
    ),
  );

  createNewInstallerConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newInstallerInvitedSuccessfully),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ userId, source }, authUser]) => ({ userId, source, authUser })),
      ensureChildrenRequired<
        { userId: string; source: Source; authUser?: User },
        { userId: string; source: Source; authUser: CompanyUser }
      >(value => !!value?.authUser && isCompanyUser(value.authUser)),
      map(({ userId, source, authUser }) =>
        createConnection({
          entity: {
            companyId: authUser.companyId,
            installerTeamLeadId: userId,
            source,
            status: ConnectionStatus.Pending,
            statusChangedOn: new Date().toISOString(),
          },
        }),
      ),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(action => [
        newInstallerConnectionCreating({
          correlationId: action.correlationId,
          userId: action.entity.installerTeamLeadId,
        }),
        action,
      ]),
    ),
  );

  loadNewInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newInstallerConnectionCreating),
      switchMap(({ correlationId: expectedCorrelationId, userId }) =>
        this.actions$.pipe(
          ofType(connectionCreationSucceeded),
          first(({ correlationId }) => expectedCorrelationId === correlationId),
          map(() => userId),
        ),
      ),
      map(userId => loadUser({ keys: userId })),
      // eslint-disable-next-line @ngrx/no-multiple-actions-in-effects
      switchMap(action => [installerInviteProcessCompleting({ correlationId: action.correlationId }), action]),
    ),
  );
}
