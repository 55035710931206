import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { FacilityType } from './facility-type.model';

export const {
  initialState: initialFacilityTypeState,
  facade: FacilityTypeFacadeBase,
  selectors: { selectAll: allFacilityTypes, selectAllSorted: sortedFacilityTypes, selectEntities: facilityTypeEntities},
  actions: { loadAllIfNecessary: loadAllFacilityTypesIfNecessary },
} = buildState(FacilityType);

const reduce = createReducer(initialFacilityTypeState);

export function facilityTypeReducer(state = initialFacilityTypeState, action: Action): IEntityState<FacilityType> {
  return reduce(state, action);
}
