import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { AddressType } from './address-type.model';

export const {
  initialState: initialAddressTypeState,
  facade: AddressTypeFacadeBase,
  selectors: { selectAll: allAddressTypes },
} = buildState(AddressType);

const reduce = createReducer(initialAddressTypeState);

export function addressTypeReducer(state = initialAddressTypeState, action: Action): IEntityState<AddressType> {
  return reduce(state, action);
}
