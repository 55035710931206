import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './store/feature.name';
import { reducer } from './store/feature.state';
import { FiltersEffects } from './store/filters.effects';
import { SettingsEffects } from './store/settings.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([FiltersEffects, SettingsEffects]),
  ],
})
export class CoreCalendarModule {}
