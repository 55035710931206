import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { BankAccount } from './bank-account.model';
import { BankAccountFacadeBase } from './bank-account.state';

@Injectable()
export class BankAccountFacade extends BankAccountFacadeBase {
  constructor(private store: Store<AppState>) {
    super(BankAccount, store);
  }
}
