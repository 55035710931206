import { createSelector } from '@ngrx/store';
import { authenticatedUser } from '../app/auth/auth.selectors';
import { currentUser } from '../users/user/user.state';
import { filterByUser } from '../users/utils';
import { Category } from './category/category.model';
import { allCategories, currentCategory } from './category/category.state';
import { DetailedTrainingClaim } from './models/detailed-training-claim';
import { ClaimableProductInstallationType } from './models/obtainable-product-installation-types';
import { ProductInstallationTypeClaimed } from './product-installation-types-claimed/product-installation-types-claimed.model';
import { allProductInstallationTypeClaimed } from './product-installation-types-claimed/product-installation-types-claimed.state';
import { ProductInstallationType } from './product-installation-types/product-installation-types.model';
import {
  allProductInstallationTypes,
  currentProductInstallationType,
} from './product-installation-types/product-installation-types.state';
import { TrainingFile } from './training-files/training-file.model';
import { allTrainingFiles } from './training-files/training-file.state';
import { Training } from './training/training.model';
import { allTrainings } from './training/training.state';
import { TrainingClaimed } from './trainings-claimed/trainings-claimed.model';
import { allTrainingsClaimed } from './trainings-claimed/trainings-claimed.state';

// -----===[ GETTERS & PROJECTIONS ]===-----

export const attachMatchingClaimForInstallationType =
  (claims: ProductInstallationTypeClaimed[]) => (type: ProductInstallationType) => {
    const claimed = claims.find(claim => claim.productInstallationTypeId === type?.id);
    return {
      ...type,
      claimed,
    };
  };

export const joinProductInstallationTypesToClaimed = (
  types: ProductInstallationType[],
  claimed: ProductInstallationTypeClaimed[],
): ClaimableProductInstallationType[] => {
  const derp = types.map(attachMatchingClaimForInstallationType(claimed));
  return derp;
};

export const findTrainingsClaimedForInstallType = (
  claimed: DetailedTrainingClaim[],
  installType?: ProductInstallationType,
): DetailedTrainingClaim[] => claimed.filter(claim => claim.productInstallationTypeId === installType?.id);

// -----===[ SELECTORS ]===-----

export const currentUserProductInstallationTypesClaimed = createSelector(
  allProductInstallationTypeClaimed,
  currentUser,
  filterByUser,
);

export const authenticatedUserProductInstallationTypesClaimed = createSelector(
  allProductInstallationTypeClaimed,
  authenticatedUser,
  filterByUser,
);

export const approvedAuthenticatedUserInstallationTypes = createSelector(
  authenticatedUserProductInstallationTypesClaimed,
  installationTypes => installationTypes.filter(type => type.isVerified),
);

export const claimableProductInstallationTypes = createSelector(
  allProductInstallationTypes,
  currentUserProductInstallationTypesClaimed,
  joinProductInstallationTypesToClaimed,
);

export const currentClaimedProductInstallationType = createSelector(
  claimableProductInstallationTypes,
  currentProductInstallationType,
  (claimables: ClaimableProductInstallationType[], current?: ProductInstallationType) =>
    claimables.find(claimable => claimable.id === current?.id),
);

export const currentUserTrainingsClaimed = createSelector(allTrainingsClaimed, currentUser, filterByUser);

export const currentDetailedTrainingsClaimed = createSelector(
  allTrainings,
  allCategories,
  allProductInstallationTypes,
  currentUserTrainingsClaimed,
  (
    trainings: Training[],
    categories: Category[],
    installationTypes: ProductInstallationType[],
    claimed: TrainingClaimed[],
  ): DetailedTrainingClaim[] =>
    claimed.map(claim => ({
      ...claim,
      category: categories?.find(category => category.id === claim.categoryId),
      installationType: installationTypes?.find(
        installationType => installationType.id === claim.productInstallationTypeId,
      ),
      training: trainings?.find(training => training.id === claim.trainingId),
    })),
);

export const currentInstallTypeCategories = createSelector(
  allCategories,
  currentProductInstallationType,
  (categories: Category[], installType?: ProductInstallationType) =>
    categories?.filter(category => category.productInstallationTypeId === installType?.id) ?? [],
);

export const currentUserTrainingsClaimedForCurrentInstallType = createSelector(
  currentDetailedTrainingsClaimed,
  currentProductInstallationType,
  findTrainingsClaimedForInstallType,
);

export const unclaimedTrainingsForCurrentCategory = createSelector(
  allTrainings,
  currentUserTrainingsClaimed,
  currentCategory,
  (trainings: Training[], trainingsClaimed: TrainingClaimed[], category?: Category) =>
    category
      ? trainings.filter(
          training =>
            training.categoryId === category.id &&
            !trainingsClaimed.some(claimed => claimed.trainingId === training.id),
        )
      : [],
);

export const currentInstallTypeTrainingFiles = createSelector(
  allTrainingFiles,
  currentUserProductInstallationTypesClaimed,
  currentProductInstallationType,
  (trainingFiles: TrainingFile[], claimed: ProductInstallationTypeClaimed[], installType?: ProductInstallationType) =>
    trainingFiles?.filter(
      file =>
        file.productInstallationTypeClaimedId ===
        claimed?.find(claim => claim.productInstallationTypeId === installType?.id)?.id,
    ) ?? [],
);
