import { T } from '@fullcalendar/core/internal-common';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { EntityKey } from '../../domains/entity.service.utils';
import { GenericFile } from '../../domains/models/generic-file';
import { PendingFile } from '../../domains/models/pending-file';
import { FileState } from './file-state';

export const mapToPendingUploads = <T extends GenericFile>(state: FileState<T>) =>
  state.pendingUploads
    ? state.pendingUploads.map(pend => ({
        ...pend,
        processing: !pend.failed,
        progressPercent:
          (pend.progress?.loaded ?? 0) > 0 ? (pend.progress?.loaded ?? 0) / (pend.progress?.total ?? 1) : 0,
      }))
    : undefined;

export const selectPendingUploadsFactory = <T extends GenericFile>(getState: (state: any) => FileState<T>) =>
  createSelector(getState, mapToPendingUploads);

export const selectPendingUploadByCorrelationIdFactory =
  <T extends GenericFile>(getState: (state: any) => FileState<T>) =>
  (correlationId: string) =>
    createSelector(
      selectPendingUploadsFactory(getState),
      uploads => uploads?.find(upload => upload.correlationId === correlationId),
    );

export const selectPendingDeleteFactory = <T extends GenericFile>(getState: (state: any) => FileState<T>) =>
  createSelector(getState, state => state.pendingDelete || undefined);

export type ProcessingFile<T extends GenericFile> = (PendingFile | T) & {
  processing: boolean;
  progressPercent?: number;
};

export const buildProcessingFiles = <T extends GenericFile>(
  files: T[],
  deletes: EntityKey[] | undefined,
): ProcessingFile<T>[] => [
  ...files.map(file => ({
    ...file,
    processing: file.id ? deletes?.includes(file.id) ?? false : false,
    progressPercent: 1,
  })),
];

export const selectPendingFilesFactory = <T extends GenericFile>(
  selectPendingUploads: MemoizedSelector<unknown, ProcessingFile<PendingFile>[] | undefined>,
  selectPendingDelete: MemoizedSelector<unknown, EntityKey[] | undefined>,
  selectAll: MemoizedSelector<FileState<T>, T[] | undefined>,
) =>
  createSelector(
    selectPendingUploads,
    selectPendingDelete,
    selectAll,
    (uploads, deletes, files): ProcessingFile<T>[] | undefined =>
      !!files && !!uploads
        ? [...buildProcessingFiles(files, deletes), ...uploads]
        : !!uploads
        ? uploads
        : !!files
        ? buildProcessingFiles(files, deletes)
        : undefined,
  );
