<div class="flex align-items-stretch title-container">
  <h1 class="modal-title" data-cy="background-title">Background</h1>
</div>
<ng-container *rxLet="context$; let context">
  <ion-list *ngIf="context?.installer?.bio" lines="none" data-cy="installer-bio-section">
    <ion-list-header>
      <h2 data-cy="installer-bio-header">More About {{ context.installer.firstName }}</h2>
    </ion-list-header>
    <ion-item data-cy="installer-bio">
      <ion-label class="ion-text-wrap" [attr.data-cy]="context.installer.firstName + 'bio-section'">
        {{ context?.installer?.bio }}
      </ion-label>
    </ion-item>
  </ion-list>

  <div *ngIf="context?.installer | asInstallerTeamLead as teamLead">
    <ion-list
      lines="none"
      *ngIf="teamLead.commercialWorkPercentage as commercialWorkPercentage"
      data-cy="work-experience-section"
    >
      <ion-list-header>
        <h2 data-cy="work-experience-header">Work Experience</h2>
      </ion-list-header>
      <ion-item [attr.data-cy]="context.installer.firstName + 'work-experience-details'">
        <ion-label
          class="ion-text-wrap"
          *ngIf="commercialWorkPercentage >= 50"
          style="white-space: normal"
          data-cy="commercial-work-description"
        >
          {{ teamLead.firstName }} has {{ commercialWorkPercentage }}% commercial work experience over the last three
          years indicating a focus on commercial work with just {{ 100 - commercialWorkPercentage }}% residential work.
        </ion-label>
        <ion-label
          class="ion-text-wrap"
          *ngIf="commercialWorkPercentage < 50"
          style="white-space: normal"
          data-cy="residential-work-description"
        >
          {{ teamLead.firstName }} has {{ 100 - commercialWorkPercentage }}% residential work experience over the last three
          years indicating a focus on residential work with just {{ commercialWorkPercentage }}% commercial work.
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  <ion-grid *ngIf="canEditProfile && context?.installer">
    <ion-row *ngIf="percentCommercialForm" data-cy="work-experience-editor">
      <ion-col>
        <ion-card id="percent-commercial-collection" class="ion-padding ion-no-margin" mode="ios">
          <ion-card-header *ngIf="!percentCommercialForm.disabled">
            <ion-card-title class="ion-text-center">
              Over the last 3 years, what portion of your work was residential versus commercial?
            </ion-card-title>
          </ion-card-header>
          <ion-card-content class="ion-no-padding">
            <ion-item lines="none">
              <ion-range
                [formControl]="percentCommercialForm"
                min="0"
                max="100"
                step="1"
                class="ion-no-padding"
                data-cy="work-experience-form-slider"
              >
                <ion-text
                  slot="start"
                  class="ion-text-center"
                  style="margin-right: 20px"
                  data-cy="residential-work-percentage"
                >
                  <span
                    style="font-size: 1.5em; font-weight: bold"
                    [attr.data-cy]="100 - percentCommercialForm.value + '-percent-residential'"
                    >{{ 100 - percentCommercialForm.value }} %</span
                  >

                  <span style="display: block">Residential</span>
                </ion-text>
                <ion-text
                  slot="end"
                  class="ion-text-center"
                  style="margin-left: 20px"
                  data-cy="commercial-work-percentage"
                >
                  <span
                    style="font-size: 1.5em; font-weight: bold"
                    [attr.data-cy]="percentCommercialForm.value + '-percent-commercial'"
                    >{{ percentCommercialForm.value }} %</span
                  >
                  <span style="display: block">Commercial</span>
                </ion-text>
              </ion-range>
            </ion-item>
          </ion-card-content>
          <ion-button
            *ngIf="!percentCommercialForm.disabled"
            [disabled]="percentCommercialForm.invalid"
            class="ion-margin-top"
            color="gc-green"
            expand="block"
            (click)="save(context.installer)"
            data-cy="work-experience-form-save"
          >
            Save
          </ion-button>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ng-container>
