import { Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ClaimProductInstallationSubtypesModalComponent } from '../../modals/claim-product-installation-subtypes-modal/claim-product-installation-subtypes-modal.component';


@Injectable()
export class ProductInstallationSubtypesUiService {
  constructor(
    private readonly modalGC: ModalController,
    private readonly alert: AlertController,
  ) {}

  showClaimSubtypesModal() {
    return from(
      this.modalGC.create({
        component: ClaimProductInstallationSubtypesModalComponent,
        cssClass: 'rounded-modal',
      }),
    ).pipe(
      switchMap(modal =>
        from(modal.present()).pipe(
          switchMap(() => modal.onDidDismiss()),
          map(({ role }) => ({ role })),
        ),
      ),
    );
  }

  confirmRemoveSubtypeClaim() {
    return from(
      this.alert.create({
        header: 'Remove Area of Interest',
        message: `Are you sure you want to remove this?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'button-text-dark',
          },
          {
            text: 'Remove',
            role: 'ok',
            cssClass: 'button-text-red',
          },
        ],
      }),
    ).pipe(
      switchMap(alert =>
        from(alert.present()).pipe(
          switchMap(() => alert.onDidDismiss()),
          map(({ role }) => ({ role })),
        ),
      ),
    );
  }}
