import { createSelector } from '@ngrx/store';
import { get } from '../../../shared/utils/func';
import { modalState } from './modal.name';


export const uiState = createSelector(modalState, get('ui'));

export const installTypeBeingClaimed = createSelector(uiState, get('claiming'));

export const added = createSelector(uiState, get('added'));

export const pending = createSelector(uiState, get('pending'));

export const claiming = createSelector(uiState, get('claiming'));
