<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-center">Manage Card Payments</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding ion-text-center">
  <ion-img src="assets/images/logos/stripe/stripe-wordmark-blurple.svg"></ion-img>
  <ion-text>Tap the button below to visit Stripe and manage card payments.</ion-text>
  <ion-button color="light" size="large" expand="block" mode="ios" class="ion-margin-top" (click)="profile.managePayments()">
    View Dashboard
    <ion-icon
      *ngIf="!(profile.managePaymentsLoading$ | push)"
      name="enter-outline"
      slot="end"
      color="dark"
    ></ion-icon>
    <ion-spinner *ngIf="profile.managePaymentsLoading$ | push" slot="end" name="dots" color="dark"></ion-spinner>
  </ion-button>
  <ion-button fill="clear" color="dark" expand="full" mode="ios" (click)="dismiss()">Cancel</ion-button>
</ion-content>
