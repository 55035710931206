import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { EmptyListWarningModule } from '../../shared/components/empty-list-warning/empty-list-warning.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { CallPhoneNumbersModalComponent } from './call-phone-numbers-modal/call-phone-numbers-modal.component';

@NgModule({
  declarations: [CallPhoneNumbersModalComponent],
  imports: [CommonModule, IonicModule, EmptyListWarningModule, PipesModule, PushModule],
})
export class CallPhoneNumbersModule {}
