import { createReducer, on } from '@ngrx/store';
import {
  phoneNumberVerificationFailure,
  phoneNumberVerificationInitiated,
  phoneNumberVerificationSuccess,
  phoneNumberVerificationTimedOut,
} from '../phone-numbers.actions';


export type VerifyingPhoneNumberDetails = Record<string, PhoneNumberVerificationStatus | undefined>;

export interface PhoneNumberVerificationStatus {
  isVerifying: boolean;
  error?: string;
}

export interface PhoneNumberManagementState {
  verifyingPhoneNumbers: VerifyingPhoneNumberDetails;
}

export const initialState: PhoneNumberManagementState = {
  verifyingPhoneNumbers: {},
};

export const phoneNumberManagementReducer = createReducer(
  initialState,
  on(
    phoneNumberVerificationInitiated,
    (state, { phoneNumber }): PhoneNumberManagementState => ({
      ...state,
      verifyingPhoneNumbers: {
        ...state.verifyingPhoneNumbers,
        [phoneNumber.number]: {
          isVerifying: true,
          error: undefined,
        },
      },
    }),
  ),
  on(
    phoneNumberVerificationFailure,
    (state, { phoneNumber, error }): PhoneNumberManagementState => ({
      ...state,
      verifyingPhoneNumbers: {
        ...state.verifyingPhoneNumbers,
        [phoneNumber.number]: {
          isVerifying: false,
          error,
        },
      },
    }),
  ),
  on(
    phoneNumberVerificationSuccess,
    phoneNumberVerificationTimedOut,
    (state, { phoneNumber }): PhoneNumberManagementState => ({
      ...state,
      verifyingPhoneNumbers: {
        ...state.verifyingPhoneNumbers,
        [phoneNumber.number]: undefined,
      },
    }),
  ),
);
