import { Pipe, PipeTransform } from '@angular/core';
import { CompanyRoles, InstallerRoles, isRole, RoleKeys } from '../../domains/app/auth/auth.maps';

@Pipe({ name: 'isRole' })
export class IsRolePipe implements PipeTransform {
  transform(currentRole?: string, ...roles: RoleKeys[]): boolean {
    return isRole(currentRole, ...roles);
  }
}

@Pipe({ name: 'isCompanyRole' })
export class IsCompanyRolePipe implements PipeTransform {
  transform(currentRole: string): boolean {
    return isRole(currentRole, ...CompanyRoles);
  }
}

@Pipe({ name: 'isInstallerRole' })
export class IsInstallerRole implements PipeTransform {
  transform(currentRole: string): boolean {
    return isRole(currentRole, ...InstallerRoles);
  }
}
