import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../services/config.service';

export const VERSION_HEADER = 'X-Client-Version';
export const PLATFORM_HEADER = 'X-Client-Platform';
export const CAPABILITIES_HEADER = 'X-Client-Capabilities';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  constructor(private readonly config: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.shouldHandleRoute(req.url)
      ? next.handle(
          req.clone({
            setHeaders: {
              [VERSION_HEADER]: environment.version.frontend,
              [PLATFORM_HEADER]: Capacitor.isNativePlatform() ? 'mobile' : 'web',
              [CAPABILITIES_HEADER]: 'custom-dates declined-matched-installers',
            },
          }),
        )
      : next.handle(req);
  }

  private shouldHandleRoute(url: string): boolean {
    return url.startsWith(this.config.host);
  }
}
