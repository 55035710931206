import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MaskitoModule } from '@maskito/angular';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { AdvancedPhoneNumberManagerComponent } from './advanced-phone-number-manager.component';

@NgModule({
  declarations: [AdvancedPhoneNumberManagerComponent],
  imports: [CommonModule, IonicModule, ReactiveFormsModule, PipesModule, MaskitoModule],
  exports: [AdvancedPhoneNumberManagerComponent],
})
export class AdvancedPhoneNumberManagerModule {}
