import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NegotiableWorkOrderItem } from '~gc/domains/models/negotiable-work-order-item';
import { DisplayGroupAllowance, WorkOrderItemGroup } from '~gc/domains/work-order-item-group.selectors';
import { WorkOrderItem } from '~gc/domains/work/work-order-item/work-order-item.model';


export interface GroupContext {
  group: WorkOrderItemGroup;
}

export interface GroupItemContext {
  item: WorkOrderItem | NegotiableWorkOrderItem;
  group: WorkOrderItemGroup;
}

@Component({
  selector: '∂gc-templated-work-order-item-list',
  templateUrl: './templated-work-order-item-list.component.html',
  styleUrls: ['./templated-work-order-item-list.component.scss'],
})
export class TemplatedWorkOrderItemListComponent {
  @ViewChild('defaultItemTotal') defaultItemTotal!: TemplateRef<GroupItemContext>;
  @Input() groups!: WorkOrderItemGroup[];
  @Input() groupHeader?: TemplateRef<GroupContext>;
  @Input() itemExpander?: TemplateRef<GroupItemContext>;
  @Input() itemNote?: TemplateRef<GroupItemContext>;
  @Input() itemExtra?: TemplateRef<GroupItemContext>;
  @Input() inlineButtons?: TemplateRef<GroupItemContext>;
  @Input() expandedDetail?: TemplateRef<GroupItemContext>;
  @Input() role?: string;
  @Input() allowedPriceBreakdown: boolean = false;
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];

  constructor() {
    if (!this.itemNote) {
      this.itemNote = this.defaultItemTotal;
    }
  }

  trackGroupBy(index: number, group: WorkOrderItemGroup) {
    return index;
  }

  trackItemBy(index: number, item: WorkOrderItemGroup['items'][number]) {
    return item.id;
  }
}
