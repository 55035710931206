import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { EmptyListWarningModule } from '../empty-list-warning/empty-list-warning.module';
import { PhoneNumberListComponent } from './phone-number-list.component';

@NgModule({
  declarations: [PhoneNumberListComponent],
  imports: [CommonModule, IonicModule, EmptyListWarningModule, PipesModule],
  exports: [PhoneNumberListComponent],
})
export class PhoneNumberListModule {}
