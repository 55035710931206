<mat-form-field appearance="fill" class="w-100">
  <mat-label>
    <ng-content></ng-content>
  </mat-label>
  <input
    matInput
    [formControl]="control!"
    [placeholder]="placeHolder"
    [matDatepicker]="picker"
    (dateChange)="dismissed.emit('confirm')"
  />
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
