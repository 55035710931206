import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MaskitoModule } from '@maskito/angular';
import { PushModule } from '@rx-angular/template/push';
import { PhoneNumberManagerModule } from '../../shared/components/phone-number-manager/phone-number-manager.module';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { UnapprovedInstallerSaveSuccessPopoverComponent } from './unapproved-installer-save-success-popover/unapproved-installer-save-success-popover.component';
import { UnapprovedInstallerComponent } from './unapproved-installer.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    ReactiveFormsModule,
    PhoneNumberManagerModule,
    DirectivesModule,
    PushModule,
    MaskitoModule,
  ],
  declarations: [UnapprovedInstallerComponent, UnapprovedInstallerSaveSuccessPopoverComponent],
  exports: [UnapprovedInstallerComponent, UnapprovedInstallerSaveSuccessPopoverComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UnapprovedInstallerModule {}
