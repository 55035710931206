import { createSelector } from '@ngrx/store';
import { DetailedInstaller } from '../../models/detailed-installer';
import { AppState } from '../../state';
import { InstallerReviewState } from './installer-review.state';

export const getInstallerReviewState = (state: AppState): InstallerReviewState => state.installerReview;

export const getCurrentInstaller = (state: InstallerReviewState): DetailedInstaller | undefined =>
  state.currentInstaller;

export const currentInstaller = createSelector(getInstallerReviewState, getCurrentInstaller);
