import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Category } from '../../../domains/training/category/category.model';
import { DetailedTrainingClaim } from '../../../domains/training/models/detailed-training-claim';
import { ClaimableProductInstallationType } from '../../../domains/training/models/obtainable-product-installation-types';
import { ProductInstallationTypeClaimed } from '../../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';

@Component({
  selector: 'gc-claimed-trainings-list',
  templateUrl: './claimed-trainings-list.component.html',
  styleUrls: ['./claimed-trainings-list.component.scss'],
})
export class ClaimedTrainingsListComponent {
  @Input() installationType?: ClaimableProductInstallationType;
  @Input() categories!: Category[];
  @Input() claims!: DetailedTrainingClaim[];

  @Output() added = new EventEmitter<{ category: Category; claimedInstallType: ProductInstallationTypeClaimed }>();
  @Output() deleted = new EventEmitter<DetailedTrainingClaim>();
}
