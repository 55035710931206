import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { LetModule } from '@rx-angular/template/let';
import { PushModule } from '@rx-angular/template/push';
import { StreamAutocompleteTextareaModule, StreamChatModule } from 'stream-chat-angular';
import { PipesModule } from '../pipes/pipes.module';
import { ChannelHasUnreadPipe } from './channel-has-unread.pipe';
import { ChannelItemComponent } from './channel-item/channel-item.component';
import { ChatContextWarningComponent } from './chat-context-warning/chat-context-warning.component';
import { ChatUnreadCountComponent } from './chat-unread-count/chat-unread-count.component';
import { ChatUnreadWidgetComponent } from './chat-unread-widget/chat-unread-widget.component';
import { ChatComponent } from './chat.component';
import { IdsToChannelsPipe } from './id-to-channel.pipe';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    StreamAutocompleteTextareaModule,
    StreamChatModule,
    PipesModule,
    PushModule,
    LetModule,
  ],
  declarations: [
    ChatComponent,
    ChannelItemComponent,
    ChatContextWarningComponent,
    ChatUnreadCountComponent,
    IdsToChannelsPipe,
    ChannelHasUnreadPipe,
    ChatUnreadWidgetComponent,
  ],
  exports: [
    ChatComponent,
    ChatUnreadWidgetComponent,
    ChatUnreadCountComponent,
    ChannelHasUnreadPipe,
    IdsToChannelsPipe,
  ],
})
export class ChatModule {}
