import { Entity, Key } from '@briebug/ngrx-auto-entity';

export const setBODTime = (date: string) => `${date.substr(0, date.indexOf('T'))}T00:00:00.000`;
export const setEODTime = (date: string) => `${date.substr(0, date.indexOf('T'))}T23:59:59.999`;

export const makeAllDay = (startProp: keyof CustomDate, endProp: keyof CustomDate) => ({
  fromServer: (data: any) => (
    (data[startProp] = setBODTime(data[startProp])), (data[endProp] = setEODTime(data[endProp])), data
  ),
  toServer: (entity: any) => (
    (entity[startProp] = `${entity[startProp]}Z`), (entity[endProp] = `${entity[endProp]}Z`), entity
  ),
});

export const stripZulu = (date: string) => (date.endsWith('Z') ? date.substr(0, date.length - 1) : date);
export const stripTZ = (date: string, idxDash = date.lastIndexOf('-')) =>
  idxDash !== -1 ? date.substr(0, idxDash) : date;

export const stripTimeZone = (prop: string) => ({
  fromServer: (data: any) => ((data[prop] = stripZulu(data[prop])), (data[prop] = stripTZ(data[prop])), data),
  toServer: (entity: any) => (
    (entity[prop] = stripZulu(entity[prop])),
    (entity[prop] = stripTZ(entity[prop])),
    (entity[prop] = `${entity[prop]}Z`),
    entity
  ),
});

export const valueOfDate = (value: string) => new Date(value).valueOf();

export const attachDateValue = (prop: string) => ({
  fromServer: (data: any) => ((data[`${prop}Value`] = valueOfDate(data[prop])), data),
  toServer: (entity: any) => ((entity[`${prop}Value`] = undefined), entity),
});

export const attachType = {
  fromServer: (data: CustomDate) => (
    (data.type = data.userId ? 'userevent' : data.workOrderId ? 'workorder' : 'changeorder'), data
  ),
  toServer: (entity: CustomDate) => ((entity.type = undefined), entity),
};

export type CustomDateType = 'workorder' | 'changeorder' | 'userevent';

@Entity('CustomDate', {
  uriName: 'custom-dates',
  comparer: (a, b) => a.startDate.localeCompare(b.startDate, undefined, { numeric: true }),
  transform: [attachType, makeAllDay('startDate', 'endDate'), attachDateValue('startDate'), attachDateValue('endDate')],
})
export class CustomDate {
  @Key id?: string;
  startDate!: string;
  endDate!: string;
  startDateValue!: number;
  endDateValue!: number;
  description?: string;
  title?: string;
  userId?: string;
  workOrderId?: string;
  workOrderItemId?: string;

  type?: CustomDateType;
}
