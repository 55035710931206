import { Component, Input } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { DisplayGroupAllowance, WorkOrderItemGroup } from '../../../../domains/work-order-item-group.selectors';

@Component({
  selector: 'gc-item-price-display',
  templateUrl: './item-price-display.component.html',
  styleUrls: ['./item-price-display.component.scss'],
})
export class ItemPriceDisplayComponent {
  @Input() allowedPrivateDetails!: boolean;
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];
  @Input() group!: WorkOrderItemGroup;
  @Input() item!: DetailedWorkOrderItem;
}
