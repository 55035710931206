import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DisplayGroupAllowance, DisplayOptions, WorkOrderItemGroup } from '../../../../domains/work-order-item-group.selectors';

@Component({
  selector: 'gc-item-group-header',
  templateUrl: './item-group-header.component.html',
  styleUrls: ['./item-group-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemGroupHeaderComponent {
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];
  @Input() group!: WorkOrderItemGroup;
}
