import { createAction, props } from '@ngrx/store';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';


export const productInstallationTypeClaiming = createAction(
  '[Claim Product Installation Type Modal] (Claiming) Product Installation Type',
  props<{ installType: ProductInstallationType }>(),
);

export const claimProductInstallationTypeCancelled = createAction('[Claim Product Installation Type Modal] (Claiming) Cancelled');

export const claimProductInstallationTypeDateChosen = createAction(
  '[Claim Product Installation Type Modal] (Claiming) Date Chosen',
  props<{ date: string }>(),
);
