import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, IonicSafeString, ModalController, PopoverController, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { Invoice, InvoiceStatus } from '~gc/domains/invoices/invoice/invoice.model';
import { RouterOutletService } from '~gc/domains/router-outlet.service';
import { User } from '~gc/domains/users';
import { Company } from '~gc/domains/users/companies/company.model';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { CreateInvoiceModalComponent } from '~gc/modals/create-invoice/create-invoice-modal/create-invoice-modal.component';
import { DeclineInvoiceModalComponent } from '~gc/modals/decline-invoice/decline-invoice-modal.component';
import { LienReleaseModalComponent } from '~gc/modals/lien-release/lien-release.component';
import { PayInvoiceComponent } from '~gc/modals/pay-invoice/pay-invoice.component';
import { ViewInvoiceModalComponent } from '~gc/modals/view-invoice-modal/view-invoice-modal.component';
import { CustomListPopoverComponent } from '~gc/shared/popovers/custom-list-popover/custom-list-popover.component';
import { ReviewInvoiceModalComponent } from '../../modals/invoicing/review-invoice/review-invoice-modal/review-invoice-modal.component';
import { ListOption } from '../../shared/popovers/types/list-options';
import { waitOn } from '../../shared/utils/operators';
import { tap } from '../../shared/utils/utils';
import { ViewInvoiceContext } from './invoice-ui.selectors';

@Injectable()
export class InvoiceUIService {
  private currentAddInvoiceModal?: HTMLIonModalElement;
  private currentReviewInvoiceModal?: HTMLIonModalElement;
  private currentViewInvoiceModal?: HTMLIonModalElement;
  private currentLienReleaseModal?: HTMLIonModalElement;

  constructor(
    private popovers: PopoverController,
    private modals: ModalController,
    private alerts: AlertController,
    private toasts: ToastController,
    private readonly routerOutletService: RouterOutletService,
  ) {}

  async confirmInvoiceAction(title: string, message: string, okLabel: string) {
    const alert = await this.alerts.create({
      message,
      header: title,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        { text: okLabel, role: 'ok' },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async confirmDecline(invoice: Invoice) {
    const modal = await this.modals.create({
      component: DeclineInvoiceModalComponent,
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      componentProps: {
        invoice,
      },
    });
    await modal.present();
    return modal.onDidDismiss();
  }

  async showPaymentMethods(invoice: Invoice, invoicer: User, workOrder: WorkOrder) {
    const modal = await this.modals.create({
      component: PayInvoiceComponent,
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      componentProps: {
        invoice,
        invoicer,
        workOrder,
      },
    });
    await modal.present();
    return modal.onDidDismiss();
  }

  async showInvoiceOptions(event: Event, listOptions: ListOption[]) {
    const popover = await this.popovers.create({
      component: CustomListPopoverComponent,
      componentProps: {
        title: 'Actions',
        listOptions,
      },
      event,
    });
    await popover.present();
    return await popover.onDidDismiss();
  }

  async toastInvoiceCreationError(error: Error & { lastError?: HttpErrorResponse }): Promise<void> {
    const toast = await this.toasts.create({
      header: 'Error Creating Invoice',
      message:
        error instanceof HttpErrorResponse
          ? 'Unable to create Invoice. Check your internet connection.'
          : error.message || 'Unknown error creating Invoice.',
      color: 'danger',
      position: 'bottom',
      duration: 5000,
      buttons: [
        {
          icon: 'close',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }

  async toastInvoiceUpdateError(error: Error): Promise<void> {
    const toast = await this.toasts.create({
      header: 'Error Updating Invoice',
      message:
        error instanceof HttpErrorResponse &&
        error.status === 400 &&
        error.error.message?.includes(InvoiceStatus.Voided)
          ? `We were unable to perform this action. The Invoice was already voided.`
          : `We were unable to update the Invoice. Reloading...`,
      color: 'danger',
      position: 'bottom',
      duration: 5000,
      buttons: [
        {
          text: 'Dismiss',
          side: 'end',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }

  async showViewInvoiceModal(context: ViewInvoiceContext) {
    this.currentViewInvoiceModal = await this.modals.create({
      component: ViewInvoiceModalComponent,
      componentProps: { context },
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      backdropDismiss: false,
    });

    await this.currentViewInvoiceModal.present();
    return await this.currentViewInvoiceModal.onDidDismiss();
  }

  async closeViewInvoiceModal() {
    if (this.currentViewInvoiceModal) {
      await this.currentViewInvoiceModal.dismiss(undefined, 'close');
      this.currentViewInvoiceModal = undefined;
    }
  }

  async hideViewInvoiceModal() {
    if (this.currentViewInvoiceModal) {
      await this.currentViewInvoiceModal.dismiss(undefined, 'hide');
      this.currentViewInvoiceModal = undefined;
    }
  }

  async showCreateInvoiceModal(): Promise<void> {
    if (this.currentAddInvoiceModal) {
      return Promise.resolve(void 0);
    }

    this.currentAddInvoiceModal = await this.modals.create({
      component: CreateInvoiceModalComponent,
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      backdropDismiss: false,
    });

    this.currentAddInvoiceModal.onDidDismiss().then(() => {
      this.currentAddInvoiceModal = undefined;
    });

    await this.currentAddInvoiceModal.present();
  }

  async showReviewInvoiceModal(): Promise<OverlayEventDetail | void> {
    if (this.currentReviewInvoiceModal) {
      return Promise.resolve(void 0);
    }

    this.currentReviewInvoiceModal = await this.modals.create({
      component: ReviewInvoiceModalComponent,
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      backdropDismiss: false,
    });

    await this.currentReviewInvoiceModal.present();

    return this.currentReviewInvoiceModal.onDidDismiss().then(tap(() => (this.currentReviewInvoiceModal = undefined)));
  }

  confirmPendingInvoice(company: Company): Observable<string | undefined> {
    return from(
      this.alerts.create({
        header: 'Send Invoice',
        message: `Are you sure you want to send this invoice to ${company.name}?`,
        buttons: [
          { text: 'Cancel', role: 'cancel' },
          { text: 'Send', role: 'ok' },
        ],
        backdropDismiss: false,
      }),
    ).pipe(
      timeout(5000),
      waitOn(confirm => confirm.present()),
      switchMap(confirm => confirm.onDidDismiss()),
      map(({ role }) => role),
      catchError(err =>
        throwError(new Error('Could not load confirmation dialog. Please check your internet connection.')),
      ),
    );
  }

  async unwindAddReviewModalStack() {
    await this.currentAddInvoiceModal?.dismiss();
    await this.currentReviewInvoiceModal?.dismiss();
  }

  async showViewLienReleaseModal(invoice: Invoice) {
    const modal = await this.modals.create({
      component: LienReleaseModalComponent,
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      componentProps: { invoice, enableSigning: false },
    });

    await modal.present();
    return await modal.onDidDismiss();
  }

  async showSignLienReleaseModal(invoice: Invoice): Promise<OverlayEventDetail | void> {
    if (this.currentLienReleaseModal) {
      return Promise.resolve(void 0);
    }

    this.currentLienReleaseModal = await this.modals.create({
      component: LienReleaseModalComponent,
      componentProps: { invoice, enableSigning: true },
      cssClass: 'rounded-modal',
      canDismiss: true,
      presentingElement: this.routerOutletService.routerOutlet?.nativeEl,
      backdropDismiss: false,
    });

    this.currentLienReleaseModal.onDidDismiss().then(() => {
      this.currentLienReleaseModal = undefined;
    });

    await this.currentLienReleaseModal.present();
    return this.currentLienReleaseModal.onWillDismiss();
  }

  async notifyInvoicePaymentConfirmed(invoiceName: string): Promise<string | undefined> {
    const confirm = await this.alerts.create({
      header: 'Invoice Paid',
      message: `We've sent the Lien Release to ${invoiceName} and will pay from the account you selected once it's signed`,
      buttons: [{ text: 'Ok', role: 'ok' }],
      backdropDismiss: false,
    });
    await confirm.present();

    const { role } = await confirm.onDidDismiss();
    return role;
  }

  async notifyPaymentInitiated(): Promise<string | undefined> {
    const confirm = await this.alerts.create({
      header: 'Lien Release Submitted',
      message: `Invoice has been paid. We will mark the Invoice paid once it hits your bank.`,
      buttons: [{ text: 'Ok', role: 'ok' }],
      backdropDismiss: false,
    });
    await confirm.present();

    const { role } = await confirm.onDidDismiss();
    return role;
  }

  async notifyPaymentFailure(): Promise<string | undefined> {
    const confirm = await this.alerts.create({
      header: 'Lien Release Failed to Process',
      message: new IonicSafeString(
        `We encountered an issue signing your Lien Release. Please try again and/or contact <a href="mailto:support@gocarrera.com">Go Carrera Support</a> if you continue to have issues.`,
      ),
      buttons: [{ text: 'Ok', role: 'ok' }],
      backdropDismiss: false,
      cssClass: 'alert-danger',
    });
    await confirm.present();

    const { role } = await confirm.onDidDismiss();
    return role;
  }

  async unwindLienReleaseStack() {
    await this.currentLienReleaseModal?.dismiss();
  }
}
