import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'FacilityType',
  uriName: 'facility-types',
  comparer: (a, b) => (a.type.localeCompare(b.type, undefined, { numeric: true }))
})
export class FacilityType {
  @Key id?: number;
  type!: string;
  description?: string;
  isActive!: boolean;
}
