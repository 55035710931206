import { Action, createFeatureSelector, createReducer } from '@ngrx/store';
import { buildFileState } from '../../../shared/file-uploads/build-file-state';
import { FileState } from '../../../shared/file-uploads/file-state';
import { WorkOrderFile } from './work-order-files.model';

export interface IWorkOrderFileState extends FileState<WorkOrderFile> {}

export const {
  initialState,
  facade: WorkOrderFilesFacadeBase,
  selectors: { selectAll: allWorkOrderFiles },
  actions: {
    loadAll: loadAllWorkOrderFiles,
    loadAllSuccess: loadAllWorkOrderFilesSuccess,
    createSuccess: createFileSuccess,
    clear: clearWorkOrderFiles,
    delete: deleteWorkOrderFile,
    deleteSuccess: deleteWorkOrderFileSuccess,
  },
  fileSelectors: { selectPendingFiles: pendingWorkOrderFiles },
  fileActions: {
    upload: uploadWorkOrderFile,
    uploadRetry: uploadWorkOrderFileRetry,
    delete: deleteWorkOrderFileUpload,
  },
} = buildFileState(WorkOrderFile);

const reduce = createReducer(initialState);

export function workOrderFileReducer(state = initialState, action: Action): IWorkOrderFileState {
  return reduce(state, action);
}

export const workOrderFileState = createFeatureSelector<IWorkOrderFileState>('workOrderFile');
