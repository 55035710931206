import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TooltipDisplayComponent } from '../tooltip-display/tooltip-display.component';

@Component({
  selector: 'gc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @ViewChild('content') content!: TemplateRef<any>;
  @Input() icon = 'information-circle';
  @Input() color = 'dark';
  @Input() size = 'large';
  @Input() styles = '';

  constructor(private popoverGC: PopoverController) {}

  async createTooltip(event: Event) {
    const popover = await this.popoverGC.create({
      component: TooltipDisplayComponent,
      componentProps: {
        content: this.content,
      },
      backdropDismiss: true,
      showBackdrop: false,
      mode: 'ios',
      event,
    });
    await popover.present();
    return await popover.onDidDismiss();
  }
}
