import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { delay, exhaustMap, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SidebarService } from '../../shared/services/sidebar.service';
import { routeEndsInPath } from '../../shared/utils/ngrx';
import { waitForAll } from '../../shared/utils/operators';
import { ensureExists } from '../../shared/utils/rxjs';
import { AuthFacade } from '../app/auth/auth.facade';
import { isRole } from '../app/auth/auth.maps';
import { AppState } from '../state';
import { detailedInvoiceItemsForCurrentInvoice } from '../work-order-invoices.selectors';
import { currentDetailedWorkOrderItems } from '../work-order-items-detail.selectors';
import { viewWorkOrderFromModal } from '../work/work-order-ui.actions';
import { detailedWorkOrder } from '../work/work-order/work-order.selectors';
import { deselectWorkOrder, selectWorkOrderById } from '../work/work-order/work-order.state';
import { INVOICES_LIST_URL, INVOICES_URL } from './constants';
import {
  approveInvoice,
  archiveInvoice,
  declineInvoice,
  invoiceAction, invoiceModalClosed,
  payInvoice,
  showInvoiceOptions,
  showSignLienRelease,
  signLienRelease,
  viewInvoice,
  viewLienRelease,
  voidInvoice, workOrderInvoiceModalClosed,
} from './invoice-and-payment.actions';
import { interactingInvoice } from './invoice-and-payment.selectors';
import { interactingCompanyInvoiceOptions, interactingInstallerInvoiceOptions, invoicingContext } from './invoice-ui.selectors';
import { InvoiceUIService } from './invoice-ui.service';
import { invoiceIsStatus, InvoiceStatus } from './invoice/invoice.model';
import { loadStatusGroup } from './invoice/invoices.actions';
import { currentInvoice, deselectInvoice, selectInvoice } from './invoice/invoices.state';


@Injectable()
export class InvoiceUIEffects {
  constructor(
    private actions$: Actions,
    private invoiceUI: InvoiceUIService,
    private auth: AuthFacade,
    private store: Store<AppState>,
    private sidebarService: SidebarService,
  ) {}

  showInstallerInvoiceOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showInvoiceOptions),
      delay(100),
      withLatestFrom(this.auth.role$, this.store.select(interactingInstallerInvoiceOptions)),
      filter(([, role]) => isRole(role, 'installerLead', 'companyEmployee')),
      exhaustMap(([{ event, invoice },, options]) =>
        from(
          this.invoiceUI.showInvoiceOptions(event, options),
        ).pipe(
          filter(({ role }) => role === 'act'),
          map(({ data }) => invoiceAction({ action: data.name, invoice })),
        ),
      ),
    ),
  );

  showCompanyInvoiceOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showInvoiceOptions),
      withLatestFrom(
        this.auth.role$,
        this.store.select(interactingCompanyInvoiceOptions)
      ),
      filter(([, role]) => isRole(role, 'companyAdmin', 'companyManager')),
      map(([{ event, invoice },, options]) => (
        {
          event,
          invoice,
          listOptions: options,
        }
      )),
      exhaustMap(({ event, invoice, listOptions }) =>
        from(this.invoiceUI.showInvoiceOptions(event, listOptions)).pipe(
          filter(({ role }) => role === 'act'),
          map(({ data }) => invoiceAction({ action: data.name, invoice })),
        ),
      ),
    ),
  );

  // TODO: Split into one effect per action
  handleSignLienRelease$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showSignLienRelease),
      switchMap(({ invoice }) => [
        signLienRelease({ invoice }),
        selectInvoice({ entity: invoice }),
        selectWorkOrderById({ key: invoice.workOrderId }),
      ]),
    ),
  );

  showViewInvoiceModalFromWorkOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewInvoice),
        withLatestFrom(
          this.store.select(invoicingContext),
        ),
        filter(([, { url }]) => url.startsWith('/app/work/orders')),
        exhaustMap(([, context]) =>
          from(this.invoiceUI.showViewInvoiceModal(context)).pipe(
            map(() => workOrderInvoiceModalClosed()),
          ),
        ),
      ),
  );

  showViewInvoiceModalFromInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewInvoice),
      withLatestFrom(this.store.select(invoicingContext)),
      filter(([, { url }]) => url.startsWith(INVOICES_URL)),
      waitForAll(
        {
          retryDelay: 250,
          maxRetries: 16,
          predicate: (haveWorkOrder, haveCurrentInvoiceItems, haveWorkOrderItems) =>
            !!haveWorkOrder && !!haveCurrentInvoiceItems && !!haveWorkOrderItems,
        },
        this.store.select(detailedWorkOrder),
        this.store.select(detailedInvoiceItemsForCurrentInvoice),
        this.store.select(currentDetailedWorkOrderItems),
      ),
      exhaustMap(([[, context]]) => this.invoiceUI.showViewInvoiceModal(context)),
      filter(({ role }) => role !== 'hide'),
      switchMap(() => [invoiceModalClosed(), deselectWorkOrder(), deselectInvoice()]),
    ),
  );

  hideInvoiceModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewWorkOrderFromModal),
        exhaustMap(() => this.invoiceUI.hideViewInvoiceModal()),
      ),
    { dispatch: false },
  );

  reshowInvoiceModalOnReturnFromWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrderFromModal),
      switchMap(() =>
        this.actions$.pipe(
          ofType(routerNavigationAction),
          routeEndsInPath(INVOICES_LIST_URL),
          first(),
          withLatestFrom(this.store.select(currentInvoice)),
          map(([, invoice]) => invoice),
          tap(invoice => console.log('current invoice', invoice)),
          tap(() => this.sidebarService.setHideSidebar(false)),
          ensureExists(invoice => !!invoice),
          switchMap(invoice => [
            viewInvoice({ invoice }),
            selectWorkOrderById({ key: invoice.workOrderId }),
            loadStatusGroup(),
          ]),
        ),
      ),
    ),
  );
}
