import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { Company, Installer } from '~gc/domains/users';
import { DetailedWorkOrder } from '~gc/domains/work/models/detailed-work-order';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { WorkOrderDate } from '../../../core/calendar';
import { awardedInstallerProfileShown } from '../../../domains/users/users-ui.actions';
import { MayHaveInstallerTeamLeadId } from '../../pipes/is-awarded-to-installer.pipe';
import { CustomDateForm, CustomDateFormArray } from '../schedule/schedule.form';

@Component({
  selector: 'gc-detail-header',
  templateUrl: './detail-header.component.html',
  styleUrls: ['./detail-header.component.scss'],
})
export class DetailHeaderComponent implements OnChanges, OnInit {
  @Input() workOrder!: DetailedWorkOrder;
  @Input() workOrderDates: WorkOrderDate[] = [];
  @Input() matchedInstallerCount!: number;
  @Input() currentRole!: string;
  @Input() currentUser!: any & MayHaveInstallerTeamLeadId;
  @Input() allowedPrivateDetails!: boolean;
  @Input() scheduleOpen = true;
  @Input() isChatOpen!: boolean;
  @Input() company!: Company;

  @Output() viewInstaller = new EventEmitter<Installer>();
  @Output() reviewInstallers = new EventEmitter<WorkOrder>();
  @Output() dateAdded = new EventEmitter<WorkOrderDate>();
  @Output() dateSaved = new EventEmitter<WorkOrderDate>();
  @Output() dateDeleted = new EventEmitter<WorkOrderDate>();

  datesForm!: CustomDateFormArray;

  ngOnInit() {
    this.datesForm = new CustomDateFormArray(this.workOrderDates.map(date => new CustomDateForm(date)) || [], () => ({
      workOrderId: this.workOrder.id,
    }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['workOrderDates'] && !changes['workOrderDates'].firstChange) {
      this.datesForm.repopulate(changes['workOrderDates'].currentValue || []);
    }
  }

  addDate(date: WorkOrderDate): void {
    this.dateAdded.emit({
      ...date,
      type: 'workorder',
      startDate: typeof date.startDate === 'string' ? date.startDate : formatISO(date.startDate as unknown as Date),
      endDate: typeof date.endDate === 'string' ? date.endDate : formatISO(date.endDate as unknown as Date),
      workOrder: this.workOrder,
    });
  }

  saveDate(date: WorkOrderDate): void {
    this.dateSaved.emit({
      ...date,
      type: 'workorder',
      startDate: typeof date.startDate === 'string' ? date.startDate : formatISO(date.startDate as unknown as Date),
      endDate: typeof date.endDate === 'string' ? date.endDate : formatISO(date.endDate as unknown as Date),
      workOrder: this.workOrder,
    });
  }

  deleteDate(date: WorkOrderDate): void {
    this.dateDeleted.emit({
      ...date,
      type: 'workorder',
      workOrder: this.workOrder,
    });
  }
}
