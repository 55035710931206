<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss(null, 'ok')" data-cy="close-phone-number-modal">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-title data-cy="phone-number-modal-title">Choose Phone Number</ion-title>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <gc-empty-list-warning *ngIf="!(phoneNumbers.all$ | push)?.length"
    >Hmm, we could not find any phone numbers to show for this user.
  </gc-empty-list-warning>
  <ion-list *ngFor="let phoneNumber of phoneNumbers.all$ | push">
    <ion-item lines="full" [attr.data-cy]="phoneNumber.type + '-phone-number-' + phoneNumber.number">
      <ion-label>
        <a href="tel:{{ phoneNumber.number | phoneFormat }}" (click)="modalGC.dismiss(null, 'ok')">{{
          phoneNumber.number | phoneFormat
        }}</a>
      </ion-label>
      <ion-badge slot="end" mode="ios" color="light" Ï [attr.data-cy]="'phone-number-type=' + phoneNumber.type"
        >{{ phoneNumber.type }}
      </ion-badge>
    </ion-item>
  </ion-list>
</ion-content>
