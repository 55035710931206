import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer, on } from '@ngrx/store';
import { showUnclaimedTrainingSelection } from '../training.actions';
import { Category } from './category.model';

export const {
  initialState: initialCategoryState,
  facade: CategoryFacadeBase,
  selectors: { selectAll: allCategories, selectCurrentEntity: currentCategory },
  actions: { loadAllIfNecessary: loadAllCategoriesIfNecessary },
} = buildState(Category);

export const setCurrentEntityKey = (state: IEntityState<Category>, { category }: { category: Category }) => ({
  ...state,
  selections: {
    ...state.selections,
    currentEntityKey: category.id,
  },
});

const reduce = createReducer(initialCategoryState, on(showUnclaimedTrainingSelection, setCurrentEntityKey));

export function categoryReducer(state = initialCategoryState, action: Action): IEntityState<Category> {
  return reduce(state, action);
}
