import { Component, Input } from '@angular/core';
import { InstallerProfile } from '~gc/domains/users/models/installer-profile';

@Component({
  selector: 'gc-hammer-rating-area',
  templateUrl: './hammer-rating-area.component.html',
  styleUrls: ['./hammer-rating-area.component.scss'],
})
export class HammerRatingAreaComponent {
  @Input() isLoading = false;
  @Input() installer?: InstallerProfile;
}
