import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { FacilityType } from './facility-type.model';
import { FacilityTypeFacadeBase } from './facility-type.state';

@Injectable()
export class FacilityTypeFacade extends FacilityTypeFacadeBase {
  constructor(private store: Store<AppState>) {
    super(FacilityType, store);
  }
}
