<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        data-cy="invite-user-cancel-button"
        color="dark"
        slot="start"
        fill="clear"
        (click)="team.cancelInviteUser()"
      >
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title
      *ngIf="(auth.user$ | push | isInstallerTeamLead) && userType === 'INSTALLER_TEAM_LEAD'; else standardTitle"
    >
      Invite a Friend
    </ion-title>
    <ng-template #standardTitle>
      <ion-title>
        {{ ($any({ type: userType }) | isInstallerTeamLead) ? 'Invite' : 'Add' }} {{ { type: userType } | userType }}
      </ion-title>
    </ng-template>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="inviteUserForm">
    <ion-list>
      <ion-item [lines]="(team.emailLookupLoading$ | push) ? 'none' : 'full'">
        <ion-input
          data-cy="invite-user-email-input"
          formControlName="email"
          labelPlacement="floating"
          inputmode="email"
          type="email"
          [debounce]="600"
          clearInput="true"
        >
          <div slot="label" data-cy="invite-user-validation-label">
            <ion-text [color]="inviteUserForm!.get('email')!.valid ? 'dark' : 'danger'">
              {{ inviteUserForm!.get('email')!.valid ? 'User\'s Email Address' : '* Must be a valid email.' }}
            </ion-text>
          </div>
        </ion-input>

        <ng-container
          slot="end"
          *ngIf="(team.emailLookup$ | push) && !((auth.user$ | push | isInstallerTeamLead) && userType === 'INSTALLER_TEAM_LEAD')"
        >
          <ng-container *ngIf="team.foundUserMatchingEmail$ | push as user; else noUser">
            <ion-icon
              *ngIf="user.type === userType"
              slot="end"
              name="checkmark-circle"
              color="success"
              style="margin-top: 27px"
            ></ion-icon>

            <gc-tooltip *ngIf="user.type !== userType" slot="end" color="warning" style="margin-top: 27px">
              User found for this email does not match the role you're currently looking for.
            </gc-tooltip>
          </ng-container>

          <ng-template #noUser>
            <gc-tooltip slot="end" color="warning" style="margin-top: 27px">
              No account found for this email. If you're inviting an Installer with an existing account, please
              double-check the email you entered. If you're inviting a new Installer, fill out their name below.
            </gc-tooltip>
          </ng-template>
        </ng-container>
      </ion-item>
      <ion-progress-bar *ngIf="team.emailLookupLoading$ | push" type="indeterminate"></ion-progress-bar>

      <ng-container *ngIf="!(team.foundUser$ | push) && inviteUserForm!.dirty">
        <ion-item>
          <ion-input
            data-cy="invite-user-first-name-field"
            formControlName="firstName"
            label="First Name"
            labelPlacement="floating"
            clearInput="true"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            data-cy="invite-user-last-name-field"
            formControlName="lastName"
            label="Last Name"
            labelPlacement="floating"
            clearInput="true"
          ></ion-input>
        </ion-item>
      </ng-container>
    </ion-list>
  </form>

  <gc-information-message
    *ngIf="team.userIsAlreadyConnected$ | push"
    message="It looks like this user is already invited to your Network or on one of your teams."
  ></gc-information-message>
  <gc-information-message
    *ngIf="(auth.user$ | push | isInstallerTeamLead) && (team.foundUser$ | push) && userType === 'INSTALLER_TEAM_LEAD'"
    message="It looks like this installer already has a Go Carrera profile."
  ></gc-information-message>
</ion-content>

<ion-footer class="ion-padding" *ngIf="auth.user$ | push as authUser">
  <ion-button
    data-cy="invite-user-button"
    *ngIf="team.foundUser$ | push as user; else basicInvite"
    size="large"
    expand="block"
    mode="ios"
    color="gc-green"
    (click)="submit(user)"
    [disabled]="user.type !== userType || inviteUserForm!.invalid || (team.userIsAlreadyConnected$ | push) || ((authUser | isInstallerTeamLead) &&  userType === 'INSTALLER_TEAM_LEAD')"
  >
    <strong>
      {{ ($any({ type: userType }) | isInstallerTeamLead) ? 'Invite' : 'Add' }} this {{ { type: userType } | userType }}
    </strong>
  </ion-button>
  <ng-template #basicInvite>
    <ion-button
      data-cy="add-user-button-basic"
      size="large"
      expand="block"
      color="gc-green"
      mode="ios"
      (click)="submit(undefined)"
      [disabled]="inviteUserForm!.invalid || (team.userIsAlreadyConnected$ | push)"
    >
      <strong *ngIf="(authUser | isInstallerTeamLead) && userType === 'INSTALLER_TEAM_LEAD'; else addUser">
        Invite Installer
      </strong>
      <ng-template #addUser>
        <strong> Add {{ { type: userType } | userType }} </strong>
      </ng-template>
    </ion-button>
  </ng-template>
</ion-footer>
