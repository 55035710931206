<ng-container *ngIf="allowedPrivateDetails; else publicPrice">
  <gc-changed-price-display
    [displayGroups]="displayGroups"
    [permissions]="permissions"
    [group]="group"
    [item]="item"
  ></gc-changed-price-display>
  <gc-basic-price-display
    [displayGroups]="displayGroups"
    [permissions]="permissions"
    [group]="group"
    [item]="item"
  ></gc-basic-price-display>
</ng-container>
<ng-template #publicPrice>
  <gc-public-price-display
    [permissions]="permissions"
    [item]="item"
  ></gc-public-price-display>
</ng-template>
