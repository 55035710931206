import { createAction, props } from '@ngrx/store';
import { DetailedInstaller } from '../../models/detailed-installer';
import { Installer, TeamUser } from '../../users';
import { WorkOrder } from './work-order.model';

export const confirmAwardToInstaller = createAction(
  '[Work Order] Confirm Award to Installer',
  props<{ workOrder: WorkOrder; installer: Installer | DetailedInstaller }>(),
);

export const awardToInstaller = createAction(
  '[Work Order] Award to Installer',
  props<{ workOrder: WorkOrder; installer: Installer | DetailedInstaller }>(),
);

export const awardedToInstaller = createAction('[Work Order] Awarded to Installer', props<{ correlationId: string }>());

export const awardToInstallerCompleted = createAction('[Work Order] Award to Installer Completed');

export const openDropdownMenu = createAction('[Work Order] Open Dropdown Menu', props<{ event: Event }>());

export const shareWorkOrder = createAction('[Work Order] Share');

export const shareWithInstallers = createAction(
  '[Work Order] Share To Installers',
  props<{ installers: TeamUser[] }>(),
);

export const workOrderListEndReached = createAction(
  '[Work Order] List End Reached',
  props<{ event: CustomEvent<void> }>(),
);
