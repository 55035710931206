import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { Invoice } from './invoice.model';
import { InvoicesFacadeBase, isLoadingFresh } from './invoices.state';

@Injectable()
export class InvoicesFacade extends InvoicesFacadeBase {
  isLoadingFresh$ = this.store.select(isLoadingFresh);

  constructor(private store: Store<AppState>) {
    super(Invoice, store);
  }
}
