import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { DisplayGroupAllowance, DisplayOptions, WorkOrderItemGroup } from '../../../../domains/work-order-item-group.selectors';

@Component({
  selector: 'gc-item-expanders',
  templateUrl: './item-expanders.component.html',
  styleUrls: ['./item-expanders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemExpandersComponent {
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];
  @Input() group!: WorkOrderItemGroup;
  @Input() item!: DetailedWorkOrderItem;
  @Input() expandedItem!: DetailedWorkOrderItem;
  @Output() expanded = new EventEmitter<DetailedWorkOrderItem>();
}
