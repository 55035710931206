import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { authenticatedUser } from '../../../domains/app/auth/auth.selectors';
import { AppState } from '../../../domains/state';
import { isManager, Manager, User } from '../../../domains/users';
import { loadConnection } from '../../../domains/users/connection/connection.state';
import { loadUser } from '../../../domains/users/user/user.state';
import { ensureChildrenRequired } from '../../../shared/utils/rxjs';
import { InvitesService } from '../invites.service';
import { UserQuery } from '../models/user-query.model';
import { lookupUser, userLookupFailed, userLookupSucceeded } from './lookups.actions';

@Injectable()
export class LookupsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly invites: InvitesService,
  ) {}

  lookupUserByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lookupUser),
      switchMap(({ email, userType }) =>
        this.invites.lookupUserByEmail(email, userType).pipe(
          map(user => userLookupSucceeded({ user })),
          catchError(error => of(userLookupFailed({ error }))),
        ),
      ),
    ),
  );

  loadConnectionForFoundUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userLookupSucceeded),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ user }, currentUser]) => ({ foundUser: user, currentUser })),
      ensureChildrenRequired<
        { foundUser?: UserQuery; currentUser?: User },
        { foundUser: UserQuery; currentUser: Manager }
      >(value => !!value?.foundUser && isManager(value?.currentUser)),
      tap(({ foundUser: { id }, currentUser: { companyId } }) =>
        console.log(`Loading connection for user ${id} and company ${companyId}...`),
      ),
      map(({ foundUser: { id }, currentUser: { companyId } }) =>
        loadConnection({ criteria: { parents: { companies: companyId }, param: id } }),
      ),
    ),
  );

  loadUserForFoundUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userLookupSucceeded),
      filter(({ user }) => !!user),
      tap(({ user }) => console.log(`Loading user ${user.id}...`)),
      map(({ user }) => loadUser({ keys: user.id })),
    ),
  );
}
