import { AbstractControl, FormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { TypedFormGroup } from './typed-form-group';

export const truncateTo = <TItem extends AbstractControl>(
  array: FormArray<TItem>,
  maxLength: number,
  removeAt = array.length - 1,
): void => (array.length > maxLength ? (array.removeAt(removeAt), truncateTo(array, maxLength, removeAt - 1)) : void 0);

export class TypedFormArray<TModel, TItem extends TypedFormGroup<TModel>> extends FormArray<TItem> {
  readonly items = this.controls as TItem[];
  readonly itemsChanged$$ = new Subject<TItem[]>();

  constructor(controls: TItem[]) {
    super(controls);
    this.itemsChanged$$.next(this.items);
  }

  repopulate(values: any[]) {
    this.clear();
    values.forEach(value => this.push(this.makeNewGroup(value)));
  }

  override setValue(values: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.setValue(values, options);
    this.itemsChanged$$.next(this.items);
  }

  override patchValue(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any[],
    options?: { onlySelf?: boolean; emitEvent?: boolean; truncate?: boolean },
  ) {
    if (options?.truncate) {
      truncateTo(this, values.length);
    }
    super.patchValue(values, options);
    if (!options?.truncate) {
      values // This pushes any values beyond however many are already in the FormArray
        ?.slice(this.controls.length)
        .forEach(value => this.push(this.makeNewGroup(value)));
    }
    this.itemsChanged$$.next(this.items);
  }

  protected makeNewGroup(model: TModel, ...args: unknown[]): TItem {
    throw new Error('Not Implemented');
  }

  enableValidators(indices?: number[] | ((index: number) => boolean)): void {
    !indices
      ? this.items.forEach(item => item.enableValidators())
      : Array.isArray(indices)
      ? this.items.forEach((item, index) => (indices.includes(index) ? item.enableValidators() : void 0))
      : this.items.forEach((item, index) => (indices(index) ? item.enableValidators() : void 0));
  }

  disableValidators(indices?: number[] | ((index: number) => boolean)): void {
    !indices
      ? this.items.forEach(item => item.disableValidators())
      : Array.isArray(indices)
      ? this.items.forEach((item, index) => (indices.includes(index) ? item.disableValidators() : void 0))
      : this.items.forEach((item, index) => (indices(index) ? item.disableValidators() : void 0));
  }
}
