import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../state';
import { approvedTeamLeads } from '../network.selectors';
import {
  EmployeeInstaller,
  InstallerTeamLead,
  InstallerTeamMember,
  Jumpstarter,
  Manager,
  ProjectManager,
  User,
} from './user.model';
import {
  allEmployeeInstallers,
  allJumpstarters,
  allManagers,
  allProjectManagers,
  allTeamLeads,
  allTeamMembers,
  combinedTeamMembers,
  detailedCurrentCompanyAdmin,
  installersWithRatingTypes,
  jumpstartersNotOnTeamFavoriteStatus,
  usersTeamLead,
} from './user.selectors';
import { userEntities, UserFacadeBase } from './user.state';

@Injectable()
export class UserFacade extends UserFacadeBase {
  userEntities$ = this.store.select(userEntities);
  detailedCurrentCompanyAdmin$ = this.store.select(detailedCurrentCompanyAdmin);
  allEmployeeInstallers$ = this.store.select(allEmployeeInstallers) as Observable<EmployeeInstaller[]>;
  allProjectManagers$ = this.store.select(allProjectManagers) as Observable<ProjectManager[]>;
  allManagers$ = this.store.select(allManagers) as Observable<Manager[]>;
  allTeamLeads$ = this.store.select(allTeamLeads) as Observable<InstallerTeamLead[]>;
  approvedTeamLeads$ = this.store.select(approvedTeamLeads);
  allTeamMembers$ = this.store.select(allTeamMembers) as Observable<InstallerTeamMember[]>;
  allJumpstarters$ = this.store.select(allJumpstarters) as Observable<Jumpstarter[]>;
  combinedTeamMembers$ = this.store.select(combinedTeamMembers);
  jumpstartersNotOnTeam$ = this.store.select(jumpstartersNotOnTeamFavoriteStatus);
  usersWithRatingTypes$ = this.store.select(installersWithRatingTypes);
  usersTeamLead$ = this.store.select(usersTeamLead);

  constructor(private store: Store<AppState>) {
    super(User, store);
  }
}
