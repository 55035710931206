import { Action, createAction, createFeatureSelector, createReducer, createSelector, on, props } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export interface EnvironmentState {
  frontendVersion: string;
  backendVersion: string;
  connected: boolean;
  isActive: boolean;
  updateAvailable: boolean;
  progress: {
    download: number;
    extraction: number;
    complete: boolean;
  };
  windowSize: {
    height: number;
    width: number;
  };
}

export const initialEnvironmentState: EnvironmentState = {
  frontendVersion: environment.version.frontend,
  backendVersion: '2.0.0',
  connected: true,
  isActive: true,
  updateAvailable: false,
  progress: {
    download: 0,
    extraction: 0,
    complete: false,
  },
  windowSize: {
    height: window.innerHeight,
    width: window.innerWidth,
  },
};

export const updateFrontendVersion = createAction(
  '[Environment] Update Frontend Version',
  props<{ frontendVersion: string }>(),
);
export const updateBackendVersion = createAction(
  '[Environment] Update Backend Version',
  props<{ backendVersion: string }>(),
);

export const liveUpdateDetected = createAction('[Environment] Live Update Available');

export const initiateLiveUpdate = createAction('[Environment] Live Update Initiated');

export const liveUpdateDownloadProgress = createAction(
  '[Environment] Live Update Download Progress',
  props<{ progress: number }>(),
);

export const liveUpdateDownloadComplete = createAction('[Environment] Live Update Download Complete');

export const liveUpdateExtractionProgress = createAction(
  '[Environment] Live Update Extraction Progress',
  props<{ progress: number }>(),
);

export const liveUpdateExtractionComplete = createAction('[Environment] Live Update Extraction Complete');

export const networkStatusChanged = createAction(
  '[Environment] (NetworkStatus) Changed',
  props<{ connected: boolean }>(),
);

export const appStateChanged = createAction('[Environment] (AppState) Changed', props<{ isActive: boolean }>());

export const windowSizeUpdated = createAction(
  '[Environment] Window Size Update',
  props<{ width: number; height: number }>(),
);

export const setBackendVersion = (state: EnvironmentState, { backendVersion }: { backendVersion: string }) => ({
  ...state,
  backendVersion,
});

export const setFrontendVersion = (state: EnvironmentState, { frontendVersion }: { frontendVersion: string }) => ({
  ...state,
  frontendVersion,
});

export const setNetworkStatus = (state: EnvironmentState, { connected }: { connected: boolean }) => ({
  ...state,
  connected,
});

export const setAppState = (state: EnvironmentState, { isActive }: { isActive: boolean }) => ({
  ...state,
  isActive,
});

export const setLiveUpdateAvailable = (state: EnvironmentState): EnvironmentState => ({
  ...state,
  updateAvailable: true,
});

export const setDownloadProgress = (state: EnvironmentState, { progress }: { progress: number }): EnvironmentState => ({
  ...state,
  progress: {
    ...state.progress,
    download: progress,
  },
});

export const setExtractionProgress = (
  state: EnvironmentState,
  { progress }: { progress: number },
): EnvironmentState => ({
  ...state,
  progress: {
    ...state.progress,
    extraction: progress,
  },
});

const reduce = createReducer(
  initialEnvironmentState,
  on(updateFrontendVersion, setFrontendVersion),
  on(updateBackendVersion, setBackendVersion),
  on(networkStatusChanged, setNetworkStatus),
  on(appStateChanged, setAppState),
  on(liveUpdateDetected, setLiveUpdateAvailable),
  on(liveUpdateDownloadProgress, setDownloadProgress),
  on(liveUpdateExtractionProgress, setExtractionProgress),
  on(
    liveUpdateExtractionComplete,
    (state: EnvironmentState): EnvironmentState => ({
      ...state,
      progress: { ...state.progress, complete: true },
    }),
  ),
  on(
    windowSizeUpdated,
    (state: EnvironmentState, { width, height }): EnvironmentState => ({ ...state, windowSize: { width, height } }),
  ),
);

export function environmentReducer(state = initialEnvironmentState, action: Action): EnvironmentState {
  return reduce(state, action);
}

export const environmentState = createFeatureSelector<EnvironmentState>('environment');

export const getFrontendVersion = (state: EnvironmentState): string => state.frontendVersion;
export const getBackendVersion = (state: EnvironmentState): string => state.backendVersion;

export const mapToUpdateAvailable = (state: EnvironmentState): boolean => state.updateAvailable;
export const mapToNetworkConnected = (state: EnvironmentState): boolean => state.connected;
export const mapToAppActive = (state: EnvironmentState): boolean => state.isActive;

export const backendVersion = createSelector(environmentState, getBackendVersion);
export const frontendVersion = createSelector(environmentState, getFrontendVersion);

export const networkConnected = createSelector(environmentState, mapToNetworkConnected);
export const appActive = createSelector(environmentState, mapToAppActive);
export const updateAvailable = createSelector(environmentState, mapToUpdateAvailable);

export const updateProgress = createSelector(environmentState, state => state.progress);
export const updateComplete = createSelector(updateProgress, progress => progress.complete);

export const windowSize = createSelector(environmentState, state => state.windowSize);
export const windowWidth = createSelector(windowSize, size => size.width);
export const windowHeight = createSelector(windowSize, size => size.height);
export const windowIsMobileSize = createSelector(windowWidth, width => width < 960);
