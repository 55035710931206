import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { WorkOrderTag } from './work-order-tag.model';
import { WorkOrderTagFacadeBase } from './work-order-tag.state';

@Injectable()
export class WorkOrderTagFacade extends WorkOrderTagFacadeBase {
  constructor(private store: Store<AppState>) {
    super(WorkOrderTag, store);
  }
}
