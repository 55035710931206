export interface Correlatable {
  correlationId?: string;
}

export type BasicIdentity = number | string;

export interface HasId {
  id: BasicIdentity;
}

export interface MayHaveId {
  id?: BasicIdentity;
}

export type WithId<T> = T & HasId;

export type RequireId<T> = T extends HasId ? T : T extends MayHaveId ? WithId<T> : never;

export const existsWithId = <T extends MayHaveId>(item?: T): item is NonNullable<RequireId<T>> => !!item?.id;

export interface HasTargetFiles {
  target: {
    files: File[];
  };
}

export interface HasTargetValue {
  target: {
    value: any;
  };
}

export interface HasTargetCompleteFn {
  target: {
    complete: () => void;
  };
}

export interface HasDataTransfer {
    dataTransfer: {
      files: File[];
    }
}

export interface Selectable<T> {
  item: T;
  selected: boolean;
}
