import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { formatISO } from 'date-fns';
import { DetailedInstaller } from '~gc/domains/models/detailed-installer';
import { Installer, InstallerTeamLead, isInstallerLead, User } from '~gc/domains/users/user/user.model';
import { BrowserService } from '../../../domains/browser.service';
import { UsersUIFacade } from '../../../domains/users';
import { selectedInstallerTeamLeadProfile } from '../../../domains/users/users-ui.actions';
import { DetailedWorkOrder } from '../../../domains/work/models/detailed-work-order';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
})
export class UserHeaderComponent {
  @Input() isLoading = false;
  @Input() user?: User | DetailedInstaller;
  @Input() workOrder?: WorkOrder | DetailedWorkOrder;
  @Input() centered = true;

  validInsurance(user: InstallerTeamLead) {
    return user.insuranceExpiration > formatISO(Date.now());
  }

  meetsHammerRatingThreshold(user: DetailedInstaller) {
    return ((user as DetailedInstaller).keyHammerRating ?? 0) >= (this.workOrder?.minimumHammerRatingThreshold ?? 0);
  }

  showInstallerProfile(installer: DetailedInstaller) {
    this.usersUI.showInstallerProfile(installer as Installer);
  }

  showInstallerTeamLeadProfile(installer: DetailedInstaller) {
    this.store.dispatch(selectedInstallerTeamLeadProfile({ installer: installer as InstallerTeamLead }));
  }

  isDetailedInstaller<T extends User>(user: User): user is DetailedInstaller {
    return isInstallerLead(user) ? typeof (user as DetailedInstaller).keyHammerRating === 'number' : false;
  }

  constructor(
    private browser: BrowserService,
    public usersUI: UsersUIFacade,
    public readonly store: Store,
  ) {}

  async openBrowser(url: string): Promise<void> {
    await this.browser.openUrl(url);
  }
}
