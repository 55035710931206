import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { AreaSegmentsModule } from '../../shared/components/area-segments/area-segments.module';
import { HammerRatingDetailsModule } from '../../shared/components/hammer-rating-details/hammer-rating-details.module';
import { UserHeaderModule } from '../../shared/components/user-header/user-header.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { ViewProjectManagerProfileModalComponent } from './view-project-manager-profile-modal/view-project-manager-profile-modal.component';

@NgModule({
  declarations: [ViewProjectManagerProfileModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    UserHeaderModule,
    AreaSegmentsModule,
    HammerRatingDetailsModule,
    FontAwesomeModule,
    PushModule,
  ],
  exports: [ViewProjectManagerProfileModalComponent],
})
export class ViewProjectManagerProfileModule {}
