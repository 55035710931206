import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsyncValidatorFn } from '@angular/forms';
import { Store } from '@ngrx/store';
import { GoogleMapsService } from '../services/google-maps.service';
import { latLng } from './async-lat-lng.validators';
import { matchesZip } from './async-matches-zip.validator';
import { knownValidPhoneNumber } from './async-phone-number.validator';
import { stateMatchesStateCode, StateMatchesStateCodeValidator } from './async-state-matches-code.validator';
import { knownCrossValidZipCode, knownValidZipCode } from './async-zipcode.validator';

@Injectable()
export class AsyncAddressValidators {
  static knownValidZipCode: AsyncValidatorFn;
  static knownCrossValidZipCode: (cityField: string, stateField: string) => AsyncValidatorFn;
  static knownValidPhoneNumber: () => AsyncValidatorFn;
  static cityMatchesZip: (zipField: string) => AsyncValidatorFn;
  static stateMatchesZip: (zipField: string) => AsyncValidatorFn;
  static stateMatchesStateCode: StateMatchesStateCodeValidator;
  static latLng: (
    cityField?: string,
    stateField?: string,
    addressField?: string,
    zipField?: string,
  ) => AsyncValidatorFn;

  constructor(
    private http: HttpClient,
    private googleMaps: GoogleMapsService,
    private store: Store,
  ) {
    AsyncAddressValidators.knownValidZipCode = knownValidZipCode(http);
    AsyncAddressValidators.knownCrossValidZipCode = knownCrossValidZipCode(http);
    AsyncAddressValidators.knownValidPhoneNumber = knownValidPhoneNumber(http);
    AsyncAddressValidators.cityMatchesZip = matchesZip(http, 'city');
    AsyncAddressValidators.stateMatchesZip = matchesZip(http, 'state');
    AsyncAddressValidators.latLng = latLng(googleMaps);
    AsyncAddressValidators.stateMatchesStateCode = stateMatchesStateCode(store);
  }
}
