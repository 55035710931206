import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, mergeMap, reduce } from 'rxjs';
import { exhaustMap, filter, first, map, switchMap, tap, throttleTime } from 'rxjs/operators';
import { loggedOut } from '../domains/app/auth/auth.actions';
import { cachedDataPurged, httpResponseReceived } from './actions';
import { ResponseMetadata } from './models/response-metadata.model';
import { ResponseCacheService } from './response-cache.service';
import { httpResponseMetadataList } from './store/http-traffic.selectors';
import { getAppCacheControlDirectives, getCacheControlDirectives, getMaxAge, getMaxStaleAge, isExpired, } from './utils';


export const PURGE_INTERVAL = 180000;

@Injectable()
export class CacheEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly cache: ResponseCacheService,
  ) {}

  cacheResponseBody$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(httpResponseReceived),
        map(({ response, body }) => ({
          url: response.url,
          cacheControl: getCacheControlDirectives(response.headers?.['cache-control']),
          appCacheControl: getAppCacheControlDirectives(response.headers?.['x-app-cache-control']),
          body,
        })),
        filter(
          ({ cacheControl, appCacheControl }) =>
            (appCacheControl
              ? !appCacheControl['no-cache'] &&
                (appCacheControl['max-stale-age'] == null || +appCacheControl['max-stale-age'] !== 0)
              : true) &&
            (cacheControl
              ? !cacheControl['no-cache'] && (cacheControl['max-age'] == null || +cacheControl['max-age'] !== 0)
              : true),
        ),
        mergeMap(({ url, body }) => this.cache.set(url, body)),
      ),
    { dispatch: false },
  );

  purgeStaleCachedResponses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(httpResponseReceived),
      throttleTime(PURGE_INTERVAL),
      filter(() => window.navigator.onLine),
      exhaustMap(() =>
        this.store.select(httpResponseMetadataList).pipe(
          first(),
          map((metadataList: ResponseMetadata[]) => ({ metadataList, referenceDate: new Date() })),
          switchMap(({ metadataList, referenceDate }) =>
            from(metadataList).pipe(
              filter(
                metadata =>
                  (metadata.headers?.['x-app-cache-control']?.includes('max-stale-age') &&
                    isExpired(
                      referenceDate,
                      metadata.receivedAt,
                      getMaxStaleAge(metadata.headers?.['x-app-cache-control']),
                    )) ||
                  (!metadata.headers?.['x-app-cache-control']?.includes('max-stale-age') &&
                    metadata.headers?.['cache-control']?.includes('max-age') &&
                    isExpired(referenceDate, metadata.receivedAt, getMaxAge(metadata.headers?.['cache-control']))),
              ),
              tap(metadata => console.log(`Purging data from ${metadata.url} from cache...`)),
              mergeMap(metadata => this.cache.remove(metadata.url)),
              reduce(() => referenceDate, referenceDate),
              map(referenceDate => cachedDataPurged({ referenceDate })),
            ),
          ),
        ),
      ),
    ),
  );

  clearCacheOnLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loggedOut),
        tap(() => this.cache.clear()),
      ),
    { dispatch: false },
  );
}
