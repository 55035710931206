import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { WorkOrderItemForm } from '../draft-work-order.form';

@Component({
  selector: 'gc-work-order-item-sliding',
  templateUrl: './work-order-item-sliding.component.html',
  styleUrls: ['./work-order-item-sliding.component.scss'],
})
export class WorkOrderItemSlidingComponent {
  @Input() itemForm!: WorkOrderItemForm;
  @Input() disabled = false;

  @Output() edit = new EventEmitter<WorkOrderItemForm>();
  @Output() remove = new EventEmitter<WorkOrderItemForm>();

  async toggleOpenState(sliding: IonItemSliding) {
    if (this.disabled) {
      return;
    }
    return (await sliding.getOpenAmount()) === 0 ? sliding.open('end') : sliding.close();
  }
}
