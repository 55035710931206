import { Pipe, PipeTransform } from '@angular/core';
import { addDays, isBefore } from 'date-fns';

@Pipe({ name: 'isWithinDays' })
export class IsWithinDaysPipe implements PipeTransform {
  today = new Date();

  transform(date: string, days: number): boolean {
    const compareDate = addDays(this.today, days);
    return isBefore(new Date(date), compareDate);
  }
}
