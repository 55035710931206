import { IEntityDictionary } from '@briebug/ngrx-auto-entity';
import { createSelector } from '@ngrx/store';
import { isPast } from 'date-fns';
import { Connection } from '~gc/domains/users/connection/connection.model';
import { matchByOptionalId, matchOptionalByOptionalId } from '~gc/shared/utils/utils';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { Address } from '../../locations';
import { allAddresses } from '../../locations/address/address.state';
import { FileType } from '../../models/generic-file';
import { PhoneNumber } from '../../phone-number';
import { allPhoneNumbers } from '../../phone-number/phone-number.state';
import { AppState } from '../../state';
import { Category } from '../../training/category/category.model';
import { allCategories } from '../../training/category/category.state';
import { DetailedTrainingObtainedOld } from '../../training/models/detailed-training-claim';
import { ProductInstallationTypeClaimed } from '../../training/product-installation-types-claimed/product-installation-types-claimed.model';
import { ProductInstallationType } from '../../training/product-installation-types/product-installation-types.model';
import {
  allProductInstallationTypes,
  productInstallationTypeEntities,
} from '../../training/product-installation-types/product-installation-types.state';
import { authenticatedUserProductInstallationTypesClaimed } from '../../training/skills-and-experience.selectors';
import { Training } from '../../training/training/training.model';
import { allTrainings } from '../../training/training/training.state';
import { TrainingClaimed } from '../../training/trainings-claimed/trainings-claimed.model';
import { allTrainingsClaimed } from '../../training/trainings-claimed/trainings-claimed.state';
import { HasUserId } from '../../types';
import { companyIdsMatch, whereMatchesUser } from '../../utils';
import { HammerRating } from '../hammer-rating/hammer-rating.model';
import { JumpstarterFavorite } from '../jumpstarter-favorites/jumpstarter-favorite.model';
import { jumpstarterFavoriteForCurrentUser } from '../jumpstarter-favorites/jumpstarter-favorites.selectors';
import { DetailedInstallerTeamLead } from '../models/detailed-installer-team-lead.model';
import { InstallerProfile, TrainingGroup, TrainingGroupMap } from '../models/installer-profile';
import { WorkingUser } from '../models/working-user';
import { connectionForCurrentInstaller } from '../network.selectors';
import { filterComplianceFilesForUser, UserFile } from '../user-files/user-file.model';
import { allUserFiles } from '../user-files/user-files.state';
import {
  InstallerTeamLead,
  isCompanyUser,
  isInstaller,
  isInstallerLead,
  isTeamMember,
  ProjectManager,
  User,
} from '../user/user.model';
import { allUsers, currentUser } from '../user/user.state';
import { filterByUser, userIdMatchesUser } from '../utils';
import { ProfileState } from './profile.state';

// -----===[ GETTERS & PROJECTIONS ]===-----
export const getProfileState = (state: AppState): ProfileState => state.profile;

export const getCurrentStep = (state: ProfileState): string => state.currentEditingStep;

export const getCurrentWorkingUser = (state: ProfileState): WorkingUser | undefined => state.workingUser;

export const getCategoryName = (categories: Category[], categoryId: number): string | undefined =>
  categories?.find(matchByOptionalId(categoryId))?.type;

export const getProductInstallationTypeId = (categories: Category[], categoryId: number): number | undefined =>
  categories?.find(matchByOptionalId(categoryId))?.productInstallationTypeId;

export const getProductInstallationTypeName = (
  installTypes: ProductInstallationType[],
  installTypeId?: number,
): string | undefined => installTypes?.find(matchOptionalByOptionalId(installTypeId))?.type;

export const getTrainingName = (trainings: Training[], trainingId: number): string | undefined =>
  trainings?.find(matchByOptionalId(trainingId))?.certification;

export const combineTrainingsObtainedWithNames = (
  obtaineds: TrainingClaimed[],
  installTypes: ProductInstallationType[],
  categories: Category[],
  trainings: Training[],
): DetailedTrainingObtainedOld[] | undefined =>
  obtaineds
    ? obtaineds.map(obtained => ({
        ...obtained,
        categoryName: getCategoryName(categories, obtained.categoryId),
        installTypeId: getProductInstallationTypeId(categories, obtained.categoryId),
        installTypeName: getProductInstallationTypeName(
          installTypes,
          getProductInstallationTypeId(categories, obtained.categoryId),
        ),
        trainingName: getTrainingName(trainings, obtained.trainingId),
      }))
    : undefined;

export const trainingGroupMapToArray = (map: TrainingGroupMap): TrainingGroup[] =>
  (Object.keys(map) as unknown as (keyof TrainingGroupMap)[]).map((key: keyof TrainingGroupMap) => map[key]);

export const aggregateTrainingGroups = (trainings?: DetailedTrainingObtainedOld[]): TrainingGroup[] =>
  trainings
    ? trainingGroupMapToArray(
        trainings?.reduce(
          (groups: TrainingGroupMap, training) =>
            training.installTypeId && training.installTypeName
              ? ((groups[training.installTypeId] = {
                  name: training.installTypeName,
                  trainings: [...(groups?.[training.installTypeId]?.trainings || []), training],
                }),
                groups)
              : groups,
          {},
        ),
      )
    : [];

export const contractorAddressMatches = (address: Address, user: User, users: User[]): boolean =>
  userIdMatchesUser(
    address,
    users.find(u => isInstallerLead(u) && isInstallerLead(user) && u.installerTeamLeadId === user.installerTeamLeadId),
  );

export const addressMatching =
  (user: User | undefined, users: User[]) =>
  (address: Address): boolean =>
    user
      ? isCompanyUser(user)
        ? companyIdsMatch(address, user)
        : contractorAddressMatches(address, user, users)
      : false;

export const getUserWorkAddress = (addresses: Address[], users: User[], user?: User): Address | undefined =>
  !!user ? addresses.find(addressMatching(user, users)) : undefined;

export const createDetailedHammerRating =
  (productInstallationTypes: IEntityDictionary<ProductInstallationType>) => (rating: HammerRating) => ({
    ...rating,
    productInstallationType: productInstallationTypes[rating.productInstallationTypeId],
  });

export const mapToInstallerProfile = (
  user: User | undefined,
  authUser: User | undefined,
  workAddress: Address | undefined,
  trainingGroups: TrainingGroup[],
  productInstallationTypes: IEntityDictionary<ProductInstallationType>,
  phoneNumbers: PhoneNumber[],
  favorite?: JumpstarterFavorite,
  networkConnection?: Connection,
): InstallerProfile | undefined =>
  user && authUser
    ? {
        ...user,
        hammerRatings:
          user && isInstaller(user) && user.hammerRatings
            ? user.hammerRatings.map(createDetailedHammerRating(productInstallationTypes))
            : [],
        trainingGroups,
        workAddress,
        phoneNumbers: !!user ? phoneNumbers.filter(({ userId }) => userId === user.id) : [],
        favorite,
        isOnTeam: !!user && isTeamMember(user) && user.installerTeamLeadId === authUser?.id,
        networkConnection,
      }
    : undefined;

// -----===[ SELECTORS ]===-----
export const currentEditInstallerProfileStep = createSelector(getProfileState, getCurrentStep);

export const currentWorkingUser = createSelector(getProfileState, getCurrentWorkingUser);

export const currentUserTrainingsObtained = createSelector(allTrainingsClaimed, currentUser, filterByUser);

export const currentUserTrainingsObtainedWithNames = createSelector(
  currentUserTrainingsObtained,
  allProductInstallationTypes,
  allCategories,
  allTrainings,
  combineTrainingsObtainedWithNames,
);

export const currentUserTrainingGroups = createSelector(currentUserTrainingsObtainedWithNames, aggregateTrainingGroups);

export const currentUserWorkAddress = createSelector(allAddresses, allUsers, currentUser, getUserWorkAddress);

export const currentInstallerProfile = createSelector(
  currentUser,
  authenticatedUser,
  currentUserWorkAddress,
  currentUserTrainingGroups,
  productInstallationTypeEntities,
  allPhoneNumbers,
  jumpstarterFavoriteForCurrentUser,
  connectionForCurrentInstaller,
  mapToInstallerProfile,
);

export const currentInstallerProfileAreas = createSelector(currentInstallerProfile, (installer?: InstallerProfile) => [
  { name: 'general', label: 'General' },
  { name: 'rating', label: 'Hammer Rating' },
  ...(isInstallerLead(installer as InstallerTeamLead) ? [{ name: 'compliance', label: 'Documents' }] : []),
]);

// To provide room for extension without refactor
export const currentProjectManagerProfile = createSelector(currentUser, (user): ProjectManager => user as any);

export const currentInstallerProfileComplianceFiles = createSelector(
  allUserFiles,
  currentInstallerProfile,
  filterComplianceFilesForUser,
);

export const findItemsForUser = <T extends HasUserId>(items: T[], user?: User): T[] =>
  user ? items?.filter(whereMatchesUser(user)) ?? [] : [];

export const findFilesOfType =
  (...fileTypes: FileType[]) =>
  (files: UserFile[]) =>
    files.filter(file => fileTypes.includes(file.type));

export const currenUserAddresses = createSelector(allAddresses, authenticatedUser, findItemsForUser);

export const currentUserPhoneNumbers = createSelector(allPhoneNumbers, authenticatedUser, findItemsForUser);

export const currentUsersFiles = createSelector(allUserFiles, authenticatedUser, findItemsForUser);

export const currentUserInsuranceFiles = createSelector(currentUsersFiles, findFilesOfType(FileType.Insurance));

export const currentUserTaxFiles = createSelector(currentUsersFiles, findFilesOfType(FileType.W9));

export const currentEditedUser = createSelector(
  getProfileState,
  authenticatedUser,
  currenUserAddresses,
  (state: ProfileState, user?: User, addresses?: Address[]): User | undefined =>
    state.isEditing
      ? ({
          ...user,
          addresses,
        } as User)
      : undefined,
);

export const currentDetailedUser = createSelector(
  authenticatedUser,
  currenUserAddresses,
  currentUserPhoneNumbers,
  currentUserInsuranceFiles,
  currentUserTaxFiles,
  authenticatedUserProductInstallationTypesClaimed,
  (
    user: User | undefined,
    addresses: Address[],
    phoneNumbers: PhoneNumber[],
    insuranceFiles: UserFile[],
    taxFiles: UserFile[],
    productInstallationsTypesClaimed: ProductInstallationTypeClaimed[],
  ): DetailedInstallerTeamLead | undefined =>
    user
      ? {
          ...(user as InstallerTeamLead),
          addresses,
          phoneNumbers,
          insuranceFiles,
          taxFiles,
          productInstallationsTypesClaimed,
        }
      : undefined,
);

export const dashboardWizardUser = createSelector(currentDetailedUser, (user): DetailedInstallerTeamLead | undefined =>
  user
    ? {
        ...user,
        hasValidProfile: !!(
          user.phoneNumbers?.length &&
          user.addresses?.length &&
          user.insuranceFiles?.length &&
          user.taxFiles?.length &&
          user.dob &&
          user.insuranceExpiration &&
          user.insurancePolicyNumber &&
          user.insuranceProvider &&
          user.insuranceAgentName &&
          user.insuranceAgentPhone &&
          user.ein
        ),
        hasInstallationTypeClaimed: !!user.productInstallationsTypesClaimed?.length,
      }
    : undefined,
);

export const loadingFlags = createSelector(getProfileState, (state: ProfileState) => state.loadingFlags);

export const setupPaymentsLoading = createSelector(loadingFlags, flags => flags.setupPayments);

export const managePaymentsLoading = createSelector(loadingFlags, flags => flags.managePayments);

export const toggleVacationLoading = createSelector(loadingFlags, flags => flags.toggleVacation);

export const onVacationStatus = createSelector(authenticatedUser, user =>
  user?.onVacationSince ? isPast(new Date(user.onVacationSince)) : false,
);
