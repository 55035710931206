export function asReadonly<S1, Result>(projector: (s1: S1) => Result[]): (s1: S1) => readonly Result[];
export function asReadonly<S1, S2, Result>(
  projector: (s1: S1, s2: S2) => Result[],
): (s1: S1, s2: S2) => readonly Result[];
export function asReadonly<S1, S2, S3, Result>(
  projector: (s1: S1, s2: S2, s3: S3) => Result[],
): (s1: S1, s2: S2, s3: S3) => readonly Result[];
export function asReadonly<S1, S2, S3, S4, Result>(
  projector: (s1: S1, s2: S2, s3: S3, s4: S4) => Result[],
): (s1: S1, s2: S2, s3: S3, s4: S4) => readonly Result[];
export function asReadonly<S1, S2, S3, S4, S5, Result>(
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5) => Result[],
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5) => readonly Result[];
export function asReadonly<S1, S2, S3, S4, S5, S6, Result>(
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6) => Result[],
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6) => readonly Result[];
export function asReadonly<S1, S2, S3, S4, S5, S6, S7, Result>(
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7) => Result[],
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7) => readonly Result[];
export function asReadonly<S1, S2, S3, S4, S5, S6, S7, S8, Result>(
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => Result[],
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8) => readonly Result[];
export function asReadonly<S1, S2, S3, S4, S5, S6, S7, S8, S9, Result>(
  projector: (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8, s9: S9) => Result[],
): (s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8, s9: S9) => readonly Result[];

export function asReadonly<T, P extends (...params: any[]) => T[]>(projector: P) {
  return (...params: any[]): readonly T[] => Object.freeze(projector(...params));
}
