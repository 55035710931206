import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Kudos',
  uriName: 'kudos',
})
export class Kudos {
  @Key id?: number;
  workOrderId!: string;
  reasons!: KudosReason[];
}

export enum KudosReason {
  Default = 'NOT_PROVIDED',
  Professionalism = 'PROFESSIONALISM',
  Dependability = 'DEPENDABILITY',
  Punctuality = 'PUNCTUALITY',
  Quality = 'QUALITY',
  Attitude = 'ATTITUDE',
}
