import { Injectable } from '@angular/core';
import { getKeyFromModel, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { userEntities } from '../../users/user/user.state';
import { WorkOrder } from '../work-order/work-order.model';
import { loadWorkOrder } from '../work-order/work-order.state';
import { KudosUIService } from './kudos-ui.service';
import { kudosGiven } from './kudos.actions';
import { createKudos, createKudosSuccess } from './kudos.state';

@Injectable()
export class KudosEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly kudosUI: KudosUIService,
    private readonly store: Store,
  ) {}

  giveKudos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kudosGiven),
      withLatestFrom(this.store.select(userEntities)),
      switchMap(([{ workOrder }, users]) =>
        from(this.kudosUI.promptKudosReason(users[workOrder.awardedToInstallerId])).pipe(
          filter(({ role, data }) => !!data),
          map(({ data }) => ({ workOrder, reasons: data?.map(d => d.name) ?? [] })),
        ),
      ),
      map(({ workOrder, reasons }) =>
        createKudos({
          entity: {
            workOrderId: workOrder.id,
            reasons,
          } as any,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: getKeyFromModel(WorkOrder, workOrder),
            },
          },
        }),
      ),
    ),
  );

  reloadWorkOrderOnKudosGiven$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createKudosSuccess),
      map(({ entity }) => loadWorkOrder({ keys: entity.workOrderId })),
    ),
  );
}
