import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'WorkOrderTag',
  uriName: 'tags',
})
export class WorkOrderTag {
  @Key id?: number;
  name!: string;
  value!: string;
}
