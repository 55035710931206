import { Injectable } from '@angular/core';
import { SelectByKey } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { from, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, takeUntil } from 'rxjs/operators';
import { ensureRequired } from '../../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { WorkOrder } from '../work-order/work-order.model';
import { WorkUIService } from '../work-ui.service';
import { reviewInstaller, reviewInstallers } from './installer-review.actions';

@Injectable()
export class InstallerReviewEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private workUI: WorkUIService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  selectWorkOrderForReview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reviewInstallers),
      map(({ workOrder }) => workOrder),
      ensureRequired(workOrder => !!workOrder),
      map(workOrder => new SelectByKey(WorkOrder, workOrder.id)),
    ),
  );

  showInstallersForReview$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reviewInstallers),
        exhaustMap(() => from(this.workUI.showReviewInstallersModal()).pipe(catchError(() => of(null)))),
      ),
    { dispatch: false },
  );

  showInstallerReviewDetail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(reviewInstaller),
        exhaustMap(({ type }) => this.workUI.showReviewInstallerModal()),
      ),
    { dispatch: false },
  );
}
