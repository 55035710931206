import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { CustomListPopoverComponent } from './custom-list-popover.component';

@NgModule({
  declarations: [CustomListPopoverComponent],
  exports: [CustomListPopoverComponent],
  imports: [CommonModule, IonicModule, FontAwesomeModule],
})
export class CustomListPopoverModule {}
