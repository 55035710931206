import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { State } from './state.model';

export const {
  initialState: initialStateState,
  facade: StateFacadeBase,
  selectors: { selectAll: allStates, selectEntities: stateEntities },
  actions: { loadAllIfNecessary: loadAllStatesIfNecessary },
} = buildState(State);

const reduce = createReducer(initialStateState);

export function stateReducer(state = initialStateState, action: Action): IEntityState<State> {
  return reduce(state, action);
}
