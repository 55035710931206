import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { CustomDatesModule } from '~gc/domains/custom-dates/custom-dates.module';
import { ConnectionEffects } from '~gc/domains/users/connection/connection.effects';
import { LogModule } from '~gc/shared/services/log.module';
import { environment } from '../../environments/environment';
import { CoreCalendarModule } from '../core/calendar';
import { CoreInstallerProfileModule } from '../core/installer-profile';
import { CoreInvitesModule } from '../core/invites/core-invites.module';
import { ProfileModule } from '../core/profile/profile.module';
import { HttpModule } from '../http';
import { CallPhoneNumbersModule } from '../modals/call-phone-numbers/call-phone-numbers.module';
import { ClaimProductInstallationSubtypesModalModule } from '../modals/claim-product-installation-subtypes-modal/claim-product-installation-subtypes-modal.module';
import {
  ClaimProductInstallationTypeModalModule
} from '../modals/claim-product-installation-type-modal/claim-product-installation-type-modal.module';
import { ClaimTrainingModalModule } from '../modals/claim-training-modal/claim-training-modal.module';
import { ConfirmDeclineModalModule } from '../modals/confirm-decline-modal/confirm-decline-modal.module';
import { ViewInstallerProfileModalModule } from '../modals/view-installer-profile';
import { ViewInstallerProfileEnhancedModule } from '../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.module';
import { ViewJumpstarterProfileModalModule } from '../modals/view-jumpstarter-profile-modal/view-jumpstarter-profile-modal.module';
import { ViewProjectManagerProfileModule } from '../modals/view-project-manager-profile/view-project-manager-profile.module';
import { FileUploadModule } from '../shared/file-uploads/file-upload.module';
import { FileUploadProgressPopoverModule } from '../shared/popovers/file-upload-progress-popover/file-upload-progress-popover.module';
import { OptionsPopoverModule } from '../shared/popovers/options-popover/options-popover.module';
import { StorageSyncModule } from '../shared/storage-sync/storage-sync.module';
import { AppUIService } from './app-ui.service';
import { AnalyticsService } from './app/analytics.service';
import { AppEffects } from './app/app.effects';
import { AppFacade } from './app/app.facade';
import { AuthConnectEffects } from './app/auth/auth-connect.effects';
import { AuthEffects } from './app/auth/auth.effects';
import { AuthFacade } from './app/auth/auth.facade';
import { EnvironmentEffects } from './app/environment/environment.effects';
import { FirebaseEffects } from './app/firebase.effects';
import { FirebaseService } from './app/firebase.service';
import { LiveUpdateService } from './app/live-update.service';
import { LoginEffects } from './app/login.effects';
import { LoginService } from './app/login.service';
import { BrowserService } from './browser.service';
import { ChangeOrdersModule } from './change-orders/change-order.module';
import { FileUploadService } from './file-upload.service';
import { InvoiceActionsEffects } from './invoices/invoice-actions.effects';
import { InvoiceUIEffects } from './invoices/invoice-ui.effects';
import { InvoiceModule } from './invoices/invoice.module';
import { InvoiceEffects } from './invoices/invoices.effects';
import { LienReleaseEffects } from './invoices/lien-releases/lien-release.effects';
import { LocationsEffects } from './locations/locations.effects';
import { LocationsModule } from './locations/locations.module';
import { NegotiationsModule } from './negotiations/negotiations.module';
import { PartnerDealsModule } from './partner-deals/partner-deals.module';
import { PhoneNumberModule } from './phone-number/phone-number.module';
import { RouterOutletService } from './router-outlet.service';
import { GRouterStateSerializer } from './router-store.serializer';
import { AppState, metaReducers, reducers } from './state';
import { StripeService } from './stripe.service';
import { TrainingModule } from './training/training.module';
import { UnitsOfMeasureEffects } from './unit-of-measure/units-of-measure.effects';
import { CompanyEffects } from './users/companies/company.effects';
import { NetworkEffects } from './users/network.effects';
import { ProfileEffects } from './users/profile/profile.effects';
import { TeamEffects } from './users/team/team.effects';
import { UserFileEffects } from './users/user-files/user-file.effects';
import { UserModule } from './users/user.module';
import { UserEffects } from './users/users.effects';
import { CalendarEffects } from './work/calendar/calendar.effects';
import { InstallerReviewEffects } from './work/installer-review/installer-review.effects';
import { KudosEffects } from './work/kudos/kudos.effects';
import { LocalNotificationsService } from './work/work-order-chat/local-notifications.service';
import { WorkOrderEditEffects } from './work/work-order-edit/work-order-edit.effects';
import { WorkOrderItemEffects } from './work/work-order-item/work-order-item.effects';
import { WorkOrderEffects } from './work/work-order/work-order.effects';
import { WorkPollingEffects } from './work/work-polling.effects';
import { WorkEffects } from './work/work.effects';
import { WorkModule } from './work/work.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LogModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    FileUploadModule,
    EffectsModule.forRoot([
      /*01*/ AuthConnectEffects,
      /*02*/ AuthEffects,
      /*03*/ AppEffects,
      UserFileEffects,
      UnitsOfMeasureEffects,
      WorkEffects,
      KudosEffects,
      LienReleaseEffects,
      CalendarEffects,
      CompanyEffects,
      InvoiceEffects,
      InvoiceUIEffects,
      InvoiceActionsEffects,
      InstallerReviewEffects,
      LocationsEffects,
      LoginEffects,
      TeamEffects,
      ConnectionEffects,
      NetworkEffects,
      UserEffects,
      FirebaseEffects,
      WorkOrderEditEffects,
      WorkOrderEffects,
      WorkOrderItemEffects,
      WorkPollingEffects,
      ProfileEffects,
      EnvironmentEffects,
    ]),
    StoreDevtoolsModule.instrument({
      name: 'Go Carrera App',
      maxAge: 100,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: GRouterStateSerializer,
    }),
    NgrxAutoEntityModule.forRoot(),
    IonicStorageModule.forRoot({
      name: 'go-carrera',
      // eslint-disable-next-line no-underscore-dangle
      driverOrder: ['sqlite', Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    StorageSyncModule.forRoot<AppState>({
      storageKey: 'GC_Storage',
      keys: {
        calendar: true,
        app: true,
        workOrder: {
          editedWorkOrders: true,
        },
        invoiceAndPayment: {
          invoiceInfoForSigningLienRelease: true,
        },
      },
    }),

    HttpModule,

    CustomDatesModule,
    CoreCalendarModule,
    CoreInvitesModule,
    ProfileModule,
    CoreInstallerProfileModule,
    WorkModule,
    InvoiceModule,
    NegotiationsModule,
    ChangeOrdersModule,
    ConfirmDeclineModalModule,
    PhoneNumberModule,
    TrainingModule,
    LocationsModule,
    UserModule,
    OptionsPopoverModule,
    PartnerDealsModule,
    FileUploadProgressPopoverModule,
    ViewProjectManagerProfileModule,
    ViewInstallerProfileModalModule,
    ViewJumpstarterProfileModalModule,
    ViewInstallerProfileEnhancedModule,
    ClaimProductInstallationTypeModalModule,
    ClaimTrainingModalModule,
    ClaimProductInstallationSubtypesModalModule,
    CallPhoneNumbersModule,
  ],
  providers: [
    AppFacade,
    AuthFacade,
    LoginService,
    FileUploadService,
    AppUIService,
    FirebaseService,
    AnalyticsService,
    StripeService,
    BrowserService,
    RouterOutletService,
    LaunchNavigator,
    Deploy,
    LiveUpdateService,
    LocalNotificationsService,
  ],
})
export class StateModule {}
