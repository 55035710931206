<ion-header class="ion-no-border">
  <ion-toolbar color="danger">
    <ion-buttons slot="start">
      <ion-button color="light" (click)="cancelAddEdit()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Negotiate Items</ion-title>
  </ion-toolbar>
  <ion-toolbar class="segment-container" color="danger" *ngIf="auth.user$ | push | isInstaller">
    <ion-segment
      mode="ios"
      [value]="negotiations.onlyNegotiated$ | push"
      (ionChange)="updateNegotiationFilter($event.target.value)"
    >
      <ion-segment-button [value]="false"> All</ion-segment-button>
      <ion-segment-button [value]="true"> Negotiated</ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="form" *ngIf="form">
    <gc-bid-ask-negotiation-date
      [form]="form"
      [pendingNegotiation]="negotiations.filteredPendingNegotiationDetails$ | push"
      [role]="auth.role$ | push"
      [workOrder]="work.current$ | push"
    ></gc-bid-ask-negotiation-date>

    <gc-bid-ask-negotiation-item-list
      [form]="form"
      [pendingNegotiation]="negotiations.filteredPendingNegotiationDetails$ | push"
      [role]="auth.role$ | push"
    ></gc-bid-ask-negotiation-item-list>
  </form>
</ion-content>

<ion-footer>
  <ion-grid *ngIf="!(negotiations.pendingNegotiationDetails$ | push)?.isUnavailable">
    <ion-row>
      <ion-col>
        <ion-button
          color="gc-green"
          expand="block"
          size="large"
          (click)="submit()"
          [disabled]="!form.hasChanges || form.invalid"
        >
          <strong>Submit Negotiation</strong>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
