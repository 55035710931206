import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { authenticatedUserMetadataRetrieved } from '~gc/domains/app/auth/auth.actions';
import { ToastService } from '~gc/shared/services/toast.service';
import { transformWhenType, whenType } from '~gc/shared/utils/action-transformer.util';
import { ensureExists } from '../../../utils/rxjs';
import { openPlaid } from '../plaid/plaid.actions';
import {
  dwollaBeneficialOwnershipCertified,
  dwollaCustomerCreateSuccess,
  dwollaDocumentUploadSuccess,
  dwollaDropInFailure,
  dwollaGenericSuccess,
  dwollaInitFailure,
  loadDwollaAccount,
  loadDwollaAccountFailure,
  loadDwollaAccountSuccess,
  loadDwollaCustomerDetails,
  loadDwollaCustomerDetailsFailure,
  loadDwollaCustomerDetailsSuccess,
  transferDwollaBalance,
  transferDwollaBalanceFailure,
  transferDwollaBalanceSuccess,
} from './dwolla.actions';
import { dwollaAccountLink, shouldShowCustomerCreate } from './dwolla.selectors';
import { DwollaService } from './dwolla.service';

@Injectable()
export class DwollaEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly dwolla: DwollaService,
    private readonly store: Store,
    private readonly loading: LoadingController,
    private readonly toasts: ToastService,
  ) {}

  loadDwollaAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticatedUserMetadataRetrieved),
      first(),
      map(() => loadDwollaAccount()),
    ),
  );

  handleLoadDwollaAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDwollaAccount),
      concatMap(() =>
        this.dwolla.loadDwollaAccount().pipe(
          map(dwollaAccount => loadDwollaAccountSuccess({ dwollaAccount })),
          catchError(error => of(loadDwollaAccountFailure({ error }))),
        ),
      ),
    ),
  );

  fetchAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dwollaCustomerCreateSuccess, loadDwollaAccountSuccess),
      transformWhenType<string>(
        whenType(dwollaCustomerCreateSuccess, ({ location }) => location),
        whenType(loadDwollaAccountSuccess, ({ dwollaAccount }) => dwollaAccount.link),
      ),
      map(link => loadDwollaCustomerDetails({ link })),
    ),
  );

  reloadAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dwollaBeneficialOwnershipCertified, dwollaDocumentUploadSuccess, dwollaGenericSuccess),
      withLatestFrom(this.store.select(dwollaAccountLink)),
      map(([, link]) => link),
      ensureExists(link => !!link),
      map(link => loadDwollaCustomerDetails({ link })),
    ),
  );

  closeModalWhenNoLongerNeeded$ = createEffect(() =>
    this.store.select(shouldShowCustomerCreate).pipe(
      filter(shouldShow => !shouldShow),
      switchMap(() => this.dwolla.dismissModal()),
      filter(dismiss => dismiss),
      map(() => openPlaid({})),
    ),
  );

  handleLoadAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDwollaCustomerDetails),
      map(({ link }) => link.split('/').pop()),
      ensureExists(id => !!id),
      concatMap(id =>
        this.dwolla.getAccountDetails(id).pipe(
          map(customerDetails => loadDwollaCustomerDetailsSuccess({ customerDetails })),
          catchError(error => of(loadDwollaCustomerDetailsFailure({ error }))),
        ),
      ),
    ),
  );

  handleDwollaInitFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dwollaInitFailure),
        tap(() =>
          this.toasts.toastResult('Cannot connect to dwolla at this time', 'Error connecting to Dwolla', {
            error: true,
          }),
        ),
      ),
    { dispatch: false },
  );

  handleDropInFailureRes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dwollaDropInFailure),
        tap(({ code, message }) =>
          this.toasts.toastResult(message, `Error: ${code}`, {
            error: true,
          }),
        ),
      ),
    { dispatch: false },
  );

  transferBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transferDwollaBalance),
      // exhaustMap(() => this.loading.create({ message: 'Transferring Balance...' }).then(loading => loading.present())),
      exhaustMap(() =>
        this.dwolla.transferBalance().pipe(
          map(dwollaAccount => transferDwollaBalanceSuccess({ dwollaAccount })),
          catchError(() => of(transferDwollaBalanceFailure())),
        ),
      ),
    ),
  );

  transferBalanceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transferDwollaBalanceSuccess),
        // switchMap(() => this.loading.dismiss())
        tap(() => this.toasts.toastResult('Balance successfully transferred to account.', 'Balance Transfer')),
      ),
    { dispatch: false },
  );

  transferBalanceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(transferDwollaBalanceFailure),
        // switchMap(() => this.loading.dismiss()),
        switchMap(() =>
          this.toasts.toastResult('Balance successfully transfered to account.', 'Payment Error', { error: true }),
        ),
      ),
    { dispatch: false },
  );
}
