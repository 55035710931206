import { ActionReducerMap } from '@ngrx/store';
import { invitesReducer, InvitesState } from './invites.state';
import { lookupsReducer, LookupsState } from './lookups.state';

export interface FeatureState {
  lookups: LookupsState;
  invites: InvitesState;
}

export const reducer: ActionReducerMap<FeatureState> = {
  lookups: lookupsReducer,
  invites: invitesReducer,
};
