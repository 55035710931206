import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../pipes/pipes.module';
import { ContentModule } from '../content/content.module';
import { NegotiableItemListComponent } from './negotiable-item-list.component';

@NgModule({
  declarations: [NegotiableItemListComponent],
  imports: [CommonModule, IonicModule, PipesModule, ContentModule],
  exports: [NegotiableItemListComponent],
})
export class NegotiableItemListModule {}
