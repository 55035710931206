import { createAction, props } from '@ngrx/store';
import { WorkOrder } from '../work-order/work-order.model';

export const updateCalendarAreaTitle = createAction('[Calendar] Set Area Title', props<{ title: string }>());

export const updateCalendarDate = createAction('[Calendar] Update Date', props<{ date: number }>());

export const previewCalendarWorkOrder = createAction(
  '[Work Orders] (Calendar) Preview',
  props<{ event: Event; workOrderId?: string }>(),
);

export const reviewInstallerFromPreview = createAction(
  '[Work Orders] (Calendar) Review Installers From Preview',
  props<{ workOrder: WorkOrder }>(),
);
