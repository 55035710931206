import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { Category } from './category.model';
import { CategoryFacadeBase } from './category.state';

@Injectable()
export class CategoryFacade extends CategoryFacadeBase {
  constructor(store: Store<AppState>) {
    super(Category, store);
  }
}
