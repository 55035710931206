<div class="profile-content-section" data-cy="kudos-chart-area">
  <h2 data-cy="kudos-chart-title">Kudos</h2>
  <div class="radar-chart-wrapper">
    <div class="radar-chart">
      <div id="kudosReasonsRadarChart" data-cy="kudos-radar-chart"></div>
    </div>
    <div class="m-20" style="align-self: center" *ngIf="kudosReasonsDetails; else noKudos">
      <h2 [attr.data-cy]="'kudos-chart-title-' + kudosReasonsDetails.primaryKudos.title">
        {{ kudosReasonsDetails.primaryKudos.title }}
      </h2>
      <p [attr.data-cy]="'kudos-chart-description-' + kudosReasonsDetails.primaryKudos.description">
        {{ kudosReasonsDetails.primaryKudos.description }}
      </p>
      <a
        [href]="kudosReasonsDetails.primaryKudos.url"
        style="color: #0058ff; text-decoration: none; font-weight: bold"
        target="_blank"
        data-cy="kudos-chart-link"
      >
        <small>What does this imply?</small>
      </a>
    </div>
    <ng-template #noKudos>
      <div class="alert alert__warning text-center mt-0" data-cy="empty-kudos-area">
        There are currently no kudos to display.
      </div>
    </ng-template>
  </div>
</div>
