import { buildState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { JumpstarterFavorite } from './jumpstarter-favorite.model';

export const {
  initialState: initialJumpstarterFavoriteState,
  facade: JumpstartFavoritesFacadeBase,
  actions: {
    loadAll: loadAllJumpstarterFavorites,
    create: createJumpstarterFavorite,
    delete: deleteJumpstarterFavorite,
    deleteSuccess: deleteJumpstarterFavoriteSuccess,
    replace: replaceJumpstarterFavorite,
    replaceSuccess: replaceJumpstarterFavoriteSuccess,
  },
  selectors: { selectAll: selectAllJumpstarterFavorites },
} = buildState(JumpstarterFavorite);

const reduce = createReducer(initialJumpstarterFavoriteState);

export function jumpstarterFavoriteReducer(state = initialJumpstarterFavoriteState, action: Action) {
  return reduce(state, action);
}
