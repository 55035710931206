import { Entity } from '@briebug/ngrx-auto-entity';
import { FileType, GenericFile } from '../../models/generic-file';
import { PendingFile } from '../../models/pending-file';

@Entity({
  modelName: 'WorkOrderFile',
  uriName: 'files',
})
export class WorkOrderFile extends GenericFile {
  override type: FileType.WorkOrderAttachment = FileType.WorkOrderAttachment as const;
}

export type PendingWorkOrderFile = WorkOrderFile | PendingFile;
