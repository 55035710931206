import { createSelector } from '@ngrx/store';
import { authenticatedUser } from '../../../domains/app/auth/auth.selectors';
import { Installer, User } from '../../../domains/users';
import { currentInstaller } from './profile.selectors';


export const isCurrentInstallerAlsoCurrentUser = createSelector(
  currentInstaller,
  authenticatedUser,
  (installer: Installer, user: User): boolean => installer?.id === user?.id,
);
