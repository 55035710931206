import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';
import { tokenPermissions } from '../../../domains/app/auth/auth.selectors';
import { WorkOrderTagFacade } from '../../../domains/work/work-order-tag/work-order-tag.facade';
import { WorkOrderFacade } from '../../../domains/work/work-order/work-order.facade';


@Component({
  selector: 'gc-work-order-detail-detail',
  templateUrl: './work-order-detail-detail.component.html',
  styleUrls: ['./work-order-detail-detail.component.scss'],
})
export class WorkOrderDetailDetailComponent {
  permissions$ = this.store.select(tokenPermissions);

  constructor(
    public readonly auth: AuthFacade,
    public readonly workOrders: WorkOrderFacade,
    public readonly workOrderTags: WorkOrderTagFacade,
    private readonly store: Store,
  ) {}
}
