import { createReducer, on } from '@ngrx/store';
import { calendarFilterOptionsChanged, calendarFlterOptionsRestored } from './filters.actions';

export interface FiltersState {
  showPending: boolean;
  showUnapprovedConnections: boolean;
  privateConnections?: string[];
  publicConnections?: string[];
  nonConnectedInstallers?: string[];
  employeeInstallers?: string[];
  managers?: string[];
}

export const filtersInitialState: FiltersState = {
  showPending: true,
  showUnapprovedConnections: true,
};

export const restoreState = <TState>(state: TState, { restored }: { restored: TState }): TState => restored;

export const mergeFilterOptions = (state: FiltersState, options: Partial<FiltersState>) => ({
  ...state,
  ...{ ...options, type: undefined },
});

export const filtersReducer = createReducer(
  filtersInitialState,
  on(calendarFlterOptionsRestored, restoreState),
  on(calendarFilterOptionsChanged, mergeFilterOptions),
);
