<ng-container *rxLet="context$; let context">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="dark" (click)="modalGC.dismiss(undefined, 'cancel')" data-cy="close-claim-training-modal">
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title data-cy="claim-training-modal-title">{{ context.category.type }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content [attr.data-cy]="context.category.type + '-modal-list'">
    <gc-item-chooser-list
      [items]="context.items"
      noItemsMessage="No additional trainings to claim for this category."
      (added)="popover.present($event.$event); adding($event.value)"
    ></gc-item-chooser-list>
    <ion-popover side="left" #popover (didDismiss)="cancelled($event)" data-cy="training-confirmation-popover">
      <ng-template>
        <ion-datetime
          [max]="today"
          presentation="date"
          (ionChange)="addTraining(popover, $event)"
          data-cy="training-confirmation-date-picker"
        ></ion-datetime>
      </ng-template>
    </ion-popover>
  </ion-content>
  <ion-footer class="p-3">
    <ion-button
      mode="ios"
      color="dark"
      expand="block"
      (click)="modalGC.dismiss(undefined, 'ok')"
      data-cy="training-submit-button"
      >Submit
    </ion-button>
  </ion-footer>
</ng-container>
