import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Invoice } from '~gc/domains/invoices';
import { SettlementTimingFacade } from '~gc/domains/invoices/settlement-timing/settlement-timing.facade';
import { DwollaFacade } from '~gc/shared/dwolla/dwolla.facade';

@Component({
  selector: 'gc-select-settlement-timing',
  templateUrl: './select-settlement-timing.component.html',
  styleUrls: ['./select-settlement-timing.component.scss'],
})
export class SelectSettlementTimingComponent {
  @Input() invoice?: Invoice;

  constructor(
    public settlementTimings: SettlementTimingFacade,
    public modalGC: ModalController,
    public dwolla: DwollaFacade,
  ) {}
}
