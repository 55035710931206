import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'gc-add-fab',
  template: `
    <ion-fab (click)="clicked.emit()" slot="fixed" vertical="bottom" horizontal="end">
      <ion-fab-button [color]="color">
        <ion-icon *ngIf="icon && !faIcon && !iconTemplate" [name]="icon"></ion-icon>
        <fa-icon *ngIf="!icon && !faIcon && !iconTemplate" [icon]="faPlus" size="2x"></fa-icon>
        <fa-icon *ngIf="faIcon && !icon && !iconTemplate" [icon]="faIcon" size="2x"></fa-icon>
        <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
      </ion-fab-button>
    </ion-fab>
  `,
})
export class AddFabComponent {
  @Input() color = 'primary';
  @Input() icon?: string; // = 'add-outline';
  @Input() iconTemplate!: TemplateRef<any>;
  @Input() faIcon?: IconDefinition;

  @Output() clicked = new EventEmitter();

  readonly faPlus = faPlus;
}
