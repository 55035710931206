import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { Connection } from './connection/connection.model';
import { Installer, Jumpstarter, ProjectManager, User } from './user/user.model';
import {
  closeProfileModal,
  disconnectUser,
  removeUser,
  selectedEmployeeInstallerProfile,
  showJumpstarterProfile,
  showProjectManagerProfile,
  skillsAndExperienceViewed,
} from './users-ui.actions';

@Injectable({ providedIn: 'root' })
export class UsersUIFacade {
  constructor(private store: Store<AppState>) {}

  removeUser(user: User) {
    this.store.dispatch(removeUser({ user }));
  }

  disconnectUser(connection: Connection) {
    this.store.dispatch(disconnectUser({ connection }));
  }

  showInstallerProfile(installer: Installer): void {
    this.store.dispatch(selectedEmployeeInstallerProfile({ installer }));
  }

  showJumpstarterProfile(jumpstarter: Jumpstarter): void {
    this.store.dispatch(showJumpstarterProfile({ jumpstarter }));
  }

  showProjectManagerProfile(projectManager: ProjectManager): void {
    this.store.dispatch(showProjectManagerProfile({ projectManager }));
  }

  closeProfile(): void {
    this.store.dispatch(closeProfileModal());
  }

  viewSkillsAndExperience(user: User): void {
    this.store.dispatch(skillsAndExperienceViewed({ user }));
  }
}
