import { ActionReducerMap } from '@ngrx/store';
import { uiReducer, UIState } from './ui.state';

export interface FeatureState {
  ui: UIState;
}

export const reducer: ActionReducerMap<FeatureState> = {
  ui: uiReducer,
};
