import { createSelector } from '@ngrx/store';
import { Invoice } from '../invoice/invoice.model';
import { currentInvoice } from '../invoice/invoices.state';
import { InvoiceItem } from './invoice-item.model';
import { allInvoiceItems } from './invoice-item.state';

export const currentInvoiceItems = createSelector(
  allInvoiceItems,
  currentInvoice,
  (items: InvoiceItem[], invoice?: Invoice): InvoiceItem[] =>
    !!invoice ? items?.filter(item => item.invoiceId === invoice?.id) : [],
);
