import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import {
  detailedInstallerProfileContext,
  DetailedProductInstallationTypeClaimed,
  subtypeClaimRemoved,
  trainingClaimRemoved,
} from '../../core/installer-profile';
import { productInstallationTypeClaimRemoved } from '../../core/installer-profile/product-installation-type.actions';
import { Category } from '../../domains/training/category/category.model';
import {
  ProductInstallationSubtypesClaimed
} from '../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import {
  ProductInstallationTypeClaimed
} from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';
import { TrainingClaimed } from '../../domains/training/trainings-claimed/trainings-claimed.model';
import { Installer } from '../../domains/users';
import { Connection } from '../../domains/users/connection/connection.model';
import { onlyNonNullish } from '../../shared/utils/rxjs';
import { InstallerProfileAreas } from './store/ui.state';
import {
  backgroundSelected,
  closeInstallerProfileEnhancedModal,
  complianceSelected,
  detailsSelected,
  editMyProfileSelected,
  installerCalled,
  installerEmailed,
  installerWebsiteVisited,
  installTypeClaimSelected,
  optionsMenuOpened,
  overviewSelected,
  productInstallationSubtypeAddingFromEducationAndTraining,
  productInstallationSubtypeAddingFromOverview,
  productInstallationTypeAdding,
  trainingClaimAdding,
  trainingSelected,
} from './view-installer-profile-enhanced.actions';
import { selectedArea } from './view-installer-profile-enhanced.selectors';


@Component({
  selector: 'gc-view-installer-profile-enhanced',
  templateUrl: './view-installer-profile-enhanced.component.html',
  styleUrls: ['./view-installer-profile-enhanced.component.scss'],
})
export class ViewInstallerProfileEnhancedComponent {
  context$ = this.store.select(detailedInstallerProfileContext).pipe(onlyNonNullish, tap(console.log));
  selectedArea$ = this.store.select(selectedArea);

  constructor(public readonly store: Store) {}

  callInstaller(installer: Installer) {
    this.store.dispatch(installerCalled({ installer }));
  }

  emailInstaller(installer: Installer) {
    this.store.dispatch(installerEmailed({ installer }));
  }

  visitInstallerWebsite(installer: Installer) {
    this.store.dispatch(installerWebsiteVisited({ installer }));
  }

  openOptionsMenu({ event, installer }: { event: Event; installer: Installer }, connection?: Connection) {
    this.store.dispatch(optionsMenuOpened({ event, installer, connection }));
  }

  selectOverview(area: InstallerProfileAreas) {
    this.store.dispatch(overviewSelected({ area }));
  }

  selectBackground(area: InstallerProfileAreas) {
    this.store.dispatch(backgroundSelected({ area }));
  }

  selectCompliance(area: InstallerProfileAreas) {
    this.store.dispatch(complianceSelected({ area }));
  }

  selectTraining(area: InstallerProfileAreas) {
    this.store.dispatch(trainingSelected({ area }));
  }

  selectDetails(area: InstallerProfileAreas) {
    this.store.dispatch(detailsSelected({ area }));
  }

  selectEducationArea(claim: DetailedProductInstallationTypeClaimed) {
    console.log(claim);
    this.store.dispatch(installTypeClaimSelected({ claim }));
  }

  closeProfile(installer: Installer) {
    this.store.dispatch(closeInstallerProfileEnhancedModal({ installer }));
  }

  editMyProfile(installer: Installer) {
    this.store.dispatch(editMyProfileSelected({ installer }));
  }

  addNewProductInstallationTypeToProfile() {
    this.store.dispatch(productInstallationTypeAdding());
  }

  removeProductInstallationTypeFromProfile(claim: ProductInstallationTypeClaimed) {
    this.store.dispatch(productInstallationTypeClaimRemoved({ claim }));
  }

  addNewTrainingClaimToCategory(category: Category) {
    this.store.dispatch(trainingClaimAdding({ category }));
  }

  removeTrainingClaimFromCategory(claim: TrainingClaimed) {
    this.store.dispatch(trainingClaimRemoved({ claim }));
  }

  addNewSubtypeToProfileFromOverview(installType: ProductInstallationType) {
    this.store.dispatch(productInstallationSubtypeAddingFromOverview({ installType }));
  }

  addNewSubtypeToProfileFromEducationAndTraining(installType: ProductInstallationType) {
    this.store.dispatch(productInstallationSubtypeAddingFromEducationAndTraining({ installType }));
  }

  removeSubtypeFromProfile(subtypeClaim: ProductInstallationSubtypesClaimed) {
    this.store.dispatch(subtypeClaimRemoved({ subtypeClaim }));
  }
}
