import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { InvoiceItem } from '~gc/domains/invoices/invoice-item/invoice-item.model';

@Entity({
  modelName: 'Invoice',
  uriName: 'invoices',
  comparer: (a: any, b: any) => (b.createdOn ?? b.id).localeCompare(a.createdOn ?? a.id),
})
export class Invoice {
  @Key id?: number;
  approvedAt?: string;
  approvedById?: string;
  status!: InvoiceStatus;
  workOrderId!: string;
  isArchived?: boolean;
  isProgressReport?: boolean;
  workOrderName?: string;
  declinedReason?: string;
  invoiceAmount?: number;
  settlementAmount?: number;
  invoiceItems?: Partial<InvoiceItem>[];
  createdOn?: string;
  updatedOn?: string;
  companyId?: string;
  installerId?: string;
}

export enum InvoiceStatus {
  New = 'NEW',
  Read = 'READ',
  ApprovedPendingPayment = 'APPROVED_PENDING_PAYMENT',
  PaidPendingLienRelease = 'PAID_PENDING_LIEN_RELEASE',
  PaymentProcessing = 'PAYMENT_PROCESSING',
  Paid = 'PAID',
  Declined = 'REJECTED',
  Closed = 'CLOSED',
  Voided = 'VOIDED',
}

export const invoiceIsStatus = (invoice: { status: InvoiceStatus }, ...statuses: InvoiceStatus[]) =>
  statuses.includes(invoice.status);
