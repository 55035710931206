import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { NetworkConnectionModalComponent } from './network-connection-modal/network-connection-modal.component';

@NgModule({
  declarations: [NetworkConnectionModalComponent],
  imports: [CommonModule, IonicModule, PipesModule],
  exports: [NetworkConnectionModalComponent],
})
export class NetworkConnectionModule {}
