import { Injectable } from '@angular/core';
import { ReplaceSuccess } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, iif, MonoTypeOperatorFunction, Observable, of, pipe } from 'rxjs';
import { delay, exhaustMap, filter, map, switchMap, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { matchesCorrelationProcess } from '../../../shared/utils/operators';
import { ensureChildrenRequired } from '../../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedUser, tokenPermissions } from '../../app/auth/auth.selectors';
import { AppState } from '../../state';
import {
  EmployeeInstaller,
  Installer,
  InstallerTeamLead,
  isEmployeeInstaller,
  isInstallerLead,
  isManager,
  Manager,
  User,
} from '../../users';
import { sortedApprovedConnectedTeamLeads, sortedApprovedTeamLeads } from '../../users/network.selectors';
import { allEmployeeInstallers } from '../../users/user/user.selectors';
import { userEntities } from '../../users/user/user.state';
import { viewWorkOrder } from '../work-order-ui.actions';
import { WorkOrder, workOrderIsStatus, WorkOrderPublishMethod, WorkOrderStatus } from '../work-order/work-order.model';
import {
  endWorkOrderEdit,
  loadWorkOrder,
  loadWorkOrderSuccess,
  replaceWorkOrder,
  replaceWorkOrderSuccess,
} from '../work-order/work-order.state';
import { inferableInstallerMap } from '../work.selectors';
import {
  publishAssumedWorkOrder,
  publishedToConnections,
  publishedToEmployeeInstaller,
  publishedWorkOrder,
  publishToConnections,
  publishToEmployeeInstallers,
  publishToPublicNetwork,
  publishUnAssumedWorkOrder,
  publishWorkOrder,
  republishEditedWorkOrder,
} from './work-order-edit.actions';
import { renderBasicWorkOrder } from './work-order-edit.effects';
import { PublishTo, WorkOrderPublishUIService } from './work-order-publish-ui.service';

export const WORK_ORDER_PUBLISH_CORRELATION = 'WorkOrderPublish';

export const hasBeenRepublished = (process: string): MonoTypeOperatorFunction<ReplaceSuccess<WorkOrder>> =>
  pipe(
    matchesCorrelationProcess(process),
    filter(({ entity }) => workOrderIsStatus(entity, WorkOrderStatus.Cancelled) && !!entity.republishedId),
  );

@Injectable()
export class WorkOrderPublishEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private publishUI: WorkOrderPublishUIService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  checkPublishMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishWorkOrder, republishEditedWorkOrder),
      withLatestFrom(this.store.select(inferableInstallerMap), this.store.select(userEntities)),
      switchMap(([{ workOrder }, installerMap, users]) =>
        iif(
          () => !!installerMap[workOrder.id ?? ''],
          of(
            publishAssumedWorkOrder({
              installer: users[installerMap[workOrder.id!]] as Installer, // TODO: Can we eliminate this non-nullish assertion????
              workOrder,
            }),
          ),
          of(publishUnAssumedWorkOrder({ workOrder })),
        ),
      ),
    ),
  );

  confirmAssumedEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishAssumedWorkOrder),
      filter(({ installer }) => isEmployeeInstaller(installer)),
      switchMap(({ installer, workOrder }) =>
        from(this.publishUI.confirmAssumedEmployee(installer)).pipe(
          switchMap(role =>
            role === 'ok'
              ? of(publishedToEmployeeInstaller({ workOrder, installer: installer as EmployeeInstaller }))
              : of(publishUnAssumedWorkOrder({ workOrder })),
          ),
        ),
      ),
    ),
  );

  confirmAssumedInstaller$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishAssumedWorkOrder),
      filter(({ installer }) => isInstallerLead(installer)),
      switchMap(({ installer, workOrder }) =>
        from(this.publishUI.confirmAssumedInstaller(installer)).pipe(
          switchMap(role =>
            role === 'ok'
              ? of(
                  publishedToConnections({
                    workOrder,
                    installers: [installer.id!],
                  }),
                )
              : role === 'select'
              ? of(publishToConnections({ workOrder, installers: [installer as InstallerTeamLead] }))
              : of(publishUnAssumedWorkOrder({ workOrder })),
          ),
        ),
      ),
    ),
  );

  publishWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishUnAssumedWorkOrder),
      withLatestFrom(this.store.select(tokenPermissions)),
      exhaustMap(([{ workOrder }, permissions]) =>
        from(this.publishUI.publish(permissions)).pipe(
          filter(({ role }) => role !== PublishTo.cancel),
          delay(500),
          map(({ role }) =>
            role === PublishTo.employees
              ? publishToEmployeeInstallers({ workOrder })
              : role === PublishTo.connections
              ? publishToConnections({ workOrder })
              : publishToPublicNetwork({ workOrder }),
          ),
        ),
      ),
    ),
  );

  publishToPublicNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishToPublicNetwork),
      map(({ workOrder }) => ({
        ...(workOrderIsStatus(workOrder, WorkOrderStatus.Draft) ? renderBasicWorkOrder(workOrder) : workOrder),
        status: workOrderIsStatus(workOrder, WorkOrderStatus.Draft)
          ? WorkOrderStatus.PublishedAwaitingResponse
          : workOrder.status,
        publishMethod: WorkOrderPublishMethod.Public,
      })),
      map(finalWorkOrder => publishedWorkOrder({ workOrder: finalWorkOrder })),
    ),
  );

  publishToConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishToConnections),
      withLatestFrom(this.store.select(sortedApprovedConnectedTeamLeads)),
      exhaustMap(([{ workOrder, installers: prefilled }, connectionInstallers]) =>
        from(this.publishUI.showPublishToConnectionsModal(workOrder, connectionInstallers, prefilled)).pipe(
          filter(({ role }) => role === 'ok'),
          map(({ installers }) => publishedToConnections({ workOrder, installers })),
        ),
      ),
    ),
  );

  finalizePublishToConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishedToConnections),
      map(({ workOrder, installers: installerIds }) => ({
        ...(workOrderIsStatus(workOrder, WorkOrderStatus.Draft) ? renderBasicWorkOrder(workOrder) : workOrder),
        matchedInstallers: installerIds.map(id => ({ installerId: id })),
        status: workOrderIsStatus(workOrder, WorkOrderStatus.Draft)
          ? WorkOrderStatus.PublishedAwaitingResponse
          : workOrder.status,
        publishMethod: WorkOrderPublishMethod.ConnectionsWithMap,
      })),
      map(finalWorkOrder => publishedWorkOrder({ workOrder: finalWorkOrder })),
    ),
  );

  publishToEmployeeInstallers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishToEmployeeInstallers),
      withLatestFrom(this.store.select(allEmployeeInstallers)),
      exhaustMap(([{ workOrder }, employeeInstallers]) =>
        from(this.publishUI.showPublishToEmployeeModal(employeeInstallers)).pipe(
          filter(({ role }) => role === 'ok'),
          map(({ installer }) => employeeInstallers.find(employee => employee.id === installer[0] )),
          map(installer => publishedToEmployeeInstaller({ workOrder, installer: installer! })),
        ),
      ),
    ),
  );

  finalizePublishToEmployeeInstallers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishedToEmployeeInstaller),
      map(({ installer, workOrder }) => ({
        ...(workOrderIsStatus(workOrder, WorkOrderStatus.Draft) ? renderBasicWorkOrder(workOrder) : workOrder),
        awardedToInstallerId: installer.id,
        status: WorkOrderStatus.AwardedNotStarted,
        publishMethod: WorkOrderPublishMethod.Internal,
      })),
      map(finalWorkOrder => publishedWorkOrder({ workOrder: finalWorkOrder })),
    ),
  );

  publishAndCompleteWorkOrderEdit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishedWorkOrder),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ workOrder }, user]) => ({ workOrder, user })),
      ensureChildrenRequired<
        { workOrder?: Partial<WorkOrder>; user?: User },
        { workOrder: Required<WorkOrder>; user: Required<Manager> }
      >(value => !!value?.workOrder && !!value.user?.id && isManager(value.user)),
      map(({ workOrder, user }) =>
        replaceWorkOrder({
          entity: { ...workOrder, projectManagerId: user.id } as WorkOrder,
          criteria: { correlationProcess: WORK_ORDER_PUBLISH_CORRELATION },
        }),
      ),
      switchMap(action => [action, endWorkOrderEdit()]),
    ),
  );

  notifyPublishCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replaceWorkOrderSuccess),
      filter(({ entity }) =>
        workOrderIsStatus(entity, WorkOrderStatus.PublishedWithResponses, WorkOrderStatus.PublishedAwaitingResponse),
      ),
      exhaustMap(({ entity }) =>
        from(this.publishUI.notifyPublishCompleted()).pipe(map(() => viewWorkOrder({ workOrder: entity }))),
      ),
    ),
  );

  viewRepublishedWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replaceWorkOrderSuccess),
      hasBeenRepublished(WORK_ORDER_PUBLISH_CORRELATION),
      switchMap(({ entity }) =>
        this.actions$.pipe(
          ofType(loadWorkOrderSuccess),
          filter(({ entity: workOrder }) => entity.republishedId === workOrder.id),
          take(1),
        ),
      ),
      map(({ entity: workOrder }) => viewWorkOrder({ workOrder })),
    ),
  );

  loadNewRepublish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(replaceWorkOrderSuccess),
      hasBeenRepublished(WORK_ORDER_PUBLISH_CORRELATION),
      map(({ entity }) => loadWorkOrder({ keys: entity.republishedId })),
    ),
  );

  notifyRepublishCompleted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(replaceWorkOrderSuccess),
        hasBeenRepublished(WORK_ORDER_PUBLISH_CORRELATION),
        exhaustMap(({ entity }) => this.publishUI.notifyRepublishCompleted()),
      ),
    { dispatch: false },
  );
}
