import * as am5 from '@amcharts/amcharts5';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { currentInstallerHammerRatingCount, ExtendedHammerRatingDetails } from '../../../../core/installer-profile';
import { buildHammerRatingCharts, renderHammerRatingCharts } from '../../../../shared/charts';

@Component({
  selector: 'gc-installer-hammer-rating-charts',
  templateUrl: './installer-hammer-rating-charts.component.html',
  styleUrls: ['./installer-hammer-rating-charts.component.scss'],
})
export class InstallerHammerRatingChartsComponent implements OnDestroy, OnChanges, AfterViewInit {
  private hammerRatingRoots?: am5.Root[];

  @Input() hammerRatingsDetails!: ExtendedHammerRatingDetails[];

  hammerRatingCount$ = this.store.select(currentInstallerHammerRatingCount);

  constructor(
    private zone: NgZone,
    private readonly store: Store,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hammerRatingsDetails &&
      changes.hammerRatingsDetails.currentValue &&
      changes.hammerRatingsDetails.currentValue !== changes.hammerRatingsDetails.previousValue
    ) {
      this.updateChart();
    }
  }

  ngAfterViewInit() {
    this.updateChart();
  }

  updateChart() {
    Promise.resolve().then(() => {
      this.hammerRatingRoots?.forEach(root => root.dispose());
      this.hammerRatingChart(this.hammerRatingsDetails);
      this.changeDetectorRef.detectChanges();
    });
  }

  hammerRatingChart(hammerRatingDetails: ExtendedHammerRatingDetails[]) {
    const charts = buildHammerRatingCharts(hammerRatingDetails);
    renderHammerRatingCharts(charts);
    this.hammerRatingRoots = charts.map(({ root }) => root!);
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      this.hammerRatingRoots?.forEach(root => root.dispose());
    });
  }
}
