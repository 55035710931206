import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PhoneNumberFacade } from '../../../domains/phone-number';

@Component({
  selector: 'gc-call-phone-numbers-modal',
  templateUrl: './call-phone-numbers-modal.component.html',
  styleUrls: ['./call-phone-numbers-modal.component.scss'],
})
export class CallPhoneNumbersModalComponent {
  constructor(
    public readonly modalGC: ModalController,
    public readonly phoneNumbers: PhoneNumberFacade,
  ) {}
}
