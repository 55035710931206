import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../../domains/training/category/category.model';

interface HasCategoryId {
  categoryId: number;
}

@Pipe({ name: 'forCategory' })
export class ForCategoryPipe implements PipeTransform {
  transform<T extends HasCategoryId>(have: T[], category: Category): T[] {
    return have?.filter(has => has.categoryId === category.id) ?? [];
  }
}
