import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { combineLatest, from, iif, Observable, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { editMyBasicProfileSelected } from '../../../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.actions';
import { FileService } from '../../../shared/file-uploads/file.service';
import { routeEndsInPath } from '../../../shared/utils/ngrx';
import { ensureChildrenExist, ensureChildrenRequired, ensureExists, ensureRequired } from '../../../shared/utils/rxjs';
import { coerceEmptyToNull } from '../../../shared/utils/utils';
import { AppUIService } from '../../app-ui.service';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { authenticatedUserMetadataRetrieved, authenticatedUserMetadataUpdated, logout, } from '../../app/auth/auth.actions';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { BrowserService } from '../../browser.service';
import { EmptyKey } from '../../entity.service.utils';
import { FileUploadService } from '../../file-upload.service';
import { loadManyAddresses } from '../../locations/address/address.state';
import { createPhoneNumber, loadAllPhoneNumbersSuccess } from '../../phone-number/phone-number.state';
import { AppState } from '../../state';
import { StripeService } from '../../stripe.service';
import {
  createUserFileSuccess,
  selectPendingUserFileUploads,
  uploadUserFile,
  uploadUserFileFailure,
  uploadUserFileSuccess,
} from '../user-files/user-files.state';
import { isInstallerLead, User } from '../user/user.model';
import { replaceUser, replaceUserFailed, replaceUserSucceeded } from '../user/user.state';
import {
  addProfileFile,
  cancelEditProfile,
  cancelEditProfileConfirmed,
  confirmCancelEditProfile,
  correlateProfileSaved,
  editUserProfile,
  loadUserProfile,
  managePayments,
  profileEditingCompleted,
  ProfileSaveEvents,
  saveUserProfile,
  setProfilePhoto,
  setupPayment,
  stripeUrlRequestError,
  stripeUrlRequestSuccess,
  unapprovedInstallerWizardSubmitted,
  vacationStatusSet,
  vacationStatusToggled,
  viewProvider,
} from './profile.actions';
import { onVacationStatus } from './profile.selectors';
import { ProfilesUIService } from './profiles-ui.service';


export type CorrelatingAction = { correlationId: string; event?: ProfileSaveEvents };

export const checkCorrelation = <T extends CorrelatingAction>(event?: ProfileSaveEvents) =>
  filter(
    ([{ correlationId }, { correlationId: expectedCorrelationId, event: receivedEvent }]: [T, CorrelatingAction]) =>
      correlationId === expectedCorrelationId && (
        event ? receivedEvent === event : true
      ),
  );

@Injectable()
export class ProfileEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private store: Store<AppState>,
    private readonly nav: NavController,
    private readonly appUI: AppUIService,
    private readonly profileUI: ProfilesUIService,
    private readonly fileUploader: FileUploadService,
    private readonly stripe: StripeService,
    private readonly browser: BrowserService,
    private readonly files: FileService,
  ) {
  }

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editUserProfile, editMyBasicProfileSelected, profileEditingCompleted),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) => user),
      ensureExists(user => !!user),
      map(user =>
        loadManyAddresses({
          criteria: {
            parents: { [uriNameOfEntityOrEmpty(User)]: user.id },
          },
        }),
      ),
    ),
  );

  loadUserProfileWizard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeEndsInPath('dashboard'),
      switchMap(() =>
        this.store.select(authenticatedUser).pipe(
          filter(user => !!user),
          take(1),
        ),
      ),
      ensureRequired(user => (
        user && isInstallerLead(user) ? !user.isApproved : false
      )),
      map(user => loadUserProfile({ user })),
    ),
  );

  showUnapprovedInstallerModal$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(authenticatedUserMetadataRetrieved)),
        this.actions$.pipe(ofType(loadAllPhoneNumbersSuccess)),
      ]).pipe(
        map(([{ user }, { entities }]) => (
          { user, entities }
        )),
        ensureRequired(value => (
          !!value?.user && isInstallerLead(value.user) ? !value.user.isApproved : false
        )),
        ensureRequired(value => !value?.user?.addresses?.length || !value?.entities?.length),
        take(1),
        tap(() => this.nav.navigateForward('/app/work/map')),
        switchMap(({ user }) => (
          isInstallerLead(user) ? this.profileUI.showUnapprovedInstallerModal(user) : of(null)
        )),
      ),
    { dispatch: false },
  );

  unapprovedInstallerSubmitted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(unapprovedInstallerWizardSubmitted),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ phoneNumber, address }, authenticatedUser]) => (
        { phoneNumber, address, authenticatedUser }
      )),
      ensureChildrenRequired(value => !!value?.authenticatedUser?.id),
      switchMap(({ phoneNumber, address, authenticatedUser }) => [
        createPhoneNumber({
          entity: phoneNumber,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: authenticatedUser?.id,
            },
          },
        }),
        saveUserProfile({
          user: { ...authenticatedUser, addresses: [address as any] },
          event: ProfileSaveEvents.Unapproved,
        }),
      ]),
    ),
  );

  notifyUnapprovedSaveSuccess$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(replaceUserSucceeded)),
        this.actions$.pipe(ofType(correlateProfileSaved)),
      ]).pipe(
        checkCorrelation(ProfileSaveEvents.Unapproved),
        tap(() => this.profileUI.dismissUnapprovedInstallerModal()),
      ),
    { dispatch: false },
  );

  navigateToProfileEditor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editUserProfile),
        withLatestFrom(this.store.select(authenticatedUser)),
        map(([{ showEditPhoto }, user]) => ({ showEditPhoto, user })),
        ensureChildrenExist(value => !!value?.user),
        exhaustMap(({ showEditPhoto, user }) => from(this.profileUI.showEditProfileModal(user, showEditPhoto))),
      ),
    { dispatch: false },
  );

  cancelEditProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelEditProfile),
      exhaustMap(({ hasChanges }) =>
        iif(() => hasChanges, of(confirmCancelEditProfile()), of(cancelEditProfileConfirmed())),
      ),
    ),
  );

  confirmEditProfileCancellation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmCancelEditProfile),
      exhaustMap(() =>
        from(this.profileUI.confirmEditCancellation()).pipe(
          filter(role => role === 'ok'),
          map(() => cancelEditProfileConfirmed()),
        ),
      ),
    ),
  );

  completeEditProfileCancelation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelEditProfileConfirmed),
        tap(() => this.profileUI.dismissEditProfileModal('cancel')),
      ),
    { dispatch: false },
  );

  addProfileFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProfileFile),
      tap(() => this.appUI.showBlockingSpinner()),
      switchMap(({ user, file }) =>
        this.fileUploader.uploadProfileFile(user, file).pipe(map(entity => createUserFileSuccess({ entity }))),
      ),
      tap(
        () => this.appUI.hideBlockingSpinnerAfterDelay(3000),
        () => this.appUI.hideBlockingSpinnerAfterDelay(3000),
      ),
    ),
  );

  setProfilePhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProfilePhoto),
      ensureChildrenRequired(action => !!action?.user?.id),
      map(({ user, file }) =>
        uploadUserFile({
          // set Id as user id since user can only have 1 profile photo.
          // TODO:: Potential conflict with actual user file id??
          pendingFile: { ...file, id: parseInt(user.id, 10) },
          // Profile Photos don't require success report to api.
          skipAPISuccessCall: true,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
              profiles: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  showUploadProgress$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadUserFile),
        switchMap(() =>
          this.appUI.showPendingFileUploads('Uploading File...', this.store.select(selectPendingUserFileUploads)),
        ),
      ),
    { dispatch: false },
  );

  hideUploadProgressOnFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(uploadUserFileSuccess, uploadUserFileFailure),
        switchMap(() => this.appUI.hidePendingFileUploads()),
      ),
    { dispatch: false },
  );

  saveProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveUserProfile),
      map(({ user, event }) => (
        { user: coerceEmptyToNull(user), event }
      )),
      map(({ user, event }) => (
        {
          action: replaceUser({ entity: { ...user, profilePhoto: undefined } as User }),
          event,
        }
      )),
      switchMap(({ action, event }) => [
        action,
        correlateProfileSaved({ correlationId: action.correlationId, event: event || ProfileSaveEvents.Standard }),
      ]),
    ),
  );

  completeSaveProfile$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(replaceUserSucceeded)),
      this.actions$.pipe(ofType(correlateProfileSaved)),
    ]).pipe(
      checkCorrelation(ProfileSaveEvents.Standard),
      tap(() => this.profileUI.dismissEditProfileModal('ok')),
      exhaustMap(([{ entity }]) => this.profileUI.notifySuccess()),
      map(() => profileEditingCompleted()),
    ),
  );

  updateAuthenticatedUser$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(replaceUserSucceeded)),
      this.actions$.pipe(ofType(correlateProfileSaved)),
    ]).pipe(
      checkCorrelation(),
      map(([{ entity }]) => authenticatedUserMetadataUpdated({ user: entity })),
    ),
  );

  alertSaveFailure$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(replaceUserFailed)),
        this.actions$.pipe(ofType(correlateProfileSaved)),
      ]).pipe(
        checkCorrelation(ProfileSaveEvents.Standard),
        exhaustMap(() => this.profileUI.notifyFailure()),
      ),
    { dispatch: false },
  );

  setupStripePayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setupPayment),
      switchMap(({ businessType }) =>
        this.stripe.create(businessType).pipe(
          map(({ url }) => stripeUrlRequestSuccess({ url })),
          catchError(error => of(stripeUrlRequestError({ error }))),
        ),
      ),
    ),
  );

  manageStripePayments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(managePayments),
      switchMap(() =>
        this.stripe.login().pipe(
          map(({ url }) => stripeUrlRequestSuccess({ url })),
          catchError(error => of(stripeUrlRequestError({ error }))),
        ),
      ),
    ),
  );

  showStripeBrowser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stripeUrlRequestSuccess),
        switchMap(({ url }) => this.browser.openUrl(url)),
      ),
    { dispatch: false },
  );

  viewProviderPopover$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(viewProvider),
        switchMap(() => this.profileUI.viewPaymentProvider()),
      ),
    { dispatch: false },
  );

  showStripeError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stripeUrlRequestError),
        switchMap(() => this.profileUI.toastStripeError()),
      ),
    { dispatch: false },
  );

  toggleVacationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vacationStatusToggled),
      withLatestFrom(this.store.select(authenticatedUser), this.store.select(onVacationStatus)),
      map(([, user, status]) => (
        { user, status }
      )),
      ensureChildrenExist(value => !!value?.user),
      map(({ user, status }) => (
        { ...user, onVacationSince: status ? null : new Date().toISOString() }
      )),
      map(user => replaceUser({ entity: user })),
      switchMap(action => [
        action,
        correlateProfileSaved({ correlationId: action.correlationId, event: ProfileSaveEvents.Vacation }),
      ]),
    ),
  );

  clearLoadingFlag$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(replaceUserSucceeded)),
      this.actions$.pipe(ofType(correlateProfileSaved)),
    ]).pipe(
      checkCorrelation(ProfileSaveEvents.Vacation),
      map(() => vacationStatusSet()),
    ),
  );
}
