<ion-header class="nav-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="save()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Profile</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [ngSwitch]="step">
  <ng-container *ngSwitchCase="1">
    <ion-list>
      <ion-item-divider>Personal</ion-item-divider>
      <gc-basic-profile [validated]="validated" [form]="form"></gc-basic-profile>

      <ion-item-divider>Address</ion-item-divider>
      <gc-address-form
        [validated]="validated"
        [addressForm]="form.address"
        [states]="states.all$ | push"
      ></gc-address-form>
    </ion-list>
  </ng-container>

  <ng-container *ngSwitchCase="2">
    <ion-list>
<!--      <gc-phone-number-manager-->
<!--        [validated]="validated"-->
<!--        [user]="profile.currentEditedUser$ | push"-->
<!--        [phoneNumbers]="profile.currentUserPhoneNumbers$ | push"-->
<!--        [form]="phoneNumberForm"-->
<!--        (added)="phoneNumbers.create($event, { parents: { users: $event.userId } })"-->
<!--        (remove)="phoneNumbers.delete($event, { parents: { users: $event.userId } })"-->
<!--      ></gc-phone-number-manager>-->

      <gc-advanced-phone-number-manager
        [canEdit]="true"
        [entries]="phoneNumbers$ | push"
        (added)="addPhoneNumber($event)"
        (removed)="removePhoneNumber($event)"
        (verified)="verifyPhoneNumber($event)"
      >
      </gc-advanced-phone-number-manager>
    </ion-list>
  </ng-container>

  <ng-container *ngSwitchCase="3">
    <ion-list>
      <gc-profile-picture-form
        [validated]="validated"
        [form]="form"
        [user]="profile.currentEditedUser$ | push"
        (photoChanged)="profile.setProfilePhoto($event.user, $event.file)"
      ></gc-profile-picture-form>

      <gc-color-picker
        *ngIf="auth.role$ | push | isRole: 'companyManager' : 'companyAdmin'"
        [form]="form.color"
      ></gc-color-picker>
    </ion-list>
  </ng-container>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row>
      <ion-col size="3" class="flex justify-content-start">
        <ion-buttons>
          <ion-button *ngIf="step > 1" color="dark" size="large" mode="md" (click)="back()">
            <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
      <ion-col size="6">
        <ion-button color="gc-alt-green" expand="block" size="large" mode="ios" (click)="save()">
          Save Profile
        </ion-button>
      </ion-col>
      <ion-col size="3" class="flex justify-content-end">
        <ion-buttons>
          <ion-button *ngIf="step !== lastStep" color="dark" size="large" mode="md" (click)="next()">
            <ion-icon slot="icon-only" name="chevron-forward"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
