export const stepMap: Record<string, string> = {
  publicJobName: 'basics',
  privateJobName: 'details',
  facilityTypeId: 'basics',
  publicJobSummary: 'basics',
  installationDetails: 'details',
  productInstallationTypeId: 'basics',
  scheduledStartDate: 'basics',
  scheduledEndDate: 'basics',
  dates: 'basics',
  minimumHammerRatingThreshold: 'publishing',
  radius: 'publishing',
  lienReleaseTemplateId: 'publishing',
  address: 'details',
  workOrderItems: 'items',
  files: 'details',
  tags: 'publishing',
};

export const labelMap: Record<string, string> = {
  publicJobName: 'Public Job Name',
  privateJobName: 'Private Job Name',
  facilityTypeId: 'Facility Type',
  publicJobSummary: 'Job Summary',
  installationDetails: 'Installation Details',
  productInstallationTypeId: 'Installation Type',
  scheduledStartDate: 'Scheduled Start Date',
  scheduledEndDate: 'Scheduled End Date',
  dates: 'Schedule',
  minimumHammerRatingThreshold: 'Hammer Rating',
  radius: 'Radius',
  lienReleaseTemplateId: 'Lien Release',
  address: 'Address',
  workOrderItems: 'Work Order Items',
  files: 'Files',
  tags: 'Tags',
};
