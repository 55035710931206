<ion-grid *ngIf="!!invoices && invoices.length > 0" id="invoice-table-component">
  <ion-row class="ion-text-start ion-padding-start ion-padding-end">
    <ion-col *ngIf="!showDetails" size="3">Status</ion-col>
    <ion-col [size]="showDetails ? 7 : 4">Date</ion-col>
    <ion-col class="ion-text-right" size="3">Amount</ion-col>
    <ion-col class="ion-text-right" size="2">Actions</ion-col>
  </ion-row>

  <ng-container *ngIf="!loading; else skeletons">
    <ion-row *ngFor="let invoice of invoices; trackBy: trackBy" class="ion-padding">
      <ion-col *ngIf="showDetails" size="7">
        <ion-text>
          <b>{{ invoice.workOrderName || (invoice | invoiceStatus) }}</b>
          <gc-tooltip *ngIf="invoice.failedLoad" color="warning" icon="warning" size="small">
            {{ invoiceType }} is out of sync with the server. Hit the retry button to reload the invoice.
          </gc-tooltip>
        </ion-text>
        <p class="ion-no-margin">
          <ion-badge
            *ngIf="invoice.status === PaidPendingLienRelease"
            mode="ios"
            color="dark"
            style="transform: translateY(2px)"
          >
            Pending Lien Release
          </ion-badge>
        </p>

        <p class="ion-no-margin">{{ invoice.createdOn | date: 'longDate' }}</p>

        <ng-container *ngIf="!isInstaller; else companyChip">
          <ion-chip
            (click)="showInvoicingInstallerProfile(invoice.installer)"
            outline
            class="installer-avatar-chip ion-margin-top"
            *ngIf="invoice.installer as installer"
          >
            <ion-avatar>
              <img
                alt="{{ installer.firstName }} {{ installer.lastName }}"
                [src]="installer.profilePhoto"
                *ngIf="!!installer.profilePhoto"
              />
            </ion-avatar>
            <ion-icon name="person-circle" size="large" color="medium" *ngIf="!installer.profilePhoto"></ion-icon>
            <ion-label>{{ installer.firstName }} {{ installer.lastName }}</ion-label>
          </ion-chip>
        </ng-container>

        <ng-template #companyChip>
          <ion-chip outline class="company-avatar-chip" *ngIf="invoice.company as company">
            <ion-avatar>
              <img *ngIf="!!company.logoUrl" alt="{{ company.name }}" [src]="company.logoUrl" />
            </ion-avatar>
            <ion-icon *ngIf="!company.logoUrl" name="person-circle" size="large" color="medium"></ion-icon>
            <ion-label>{{ company.name }}</ion-label>
          </ion-chip>
        </ng-template>
      </ion-col>

      <ion-col *ngIf="!showDetails" size="3">
        <p>
          <ion-text [color]="invoice | invoiceStatusColor">
            {{ invoice.status | invoiceStatusLabel: reportsProgress }}
          </ion-text>
        </p>
      </ion-col>

      <ion-col *ngIf="!showDetails" size="4">
        <p>{{ invoice.createdOn | date: 'mediumDate' }}</p>
      </ion-col>

      <ion-col class="ion-text-right" size="3">
        <ion-text
          *ngIf="!!invoice.workOrder && invoice.workOrder?.awardedToInstaller.type === 'INSTALLER_TEAM_LEAD'"
          class="ion-text-nowrap"
          [ngClass]="{ muted: invoice.status === 'REJECTED' }"
        >
          <b>{{ invoice.invoiceAmount | currency }}</b>
        </ion-text>
        <ion-text
          *ngIf="!!invoice.workOrder && invoice.workOrder?.awardedToInstaller.type !== 'INSTALLER_TEAM_LEAD'"
          class="ion-text-nowrap"
          [ngClass]="{ muted: invoice.status === 'REJECTED' }"
        >
          <b>&mdash;</b>
        </ion-text>
        <ion-text
          *ngIf="!invoice.workOrder"
          class="ion-text-nowrap"
          [ngClass]="{ muted: invoice.status === 'REJECTED' }"
        >
          <b>...</b>
        </ion-text>
      </ion-col>

      <ion-col class="ion-text-right invoice-actions" size="2">
        <ion-button
          *ngIf="!invoice.mutating"
          mode="md"
          fill="clear"
          shape="round"
          class="gc-button-round"
          (click)="options.emit({ event: $event, invoice: invoice })"
        >
          <ion-icon color="dark" slot="icon-only" name="chevron-down-circle-outline"></ion-icon>
        </ion-button>
        <ion-spinner *ngIf="invoice.mutating || invoice.loading"></ion-spinner>
        <ion-button *ngIf="invoice.failedLoad" (click)="reload.emit(invoice)" mode="md" fill="clear" shape="round">
          <ion-icon color="dark" slot="icon-only" name="reload"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-infinite-scroll *ngIf="invoices.length > 0" threshold="100px" (ionInfinite)="listEndReached.emit($event)">
      <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more invoices...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </ng-container>

  <ng-template #skeletons>
    <gc-invoice-table-skeleton [showDetails]="showDetails" count="3"></gc-invoice-table-skeleton>
  </ng-template>
</ion-grid>
