import { Component, EventEmitter, Input, Output } from '@angular/core';
import { State } from '~gc/domains/locations';
import { environment } from '../../../../environments/environment';
import { PendingFile } from '../../../domains/models/pending-file';
import { WorkOrderForm } from '../draft-work-order.form';

@Component({
  selector: 'gc-details-step',
  templateUrl: './details-step.component.html',
  styleUrls: ['./details-step.component.scss'],
})
export class DetailsStepComponent {
  @Input() form!: WorkOrderForm;
  @Input() states!: State[];
  @Output() fileAdded = new EventEmitter<PendingFile>();
  @Output() fileRemoved = new EventEmitter<PendingFile>();
  @Output() fileRetried = new EventEmitter<PendingFile>();
  @Output() ready = new EventEmitter<void>();
  @Output() initializing = new EventEmitter<void>();

  tinyMceApiKey = environment.apiKeys.tinyMce;

  constructor() {
    setTimeout(() => {
      this.initializing.emit();
    }, 0);
  }

  onReady = () => {
    setTimeout(() => {
      this.ready.emit();
    }, 10);
  };
}
