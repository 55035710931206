import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { TrainingClaimed } from './trainings-claimed.model';
import { TrainingClaimedFacadeBase } from './trainings-claimed.state';

@Injectable()
export class TrainingClaimedFacade extends TrainingClaimedFacadeBase {
  constructor(store: Store<AppState>) {
    super(TrainingClaimed, store);
  }
}
