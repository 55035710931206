import { Component, Input } from '@angular/core';
import { Invoice } from '../../../domains/invoices';

@Component({
  selector: 'gc-invoice-declined-reason',
  templateUrl: './invoice-declined-reason.component.html',
  styleUrls: ['./invoice-declined-reason.component.scss'],
})
export class InvoiceDeclinedReasonComponent {
  @Input() invoice!: Invoice;
  @Input() reportsProgress!: boolean;
}
