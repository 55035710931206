import { Pipe, PipeTransform } from '@angular/core';

export const EXTENSION_ICONS: Record<string, string> = {
  '.png': 'image',
  '.jpg': 'image',
  '.jpeg': 'image',
  '.gif': 'image',
  '.doc': 'document-text',
  '.pdf': 'document-text',
  '.txt': 'document-text',
  '.csv': 'document-text',
  '.zip': 'documents',
  '.rar': 'documents',
  '.7z': 'documents',
  '.tar': 'documents',
  '.tar.gz': 'documents',
};

@Pipe({ name: 'fileIcon' })
export class FileIconPipe implements PipeTransform {
  transform(filename: string): string {
    return EXTENSION_ICONS[filename?.replace(/(.*)(\.[^.\s]+)$/gi, '$2')?.toLowerCase()] ?? 'document';
  }
}
