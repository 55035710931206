import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'UnitOfMeasure',
  uriName: 'unit-of-measures',
})
export class UnitOfMeasure {
  @Key symbol!: string;
  description?: string;
  class!: string;
}
