import { createAction, props } from '@ngrx/store';
import { InvitedUser } from '../../domains/users';

export const newEmployeeInstallerInvited = createAction(
  '[CORE: Invites] (New Employee Installer) Invited',
  props<{ invite: InvitedUser }>(),
);

export const newProjectManagerInvited = createAction(
  '[CORE: Invites] (New Project Manager) Invited',
  props<{ invite: InvitedUser }>(),
);

export const newEmployeeInstallerInvitedSuccessfully = createAction(
  '[CORE: Invites] (New Employee Installer) Invited Successfully',
  props<{ userId: string }>(),
);

export const newEmployeeInstallerInviteFailed = createAction(
  '[CORE: Invites] (New Employee Installer Invite) Failed',
  props<{ error: any }>(),
);

export const newProjectManagerInvitedSuccessfully = createAction(
  '[CORE: Invites] (New Project Manager) Invited Successfully',
  props<{ userId: string }>(),
);

export const newProjectManagerInviteFailed = createAction(
  '[CORE: Invites] (New Project Manager Invite) Failed',
  props<{ error: any }>(),
);

export const internalInviteProcessCompleting = createAction(
  '[CORE: Invites] (Internal Team Invite) Completing',
  props<{ correlationId: string }>(),
);
