import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { Address } from './address.model';

export const {
  initialState: initialAddressState,
  facade: AddressFacadeBase,
  selectors: { selectAll: allAddresses, selectCurrentEntity: currentAddress },
  actions: {
    loadAll: loadAllAddresses,
    loadAllIfNecessary: loadAllAddressesIfNecessary,
    loadMany: loadManyAddresses,
    loadAllSuccess: loadAllAddressesSuccess,
    replace: replaceAddress,
  },
} = buildState(Address);

const reduce = createReducer(initialAddressState);

export function addressReducer(state = initialAddressState, action: Action): IEntityState<Address> {
  return reduce(state, action);
}
