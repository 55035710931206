<ion-grid>
  <ion-row>
    <ion-col>
      <ion-segment mode="ios" (ionChange)="selected.emit($event.detail.value)" [value]="selectedArea">
        <ion-segment-button
          [attr.data-cy]="'area-segment-button-' + area.name"
          *ngFor="let area of areas"
          [value]="area.name"
          layout="icon-end"
        >
          <ion-badge *ngIf="badgeMap && badgeMap[area.name]" [color]="badgeMap[area.name]?.color">
            {{ badgeMap[area.name].label }}
          </ion-badge>
          <ion-label>{{ area.label }}</ion-label>
          <ion-icon *ngIf="area.icon" [name]="area.icon"></ion-icon>
        </ion-segment-button>
      </ion-segment>
    </ion-col>
  </ion-row>
</ion-grid>
