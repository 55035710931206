import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { PendingNegotiationDetails } from '~gc/domains/negotiations/models/pending-negotiation.model';
import { NegotiationItem } from '~gc/domains/negotiations/negotiation-item/negotiation-item.model';
import { NegotiationMode } from '~gc/modals/bid-ask-negotiation/negotiation-mode';
import { TypedFormGroup } from '~gc/shared/utils/typed-form-group';
import { WorkOrderNegotiationItem } from '../../domains/negotiations/models/work-order-negotiation-item.model';
import { Negotiation } from '../../domains/negotiations/negotiation/negotiation.model';
import { WorkOrder } from '../../domains/work/work-order/work-order.model';
import { compareDateFields } from '../../shared/validators/compare-date-fields';


const defaultAccessor = <T>(obj: T | undefined, prop: keyof T) => obj?.[prop];

export const diffProp = <S, T, K extends keyof (S & T)>({
  source,
  target,
  prop,
  sourceAccessor,
  targetAccessor,
}: {
  source: S;
  target: T;
  prop: K;
  sourceAccessor?: (source: S, prop: keyof S) => any;
  targetAccessor?: (target: T, prop: keyof T) => any;
}) => {
  const a = (sourceAccessor ?? defaultAccessor)(source, prop as keyof S);
  const b = (targetAccessor ?? defaultAccessor)(target, prop as keyof T);
  return a !== b;
};

export const diffProps = <S, T, K extends keyof (S & T)>({
  source,
  target,
  props,
  sourceAccessor,
  targetAccessor,
}: {
  source: S;
  target: T;
  props: K[];
  sourceAccessor?: (source: S, prop: keyof S) => any;
  targetAccessor?: (target: T, prop: keyof T) => any;
}) => {
  return props.some(prop => diffProp({ source, target, prop, sourceAccessor, targetAccessor }));
};

const accessFormControlValue = <T>(target: T, prop: keyof T) => (target[prop] as unknown as UntypedFormControl).value;

export const compareWorkOrderWithBidDate = (workOrder: WorkOrder) => {
  const scheduledStart = new Date(workOrder.scheduledStartDate).getTime();
  const scheduledEnd = new Date(workOrder.scheduledEndDate).getTime();
  return (bidStart: number, askStart: number) => bidStart <= scheduledEnd;
};

export class NegotiationForm extends TypedFormGroup {
  readonly negotiation = this.getControl('negotiation') as NegotiationDateForm;
  readonly negotiationItems = this.getFormArray('negotiationItems') as NegotiationItemFormArray;

  validated = false;

  get isValid(): boolean {
    this.validated = true;
    return this.valid;
  }

  get hasChanges(): boolean {
    const hasDateDiff = this.negotiation.hasChanges;
    const hasItemDiffs = this.negotiationItems.hasChanges;
    return this.dirty && (hasDateDiff || hasItemDiffs);
  }

  get updatedModel(): Partial<PendingNegotiationDetails> {
    const updated = {
      ...this.model,
      ...this.value,
      negotiation: this.negotiation.updatedModel,
      negotiationItems: this.negotiationItems.itemForms.map(form => form.updatedModel),
    };
    return updated;
  }

  constructor(
    public readonly mode: NegotiationMode,
    private readonly workOrder: WorkOrder,
    private model?: Partial<PendingNegotiationDetails>,
  ) {
    super({
      controls: {
        negotiation: new NegotiationDateForm(mode, workOrder, model?.negotiation),
        negotiationItems: new NegotiationItemFormArray(
          mode,
          model?.negotiationItems?.map(item => new NegotiationItemForm(mode, item)) ?? [],
        ),
      },
    });
  }

  updateModel(model: Partial<PendingNegotiationDetails>): void {
    this.model = model;
    this.patchValue(model);
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = value as Partial<PendingNegotiationDetails>;
    super.patchValue(value, options);
  }

  matchingItemForm(item: WorkOrderNegotiationItem) {
    return this.negotiationItems.itemForms.find(form => form.model?.workOrderItemId === item.workOrderItemId);
  }
}

export class NegotiationDateForm extends TypedFormGroup {
  readonly currentAskStartDate = this.getFormControl('currentAskStartDate');
  readonly currentBidStartDate = this.getFormControl('currentBidStartDate');
  readonly isAcceptedByInstaller = this.getFormControl('isAcceptedByInstaller');

  get scheduledStartDate(): string {
    return this.workOrder.scheduledStartDate;
  }

  get startDate(): UntypedFormControl {
    return this.mode === NegotiationMode.Company ? this.currentAskStartDate : this.currentBidStartDate;
  }

  get opposingStartDate(): UntypedFormControl {
    return this.mode === NegotiationMode.Company ? this.currentBidStartDate : this.currentAskStartDate;
  }

  get hasChanges(): boolean {
    const hasDiff = diffProps({
      source: this.model,
      target: this,
      props: ['currentAskStartDate', 'currentBidStartDate', 'isAcceptedByInstaller'],
      targetAccessor: accessFormControlValue,
    });
    return this.dirty && hasDiff;
  }

  get updatedModel(): Negotiation {
    const updated = {
      ...this.model,
      ...this.value,
    };
    return updated;
  }

  constructor(
    public readonly mode: NegotiationMode,
    private readonly workOrder: WorkOrder,
    private model?: Partial<Negotiation>,
  ) {
    super({
      controls: {
        currentAskStartDate: [model?.currentAskStartDate ?? null],
        currentBidStartDate: [model?.currentBidStartDate ?? null],
        isAcceptedByInstaller: [model?.isAcceptedByInstaller ?? false],
      },
      validatorOrOpts: [
        compareDateFields('currentBidStartDate', 'currentAskStartDate', compareWorkOrderWithBidDate(workOrder)),
      ],
    });
  }

  accept() {
    this.isAcceptedByInstaller.setValue(true);
    this.markAsDirty();
    this.updateValueAndValidity();
  }

  unaccept() {
    this.isAcceptedByInstaller.setValue(false);
    this.markAsDirty();
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = value as Partial<Negotiation>;
    super.patchValue(value, options);
  }
}

export class NegotiationItemFormArray extends UntypedFormArray {
  readonly itemForms = this.controls as NegotiationItemForm[];

  get hasChanges(): boolean {
    return this.dirty && this.itemForms.some(form => form.hasChanges);
  }

  constructor(
    private mode: NegotiationMode,
    controls: AbstractControl[],
  ) {
    super(controls);
  }

  override patchValue(value: any[], options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    super.patchValue(value, options);
    value.slice(this.controls.length).forEach(value => this.push(new NegotiationItemForm(this.mode, value)));
  }
}

export class NegotiationItemForm extends TypedFormGroup {
  readonly currentAskPrice = this.getFormControl('currentAskPrice');
  readonly currentBidPrice = this.getFormControl('currentBidPrice');
  readonly isAcceptedByInstaller = this.getFormControl('isAcceptedByInstaller');

  get yourPrice(): UntypedFormControl {
    return this.mode === NegotiationMode.Company ? this.currentAskPrice : this.currentBidPrice;
  }

  get opposingPrice(): UntypedFormControl {
    return this.mode === NegotiationMode.Company ? this.currentBidPrice : this.currentAskPrice;
  }

  get hasChanges(): boolean {
    const hasChanges =
      this.dirty &&
      diffProps({
        source: this.model,
        target: this,
        props: ['currentAskPrice', 'currentBidPrice', 'isAcceptedByInstaller'],
        targetAccessor: accessFormControlValue,
      });
    return hasChanges;
  }

  get updatedModel(): Partial<NegotiationItem> {
    const merged = {
      ...this.model,
      ...this.value,
    };

    const updated = {
      ...merged,
      currentAskPrice: merged.currentAskPrice ? +merged.currentAskPrice : merged.currentAskPrice,
      currentBidPrice: merged.currentBidPrice ? +merged.currentBidPrice : merged.currentBidPrice,
    };

    return updated;
  }

  constructor(
    public readonly mode: NegotiationMode,
    public model?: Partial<NegotiationItem>,
  ) {
    super({
      controls: {
        currentAskPrice: [model?.currentAskPrice ?? null],
        currentBidPrice: [model?.currentBidPrice ?? null],
        isAcceptedByInstaller: [model?.isAcceptedByInstaller ?? false],
      },
    });
  }

  accept() {
    this.isAcceptedByInstaller.setValue(true);
    this.markAsDirty();
    this.updateValueAndValidity();
  }

  unaccept() {
    this.isAcceptedByInstaller.setValue(false);
    this.markAsDirty();
    this.updateValueAndValidity();
  }

  override patchValue(value: { [p: string]: any }, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.model = value as Partial<NegotiationItem>;
    super.patchValue(value, options);
  }
}
