import { createAction, props } from '@ngrx/store';
import { Connection } from '~gc/domains/users/connection/connection.model';
import { InvitedUser } from '../models/invited-user';
import { User, UserType } from '../user/user.model';

export const teamPageInitialize = createAction('[Team Page] Initialize');

export const viewProjectManagers = createAction('[Team] View Project Managers');

export const viewTeamInstallers = createAction('[Team] View Installers');

export const showAddToConnections = createAction('[Team] Show Add To Connections');

export const showInviteUser = createAction('[Team] Show Invite User', props<{ userType: UserType }>());

export const cancelInviteUser = createAction('[Team] Cancel Invite User');

export const inviteEmailUpdated = createAction('[Team] Invite Email Updated', props<{ email: string }>());

export const userFoundByEmail = createAction('[Team] User Found By Email', props<{ user: User }>());

export const userNotFoundByEmail = createAction('[Team] User Not Found By Email');

export const addUserSubmitted = createAction('[Team] Add User Submitted', props<{ user: InvitedUser }>());

export const addUserSuccess = createAction('[Team] Add User Success', props<{ userId: string; user: InvitedUser }>());

export const addUserFailure = createAction('[Team] Add User Failure', props<{ error: any }>());

export const inviteUserSubmitted = createAction('[Team] Invite User Submitted', props<{ user: InvitedUser }>());

export const sendUserInvite = createAction('[Team] Send User Invite', props<{ user: InvitedUser }>());

export const inviteInstaller = createAction('[Team] Invite Installer', props<{ installer: User }>());

export const sendUserInviteSuccess = createAction(
  '[Team] Send User Invite Success',
  props<{ connection: Connection }>(),
);

export const sendUserInviteFailure = createAction('[Team] Send User Invite Failure', props<{ error: any }>());

export const stateFilterUpdated = createAction('[Team] State Filter Updated', props<{ stateFilter: string[] }>());
