<ion-grid>
  <ion-row>
    <ion-col>
      <h1>Contact Information</h1>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col>
      <fieldset>
        <legend>Name</legend>
        <gc-basic-profile [validated]="validated" [form]="form"></gc-basic-profile>
      </fieldset>

      <fieldset>
        <legend>Date of Birth</legend>
        <form [formGroup]="form">
          <gc-datepicker-item
            [control]="form.get('dob')"
            closeOn="select"
            placeHolder="Date of Birth"
            data-cy="installer-editor-date-of-birth-date-picker"
          >
            <ion-label [color]="validated && form.dob.invalid ? 'danger' : undefined"> Date of Birth</ion-label>
          </gc-datepicker-item>
        </form>
      </fieldset>

      <fieldset>
        <legend>Address</legend>
        <gc-address-form [validated]="validated" [addressForm]="form.address" [states]="states"></gc-address-form>
      </fieldset>
    </ion-col>
  </ion-row>
</ion-grid>
