import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-search',
  template: `
    <ion-searchbar
      data-cy="search-bar-input"
      debounce="250"
      [value]="value"
      [placeholder]="placeholder"
      (ionInput)="criteriaChanged.emit($event.detail.value)"
      (ionClear)="cleared.emit()"
      mode="ios"
      style="padding-top: 0"
    ></ion-searchbar>
  `,
})
export class SearchComponent {
  @Input() value?: string;
  @Input() placeholder?: string;
  @Output() criteriaChanged = new EventEmitter<string>();
  @Output() cleared = new EventEmitter();
}
