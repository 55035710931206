import { HttpErrorResponse } from '@angular/common/http';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { of, OperatorFunction, pipe } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { reserialize } from '../utils/utils';

interface ValidationErrorStatusCodeMap {
  [statusCode: number]: ValidationErrors;
}

export const fieldValueMatchesAcceptable = (
  control: AbstractControl | null | undefined,
  possibleValues: string[],
): boolean => !control?.value || possibleValues.some(possible => possible === control.value);

export const mapValidationErrorsFromHttpStatus = (
  map: ValidationErrorStatusCodeMap,
  defaultError = null,
): OperatorFunction<ValidationErrors | null, ValidationErrors | null> =>
  pipe(catchError((err: HttpErrorResponse) => of(map[err.status] ?? defaultError)));

export const autoSetFormValue = (
  control: (AbstractControl & { autoSetValue?: any }) | null | undefined,
  value: any,
): void =>
  !!control && (control?.value || control.autoSetValue != value)
    ? (control.setValue(value), (control.autoSetValue = value))
    : void 0;

export const setFieldOptions = (
  control: (AbstractControl & { availableOptions?: any[] }) | null | undefined,
  options: any[],
): void => (control ? (control.availableOptions = options) : void 0, void 0);

export const addToErrors = (control: AbstractControl | null | undefined, errorName: string, value: any = true): void =>
  Object.keys(control?.errors ?? {}).length > 0
    ? !control?.errors?.[errorName]
      ? control?.setErrors({
          ...control.errors,
          [errorName]: value,
        })
      : void 0
    : control?.setErrors({ [errorName]: value });

export const removeFromErrors = (control: AbstractControl | null | undefined, errorName: string): void =>
  control?.errors?.[errorName]
    ? Object.keys(control.errors ?? {}).length > 1
      ? control.setErrors(
          reserialize({
            ...control.errors,
            [errorName]: undefined,
          }),
        )
      : control.setErrors(null)
    : void 0;
