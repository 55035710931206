import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceItemForm } from './invoice.form';

@Pipe({
  name: 'hasItemFormError',
  pure: false,
})
export class HasItemFormErrorPipe implements PipeTransform {
  transform(itemForm: InvoiceItemForm, error: string): boolean {
    return (
      itemForm.amountToInvoice.invalid &&
      itemForm.amountToInvoice.touched &&
      itemForm.amountToInvoice.dirty &&
      itemForm.amountToInvoice.errors?.[error]
    );
  }
}
