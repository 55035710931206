import { createAction, props } from '@ngrx/store';
import { PendingNegotiationDetails } from '~gc/domains/negotiations/models/pending-negotiation.model';
import { User } from '~gc/domains/users';
import { WorkOrder } from '../work/work-order/work-order.model';

export const negotiationsViewed = createAction('[Negotiations] Viewed');

export const negotiationsClosed = createAction('[Negotiations] Closed', props<{ details: Partial<PendingNegotiationDetails> }>());

export const negotiationsFilterUpdated = createAction(
  '[Negotiations] Negotiations Filtered',
  props<{ filterOnlyNegotiated: boolean }>(),
);

export const negotiationsCanceled = createAction('[Negotiations] Canceled', props<{ workOrder: WorkOrder }>());

export const pendingNegotiationSaved = createAction(
  '[Negotiations] Pending Negotiation Saved',
  props<{
    role: string;
    pendingNegotiation: Partial<PendingNegotiationDetails>;
    workOrder?: WorkOrder;
    user?: User;
  }>(),
);
