import { Entity, Key } from '@briebug/ngrx-auto-entity';


@Entity({
  modelName: 'ProductInstallationSubtype',
  uriName: 'subtypes',
})
export class ProductInstallationSubtype {
  @Key id?: number;
  productInstallationTypeId!: number;
  name!: string;
}

