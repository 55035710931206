import { createAction, props } from '@ngrx/store';
import { AdHocInvoice } from './ad-hoc-invoice/ad-hoc-invoice.model';
import { Customer } from './customer/customer.model';

export const viewCustomer = createAction('[AdHocInvoice] [Customer] View Customer', props<{ customer: Customer }>());

export const showCreateCustomer = createAction('[AdHocInvoice] [Customer] Show Create Customer');

export const editCustomer = createAction('[AdHocInvoice] [Customer] Edit Customer', props<{ customer: Customer }>());

export const submitCreateCustomer = createAction(
  '[AdHocInvoice] [Customer] Submit Create Customer',
  props<{ customer: Customer }>(),
);

export const viewAdHocInvoice = createAction('[AdHocInvoice] View Invoice', props<{ invoice: AdHocInvoice }>());

export const showCreateAdHocInvoice = createAction('[AdHocInvoice] Show Create Invoice');

export const submitCreateAdHocInvoice = createAction(
  '[AdHocInvoice] Submit Create Invoice',
  props<{ invoice: AdHocInvoice }>(),
);
