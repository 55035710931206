import { createSelector } from '@ngrx/store';
import { ROLE_NAMES_MAP } from '~gc/domains/app/auth/auth.maps';
import { WorkOrder, WorkOrderStatus } from '~gc/domains/work/work-order/work-order.model';
import { get } from '../../shared/utils/func';
import { authenticatedRole, authenticatedUser } from '../app/auth/auth.selectors';
import { reportsProgress } from '../invoice-ui.utils';
import { AppState } from '../state';
import { User } from '../users';
import { allUsers } from '../users/user/user.state';
import { calendarAreaTitle } from './calendar/calendar.selectors';
import { currentWorkOrder } from './work-order/work-order.state';
import {
  DETAIL_AREAS_ALLOWED_BY_ROLE_MAP,
  INameLabel,
  mapToNameLabelArray,
  SELECTED_AREA_LABEL_MAP,
  SELECTED_AREA_PAGE_TITLE_MAP,
  SELECTED_DETAIL_AREA_LABEL_MAP,
  STATUS_GROUP_LABEL_MAP,
  STATUS_GROUP_TO_COLOR,
  WORK_AREAS_ALLOWED_BY_ROLE_MAP,
} from './work.maps';
import { StatusGroup, WorkState } from './work.state';

// -----===[ GETTERS & PROJECTIONS ]===-----
// Getters retrieve data from the root app state, mappers
// project data and transform, reduce, join, group, etc.
// to provide specific "data shapes" to the rest of the application

export const getWorkState = (appState: AppState): WorkState => appState.work;

export const mapToPageTitle = (selectedArea: string): string => SELECTED_AREA_PAGE_TITLE_MAP[selectedArea];
export const mapToMonthOrPageTitle = (selectedArea: string, month: string): string =>
  selectedArea === 'calendar' || selectedArea === 'customCalendar'
    ? month && month !== 'Calendar'
      ? `${month?.slice(0, 3)} ${month?.slice(-4)}`.trim() || SELECTED_AREA_PAGE_TITLE_MAP[selectedArea]
      : 'Calendar'
    : SELECTED_AREA_PAGE_TITLE_MAP[selectedArea];

export const mapToWorkAreas = (role: string): INameLabel[] =>
  mapToNameLabelArray(SELECTED_AREA_LABEL_MAP, WORK_AREAS_ALLOWED_BY_ROLE_MAP[ROLE_NAMES_MAP[role]]);

export const mapToDetailAreas = (role: string): INameLabel[] =>
  mapToNameLabelArray(SELECTED_DETAIL_AREA_LABEL_MAP, DETAIL_AREAS_ALLOWED_BY_ROLE_MAP[ROLE_NAMES_MAP[role]]);

export const filterByWorkOrderState = (
  areas: INameLabel[] = [],
  workOrder?: WorkOrder,
): INameLabel[] =>
  areas.filter(
    area =>
      (area.label !== SELECTED_DETAIL_AREA_LABEL_MAP['invoices'] ||
        (workOrder?.status !== WorkOrderStatus.Draft &&
          workOrder?.status !== WorkOrderStatus.PublishedAwaitingResponse &&
          workOrder?.status !== WorkOrderStatus.PublishedWithResponses)),
  );

export const getStatusGroup = (state: WorkState): StatusGroup | undefined => state.currentStatusGroup;

export const mapToStatusLabel = (status?: StatusGroup): string | undefined =>
  status ? STATUS_GROUP_LABEL_MAP[status] : undefined;

export const mapToStatusColor = (status?: StatusGroup): string | undefined =>
  status ? STATUS_GROUP_TO_COLOR[status] || undefined : undefined;

export const getSearchCriteria = (state: WorkState): string => state.searchCriteria ?? '';

export const mapToShouldShowProgressAreas = (status?: StatusGroup): boolean => status === 'in_progress';

export const mapToShouldShowAvatars = (status?: StatusGroup): boolean =>
  ['in_progress', 'completed'].includes(status ?? '');

export const getCurrentAddEditWorkOrderStep = (state: WorkState): string => state.currentEditingStep;

export const getInferredInstallerMap = (state: WorkState): Record<string, string> => state?.inferableInstallers || {};

// -----===[ SELECTORS ]===-----
// Selectors compose getters, other selectors and projections
// to provide memoized copies of that data to the rest of the app.

export const selectedWorkArea = createSelector(getWorkState, get('selectedWorkArea'));

export const selectedDetailArea = createSelector(getWorkState, get('selectedDetailArea'));

export const isAdding = createSelector(getWorkState, get('isAdding'));

export const isShowingDetailModal = createSelector(getWorkState, get('isShowingDetailModal'));

export const detailAreasByRole = createSelector(authenticatedRole, mapToDetailAreas);

export const detailAreasByRoleAndOrderState = createSelector(
  detailAreasByRole,
  currentWorkOrder,
  filterByWorkOrderState,
);

export const filterDetailAreasByRoleAndOrderStateAndAssignment = (areas: INameLabel[], users: User[], user: User, workOrder: WorkOrder) =>
  areas.filter(
    area =>
      (area.label === SELECTED_DETAIL_AREA_LABEL_MAP['progress'] && reportsProgress(users, user, workOrder)) ||
      (area.label === SELECTED_DETAIL_AREA_LABEL_MAP['invoices'] && !reportsProgress(users, user, workOrder)) ||
      (area.label !== SELECTED_DETAIL_AREA_LABEL_MAP['progress'] && area.label !== SELECTED_DETAIL_AREA_LABEL_MAP['invoices'])
  )

export const detailAreasByRoleOrderStateAndAssignment = createSelector(
  detailAreasByRoleAndOrderState,
  allUsers,
  authenticatedUser,
  currentWorkOrder,
  filterDetailAreasByRoleAndOrderStateAndAssignment,
);

export const workArea = createSelector(selectedWorkArea, mapToPageTitle);

export const workAreaOrMonth = createSelector(selectedWorkArea, calendarAreaTitle, mapToMonthOrPageTitle);

export const workAreasByRole = createSelector(authenticatedRole, mapToWorkAreas);

export const currentStatusGroup = createSelector(getWorkState, getStatusGroup);

export const currentStatusLabel = createSelector(currentStatusGroup, mapToStatusLabel);

export const currentStatusColor = createSelector(currentStatusGroup, mapToStatusColor);

export const currentSearchCriteria = createSelector(getWorkState, getSearchCriteria);

export const shouldShowProgressAreas = createSelector(currentStatusGroup, mapToShouldShowProgressAreas);

export const shouldShowAvatars = createSelector(currentStatusGroup, mapToShouldShowAvatars);

export const currentWorkOrderEditingStep = createSelector(getWorkState, getCurrentAddEditWorkOrderStep);

export const inferableInstallerMap = createSelector(getWorkState, getInferredInstallerMap);
