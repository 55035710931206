import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AllowsItemGroupsPipe, ItemGroupsAllowedPipe } from './allows-groups.pipe';
import { AllowsAnyItemActionsPipe, AllowsItemActionsPipe } from './allows-item-actions.pipe';
import { AllowsPriceInfoPipe } from './allows-price-info.pipe';
import { AsInstallerTeamLeadPipe } from './as-installer-lead.pipe';
import { ConnectionStatusLabelPipe } from './connection-status-label.pipe';
import { DaysUntilPipe } from './days-until.pipe';
import { EditModePipe } from './edit-mode.pipe';
import { ExistsPipe } from './exists.pipe';
import { FallbackToPipe } from './fallback-to.pipe';
import { FieldInvalidPipe } from './field-invalid.pipe';
import { FileIconPipe } from './file-icon.pipe';
import { FormatUserNumberPipe } from './format-user-number.pipe';
import { FullNamePipe } from './full-name.pipe';
import { HammerRatingForInstallTypePipe } from './hammer-rating-for-install-type.pipe';
import { HasChangeOrdersPipe } from './has-change-orders.pipe';
import { HasDeclinedWorkOrderPipe } from './has-declined-work-order.pipe';
import { HasNegotiationsPipe } from './has-negotiations.pipe';
import { HasNetworkStatusPipe } from './has-network-status.pipe';
import { HasAnyPermissionPipe, HasPermissionsPipe } from './has-permissions.pipe';
import { HasShownInterestPipe } from './has-shown-interest.pipe';
import { HasSomePipe } from './has-some.pipe';
import { HasUnacceptedNegotiationsPipe } from './has-unaccepted-negotiations.pipe';
import { InvoiceStatusColorPipe } from './invoice-status-color.pipe';
import { InvoiceStatusGroupColorPipe } from './invoice-status-group-color.pipe';
import { InvoiceStatusGroupLabelPipe } from './invoice-status-group-label.pipe';
import { InvoiceStatusLabelPipe } from './invoice-status-label.pipe';
import { InvoiceStatusPipe } from './invoice-status.pipe';
import { IsActiveRoutePipe } from './is-active-route.pipe';
import { IsAreaPipe } from './is-area.pipe';
import { IsAwardedToInstallerPipe } from './is-awarded-to-installer.pipe';
import { IsChangeOrderStatusPipe } from './is-change-order-status.pipe';
import { IsConnectedToPipe } from './is-connected-to.pipe';
import { IsEditablePipe } from './is-editable.pipe';
import { IsEmployeeInstallerPipe } from './is-employee-installer.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { IsInProgressPipe } from './is-in-progress.pipe';
import { IsInstallerApprovedPipe } from './is-installer-approved.pipe';
import { IsInstallerTeamLeadPipe } from './is-installer-team-lead.pipe';
import { IsInstallerTeamMemberPipe } from './is-installer-team-member.pipe';
import { IsInstallerPipe } from './is-installer.pipe';
import { IsInvoiceStatusPipe } from './is-invoice-status.pipe';
import { IsMyCompanyAdminPipe } from './is-my-company-admin.pipe';
import { IsMyCompanyEmployeePipe } from './is-my-company-employee.pipe';
import { IsMyTeamMemberPipe } from './is-my-team-member.pipe';
import { IsPublishedAwaitingResponsePipe } from './is-published-awaiting-response.pipe';
import { IsPublishedWithResponsesPipe } from './is-published-with-responses.pipe';
import { IsCompanyRolePipe, IsInstallerRole, IsRolePipe } from './is-role.pipe';
import { IsSelectedAreaPipe } from './is-selected-area.pipe';
import { IsStatusPipe } from './is-status.pipe';
import { IsWithinDaysPipe } from './is-within-days.pipe';
import { ItemProgressPercentCompletePipe } from './item-progress-percent-complete.pipe';
import { ItemTotalPipe } from './item-total.pipe';
import { JumpstarterFavoriteIsStatusPipe } from './jumpstarter-favorite-is-status.pipe';
import { LogoPipe } from './logo.pipe';
import { MatchingHammerRatingPipe } from './matching-hammer-rating.pipe';
import { NegotiablePriceColorPipe } from './negotiable-price-color.pipe';
import { NetworkStatusColorPipe } from './network-status-color.pipe';
import { NetworkStatusLabelPipe } from './network-status-label.pipe';
import { NotPipe } from './not.pipe';
import { NumberOfUnitsStillPendingPipe } from './number-of-units-still-pending.pipe';
import { PhoneFormatPipe } from './phone-format.pipe';
import { PhoneTypeIconPipe } from './phone-type-icon.pipe';
import { ProfilePhotoPipe } from './profile-photo.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SortByPipe } from './sort-by.pipe';
import { StatusGroupColorPipe } from './status-group-color.pipe';
import { StatusGroupLabelPipe } from './status-group-label.pipe';
import { StatusToStatusGroupPipe } from './status-to-status-group.pipe';
import { TotalNormalizedPricePipe } from './total-normalized-price.pipe';
import { TotalNormalizedUnitsPipe } from './total-normalized-units.pipe';
import { TotalDeductionsPipe } from './total-price.pipe';
import { TrainingStatusIconPipe } from './training-status-icon.pipe';
import { TypeIsInPipe } from './type-is-in.pipe';
import { UserTypePipe } from './user-type.pipe';

@NgModule({
  declarations: [
    AllowsPriceInfoPipe,
    ExistsPipe,
    FormatUserNumberPipe,
    FullNamePipe,
    HammerRatingForInstallTypePipe,
    HasNegotiationsPipe,
    HasSomePipe,
    DaysUntilPipe,
    InvoiceStatusLabelPipe,
    InvoiceStatusPipe,
    IsActiveRoutePipe,
    IsAreaPipe,
    IsAwardedToInstallerPipe,
    IsEditablePipe,
    IsInstallerApprovedPipe,
    IsInstallerPipe,
    IsInstallerTeamMemberPipe,
    IsInstallerTeamLeadPipe,
    IsEmployeeInstallerPipe,
    AsInstallerTeamLeadPipe,
    IsWithinDaysPipe,
    IsPublishedAwaitingResponsePipe,
    IsPublishedWithResponsesPipe,
    IsRolePipe,
    IsCompanyRolePipe,
    IsInstallerRole,
    IsStatusPipe,
    EditModePipe,
    JumpstarterFavoriteIsStatusPipe,
    LogoPipe,
    NegotiablePriceColorPipe,
    NotPipe,
    ProfilePhotoPipe,
    StatusGroupColorPipe,
    StatusGroupLabelPipe,
    StatusToStatusGroupPipe,
    TrainingStatusIconPipe,
    IsInvoiceStatusPipe,
    SafeHtmlPipe,
    HasChangeOrdersPipe,
    ItemGroupsAllowedPipe,
    AllowsItemGroupsPipe,
    AllowsItemActionsPipe,
    AllowsAnyItemActionsPipe,
    TypeIsInPipe,
    NumberOfUnitsStillPendingPipe,
    MatchingHammerRatingPipe,
    IsEmptyPipe,
    PhoneFormatPipe,
    PhoneTypeIconPipe,
    HasShownInterestPipe,
    HasDeclinedWorkOrderPipe,
    FileIconPipe,
    UserTypePipe,
    InvoiceStatusColorPipe,
    InvoiceStatusGroupLabelPipe,
    InvoiceStatusGroupColorPipe,
    HasUnacceptedNegotiationsPipe,
    SortByPipe,
    ItemTotalPipe,
    NetworkStatusLabelPipe,
    NetworkStatusColorPipe,
    HasNetworkStatusPipe,
    IsInProgressPipe,
    HasPermissionsPipe,
    HasAnyPermissionPipe,
    FieldInvalidPipe,
    FallbackToPipe,
    ConnectionStatusLabelPipe,
    IsMyCompanyEmployeePipe,
    IsMyTeamMemberPipe,
    IsConnectedToPipe,
    IsMyCompanyAdminPipe,
    IsChangeOrderStatusPipe,
    TotalNormalizedUnitsPipe,
    TotalNormalizedPricePipe,
    TotalDeductionsPipe,
    IsSelectedAreaPipe,
    ItemProgressPercentCompletePipe,
  ],
  exports: [
    AllowsPriceInfoPipe,
    ExistsPipe,
    FormatUserNumberPipe,
    FullNamePipe,
    HammerRatingForInstallTypePipe,
    HasNegotiationsPipe,
    HasSomePipe,
    InvoiceStatusLabelPipe,
    InvoiceStatusPipe,
    IsActiveRoutePipe,
    IsAwardedToInstallerPipe,
    IsAreaPipe,
    IsEditablePipe,
    IsInstallerApprovedPipe,
    IsInstallerPipe,
    IsInstallerTeamMemberPipe,
    IsInstallerTeamLeadPipe,
    IsEmployeeInstallerPipe,
    IsPublishedAwaitingResponsePipe,
    IsPublishedWithResponsesPipe,
    IsRolePipe,
    IsCompanyRolePipe,
    IsInstallerRole,
    IsStatusPipe,
    EditModePipe,
    JumpstarterFavoriteIsStatusPipe,
    LogoPipe,
    NegotiablePriceColorPipe,
    NotPipe,
    ProfilePhotoPipe,
    StatusGroupColorPipe,
    StatusGroupLabelPipe,
    StatusToStatusGroupPipe,
    TrainingStatusIconPipe,
    IsInvoiceStatusPipe,
    SafeHtmlPipe,
    HasChangeOrdersPipe,
    ItemGroupsAllowedPipe,
    AllowsItemGroupsPipe,
    AllowsItemActionsPipe,
    TypeIsInPipe,
    AllowsAnyItemActionsPipe,
    NumberOfUnitsStillPendingPipe,
    MatchingHammerRatingPipe,
    IsEmptyPipe,
    PhoneFormatPipe,
    PhoneTypeIconPipe,
    HasShownInterestPipe,
    HasDeclinedWorkOrderPipe,
    FileIconPipe,
    UserTypePipe,
    InvoiceStatusColorPipe,
    InvoiceStatusGroupLabelPipe,
    InvoiceStatusGroupColorPipe,
    HasUnacceptedNegotiationsPipe,
    SortByPipe,
    ItemTotalPipe,
    NetworkStatusLabelPipe,
    NetworkStatusColorPipe,
    HasNetworkStatusPipe,
    IsInProgressPipe,
    AsInstallerTeamLeadPipe,
    IsWithinDaysPipe,
    DaysUntilPipe,
    HasPermissionsPipe,
    HasAnyPermissionPipe,
    FieldInvalidPipe,
    FallbackToPipe,
    ConnectionStatusLabelPipe,
    IsMyCompanyEmployeePipe,
    IsMyTeamMemberPipe,
    IsMyCompanyAdminPipe,
    IsConnectedToPipe,
    IsChangeOrderStatusPipe,
    TotalNormalizedUnitsPipe,
    TotalNormalizedPricePipe,
    TotalDeductionsPipe,
    ItemProgressPercentCompletePipe,
    IsSelectedAreaPipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
