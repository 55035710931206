import { Component } from '@angular/core';
import { AuthFacade } from '../../domains/app/auth/auth.facade';
import { ProfileFacade, UsersUIFacade } from '../../domains/users';
import { JumpstarterFavoritesFacade } from '../../domains/users/jumpstarter-favorites/jumpstarter-favorites.facade';

@Component({
  selector: 'gc-view-jumpstarter-profile-modal',
  templateUrl: './view-jumpstarter-profile-modal.component.html',
  styleUrls: ['./view-jumpstarter-profile-modal.component.scss'],
})
export class ViewJumpstarterProfileModalComponent {
  selectedArea = 'general';

  constructor(
    public auth: AuthFacade,
    public profile: ProfileFacade,
    public usersUI: UsersUIFacade,
    public jumpstartFavorites: JumpstarterFavoritesFacade,
  ) {}
}
