import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ConfigService } from '../../../shared/services/config.service';

export const { version } = require('../../../../../package.json');

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(
    private http: HttpClient,
    private toasts: ToastController,
    private readonly config: ConfigService,
  ) {}

  getBackendVersion(): Observable<any> {
    return this.http.get(`${this.config.host}/healthz/info`);
  }

  async toastNetworkDisconnected() {
    const toast = await this.toasts.create({
      color: 'dark',
      position: 'bottom',
      duration: 5000,
      message: 'No Connection.',
      buttons: [{ icon: 'close' }],
    });
    await toast.present();
    return await toast.onDidDismiss();
  }

  async toastNetworkConnected() {
    const toast = await this.toasts.create({
      color: 'dark',
      position: 'bottom',
      duration: 5000,
      message: 'Connection Restored.',
      buttons: [{ icon: 'close' }],
    });
    await toast.present();
    return await toast.onDidDismiss();
  }
}
