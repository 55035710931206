import { Pipe, PipeTransform } from '@angular/core';
import { User } from '~gc/domains/users';

@Pipe({
  name: 'profilePhoto',
})
export class ProfilePhotoPipe implements PipeTransform {
  transform(user: User): string {
    return user.profilePhoto ?? '/assets/images/person-circle.svg';
  }
}
