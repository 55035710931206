<image-cropper
  *ngIf="imageBase64"
  (imageCropped)="imageCropped($event)"
  [imageBase64]="imageBase64"
  [roundCropper]="true"
  [format]="format"
></image-cropper>

<ion-grid>
  <ion-row>
    <ion-col size="3" offset="6">
      <ion-button mode="ios" color="dark" expand="block" fill="clear" (click)="popoverGC.dismiss(null, 'cancel')">
        Cancel
      </ion-button>
    </ion-col>
    <ion-col size="3">
      <ion-button mode="ios" color="gc-green" expand="block" (click)="popoverGC.dismiss(pendingBase64, 'ok')">
        Save
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
