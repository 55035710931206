import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { exhaustMap, map, takeUntil } from 'rxjs/operators';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { EmptyKey } from '../../entity.service.utils';
import { selectedEmployeeInstallerProfile } from '../../users/users-ui.actions';
import { ProductInstallationType } from '../product-installation-types/product-installation-types.model';
import { productInstallTypeClaimedSelected } from '../training.actions';
import { loadAllCategoriesIfNecessary } from './category.state';


@Injectable()
export class CategoryEffects implements  OnRunEffects {
  constructor(private readonly actions$: Actions) {
  }

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallTypeClaimedSelected, selectedEmployeeInstallerProfile),
      map(() =>
        loadAllCategoriesIfNecessary({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(ProductInstallationType)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );
}
