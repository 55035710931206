import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { DetailedWorkOrder } from '../../../domains/work/models/detailed-work-order';

@Component({
  selector: 'gc-work-order-preview-popover',
  templateUrl: './work-order-preview-popover.component.html',
  styleUrls: ['./work-order-preview-popover.component.scss'],
})
export class WorkOrderPreviewPopoverComponent {
  @Input() workOrder!: DetailedWorkOrder;
  @Input() matchedInstallerCount!: number;
  @Input() simple = false;
  @Output() view = new EventEmitter<DetailedWorkOrder>();
  @Output() reviewInstallers = new EventEmitter<DetailedWorkOrder>();

  constructor(public popoverGC: PopoverController) {}
}
