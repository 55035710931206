import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IonItemSliding } from '@ionic/angular';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { PhoneNumber } from '../../../domains/phone-number';
import { User } from '../../../domains/users';

@Component({
  selector: 'gc-phone-number-manager',
  templateUrl: './phone-number-manager.component.html',
  styleUrls: ['./phone-number-manager.component.scss'],
})
export class PhoneNumberManagerComponent {
  @Input() validated = false;
  @Input() user?: User;
  @Input() phoneNumbers!: PhoneNumber[];
  @Input() form!: UntypedFormGroup;
  @Output() remove = new EventEmitter<PhoneNumber>();
  @Output() added = new EventEmitter<PhoneNumber>();

  submitted = false;
  /*
    mask = '(000) 000-0000';
  */

  readonly phoneMask: MaskitoOptions = {
    mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  };

  readonly maskPredicate: MaskitoElementPredicateAsync = async el => (el as HTMLIonInputElement).getInputElement();

  async toggleOpenState(sliding: IonItemSliding) {
    return (await sliding.getOpenAmount()) === 0 ? sliding.open('end') : sliding.close();
  }

  add() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const rawNumber: string = this.form.value.number.replace(/[()\- ]/g, '');

    const formatted = `${rawNumber.substr(0, 3)} ${rawNumber.substr(3, 3)} ${rawNumber.substr(6)}`;

    this.added.emit({ ...this.form.value, number: formatted, userId: this.user!.id! }); // TODO: Properly deal with assertion
    this.form.reset();
    this.submitted = false;
  }
}
