import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';

const routerState = createFeatureSelector<RouterReducerState>('router');

export const {
  selectUrl: currentRouteUrl,
  selectRouteParams: currentRouteParams,
  selectRouteParam: currentRouteParam,
} = getRouterSelectors(routerState);
