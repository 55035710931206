export const or =
  <T>(...conditions: Array<(v: T) => boolean>) =>
  (value: T): boolean =>
    conditions.reduce((result: boolean, condition) => result || condition(value), false);

export const and =
  <T>(...conditions: Array<(v: T) => boolean>) =>
  (value: T): boolean =>
    conditions.reduce((result: boolean, condition) => result && condition(value), true);

export const not =
  <T>(condition: (v: T) => boolean) =>
  (value: T): boolean =>
    !condition(value);

export const ifNullish =
  <T, R>(project?: (value: T) => R) =>
  (value: T): boolean =>
    (project ? project(value) : value) == null;

export const ifNonNullish =
  <T, R>(project?: (value: T) => R) =>
  (value: T): boolean =>
    (project ? project(value) : value) != null;

export const ifTruthy =
  <T, R>(project?: (value: T) => R) =>
  (value: T): boolean =>
    !!(project ? project(value) : value);

export const iif =
  <T, RT, RF>(condition: (value: T) => boolean, ifTrue: (value: T) => RT, ifFalse: (value: T) => RF) =>
  (value: T): RT | RF =>
    condition(value) ? ifTrue(value) : ifFalse(value);
