import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PushModule } from '@rx-angular/template/push';
import { EntityService } from '../../domains/entity.service';
import { DwollaAccountStatusAlertComponent } from './dwolla-account-status-alert/dwolla-account-status-alert.component';
import { DwollaAddFundingSourceComponent } from './dwolla-add-funding-source/dwolla-add-funding-source.component';
import { DwollaBalanceAlertComponent } from './dwolla-balance-alert/dwolla-balance-alert.component';
import { DwollaCustomerCreateComponent } from './dwolla-customer-create/dwolla-customer-create.component';
import { DwollaFacade } from './dwolla.facade';
import { DwollaAccount } from './feature/dwolla-account/dwolla-account.entity';
import { DWOLLA_ACCOUNTS_FEATURE_KEY, dwollaAccountsReducer } from './feature/dwolla-account/dwolla-accounts.state';
import { DwollaEffects } from './feature/dwolla-account/dwolla.effects';
import { DwollaService } from './feature/dwolla-account/dwolla.service';
import { PLAID_ACCOUNT_FEATURE_KEY, plaidAccountReducer } from './feature/plaid/plaid-account.state';
import { PlaidEffects } from './feature/plaid/plaid.effects';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    IonicModule,
    StoreModule.forFeature(DWOLLA_ACCOUNTS_FEATURE_KEY, dwollaAccountsReducer),
    StoreModule.forFeature(PLAID_ACCOUNT_FEATURE_KEY, plaidAccountReducer),
    EffectsModule.forFeature([DwollaEffects, PlaidEffects]),
    PushModule,
  ],
  declarations: [
    DwollaCustomerCreateComponent,
    DwollaAddFundingSourceComponent,
    DwollaAccountStatusAlertComponent,
    DwollaBalanceAlertComponent,
  ],
  exports: [
    DwollaCustomerCreateComponent,
    DwollaAddFundingSourceComponent,
    DwollaAccountStatusAlertComponent,
    DwollaBalanceAlertComponent,
  ],
})
export class DwollaModule {
  static forRoot(): ModuleWithProviders<DwollaModule> {
    return {
      ngModule: DwollaModule,
      providers: [DwollaService, { provide: DwollaAccount, useClass: EntityService }, DwollaFacade],
    };
  }
}
