<form [formGroup]="form">
  <ion-item lines="full">
    <ion-input
      data-e2e="basic-first-name-input"
      formControlName="firstName"
      labelPlacement="floating"
      clearInput
      data-cy="installer-editor-first-name-input"
    >
      <div slot="label">
        <ion-text [color]="validated && form.firstName.invalid ? 'danger' : undefined"> First Name</ion-text>
      </div>
    </ion-input>
  </ion-item>
  <ion-item lines="full">
    <ion-input
      data-e2e="basic-last-name-input"
      formControlName="lastName"
      labelPlacement="floating"
      clearInput
      data-cy="installer-editor-last-name-input"
    >
      <div slot="label">
        <ion-text [color]="validated && form.lastName.invalid ? 'danger' : undefined"> Last Name</ion-text>
      </div>
    </ion-input>
  </ion-item>
  <ion-item lines="full">
    <ion-input
      data-e2e="basic-last-name-input"
      formControlName="email"
      labelPlacement="floating"
      [readonly]="true"
      data-cy="installer-editor-email-input"
    >
      <div slot="label">
        <ion-text [color]="validated && form.email.invalid ? 'danger' : undefined"> Email</ion-text>
      </div>
    </ion-input>
  </ion-item>
</form>
