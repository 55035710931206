import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faCircleHalf } from '@fortawesome/pro-duotone-svg-icons/faCircleHalf';
import { faCircleQuarter } from '@fortawesome/pro-duotone-svg-icons/faCircleQuarter';
import { faCircleThreeQuarters } from '@fortawesome/pro-duotone-svg-icons/faCircleThreeQuarters';
import { createSelector } from '@ngrx/store';
import { add, differenceInHours } from 'date-fns';
import { compose } from '../shared/utils/compose.util';
import { ifNonNullish } from '../shared/utils/func';
import { and } from '../shared/utils/utils';
import { ChangeOrder, ChangeOrderStatus } from './change-orders/change-order/change-order.model';
import { allChangeOrders } from './change-orders/change-order/change-order.state';
import { DetailedChangeOrder, IconDef } from './change-orders/models/detailed-change-order.model';
import { DetailedInstaller } from './models/detailed-installer';
import { DetailedWorkOrderItem } from './models/detailed-work-order-item';
import { WorkOrderNegotiationItem } from './negotiations/models/work-order-negotiation-item.model';
import { NegotiationItem } from './negotiations/negotiation-item/negotiation-item.model';
import { allNegotiationItems } from './negotiations/negotiation-item/negotiation-item.state';
import { whereMatchesInstallers, whereMatchesWorkOrder, whereMatchesWorkOrderItem } from './utils';
import { toInstallerNegotiationItem } from './work-order-item-negotiation.selectors';
import { currentOrAllNegotiatingInstallersForCurrentWorkOrder } from './work-order-users.selectors';
import { DetailedWorkOrder } from './work/models/detailed-work-order';
import { WorkOrderItem } from './work/work-order-item/work-order-item.model';
import { sortedWorkOrderItems } from './work/work-order-item/work-order-item.state';
import { WorkOrder } from './work/work-order/work-order.model';
import { detailedWorkOrder } from './work/work-order/work-order.selectors';
import { currentWorkOrder } from './work/work-order/work-order.state';

export const deductChangeOrderAdjustments = (total: number, changeOrder: ChangeOrder) =>
  total - changeOrder.adjustedPricePerUnit * changeOrder.adjustedNumberOfUnits;

interface CanBeVoidedBy {
  voidedById?: string;
}

export const isNotVoided = () => (canBe: CanBeVoidedBy): boolean => !canBe.voidedById;

export const isNotInvoiced = () => (changeOrder: ChangeOrder): boolean =>
  changeOrder.status !== ChangeOrderStatus.INVOICED && changeOrder.status !== ChangeOrderStatus.PAID;

export const currentWorkOrderItems = createSelector(
  sortedWorkOrderItems,
  currentWorkOrder,
  (items: WorkOrderItem[], workOrder?: WorkOrder): WorkOrderItem[] =>
    workOrder ? items?.filter(and(whereMatchesWorkOrder(workOrder), isNotVoided())) ?? [] : [],
);

export const currentNegotiationItems = createSelector(
  allNegotiationItems,
  currentWorkOrder,
  (negotiations: NegotiationItem[], workOrder?: WorkOrder): NegotiationItem[] =>
    workOrder ? negotiations?.filter(whereMatchesWorkOrder(workOrder)) ?? [] : [],
);

export const currentChangeOrders = createSelector(
  allChangeOrders,
  currentWorkOrder,
  (changeOrders: ChangeOrder[], workOrder?: WorkOrder): ChangeOrder[] =>
    workOrder ? changeOrders?.filter(and(whereMatchesWorkOrder(workOrder), isNotVoided())) ?? [] : [],
);

export const currentUninvoicedChangeOrders = createSelector(
  currentChangeOrders,
  (changeOrders: ChangeOrder[]): ChangeOrder[] => changeOrders?.filter(isNotInvoiced()) ?? [],
);

// export const currentNegotiableWorkOrderItems = createSelector(
//   currentWorkOrderItems,
//   currentNegotiationItems,
//   currentOrAllNegotiatingInstallersForCurrentWorkOrder,
//   joinWorkOrderItemsAndNegotiations
// );

export const whereNegotiationApplies = (workOrderItem: WorkOrderItem) => (negotiation: WorkOrderNegotiationItem) =>
  workOrderItem && negotiation
    ? !(negotiation.isAcceptedByInstaller && negotiation.currentAskPrice === workOrderItem.pricePerUnit)
    : false;

export const joinInstallersToNegotationsForWorkOrderItem = (
  workOrderItem: WorkOrderItem,
  negotiationItems: NegotiationItem[],
  installers: DetailedInstaller[],
) =>
  negotiationItems
    .filter(
      and(
        whereMatchesWorkOrderItem(workOrderItem),
        whereMatchesInstallers(installers),
        whereNegotiationApplies(workOrderItem),
      ),
    )
    .map(toInstallerNegotiationItem(installers));

export const determineIfHasAcceptedDeductions = (changeOrders: ChangeOrder[]) =>
  changeOrders.some(ifNonNullish(value => value.acceptedById));

export const sumChangeOrderAdjustments = (sum: number, changeOrder: ChangeOrder): number =>
  sum + +(changeOrder.adjustedPricePerUnit * changeOrder.adjustedNumberOfUnits).toFixed(2);

export const calculateAcceptedChangeOrderTotal = (item: WorkOrderItem, changeOrders: ChangeOrder[]) =>
  changeOrders.filter(ifNonNullish(value => value.acceptedById)).reduce(sumChangeOrderAdjustments, 0);

export const calculateChangeOrderTotal = (item: WorkOrderItem, changeOrders: ChangeOrder[]) =>
  changeOrders.reduce(sumChangeOrderAdjustments, 0);

export const calculateInvoicedTotal = (item: WorkOrderItem) =>
  +(item.pricePerUnit * (item.numberOfUnits - item.numberOfUnitsAlreadyInvoiced)).toFixed(2);

const AUTO_ACCEPT_PERIOD = 7;

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max);
export const attachAutoAcceptHoursRemaining = (changeOrder: DetailedChangeOrder): DetailedChangeOrder => (
  (changeOrder.autoAcceptHoursRemaining = clamp(
    differenceInHours(add(new Date(changeOrder.createdOn), { days: AUTO_ACCEPT_PERIOD }), new Date()),
    0,
    AUTO_ACCEPT_PERIOD * 24,
  )),
  changeOrder
);

export const attachAutoAcceptDaysRemaining = (changeOrder: DetailedChangeOrder): DetailedChangeOrder => (
  (changeOrder.autoAcceptDaysRemaining = Math.round(changeOrder.autoAcceptHoursRemaining / 24)), changeOrder
);

export const attachAutoAcceptedPercentage = (changeOrder: DetailedChangeOrder): DetailedChangeOrder => (
  (changeOrder.autoAcceptPercentage = changeOrder.autoAcceptHoursRemaining / (AUTO_ACCEPT_PERIOD * 24)), changeOrder
);

export const AUTO_ACCEPT_PIE_PIECE: Record<string, IconDef> = {
  '1.00': { icon: faCircle, type: 's' },
  '0.85': { icon: faCircleThreeQuarters, type: 'dt' },
  '0.60': { icon: faCircleHalf, type: 'dt' },
  '0.35': { icon: faCircleQuarter, type: 'dt' },
  '0.01': { icon: faCircleXmark, type: 'dt' },
  '0.00': { icon: faCircleXmark, type: 'dt' },
};

export const attachAutoAcceptPiePiece = (changeOrder: DetailedChangeOrder): DetailedChangeOrder => (
  (changeOrder.autoAcceptPiePiece = Object.keys(AUTO_ACCEPT_PIE_PIECE)
    .sort((a, b) => b.localeCompare(a, undefined, { numeric: true }))
    .reduce(
      (icon: IconDef, key: string) => (changeOrder.autoAcceptPercentage < +key ? AUTO_ACCEPT_PIE_PIECE[key] : icon),
      AUTO_ACCEPT_PIE_PIECE['1.00'],
    )),
  changeOrder
);
export const makeDetailedChangeOrder = (changeOrder: ChangeOrder): DetailedChangeOrder =>
  compose(
    attachAutoAcceptHoursRemaining,
    attachAutoAcceptDaysRemaining,
    attachAutoAcceptedPercentage,
    attachAutoAcceptPiePiece,
  )({ ...changeOrder } as DetailedChangeOrder);

// TODO: Make this more efficient by computing some child structures with additional selectors for memoization
export const makeDetailedWorkOrderItem = (
  workOrder: WorkOrder,
  item: WorkOrderItem,
  negotiations: NegotiationItem[],
  changeOrders: ChangeOrder[],
  uninvoicedChangeOrders: ChangeOrder[],
  installers: DetailedInstaller[],
): DetailedWorkOrderItem => ({
  ...item,
  workOrder,
  negotiations: joinInstallersToNegotationsForWorkOrderItem(item, negotiations, installers),
  changeOrders: changeOrders.map(makeDetailedChangeOrder),
  uninvoicedChangeOrders: uninvoicedChangeOrders.map(makeDetailedChangeOrder),
  changeInfo: {
    hasAcceptedDeductions: determineIfHasAcceptedDeductions(changeOrders),
    acceptedChangedTotal: calculateAcceptedChangeOrderTotal(item, changeOrders),
    changedTotal: calculateChangeOrderTotal(item, changeOrders),
    amountToInvoice: calculateInvoicedTotal(item) - calculateAcceptedChangeOrderTotal(item, changeOrders),
    amountRemainingAfterAll: calculateInvoicedTotal(item) - calculateChangeOrderTotal(item, changeOrders),
  },
  uninvoicedChangeInfo: {
    hasAcceptedDeductions: determineIfHasAcceptedDeductions(changeOrders),
    acceptedChangedTotal: calculateAcceptedChangeOrderTotal(item, uninvoicedChangeOrders),
    changedTotal: calculateChangeOrderTotal(item, uninvoicedChangeOrders),
    amountToInvoice: calculateInvoicedTotal(item) - calculateAcceptedChangeOrderTotal(item, uninvoicedChangeOrders),
    amountRemainingAfterAll: calculateInvoicedTotal(item) - calculateChangeOrderTotal(item, uninvoicedChangeOrders),
  },
  invoiceInfo: {
    hasBeenInvoiced: item.numberOfUnitsAlreadyInvoiced > 0,
    originalTotal: +(item.pricePerUnit * item.numberOfUnits).toFixed(2),
    amountToInvoice: calculateInvoicedTotal(item),
    remaining: item.numberOfUnits - item.numberOfUnitsAlreadyInvoiced,
  },
});

export const makeDetailedWorkOrderItems = (
  items: WorkOrderItem[],
  negotiations: NegotiationItem[],
  changeOrders: ChangeOrder[],
  uninvoicedChangeOrders: ChangeOrder[],
  installers: DetailedInstaller[],
  workOrder?: DetailedWorkOrder,
): DetailedWorkOrderItem[] | undefined =>
  workOrder && items.length
    ? items.map(item =>
        makeDetailedWorkOrderItem(
          workOrder,
          item,
          negotiations.filter(negotiations => negotiations.workOrderItemId === item.id),
          changeOrders.filter(changeOrder => changeOrder.workOrderItemId === item.id),
          uninvoicedChangeOrders.filter(changeOrder => changeOrder.workOrderItemId === item.id),
          installers,
        ),
      )
    : undefined;

export const currentDetailedWorkOrderItems = createSelector(
  currentWorkOrderItems,
  currentNegotiationItems,
  currentChangeOrders,
  currentUninvoicedChangeOrders,
  currentOrAllNegotiatingInstallersForCurrentWorkOrder,
  detailedWorkOrder,
  makeDetailedWorkOrderItems,
);
