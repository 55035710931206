import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { EmployeeInstaller, Manager } from '../../../domains/users';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-select-employee-popover',
  templateUrl: './select-employee-popover.component.html',
  styleUrls: ['./select-employee-popover.component.scss']
})
export class SelectEmployeePopoverComponent {
  employees!: EmployeeInstaller[];
  workOrder!: WorkOrder;

  constructor(public popoverGC: PopoverController) {}
}
