import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { ROUTER_NAVIGATED, routerNavigatedAction } from '@ngrx/router-store';
import { merge, Observable } from 'rxjs';
import { exhaustMap, map, takeUntil } from 'rxjs/operators';
import { editMyBasicProfileSelected } from '../../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.actions';
import { routeIncludesPath, routeStartsWithPath } from '../../shared/utils/ngrx';
import { editCustomer, showCreateCustomer } from '../ad-hoc-invoices/ad-hoc-invoices.actions';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { editUserProfile, loadUserProfile } from '../users/profile/profile.actions';
import { selectedEmployeeInstallerProfile, showJumpstarterProfile, showProjectManagerProfile } from '../users/users-ui.actions';
import { workOrderMapRouteVisit } from '../work/work-order-map/work-order-map.state';
import { viewWorkOrder } from '../work/work-order-ui.actions';
import { selectDetailArea } from '../work/work.actions';
import { loadAllAddressesIfNecessary } from './address/address.state';
import { Country } from './country/country.model';
import { loadAllFacilityTypesIfNecessary } from './facility-type/facility-type.state';
import { loadAllStatesIfNecessary } from './state/state.state';

@Injectable()
export class LocationsEffects implements OnRunEffects {
  constructor(private readonly actions$: Actions) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectedEmployeeInstallerProfile, showProjectManagerProfile, showJumpstarterProfile, loadUserProfile),
      map(() => loadAllAddressesIfNecessary({})),
    ),
  );

  loadFacilityTypes$ = createEffect(() =>
    merge(
      this.actions$.pipe(ofType(ROUTER_NAVIGATED), routeStartsWithPath('/app/')),
      this.actions$.pipe(ofType(claimsAndTokenRetrieved, selectDetailArea, viewWorkOrder, workOrderMapRouteVisit)),
    ).pipe(map(() => loadAllFacilityTypesIfNecessary())),
  );

  loadStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        editUserProfile,
        editMyBasicProfileSelected,
        selectedEmployeeInstallerProfile,
        showJumpstarterProfile,
        showProjectManagerProfile,
        showCreateCustomer,
        editCustomer,
      ),
      map(() =>
        loadAllStatesIfNecessary({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(Country)]: 'USA',
            },
          },
        }),
      ),
    ),
  );

  loadStatesOnNavigation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      routeIncludesPath('team/members'),
      map(() =>
        loadAllStatesIfNecessary({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(Country)]: 'USA',
            },
          },
        }),
      ),
    ),
  );
}
