import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { PublishToConnectionsModalComponent } from '~gc/modals/add-edit-work-order/publish-to-connections-modal/publish-to-connections-modal.component';
import { PublishToEmployeesModalComponent } from '../../../modals/add-edit-work-order/publish-to-employees-modal/publish-to-employees-modal.component';
import { hasAnyPermission, hasPermissions } from '../../../shared/pipes/has-permissions.pipe';
import { EmployeeInstaller, Installer, InstallerTeamLead } from '../../users';
import { WorkOrder } from '../work-order/work-order.model';

export enum PublishTo {
  employees = 'employees',
  connections = 'connections',
  publicNetwork = 'public',
  cancel = 'cancel',
}

@Injectable()
export class WorkOrderPublishUIService {
  constructor(
    private alerts: AlertController,
    private modals: ModalController,
    private actionSheets: ActionSheetController,
  ) {}

  async showPublishToConnectionsModal(
    workOrder: Partial<WorkOrder>,
    installers: InstallerTeamLead[],
    prefilled?: InstallerTeamLead[],
  ): Promise<{ role?: string; installers: string[] }> {
    const modal = await this.modals.create({
      component: PublishToConnectionsModalComponent,
      cssClass: 'rounded-modal',
      componentProps: {
        workOrder,
        installers,
        prefilled,
      },
      presentingElement: await this.modals.getTop(),
    });

    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return { role, installers: data as string[] };
  }

  async showPublishToEmployeeModal(
    installers: EmployeeInstaller[],
  ): Promise<{ role?: string; installer: EmployeeInstaller['id'][] }> {
    const modal = await this.modals.create({
      component: PublishToEmployeesModalComponent,
      cssClass: 'rounded-modal',
      componentProps: {
        installers,
      },
    });

    await modal.present();
    const { role, data } = await modal.onDidDismiss();
    return { role, installer: data as EmployeeInstaller['id'][] };
  }

  async notifyPublishCompleted() {
    const alert = await this.alerts.create({
      backdropDismiss: false,
      header: 'Work Order Published',
      message: 'Once Installer(s) have responded, you can review each of them in Pending Work Orders.',
      buttons: [{ text: 'Ok', role: 'ok' }],
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async notifyRepublishCompleted() {
    const alert = await this.alerts.create({
      backdropDismiss: false,
      header: 'Work Order Republished',
      message: 'The Work Order has been republished.',
      buttons: [{ text: 'Ok', role: 'ok' }],
    });

    await alert.present();
    await alert.onDidDismiss();
  }

  async publish(permissions: string[]): Promise<OverlayEventDetail<any>> {
    const actionSheet = await this.actionSheets.create({
      backdropDismiss: false,
      header: 'How should we publish this Work Order?',
      buttons: [
        {
          text: 'Select Employee Installer',
          role: PublishTo.employees,
        },
        ...(hasAnyPermission(permissions, 'work-order.private:publish', 'work-order.public:publish')
          ? [
              {
                text: 'Choose Connection(s)',
                role: PublishTo.connections,
              },
            ]
          : []),
        ...(hasPermissions(permissions, 'work-order.public:publish')
          ? [
              {
                text: 'Go Carrera Public Network',
                role: PublishTo.publicNetwork,
              },
            ]
          : []),
        {
          text: 'Cancel',
          role: PublishTo.cancel,
          cssClass: 'button-text-red',
        },
      ],
    });

    await actionSheet.present();
    return await actionSheet.onDidDismiss();
  }

  async confirm(header: string, message: string): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header,
      message,
      buttons: [
        {
          text: 'Yes',
          role: 'ok',
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async confirmAssumedInstaller(installer: Installer): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header: 'Publish to Installer',
      message: `Do you wish to publish this Work Order to ${installer.firstName} ${installer.lastName} only? Or would you like to select more installers from your <b>Network</b>`,
      buttons: [
        {
          text: `Publish to ${installer.firstName}`,
          role: 'ok',
        },
        {
          text: 'Select More',
          role: 'select',
        },
        {
          text: 'Publish Another Way...',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  confirmAssumedEmployee(installer: Installer): Promise<string | undefined> {
    return this.confirm(
      'Award to Installer',
      `Do you wish to award this Work Order to ${installer.firstName} ${installer.lastName}?`,
    );
  }
}
