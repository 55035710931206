import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthFacade } from '~gc/domains/app/auth/auth.facade';
import { NegotiationsFacade } from '~gc/domains/negotiations/negotiations.facade';
import { WorkOrderRelationsFacade } from '~gc/domains/work-order-relations.facade';
import { InstallerReviewFacade } from '~gc/domains/work/installer-review/installer-review.facade';
import { MatchedInstallersFacade } from '~gc/domains/work/matched-installer/matched-installers.facade';
import { ChangeOrdersFacade } from '../../../domains/change-orders/change-orders.facade';
import { reportsProgressForCurrentWorkOrder } from '../../../domains/invoices/invoice-ui.selectors';
import { NegotiationItemFacade } from '../../../domains/negotiations/negotiation-item/negotiation-item.facade';
import { WorkOrderItemFacade } from '../../../domains/work/work-order-item/work-order-item.facade';
import { WorkOrderFacade } from '../../../domains/work/work-order/work-order.facade';
import { WorkFacade } from '../../../domains/work/work.facade';
import { areLineItemsPending } from '../store/ui.selectors';

@Component({
  selector: 'gc-work-order-items-detail',
  templateUrl: './work-order-items-detail.component.html',
  styleUrls: ['./work-order-items-detail.component.scss'],
})
export class WorkOrderItemsDetailComponent {
  isPending$ = this.store.select(areLineItemsPending);
  reportsProgress$ = this.store.select(reportsProgressForCurrentWorkOrder);
  expandedItem = null;

  constructor(
    public auth: AuthFacade,
    public work: WorkFacade,
    public workOrders: WorkOrderFacade,
    public workOrderItems: WorkOrderItemFacade,
    public workOrderRelations: WorkOrderRelationsFacade,
    public installerReview: InstallerReviewFacade,
    public matchedInstallers: MatchedInstallersFacade,
    public negotiationsFacade: NegotiationsFacade,
    public negotiationItemsFacade: NegotiationItemFacade,
    public changeOrders: ChangeOrdersFacade,
    private readonly store: Store,
  ) {}
}
