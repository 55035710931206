import { Component, Input } from '@angular/core';
import { State } from '../../../domains/locations';
import { InstallerProfileForm } from '../installer-profile.form';

@Component({
  selector: 'gc-installer-profile-part-one',
  templateUrl: './installer-profile-part-one.component.html',
  styleUrls: [
    './installer-profile-part-one.component.scss',
    '../installer-profile-modal/installer-profile-modal.component.scss',
  ],
})
export class InstallerProfilePartOneComponent {
  @Input() validated = false;
  @Input() form!: InstallerProfileForm;
  @Input() states!: State[];
}
