import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EntityService } from '../entity.service';
import { CategoryEffects } from './category/category.effects';
import { CategoryFacade } from './category/category.facade';
import { Category } from './category/category.model';
import { ProductInstallationSubtypesClaimed } from './product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import { ProductInstallationSubtype } from './product-installation-subtypes/product-installation-subtypes.model';
import { ProductInstallationTypesClaimedEffects } from './product-installation-types-claimed/product-installation-types-claimed.effects';
import { ProductInstallationTypeClaimedFacade } from './product-installation-types-claimed/product-installation-types-claimed.facade';
import { ProductInstallationTypeClaimed } from './product-installation-types-claimed/product-installation-types-claimed.model';
import { ProductInstallationTypesEffects } from './product-installation-types/product-installation-types.effects';
import { ProductInstallationTypeFacade } from './product-installation-types/product-installation-types.facade';
import { ProductInstallationType } from './product-installation-types/product-installation-types.model';
import { SkillsAndExperienceFacade } from './skills-and-experience.facade';
import { TrainingFile } from './training-files/training-file.model';
import { TrainingEffects } from './training.effects';
import { TrainingFacade } from './training/training.facade';
import { Training } from './training/training.model';
import { TrainingClaimedFacade } from './trainings-claimed/trainings-claimed.facade';
import { TrainingClaimed } from './trainings-claimed/trainings-claimed.model';
import { TrainingsUIService } from './trainings-ui.service';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([TrainingEffects, CategoryEffects, ProductInstallationTypesEffects, ProductInstallationTypesClaimedEffects])],
  providers: [
    CategoryFacade,
    ProductInstallationTypeFacade,
    ProductInstallationTypeClaimedFacade,
    TrainingFacade,
    TrainingClaimedFacade,
    SkillsAndExperienceFacade,
    TrainingsUIService,
    { provide: Category, useClass: EntityService },
    { provide: ProductInstallationType, useClass: EntityService },
    { provide: ProductInstallationTypeClaimed, useClass: EntityService },
    { provide: ProductInstallationSubtype, useClass: EntityService },
    { provide: ProductInstallationSubtypesClaimed, useClass: EntityService },
    { provide: Training, useClass: EntityService },
    { provide: TrainingClaimed, useClass: EntityService },
    { provide: TrainingFile, useClass: EntityService },
  ],
})
export class TrainingModule {}
