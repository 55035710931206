<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <strong>Review Installers</strong>
    </ion-title>
  </ion-toolbar>
</ion-header>

<gc-content name="review-installerS-modal">
  <gc-installer-card
    *ngFor="let installer of workOrderRelations.currentWorkOrderMatchedInterestedNonPendingInstallerDetails$ | push"
    [installer]="installer"
    (viewed)="installerReview.reviewInstaller($event)"
  >
  </gc-installer-card>
</gc-content>
