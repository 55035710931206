import { Pipe, PipeTransform } from '@angular/core';
import { NegotiationMode } from '~gc/modals/bid-ask-negotiation/negotiation-mode';

@Pipe({ name: 'negotiatedPriceColor' })
export class NegotiatedPriceColorPipe implements PipeTransform {
  transform(mode: NegotiationMode, yourPrice: number, opposingPrice: number, shouldApply: boolean): unknown {
    return !shouldApply
      ? 'medium'
      : mode === NegotiationMode.Company
      ? yourPrice > opposingPrice
        ? 'danger'
        : yourPrice < opposingPrice
        ? 'gc-green'
        : 'warning'
      : yourPrice > opposingPrice
      ? 'gc-green'
      : yourPrice < opposingPrice
      ? 'danger'
      : 'warning';
  }
}
