import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';

import { trainingClaimed } from '../../core/installer-profile';
import { Training } from '../../domains/training/training/training.model';
import { ensureChildrenRequired } from '../../shared/utils/rxjs';
import { claimDateChosen } from './claim-training-modal.actions';
import { trainingBeingClaimed } from './store/ui.selectors';

@Injectable()
export class ClaimTrainingModalEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  beginClaimTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(claimDateChosen),
      withLatestFrom(this.store.select(trainingBeingClaimed)),
      map(([{ date }, training]) => ({ date, training })),
      ensureChildrenRequired<{ date: string; training?: Training }, { date: string; training: Training }>(
        value => !!value?.training && !!value.date,
      ),
      map(({ date, training }) => trainingClaimed({ training, date })),
    ),
  );
}
