<ion-toolbar *ngIf="form" mode="md">
  <ion-button
    color="dark"
    *ngIf="form.workOrderStatus | editMode: 'full' : 'partial'"
    fill="clear"
    (click)="save.emit()"
    [disabled]="isSaving || isStepInitializing || !form.isDraftValid"
    mode="ios"
  >
    <ion-icon slot="start" name="save"></ion-icon>
    Save & Exit
  </ion-button>
  <ion-button
    *ngIf="step !== 'publishing' && step !== 'items'"
    (click)="next.emit()"
    [disabled]="isStepInitializing || !form.isDraftValid"
    fill="solid"
    color="dark"
    mode="ios"
  >
    Next
    <ion-icon slot="end" name="chevron-forward"></ion-icon>
  </ion-button>

  <!--Separate "Next" Button for Items Page-->
  <ion-button
    *ngIf="step === 'items'"
    data-cy="item-next-button"
    (click)="next.emit()"
    [disabled]="isStepInitializing || form.workOrderItems.invalid"
    fill="solid"
    color="dark"
    mode="ios"
  >
    Next
    <ion-icon slot="end" name="chevron-forward"></ion-icon>
  </ion-button>



  <ion-button
    *ngIf="step === 'publishing' && (form.workOrderStatus | editMode: 'full')"
    (click)="publish.emit()"
    [disabled]="isSaving  || isStepInitializing || form.pending"
    fill="solid"
    color="gc-green"
    mode="ios"
  >
    Publish Work Order
  </ion-button>
  <ion-button
    *ngIf="step === 'publishing' && (form.workOrderStatus | editMode: 'republish')"
    [disabled]="isSaving || form.pending"
    (click)="republish.emit()"
    fill="solid"
    color="dark"
    mode="ios"
  >
    Republish Work Order
    <ion-icon name="refresh-outline" slot="start"></ion-icon>
  </ion-button>
</ion-toolbar>
