import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  createCustomDate,
  deleteCustomDate,
  updateCustomDate,
} from '../../domains/custom-dates/custom-date/custom-date.store';
import { WorkOrderStatus } from '../../domains/work/work-order/work-order.model';
import { editedWorkOrder } from '../../domains/work/work-order/work-order.state';
import { LogService } from '../../shared/services/log.service';
import { editingWorkOrderDateDeleted, editingWorkOrderDateSaved } from './add-edit-work-order.actions';
import { WorkOrderDatesService } from './work-order-dates.service';

const TAGS = ['WorkOrder', 'Add/Edit', 'Dates'];

@Injectable()
export class WorkOrderDatesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly log: LogService,
    private readonly service: WorkOrderDatesService,
  ) {}

  addCustomDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editingWorkOrderDateSaved),
      filter(({ date }) => !date.id),
      tap(({ date }) => this.log.trace(TAGS, `Adding work order schedule dates: ${date}`)),
      withLatestFrom(this.store.select(editedWorkOrder)),
      filter(([, workOrder]) => workOrder!.status === WorkOrderStatus.Draft),
      map(([{ date }, workOrder]) => createCustomDate({ entity: { ...date, workOrderId: workOrder?.id } })),
    ),
  );

  saveCustomDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editingWorkOrderDateSaved),
      filter(({ date }) => !!date.id),
      tap(({ date }) => this.log.trace(TAGS, `Saving work order schedule dates: ${date}`)),
      withLatestFrom(this.store.select(editedWorkOrder)),
      filter(([, workOrder]) => workOrder!.status === WorkOrderStatus.Draft),
      map(([{ date }, workOrder]) => updateCustomDate({ entity: { ...date, workOrderId: workOrder?.id } })),
    ),
  );

  deleteDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editingWorkOrderDateDeleted),
      tap(({ date }) => this.log.trace(TAGS, `Saving work order schedule dates: ${date}`)),
      withLatestFrom(this.store.select(editedWorkOrder)),
      filter(([, workOrder]) => workOrder!.status === WorkOrderStatus.Draft),
      map(([{ date }]) => deleteCustomDate({ key: date.id! })),
    ),
  );

  warn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editingWorkOrderDateSaved, editingWorkOrderDateDeleted),
        withLatestFrom(this.store.select(editedWorkOrder)),
        filter(([, workOrder]) => workOrder!.status !== WorkOrderStatus.Draft),
        switchMap(() => this.service.warnNoScheduleEditing()),
      ),
    { dispatch: false },
  );
}
