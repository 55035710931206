import { Component, Input } from '@angular/core';
import { InstallerProfile } from '~gc/domains/users/models/installer-profile';

@Component({
  selector: 'gc-general-area',
  templateUrl: './general-area.component.html',
  styleUrls: ['./general-area.component.scss'],
})
export class GeneralAreaComponent {
  @Input() isLoading = false;
  @Input() installer?: InstallerProfile;
}
