import { createSelector } from '@ngrx/store';
import { whereMatchesWorkOrder } from '../../utils';
import { WorkOrder } from '../work-order/work-order.model';
import { currentWorkOrder } from '../work-order/work-order.state';
import { WorkOrderItem } from './work-order-item.model';
import { sortedWorkOrderItems } from './work-order-item.state';

export const filterWorkOrderItemsByWorkOrder = (items: WorkOrderItem[], workOrder?: WorkOrder) =>
  workOrder ? items.filter(whereMatchesWorkOrder(workOrder)) : [];

export const currentWorkOrdersItems = createSelector(
  sortedWorkOrderItems,
  currentWorkOrder,
  filterWorkOrderItemsByWorkOrder,
);
