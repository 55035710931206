<ng-container *ngIf="pendingInvoice">
  <ion-list *ngFor="let item of pendingInvoice.items; let lastItem = last">
    <ion-item [lines]="lastItem && !item?.applicableChangeOrders?.length ? 'full' : 'none'">
      <ion-label>
        <h2>
          <strong>{{ item.name }}</strong>
        </h2>
        <p>
          <span>
            {{ item.amountToInvoice + (item.applicableChangeOrders | totalNormalizedUnits: item.pricePerUnit) }}
            <span *ngIf="permissions | hasPermissions: 'work-order.item.price:view'"
              >@ {{ item.pricePerUnit | currency }} /
            </span>
          </span>
          <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
        </p>
      </ion-label>
      <ion-note
        *ngIf="permissions | hasPermissions: 'work-order.item.price:view'"
        class="ion-no-margin ion-text-right"
        slot="end"
      >
        <ion-text color="dark">
          <strong>{{
            item.pricePerUnit * item.amountToInvoice +
              (item.applicableChangeOrders | totalNormalizedPrice: item.pricePerUnit) | currency
          }}</strong>
        </ion-text>
      </ion-note>
    </ion-item>
    <ng-container *ngIf="role !== 'companyEmployee'">
      <ion-item
        *ngFor="let changeOrder of item?.applicableChangeOrders; let lastCO = last"
        [lines]="lastCO ? 'full' : 'none'"
      >
        <ion-label>
          <h2>
            <ion-text color="gc-blue"
              ><strong>{{ changeOrder.name }}</strong></ion-text
            >
          </h2>
          <p>
            <span> {{ changeOrder.adjustedNumberOfUnits }} @ {{ changeOrder.adjustedPricePerUnit | currency }} </span>
            / <span class="uom">{{ item.unitOfMeasureSymbol }}</span>
          </p>
        </ion-label>
        <ion-note class="ion-no-margin ion-text-right" slot="end">
          <ion-text color="gc-blue">
            -{{ changeOrder.adjustedPricePerUnit * changeOrder.adjustedNumberOfUnits | currency }}
          </ion-text>
        </ion-note>
      </ion-item>
    </ng-container>
  </ion-list>
  <ion-list *ngIf="permissions | hasPermissions: 'work-order.item.price:view'">
    <ion-item lines="none">
      <ion-label>
        <h5><strong>TOTAL</strong></h5>
      </ion-label>
      <ion-note class="ion-no-margin ion-text-right" slot="end">
        <ion-text color="dark"
          ><strong>{{ pendingInvoice.items | reviewingInvoiceTotal | currency }}</strong>
        </ion-text>
      </ion-note>
    </ion-item>
  </ion-list>
</ng-container>
