import { createAction, props } from '@ngrx/store';
import { InvitedUser } from '../../domains/users';
import { Connection, Source } from '../../domains/users/connection/connection.model';
import { UserQuery } from './models/user-query.model';

export const existingInstallerInvitedPublicly = createAction(
  '[CORE: Invites] (Existing Installer) Invited Publicly',
  props<{ user: UserQuery }>(),
);

export const existingInstallerInvitedPrivately = createAction(
  '[CORE: Invites] (Existing Installer) Invited Privately',
  props<{ user: UserQuery }>(),
);

export const existingInstallerInvitedSuccessfully = createAction(
  '[CORE: Invites] (Existing Installer) Invited Successfully',
  props<{ connection: Connection; source: Source }>(),
);

export const existingInstallerInviteFailed = createAction(
  '[CORE: Invites] (Existing Installer Invite) Failed',
  props<{ error: any }>(),
);

export const newInstallerInvitedPublicly = createAction(
  '[CORE: Invites] (New Installer) Invited Publicly',
  props<{ invite: InvitedUser }>(),
);

export const newInstallerInvitedPrivately = createAction(
  '[CORE: Invites] (New Installer) Invited Privately',
  props<{ invite: InvitedUser }>(),
);

export const newInstallerInvitedSuccessfully = createAction(
  '[CORE: Invites] (New Installer) Invited Successfully',
  props<{
    userId: string;
    source: Source;
  }>(),
);

export const newInstallerInviteFailed = createAction(
  '[CORE: Invites] (New Installer Invite) Failed',
  props<{ error: any }>(),
);

export const newInstallerConnectionCreating = createAction(
  '[CORE: Invites] (New Installer Connection) Creating',
  props<{ userId?: string; correlationId: string }>(),
);

export const installerInviteProcessCompleting = createAction(
  '[CORE: Invite] (Installer Invite Process) Completing',
  props<{ correlationId: string }>(),
);
