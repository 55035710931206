import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../../domains/users';
import { LynkService } from '../../services/lynk.service';

@Component({
  selector: 'gc-lynk-button',
  templateUrl: './lynk-button.component.html',
  styleUrls: ['./lynk-button.component.scss'],
})
export class LynkButtonComponent implements OnInit, OnDestroy {
  constructor(private lynkService: LynkService) {}

  @Input() currentUser?: User;
  @Input() colorMode!: string;

  isLynkLoading = false;
  private isLoadingSubscription!: Subscription;

  ngOnInit() {
    this.isLoadingSubscription = this.lynkService.isLoading$.subscribe(isLoading => {
      this.isLynkLoading = isLoading;
    });
  }

  ngOnDestroy(): void {
    this.isLoadingSubscription.unsubscribe();
  }

  startNewLynkSession() {
    this.currentUser ? this.lynkService.authenticateThenStartNewLynkSession(this.currentUser) : void 0;
  }
}
