import { Component } from '@angular/core';
import { IonPopover, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';
import { createDatePickerTime } from '../../shared/utils/utils';
import {
  claimProductInstallationTypeCancelled,
  claimProductInstallationTypeDateChosen,
  productInstallationTypeClaiming
} from './claim-product-installation-type-modal.actions';
import { claimProductInstallationTypeContext } from './claim-product-installation-type-modal.selectors';

@Component({
  selector: 'gc-claim-product-installation-type-modal',
  templateUrl: './claim-product-installation-type-modal.component.html',
  styleUrls: ['./claim-product-installation-type-modal.component.scss']
})
export class ClaimProductInstallationTypeModalComponent {

  context$ = this.store.select(claimProductInstallationTypeContext);

  today = createDatePickerTime();

  constructor(
    public readonly modalGC: ModalController,
    private readonly store: Store,
  ) {}

  async addProductInstallationType(popoverGC: IonPopover, date: CustomEvent) {
    await popoverGC.dismiss(undefined, 'ok');
    this.store.dispatch(claimProductInstallationTypeDateChosen({date: date.detail.value}));
  }

  adding(installType: ProductInstallationType): void {
    this.store.dispatch(productInstallationTypeClaiming({installType}));
  }

  cancelled(event: any): void {
    if (event.detail.role === 'backdrop') {
      this.store.dispatch(claimProductInstallationTypeCancelled());
    }
  }

}
