import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { UserQuery } from '../../../../core/invites/models/user-query.model';
import { InvitedUser, UserType } from '../../../../domains/users';
import { InviteForm } from '../../invite.form';
import { InviteView } from '../company-invite.selectors';

@Component({
  selector: 'gc-company-invite-footer',
  templateUrl: './company-invite-footer.component.html',
  styleUrls: ['./company-invite-footer.component.scss'],
})
export class CompanyInviteFooterComponent {
  @Input() view!: InviteView;
  @Input() type!: UserType;
  @Input() typeDisplay = '';
  @Input() form!: InviteForm;
  @Input() processing = false;

  @Output() submitted = new EventEmitter<{ invite: Partial<InvitedUser>; user: UserQuery | undefined }>();

  faCircleNotch = faCircleNotch;
}
