import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { Company } from '../../users/companies/company.model';
import { User } from '../../users/user/user.model';

@Entity({
  modelName: 'Contact',
  uriName: 'contacts',
})
export class Contact {
  @Key id?: number;
  workOrderId!: string;
  typeLabel!: string;
  company?: Company;
  user?: User;
}
