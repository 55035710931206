<ng-template #view>
  <ion-list>
    <ion-list-header>
      <ion-label color="medium">DOCUMENTS</ion-label>
    </ion-list-header>
    <ion-item *ngFor="let file of files">
      <ion-icon [name]="file.filename | fileIcon" slot="start" class="ion-margin-end"></ion-icon>
      <ion-label>
        <h3>{{ file.filename }}</h3>
      </ion-label>
      <ion-button slot="end" fill="clear" target="_system" [href]="file.url">
        View
        <ion-icon name="chevron-forward-outline" size="large" slot="end"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</ng-template>

<ng-container *ngIf="isLoading; else view">
  <ion-list>
    <ion-list-header>
      <ion-label color="medium">DOCUMENTS</ion-label>
    </ion-list-header>
    <ion-item>
      <ion-thumbnail>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
      <ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-label>
      <ion-button slot="end" fill="clear" target="_system" [disabled]="true">
        View
        <ion-icon name="chevron-forward-outline" size="large" slot="end"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-thumbnail>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
      <ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-label>
      <ion-button slot="end" fill="clear" target="_system" [disabled]="true">
        View
        <ion-icon name="chevron-forward-outline" size="large" slot="end"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</ng-container>
