import { Position } from '@capacitor/geolocation';
import { createAction, props } from '@ngrx/store';

export const appInitialize = createAction('[APP] Initialize');

export const viewedAnnouncement = createAction('[App] Viewed Announcement');

export const navigateRoot = createAction('[App] Root Navigated', props<{ url: string }>());

export const geoLocationUpdated = createAction('[App] Geo Location Updated', props<{ position: Position }>());
