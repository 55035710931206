import { Injectable } from '@angular/core';
import { getKeyFromModel, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { User } from '../../users';
import { loadUserProfile } from '../../users/profile/profile.actions';
import { skillsAndExperienceViewed } from '../../users/users-ui.actions';
import { workOrderMapRouteVisit } from '../../work/work-order-map/work-order-map.state';
import { workOrdersRouteVisit } from '../../work/work.actions';
import { loadAllProductInstallationTypesClaimedIfNecessary } from './product-installation-types-claimed.state';

@Injectable()
export class ProductInstallationTypesClaimedEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadProductInstallationTypesClaimed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(skillsAndExperienceViewed, loadUserProfile, workOrdersRouteVisit, workOrderMapRouteVisit),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) =>
        loadAllProductInstallationTypesClaimedIfNecessary({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: getKeyFromModel(User, user),
            },
          },
        }),
      ),
    ),
  );
}
