import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../state';
import { currentWorkOrderCompany } from '../../work-order-users.selectors';
import { Company } from './company.model';
import { CompaniesFacadeBase } from './company.state';

@Injectable({ providedIn: 'root' })
export class CompaniesFacade extends CompaniesFacadeBase {
  currentWorkOrderCompany$ = this.store.select(currentWorkOrderCompany);

  constructor(private store: Store<AppState>) {
    super(Company, store);
  }
}
