<ion-list>
  <ion-list-header class="ion-padding-horizontal flex flex-column align-items-start">
    <h1>{{ title }}</h1>
    <p *ngIf="!!subtitle">{{ subtitle }}</p>
  </ion-list-header>
  <ng-container *ngFor="let item of listOptions">
    <ion-item [attr.data-cy]="'menu-item-' + item.label" detail="false" button="true" (click)="select(item)">
      <fa-icon *ngIf="item.icon" [icon]="item.icon" style="margin-right: 5px"></fa-icon>
      <ion-label *ngIf="!multi" [color]="item.color">
        {{ item.label }}
      </ion-label>

      <ion-checkbox *ngIf="multi" labelPlacement="start" justify="space-between" (ionChange)="updateSelected(item)">
        {{ item.label }}
      </ion-checkbox>
    </ion-item>
  </ng-container>

  <ion-item *ngIf="listOptions.length === 0">No Actions Available.</ion-item>

  <ion-grid *ngIf="multi">
    <ion-row>
      <ion-col>
        <ion-button expand="block" color="medium" mode="ios" (click)="popoverGC.dismiss(null, 'cancel')">
          Cancel
        </ion-button>
      </ion-col>
      <ion-col>
        <ion-button
          expand="block"
          mode="ios"
          [disabled]="selected.length === 0"
          (click)="popoverGC.dismiss(selected, 'multi')"
        >
          {{ doneText }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-list>
