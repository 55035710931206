import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'LienRelease',
  uriName: 'lien-releases',
})
export class LienRelease {
  @Key id?: number;
  invoiceId!: number;
  companyId!: number;
  lienReleaseTemplateId!: number;
  name!: string;
}
