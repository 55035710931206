import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AnnouncementModalComponent } from './announcement-modal.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [AnnouncementModalComponent],
  exports: [AnnouncementModalComponent],
})
export class AnnouncementModalModule {}
