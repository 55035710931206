import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { InvitedUser, isInstallerLead, TeamFacade, User, UserType } from '~gc/domains/users';
import { AuthFacade } from '../../domains/app/auth/auth.facade';

@Component({
  selector: 'gc-invite-user',
  templateUrl: './invite-user.modal.html',
  styleUrls: ['./invite-user.modal.scss'],
})
export class InviteUserModalComponent implements OnInit, OnDestroy {
  @Input() userType!: UserType;
  inviteUserForm?: UntypedFormGroup;
  private onDestroy: Subject<void> = new Subject<void>();

  constructor(
    public auth: AuthFacade,
    public team: TeamFacade,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.inviteUserForm = this.fb.group({
      email: ['', Validators.email],
      firstName: [''],
      lastName: [''],
    });

    this.inviteUserForm
      ?.get('email')
      ?.valueChanges.pipe(
        filter(() => !!this.inviteUserForm?.get('email')?.valid),
        takeUntil(this.onDestroy),
      )
      .subscribe(val => this.team.inviteEmailUpdated(val));
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }

  submit(user?: User): void {
    if (!!this.inviteUserForm?.invalid) {
      return;
    }
    const invitedUser: InvitedUser = { ...this.inviteUserForm?.value, role: this.userType };

    user && isInstallerLead(user) ? this.team.submitUserInvite(invitedUser) : this.team.submitUserAdd(invitedUser);
  }
}
