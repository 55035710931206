import { Pipe, PipeTransform } from '@angular/core';
import { ChangeOrder } from '../../domains/change-orders/change-order/change-order.model';

export const totalNormalizedPrice = (changeOrders: ChangeOrder[], pricePerUnit: number) =>
  changeOrders.reduce(
    (sum, changeOrder) =>
      +(
        sum +
        Math.ceil(changeOrder.adjustedNumberOfUnits * +(changeOrder.adjustedPricePerUnit / pricePerUnit)) * pricePerUnit
      ).toFixed(2),
    0,
  );

@Pipe({
  name: 'totalNormalizedPrice',
})
export class TotalNormalizedPricePipe implements PipeTransform {
  transform(changeOrders: ChangeOrder[], pricePerUnit: number): number {
    return totalNormalizedPrice(changeOrders, pricePerUnit);
  }
}
