import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { from } from 'rxjs';
import { InviteUserModalComponent } from '~gc/modals/invite-user';
import { UserType } from '../user/user.model';

@Injectable()
export class TeamUIService {
  private inviteUserModal?: HTMLIonModalElement;

  constructor(
    private alerts: AlertController,
    private toasts: ToastController,
    private modals: ModalController,
    private loading: LoadingController,
  ) {}

  async showInviteUserModal(userType: UserType): Promise<void> {
    if (this.inviteUserModal) {
      return Promise.resolve(void 0);
    }

    this.inviteUserModal = await this.modals.create({
      component: InviteUserModalComponent,
      componentProps: { userType },
      backdropDismiss: true,
      cssClass: 'rounded-modal',
    });

    this.inviteUserModal.onDidDismiss().then(() => {
      this.inviteUserModal = undefined;
    });

    await this.inviteUserModal.present();
  }

  closeInviteUserModal(): void {
    this.inviteUserModal?.dismiss();
  }

  async confirmUserInvite(): Promise<string | undefined> {
    const confirmInviteAlert = await this.alerts.create({
      header: 'Invite User',
      message: 'We’ll send an email their way and let them know you’d like to connect.',
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        { text: 'Send', role: 'ok' },
      ],
      backdropDismiss: false,
    });

    await confirmInviteAlert.present();
    const { role } = await confirmInviteAlert.onDidDismiss();
    return role;
  }

  async showUserInviteFailed() {
    const confirmInviteAlert = await this.alerts.create({
      header: 'User Invite Failed',
      message: 'Try double checking the email address or try again later',
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        { text: 'Okay', role: 'ok' },
      ],
      backdropDismiss: false,
    });

    await confirmInviteAlert.present();
    const { role } = await confirmInviteAlert.onDidDismiss();
    return role;
  }

  async showProcessing() {
    const loader = await this.loading.create();
    return loader.present();
  }

  closeProcessing() {
    return from(this.loading.dismiss());
  }

  async toastResult(message: string, error = false) {
    const toast = await this.toasts.create({
      message,
      buttons: [
        {
          text: 'Dismiss',
          side: 'end',
        },
      ],
      color: error ? 'danger' : 'dark',
      duration: 5000,
      position: 'bottom',
    });
    await toast.present();
    return toast.onDidDismiss();
  }
}
