import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { EmptyKey } from '../../entity.service.utils';
import { WorkOrder } from '../../work/work-order/work-order.model';
import { viewLienRelease } from '../invoice-and-payment.actions';
import { Invoice } from '../invoice/invoice.model';
import { LienRelease } from '../lien-releases/lien-release.model';
import { loadAllLienReleaseFiles } from './lien-release-file.state';

@Injectable()
export class LienReleaseFileEffects {
  constructor(private readonly actions$: Actions) {}

  loadLienReleaseFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewLienRelease),
      map(({ invoice }) =>
        loadAllLienReleaseFiles({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: invoice.workOrderId,
              [uriNameOfEntityOrEmpty(Invoice)]: invoice.id,
              [uriNameOfEntityOrEmpty(LienRelease)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );
}
