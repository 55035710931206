import { createAction, props } from '@ngrx/store';
import { MatchedInstaller } from './matched-installer/matched-installer.model';
import { WorkOrder } from './work-order/work-order.model';

export const navigateToWorkOrderAddress = createAction(
  '[Work] Navigate To Work Order Address',
  props<{ workOrder: WorkOrder }>(),
);

export const viewWorkOrder = createAction('[Work] View work order', props<{ workOrder: WorkOrder }>());

export const viewWorkOrderFromModal = createAction(
  '[Work] View WorkOrder from Modal',
  props<{ workOrder: WorkOrder }>(),
);

export const viewWorkOrderModalClosed = createAction('[Work] View WorkOrder from Modal Closed');

export const declineWorkOrder = createAction(
  '[Work] Decline Work Order',
  props<{ matchedInstaller: MatchedInstaller; workOrder: WorkOrder }>(),
);

export const showUserProfile = createAction('[Work] Show user Profile', props<{ id: string }>());
