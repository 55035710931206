import { Injectable } from '@angular/core';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { Capacitor } from '@capacitor/core';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Store } from '@ngrx/store';
import { AddEditWorkOrderModalComponent } from '~gc/modals/add-edit-work-order';
import { ReviewInstallerModalComponent } from '~gc/shared/components/installer-review/review-installer-modal/review-installer-modal.component';
import { ReviewInstallersModalComponent } from '~gc/shared/components/installer-review/review-installers-modal/review-installers-modal.component';
import { labelMap, stepMap } from '../../modals/add-edit-work-order/field.maps';
import { ShareWithInstallersModalComponent } from '../../modals/share-with-installers-modal/share-with-installers-modal.component';
import { WorkOrderDetailPage } from '../../pages/work-order-detail/work-order-detail.page';
import { getEditMode } from '../../shared/pipes/edit-mode.pipe';
import { CustomListPopoverComponent } from '../../shared/popovers/custom-list-popover/custom-list-popover.component';
import { SelectEmployeePopoverComponent } from '../../shared/popovers/select-employee-popover/select-employee-popover.component';
import { SelectProjectManagerPopoverComponent } from '../../shared/popovers/select-project-manager-popover/select-project-manager-popover.component';
import { ListOption } from '../../shared/popovers/types/list-options';
import { DetailedInstaller } from '../models/detailed-installer';
import { AppState } from '../state';
import {
  EmployeeInstaller,
  Installer,
  isAdmin,
  isEmployeeInstaller,
  isInstallerLead,
  isManager,
  Manager,
  TeamUser,
  User,
} from '../users';
import { installerReviewCompleted, installersReviewCompleted } from './installer-review/installer-review.actions';
import {
  closeWorkOrder,
  deletePendingWorkOrder,
  duplicateAsDraft,
  editCurrentWorkOrder,
  showSelectEmployee,
  showSelectProjectManager,
} from './work-order-edit/work-order-edit.actions';
import { shareWorkOrder } from './work-order/work-order.actions';
import { WorkOrder, WorkOrderStatus } from './work-order/work-order.model';
import { isStatus } from './work.maps';

// -----===[ SUPPORT SERVICES ]===-----
// While services in "classic" angular applications are most often used
// to encapsulate API-call functionality, services may be used for many
// purposes. With Ionic 4 and up, UI services are a great way to
// encapsulate common patterns employed when using any of the
// Ionic "Creator-Controller" type services, such as ModalController,
// AlertController, etc. Each of these types of services operates
// in a standard way. UI services are a great place to
// encapsulate such functionality. This allows us to simplify
// effects that may need to display a modal, or alert, etc.

@Injectable()
export class WorkUIService {
  private workOrderDetailModule?: any;
  private currentReviewInstallerModal?: HTMLIonModalElement;
  private currentReviewInstallersModal?: HTMLIonModalElement;

  constructor(
    private alerts: AlertController,
    private modals: ModalController,
    private actionSheets: ActionSheetController,
    public toastController: ToastController,
    private popovers: PopoverController,
    private store: Store<AppState>,
    private navigator: LaunchNavigator,
  ) {}

  // To Effect
  async toastResult(message: string, success = true) {
    const toast = await this.toastController.create({
      message,
      buttons: [
        {
          text: 'Dismiss',
          side: 'end',
        },
      ],
      color: success ? 'dark' : 'danger',
      duration: 5000,
      position: 'bottom',
    });
    await toast.present();
    const { role } = await toast.onDidDismiss();
    return role;
  }

  // TODO: Have no idea what's going on here...it relies on three maps, only one of which is provided...
  // TODO: Where are the otehr two maps? Did they ever exist? Is this code even really used anymore???
  async warnWorkOrderInvalid(errorMap: any): Promise<string> {
    const alert: HTMLIonAlertElement = await this.alerts.create({
      header: 'Form Invalid',
      message:
        'Your Work Order is incomplete or some fields are failing validation. Review and correct before publishing.',
      inputs: (Object.keys(errorMap) as unknown as string[]).map(field => ({
        name: field,
        type: 'radio',
        label: labelMap[field],
        value: field,
        handler: () => alert.dismiss(field),
      })),
    });

    await alert.present();
    const { data } = await alert.onDidDismiss();
    return stepMap[data];
  }

  // TODO: Reevaluate this entire approach, of lazy-loading a PAGE (!!!) and showing it as a MODAL (!!!)!!!
  async showViewWorkOrderModal() {
    if (!this.workOrderDetailModule) {
      // WorkOrderDetailPage is lazy loaded, so the first time it's presented, the module needs imported.
      this.workOrderDetailModule = await import('../../pages/work-order-detail/work-order-detail.module').then(
        m => m.WorkOrderDetailModule,
      );
    }

    const modalGC = await this.modals.create({
      component: WorkOrderDetailPage,
      cssClass: 'fullscreen-modal rounded-modal',
      // backdropDismiss: false,
      componentProps: {
        showAsModal: true,
      },
    });

    await modalGC.present();
    return await modalGC.onDidDismiss();
  }

  async showAddEditModal() {
    const modal = await this.modals.create({
      component: AddEditWorkOrderModalComponent,
      cssClass: 'fullscreen-modal rounded-modal',
      backdropDismiss: false,
    });
    (modal.component as unknown as AddEditWorkOrderModalComponent).modal = modal;
    await modal.present();
    return await modal.onDidDismiss();
  }

  async showAddEditActionSheet(status: WorkOrderStatus) {
    const actionSheet = await this.actionSheets.create({
      header: 'What would you like to do?',
      buttons: [
        {
          text: 'Leave without Saving',
          role: 'leave',
        },
        ...(getEditMode(status) === 'republish'
          ? [
              {
                text: 'Cancel this Work Order',
                cssClass: 'button-text-red',
                role: 'setCancel', // Can't be 'cancel', backdrop dismiss emits cancel and could cause bugs
              },
            ]
          : [
              {
                text: 'Save & Close',
                role: 'save',
              },
            ]),
        ...(getEditMode(status) === 'partial'
          ? []
          : [
              {
                text: 'Delete this Work Order',
                cssClass: 'button-text-red',
                role: 'delete',
              },
            ]),
      ],
    });

    await actionSheet.present();
    return await actionSheet.onDidDismiss();
  }

  async showReviewInstallersModal(): Promise<void> {
    if (this.currentReviewInstallersModal) {
      return Promise.resolve(void 0);
    }

    this.currentReviewInstallersModal = await this.modals.create({
      component: ReviewInstallersModalComponent,
      backdropDismiss: false,
      cssClass: 'rounded-modal fullscreen-modal',
    });

    this.currentReviewInstallersModal.onDidDismiss().then(() => {
      this.currentReviewInstallersModal = undefined;
      this.store.dispatch(installersReviewCompleted());
    });

    await this.currentReviewInstallersModal.present();
  }

  async showReviewInstallerModal(): Promise<void> {
    if (this.currentReviewInstallerModal) {
      return Promise.resolve(void 0);
    }

    this.currentReviewInstallerModal = await this.modals.create({
      component: ReviewInstallerModalComponent,
      backdropDismiss: false,
      cssClass: 'fullscreen-modal rounded-modal',
    });

    this.currentReviewInstallerModal.onDidDismiss().then(() => {
      this.currentReviewInstallerModal = undefined;
      this.store.dispatch(installerReviewCompleted());
    });

    await this.currentReviewInstallerModal.present();
  }

  async closeReviewInstallerModal(): Promise<boolean> {
    if (this.currentReviewInstallerModal) {
      await this.currentReviewInstallerModal.dismiss();
      return true;
    }
    return false;
  }

  async unwindReviewInstallersModalStack() {
    if (this.currentReviewInstallerModal) {
      await this.currentReviewInstallerModal.dismiss();
    }
    if (this.currentReviewInstallersModal) {
      await this.currentReviewInstallersModal.dismiss();
    }
  }

  async confirm(header: string, message: string): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header,
      message,
      buttons: [
        {
          text: 'Yes',
          role: 'ok',
        },
        {
          text: 'No',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  confirmAction(action: string, thing: string = 'work order'): Promise<string | undefined> {
    return this.confirm('Are you sure?', `${action} this ${thing} cannot be undone!`);
  }

  confirmCancelWorkOrder(): Promise<string | undefined> {
    return this.confirm('Cancel Work Order', 'Are you sure you wish to cancel this Work Order.');
  }

  confirmAwardToInstaller(installer: DetailedInstaller): Promise<string | undefined> {
    return this.confirm(
      'Award to Installer',
      `Are you sure you wish to award this Work Order to ${installer.firstName} ${installer.lastName}?`,
    );
  }

  async showDropdownMenu(
    event: Event,
    workOrder: WorkOrder,
    authUser: User,
    awardedUser?: Installer,
  ): Promise<OverlayEventDetail<ListOption>> {
    const popover = await this.popovers.create({
      component: CustomListPopoverComponent,
      componentProps: {
        title: 'Actions',
        listOptions: [
          ...(isManager(authUser) &&
          !isStatus(workOrder.status, WorkOrderStatus.Done, WorkOrderStatus.Cancelled, WorkOrderStatus.Deleted)
            ? [{ color: 'default', label: 'Edit', name: editCurrentWorkOrder.type }]
            : []),

          ...(isInstallerLead(authUser) &&
          isStatus(
            workOrder.status,
            WorkOrderStatus.InProgressNoInvoices,
            WorkOrderStatus.InProgressHasInvoices,
            WorkOrderStatus.AwardedNotStarted,
          )
            ? [{ color: 'default', label: 'Share Work Order', name: shareWorkOrder.type }]
            : []),

          ...(isAdmin(authUser) &&
          isStatus(
            workOrder.status,
            WorkOrderStatus.Draft,
            WorkOrderStatus.PublishedAwaitingResponse,
            WorkOrderStatus.PublishedWithResponses,
            WorkOrderStatus.AwardedNotStarted,
            WorkOrderStatus.InProgressHasInvoices,
            WorkOrderStatus.InProgressNoInvoices,
          )
            ? [{ label: 'Select Project Manager', name: showSelectProjectManager.type }]
            : []),

          ...(isAdmin(authUser) &&
          isEmployeeInstaller(awardedUser) &&
          isStatus(
            workOrder.status,
            WorkOrderStatus.AwardedNotStarted,
            WorkOrderStatus.InProgressHasInvoices,
            WorkOrderStatus.InProgressNoInvoices,
          )
            ? [{ label: 'Select Employee', name: showSelectEmployee.type }]
            : []),

          ...(isManager(authUser) ? [{ label: 'Duplicate as Draft', name: duplicateAsDraft.type }] : []),

          ...(isManager(authUser) &&
          isEmployeeInstaller(awardedUser) &&
          !isStatus(workOrder.status, WorkOrderStatus.Draft, WorkOrderStatus.Done)
            ? [{ label: 'Close Work Order', name: closeWorkOrder.type }]
            : []),

          ...(isManager(authUser) &&
          isStatus(
            workOrder.status,
            WorkOrderStatus.Draft,
            WorkOrderStatus.PublishedAwaitingResponse,
            WorkOrderStatus.PublishedWithResponses,
          )
            ? [
                {
                  label: 'Delete',
                  color: 'danger',
                  name: deletePendingWorkOrder.type,
                },
              ]
            : []),
        ],
      },
      event,
    });
    await popover.present();
    return await popover.onDidDismiss();
  }

  async showShareWorkOrderModal(
    installers: TeamUser[],
    selected: TeamUser[],
    workOrder: WorkOrder,
  ): Promise<OverlayEventDetail<string[]>> {
    const modalGC = await this.modals.create({
      component: ShareWithInstallersModalComponent,
      componentProps: {
        installers,
        selected,
        workOrder,
      },
    });
    await modalGC.present();
    return await modalGC.onDidDismiss();
  }

  async showSelectProjectManager(workOrder: WorkOrder, projectManagers: Manager[], event: Event) {
    const modalGC = await this.popovers.create({
      component: SelectProjectManagerPopoverComponent,
      event,
      componentProps: {
        workOrder,
        projectManagers,
      },
    });
    await modalGC.present();
    return await modalGC.onDidDismiss();
  }

  async showSelectEmployee(workOrder: WorkOrder, employees: EmployeeInstaller[], event: Event) {
    const modalGC = await this.popovers.create({
      component: SelectEmployeePopoverComponent,
      event,
      componentProps: {
        workOrder,
        employees,
      },
    });
    await modalGC.present();
    return await modalGC.onDidDismiss();
  }

  async navigateToWorkOrderAddress(address: string): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      await this.navigator.navigate(address);
    } else {
      window.open(`https://www.google.com/maps/search/?api=1&query=${address}`, '_blank');
    }
  }
}
