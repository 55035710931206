import { createSelector } from '@ngrx/store';
import { NegotiationItem } from './negotiations/negotiation-item/negotiation-item.model';
import { allNegotiationItems } from './negotiations/negotiation-item/negotiation-item.state';
import { Negotiation } from './negotiations/negotiation/negotiation.model';
import { allNegotiations } from './negotiations/negotiation/negotiation.state';
import { whereMatchesWorkOrder } from './utils';
import { WorkOrder } from './work/work-order/work-order.model';
import { currentWorkOrder } from './work/work-order/work-order.state';

export const filterNegotiationsForWorkOrder = (negotiations: Negotiation[], workOrder?: WorkOrder): Negotiation[] =>
  workOrder ? negotiations.filter(whereMatchesWorkOrder(workOrder)) : [];

export const negotiationsForCurrentWorkOrder = createSelector(
  allNegotiations,
  currentWorkOrder,
  filterNegotiationsForWorkOrder,
);

export const filterNegotiationItemssForWorkOrder = (
  negotiationItems: NegotiationItem[],
  workOrder?: WorkOrder,
): NegotiationItem[] => (workOrder ? negotiationItems.filter(whereMatchesWorkOrder(workOrder)) : []);

export const negotiationItemsForCurrentWorkOrder = createSelector(
  allNegotiationItems,
  currentWorkOrder,
  filterNegotiationItemssForWorkOrder,
);
