import { createAction, props } from '@ngrx/store';
import { WorkOrderDate } from '../../core/calendar';

export const editingWorkOrderDateAdded = createAction(
  '[MODAL: Add/Edit Work Order] (Scheduler Date) Added',
  props<{ date: WorkOrderDate }>(),
);

export const editingWorkOrderDateSaved = createAction(
  '[MODAL: Add/Edit Work Order] (Scheduler Date) Saved',
  props<{ date: WorkOrderDate }>(),
);

export const editingWorkOrderDateDeleted = createAction(
  '[MODAL: Add/Edit Work Order] (Scheduler Date) Deleted',
  props<{ date: WorkOrderDate }>(),
);
