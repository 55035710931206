import { Animation, createAnimation } from '@ionic/angular';

export interface TransitionOptions {
  progressCallback?: (ani: Animation | undefined) => void;
  baseEl: any;
  enteringEl: HTMLElement;
  leavingEl: HTMLElement | undefined;
}

export type AnimationCreator = (an: Animation) => Animation;

// Stolen from source code since it's not exported
export const getIonPageElement = (element: HTMLElement): Element => {
  if (element?.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element?.querySelector(':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs');
  if (ionPage) {
    return ionPage;
  }
  // idk, return the original element so at least something animates and we don't have a null pointer
  return element;
};

export const createAnimationWithEl = (el: HTMLElement) => createAnimation().addElement(getIonPageElement(el));

export const createRootAnimation = (duration: number) =>
  createAnimation().duration(duration).easing('cubic-bezier(0.3,0,0.66,1)');

export const appendEnterOpacityChange = (an: Animation) =>
  an.beforeRemoveClass('ion-page-invisible').fromTo('opacity', '0.25', '1');

export const appendExitOpacityChange = (an: Animation) => an.fromTo('opacity', '1', '0.25');

export const appendShiftDown = (an: Animation) => an.fromTo('transform', 'translateY(0px)', 'translateY(25px)');

export const appendShiftUp = (an: Animation) => an.fromTo('transform', 'translateY(25px)', 'translateY(0px)');

export const createTransition =
  (enter: AnimationCreator, leave: AnimationCreator, config: { duration: number } = { duration: 100 }) =>
  <TOptions extends TransitionOptions>(baseEl: any, options: TOptions) =>
    createRootAnimation(config.duration).addAnimation([
      enter(createAnimationWithEl(options.enteringEl)),
      ...(options.leavingEl ? [leave(createAnimationWithEl(options.leavingEl))] : []),
    ]);
