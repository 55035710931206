import { createSelector } from '@ngrx/store';
import { authenticatedUser } from '../../domains/app/auth/auth.selectors';
import { Installer, isCompanyUser, User } from '../../domains/users';
import { Connection } from '../../domains/users/connection/connection.model';
import { allConnections } from '../../domains/users/connection/connection.state';
import { view } from '../../shared/utils/ngrx';
import { isCurrentInstallerAlsoCurrentUser } from './store/feature.selectors';
import { currentInstaller } from './store/profile.selectors';

export const authenticatedUserInstallerConnections = createSelector(
  allConnections,
  authenticatedUser,
  view(
    'authenticatedUserInstallerConnections',
    (connections: Connection[], user?: User): Connection[] =>
      isCompanyUser(user) ? connections.filter(connection => connection.companyId === user.companyId) : connections,
    true,
    true,
  ),
);

export const currentInstallerConnection = createSelector(
  isCurrentInstallerAlsoCurrentUser,
  authenticatedUserInstallerConnections,
  currentInstaller,
  (isCurrentUser: boolean, connections: Connection[], installer: Installer | undefined): Connection | undefined =>
    isCurrentUser
      ? undefined
      : installer &&
        connections.every(
          connection =>
            connection.status !== 'APPROVED' && connection.status !== 'ACCEPTED' && connection.status !== 'PENDING',
        )
      ? connections.find(connection => connection.installerTeamLeadId === installer.id)
      : installer &&
        connections.some(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'APPROVED',
        )
      ? connections.find(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'APPROVED',
        )
      : installer &&
        connections.some(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'ACCEPTED',
        )
      ? connections.find(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'ACCEPTED',
        )
      : installer &&
        connections.some(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'PENDING',
        )
      ? connections.find(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'PENDING',
        )
      : installer &&
        connections.some(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'DECLINED',
        )
      ? connections.find(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'DECLINED',
        )
      : installer &&
        connections.some(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'DISCONNECTED',
        )
      ? connections.find(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'DISCONNECTED',
        )
      : installer &&
        connections.some(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'NO_SUBSCRIPTION',
        )
      ? connections.find(
          connection => connection.installerTeamLeadId === installer.id && connection.status === 'NO_SUBSCRIPTION',
        )
      : undefined,
);
