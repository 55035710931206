<gc-invoice-table
  [reportsProgress]="reportsProgress$ | push"
  [invoices]="workOrderRelations.totalledInvoicesForWorkOrder$ | push"
  [loading]="isPending$ | push"
  (options)="invoiceAndPayment.showInvoiceOptions($event.event, $event.invoice)"
  (listEndReached)="invoiceAndPayment.invoiceListEndReached($event)"
  (reload)="invoices.load($event.id)"
></gc-invoice-table>

<gc-empty-list-warning
  *ngIf="!(invoices.isLoading$ | push) && !(workOrderRelations.totalledInvoicesForWorkOrder$ | push)?.length"
>
  No {{ invoiceLabel }} found for this Work Order.
</gc-empty-list-warning>
