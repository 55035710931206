import { Component, Input } from '@angular/core';
import { PendingInvoice } from '../../../domains/models/pending-invoice';

@Component({
  selector: 'gc-review-invoice-list',
  templateUrl: './review-invoice-list.component.html',
  styleUrls: ['./review-invoice-list.component.scss'],
})
export class ReviewInvoiceListComponent {
  @Input() pendingInvoice!: PendingInvoice;
  @Input() role?: string;
  @Input() permissions!: string[];
}
