import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, distinctUntilChanged, filter, map, throttleTime } from 'rxjs/operators';
import { lookupUser } from '../../../core/invites';
import { inviteProcessAborted, inviteProcessInitiated } from '../../../core/invites/invites.actions';
import { UserQuery } from '../../../core/invites/models/user-query.model';
import { InvitedUser } from '../../../domains/users';
import {
  companyInviteModalCanceled,
  companyInviteModalSubmitted,
  emailAddressFieldChanged,
} from './company-invite.actions';

@Injectable()
export class CompanyInviteEffects {
  constructor(private readonly actions$: Actions) {}

  lookupUserByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(emailAddressFieldChanged),
      debounceTime(777),
      distinctUntilChanged((x, y) => x?.email === y?.email),
      map(({ email, userType }) => lookupUser({ email, userType })),
    ),
  );

  submitInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyInviteModalSubmitted),
      throttleTime(50),
      filter(
        (value: {
          invite: Partial<InvitedUser>;
          user?: UserQuery;
        }): value is {
          invite: InvitedUser;
          user?: UserQuery;
        } => !!value.invite && !!value.invite.email && !!value.invite.fullName,
      ),
      map(({ invite, user }) => inviteProcessInitiated({ invite, user })),
    ),
  );

  abortInvite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(companyInviteModalCanceled),
      map(() => inviteProcessAborted()),
    ),
  );
}
