<div class="profile-content-section">
  <div class="flex justify-content-between align-items-center" data-cy="subtypes-area">
    <h2 data-cy="subtypes-title">My Favorite Flooring to Install</h2>
    <ion-button
      *ngIf="canEditProfile"
      class="add"
      fill="outline"
      size="small"
      mode="ios"
      color="dark"
      (click)="subtypeAdded.emit(claimedInstallType.installationType)"
      data-cy="installer-add-subtype-button"
    >
      <ion-icon slot="start" name="add-sharp" style="font-size: 14px"></ion-icon>
      Add
    </ion-button>
  </div>
  <div
    class="profile-content-section-content"
    *ngIf="productInstallationSubtypes.subtypesClaimed?.length > 0; else noSubtypes"
    data-cy="subtypes-list"
  >
    <div class="flex flex-column">
      <gc-installer-subtypes-badge-list
        [subtypes]="productInstallationSubtypes.subtypesClaimed"
        [showRemove]="productInstallationSubtypes.showRemove"
        (remove)="subtypeRemoved.emit($event)"
      ></gc-installer-subtypes-badge-list>
    </div>
  </div>
  <ng-template #noSubtypes>
    <ng-container *ngIf="!canEditProfile; else installerView">
      <div class="profile-content-section-content" data-cy="company-empty-subtypes-area">
        <div class="alert alert__warning text-center mt-0">
          No Favorite Flooring to Install found for <strong>{{ claimedInstallType.type }}</strong
          >.
        </div>
      </div>
    </ng-container>
    <ng-template #installerView>
      <div class="profile-content-section-content" data-cy="installer-empty-subtypes-area">
        <div class="alert alert__warning text-center mt-0">
          You have not added any Favorite Flooring to Install for <strong>{{ claimedInstallType.type }}</strong
          >.
        </div>
      </div>
    </ng-template>
  </ng-template>
</div>
