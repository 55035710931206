import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation, Position } from '@capacitor/geolocation';

@Injectable({ providedIn: 'root' })
export class GeoLocationService {
  async requestPermission() {
    if (Capacitor.isNativePlatform()) {
      const permissions = await Geolocation.requestPermissions({ permissions: ['location', 'coarseLocation'] });
      console.log('GeoLocation Permissions', permissions);
      return permissions.location !== 'denied';
    } else {
      const permissions = await navigator.permissions.query({ name: 'geolocation' });
      console.log('GeoLocation Permissions', permissions);
      return permissions.state !== 'denied';
    }
  }

  getCurrentGeolocation(): Promise<Position> {
    return Capacitor.isNativePlatform()
      ? Geolocation.getCurrentPosition()
      : new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject));
  }
}
