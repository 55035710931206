import { Pipe, PipeTransform } from '@angular/core';
import { DetailedWorkOrderItem } from '../../domains/models/detailed-work-order-item';
import { NegotiableWorkOrderItem } from '../../domains/models/negotiable-work-order-item';

@Pipe({ name: 'negotiablePriceColor' })
export class NegotiablePriceColorPipe implements PipeTransform {
  transform(item: DetailedWorkOrderItem | NegotiableWorkOrderItem, canBeNegotiated: boolean = true): string {
    return canBeNegotiated && item?.negotiations?.some(item => item.isAcceptedByInstaller === false) === true
      ? 'danger'
      : 'gc-green';
  }
}
