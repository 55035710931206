import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TransactionItemComponent } from './transaction-item.component';

@NgModule({
  declarations: [TransactionItemComponent],
  imports: [CommonModule, IonicModule],
  exports: [TransactionItemComponent],
})
export class TransactionItemModule {}
