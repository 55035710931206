import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeOrder } from '../../../../domains/change-orders/change-order/change-order.model';
import { DetailedInstaller } from '../../../../domains/models/detailed-installer';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { User } from '../../../../domains/users';
import { DisplayGroupAllowance, DisplayOptions, WorkOrderItemGroup } from '../../../../domains/work-order-item-group.selectors';
import { WorkOrder } from '../../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-item-expanded-details',
  templateUrl: './item-expanded-details.component.html',
  styleUrls: ['./item-expanded-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemExpandedDetailsComponent {
  @Input() displayGroups!: DisplayGroupAllowance;
  @Input() permissions!: string[];
  @Input() userType!: string;
  @Input() group!: WorkOrderItemGroup;
  @Input() item!: DetailedWorkOrderItem;
  @Input() expandedItem!: DetailedWorkOrderItem;
  @Input() workOrder!: WorkOrder;
  @Input() user?: User;
  @Input() role?: string;

  @Output() collapsed = new EventEmitter();
  @Output() reviewed = new EventEmitter<DetailedInstaller>();
  @Output() voided = new EventEmitter<ChangeOrder>();
  @Output() accepted = new EventEmitter<ChangeOrder>();
  @Output() pmCalled = new EventEmitter<WorkOrder>();
}
