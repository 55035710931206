import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { InvoiceItem } from '../../invoices';
import { IsoDateString } from './../../models/iso-date-string';

@Entity({
  modelName: 'ChangeOrder',
  uriName: 'change-orders',
})
export class ChangeOrder {
  @Key id?: number;
  acceptedAt?: IsoDateString;
  acceptedById?: string;
  adjustedNumberOfUnits!: number;
  adjustedPricePerUnit!: number;
  invoiceItemIds!: number[];
  invoiceItems?: InvoiceItem[];
  voidedById?: string;
  voidedAt?: IsoDateString;
  isVoided?: boolean;
  name!: string;
  workOrderId!: string;
  workOrderItemId!: string;
  status!: ChangeOrderStatus;
  createdOn!: string;
  updatedOn!: string;
}

export enum ChangeOrderStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  VOIDED = 'VOIDED',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
}
