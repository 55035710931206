import { createSelector } from '@ngrx/store';
import { SettlementTiming } from '~gc/domains/invoices/settlement-timing/settlement-timing.entity';
import {
  allSettlementTimings,
  currentSettlementTiming,
} from '~gc/domains/invoices/settlement-timing/settlement-timing.state';
import { currentInstallerConnection } from '../../../core/installer-profile/installer-connection.selectors';
import { hasVerifiedFundingSource } from '../../../shared/dwolla/feature/dwolla-account/dwolla.selectors';
import { view } from '../../../shared/utils/ngrx';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { Company } from '../../users';
import { Connection } from '../../users/connection/connection.model';
import { allConnections } from '../../users/connection/connection.state';
import { privateApprovedConnections } from '../../users/connection/connections.selectors';
import { currentWorkOrderCompany } from '../../work-order-users.selectors';
import { currentInvoicer } from '../invoice-and-payment.selectors';

const createDetailedSettlementTiming = (timing: SettlementTiming, verified: boolean) => ({
  ...timing,
  available: timing.providers.includes('STRIPE') || (timing.providers.includes('DWOLLA') && verified),
});

export const currentDetailedSettlementTiming = createSelector(
  currentSettlementTiming,
  hasVerifiedFundingSource,
  (timing, verified) => timing && createDetailedSettlementTiming(timing, verified),
);

export const detailedSettlementTimings = createSelector(
  allSettlementTimings,
  hasVerifiedFundingSource,
  (timings, verified) => timings.map(timing => createDetailedSettlementTiming(timing, verified)),
);

export const detailedSettlementTimingsForPrivateConnections = createSelector(
  detailedSettlementTimings,
  timings => timings.filter(timing => timing.showForPrivateConnections), // 2
);

export const detailedSettlementTimingsForPublicConnections = createSelector(
  detailedSettlementTimings,
  timings => timings.filter(timing => !timing.showForPrivateConnections), // 3
);

export const currentWorkOrderCompanyPrivateApprovedConnections = createSelector(
  privateApprovedConnections,
  currentWorkOrderCompany,
  (connections, company) => connections.find(connection => connection.companyId === company?.id),
);

export const applicableDetailedSettlementTimings = createSelector(
  detailedSettlementTimingsForPublicConnections,
  detailedSettlementTimingsForPrivateConnections,
  currentWorkOrderCompanyPrivateApprovedConnections,
  view(
    'applicableDetailedSettlementTimings',
    (publicTimings: SettlementTiming[], privateTimings: SettlementTiming[], connection?: Connection): SettlementTiming[] =>
    !!connection ? privateTimings : publicTimings,
  true, true),
  );
