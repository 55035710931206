import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PendingFile } from '../../../domains/models/pending-file';
import { User } from '../../../domains/users';
import { UserFile } from '../../../domains/users/user-files/user-file.model';

@Component({
  selector: 'gc-installer-profile-part-four',
  templateUrl: './installer-profile-part-four.component.html',
  styleUrls: [
    './installer-profile-part-four.component.scss',
    '../installer-profile-modal/installer-profile-modal.component.scss',
  ],
})
export class InstallerProfilePartFourComponent {
  @Input() validated = false;
  @Input() form!: UntypedFormGroup;
  @Input() user?: User;
  @Input() files!: UserFile[];
  @Output() fileAdded = new EventEmitter<{ user: User; file: PendingFile }>();
}
