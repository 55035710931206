import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action } from '@ngrx/store';
import { LienRelease } from '~gc/domains/invoices/lien-releases/lien-release.model';

export const {
  initialState: initialLienReleaseState,
  facade: LienReleaseFacadeBase,
  selectors: { selectAll: allLienReleases },
  actions: { loadAll: loadAllLienReleases },
} = buildState(LienRelease);

export function lienReleaseReducer(state = initialLienReleaseState, action: Action): IEntityState<LienRelease> {
  return state;
}
