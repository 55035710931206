import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceableWorkOrderItem } from '../../domains/models/invoiceable-work-order-item';
import { getChangeOrdersTotal } from './change-orders-total.pipe';
import { PendingInvoiceForm } from './invoice.form';

export const invoiceItemTotal = (amountToInvoice: number, item: InvoiceableWorkOrderItem): number =>
  amountToInvoice ? amountToInvoice * item.pricePerUnit - (getChangeOrdersTotal(item.applicableChangeOrders) || 0) : 0;

@Pipe({ name: 'pendingInvoiceTotal', pure: false })
export class PendingInvoiceTotalPipe implements PipeTransform {
  transform(form: PendingInvoiceForm): number {
    return form.items.itemForms.reduce((total, item) => total + item.totaledAmountInclusiveOfApplicableChangeOrders, 0);
  }
}
