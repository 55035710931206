import { matchOptionalByOptionalId } from '../shared/utils/utils';
import { isEmployeeInstaller, isManager, User } from './users';
import { WorkOrder } from './work/work-order/work-order.model';


export const isAssignedEmployee = (user?: User, workOrder?: WorkOrder): boolean =>
  isEmployeeInstaller(user) && workOrder?.awardedToInstallerId === user.id && workOrder?.companyId === user.companyId;

export const isIssuingManager = (user?: User, workOrder?: WorkOrder): boolean =>
  isManager(user) && workOrder?.companyId === user.companyId;

export const reportsProgress = (users: User[], user?: User, workOrder?: WorkOrder): boolean =>
  (isAssignedEmployee(user, workOrder) ||
    (
      isAssignedEmployee(users.find(matchOptionalByOptionalId(workOrder?.awardedToInstallerId)), workOrder) &&
      isIssuingManager(user, workOrder)
    ));
