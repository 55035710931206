import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, map, takeUntil } from 'rxjs/operators';
import { EmptyKey } from '~gc/domains/entity.service.utils';
import { viewWorkOrder } from '~gc/domains/work/work-order-ui.actions';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { Invoice } from '..';
import { excludeRoles } from '../../../shared/utils/roles.operators';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedRole } from '../../app/auth/auth.selectors';
import { AppState } from '../../state';
import { showSignLienRelease, signLienRelease } from '../invoice-and-payment.actions';
import { loadAllLienReleases } from './lien-release.state';

@Injectable()
export class LienReleaseEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadLienReleases$ = createEffect(() =>
    this.actions$.pipe(
      // TODO:: Might need this one showInvoiceOptions,
      ofType(viewWorkOrder, signLienRelease, showSignLienRelease),
      excludeRoles(this.store.select(authenticatedRole), 'installerMember', 'jumpstarter'),
      map(action =>
        loadAllLienReleases({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]:
                action.type === viewWorkOrder.type ? action.workOrder.id : action.invoice.workOrderId,
              [uriNameOfEntityOrEmpty(Invoice)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );
}
