import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Store } from '@ngrx/store';
import { GenericFile } from '../../models/generic-file';
import { PendingFile } from '../../models/pending-file';
import { AppState } from '../../state';
import { WorkOrder } from '../work-order/work-order.model';
import { openWorkOrderFile } from './work-order-files.actions';
import { WorkOrderFile } from './work-order-files.model';
import {
  deleteWorkOrderFileUpload,
  pendingWorkOrderFiles,
  uploadWorkOrderFile,
  uploadWorkOrderFileRetry,
  WorkOrderFilesFacadeBase,
} from './work-order-files.state';

@Injectable()
export class WorkOrderFilesFacade extends WorkOrderFilesFacadeBase {
  allWorkOrderFiles$ = this.store.select(pendingWorkOrderFiles);

  constructor(private readonly store: Store<AppState>) {
    super(WorkOrderFile, store);
  }

  open(file: WorkOrderFile): void {
    this.store.dispatch(openWorkOrderFile({ file }));
  }

  upload(pendingFile: PendingFile, workOrder: Partial<WorkOrder>): void {
    this.store.dispatch(
      uploadWorkOrderFile({
        pendingFile,
        criteria: {
          parents: {
            [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
          },
        },
      }),
    );
  }

  retry(pendingFile: PendingFile, workOrder: Partial<WorkOrder>): void {
    this.store.dispatch(
      uploadWorkOrderFileRetry({
        pendingFile,
        criteria: {
          parents: {
            [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
          },
        },
      }),
    );
  }

  remove(file: GenericFile, workOrder: Partial<WorkOrder>): void {
    this.store.dispatch(
      deleteWorkOrderFileUpload({
        file,
        criteria: {
          parents: {
            [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
          },
        },
      }),
    );
  }
}
