<div class="flex justify-content-between align-items-center p-10">
  <ion-buttons slot="start">
    <ion-button
      color="dark"
      (click)="closeProfileModal.emit({ event: $event, installer: installer })"
      data-cy="close-installer-profile"
    >
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons *ngIf="canEditProfile" slot="end">
    <ion-button
      fill="clear"
      size="medium"
      mode="ios"
      color="dark"
      (click)="editMyProfile.emit({ event: $event, installer: installer })"
      data-cy="edit-installer-profile"
    >
      <ion-icon slot="start" name="create-sharp"></ion-icon>
      Edit
    </ion-button>
  </ion-buttons>
</div>

<div class="modal-sidebar-header-content ion-text-center" [ngClass]="installer.isUnion ? '' : 'pb-20'">
  <gc-user-profile-avatar
    [user]="installer"
    [centered]="true"
    [isPrivate]="
      connection?.source === 'COMPANY_PRIVATE' &&
      (connection?.status === 'APPROVED' || connection?.status === 'ACCEPTED')
    "
  ></gc-user-profile-avatar>

  <h1 data-cy="installer-full-name">{{ installer | fullName }}</h1>
  <p *ngIf="installer.businessName" style="margin-top: 0" data-cy="installer-business-name">
    {{ installer.businessName }}
  </p>
  <div>
    <ng-container *ngIf="installer.type === 'INSTALLER_TEAM_LEAD'">
      <div class="flex align-items-center justify-content-center">
        <ion-chip color="dark" mode="ios" data-cy="installer-kudos-badge" ngxTippy="Kudos Received">
          <ion-icon name="thumbs-up"></ion-icon>
          <strong>{{ installer.kudos }}</strong>
        </ion-chip>
        <ion-chip color="dark" mode="ios" data-cy="installer-work-orders-badge" ngxTippy="Completed Work Orders">
          <ion-icon name="checkmark-circle"></ion-icon>
          <strong>{{ installer.completedWorkOrders }}</strong>
        </ion-chip>
        <ion-badge
          *ngIf="connection"
          slot="end"
          mode="ios"
          [color]="connection.status | networkStatusColor"
          class="flex align-items-center rounded-badge"
          style="margin-left: 4px"
          data-cy="installer-connection-status-badge"
        >
          <fa-icon [icon]="faChartNetwork" style="margin-right: 6px"></fa-icon>
          {{ connection | connectionStatusLabel: 'company' }}
        </ion-badge>
      </div>
    </ng-container>
  </div>

  <div style="display: flex; justify-content: center; gap: 4px; padding: 15px 5px 0">
    <ion-button mode="ios" color="gc-green" (click)="called.emit(installer)" data-cy="call-installer-button">
      <ion-icon name="call"></ion-icon>
      <ion-label>Call</ion-label>
    </ion-button>
    <ion-button mode="ios" color="light" ngxTippy="Send Email" data-cy="email-installer-button"
                (click)="emailed.emit(installer)">
      <ion-icon name="mail-outline"></ion-icon>
    </ion-button>
    <ng-container *ngIf="installer.type === 'INSTALLER_TEAM_LEAD'">
      <ion-button mode="ios" color="light" ngxTippy="Visit Public Profile" data-cy="installer-website-button"
                  (click)="websiteVisited.emit(installer)">
        <ion-icon name="globe-outline"></ion-icon>
      </ion-button>
    </ng-container>
    <ion-button
      mode="ios"
      color="light"
      data-cy="installer-menu-options"
      (click)="optionsOpened.emit({ event: $event, installer: installer })"
    >
      <ion-icon name="ellipsis-horizontal"></ion-icon>
    </ion-button>
  </div>
  <div id="reputation-score" *ngIf="installer.reputationScore" class="flex flex-row m-6 align-items-center">
    <ion-icon id="ribbon" name="ribbon-sharp"></ion-icon>
    <div class="flex flex-col justify-content-start text-left">
      <h2 class="m-0">
        Reputation Score
        <a href="https://gocarrera.com/resources/knowledge-base/what-is-the-reputation-score" target="_blank"
           ngxTippy="Learn more about our Reputation Score">
          <ion-icon name="help-circle"></ion-icon>
        </a>
      </h2>
      <small>Last updated: {{ installer.reputationScoreLastUpdated | date }}</small>
      <div class="flex flex-row align-items-center">
        <ion-progress-bar [value]="(installer.reputationScore) / 100"></ion-progress-bar>
        <strong>{{ installer.reputationScore }}%</strong>
      </div>
    </div>
  </div>
  <div *ngIf="installer.isUnion" class="union-tab">Union Installer</div>
</div>
