import { createSelector } from '@ngrx/store';
import { Installer } from '../../domains/users';
import { Connection } from '../../domains/users/connection/connection.model';
import { PhoneNumberEntry } from '../../shared/components/advanced-phone-number-manager/models/phone-number-entry.model';
import { view } from '../../shared/utils/ngrx';
import { currentInstallerComplianceRecords } from './compliance.selectors';
import { currentInstallerHammerRatingDetails } from './hammer-rating-details.selectors';
import { currentInstallerConnection } from './installer-connection.selectors';
import { currentInstallerKudosReasonDetails } from './kudos-reasons-details.selectors';
import { ComplianceRecord } from './models/compliance-record.model';
import { DetailedInstallerProfileContext, KudosReasonsDetails, } from './models/detailed-installer-profile-context.model';
import { ExtendedHammerRatingDetails } from './models/extended-hammer-rating.details.model';
import { InstallationTypeSubtypesClaimed } from './models/installation-type-subtypes-claimed.model';
import { currentInstallerPhoneNumberEntries } from './phone-number-details.selectors';
import { currentInstallerProductInstallationSubtypes } from './product-installation-type-and-subtype.selectors';
import { isCurrentInstallerAlsoCurrentUser } from './store/feature.selectors';
import { currentInstaller } from './store/profile.selectors';


export const detailedInstallerProfileContext = createSelector(
  currentInstaller,
  currentInstallerConnection,
  currentInstallerKudosReasonDetails,
  currentInstallerHammerRatingDetails,
  currentInstallerProductInstallationSubtypes,
  currentInstallerComplianceRecords,
  currentInstallerPhoneNumberEntries,
  isCurrentInstallerAlsoCurrentUser,
  view(
    'detailedInstallerProfileContext',
    (
      installer: Installer,
      connection: Connection,
      kudosReasonsDetails: KudosReasonsDetails,
      hammerRatingDetails: ExtendedHammerRatingDetails[],
      productInstallationSubtypesClaimed: InstallationTypeSubtypesClaimed[],
      complianceRecords: ComplianceRecord[],
      phoneNumberEntries: PhoneNumberEntry[],
      canEditProfile: boolean,
    ): DetailedInstallerProfileContext | undefined =>
      installer
        ? {
            installer,
            connection,
            kudosReasonsDetails,
            hammerRatingDetails,
            productInstallationSubtypesClaimed,
            complianceRecords,
            phoneNumberEntries,
            canEditProfile,
          }
        : undefined,
  ),
);
