import { createAction, props } from '@ngrx/store';
import { User } from '../../users';

export const chatLanguageSet = createAction('[Stream Chat] (Language) Set');

export const chatInitialized = createAction('[Stream Chat] (Chat Service) Initialized');
export const chatInitializationFailed = createAction(
  '[Stream Chat] (Chat Service) Initialization Failed',
  props<{ error: any }>(),
);
export const channelInitialized = createAction('[Stream Chat] (Channel) Initialized');
export const channelInitializationFailed = createAction(
  '[Stream Chat] (Channel) Initialization Failed',
  props<{ error: any }>(),
);

export const chatInitializationFinalized = createAction(
  '[Stream Chat] (Chat Service) Initialization Finalized',
  props<{ user?: User }>(),
);
