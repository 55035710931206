<ng-container *ngIf="!hasError && activeChannel; else loading">
  <div *ngIf="fullScreen" class="work-order-channel-list">
    <ion-card *ngFor="let channel of workOrderChat.currentChannelIds$ | push | idsToChannels" mode="ios">
      <gc-channel-item
        [workOrderChat]="workOrderChat.getEnrichedWorkOrderChat$(channel) | push"
        [isCurrent]="activeChannel.id === channel.id"
        (selected)="channelService.setAsActiveChannel(channel)"
      ></gc-channel-item>
    </ion-card>
  </div>

  <stream-channel *ngIf="workOrderChat.getEnrichedWorkOrderChat$(activeChannel) | push as chat">
    <gc-chat-context-warning
      *rxLet="workOrderChat.unmatchedWorkOrderChat$; let unmatchedWorkOrder"
      [unmatchedWorkOrder]="unmatchedWorkOrder"
      (switch)="workOrderChat.switchToCurrentWorkOrderChat()"
    ></gc-chat-context-warning>

    <ng-container *ngIf="workOrderChat.currentChannelIds$ | push | idsToChannels as currentChannels">
      <ion-accordion-group *ngIf="!fullScreen && currentChannels.length > 1; else basicHeader" #accordion>
        <ion-accordion value="channelSelect">
          <ion-item class="chat-header" [ngClass]="{ 'full-screen': fullScreen }" slot="header">
            <stream-avatar
              slot="start"
              [imageUrl]="chat.users[0]?.profilePhoto"
              [name]="activeChannel.data!.name"
              [size]="40"
            ></stream-avatar>
            <ion-label>
              <h3>{{ chat.users[0]?.firstName }} {{ chat.users[0]?.lastName }}</h3>
              <p>{{ activeChannel.data!.name }}</p>
            </ion-label>
          </ion-item>
          <ion-list slot="content" (click)="accordion.value = undefined">
            <gc-channel-item
              *ngFor="let channel of currentChannels"
              [workOrderChat]="workOrderChat.getEnrichedWorkOrderChat$(channel) | push"
              [isCurrent]="activeChannel.id === channel.id"
              (selected)="channelService.setAsActiveChannel(channel)"
            ></gc-channel-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>

    </ng-container>

    <ng-template #basicHeader>
      <ion-item
        class="basic-header chat-header"
        [ngClass]="{ 'full-screen': fullScreen }"
        *ngIf="channelService.activeChannel$ | push as channel"
        lines="none"
      >
        <stream-avatar
          slot="start"
          [imageUrl]="chat.users[0]?.profilePhoto"
          [name]="activeChannel.data!.name"
          [size]="40"
        ></stream-avatar>
        <ion-label>
          <h3>{{ chat.users[0]?.firstName }} {{ chat.users[0]?.lastName }}</h3>
          <p>{{ activeChannel.data!.name }}</p>
        </ion-label>
      </ion-item>
    </ng-template>

    <stream-message-list></stream-message-list>
    <stream-notification-list></stream-notification-list>
    <div id="chat-expander" (click)="toggleExpansion()">
      <ion-icon name="chevron-expand"></ion-icon>
    </div>
    <stream-message-input></stream-message-input>
    <stream-thread name="thread">
      <stream-message-list name="thread-message-list" mode="thread"></stream-message-list>
      <stream-message-input mode="thread" name="thread-message-input"></stream-message-input>
    </stream-thread>
  </stream-channel>
</ng-container>

<ng-template #loading>
  <div style="height: 100vh; display: flex; justify-content: center; align-items: center; flex-direction: column">
    <ion-spinner></ion-spinner>
    <span class="ion-text-center ion-margin"
    >You might not be a member of this channel. Would you like to join?</span
    >
    <ion-button mode="ios" (click)="workOrderChat.addMemberToChannel()">Join Channel</ion-button>
  </div>
</ng-template>
