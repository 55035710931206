import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PhoneNumber } from '../../../domains/phone-number';
import { User } from '../../../domains/users';

@Component({
  selector: 'gc-installer-profile-part-two',
  templateUrl: './installer-profile-part-two.component.html',
  styleUrls: [
    './installer-profile-part-two.component.scss',
    '../installer-profile-modal/installer-profile-modal.component.scss',
  ],
})
export class InstallerProfilePartTwoComponent {
  @Input() validated = false;
  @Input() form!: UntypedFormGroup;
  @Input() user?: User;
  @Input() phoneNumbers!: PhoneNumber[];
  @Input() phoneNumberForm!: UntypedFormGroup;
  @Output() added = new EventEmitter<PhoneNumber>();
  @Output() remove = new EventEmitter<PhoneNumber>();
}
