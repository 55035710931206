import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'Training',
  uriName: 'trainings',
})
export class Training {
  @Key id?: number;
  certification!: string;
  categoryId!: number;
  productInstallationTypeId!: number;
}
