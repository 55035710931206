import { createSelector } from '@ngrx/store';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { isManager, User } from '../../users';
import { WorkOrder } from '../work-order/work-order.model';
import { currentWorkOrder } from '../work-order/work-order.state';
import { InstallerResponse, MatchedInstaller } from './matched-installer.model';
import { allMatchedInstallers } from './matched-installer.state';

export const matchedInstallersForCurrentWorkOrder = createSelector(
  allMatchedInstallers,
  currentWorkOrder,
  (matchedInstallers: MatchedInstaller[], workOrder?: WorkOrder) =>
    workOrder ? matchedInstallers.filter(matched => matched.workOrderId === workOrder.id) : [],
);

const BIDDING_MATCHES = [InstallerResponse.Pending, InstallerResponse.Accepted, InstallerResponse.Negotiating];

export const biddingMatchedInstallersForCurrentWorkOrder = createSelector(
  matchedInstallersForCurrentWorkOrder,
  authenticatedUser,
  (matches: MatchedInstaller[], currentUser?: User): MatchedInstaller[] =>
    currentUser && isManager(currentUser) ? matches.filter(match => BIDDING_MATCHES.includes(match.response)) : [],
);

export const currentMatchedInstallerForWorkOrder = createSelector(
  matchedInstallersForCurrentWorkOrder,
  authenticatedUser,
  (matchedInstallers: MatchedInstaller[], user?: User) =>
    user ? matchedInstallers.find(matched => matched.installerId === user.id) : undefined,
);
