import { createReducer, on } from '@ngrx/store';
import {
  claimProductInstallationTypeModalCancelled,
  claimProductInstallationTypeModalDone,
  productInstallationTypeClaimed,
} from '../../../core/installer-profile/product-installation-type.actions';
import { ProductInstallationType } from '../../../domains/training/product-installation-types/product-installation-types.model';
import {
  claimProductInstallationTypeCancelled,
  claimProductInstallationTypeDateChosen,
  productInstallationTypeClaiming,
} from '../claim-product-installation-type-modal.actions';

export interface UIState {
  pending?: ProductInstallationType;
  claiming?: ProductInstallationType;
  added: number[];
}

export const initialState: UIState = {
  added: [],
};

export const uiReducer = createReducer(
  initialState,
  on(
    productInstallationTypeClaiming,
    (state: UIState, { installType }): UIState => ({ ...state, pending: installType }),
  ),
  on(
    claimProductInstallationTypeCancelled,
    (state: UIState): UIState => ({ ...state, claiming: undefined, pending: undefined }),
  ),
  on(
    claimProductInstallationTypeDateChosen,
    (state: UIState): UIState => ({ ...state, claiming: state.pending, pending: undefined }),
  ),
  on(
    productInstallationTypeClaimed,
    (state: UIState, { installType }): UIState => ({
      ...state,
      added: [...state.added, installType.id!],
      claiming: undefined,
    }),
  ),
  on(claimProductInstallationTypeModalCancelled, claimProductInstallationTypeModalDone, () => initialState),
);
