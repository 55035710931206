import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { from } from 'rxjs';
import { PendingFile } from '../../../domains/models/pending-file';
import { ClaimableProductInstallationType } from '../../../domains/training/models/obtainable-product-installation-types';
import { ProductInstallationTypeClaimed } from '../../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { TrainingFile } from '../../../domains/training/training-files/training-file.model';
import { HasTargetFiles } from '../../../shared/utils/types';

@Component({
  selector: 'gc-experience-documentation',
  templateUrl: './experience-documentation.component.html',
  styleUrls: ['./experience-documentation.component.scss'],
})
export class ExperienceDocumentationComponent {
  @Input() installationType?: ClaimableProductInstallationType;
  @Input() files?: TrainingFile[];
  @Output() fileAdded = new EventEmitter<{ claim: ProductInstallationTypeClaimed; file: PendingFile }>();

  addPendingFiles(files: File[]) {
    files
      .map((file: File) => ({
        filename: file.name,
        contentType: file.type,
        fileBlob: file,
      }))
      .forEach(file => {
        this.fileAdded.emit({
          claim: this.installationType!.claimed!, // TODO: Can we remove the non-nullish assertion here?
          file,
        });
      });
  }

  async addFiles($event: Event & HasTargetFiles): Promise<void> {
    this.addPendingFiles(Array.from($event.target.files));
  }

  addDroppedFiles(files: FileList): void {
    this.addPendingFiles(Array.from(files));
  }
}
