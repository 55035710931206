import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { ProductInstallationSubtypesClaimed } from './product-installation-subtypes-claimed.model';


export const {
  initialState,
  selectors: {
    selectAll: allProductInstallationSubtypesClaimed,
    selectEntities: productInstallationSubtypeClaimedEntities,
    selectCurrentEntity: currentProductInstallationSubtypeClaimed,
  },
  actions: {
    loadAll: loadAllProductInstallationSubtypesClaimed,
    loadAllIfNecessary: loadAllProductInstallationSubtypesClaimedIfNecessary,
    replace: replaceProductInstallationSubtypeClaim,
    create: createProductInstallationSubtypeClaim,
    delete: deleteProductInstallationSubtypeClaim,
    deleteFailure: deleteProductInstallationSubtypeClaimedFailure,
  },
} = buildState(ProductInstallationSubtypesClaimed);

export function productInstallationSubtypeClaimedReducer(
  state = initialState,
): IEntityState<ProductInstallationSubtypesClaimed> {
  return state;
}
