import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { buildFileState } from '../../../shared/file-uploads/build-file-state';
import { FileState } from '../../../shared/file-uploads/file-state';
import { ProcessingFile } from '../../../shared/file-uploads/file.selectors';
import { PendingFile } from '../../models/pending-file';
import { queueInvoiceFile, removeQueuedInvoiceFile, uploadQueuedFiles } from './invoice-file.actions';
import { InvoiceFile } from './invoice-file.model';

export interface InvoiceFileState extends FileState<InvoiceFile> {
  fileQueue: PendingFile[];
}

export const {
  initialState: initialInvoiceFileState,
  entityState: invoiceFileState,
  facade: InvoiceFilesFacadeBase,
  actions: { loadAll: loadAllInvoiceFiles },
  fileSelectors: { selectPendingFiles: pendingInvoiceFiles, selectPendingUploads: selectPendingInvoiceFileUploads },
  fileActions: { upload: uploadInvoiceFile, uploadRetry: uploadInvoiceFileRetry, delete: deleteInvoiceFileUpload },
} = buildFileState(InvoiceFile, { fileQueue: [] } as unknown as InvoiceFileState);

const reduce = createReducer(
  initialInvoiceFileState,
  on(
    queueInvoiceFile,
    (state, { pendingFile }): InvoiceFileState => ({
      ...state,
      fileQueue: [...state.fileQueue, pendingFile],
    }),
  ),
  on(
    removeQueuedInvoiceFile,
    (state, { pendingFile }): InvoiceFileState => ({
      ...state,
      fileQueue: state.fileQueue.filter(file => file.filename !== pendingFile.filename),
    }),
  ),
  on(
    uploadQueuedFiles,
    (state): InvoiceFileState => ({
      ...state,
      fileQueue: [],
    }),
  ),
);

export function invoiceFileReducer(state = initialInvoiceFileState, action: Action): InvoiceFileState {
  return reduce(state, action);
}

// export const invoiceFileState = createFeatureSelector<InvoiceFileState>('invoiceFile');

export const queuedInvoiceFiles = createSelector(invoiceFileState, state => state.fileQueue);

export const queuedProcessingInvoiceFiles = createSelector(queuedInvoiceFiles, (files): ProcessingFile<InvoiceFile>[] =>
  files.map(file => ({ ...file, processing: false })),
);
