import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { EmptyKey } from '../../domains/entity.service.utils';
import { Category } from '../../domains/training/category/category.model';
import { loadAllCategoriesIfNecessary } from '../../domains/training/category/category.state';
import {
  loadAllProductInstallationSubtypesClaimed
} from '../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.state';
import { loadAllProductInstallationSubtypesIfNecessary } from '../../domains/training/product-installation-subtypes/product-installation-subtypes.state';
import { ProductInstallationTypeClaimed } from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import { loadAllProductInstallationTypesClaimed } from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.state';
import { ProductInstallationType } from '../../domains/training/product-installation-types/product-installation-types.model';
import { loadAllProductInstallationTypesIfNecessary } from '../../domains/training/product-installation-types/product-installation-types.state';
import { loadAllTrainingsIfNecessary } from '../../domains/training/training/training.state';
import { loadAllTrainingsClaimed } from '../../domains/training/trainings-claimed/trainings-claimed.state';
import { User } from '../../domains/users';
import { loadKudosReason } from '../../domains/users/kudos-reason/kudos-reason.state';
import { loadAllUserFiles } from '../../domains/users/user-files/user-files.state';
import { closeInstallerProfileEnhancedModal } from '../../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.actions';
import { InstallerProfileUiService } from './installer-profile-ui.service';
import { showEnhancedInstallerProfile } from './installer-profile.actions';

@Injectable()
export class InstallerProfileEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly ui: InstallerProfileUiService,
    private readonly store: Store,
  ) {}

  loadInstallerKudosReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) =>
        loadKudosReason({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: installer.id,
            },
          },
        }),
      ),
    ),
  );

  loadInstallerProductInstallationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) => loadAllProductInstallationTypesIfNecessary({})),
    ),
  );

  loadInstallerProductInstallationTypeClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) =>
        loadAllProductInstallationTypesClaimed({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: installer.id,
            },
          },
        }),
      ),
    ),
  );



  loadInstallerProductInstallationSubtypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) => loadAllProductInstallationSubtypesIfNecessary({
        criteria: {
          parents: {
            [uriNameOfEntityOrEmpty(ProductInstallationType)]: EmptyKey,
          },
        },
      })),
    ),
  );


  loadInstallerProductInstallationSubtypeClaims$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) =>
        loadAllProductInstallationSubtypesClaimed({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: installer.id,
            },
          },
        }),
      ),
    ),
  );

  loadInstallerComplianceFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) =>
        loadAllUserFiles({
          criteria: {
            parents: {
              installers: installer.id,
            },
          },
        }),
      ),
    ),
  );

  loadTrainings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) =>
        loadAllTrainingsIfNecessary({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(ProductInstallationType)]: EmptyKey,
              [uriNameOfEntityOrEmpty(Category)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  loadTrainingsClaimed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) =>
        loadAllTrainingsClaimed({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: installer.id,
              [uriNameOfEntityOrEmpty(ProductInstallationTypeClaimed)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      map(({ installer }) =>
        loadAllCategoriesIfNecessary({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(ProductInstallationType)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  showInstallerProfileModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showEnhancedInstallerProfile),
        exhaustMap(() => this.ui.showInstallerProfileModal()),
      ),
    { dispatch: false },
  );

  closeInstallerProfileModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeInstallerProfileEnhancedModal),
        tap(() => this.ui.closeInstallerProfileModal()),
      ),
    { dispatch: false },
  );
}
