import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DetailedProductInstallationTypeClaimed,
  InstallationTypeSubtypesClaimed,
} from '../../../../core/installer-profile';
import { ProductInstallationSubtypesClaimed } from '../../../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import { ProductInstallationType } from '../../../../domains/training/product-installation-types/product-installation-types.model';

@Component({
  selector: 'gc-training-product-installation-subtypes',
  templateUrl: './training-product-installation-subtypes.component.html',
  styleUrls: ['./training-product-installation-subtypes.component.scss'],
})
export class TrainingProductInstallationSubtypesComponent {
  @Input() productInstallationSubtypes!: InstallationTypeSubtypesClaimed;
  @Input() claimedInstallType!: DetailedProductInstallationTypeClaimed;
  @Input() canEditProfile!: boolean;

  @Output() subtypeAdded = new EventEmitter<ProductInstallationType>();
  @Output() subtypeRemoved = new EventEmitter<ProductInstallationSubtypesClaimed>();
}
