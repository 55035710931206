import { Pipe, PipeTransform } from '@angular/core';

export interface RGBObject {
  red: number;
  green: number;
  blue: number;
}

export const numToHex = (num: number) => (num < 16 ? '0' + num.toString(16) : num.toString(16));

export const fromRGBToHex = ({ red, green, blue }: RGBObject): string =>
  '#' + numToHex(red) + numToHex(green) + numToHex(blue);

@Pipe({ name: 'hexColor' })
export class HexColorPipe implements PipeTransform {
  transform(rgb: RGBObject): string {
    return 'color: ' + fromRGBToHex(rgb);
  }
}
