import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EntityService } from '../entity.service';
import { NegotiationItemFacade } from './negotiation-item/negotiation-item.facade';
import { NegotiationItem } from './negotiation-item/negotiation-item.model';
import { NegotiationUIService } from './negotiation-ui.service';
import { NegotiationFacade } from './negotiation/negotiation.facade';
import { Negotiation } from './negotiation/negotiation.model';
import { NegotiationsEffects } from './negotiations.effects';
import { NegotiationsFacade } from './negotiations.facade';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([NegotiationsEffects])],
  providers: [
    NegotiationsFacade,
    NegotiationFacade,
    NegotiationItemFacade,
    NegotiationUIService,
    { provide: Negotiation, useClass: EntityService },
    { provide: NegotiationItem, useClass: EntityService },
  ],
})
export class NegotiationsModule {}
