import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, first, tap } from 'rxjs/operators';
import { AuthFacade } from '../../../domains/app/auth/auth.facade';
import { StateFacade } from '../../../domains/locations';
import { PhoneNumberFacade } from '../../../domains/phone-number';
import { AnyUser } from '../../../domains/users';
import { ProfileFacade } from '../../../domains/users/profile/profile.facade';
import { filterUntilPredicatePassed } from '../../../shared/utils/operators';
import { InstallerProfileForm } from '../installer-profile.form';

const STEP_TO_VALIDATORS: Record<number, keyof InstallerProfileForm> = {
  1: 'enableBasicValidators',
  2: 'disableValidators',
  3: 'enableInsuranceValidators',
  4: 'enableTaxValidators',
  5: 'enableValidators',
};

@Component({
  selector: 'gc-installer-profile',
  templateUrl: './installer-profile-modal.component.html',
  styleUrls: ['./installer-profile-modal.component.scss'],
})
export class InstallerProfileModalComponent implements OnInit {
  @Input() step = 1;
  form!: InstallerProfileForm;
  phoneNumberForm!: UntypedFormGroup;
  validated = false;

  constructor(
    public readonly auth: AuthFacade,
    public readonly profile: ProfileFacade,
    public readonly states: StateFacade,
    public readonly phoneNumbers: PhoneNumberFacade,
    private readonly modalGC: ModalController,
  ) {}

  ngOnInit() {
    this.form = new InstallerProfileForm();
    this.phoneNumberForm = new UntypedFormGroup({
      number: new UntypedFormControl(null, Validators.required),
      type: new UntypedFormControl('MOBILE', Validators.required),
    });

    combineLatest([this.profile.currentEditedUser$])
      .pipe(
        filterUntilPredicatePassed(([edited]) => !!edited),
        distinctUntilChanged(),
        tap(([edited]) => (edited ? this.form.updateModel(edited as unknown as AnyUser) : void 0)),
        filter(edited => !edited),
        first(),
      )
      .subscribe(() => this.modalGC.dismiss());
  }

  back() {
    if (this.step === 1) {
      return;
    }

    this.validated = true;
    this.step = this.step - 1;
    if (this.step === 2) {
      this.step = this.step - 1;
    }
    this.form[STEP_TO_VALIDATORS[this.step]]();
    this.validated = false;
  }

  cancel() {
    this.profile.cancelEditProfile(this.form.dirty);
  }

  next() {
    this.validated = true;
    if (this.step === 5) {
      return;
    }
    this.step = this.step + 1;
    if (this.step === 2) {
      this.step = this.step + 1;
    }
    this.form[STEP_TO_VALIDATORS[this.step]]();
    this.validated = false;
  }

  save() {
    this.form[STEP_TO_VALIDATORS[this.step]]();
    this.validated = true;
    this.profile.saveProfile(this.form.updatedModel);
  }
}
