import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { EmptyListWarningModule } from '../empty-list-warning/empty-list-warning.module';
import { FileDropTargetModule } from '../file-drop-target/file-drop-target.module';
import { FileManagerComponent } from './file-manager.component';

@NgModule({
  declarations: [FileManagerComponent],
  imports: [CommonModule, IonicModule, PipesModule, DirectivesModule, EmptyListWarningModule, PushModule, FileDropTargetModule],
  exports: [FileManagerComponent],
})
export class FileManagerModule {}
