import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { PipesModule } from '../../pipes/pipes.module';
import { HammerRatingModule } from '../hammer-rating/hammer-rating.module';
import { UserCompletedWorkComponent } from './user-completed-work/user-completed-work.component';
import { UserHeaderComponent } from './user-header.component';
import { UserRatingComponent } from './user-rating/user-rating.component';

@NgModule({
  declarations: [UserHeaderComponent, UserCompletedWorkComponent, UserRatingComponent],
  imports: [CommonModule, IonicModule, HammerRatingModule, PipesModule, NgxTippyModule],
  exports: [UserHeaderComponent, UserCompletedWorkComponent, UserRatingComponent],
})
export class UserHeaderModule {}
