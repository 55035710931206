import { Component, Input } from '@angular/core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { User } from '../../../../domains/users';

@Component({
  selector: 'gc-user-profile-avatar',
  templateUrl: './user-profile-avatar.component.html',
  styleUrls: ['./user-profile-avatar.component.scss'],
})
export class UserProfileAvatarComponent {
  @Input() user!: User;
  @Input() centered = true;
  @Input() isPrivate = false;
  protected readonly faLock = faLock;

  constructor(private readonly store: Store) {}
}
