import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { selectedArea } from '../../modals/view-installer-profile-enhanced/view-installer-profile-enhanced.selectors';


@Pipe({name: 'isSelectedArea'})
export class IsSelectedAreaPipe implements PipeTransform {
  selectedArea$ = this.store.select(selectedArea);

  constructor(private store: Store) {}

  transform(area: string) {
    return this.selectedArea$.pipe(map(selectedArea => selectedArea === area));
  }
}
