import { Pipe, PipeTransform } from '@angular/core';
import { STATUS_GROUP_LABEL_MAP } from '../../domains/work/work.maps';
import { StatusGroup } from '../../domains/work/work.state';

@Pipe({ name: 'statusGroupLabel' })
export class StatusGroupLabelPipe implements PipeTransform {
  transform(status: StatusGroup): string {
    return STATUS_GROUP_LABEL_MAP[status];
  }
}
