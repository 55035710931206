import { Injectable } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { NetworkConnectionModalComponent } from '../../modals/network-connection/network-connection-modal/network-connection-modal.component';

@Injectable()
export class NetworksUIService {
  constructor(
    private modals: ModalController,
    private toasts: ToastController,
    private alerts: AlertController,
  ) {}

  async showNetworkConnectionModal() {
    const modal = await this.modals.create({
      component: NetworkConnectionModalComponent,
      cssClass: 'rounded-modal',
    });
    await modal.present();
    return await modal.onDidDismiss();
  }

  async toastResult(message: string, error = false) {
    const toast = await this.toasts.create({
      message,
      buttons: [
        {
          icon: error ? 'close' : 'checkmark-circle',
        },
      ],
      color: error ? 'danger' : 'dark',
      duration: 5000,
      position: 'bottom',
    });
    await toast.present();
    return toast.onDidDismiss();
  }

  // TODO:: Refactor Usages to AppUIService
  async confirmAcceptance({ message, header }: { message?: string; header?: string }): Promise<any> {
    const alert = await this.alerts.create({
      message: message ?? 'Are you sure you want to accept? This cannot be undone.',
      header: header ?? 'Accept',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Accept',
          role: 'ok',
          cssClass: 'ion-color-gc-green',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }
}
