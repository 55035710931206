import { Component, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { InstallerTeamLead } from '../../../domains/users';
import { ConnectedInstallerTeamLead } from '../../../domains/users/models/connected-installer-team-lead.model';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';
import { Selectable } from '../../../shared/utils/types';
import { publishSearchCriteria, selectedInstallerIds } from '../store/ui.selectors';
import {
  connectionSelectionChanged,
  connectionSelectionCleared,
  connectionsSearchCriteriaChanged,
  connectionsSearchCriteriaCleared,
} from './publish-to-connections.actions';
import { selectableFilteredConnections } from './publish-to-connections.selectors';

@Component({
  selector: 'gc-publish-to-connections-modal',
  templateUrl: './publish-to-connections-modal.component.html',
  styleUrls: ['./publish-to-connections-modal.component.scss'],
})
export class PublishToConnectionsModalComponent {
  // @Input() installers!: InstallerTeamLead[];
  @Input() prefilled!: InstallerTeamLead[];
  @Input() workOrder!: Partial<WorkOrder>;

  installers$: Observable<Selectable<ConnectedInstallerTeamLead>[]> = this.store.select(selectableFilteredConnections);
  selected$ = this.store.select(selectedInstallerIds).pipe(tap(selected => (this.selected = selected)));
  criteria$ = this.store.select(publishSearchCriteria);

  selected: ConnectedInstallerTeamLead['id'][] = [];

  constructor(
    private readonly builder: UntypedFormBuilder,
    public readonly modalGC: ModalController,
    private readonly store: Store,
  ) {}

  async publish() {
    await this.modalGC.dismiss(this.selected, 'ok');
    this.clearSelection();
  }

  selectionChanged(selection: ConnectedInstallerTeamLead['id'][]) {
    this.store.dispatch(connectionSelectionChanged({ selection }));
  }

  clearSelection() {
    this.store.dispatch(connectionSelectionCleared());
  }

  search(criteria: string) {
    this.store.dispatch(connectionsSearchCriteriaChanged({ searchCriteria: criteria }));
  }

  searchCleared() {
    this.store.dispatch(connectionsSearchCriteriaCleared());
  }
}
