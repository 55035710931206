import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'MatchedInstaller',
  uriName: 'matched-installers',
})
export class MatchedInstaller {
  @Key id?: number;
  installerId!: string;
  workOrderId!: string;
  response!: InstallerResponse;
  chatChannelId?: string;
}

export enum InstallerResponse {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Declined = 'DECLINED',
  Negotiating = 'NEGOTIATING',
}
