import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkOrder } from '../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-chat-context-warning',
  templateUrl: './chat-context-warning.component.html',
  styleUrls: ['./chat-context-warning.component.scss'],
})
export class ChatContextWarningComponent {
  closed = false;
  workOrder?: WorkOrder;

  @Input() set unmatchedWorkOrder(workOrder: WorkOrder | undefined) {
    this.workOrder = workOrder;
    this.closed = false;
  }

  @Output() switch = new EventEmitter<WorkOrder>();
}
