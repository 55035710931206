import { createSelector } from '@ngrx/store';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { DetailedJumpstarterFavorite } from '../models/detailed-jumpstarter-favorite.model';
import { InstallerTeamLead, User } from '../user/user.model';
import { usersTeamLead } from '../user/user.selectors';
import { currentUser, userEntities } from '../user/user.state';
import { JumpstarterFavorite } from './jumpstarter-favorite.model';
import { selectAllJumpstarterFavorites } from './jumpstarter-favorites.state';

export const jumpstarterFavoriteForCurrentUser = createSelector(
  selectAllJumpstarterFavorites,
  currentUser,
  (favorites: JumpstarterFavorite[], user?: User): JumpstarterFavorite | undefined =>
    !!user
      ? favorites.find(favorite => favorite.jumpstarterId === user.id || favorite.installerTeamLeadId === user.id)
      : undefined,
);

export const jumpstarterFavoritesForAuthenticateUser = createSelector(
  selectAllJumpstarterFavorites,
  authenticatedUser,
  (favorites: JumpstarterFavorite[], user?: User): JumpstarterFavorite[] =>
    !!user
      ? favorites.filter(favorite => favorite.jumpstarterId === user.id || favorite.installerTeamLeadId === user.id)
      : [],
);

export const detailedFavoritesForAuthenticatedUser = createSelector(
  jumpstarterFavoritesForAuthenticateUser,
  userEntities,
  (favorites, users): DetailedJumpstarterFavorite[] =>
    favorites.map(favorite => ({
      ...favorite,
      installerTeamLead: users[favorite.installerTeamLeadId] as InstallerTeamLead,
    })),
);

export const favoritesInstallerTeamLeads = createSelector(detailedFavoritesForAuthenticatedUser, detailedFavorites =>
  detailedFavorites.map(fav => fav.installerTeamLead),
);

export const jumpstarterFavoriteForUsersTeamLead = createSelector(
  selectAllJumpstarterFavorites,
  usersTeamLead,
  (favorites: JumpstarterFavorite[], teamLead?: InstallerTeamLead): JumpstarterFavorite | undefined =>
    favorites.find(fav => fav.installerTeamLeadId === teamLead?.id),
);
