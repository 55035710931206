import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedProductInstallationTypeClaimed } from '../../../../../core/installer-profile';
import { ProductInstallationTypeClaimed } from '../../../../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';

@Component({
  selector: 'gc-empty-product-installation-type',
  templateUrl: './empty-product-installation-type.component.html',
  styleUrls: ['./empty-product-installation-type.component.scss'],
})
export class EmptyProductInstallationTypeComponent {
  @Input() types?: DetailedProductInstallationTypeClaimed[];
  @Input() selectedProductInstallationTypeClaim?: DetailedProductInstallationTypeClaimed;
  @Input() canEditProfile?: boolean;

  @Output() removed = new EventEmitter<ProductInstallationTypeClaimed>();
}
