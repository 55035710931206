import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { ensureRequired } from '../../../shared/utils/rxjs';
import { viewWorkOrder } from '../work-order-ui.actions';
import { WorkOrder } from '../work-order/work-order.model';
import { currentWorkOrder, workOrderDeselected } from '../work-order/work-order.state';
import { workOrderRefreshIntervalTicked } from '../work-polling.actions';
import { clearWorkOrderTags, loadAllWorkOrderTags } from './work-order-tag.state';

@Injectable()
export class WorkOrderTagEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  loadWorkOrderTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrder),
      map(({ workOrder }) =>
        loadAllWorkOrderTags({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            },
          },
        }),
      ),
    ),
  );

  unloadWorkOrderTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderDeselected),
      map(() => clearWorkOrderTags()),
    ),
  );

  reloadWorkOrderTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderRefreshIntervalTicked),
      withLatestFrom(this.store.select(currentWorkOrder)),
      map(([, workOrder]) => workOrder),
      ensureRequired(workOrder => !!workOrder?.id),
      map(workOrder =>
        loadAllWorkOrderTags({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
            },
          },
        }),
      ),
    ),
  );
}
