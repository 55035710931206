import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  takeUntil,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { WorkOrderDate } from '~gc/core/calendar';
import { AppUIService } from '~gc/domains/app-ui.service';
import { CustomDate } from '~gc/domains/custom-dates';
import { LienReleaseTemplateFacade } from '~gc/domains/invoices/lien-release-template/lien-release-template.facade';
import { FacilityTypeFacade, StateFacade } from '~gc/domains/locations';
import { ProductInstallationTypeFacade } from '~gc/domains/training/product-installation-types/product-installation-types.facade';
import { UnitOfMeasureFacade } from '~gc/domains/unit-of-measure/unit-of-measure.facade';
import { WorkOrderEditFacade } from '~gc/domains/work/work-order-edit/work-order-edit.facade';
import { WorkOrderFilesFacade } from '~gc/domains/work/work-order-files/work-order-files.facade';
import { WorkOrderItemFacade } from '~gc/domains/work/work-order-item/work-order-item.facade';
import { WorkOrderFacade } from '~gc/domains/work/work-order/work-order.facade';
import { workOrderIsStatus, WorkOrderStatus } from '~gc/domains/work/work-order/work-order.model';
import { WorkFacade } from '~gc/domains/work/work.facade';
import { filterUntilPredicatePassed } from '~gc/shared/utils/operators';
import { setBODTime, setEODTime } from '../../../domains/custom-dates/custom-date/custom-date.model';
import {
  editingWorkOrderDateAdded,
  editingWorkOrderDateDeleted,
  editingWorkOrderDateSaved,
} from '../add-edit-work-order.actions';
import { editedWorkOrderDatesSorted } from '../add-edit-work-order.selectors';
import { WorkOrderForm } from '../draft-work-order.form';

@Component({
  selector: 'gc-add-edit-work-order-modal',
  templateUrl: './add-edit-work-order-modal.component.html',
  styleUrls: ['./add-edit-work-order-modal.component.scss'],
})
export class AddEditWorkOrderModalComponent implements OnInit {
  @Input() modal!: HTMLIonModalElement;
  form!: WorkOrderForm;
  hasReset = false;
  isSaving$: Observable<boolean>;
  isStepInitializing$$ = new Subject<boolean>();
  isStepInitializing$ = this.isStepInitializing$$.asObservable().pipe(distinctUntilChanged());

  constructor(
    public facilityTypes: FacilityTypeFacade,
    public productInstallationTypes: ProductInstallationTypeFacade,
    public lienReleaseTemplates: LienReleaseTemplateFacade,
    public states: StateFacade,
    public unitOfMeasures: UnitOfMeasureFacade,
    public work: WorkFacade,
    public workOrderItems: WorkOrderItemFacade,
    public workOrders: WorkOrderFacade,
    public workOrderEdit: WorkOrderEditFacade,
    public workOrderFiles: WorkOrderFilesFacade,
    private appUI: AppUIService,
    private readonly store: Store,
  ) {
    this.isSaving$ = workOrders.isSaving$.pipe(throttleTime(3500, undefined, { leading: true, trailing: true }));
  }

  stepInitialized() {
    this.isStepInitializing$$.next(true);
  }

  stepReady() {
    this.isStepInitializing$$.next(false);
  }


  ngOnInit(): void {
    this.form = new WorkOrderForm();

    combineLatest([this.workOrders.edited$, this.workOrders.isAdding$])
      .pipe(
        filterUntilPredicatePassed(([edited]) => !!edited),
        distinctUntilChanged(),
        tap(([edited]) => (edited ? this.form.updateModel(edited) : void 0)),
        // Used to reset the initial created name from the server.
        tap(([, isAdding]) => isAdding && !this.hasReset && (this.form.publicJobName.reset(), (this.hasReset = true))),
        filter(([edited]) => !edited),
        first(),
      )
      .subscribe(() => this.modal.dismiss());

    combineLatest([this.workOrders.edited$, this.workOrderFiles.allWorkOrderFiles$])
      .pipe(
        filterUntilPredicatePassed(([edited]) => !!edited),
        distinctUntilChanged(
          ([editedX, filesX], [editedY, filesY]) =>
            // tslint:disable-next-line:triple-equals
            JSON.stringify(editedX) == JSON.stringify(editedY) && JSON.stringify(filesX) == JSON.stringify(filesY),
        ),
        tap(
          ([edited, files]) =>
            edited &&
            this.form.updateModel({
              ...this.form.updatedModel,
              files,
            }),
        ),
        filter(([edited]) => !edited),
        first(),
      )
      .subscribe();

    combineLatest([this.workOrders.edited$, this.store.select(editedWorkOrderDatesSorted)])
      .pipe(
        filterUntilPredicatePassed(([edited]) => !!edited),
        filterUntilPredicatePassed(([, dates]) => !!dates?.length),
        distinctUntilChanged(
          ([editedX, datesX], [editedY, datesY]) =>
            // tslint:disable-next-line:triple-equals
            JSON.stringify(editedX) == JSON.stringify(editedY) && JSON.stringify(datesX) == JSON.stringify(datesY),
        ),
        tap(([edited, dates]) => !!edited && this.form.dates.patchValue(dates, { truncate: !!this.form.dates.length })),
        filter(([edited]) => !edited),
        first(),
      )
      .subscribe();

    const saveOnEdit$ = this.form.valueChanges.pipe(
      debounceTime(10),
      // tslint:disable-next-line:triple-equals
      distinctUntilChanged((x, y) => JSON.stringify(x) == JSON.stringify(y)),
      filter(() => this.form.isDraftValid),
      withLatestFrom(this.workOrders.edited$),
      tap(([edited]) => edited && this.workOrders.change(this.form.updatedModel)),
      takeUntil(from(this.modal.onWillDismiss())),
    );

    workOrderIsStatus(this.form.updatedModel, WorkOrderStatus.Draft) ? saveOnEdit$.subscribe() : void 0;
  }

  async cancelAddEdit() {
    if (this.form.isDraftValid) {
      this.workOrderEdit.cancelEditWorkOrder(this.form.updatedModel);
    }

    if (!this.form.isDraftValid) {
      const confirm = await this.appUI.confirm(
        'Delete Work Order Draft?',
        'Are you sure you want to delete this Work Order Draft?',
        { confirmText: 'Delete' },
      );
      if (confirm) {
        this.workOrderEdit.deleteWorkOrderDraft(this.form.updatedModel);
      }
    }
  }

  save() {
    if (!this.form.isDraftValid) {
      return;
    }
    this.workOrderEdit.savePendingWorkOrder(this.form.updatedModel);
  }

  publish() {
    if (!this.form.isValid) {
      this.workOrderEdit.warnInvalid(this.form.orderedErrors);
      return;
    }

    this.workOrderEdit.publish(this.form.updatedModel);
  }

  republish() {
    if (!this.form.isValid) {
      this.workOrderEdit.warnInvalid(this.form.orderedErrors);
      return;
    }

    this.workOrderEdit.republish(this.form.updatedModel);
  }

  back() {
    if (this.form.isAddingItem) {
      return this.form.workingItem.isValid;
    }

    this.workOrderEdit.goBackAnEditStep();
    return true;
  }

  next() {
    if (this.form.isAddingItem) {
      return this.form.workingItem.isValid;
    }

    this.workOrderEdit.nextEditStep(this.form.updatedModel);
    return true;
  }

  addDate(date: WorkOrderDate): void {
    this.store.dispatch(
      editingWorkOrderDateAdded({
        date: {
          ...date,
          startDate:
            typeof date.startDate === 'string'
              ? date.endDate
              : setBODTime((date.startDate as unknown as Date).toISOString()),
          endDate:
            typeof date.endDate === 'string'
              ? date.endDate
              : setEODTime((date.endDate as unknown as Date).toISOString()),
        },
      }),
    );
  }

  saveDate(date: WorkOrderDate): void {
    this.store.dispatch(
      editingWorkOrderDateSaved({
        date: {
          ...date,
          startDate:
            typeof date.startDate === 'string'
              ? date.endDate
              : setBODTime((date.startDate as unknown as Date).toISOString()),
          endDate:
            typeof date.endDate === 'string'
              ? date.endDate
              : setEODTime((date.endDate as unknown as Date).toISOString()),
        },
      }),
    );
  }

  deleteDate(date: CustomDate): void {
    this.store.dispatch(
      editingWorkOrderDateDeleted({
        date: {
          ...date,
          startDate: setBODTime(date.startDate),
          endDate: setEODTime(date.endDate),
        },
      }),
    );
  }
}
