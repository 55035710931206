<ion-list
  *ngIf="
    !!group?.title &&
    !!group.items?.length &&
    (displayGroups | itemGroupsAllowed: 'change-add' : 'installer-add') &&
    (permissions | hasPermissions: 'work-order.change-order-items:view')
  "
  style="padding: 0"
>
  <ion-list-header data-cy="change-item-title" [color]="group.baseColor">
    <h5 class="ion-text-uppercase" style="margin: 0; align-self: center">
      <ion-text color="light">{{ group.title }}</ion-text>
    </h5>
  </ion-list-header>
</ion-list>
