import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { Country } from './country.model';

export const {
  initialState: initialCountryState,
  facade: CountryFacadeBase,
  selectors: { selectAll: allCountries },
} = buildState(Country);

const reduce = createReducer(initialCountryState);

export function countryReducer(state = initialCountryState, action: Action): IEntityState<Country> {
  return reduce(state, action);
}
