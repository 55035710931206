<ng-container
  *ngIf="
    (group | typeIsIn: 'negotiable') &&
    (displayGroups | itemGroupsAllowed: 'negotiable') &&
    (permissions | hasPermissions: 'work-order.item.price:view')
  "
>
  <ion-text [color]="item | negotiablePriceColor">
    <strong data-cy="change-order-item-total">{{ item.pricePerUnit * item.numberOfUnits | currency }}</strong>
  </ion-text>
</ng-container>
