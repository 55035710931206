<ion-list class="ion-padding">
  <ion-item
    *ngIf="workOrderChat"
    (click)="selected.emit(workOrderChat.channel)"
    button
    lines="none"
    [ngClass]="{ 'current-channel': isCurrent }"
  >
    <stream-avatar
      slot="start"
      [imageUrl]="workOrderChat.users[0]?.profilePhoto"
      [name]="workOrderChat.channel.data!.name"
      [size]="32"
    ></stream-avatar>
    <ion-label color="dark">
      <h3
        *ngIf="workOrderChat.users[0]?.firstName && workOrderChat.users[0]?.lastName">{{ workOrderChat.users[0].firstName }} {{ workOrderChat.users[0].lastName }}</h3>
      <p *ngIf="workOrderChat.channel.data!.name">{{ workOrderChat.channel.data!.name }}</p>
      <ng-content></ng-content>
    </ion-label>
    <ion-badge slot="end" color="gc-green" *ngIf="unread$ | push as unread">{{ unread }}</ion-badge>
  </ion-item>
</ion-list>
