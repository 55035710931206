<ion-list lines="none" *ngIf="color$ | push as color">
  <ion-item>
    <ion-label> Choose a color. This will be shown on the Calendar and Scheduler.</ion-label>
    <ion-icon name="ellipse" slot="end" [style]="color | hexColor"></ion-icon>
  </ion-item>
  <ion-item>
    <ion-range (ionChange)="updateColor($event, 'red')" [value]="color.red" min="1" max="255">
      <ion-icon
        icon="ellipse"
        slot="end"
        size="small"
        [style]="{ red: color.red, green: 0, blue: 0 } | hexColor"
      ></ion-icon>
    </ion-range>
  </ion-item>
  <ion-item>
    <ion-range (ionChange)="updateColor($event, 'green')" [value]="color.green" min="1" max="255">
      <ion-icon
        icon="ellipse"
        slot="end"
        size="small"
        [style]="{ red: 0, green: color.green, blue: 0 } | hexColor"
      ></ion-icon>
    </ion-range>
  </ion-item>
  <ion-item>
    <ion-range (ionChange)="updateColor($event, 'blue')" [value]="color.blue" min="1" max="255">
      <ion-icon
        icon="ellipse"
        slot="end"
        size="small"
        [style]="{ red: 0, green: 0, blue: color.blue } | hexColor"
      ></ion-icon>
    </ion-range>
  </ion-item>
</ion-list>
