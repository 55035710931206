import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedInstaller } from '~gc/domains/models/detailed-installer';
import { NegotiableWorkOrderItem } from '~gc/domains/models/negotiable-work-order-item';

@Component({
  selector: 'gc-negotiable-item-list',
  templateUrl: './negotiable-item-list.component.html',
  styleUrls: ['./negotiable-item-list.component.scss'],
})
export class NegotiableItemListComponent {
  @Input() showTotals!: boolean;
  @Input() showNegotiations!: boolean;
  @Input() items!: NegotiableWorkOrderItem[];
  @Output() reviewed = new EventEmitter<DetailedInstaller>();
  expandedNegotiationItem?: NegotiableWorkOrderItem;
}
