import { Injectable, Type } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { Components } from '@ionic/core';

const sheetModalBaseOptions: Partial<Components.IonModal> = {
  backdropBreakpoint: 0.3,
  initialBreakpoint: 0.5,
  breakpoints: [0, 0.3, 0.5, 0.8],
  handle: true,
};

const sheetModalDesktopOptions: Partial<Components.IonModal> = {
  breakpoints: undefined,
  initialBreakpoint: undefined,
  backdropBreakpoint: undefined,
  handle: false,
};

@Injectable({ providedIn: 'root' })
export class ModalService {
  constructor(private readonly modals: ModalController) {}

  // Only supports options for breakpoints currently.
  openSheetModal<T extends IonModal>(modalGC: T, options?: Partial<Components.IonModal>): Promise<T>;
  openSheetModal(modalGC: Type<any>, options?: Partial<Components.IonModal>): Promise<HTMLIonModalElement>;
  async openSheetModal(modalGC: IonModal | Type<any>, options: Partial<Components.IonModal> = sheetModalBaseOptions) {
    const resolvedOptions: Partial<Components.IonModal> =
      window.innerWidth < 960 ? { ...sheetModalBaseOptions, ...options } : { ...options, ...sheetModalDesktopOptions };

    if (modalGC instanceof IonModal) {
      modalGC.breakpoints = resolvedOptions.breakpoints;
      modalGC.initialBreakpoint = resolvedOptions.initialBreakpoint;
      if (resolvedOptions.backdropBreakpoint) {
        modalGC.backdropBreakpoint = resolvedOptions.backdropBreakpoint;
      }
      modalGC.showBackdrop = resolvedOptions.showBackdrop ?? false;
      modalGC.handle = resolvedOptions.handle;
      await modalGC.present();
      return modalGC;
    } else {
      const instance = await this.modals.create({
        component: modalGC,
        ...resolvedOptions,
      });
      await instance.present();
      return instance;
    }
  }

  async open(modalGC: Type<any>, options?: Partial<Components.IonModal>): Promise<HTMLIonModalElement> {
    const instance = await this.modals.create({
      component: modalGC,
      ...options,
    });
    await instance.present();
    return instance;
  }
}
