import { createAction, props } from '@ngrx/store';
import { EmployeeInstaller } from '../../../domains/users';

export const employeeSearchCriteriaChanged = createAction(
  '[MODAL: Publish to Employees] (Search Criteria) Changed',
  props<{ searchCriteria: string }>(),
);

export const employeeSearchCriteriaCleared = createAction('[MODAL: Publish to Employees] (Search Criteria) Cleared');

export const employeeSelectionChanged = createAction(
  '[MODAL: Publish to Employees] (Selection) Changed',
  props<{ selection: EmployeeInstaller['id'][] }>(),
);

export const employeeSelectionCleared = createAction('[MODAL: Publish to Employees] (Selection) Cleared');
