import { Component, Input } from '@angular/core';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';

@Component({
  selector: 'gc-public-price-display',
  templateUrl: './public-price-display.component.html',
  styleUrls: ['./public-price-display.component.scss'],
})
export class PublicPriceDisplayComponent {
  @Input() permissions!: string[];
  @Input() item!: DetailedWorkOrderItem;
}
