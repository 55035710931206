<ion-input
  #fileInput
  type="file"
  [hidden]="true"
  (change)="addFiles($event)"
  multiple="false"
  data-cy="installer-editor-profile-picture-file-input"
></ion-input>
<ion-grid class="flex flex-column ion-align-items-center">
  <div
    id="metal-card-wrapper"
    *ngIf="(auth.role$ | push | isRole: 'installerLead') && (auth!.user$ | push | isInstallerApproved)"
  >
    <ion-img src="assets/images/logo-reverse.svg" class="logo"></ion-img>
    <ion-avatar class="pointable" (click)="chooseFiles()">
      <img [src]="pendingBase64 || form.profilePhoto.value" alt="{{ user!.firstName }} {{ user!.lastName }}" />
    </ion-avatar>
    <ion-img src="assets/images/logos/go-carrera/card-sponsors.svg" class="card-sponsors"></ion-img>
    <div class="public-profile-qr-code-wrapper pointable" (click)="browser.openUrl(publicProfileUrl)">
      <qrcode [qrdata]="publicProfileUrl" margin="1" elementType="img"></qrcode>
    </div>
  </div>
  <ion-row
    *ngIf="
      (auth.role$ | push | isRole: 'companyAdmin' : 'companyManager') || !(auth.user$ | push | isInstallerApproved)
    "
  >
    <ion-col class="center-column-content">
      <img
        *ngIf="form.profilePhoto.value && !pendingFile"
        alt="Profile Photo"
        [src]="pendingBase64 || form.profilePhoto.value"
      />
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <ion-button class="ion-margin-horizontal" color="light" expand="block" mode="ios" (click)="chooseFiles()">
        <ion-icon name="add" slot="start"></ion-icon>
        Choose a Photo
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
