<div class="blue-caret" *ngIf="(item | hasChangeOrders) && item.id === expanded?.id">
  <ion-card color="gc-blue" style="margin-top: -1px; border: none; box-shadow: none; border-radius: 10px">
    <ion-card-header>
      <ion-toolbar color="gc-blue">
        <ion-title slot="start" style="padding-left: 0; text-align: left; font-size: 1.5em"
          ><strong>Change Orders</strong> <br />
          <span data-cy="change-order-expanded-total" style="font-size: 20px; display: block; opacity: 0.75">
            Total of ({{ item.uninvoicedChangeInfo.changedTotal | currency }})</span
          >
        </ion-title>
        <ion-buttons slot="end">
          <ion-button data-cy="close-change-order-item-expander" (click)="collapsed.emit()">
            <ion-icon slot="icon-only" ios="close-circle" md="close-circle"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-card-header>

    <ion-list *ngIf="(item | hasChangeOrders) && item.id === expanded?.id" class="py-0 negotiation-list">
      <ion-item
        *ngFor="let changeOrder of item.changeOrders; last as last; trackBy: trackChangeOrderBy"
        data-cy="change-order-expanded-item"
        [lines]="last ? 'none' : 'full'"
        class="bg-faded-blue"
      >
        <ion-row style="width: 100%">
          <ion-col size="6">
            <ion-label style="white-space: normal">
              <strong data-cy="expanded-change-order-item-name">{{ changeOrder.name }}</strong>
              <p class="current-ask">
                <strong data-cy="expanded-change-order-item-number-of-units">
                  {{ changeOrder.adjustedNumberOfUnits }}
                  <ng-container
                    *ngIf="permissions | hasPermissions: 'work-order.item.price:view'"
                    data-cy="expanded-change-order-item-price-per-unit"
                  >
                    @
                    {{ changeOrder.adjustedPricePerUnit | currency }}
                    <span *ngIf="!changeOrder.acceptedById"
                      >=
                      <ion-text color="danger" data-cy="expanded-change-order-item-total">
                        ({{ changeOrder.adjustedNumberOfUnits * changeOrder.adjustedPricePerUnit | currency }})
                      </ion-text>
                    </span>
                  </ng-container>
                </strong>
              </p>
            </ion-label>

            <ng-container
              slot="end"
              *ngIf="
                (userType | isRole: 'companyManager' : 'companyAdmin') &&
                (displayGroups | itemGroupsAllowed: 'original' : 'change-add') &&
                (permissions | hasPermissions: 'work-order.change-orders:view')
              "
            >
              <ion-text *ngIf="changeOrder.acceptedById" color="gc-green">
                <ion-icon name="checkmark-circle" size="medium" style="transform: translateY(2px)"></ion-icon>
                <span data-cy="expanded-change-order-item-accepted-icon">Accepted</span
                ><span *ngIf="changeOrder.acceptedById === 'system'">&nbsp;Automatically</span>
              </ion-text>
              <ion-text
                *ngIf="changeOrder | isChangeOrderStatus: ChangeOrderStatus.PAID : ChangeOrderStatus.INVOICED"
                color="gc-green"
                class="pl-5"
              >
                <ion-icon name="checkmark-circle" size="medium" style="transform: translateY(2px)"></ion-icon>
                <span data-cy="expanded-change-order-item-invoiced-icon">Invoiced</span>
              </ion-text>
              <ion-button
                *ngIf="!changeOrder.acceptedById && (permissions | hasPermissions: 'work-order.change-order:void')"
                data-cy="expanded-change-order-item-void-button"
                (click)="voided.emit(changeOrder)"
                color="danger"
                mode="ios"
              >
                Void Change Order
                <ion-icon slot="start" name="trash"></ion-icon>
              </ion-button>
            </ng-container>

            <!-- Installer Below -->
            <ng-container
              class="ion-no-margin"
              style="margin-top: 10px"
              slot="end"
              *ngIf="
                (userType | isRole: 'installerLead') &&
                (displayGroups | itemGroupsAllowed: 'original' : 'change-add') &&
                (permissions | hasPermissions: 'work-order.change-orders:view')
              "
            >
              <ion-text *ngIf="changeOrder.acceptedById" color="gc-green">
                <ion-icon name="checkmark-circle" size="medium" style="transform: translateY(2px)"></ion-icon>
                <span data-cy="expanded-change-order-item-accepted-icon">Accepted</span
                ><span *ngIf="changeOrder.acceptedById === 'system'">&nbsp;Automatically</span>
              </ion-text>
              <ion-text
                *ngIf="changeOrder | isChangeOrderStatus: ChangeOrderStatus.PAID : ChangeOrderStatus.INVOICED"
                color="gc-green"
                class="pl-5"
              >
                <ion-icon name="checkmark-circle" size="medium" style="transform: translateY(2px)"></ion-icon>
                <span data-cy="expanded-change-order-item-invoiced-icon">Invoiced</span>
              </ion-text>
              <ion-button
                *ngIf="!changeOrder.acceptedById && (permissions | hasPermissions: 'work-order.change-order:accept')"
                data-cy="expanded-change-order-item-accept-button"
                color="gc-blue"
                mode="ios"
                (click)="accepted.emit(changeOrder)"
              >
                Accept
              </ion-button>
              <ion-button
                *ngIf="!changeOrder.acceptedById && (permissions | hasPermissions: 'work-order.change-order:accept')"
                data-cy="expanded-change-order-item-call-project-manager-button"
                fill="clear"
                color="gc-blue"
                mode="ios"
                (click)="pmCalled.emit(workOrder)"
              >
                Call PM
              </ion-button>
            </ng-container>
          </ion-col>

          <ion-col size="6" class="flex flex-col justify-content-around align-items-end">
            <div *ngIf="!changeOrder.acceptedById">Pending Acceptance</div>
            <div *ngIf="!changeOrder.acceptedById && changeOrder.autoAcceptPiePiece.type === 's'" class="flex">
              <ion-badge data-cy="auto-accept-badge" class="p-4" color="danger" mode="ios">
                <fa-icon
                  [icon]="changeOrder.autoAcceptPiePiece.icon"
                  style="color: #fff"
                  transform="grow-5"
                  class="mr-5"
                ></fa-icon>
                Auto-accept in
                <ng-container *ngIf="changeOrder.autoAcceptHoursRemaining > 24; else hoursLeft"
                  >{{ changeOrder.autoAcceptDaysRemaining }} Day<span *ngIf="changeOrder.autoAcceptDaysRemaining !== 1"
                    >s</span
                  >
                </ng-container>
                <ng-template #hoursLeft
                  >{{ changeOrder.autoAcceptHoursRemaining }} Hour<span
                    *ngIf="changeOrder.autoAcceptHoursRemaining !== 1"
                    >s</span
                  ></ng-template
                >
              </ion-badge>
            </div>

            <div *ngIf="!changeOrder.acceptedById && changeOrder.autoAcceptPiePiece.type === 'dt'" class="flex">
              <ion-badge data-cy="auto-accept-time-badge" class="p-4" color="danger" mode="ios">
                <fa-duotone-icon
                  [icon]="changeOrder.autoAcceptPiePiece.icon"
                  primaryColor="#fff"
                  secondaryColor="#7f0000"
                  [primaryOpacity]="1"
                  [secondaryOpacity]="1"
                  transform="grow-5"
                  class="mr-5"
                ></fa-duotone-icon>
                Auto-accept in
                <ng-container *ngIf="changeOrder.autoAcceptHoursRemaining > 24; else hoursLeft"
                  >{{ changeOrder.autoAcceptDaysRemaining }} Day<span *ngIf="changeOrder.autoAcceptDaysRemaining !== 1"
                    >s</span
                  >
                </ng-container>
                <ng-template #hoursLeft
                  >{{ changeOrder.autoAcceptHoursRemaining }} Hour<span
                    *ngIf="changeOrder.autoAcceptHoursRemaining !== 1"
                    >s</span
                  ></ng-template
                >
              </ion-badge>
            </div>
            <div *ngIf="!!changeOrder.acceptedById">
              <ion-text
                [ngStyle]="
                  (changeOrder | isChangeOrderStatus: ChangeOrderStatus.PAID : ChangeOrderStatus.INVOICED)
                    ? { 'text-decoration': 'line-through' }
                    : undefined
                "
                data-cy="expaned-change-order-item-total-deduction"
                color="danger"
                style="font-weight: bold"
              >
                ({{ changeOrder.adjustedNumberOfUnits * changeOrder.adjustedPricePerUnit | currency }})
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-item>
      <ion-item class="change-summary" color="gc-dark-blue height-50">
        <ion-label style="white-space: normal">
          <h2>
            <strong>Total w/ Accepted Changes Remaining to be Invoiced</strong>
          </h2>
        </ion-label>
        <div slot="end" class="slotted-green slotted-box-right px-10">
          <span data-cy="expanded-change-order-total-with-accepted-changes-invoice" style="font-weight: bold">{{
            item.uninvoicedChangeInfo.amountToInvoice | currency
          }}</span>
        </div>
      </ion-item>
    </ion-list>
  </ion-card>
</div>
