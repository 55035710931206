<ng-container *ngIf="permissions | hasPermissions: 'work-order.change-orders:view'">
  <ng-container
    *ngIf="
      (userType | isRole: 'installerLead') &&
      (group | typeIsIn: 'change-add') &&
      (displayGroups | itemGroupsAllowed: 'change-add')
    "
  >
    <ion-button
      *ngIf="
        item.changeStatus === 'PENDING_ACCEPTANCE' && (permissions | hasPermissions: 'work-order.change-order:accept')
      "
      data-cy="accept-change-order-button"
      color="gc-blue"
      mode="ios"
      (click)="accepted.emit(item)"
    >
      Accept
    </ion-button>
    <ion-button
      *ngIf="
        item.changeStatus === 'PENDING_ACCEPTANCE' && (permissions | hasPermissions: 'work-order.change-order:decline')
      "
      data-cy="accept-change-order-button"
      color="danger"
      mode="ios"
      (click)="declined.emit(item)"
    >
      Decline
    </ion-button>
    <ion-button
      data-cy="call-project-manager-button"
      *ngIf="
        item.changeStatus === 'PENDING_ACCEPTANCE' &&
        (permissions | hasAnyPermission: 'work-order.change-order:accept' : 'work-order.change-order:decline')
      "
      fill="clear"
      color="gc-blue"
      mode="ios"
      (click)="called.emit(workOrder)"
    >
      Call PM
    </ion-button>
  </ng-container>

  <ng-container
    *ngIf="
      (userType | isRole: 'companyManager' : 'companyAdmin') &&
      (group | typeIsIn: 'installer-add') &&
      (displayGroups | itemGroupsAllowed: 'installer-add')
    "
  >
    <ion-button
      data-cy="approve-change-order-button"
      *ngIf="
        item.changeStatus === 'PENDING_ACCEPTANCE' && (permissions | hasPermissions: 'work-order.change-order:accept')
      "
      color="gc-blue"
      mode="ios"
      (click)="approved.emit(item)"
    >
      Accept
    </ion-button>
    <ion-button
      *ngIf="
        item.changeStatus === 'PENDING_ACCEPTANCE' && (permissions | hasPermissions: 'work-order.change-order:decline')
      "
      data-cy="accept-change-order-button"
      color="danger"
      mode="ios"
      (click)="declined.emit(item)"
    >
      Decline
    </ion-button>
  </ng-container>

  <ng-container
    *ngIf="
      (userType | isRole: 'companyManager' : 'companyAdmin' : 'installerLead') &&
      (group | typeIsIn: 'installer-add' : 'change-add') &&
      (displayGroups | itemGroupsAllowed: 'installer-add' : 'change-add')
    "
  >
    <ion-text *ngIf="item.changeStatus === 'DECLINED_WITH_REASON'">
      <ion-badge color="danger">
        {{ item.declinedById === userId ? 'You declined' : 'Declined'}}
        on {{ item.declinedAt | date: 'YYYY-MM-dd' }}
      </ion-badge>
      {{ item.declinedReason }}
    </ion-text>
  </ng-container>
</ng-container>
