import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { TermsAndConditions } from './terms-and-conditions.model';

export const {
  initialState: initialTermsAndConditionsState,

  selectors: { selectAll: allTermsAndConditions },

  facade: TermsAndConditionsFacadeBase,
} = buildState(TermsAndConditions);

export function termsAndConditionsReducer(state = initialTermsAndConditionsState): IEntityState<TermsAndConditions> {
  return state;
}
