import { createSelector } from '@ngrx/store';
import { get } from '~gc/shared/utils/func';
import { pageState } from './page.name';

export const uiState = createSelector(pageState, get('ui'));

export const schedulerOpen = createSelector(uiState, get('schedulerOpen'));

export const isWorkOrderPending = createSelector(uiState, get('isWorkOrderPending'));

export const areLineItemsPending = createSelector(uiState, get('areLineItemsPending'));

export const areFilesPending = createSelector(uiState, get('areFilesPending'));

export const areInvoicesPending = createSelector(uiState, get('areInvoicesPending'));
