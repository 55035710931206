import { Injectable } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { ViewInstallerProfileModalComponent } from '~gc/modals/view-installer-profile';
import { ViewJumpstarterProfileModalComponent } from '../../modals/view-jumpstarter-profile-modal/view-jumpstarter-profile-modal.component';
import { ViewProjectManagerProfileModalComponent } from '../../modals/view-project-manager-profile/view-project-manager-profile-modal/view-project-manager-profile-modal.component';
import { ReviewInstallerModalComponent } from '../../shared/components/installer-review/review-installer-modal/review-installer-modal.component';

@Injectable()
export class UsersUIService {
  private pmProfileModal?: HTMLIonModalElement;
  private installerProfileModal?: HTMLIonModalElement;
  private jumpstarterProfileModal?: HTMLIonModalElement;
  private reviewInstallerModal?: HTMLIonModalElement;

  constructor(
    private alerts: AlertController,
    private modals: ModalController,
    private toasts: ToastController,
  ) {}

  async showProjectManagerProfileModal(): Promise<void> {
    if (this.pmProfileModal) {
      return;
    }

    this.pmProfileModal = await this.modals.create({
      component: ViewProjectManagerProfileModalComponent,
      backdropDismiss: false,
      cssClass: 'rounded-modal',
    });

    this.pmProfileModal.onDidDismiss().then(() => {
      this.pmProfileModal = undefined;
    });

    await this.pmProfileModal.present();
  }

  async showInstallerProfileModal(): Promise<void> {
    if (this.installerProfileModal) {
      return;
    }

    this.installerProfileModal = await this.modals.create({
      component: ViewInstallerProfileModalComponent,
      backdropDismiss: false,
      cssClass: 'rounded-modal',
    });

    this.installerProfileModal.onDidDismiss().then(() => {
      this.installerProfileModal = undefined;
    });

    await this.installerProfileModal.present();
  }

  async showJumpstarterProfileModal(): Promise<void> {
    if (this.jumpstarterProfileModal) {
      return;
    }

    this.jumpstarterProfileModal = await this.modals.create({
      component: ViewJumpstarterProfileModalComponent,
      backdropDismiss: false,
      cssClass: 'rounded-modal',
    });

    this.jumpstarterProfileModal.onDidDismiss().then(() => {
      this.jumpstarterProfileModal = undefined;
    });

    await this.jumpstarterProfileModal.present();
  }

  closeProfileModal() {
    if (this.pmProfileModal) {
      return this.pmProfileModal.dismiss();
    }

    if (this.installerProfileModal) {
      return this.installerProfileModal.dismiss();
    }

    if (this.jumpstarterProfileModal) {
      return this.jumpstarterProfileModal.dismiss();
    }

    return Promise.resolve(false);
  }

  async showReviewInstallerModal() {
    if (this.reviewInstallerModal) {
      return Promise.resolve(void 0);
    }

    this.reviewInstallerModal = await this.modals.create({
      component: ReviewInstallerModalComponent,
      componentProps: {
        isJumpstarterOffer: true,
      },
      backdropDismiss: false,
      cssClass: 'fullscreen-modal rounded-modal',
    });

    await this.reviewInstallerModal.present();
    return this.reviewInstallerModal.onDidDismiss();
  }

  async closeReviewInstallerModal() {
    if (this.reviewInstallerModal) {
      return this.reviewInstallerModal.dismiss();
    }

    return Promise.resolve(void 0);
  }
}
