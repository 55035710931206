import * as fuzzy from 'fuzzy';

export const searchByCriteria =
  <T>(...props: Array<keyof T>) =>
  (items: T[], criteria?: string): T[] =>
    criteria
      ? items.filter(item =>
          fuzzy.test(
            criteria,
            props.reduce((searchStr, prop) => searchStr + ` ${item[prop]}`, ''),
          ),
        )
      : items;
