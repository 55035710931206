<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button
        data-cy="company-invite-cancel-button"
        slot="start"
        fill="clear"
        color="dark"
        mode="md"
        (click)="cancel()"
      >
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ type === 'INSTALLER_TEAM_LEAD' ? 'Invite ' + typeDisplay : 'Add ' + typeDisplay }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *rxLet="vm$; let vm; suspense: suspense;">
    <gc-company-invite-form *ngIf="!!vm" [view]="vm" [type]="type" [form]="form"></gc-company-invite-form>
  </ng-container>
  <ng-template #suspense>
    <div class="flex align-items-center justify-content-center">
      <fa-icon [icon]="faSpinner" [pulse]="false" [spin]="true" size="4x"></fa-icon>
    </div>
  </ng-template>
</ion-content>

<ion-footer class="ion-padding">
  <gc-company-invite-footer
    [view]="vm$ | push"
    [type]="type"
    [typeDisplay]="typeDisplay"
    [form]="form"
    [processing]="processing$ | push"
    (submitted)="submit($event)"
  ></gc-company-invite-footer>
</ion-footer>
