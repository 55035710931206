import { Injectable } from '@angular/core';
import { Create, DeleteByKey, Deselect, EntityAge, getKeyFromModel, uriNameOfEntityOrEmpty, } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { showEnhancedInstallerProfile } from '../../core/installer-profile';
import { InstallerProfileUiService } from '../../core/installer-profile/installer-profile-ui.service';
import { routeStartsWithPath } from '../../shared/utils/ngrx';
import { ensureChildrenRequired, ensureRequired } from '../../shared/utils/rxjs';
import { AppUIService } from '../app-ui.service';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout, updateAuthenticatedUser } from '../app/auth/auth.actions';
import { AuthFacade } from '../app/auth/auth.facade';
import { Address } from '../locations';
import { currentAddress, replaceAddress } from '../locations/address/address.state';
import { PhoneNumber } from '../phone-number/phone-number.model';
import { currentPhoneNumber } from '../phone-number/phone-number.state';
import { reviewInstallers } from '../work/installer-review/installer-review.actions';
import { addNewWorkOrder, editCurrentWorkOrder } from '../work/work-order-edit/work-order-edit.actions';
import { viewWorkOrder } from '../work/work-order-ui.actions';
import { ConnectionStatus } from './connection/connection.model';
import { replaceConnection } from './connection/connection.state';
import { reviewJumpstarterHireOffer } from './jumpstarter-favorites/jumpstarter-favorites.actions';
import { continueToNextProfileEditStep, saveInstallerProfile, updateWorkingUser } from './profile/profile.actions';
import { InstallerTeamLead, isInstallerLead, User } from './user/user.model';
import { deleteUser, deleteUserSuccess, loadAllUsersIfNecessary, loadUser, replaceUser, selectUser, } from './user/user.state';
import {
  awardedInstallerProfileShown,
  closeProfileModal,
  disconnectUser,
  removeUser,
  selectedEmployeeInstallerProfile,
  selectedInstallerTeamLeadProfile,
  selectedInvoicingInstallerProfile,
  showJumpstarterProfile,
  showProjectManagerProfile,
  skillsAndExperienceViewed,
} from './users-ui.actions';
import { UsersUIService } from './users-ui.service';


const LATENT_USER_AGE = 5 * EntityAge.Minute;

@Injectable()
export class UserEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private auth: AuthFacade,
    private store: Store,
    private usersUI: UsersUIService,
    private appUI: AppUIService,
    private installerProfileUI: InstallerProfileUiService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadUsersByLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      routeStartsWithPath('/app/'),
      map(() => loadAllUsersIfNecessary({ maxAge: LATENT_USER_AGE })),
    ),
  );

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        claimsAndTokenRetrieved,
        addNewWorkOrder,
        editCurrentWorkOrder,
        reviewInstallers,
        skillsAndExperienceViewed,
        viewWorkOrder,
      ),
      map(() => loadAllUsersIfNecessary({ maxAge: LATENT_USER_AGE })),
    ),
  );

  changeInstallerProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueToNextProfileEditStep),
      map(({ workingUser }) => updateWorkingUser({ workingUser })),
    ),
  );

  updateUserAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveInstallerProfile),
      filter(({ workingUser }) => !!workingUser.address),
      withLatestFrom(this.store.select(currentAddress)),
      map(([{ workingUser }, selectedAddress]) => ({ workingUser, selectedAddress })),
      ensureChildrenRequired(value => value?.workingUser.address !== value?.selectedAddress),
      map(({ workingUser, selectedAddress }) =>
        selectedAddress?.id
          ? replaceAddress({ entity: workingUser.address })
          : new Create(Address, workingUser.address, {
              parents: {
                [uriNameOfEntityOrEmpty(User)]: getKeyFromModel(User, workingUser.user),
              },
            }),
      ),
    ),
  );

  updateUserPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveInstallerProfile),
      filter(({ workingUser }) => !!workingUser.phoneNumber),
      withLatestFrom(this.store.select(currentPhoneNumber)),
      map(([{ workingUser }, selectedPhoneNumber]) => ({ workingUser, selectedPhoneNumber })),
      ensureChildrenRequired(value => value?.workingUser.phoneNumber?.number !== value?.selectedPhoneNumber?.number),
      switchMap(({ workingUser, selectedPhoneNumber }) => [
        ...(selectedPhoneNumber?.number
          ? [
              new DeleteByKey(PhoneNumber, selectedPhoneNumber.number, {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: getKeyFromModel(User, workingUser.user),
                },
              }),
            ]
          : []),
        new Create(PhoneNumber, workingUser.phoneNumber, {
          parents: {
            [uriNameOfEntityOrEmpty(User)]: getKeyFromModel(User, workingUser.user),
          },
        }),
      ]),
    ),
  );

  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveInstallerProfile),
      map(({ workingUser }) => workingUser.user),
      ensureRequired<Partial<User | InstallerTeamLead> | undefined, InstallerTeamLead>(
        user => !!user && isInstallerLead(user as User | InstallerTeamLead),
      ),
      map(
        (user: InstallerTeamLead) =>
          ({
            ...user,
            installerTeamLead: {
              bio: user.bio,
              dob: user.dob,
              ein: user.ein,
              insuranceAgentName: user.insuranceAgentName,
              insuranceAgentPhone: user.insuranceAgentPhone,
              insuranceExpiration: user.insuranceExpiration,
              insurancePolicyNumber: user.insurancePolicyNumber,
              insuranceProvider: user.insuranceProvider,
            },
          }) as User,
      ),
      switchMap((user: User) => [replaceUser({ entity: user }), updateAuthenticatedUser({ user })]),
    ),
  );

  initializeInstallerProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        selectedEmployeeInstallerProfile,
        showJumpstarterProfile,
        reviewJumpstarterHireOffer,
        showProjectManagerProfile,
      ),
      map(action =>
        action.type === selectedEmployeeInstallerProfile.type
          ? action.installer
          : action.type === reviewJumpstarterHireOffer.type
          ? action.installer
          : action.type === showJumpstarterProfile.type
          ? action.jumpstarter
          : action.projectManager,
      ),
      map(entity => selectUser({ entity })),
    ),
  );

  showProjectManagerProfileModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showProjectManagerProfile),
        exhaustMap(() => from(this.usersUI.showProjectManagerProfileModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  showInstallerProfileModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectedEmployeeInstallerProfile),
        exhaustMap(() => from(this.usersUI.showInstallerProfileModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  showAwardedInstallerProfileModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectedInstallerTeamLeadProfile, awardedInstallerProfileShown, selectedInvoicingInstallerProfile),
      map(({ installer }) => showEnhancedInstallerProfile({ installer })),
    ),
  );

  showJumpstartProfileModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showJumpstarterProfile),
        exhaustMap(() => from(this.usersUI.showJumpstarterProfileModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  closeProfileModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeProfileModal),
      switchMap(() => this.usersUI.closeProfileModal()),
      map(() => new Deselect(User)),
    ),
  );

  refreshUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteUserSuccess),
      map(({ entity }) => loadUser({ keys: entity.id })),
    ),
  );

  removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeUser),
      switchMap(({ user }) =>
        from(
          this.appUI.confirm('Delete User', 'Are you sure you wish to delete this user? This cannot be undone!', {
            confirmText: 'Confirm',
          }),
        ).pipe(
          filter(confirm => confirm),
          map(() => deleteUser({ entity: user })),
        ),
      ),
      tap(() => {
        void this.usersUI.closeProfileModal();
        void this.installerProfileUI.closeInstallerProfileModal();
      }),
    ),
  );

  disconnectUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disconnectUser),
      switchMap(({ connection }) =>
        from(
          this.appUI.confirm('Disconnect', 'Are you sure you wish to disconnect?', {
            confirmText: 'Confirm',
          }),
        ).pipe(
          filter(confirm => confirm),
          map(() => replaceConnection({ entity: { ...connection, status: ConnectionStatus.Disconnected } })),
        ),
      ),
      tap(() => this.usersUI.closeProfileModal()),
    ),
  );
}
