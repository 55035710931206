import { createSelector } from '@ngrx/store';
import { verifyingPhoneNumberDetails } from '../../core/profile/store/phone-number-management.selectors';
import { VerifyingPhoneNumberDetails } from '../../core/profile/store/phone-number-management.state';
import { makePhoneNumberEntry } from '../../core/profile/utils';
import { authenticatedUser } from '../../domains/app/auth/auth.selectors';
import { PhoneNumber } from '../../domains/phone-number';
import { allPhoneNumbers } from '../../domains/phone-number/phone-number.state';
import { whereMatchesUser } from '../../domains/utils';
import { PhoneNumberEntry } from '../../shared/components/advanced-phone-number-manager/models/phone-number-entry.model';


export const authenticatedUserPhoneNumbers = createSelector(allPhoneNumbers, authenticatedUser, (phoneNumbers, user) =>
  user ? phoneNumbers.filter(whereMatchesUser(user)) : undefined,
);

export const authenticatedUserPhoneNumberEntries = createSelector(
  verifyingPhoneNumberDetails,
  authenticatedUserPhoneNumbers,
  (details: VerifyingPhoneNumberDetails, phoneNumbers?: PhoneNumber[]): PhoneNumberEntry[] | undefined =>
    phoneNumbers ? phoneNumbers.map(makePhoneNumberEntry(details)) : undefined,
);
