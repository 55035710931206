<div class="flex align-items-stretch title-container">
  <h1 class="modal-title" data-cy="compliance-title">Compliance</h1>
</div>

<ng-container *ngIf="complianceRecords.length > 0; else noComplianceFiles" data-cy="compliance-files-area">
  <ion-card
    *ngFor="let record of complianceRecords"
    mode="ios"
    [attr.data-cy]="record.file.filename + '-' + record.file.type"
  >
    <ion-card-header [attr.data-cy]="record.file.filename + 'file-card'">
      <ion-card-title [attr.data-cy]="record.file.type + 'file-type'">
        <fa-icon
          [icon]="record.icon"
          [style.color]="'var(--ion-color-' + record.color + ')'"
          size="sm"
          class="ion-margin-end"
          [attr.data-cy]="'compliance-icon-' + record.color"
        ></fa-icon>
        {{ record.file.type | titlecase }}
      </ion-card-title>
    </ion-card-header>

    <ion-card-content class="flex flex-row">
      <div class="flex flex-col" style="justify-content: center">
        <ion-text data-cy="compliance-file-name">{{ record.file.filename | titlecase }}</ion-text>
        <ion-text [color]="record.color" data-cy="compliance-file-expiration">{{ record.label }}</ion-text>
      </div>
      <div class="flex flex-col">
        <ion-button
          color="light"
          mode="ios"
          [href]="record.file.url"
          target="_blank"
          data-cy="compliance-file-download-button"
        >
          <fa-icon [icon]="faCloudArrowDown" style="margin-right: 10px"></fa-icon>
          Download
        </ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ng-container>

<ng-template #noComplianceFiles>
  <div class="alert alert__warning text-center mr-20 ml-20" data-cy="empty-compliance-area">
    No compliance files found.
  </div>
</ng-template>
