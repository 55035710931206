import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { PhoneNumber } from './phone-number.model';

export const {
  initialState: initialPhoneNumberState,
  selectors: { selectAll: allPhoneNumbers, selectCurrentEntity: currentPhoneNumber },
  actions: {
    loadAll: loadAllPhoneNumbers,
    loadAllSuccess: loadAllPhoneNumbersSuccess,
    clear: clearPhoneNumbers,
    create: createPhoneNumber,
    createSuccess: phoneNumberCreatedSuccessfully,
    createFailure: phoneNumberCreationFailed,
    update: updatePhoneNumber,
    updateSuccess: phoneNumberUpdatedSuccessfully,
    updateFailure: phoneNumberUpdatedFailed,
    delete: deletePhoneNumber,
  },
  facade: PhoneNumberFacadeBase,
} = buildState(PhoneNumber);

export function phoneNumberReducer(state = initialPhoneNumberState): IEntityState<PhoneNumber> {
  return state;
}
