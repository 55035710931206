import { createAction, props } from '@ngrx/store';
import { DwollaAccount } from './dwolla-account.entity';
import { BeneficialOwnershipStatus, DwollaCustomerDetails } from './models/customer-details.model';

export const loadDwollaAccount = createAction('[Dwolla] Load DwollaAccount');
export const loadDwollaAccountSuccess = createAction(
  '[Dwolla] Load DwollaAccount Success',
  props<{ dwollaAccount: DwollaAccount }>(),
);
export const loadDwollaAccountFailure = createAction('[Dwolla] Load DwollaAccount Failure', props<{ error: any }>());

export const loadDwollaCustomerDetails = createAction('[Dwolla] Load CustomerDetails', props<{ link: string }>());
export const loadDwollaCustomerDetailsSuccess = createAction(
  '[Dwolla] Load CustomerDetails Success',
  props<{ customerDetails: DwollaCustomerDetails }>(),
);
export const loadDwollaCustomerDetailsFailure = createAction(
  '[Dwolla] Load CustomerDetails Failure',
  props<{ error: any }>(),
);

export const dwollaInitFailure = createAction('[Dwolla] Init Failure');
export const dwollaDropInFailure = createAction('[Dwolla] Drop In Failure', props<{ code: string; message: string }>());

export const dwollaCustomerCreateSuccess = createAction(
  '[Dwolla] Customer Create Success',
  props<{ location: string }>(),
);

export const dwollaBeneficialOwnershipCertified = createAction(
  '[Dwolla] Beneficial Ownership Certified',
  props<{ status: BeneficialOwnershipStatus }>(),
);

export const dwollaDocumentUploadSuccess = createAction('[Dwolla] Document Upload Success');
export const dwollaAddBeneficialOwnerSuccess = createAction('[Dwolla] Add Beneficial Owner Success');

export const dwollaGenericSuccess = createAction('[Dwolla] Generic Success', props<{ resource: string }>());

export const transferDwollaBalance = createAction('[Dwolla] Transfer Balance');
export const transferDwollaBalanceSuccess = createAction(
  '[Dwolla] Transfer Balance Success',
  props<{ dwollaAccount: DwollaAccount }>(),
);
export const transferDwollaBalanceFailure = createAction('[Dwolla] Transfer Balance Failure');
