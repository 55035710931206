import { ConnectionStatus } from './connection.model';

export const CONNECTION_STATUS_LABEL = {
  [ConnectionStatus.Pending]: 'Invited',
  [ConnectionStatus.Accepted]: 'Ready',
  [ConnectionStatus.Approved]: 'Approved',
  [ConnectionStatus.Declined]: 'Declined',
  [ConnectionStatus.Disconnected]: 'Disconnected',
  [ConnectionStatus.NoSubscription]: 'No Subscription',
};

export const COMPANY_CONNECTION_STATUS_LABEL = {
  [ConnectionStatus.Pending]: 'Invited',
  [ConnectionStatus.Accepted]: 'Accepted',
  [ConnectionStatus.Approved]: 'Approved',
  [ConnectionStatus.Declined]: 'Declined',
  [ConnectionStatus.Disconnected]: 'Disconnected',
  [ConnectionStatus.NoSubscription]: 'No Subscription',
};

export const INSTALLER_CONNECTION_STATUS_LABEL = {
  [ConnectionStatus.Pending]: 'Pending Response',
  [ConnectionStatus.Accepted]: 'Awaiting Approval',
  [ConnectionStatus.Approved]: 'Approved',
  [ConnectionStatus.Declined]: 'Declined',
  [ConnectionStatus.Disconnected]: 'Disconnected',
  [ConnectionStatus.NoSubscription]: 'No Subscription',
};

export const CONNECTION_STATUS_COLOR = {
  [ConnectionStatus.Pending]: 'gc-yellow',
  [ConnectionStatus.Accepted]: 'gc-green',
  [ConnectionStatus.Approved]: 'gc-green',
  [ConnectionStatus.Declined]: 'danger',
  [ConnectionStatus.Disconnected]: 'light',
  [ConnectionStatus.NoSubscription]: 'medium',
};
