import { Pipe, PipeTransform } from '@angular/core';
import { HammerRating } from '../../domains/users';
import { WorkOrder } from '../../domains/work/work-order/work-order.model';

@Pipe({ name: 'matchingHammerRating' })
export class MatchingHammerRatingPipe implements PipeTransform {
  transform(hammerRatings: HammerRating[], workOrder: Partial<WorkOrder>): number | undefined {
    return hammerRatings?.find(rating => rating.productInstallationTypeId === workOrder?.productInstallationTypeId)
      ?.value;
  }
}
