import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'gc-tooltip-display',
  templateUrl: './tooltip-display.component.html',
  styleUrls: ['./tooltip-display.component.scss'],
})
export class TooltipDisplayComponent {
  @Input() content!: TemplateRef<any>;
}
