import { Component, Input } from '@angular/core';
import { Invoice } from '~gc/domains/invoices/invoice/invoice.model';
import { User } from '~gc/domains/users';
import { Company } from '~gc/domains/users/companies/company.model';
import { DetailedWorkOrder } from '../../../domains/work/models/detailed-work-order';

@Component({
  selector: 'gc-invoice-header',
  templateUrl: './invoice-header.component.html',
  styleUrls: ['./invoice-header.component.scss'],
})
export class InvoiceHeaderComponent {
  @Input() workOrder?: DetailedWorkOrder;
  @Input() reportsProgress?: boolean;
  @Input() invoice!: Invoice;
  @Input() invoiceNumberLabel: string = 'Invoice No.';
  @Input() company?: Company;
  @Input() installer!: User;
  @Input() currentRole?: string;
  @Input() date: Date = new Date();
}
