import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  DetailedProductInstallationTypeClaimed,
  ExtendedHammerRatingDetails,
} from '../../../../core/installer-profile';
import { ProductInstallationTypeClaimed } from '../../../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';

@Component({
  selector: 'gc-training-product-installation-type-details',
  templateUrl: './training-product-installation-type-details.component.html',
  styleUrls: ['./training-product-installation-type-details.component.scss'],
})
export class TrainingProductInstallationTypeDetailsComponent {
  @Input() chart!: ExtendedHammerRatingDetails;
  @Input() title!: string;
  @Input() types?: DetailedProductInstallationTypeClaimed[];
  @Input() selectedProductInstallationTypeClaim?: DetailedProductInstallationTypeClaimed;
  @Input() canEditProfile?: boolean;

  @Output() productInstallationTypeClaimRemoved = new EventEmitter<ProductInstallationTypeClaimed>();
}
