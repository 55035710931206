import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EntityService } from '../entity.service';
import { CustomDatePollingEffects } from './custom-date-polling.effects';
import { CustomDateEffects } from './custom-date/custom-date.effects';
import { CustomDate } from './custom-date/custom-date.model';
import { DOMAIN_NAME } from './domain.name';
import { reducer } from './domain.state';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(DOMAIN_NAME, reducer),
    EffectsModule.forFeature([CustomDateEffects, CustomDatePollingEffects]),
  ],
  providers: [{ provide: CustomDate, useClass: EntityService }],
})
export class CustomDatesModule {}
