import { Pipe, PipeTransform } from '@angular/core';
import { ChangeOrder } from '../../domains/change-orders/change-order/change-order.model';

export const totalDeductions = (changeOrders: ChangeOrder[]): number =>
  changeOrders.reduce(
    (sum, changeOrder) => +(sum + changeOrder.adjustedNumberOfUnits * changeOrder.adjustedPricePerUnit).toFixed(2),
    0,
  );

@Pipe({
  name: 'totalDeductions',
})
export class TotalDeductionsPipe implements PipeTransform {
  transform(changeOrders: ChangeOrder[]): number {
    return totalDeductions(changeOrders);
  }
}
