import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedInstaller } from '../../../../domains/models/detailed-installer';
import { Installer } from '../../../../domains/users';
import { WorkOrderFacade } from '../../../../domains/work/work-order/work-order.facade';

@Component({
  selector: 'gc-installer-card',
  templateUrl: './installer-card.component.html',
  styleUrls: ['./installer-card.component.scss'],
})
export class InstallerCardComponent {
  @Input() installer!: DetailedInstaller;
  @Output() viewed = new EventEmitter<DetailedInstaller>();

  get realInstaller(): Installer {
    return this.installer as Installer;
  }

  constructor(public workOrder: WorkOrderFacade) {}
}
