import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CallPhoneNumbersModalComponent } from '../../modals/call-phone-numbers/call-phone-numbers-modal/call-phone-numbers-modal.component';

@Injectable()
export class PhoneNumberUIService {
  constructor(private readonly modals: ModalController) {}

  async showCallPhoneNumberModal() {
    const modal = await this.modals.create({
      component: CallPhoneNumbersModalComponent,
      cssClass: 'rounded-modal'
    });

    await modal.present();
    return await modal.onDidDismiss();
  }
}
