import { ActionReducerMap } from '@ngrx/store';
import { calendarReducer, CalendarState } from './calendar.state';
import { filtersReducer, FiltersState } from './filters.state';
import { settingsReducer, SettingsState } from './settings.state';

export interface FeatureState {
  filters: FiltersState;
  settings: SettingsState;
  calendar: CalendarState;
}

export const reducer: ActionReducerMap<FeatureState> = {
  filters: filtersReducer,
  settings: settingsReducer,
  calendar: calendarReducer,
};
