import { Pipe, PipeTransform } from '@angular/core';
import { ChangeOrder } from '../../domains/change-orders/change-order/change-order.model';

export const totalNormalizedUnits = (changeOrders: ChangeOrder[], pricePerUnit: number) =>
  changeOrders.reduce(
    (sum, changeOrder) =>
      +(
        sum + Math.ceil(changeOrder.adjustedNumberOfUnits * +(changeOrder.adjustedPricePerUnit / pricePerUnit))
      ).toFixed(2),
    0,
  );

@Pipe({
  name: 'totalNormalizedUnits',
})
export class TotalNormalizedUnitsPipe implements PipeTransform {
  transform(changeOrders: ChangeOrder[], pricePerUnit: number): number {
    return totalNormalizedUnits(changeOrders, pricePerUnit);
  }
}
