import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { merge, Observable } from 'rxjs';
import { exhaustMap, filter, map, takeUntil, throttleTime, withLatestFrom } from 'rxjs/operators';
import { routeIncludesPath } from '../../../shared/utils/ngrx';
import { onlyRoles } from '../../../shared/utils/roles.operators';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedRole, authenticatedUser } from '../../app/auth/auth.selectors';
import { EmptyKey } from '../../entity.service.utils';
import { AppState } from '../../state';
import { isEmployeeInstaller, isTeamMember } from '../../users';
import { viewWorkOrder } from '../work-order-ui.actions';
import { WorkOrder } from '../work-order/work-order.model';
import { workOrderRefreshIntervalTicked } from '../work-polling.actions';
import { loadAllMatchedInstallers } from './matched-installer.state';

const THROTTLE_WINDOW = 5000;

@Injectable()
export class MatchedInstallerEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadAllMatchedInstallersOfInterest$ = createEffect(() =>
    merge(
      this.actions$.pipe(ofType(routerNavigatedAction), routeIncludesPath('/app/work/custom-calendar')),
      this.actions$.pipe(
        ofType(viewWorkOrder),
        onlyRoles(this.store.select(authenticatedRole), 'installerLead', 'companyAdmin', 'companyManager'),
      ),
      this.actions$.pipe(ofType(workOrderRefreshIntervalTicked)),
    ).pipe(
      withLatestFrom(this.store.select(authenticatedUser)),
      filter(([, user]) => !isEmployeeInstaller(user) && !isTeamMember(user)),
      throttleTime(THROTTLE_WINDOW),
      map(() =>
        loadAllMatchedInstallers({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );
}
