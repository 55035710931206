import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { InformationMessageModule } from '../../shared/components/information-message/information-message.module';
import { TooltipModule } from '../../shared/components/tooltip/tooltip.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { InviteUserModalComponent } from './invite-user.modal';

@NgModule({
  declarations: [InviteUserModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    PipesModule,
    TooltipModule,
    InformationMessageModule,
    PushModule,
  ],
})
export class InviteUserModalModule {}
