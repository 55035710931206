export interface INameLabel {
  name: string;
  label: string;
}

// TODO: Figure out if there is a better type for `map` than any!!!
export const mapToNameLabelArray = (map: any, allowed?: string[]): INameLabel[] =>
  (Object.keys(map) as unknown as string[])
    .filter(key => (allowed?.length ? allowed.includes(key) : true))
    .map(key => ({ name: key, label: map[key] }));

export const isStatus = (status?: string, ...statuses: (keyof typeof STATUS_GROUP_MAP)[]) =>
  !!status && statuses.includes(status);

// -----===[ MAPS ]===-----
// Maps represent a very simple, highly effective way to transform
// data of one form into data of another form. Maps are a very
// functional way to handle simple operations that might otherwise
// require much more complex code. Maps are often used in selectors,
// but may also be used in reductions or effects, or anywhere else
// that their simple behavior may apply.

export const STATUS_GROUP_MAP: Record<string, string> = {
  DRAFT: 'draft',
  PUBLISHED_AWAITING_RESPONSE: 'pending',
  PUBLISHED_WITH_RESPONSES: 'pending',
  AWARDED_NOT_STARTED: 'in_progress',
  IN_PROGRESS_NO_INVOICES: 'in_progress',
  IN_PROGRESS_HAS_INVOICES: 'in_progress',
  DONE_AWAITING_SETTLEMENT: 'completed',
  DONE: 'completed',
  CANCELLED: 'cancelled',
  DELETED: 'cancelled',
};

export const STATUS_GROUP_LABEL_MAP: Record<string, string> = {
  draft: 'Drafts',
  pending: 'Pending',
  available_bids: 'Available Bids',
  in_progress: 'In Progress',
  completed: 'Completed',
  cancelled: 'Cancelled',
  deleted: 'Deleted',
};

export const STATUS_GROUP_ORDER_MAP: Record<string, number> = {
  draft: 0,
  pending: 1,
  available_bids: 2,
  in_progress: 3,
  completed: 4,
  cancelled: 5,
};

export const STATUS_GROUP_STATUS_MAP: Record<string, string[]> = {
  draft: ['DRAFT'],
  pending: ['PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES'],
  available_bids: ['PUBLISHED_AWAITING_RESPONSE', 'PUBLISHED_WITH_RESPONSES'],
  in_progress: ['AWARDED_NOT_STARTED', 'IN_PROGRESS_NO_INVOICES', 'IN_PROGRESS_HAS_INVOICES'],
  completed: ['DONE_AWAITING_SETTLEMENT', 'DONE'],
  cancelled: ['CANCELLED', 'DELETED'],
};

export const STATUS_GROUP_ALLOWED_BY_ROLE: Record<string, string[]> = {
  companyAdmin: ['draft', 'pending', 'in_progress', 'completed', 'cancelled', 'deleted'],
  companyManager: ['draft', 'pending', 'in_progress', 'completed', 'cancelled', 'deleted'],
  companyEmployee: ['in_progress'],
  installerLead: ['available_bids', 'in_progress', 'completed', 'cancelled'],
  installerMember: ['in_progress'],
  jumpstarter: ['in_progress'],
};

export const STATUS_GROUP_TO_COLOR: Record<string, string> = {
  draft: 'medium',
  pending: 'gc-yellow',
  available_bids: 'gc-yellow',
  in_progress: 'gc-green',
  completed: 'medium',
  cancelled: 'light',
};

export const STATUS_GROUP_TO_CALENDAR_COLOR: Record<string, string> = {
  draft: '#989aa2',
  pending: '#fbb84d',
  available_bids: '#fbb84d',
  in_progress: '#339a25',
  completed: '#989aa2',
  cancelled: '#989aa2',
};

export const EDIT_STEPS: string[] = ['basics', 'details', 'items', 'publishing'];

export const SELECTED_AREA_PAGE_TITLE_MAP: Record<string, string> = {
  workOrders: 'Work',
  calendar: 'Calendar',
  customCalendar: 'Calendar',
};

export const SELECTED_AREA_LABEL_MAP: Record<string, string> = {
  workOrders: 'Work Orders',
  calendar: 'Calendar',
  customCalendar: 'Calendar',
};

export const WORK_AREAS_ALLOWED_BY_ROLE_MAP: Record<string, string[]> = {
  companyAdmin: ['workOrders', 'customCalendar'],
  companyManager: ['workOrders', 'customCalendar'],
  companyEmployee: ['workOrders', 'customCalendar'],
  installerLead: ['workOrders', 'customCalendar'],
  installerMember: ['workOrders', 'customCalendar'],
  jumpstarter: ['workOrders', 'customCalendar'],
};

export const SELECTED_DETAIL_AREA_LABEL_MAP: Record<string, string> = {
  items: 'Items',
  details: 'Details',
  files: 'Files',
  invoices: 'Invoices',
  progress: 'Progress',
};

export const DETAIL_AREAS_ALLOWED_BY_ROLE_MAP: Record<string, string[]> = {
  companyAdmin: ['items', 'details', 'files', 'invoices', 'progress'],
  companyManager: ['items', 'details', 'files', 'invoices', 'progress'],
  companyEmployee: ['items', 'details', 'files', 'progress'],
  installerLead: ['items', 'details', 'files', 'invoices'],
  installerMember: ['items', 'details', 'files'],
  jumpstarter: ['items', 'details'],
};
