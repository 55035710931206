import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { MinimalActivatedRouteSnapshot, routerNavigatedAction, routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { combineLatest, merge, Observable, pipe } from 'rxjs';
import { debounceTime, exhaustMap, filter, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { routeEndsInPath, routeIncludesPath } from '~gc/shared/utils/ngrx';
import { ensureChildrenExist, ensureExists } from '../../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../../app/auth/auth-connect.actions';
import { logout } from '../../app/auth/auth.actions';
import { authenticatedUser } from '../../app/auth/auth.selectors';
import { today } from '../../custom-dates/custom-date/custom-date.effects';
import { EmptyKey } from '../../entity.service.utils';
import { currentRouteUrl } from '../../router.selectors';
import { isProjectManager } from '../../users';
import { DisplayableWorkOrderStatuses } from '../calendar/calendar.utils';
import { loadAllWorkOrderCounts } from '../work-order-count/work-order-count.state';
import { workOrderRefreshIntervalTicked, workOrdersRefreshIntervalTicked, workRefreshIntervalTicked, } from '../work-polling.actions';
import {
  clearWorkOrderSearchCriteria,
  searchWorkOrders,
  viewOrdersByStatus,
  workOrdersRefresh,
  workOrdersRouteVisit,
} from '../work.actions';
import { STATUS_GROUP_STATUS_MAP } from '../work.maps';
import { currentSearchCriteria, currentStatusGroup } from '../work.selectors';
import { workOrderListEndReached } from './work-order.actions';
import { WorkOrder } from './work-order.model';
import { currentWorkOrderPage, loadAllWorkOrders, loadManyWorkOrders, loadWorkOrder, selectWorkOrderById } from './work-order.state';


export const navigationToWithParams = (
  urls: string[],
  getParams: (root: MinimalActivatedRouteSnapshot) => Params | undefined,
) =>
  pipe(
    ofType(routerNavigationAction),
    routeIncludesPath('/custom-calendar'),
    map(
      ({
        payload: {
          routerState: { root },
        },
      }) => getParams(root) ?? {},
    ),
  );

@Injectable()
export class WorkOrderLoadingEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}

  loadWorkOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchWorkOrders, viewOrdersByStatus, clearWorkOrderSearchCriteria, workOrdersRefreshIntervalTicked),
      withLatestFrom(
        this.store.select(currentStatusGroup),
        this.store.select(currentSearchCriteria),
        this.store.select(authenticatedUser),
      ),
      map(([, status, search, user]) => ({ status, search, user })),
      ensureChildrenExist(value => !!value?.user),
      map(({ status, search, user }) =>
        loadAllWorkOrders({
          criteria: {
            query: {
              ...(status ? { '&status': STATUS_GROUP_STATUS_MAP[status] } : {}),
              ...(search ? { search } : {}),
              page: 1,
              limit: 25,
              ...(isProjectManager(user) ? { projectManagerId: user.id } : {}),
            },
          },
        }),
      ),
    ),
  );

  loadMoreWorkOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderListEndReached),
      withLatestFrom(
        this.store.select(currentStatusGroup),
        this.store.select(currentWorkOrderPage),
        this.store.select(currentSearchCriteria),
        this.store.select(authenticatedUser),
      ),
      map(([{ event }, status, page, search, user]) => ({ event, status, page, search, user })),
      ensureChildrenExist(value => !!value?.event && !!value?.user),
      map(({ event, status, page, search, user }) =>
        loadManyWorkOrders({
          criteria: {
            query: {
              ...(status ? { '&status': STATUS_GROUP_STATUS_MAP[status] } : {}),
              page: page?.page ?? 2,
              ...(search ? { search } : {}),
              limit: 25,
              ...(isProjectManager(user) ? { projectManagerId: user.id } : {}),
            },
            event,
          },
        }),
      ),
    ),
  );

  loadWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectWorkOrderById),
      map(({ entityKey }) => loadWorkOrder({ keys: entityKey })),
    ),
  );

  reloadWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrderRefreshIntervalTicked),
      map(({ workOrder }) =>
        loadWorkOrder({
          keys: workOrder.id,
        }),
      ),
    ),
  );

  loadByDateRange$ = createEffect(() =>
    merge(
      this.actions$.pipe(
        navigationToWithParams(
          ['/app/work/custom-calendar'],
          root => root.firstChild?.firstChild?.firstChild?.firstChild?.queryParams,
        ),
        debounceTime(25),
        map(params => [
          params['startDate'] ? Number(params['startDate']) : today(Date.now()),
          params['endDate'] ? Number(params['endDate']) : addDays(today(Date.now()), 1),
        ]),
      ),
    ).pipe(
      map(([startDate, endDate]) =>
        loadAllWorkOrders({
          criteria: {
            query: {
              startDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
              endDate: format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS"),
              '&status': DisplayableWorkOrderStatuses,
            },
          },
        }),
      ),
      tap({ error: err => console.error(err) }),
    ),
  );

  loadWorkOrdersOnDashboardVisit$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(routerNavigatedAction), routeEndsInPath('/dashboard')),
      this.store.select(authenticatedUser).pipe(filter(user => !!user)),
    ]).pipe(
      map(([, user]) => user),
      ensureExists(user => !!user),
      withLatestFrom(this.store.select(currentRouteUrl)),
      filter(([, route]) => route.endsWith('/dashboard')),
      map(([user]) =>
        loadAllWorkOrders({
          criteria: {
            query: {
              '&status': [...STATUS_GROUP_STATUS_MAP['in_progress'], ...STATUS_GROUP_STATUS_MAP['pending'], ...STATUS_GROUP_STATUS_MAP['completed']],
              limit: 100,
              ...(isProjectManager(user) ? { projectManagerId: user.id } : {}),
            },
          },
        }),
      ),
    ),
  );

  loadWorkOrderCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(workOrdersRouteVisit, workOrdersRefresh, workRefreshIntervalTicked),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) => user),
      ensureExists(user => !!user),
      map(user =>
        loadAllWorkOrderCounts({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: EmptyKey,
            },
            query: {
              ...(isProjectManager(user) ? { projectManagerId: user.id } : {}),
            },
          },
        }),
      ),
    ),
  );

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }
}
