import { Pipe, PipeTransform } from '@angular/core';

export interface IHasLogo {
  logoUrl?: string;
  logo?: {
    data: number[];
  };
}

@Pipe({ name: 'logo' })
export class LogoPipe implements PipeTransform {
  transform(entity: IHasLogo): string {
    return entity?.logoUrl || '';
  }
}
