import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { createAction, props } from '@ngrx/store';
import { WorkOrder } from '~gc/domains/work/work-order/work-order.model';
import { PendingInvoice } from '../models/pending-invoice';
import { IStatusGroup } from '../models/status-group';
import { BankAccount } from './bank-account/bank-account.model';
import { InvoiceArea } from './invoice-and-payment.state';
import { Invoice } from './invoice/invoice.model';

export const addNewInvoice = createAction('[Invoice] Add New Invoice');
export const invoicePageInitialize = createAction('[Invoice] Initialize');
export const viewInvoicesByStatus = createAction('[Invoice] View Invoice by Status', props<{ status: IStatusGroup }>());

// Public Route Visit Action
export const invoicesRouteVisit = createAction('[Invoice] Route Visit');

export const updateInvoiceArea = createAction('[Invoice] Set Invoice Area', props<{ area: InvoiceArea }>());

export const reviewPendingInvoice = createAction(
  '[Invoice] Review pending',
  props<{ pendingInvoice: PendingInvoice }>(),
);

export const reviewPendingProgress = createAction(
  '[Progress] Review pending',
  props<{ pendingInvoice: PendingInvoice }>(),
);

export const confirmPendingInvoice = createAction(
  '[Invoice] Confirm pending',
  props<{ pendingInvoice: PendingInvoice }>(),
);

export const pendingInvoiceConfirmed = createAction(
  '[Invoice] Pending confirmed',
  props<{ pendingInvoice: PendingInvoice }>(),
);

export const pendingInvoiceDeclined = createAction('[Invoice] Pending declined');

export const pendingInvoiceFailed = createAction('[Invoice] Pending failed', props<{ error: any }>());

export const confirmPendingProgress = createAction(
  '[Progress] Confirm pending',
  props<{ pendingInvoice: PendingInvoice }>(),
);

export const pendingProgressConfirmed = createAction(
  '[Progress] Pending confirmed',
  props<{ pendingInvoice: PendingInvoice }>(),
);

export const pendingProgressDeclined = createAction('[Progress] Pending declined');

export const pendingInvoiceCreating = createAction(
  '[Invoice] Pending Invoice Creating',
  props<{ correlationId: string }>(),
);

export const pendingInvoiceCreated = createAction('[Invoice] Pending Invoice Created', props<{ invoice: Invoice }>());

export const showInvoiceOptions = createAction(
  '[Invoice] Show Invoice Options',
  props<{ event: Event; invoice: Invoice }>(),
);

export const invoiceAction = createAction('[Invoice] Action', props<{ action: string; invoice: Invoice }>());

export const viewInvoice = createAction('[Invoice] View Invoice', props<{ invoice: Invoice }>());

export const workOrderInvoiceModalClosed = createAction('[Invoice] (Work Order Invoice Modal) Closed');

export const invoiceModalClosed = createAction('[Invoice] (Invoice Modal) Closed');

export const approveInvoice = createAction('[Invoice] Approve Invoice', props<{ invoice: Invoice }>());

export const invoiceApprovalConfirmed = createAction(
  '[Invoice] Approve Invoice: Confirmed',
  props<{ invoice: Invoice }>(),
);

export const progressApprovalConfirmed = createAction(
  '[Progress] Approve Progress: Confirmed',
  props<{ invoice: Invoice }>(),
);

export const declineInvoice = createAction('[Invoice] Decline Invoice', props<{ invoice: Invoice }>());
export const invoiceDeclineConfirmed = createAction(
  '[Invoice] Decline Invoice: Confirmed',
  props<{ invoice: Invoice }>(),
);

export const archiveInvoice = createAction('[Invoice] Archive Invoice', props<{ invoice: Invoice }>());
export const invoiceArchiveConfirmed = createAction(
  '[Invoice] Archive Invoice: Confirmed',
  props<{ invoice: Invoice }>(),
);

export const voidInvoice = createAction('[Invoice] Void Invoice', props<{ invoice: Invoice }>());
export const invoiceVoidConfirmed = createAction('[Invoice] Void Invoice: Confirmed', props<{ invoice: Invoice }>());
export const progressVoidConfirmed = createAction('[Progress] Void Progress: Confirmed', props<{ invoice: Invoice }>());

export const payInvoice = createAction('[Invoice] Pay Invoice', props<{ invoice: Invoice }>());
export const invoicePaymentMethodDetermined = createAction(
  '[Invoice] Pay Invoice: Confirmed',
  props<{ invoice: Invoice; bankAccount?: BankAccount }>(),
);

export const viewLienRelease = createAction('[Invoice] View Lien Release', props<{ invoice: Invoice }>());
export const showSignLienRelease = createAction('[Invoice] Show Sign Lien Release', props<{ invoice: Invoice }>());
export const signLienRelease = createAction('[Invoice] Sign Lien Release', props<{ invoice: Invoice }>());
export const signLienReleaseClosed = createAction('[Invoice] Sign Lien Release Closed');
export const selectInvoiceSettlementTiming = createAction(
  '[Invoice] Select Settlement Timing',
  props<{ workOrder: WorkOrder; invoice: Invoice; dataUrl: string }>(),
);

export const searchCriteriaUpdated = createAction('[Invoice] Search Criteria Updated', props<{ criteria: string }>());

export const invoiceListEndReached = createAction(
  '[Invoice] List End Reached',
  props<{ event: InfiniteScrollCustomEvent }>(),
);
