<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss(null, 'cancel')">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ category.type }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf="!(trainingsFacade.isLoading$ | push); else skeletons">
    <ion-item
      *ngFor="let training of skillsAndExperience.unclaimedTrainingsForCurrentCategory$ | push"
      (click)="chooseTraining(training)"
    >
      <ion-label>
        {{ training.certification }}
      </ion-label>
      <ion-button mode="ios" slot="end" color="dark" size="small" (click)="popover.present($event)">
        Add
        <ion-icon slot="start" name="add"></ion-icon>
      </ion-button>
      <ion-popover side="left" #popover>
        <ng-template>
          <ion-datetime [max]="today" presentation="date" (ionChange)="finalizeChoice(popover, $event)"></ion-datetime>
        </ng-template>
      </ion-popover>
    </ion-item>
  </ion-list>
  <ng-template #skeletons>
    <ion-item *ngFor="let i of [1, 2, 3]">
      <ion-label>
        <ion-skeleton-text animated style="width: 60%; height: 1em"></ion-skeleton-text>
      </ion-label>
    </ion-item>
  </ng-template>
</ion-content>
