import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedInstaller } from '~gc/domains/models/detailed-installer';
import { NegotiableWorkOrderItem } from '~gc/domains/models/negotiable-work-order-item';
import { DetailedWorkOrderItem } from '../../../../domains/models/detailed-work-order-item';
import { InstallerNegotiationItem } from '../../../../domains/models/installer-negotiation-item';
import { NegotiationItem } from '../../../../domains/negotiations/negotiation-item/negotiation-item.model';

@Component({
  selector: 'gc-item-negotiations-detail',
  templateUrl: './item-negotiations-detail.component.html',
  styleUrls: ['./item-negotiations-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemNegotiationsDetailComponent {
  @Input() item?: DetailedWorkOrderItem | NegotiableWorkOrderItem;
  @Input() expanded!: DetailedWorkOrderItem | NegotiableWorkOrderItem;
  @Input() showTotals!: boolean;
  @Input() reviewAllowed = true;
  @Output() collapsed = new EventEmitter();
  @Output() reviewed = new EventEmitter<DetailedInstaller>();

  trackNegotiationBy(index: number, item: NegotiationItem | InstallerNegotiationItem) {
    return item.id;
  }
}
