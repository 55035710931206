<ng-container *ngIf="role && user">
  <ng-container
    *ngIf="
      (group | typeIsIn: 'negotiable') &&
      (displayGroups | itemGroupsAllowed: 'negotiable') &&
      (permissions | hasPermissions: 'work-order.negotiations:view' : 'work-order.negotiation-items:view')
    "
  >
    <gc-item-negotiations-detail
      [item]="item"
      [expanded]="expandedItem"
      [reviewAllowed]="user | isInstaller | not"
      (collapsed)="collapsed.emit()"
      (reviewed)="reviewed.emit($event)"
    ></gc-item-negotiations-detail>
  </ng-container>
  <ng-container
    *ngIf="
      (permissions | hasPermissions: 'work-order.change-orders:view') &&
      (((group | typeIsIn: 'original') && (displayGroups | itemGroupsAllowed: 'original')) ||
        ((group | typeIsIn: 'change-add' : 'installer-add') && (displayGroups | itemGroupsAllowed: 'change-add')) ||
        ((group | typeIsIn: 'installer-add') && (displayGroups | itemGroupsAllowed: 'installer-add')))
    "
  >
    <gc-item-change-orders-detail
      [displayGroups]="displayGroups"
      [permissions]="permissions"
      [userType]="userType"
      [item]="item"
      [role]="role!"
      [workOrder]="workOrder"
      [expanded]="expandedItem"
      (collapsed)="collapsed.emit()"
      (accepted)="accepted.emit($event)"
      (pmCalled)="pmCalled.emit($event)"
      (voided)="voided.emit($event)"
    ></gc-item-change-orders-detail>
  </ng-container>
</ng-container>
