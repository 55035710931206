<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button color="dark" text="Back to Login"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content data-e2e="signup-page">
  <router-outlet></router-outlet>
</ion-content>
