import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { FileType, GenericFile } from '../../../domains/models/generic-file';
import { PendingFile } from '../../../domains/models/pending-file';
import { User } from '../../../domains/users';
import { InstallerProfileForm } from '../../../modals/installer-profile/installer-profile.form';
import { HasTargetFiles } from '../../utils/types';

@Component({
  selector: 'gc-tax-info-form',
  templateUrl: './tax-info-form.component.html',
  styleUrls: ['./tax-info-form.component.scss'],
})
export class TaxInfoFormComponent {
  @Input() validated = false;
  @Input() form!: InstallerProfileForm;
  @Input() user!: User;
  @Input() files!: GenericFile[];
  @Output() fileAdded = new EventEmitter<{ user: User; file: PendingFile }>();

  addPendingFiles(files: File[]) {
    files
      .map((file: File) => ({
        type: FileType.W9,
        filename: file.name,
        contentType: file.type,
        fileBlob: file,
      }))
      .forEach(file => {
        this.fileAdded.emit({
          user: this.user,
          file,
        });
      });
  }

  addFiles($event: Event & HasTargetFiles): void {
    this.addPendingFiles(Array.from($event.target.files));
  }

  addDroppedFiles(files: FileList): void {
    this.addPendingFiles(Array.from(files));
  }
}
