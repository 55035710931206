import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Invoice } from '~gc/domains/invoices';

@Component({
  selector: 'gc-approve-invoice-button',
  templateUrl: './approve-invoice-button.component.html',
  styleUrls: ['./approve-invoice-button.component.scss'],
})
export class ApproveInvoiceButtonComponent {
  @Input() invoice!: Invoice;
  @Input() reportsProgress = false;

  @Output() approved = new EventEmitter<Invoice>();
  @Output() declined = new EventEmitter<Invoice>();
}
