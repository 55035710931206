<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button color="dark" (click)="modalGC.dismiss()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ng-container *ngIf="installerReview.currentInstaller$ | push as installer">
    <ng-container *ngIf="workOrder.currentDetailedWorkOrder$ | push as workOrder">
      <gc-user-header [workOrder]="workOrder" [user]="installer" [centered]="true"></gc-user-header>
    </ng-container>
  </ng-container>
</ion-header>

<gc-content name="review-installer-modal">
    <ng-container *ngIf="installerReview.currentInstaller$ | push as installer">
      <gc-date-negotiation
        [workOrder]="workOrder.currentDetailedWorkOrder$ | push"
        [dateNegotiation]="workOrderRelations.installerNegotiation$ | push"
        [user]="installer"
      ></gc-date-negotiation>
    </ng-container>

    <gc-negotiable-item-list
      [items]="workOrderItems.negotiableWorkOrderItems$ | push"
      [showTotals]="true"
      [showNegotiations]="true"
    >
    </gc-negotiable-item-list>
</gc-content>

<ng-container *ngIf="installerReview.currentInstaller$ | push as installer">
  <ion-footer>
    <gc-company-user-installer-options
      [workOrder]="workOrder.current$ | push"
      [installer]="installer"
      [isUnderNegotiation]="workOrderItems.workOrderUnderNegotiation$ | push"
      [isJumpstarterOffer]="isJumpstarterOffer"
      (awarded)="workOrder.awardToInstaller($event.workOrder, $event.installer)"
      (negotiated)="negotiations.beginNegotiating()"
      (viewInstallerFullProfile)="showInstallerProfile(installer)"
      (declineInstallerOffer)="favorites.jumpstarterHireOfferDeclined($any($event))"
      (acceptInstallerOffer)="favorites.jumpstarterHireOfferAccepted($any($event))"
    ></gc-company-user-installer-options>
  </ion-footer>
</ng-container>
