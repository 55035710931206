import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DetailedInstaller } from '../../models/detailed-installer';
import { AppState } from '../../state';
import { InstallerTeamLead } from '../user/user.model';
import { JumpstarterFavorite } from './jumpstarter-favorite.model';
import {
  confirmLeaveTeam,
  jumpstarterFavorited,
  jumpstarterHireOfferAccepted,
  jumpstarterHireOfferDeclined,
  jumpstarterHireOfferExtended,
  removeJumpstarter,
  reviewJumpstarterHireOffer,
} from './jumpstarter-favorites.actions';
import {
  detailedFavoritesForAuthenticatedUser,
  favoritesInstallerTeamLeads,
  jumpstarterFavoriteForCurrentUser,
} from './jumpstarter-favorites.selectors';
import { JumpstartFavoritesFacadeBase } from './jumpstarter-favorites.state';

@Injectable()
export class JumpstarterFavoritesFacade extends JumpstartFavoritesFacadeBase {
  jumpstarterFavoriteForCurrentUser$ = this.store.select(jumpstarterFavoriteForCurrentUser);
  detailedFavoritesForAuthenticatedUser$ = this.store.select(detailedFavoritesForAuthenticatedUser);
  favoritesInstallerTeamLeads$ = this.store.select(favoritesInstallerTeamLeads);

  constructor(private store: Store<AppState>) {
    super(JumpstarterFavorite, store);
  }

  jumpstarterFavorited(favorite: boolean) {
    this.store.dispatch(jumpstarterFavorited({ favorite }));
  }

  removeJumpstarter(favorite: JumpstarterFavorite) {
    this.store.dispatch(removeJumpstarter({ favorite }));
  }

  jumpstarterHireOfferDeclined(installer: InstallerTeamLead) {
    this.store.dispatch(jumpstarterHireOfferDeclined({ installer }));
  }

  jumpstarterHireOfferAccepted(installer: InstallerTeamLead) {
    this.store.dispatch(jumpstarterHireOfferAccepted({ installer }));
  }

  jumpstarterHireOfferExtended() {
    this.store.dispatch(jumpstarterHireOfferExtended());
  }

  reviewJumpstarterHireOffer(installer: DetailedInstaller) {
    this.store.dispatch(reviewJumpstarterHireOffer({ installer }));
  }

  confirmLeaveTeam() {
    this.store.dispatch(confirmLeaveTeam());
  }
}
