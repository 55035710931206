import { Component, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DetailedWorkOrderItem } from '../../../domains/models/detailed-work-order-item';
import { WorkOrderItemGroup, WorkOrderItemKinds } from '../../../domains/work-order-item-group.selectors';

export const isNotAccepted = (entity: { acceptedById?: string }) => !entity.acceptedById;

export const isPendingChangeOrder = (item: Partial<DetailedWorkOrderItem>) =>
  item?.isChangeOrder && (isNotAccepted(item) || item.changeOrders?.some(isNotAccepted));

export const calculateItemTotal = (item: Partial<DetailedWorkOrderItem>): number =>
  item.changeInfo?.hasAcceptedDeductions
    ? +(item.changeInfo?.acceptedChangedTotal ?? 0)
    : item.isChangeOrder && isNotAccepted(item)
    ? 0
    : +(item?.numberOfUnits ?? 0) * +(item?.pricePerUnit ?? 0);

@Component({
  selector: 'gc-work-order-item-total',
  templateUrl: './work-order-item-total.component.html',
  styleUrls: ['./work-order-item-total.component.scss'],
})
export class WorkOrderItemTotalComponent {
  workOrderItems$ = new BehaviorSubject<Partial<DetailedWorkOrderItem>[]>([]);
  privateDetails$ = new BehaviorSubject(true);
  pendingChangeOrders = false;
  total = 0;

  total$: Observable<number> = combineLatest([this.workOrderItems$, this.privateDetails$]).pipe(
    map(([items, allowed]) =>
      items.reduce((sum, item) => sum + (allowed ? (item ? calculateItemTotal(item) : 0) : +(item?.total ?? 0)), 0),
    ),
  );

  // TODO: BAD!! Should be done with selectors!!!
  @Input() set allowedPrivateDetails(allowed: boolean) {
    this.privateDetails$.next(allowed);
  }

  // TODO: BAD!! Should be done with selectors!!!
  @Input() set workOrderItems(items: Partial<DetailedWorkOrderItem>[]) {
    this.pendingChangeOrders = items.some(isPendingChangeOrder);
    this.workOrderItems$.next(items);
  }

  // TODO: BAD!! Should be done with selectors!!!
  @Input() set workOrderItemGroups(itemGroups: WorkOrderItemGroup[]) {
    this.workOrderItems = ([] as WorkOrderItemKinds[]).concat(...itemGroups.map(group => group.items)); // TODO: Wouldn't be a problem if we used selectors
  }

  // TODO: BAD!! Should be done with selectors!!!
  @Input() set itemTotal(total: number) {
    this.total = +(total ?? 0);
  }
}
