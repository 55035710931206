import { Pipe, PipeTransform } from '@angular/core';
import { DetailedWorkOrderItem } from '../../domains/models/detailed-work-order-item';


@Pipe({ name: 'hasChangeOrders' })
export class HasChangeOrdersPipe implements PipeTransform {
  transform(item: DetailedWorkOrderItem): boolean {
    return !!item?.changeOrders?.length;
  }
}
