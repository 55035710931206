<ion-grid
  *ngIf="enableUpload"
  gcFileDropTarget
  (filesDropped)="filesDropped.emit($event)"
  [uploadEnabled]="enableUpload"
  class="file-drop"
  style="display: grid; align-items: center; justify-content: center"
  data-cy="file-upload-area"
>
  <ion-row class="ion-align-items-center">
    <ion-col class="ion-text-center">
      <ion-icon class="file-upload-icon" name="cloud-upload" color="gc-blue"></ion-icon>
    </ion-col>
  </ion-row>

  <ion-row class="ion-align-items-center">
    <ion-col class="ion-text-center">
      <ion-label class="file-upload-label" color="dark">{{ instructionsLabel }}</ion-label>
    </ion-col>
  </ion-row>

  <ion-row class="ion-align-items-center">
    <ion-col class="ion-align-items-center ion-justify-content-center">
      <ion-item lines="none" class="ion-align-items-center">
        <ion-input
          #fileInput
          type="file"
          [hidden]="true"
          (change)="filesAdded.emit($event)"
          data-cy="file-added-list"
        ></ion-input>
        <ion-button color="dark" mode="ios" size="default" (click)="chooseFiles()" data-cy="file-upload-select">
          <ion-icon name="add-circle" slot="start"></ion-icon>
          {{ buttonLabel }}
        </ion-button>
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>
