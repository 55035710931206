import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TooltipDisplayComponent } from './tooltip-display/tooltip-display.component';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [TooltipDisplayComponent, TooltipComponent],
  imports: [CommonModule, IonicModule],
  exports: [TooltipComponent],
})
export class TooltipModule {}
