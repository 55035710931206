import { Injectable } from '@angular/core';
import { getKeyFromModel, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { exhaustMap, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { isViableDateNegotiation } from '~gc/domains/utils';
import { ifExists } from '~gc/shared/utils/operators';
import { ensureChildrenRequired, ensureExists } from '../../shared/utils/rxjs';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { isRole } from '../app/auth/auth.maps';
import { authenticatedRole, authenticatedUser } from '../app/auth/auth.selectors';
import { EmptyKey, EntityCriteria } from '../entity.service.utils';
import { AppState } from '../state';
import { UsersUIService } from '../users/users-ui.service';
import { installerNegotiation } from '../work-order-item-negotiation.selectors';
import { reviewInstallerFromPreview } from '../work/calendar/calendar.actions';
import { currentInstaller } from '../work/installer-review/installer-review.selectors';
import { WorkOrderItem } from '../work/work-order-item/work-order-item.model';
import { viewWorkOrder, viewWorkOrderFromModal } from '../work/work-order-ui.actions';
import { WorkOrder } from '../work/work-order/work-order.model';
import { currentWorkOrder } from '../work/work-order/work-order.state';
import { WorkUIService } from '../work/work-ui.service';
import { STATUS_GROUP_MAP } from '../work/work.maps';
import {
  allNegotiationItems,
  createNegotiationItem,
  deleteNegotiationItem,
  loadAllNegotiationItems,
  replaceNegotiationItem,
} from './negotiation-item/negotiation-item.state';
import { NegotiationUIService } from './negotiation-ui.service';
import { Negotiation } from './negotiation/negotiation.model';
import {
  allNegotiations,
  createNegotiation,
  deleteNegotiation,
  deselectNegotiation,
  loadAllNegotiations,
  replaceNegotiation,
  selectNegotiation,
} from './negotiation/negotiation.state';
import { negotiationsCanceled, negotiationsClosed, negotiationsViewed, pendingNegotiationSaved } from './negotiations.actions';

@Injectable()
export class NegotiationsEffects implements OnRunEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly negotiationUI: NegotiationUIService,
    private readonly workUI: WorkUIService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadNegotiationsForPendingWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrder, reviewInstallerFromPreview),
      filter(({ workOrder }) => STATUS_GROUP_MAP[workOrder.status] === 'pending'),
      map(({ workOrder }) =>
        loadAllNegotiations({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: getKeyFromModel(WorkOrder, workOrder),
            },
          } as EntityCriteria,
        }),
      ),
    ),
  );

  loadNegotiationItemsForPendingWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewWorkOrder, viewWorkOrderFromModal, reviewInstallerFromPreview),
      filter(({ workOrder }) => STATUS_GROUP_MAP[workOrder.status] === 'pending'),
      map(({ workOrder }) =>
        loadAllNegotiationItems({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: getKeyFromModel(WorkOrder, workOrder),
              [uriNameOfEntityOrEmpty(WorkOrderItem)]: EmptyKey,
            },
          } as EntityCriteria,
        }),
      ),
    ),
  );

  setCurrentNegotiation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(negotiationsViewed),
      withLatestFrom(this.store.select(installerNegotiation)),
      map(([, negotiation]) => negotiation),
      ensureExists(negotiation => !!negotiation),
      map(negotiation => selectNegotiation({ entity: negotiation })),
    ),
  );

  deleteCanceledNegotiation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(negotiationsCanceled),
      withLatestFrom(
        this.store.select(allNegotiations),
        this.store.select(currentInstaller),
        this.store.select(authenticatedUser),
      ),
      map(([{ workOrder }, negotiations, selectedInstaller, user]) =>
        negotiations.find(
          negotiation =>
            negotiation.workOrderId === workOrder.id && negotiation.installerId === (selectedInstaller?.id || user?.id),
        ),
      ),
      ensureExists(entity => !!entity),
      map(entity =>
        deleteNegotiation({
          entity,
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(WorkOrder)]: entity.workOrderId,
            },
          },
        }),
      ),
    ),
  );

  deleteCanceledNegotiationItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(negotiationsCanceled),
      withLatestFrom(
        this.store.select(allNegotiationItems),
        this.store.select(currentInstaller),
        this.store.select(authenticatedUser),
      ),
      map(([{ workOrder }, negotiations, selectedInstaller, user]) =>
        negotiations.filter(
          negotiation =>
            negotiation.workOrderId === workOrder.id && negotiation.installerId === (selectedInstaller?.id || user?.id),
        ),
      ),
      filter(entities => !!entities && !!entities.length),
      switchMap(entities =>
        from(entities).pipe(
          map(entity =>
            deleteNegotiationItem({
              entity,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(WorkOrder)]: entity.workOrderId,
                  [uriNameOfEntityOrEmpty(WorkOrderItem)]: entity.workOrderItemId,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  clearCurrentNegotiation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(negotiationsCanceled),
      map(() => deselectNegotiation()),
    ),
  );

  showNegotiationModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(negotiationsViewed),
      exhaustMap(() =>
        from(this.negotiationUI.showNegotiationModal()).pipe(
          map(( details ) => negotiationsClosed({ details })),
        ),
      ),
    ),
  );

  savePendingNegotiationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(negotiationsClosed),
      map(({ details }) => details),
      ifExists(),
      withLatestFrom(
        this.store.select(currentWorkOrder),
        this.store.select(authenticatedUser),
        this.store.select(authenticatedRole),
      ),
      map(([pendingNegotiation, workOrder, user, role]) => ({ pendingNegotiation, workOrder, user, role })),
      ensureChildrenRequired(value => !!value?.user && !!value.role),
      map(({ pendingNegotiation, workOrder, user, role }) =>
        pendingNegotiationSaved({
          pendingNegotiation,
          workOrder,
          user,
          role,
        }),
      ),
    ),
  );

  closeReviewInstallersWhenNegotiationsUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(negotiationsClosed),
      filter(({ details }) => !!details),
      switchMap(() => from(this.workUI.closeReviewInstallerModal()).pipe(
        // TODO: We should be doing something like this to track the automatic closing of the modal
        // map(() => installerReviewModalAutoClosed()),
      )),
    ),
    { dispatch: false },
  );

  savePendingNegotiationDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pendingNegotiationSaved),
      filter(({ pendingNegotiation, workOrder }) => isViableDateNegotiation(pendingNegotiation.negotiation, workOrder)),
      ensureChildrenRequired(
        value => !!value?.pendingNegotiation.negotiation && !!value?.workOrder?.id && !!value?.user?.id,
      ),
      map(({ pendingNegotiation, workOrder, user, role }) => ({
        negotiation: {
          ...pendingNegotiation.negotiation,
          installerId: isRole(role, 'installerLead') ? user.id : pendingNegotiation.negotiation.installerId,
        } as Negotiation,
        workOrder,
      })),
      map(({ negotiation, workOrder }) =>
        (negotiation.id == null ? createNegotiation : replaceNegotiation)({
          entity: negotiation,
          criteria: { parents: { [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id } },
        }),
      ),
    ),
  );

  savePendingNegotiationItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pendingNegotiationSaved),
      ensureChildrenRequired(
        value => !!value?.pendingNegotiation.negotiation && !!value?.workOrder?.id && !!value?.user?.id,
      ),
      map(({ pendingNegotiation, workOrder, user, role }) => ({
        negotiationItems: [
          ...(pendingNegotiation.negotiationItems
            ?.filter(item => !isRole(role, 'installerLead') || item.isAcceptedByInstaller || item.currentBidPrice)
            .map(item => ({
              ...item,
              installerId: isRole(role, 'installerLead') ? user.id : item.installerId,
              workOrderItem: undefined,
            })) ?? []),
        ],
        workOrder,
      })),
      switchMap(({ negotiationItems, workOrder }) => [
        ...negotiationItems.map(item =>
          (item.id == null ? createNegotiationItem : replaceNegotiationItem)({
            entity: item,
            criteria: {
              parents: {
                [uriNameOfEntityOrEmpty(WorkOrder)]: workOrder.id,
                [uriNameOfEntityOrEmpty(WorkOrderItem)]: item.workOrderItemId,
              },
            },
          }),
        ),
      ]),
    ),
  );
}
