import { createReducer, on } from '@ngrx/store';
import {
  claimTrainingModalCancelled,
  claimTrainingModalDone,
  trainingClaimed,
} from '../../../core/installer-profile';
import { Training } from '../../../domains/training/training/training.model';
import { claimDateChosen, claimTrainingCancelled, trainingClaiming } from '../claim-training-modal.actions';

export interface UIState {
  pending?: Training;
  claiming?: Training;
  added: number[];
}

export const initialState: UIState = {
  added: [],
};

export const uiReducer = createReducer(
  initialState,
  on(trainingClaiming, (state: UIState, { training }): UIState => ({ ...state, pending: training })),
  on(claimTrainingCancelled, (state: UIState): UIState => ({ ...state, claiming: undefined, pending: undefined })),
  on(claimDateChosen, (state: UIState): UIState => ({ ...state, claiming: state.pending, pending: undefined })),
  on(
    trainingClaimed,
    (state: UIState, { training }): UIState => ({
      ...state,
      added: [...state.added, training.id!],
      claiming: undefined,
    }),
  ),
  on(claimTrainingModalCancelled, claimTrainingModalDone, () => initialState),
);
