import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { LiveUpdateModalComponent } from './live-update-modal.component';

@NgModule({
  imports: [CommonModule, IonicModule, PushModule],
  exports: [LiveUpdateModalComponent],
  declarations: [LiveUpdateModalComponent],
})
export class LiveUpdateModalModule {}
