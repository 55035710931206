<ng-container *ngIf="isPending$ | push | not; else skeleton">
  <ng-container *ngIf="auth.user$ | push as user">
    <ng-container *rxLet="workOrderRelations.workOrderItemDisplayMode$; let displayOptions">
      <ng-container *rxLet="workOrderRelations.workOrderItemDisplayGroups$; let displayGroups">
        <gc-templated-work-order-item-list
          [groups]="workOrderItems.workOrderItemGroups$ | push"
          [groupHeader]="groupHeaders"
          [itemNote]="prices"
          [itemExpander]="expanders"
          [itemExtra]="extras"
          [inlineButtons]="inlineButtons"
          [expandedDetail]="details"
          [role]="auth.role$ | push"
          [allowedPriceBreakdown]="workOrderRelations.userAllowedPrivateDetails$ | push"
          [displayGroups]="displayGroups"
          [permissions]="auth.tokenPermissions$ | push"
        ></gc-templated-work-order-item-list>

        <ng-template #groupHeaders let-group="group">
          <gc-item-group-header
            [displayGroups]="displayGroups"
            [permissions]="auth.tokenPermissions$ | push"
            [group]="group"
          ></gc-item-group-header>
        </ng-template>

        <ng-template #inlineButtons let-group="group" let-item="item">
          <gc-add-deduction-button
            *ngIf="reportsProgress$ | push | not"
            [displayGroups]="displayGroups"
            [permissions]="auth.tokenPermissions$ | push"
            [group]="group"
            [workOrder]="workOrders.current$ | push"
            [item]="item"
            (added)="changeOrders.createChangeOrderDeduction(item)"
          ></gc-add-deduction-button>
        </ng-template>

        <ng-template #prices let-group="group" let-item="item">
          <gc-item-price-display
            *ngIf="reportsProgress$ | push | not"
            [allowedPrivateDetails]="workOrderRelations.userAllowedPrivateDetails$ | push"
            [permissions]="auth.tokenPermissions$ | push"
            [displayGroups]="displayGroups"
            [group]="group"
            [item]="item"
          ></gc-item-price-display>
        </ng-template>

        <ng-template #expanders let-group="group" let-item="item">
          <gc-item-expanders
            [displayGroups]="displayGroups"
            [permissions]="auth.tokenPermissions$ | push"
            [group]="group"
            [item]="item"
            [expandedItem]="expandedItem"
            (expanded)="expandedItem = $event"
          ></gc-item-expanders>
        </ng-template>

        <ng-template #extras let-group="group" let-item="item">
          <!-- TODO: Add support for extra line item details on change-add items -->
          <gc-units-pending
            *ngIf="workOrderRelations.userAllowedPrivateDetails$ | push"
            [item]="item"
          ></gc-units-pending>
          <gc-change-order-pending-acceptance
            [displayGroups]="displayGroups"
            [permissions]="auth.tokenPermissions$ | push"
            [userType]="user | userType"
            [group]="group"
            [item]="item"
            (voided)="changeOrders.voidPendingChangeOrderItem($event)"
          ></gc-change-order-pending-acceptance>
          <gc-change-order-approval
            [displayGroups]="displayGroups"
            [permissions]="auth.tokenPermissions$ | push"
            [userType]="user | userType"
            [userId]="user.id"
            [group]="group"
            [item]="item"
            [workOrder]="workOrders.current$ | push"
            (accepted)="changeOrders.acceptItemByCurrentUser($event)"
            (approved)="changeOrders.approveItemByCurrentUser($event)"
            (declined)="changeOrders.declineItemByCurrentUser($event)"
            (called)="changeOrders.callProjectManager($event)"
          ></gc-change-order-approval>
        </ng-template>

        <ng-template #details let-group="group" let-item="item">
          <gc-item-expanded-details
            [displayGroups]="displayGroups"
            [permissions]="auth.tokenPermissions$ | push"
            [userType]="user | userType"
            [group]="group"
            [item]="item"
            [expandedItem]="expandedItem"
            [workOrder]="workOrders.current$ | push"
            [user]="auth.user$ | push"
            [role]="auth.role$ | push"
            (collapsed)="expandedItem = null"
            (reviewed)="installerReview.reviewInstaller($event)"
            (accepted)="changeOrders.acceptModifyByCurrentUser($event)"
            (pmCalled)="changeOrders.callProjectManager($event)"
            (voided)="changeOrders.voidPendingChangeOrderModify($event)"
          ></gc-item-expanded-details>
        </ng-template>

        <gc-work-order-item-total
          *ngIf="
            (workOrderItems.currentWorkOrderItems$ | push)?.length &&
            (auth.tokenPermissions$ | push | hasPermissions: 'work-order.item.price:view')
          "
          [allowedPrivateDetails]="workOrderRelations.userAllowedPrivateDetails$ | push"
          [workOrderItemGroups]="workOrderItems.workOrderItemGroups$ | push"
        ></gc-work-order-item-total>

        <gc-empty-list-warning *ngIf="!(workOrderItems.currentWorkOrderItems$ | push)?.length">
          Work Order Items will appear here once you've added them.
        </gc-empty-list-warning>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #skeleton>
  <ion-list *ngIf="workOrderItems.isLoading$ | push">
    <ion-item *ngFor="let i of [1, 2, 3]">
      <ion-label>
        <h2>
          <strong>
            <ion-skeleton-text animated style="height: 1.1em; width: 40%"></ion-skeleton-text>
          </strong>
          <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
        </h2>
        <p>
          <strong>
            <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
          </strong>
        </p>
      </ion-label>
      <ion-skeleton-text animated slot="end" style="width: 15%; height: 25%; margin-bottom: 2rem"></ion-skeleton-text>
    </ion-item>
  </ion-list>
</ng-template>
