/* First object has merge priority. Any properties on second that do not exist on first will be added. */
export const deepObjectMerge = <T extends object>(first: T, second?: T): T =>
  !second
    ? first
    : (Object.keys(second) as unknown as string[]).reduce(
        (merged: T, key: string) => ({
          ...merged,
          [key]: merged[key as keyof T]
            ? typeof merged[key as keyof T] === 'object' && !Array.isArray(merged[key as keyof T])
              ? deepObjectMerge(merged[key as keyof T] as object, second[key as keyof T] as object | undefined)
              : merged[key as keyof T]
            : second[key as keyof T],
        }),
        { ...first },
      );
