import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { TrainingFile } from './training-file.model';

export const {
  initialState,
  selectors: { selectAll: allTrainingFiles },
  actions: { loadAll: loadAllTrainingFiles, createSuccess: createTrainingFileSucceeded },
} = buildState(TrainingFile);

export function trainingFileReducer(state = initialState): IEntityState<TrainingFile> {
  return state;
}
