import { Injectable } from '@angular/core';
import { uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProductInstallationTypeClaimed } from '../../domains/training/product-installation-types-claimed/product-installation-types-claimed.model';
import {
  createTrainingClaim,
  deleteTrainingClaimed,
} from '../../domains/training/trainings-claimed/trainings-claimed.state';
import { User } from '../../domains/users';
import { ensureChildrenRequired } from '../../shared/utils/rxjs';
import { TrainingsUIService } from './trainings-ui.service';
import {
  claimTrainingModalCancelled,
  claimTrainingModalDone,
  showClaimCategorizedTrainingModal,
  trainingClaimed,
  trainingClaimRemoved,
} from './trainings.actions';
import { selectedInstallTypeClaim } from './trainings.selectors';

@Injectable()
export class TrainingsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly ui: TrainingsUIService,
  ) {}

  claimTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trainingClaimed),
      withLatestFrom(this.store.select(selectedInstallTypeClaim)),
      map(([{ date, training }, installTypeClaimed]) => ({
        date,
        training,
        installTypeClaimed,
      })),
      ensureChildrenRequired<
        { date: string; training?: any; installTypeClaimed?: any },
        { date: string; training: any; installTypeClaimed: any }
      >(value => !!value?.training && !!value.date && !!value.installTypeClaimed),
      map(({ date, training, installTypeClaimed }) =>
        createTrainingClaim({
          entity: {
            userId: installTypeClaimed.userId,
            categoryId: training.categoryId,
            trainingId: training.id,
            productInstallationTypeId: installTypeClaimed.productInstallationTypeId,
            productInstallationTypeClaimedId: installTypeClaimed.id,
            dateClaimed: new Date(date),
            isVerified: false,
          },
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: installTypeClaimed.userId,
              [uriNameOfEntityOrEmpty(ProductInstallationTypeClaimed)]: installTypeClaimed.id,
            },
          },
        }),
      ),
    ),
  );

  removeClaimedTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trainingClaimRemoved),
      switchMap(({ claim }) =>
        this.ui.confirmRemoveTrainingClaim().pipe(
          filter(({ role }) => role === 'ok'),
          map(() =>
            deleteTrainingClaimed({
              entity: claim,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: claim.userId,
                  [uriNameOfEntityOrEmpty(ProductInstallationTypeClaimed)]: claim.productInstallationTypeClaimedId,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  showClaimCategorizedTrainingModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showClaimCategorizedTrainingModal),
      exhaustMap(() =>
        this.ui
          .showClaimTrainingModal()
          .pipe(map(({ role }) => (role === 'ok' ? claimTrainingModalDone() : claimTrainingModalCancelled()))),
      ),
    ),
  );
}
