import { createAction, props } from '@ngrx/store';
import { PendingFile } from '../../models/pending-file';
import { Invoice } from '../invoice/invoice.model';

export const queueInvoiceFile = createAction('[InvoiceFile] Queue File', props<{ pendingFile: PendingFile }>());

export const removeQueuedInvoiceFile = createAction(
  '[InvoiceFile] Remove Queued File',
  props<{ pendingFile: PendingFile }>(),
);

export const uploadQueuedFiles = createAction(
  '[InvoiceFile] Upload Queued Files',
  props<{ invoice: Invoice; files: PendingFile[] }>(),
);
