import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { PipesModule } from '~gc/shared/pipes/pipes.module';
import { StartEndDateModule } from '../start-end-date/start-end-date.module';
import { ScheduleComponent } from './schedule.component';

@NgModule({
  declarations: [ScheduleComponent],
  exports: [ScheduleComponent],
  imports: [CommonModule, IonicModule, MatCardModule, PipesModule, PushModule, StartEndDateModule],
})
export class ScheduleModule {}
