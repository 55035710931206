import { not } from './logic';

export const joinArrays = <T>(...arrays: (T[] | undefined)[]): T[] =>
  arrays.reduce((all: T[], arr: T[] | undefined) => [...((all as any) ?? []), ...(arr ?? [])], []) as T[];

export const keyBy = <T extends { [key: string]: any }>(arr: Array<T>, key: keyof T): { [key: string]: T } =>
  arr.reduce(
    (keyedSoFar, currentEntry) => ({
      ...keyedSoFar,
      [currentEntry[key]]: currentEntry,
    }),
    {},
  );

export const groupBy = <T extends { [key: string]: any }>(arr: Array<T>, key: keyof T): { [key: string]: T[] } =>
  arr.reduce<{ [workOrderId: string]: T[] }>(
    (groupedSoFar, currentEntry) => ({
      ...groupedSoFar,
      [currentEntry[key]]: [...(groupedSoFar[currentEntry[key]] || []), currentEntry],
    }),
    {},
  );

export const extract =
  <T extends { [key: string]: any }, K extends keyof T>(key: K) =>
  (arr: T[]): T[K][] =>
    arr.map(item => item[key]);

type Extraction<T, K extends keyof T> = { [P in K]: T[P] };

export const extractMany =
  <T extends { [key: string]: any }, K extends keyof T>(...keys: K[]) =>
  (arr: T[]): Extraction<T, K>[] =>
    arr.map(item => keys.reduce((agg, key: K) => ({ ...agg, [key]: item[key] }), {} as Extraction<T, K>));

export const isEmpty = (arr: any[]): boolean => !arr?.length;
export const isNotEmpty = not(isEmpty);

export const countOf = (array: any[]): number => array?.length || 0;

export const countAll = (count: number) => count + 1;

export const atIndex =
  (index: number) =>
  <T>(array: T[]): T =>
    array[index];

export const mapEach =
  <T, R>(cb: (value: T) => R) =>
  (array: T[]): R[] =>
    array.map(cb);

export const first = <T>(arr?: T[]): T | undefined => (!!arr?.length ? arr[0] : undefined);

export const last = <T>(arr?: T[]): T | undefined => (!!arr?.length ? arr[arr.length - 1] : undefined);

export const sum = (sum: number, value: number) => sum + value;
