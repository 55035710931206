<ion-list *ngIf="codeInputs.length === entries?.length">
  <ion-list-header>
    <h2 data-cy="phone-number-title">Phone Number(s)</h2>
  </ion-list-header>
  <ion-item *ngFor="let entry of entries; index as i" [attr.data-cy]="'phone-number-entry-' + entry.phoneNumber.number">
    <ion-text
      class="flex align-items-center"
      [color]="entry.phoneNumber.isVerified ? 'dark' : 'medium'"
      style="min-width: 150px"
    >
      <ion-spinner *ngIf="entry.isVerifying" data-cy="phone-number-verification-spinner"></ion-spinner>
      <ion-icon
        *ngIf="!entry.isVerifying"
        [name]="entry.phoneNumber.isVerified ? 'checkmark-circle' : 'help-circle'"
        [color]="entry.phoneNumber.isVerified ? 'gc-green' : 'medium'"
        size="large"
        class="mr-10"
        style="min-width: 32px; min-height: 32px"
        [attr.data-cy]="'phone-number-verified-' + entry.phoneNumber.isVerified"
      ></ion-icon>
      {{ entry.phoneNumber.number | phoneFormat }}
      <ion-badge
        *ngIf="entry.phoneNumber.type !== 'MOBILE' || entry.phoneNumber.isVerified"
        color="medium"
        class="ml-5"
        [attr.data-cy]="entry.type + '-phone-number-type'"
        >{{ entry.type }}
      </ion-badge>
    </ion-text>
    <ion-badge
      *ngIf="canEdit && !entry.phoneNumber.consentAcquiredAt && entry.phoneNumber.type === 'MOBILE'"
      color="danger"
      class="ml-10"
      data-cy="no-consent-badge"
    >
      No Consent
    </ion-badge>
    <ion-input
      *ngIf="canEdit && !entry.phoneNumber.isVerified && !entry.isVerifying"
      mode="ios"
      slot="end"
      maxlength="6"
      [formControl]="codeInputs[i]"
      placeholder="Enter code"
      class="pl-10"
      style="max-width: 96px"
      (ionInput)="tryCode(codeInputs[i], entry.phoneNumber)"
      data-cy="phone-number-verification-code-input"
    ></ion-input>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="canEdit && !entry.phoneNumber.isVerified && !entry.isVerifying"
        slot="end"
        fill="clear"
        color="dark"
        (click)="refreshed.emit(entry.phoneNumber)"
        data-cy="phone-number-refresh-button"
      >
        <ion-icon name="refresh" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button
        *ngIf="canEdit"
        fill="clear"
        color="danger"
        slot="end"
        (click)="removed.emit(entry.phoneNumber)"
        data-cy="remove-phone-number-button"
      >
        <ion-icon name="trash" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-item>

  <form *ngIf="canEdit && form" [formGroup]="form" data-cy="add-new-phone-number-form">
    <ion-card [color]="submitted && form.invalid" class="ion-padding ion-margin">
      <ion-input
        formControlName="number"
        placeholder="(xxx) xxx-xxxx"
        label="Add New Phone Number"
        labelPlacement="stacked"
        fill="outline"
        [maskito]="phoneMask"
        [maskitoElement]="maskPredicate"
        [color]="submitted && form.invalid ? 'danger' : undefined"
        data-cy="new-phone-number-input"
      ></ion-input>
      <ion-select
        formControlName="type"
        placeholder="Type"
        interface="action-sheet"
        label="Phone Number Type"
        labelPlacement="stacked"
        fill="outline"
        class="my-20"
        data-cy="new-phone-number-type-select"
      >
        <ion-select-option value="MOBILE" data-cy="phone-number-select-type-mobile">
          <ion-icon name="phone-portrait"></ion-icon>
          Mobile
        </ion-select-option>
        <ion-select-option value="OFFICE" data-cy="phone-number-select-type-office">
          <ion-icon name="call"></ion-icon>
          Office
        </ion-select-option>
        <ion-select-option value="OTHER" data-cy="phone-number-select-type-other">
          <ion-icon name="call"></ion-icon>
          Other
        </ion-select-option>
      </ion-select>
      <ion-text
        *ngIf="form.number.value && form.type.value === 'MOBILE'"
        class="flex flex-column"
        data-cy="consent-text"
        >By checking this box, you agree to receive recurring automated transactional text (SMS) messages (e.g. new work
        opportunities, changes to awarded work) from us at this mobile number. Your consent here is not a condition of
        participation. Reply HELP for help and STOP to cancel. Message frequency varies. Message and data rates may
        apply.
        <ion-checkbox
          class="py-10 ion-text-wrap"
          formControlName="consent"
          labelPlacement="end"
          justify="start"
          data-cy="consent-checkbox"
          >I want to receive SMS from time to time.
        </ion-checkbox>
      </ion-text>
      <ion-button
        *ngIf="form.number.value"
        class="ion-margin"
        expand="block"
        color="gc-green"
        (click)="add()"
        mode="ios"
        data-cy="save-phone-number-button"
        >Save Phone Number
      </ion-button>
    </ion-card>
  </form>
</ion-list>
