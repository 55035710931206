import { ActionReducerMap } from '@ngrx/store';
import { httpTrafficReducer, HTTPTrafficState } from './http-traffic.state';

export interface HTTPState {
  traffic: HTTPTrafficState;
}

export const reducer: ActionReducerMap<HTTPState> = {
  traffic: httpTrafficReducer,
};
