import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DetailedInstaller } from '../../../../domains/models/detailed-installer';
import { WorkOrder } from '../../../../domains/work/work-order/work-order.model';

@Component({
  selector: 'gc-company-user-installer-options',
  templateUrl: './company-user-installer-options.component.html',
  styleUrls: ['./company-user-installer-options.component.scss'],
})
export class CompanyUserInstallerOptionsComponent {
  @Input() workOrder!: WorkOrder;
  @Input() installer!: DetailedInstaller;
  @Input() isUnderNegotiation!: boolean;
  @Input() isJumpstarterOffer!: boolean;
  @Output() awarded = new EventEmitter<{ workOrder: WorkOrder; installer: DetailedInstaller }>();
  @Output() negotiated = new EventEmitter();
  @Output() viewInstallerFullProfile = new EventEmitter<DetailedInstaller>();
  @Output() acceptInstallerOffer = new EventEmitter<DetailedInstaller>();
  @Output() declineInstallerOffer = new EventEmitter<DetailedInstaller>();
}
