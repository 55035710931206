import { createSelector } from '@ngrx/store';
import { selectedInstallType } from '../../core/installer-profile';
import { ProductInstallationSubtypesClaimed } from '../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.model';
import { allProductInstallationSubtypesClaimed } from '../../domains/training/product-installation-subtypes-claimed/product-installation-subtypes-claimed.state';
import { ProductInstallationSubtype } from '../../domains/training/product-installation-subtypes/product-installation-subtypes.model';
import { allProductInstallationSubtypes } from '../../domains/training/product-installation-subtypes/product-installation-subtypes.state';
import { ItemChooserOption } from '../../shared/components/item-chooser-list/item-chooser-option.model';
import { ClaimProductInstallationSubtypesContext } from './models/claim-product-installation-subtypes-context.model';
import { added } from './store/ui.selectors';

export const subtypesForInstallationType = createSelector(
  selectedInstallType,
  allProductInstallationSubtypes,
  (installType, subtypes): ProductInstallationSubtype[] | undefined =>
    installType ? subtypes.filter(subtype => subtype.productInstallationTypeId === installType.id) : undefined,
);

export const claimedSubtypesForInstallationType = createSelector(
  subtypesForInstallationType,
  allProductInstallationSubtypesClaimed,
  (subtypes, subtypesClaimed): ProductInstallationSubtypesClaimed[] | undefined =>
    subtypes?.length
      ? subtypesClaimed.filter(subtypeClaimed =>
          subtypes.some(subtype => subtype.id === subtypeClaimed.productInstallationSubtypeId),
        )
      : undefined,
);

export const unclaimedSubtypesForInstallationType = createSelector(
  subtypesForInstallationType,
  claimedSubtypesForInstallationType,
  added,
  (subtypes, subtypesClaimed, addedIds): ProductInstallationSubtype[] | undefined =>
    subtypes?.length
      ? subtypes.filter(
          subtype =>
            addedIds?.some(id => id === subtype.id) ||
            !subtypesClaimed?.some(subtypeClaimed => subtypeClaimed.productInstallationSubtypeId === subtype.id),
        )
      : undefined,
);

export const unclaimedSubtypeChooserOptions = createSelector(
  unclaimedSubtypesForInstallationType,
  added,
  (unclaimedSubtypes, addedIds): ItemChooserOption<ProductInstallationSubtype>[] | undefined =>
    unclaimedSubtypes
      ? unclaimedSubtypes.map(subtype => ({
          id: subtype.id,
          label: subtype.name,
          value: subtype,
          added: addedIds?.some(id => id === subtype.id) ?? false,
          adding: false,
        }))
      : undefined,
);
export const claimProductInstallationSubtypesContext = createSelector(
  selectedInstallType,
  unclaimedSubtypeChooserOptions,
  (installType, items): ClaimProductInstallationSubtypesContext | undefined =>
    installType
      ? {
          installType: installType,
          items,
        }
      : undefined,
);
