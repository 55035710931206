import { Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ListOption } from '../types/list-options';

@Component({
  selector: 'gc-custom-list-popover',
  templateUrl: './custom-list-popover.component.html',
  styleUrls: ['./custom-list-popover.component.scss'],
})
export class CustomListPopoverComponent {
  @Input() title!: string;
  @Input() subtitle?: string;
  @Input() listOptions!: ListOption[];
  @Input() multi = false;
  @Input() doneText = 'Done';
  selected: ListOption[] = [];

  constructor(public popoverGC: PopoverController) {}

  select(item: ListOption) {
    if (!this.multi) {
      this.popoverGC.dismiss(item, 'act');
    }
  }

  updateSelected(item: ListOption) {
    this.selected = this.selected.includes(item) ? this.selected.filter(sel => sel !== item) : [...this.selected, item];
  }
}
