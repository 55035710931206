import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { State } from '~gc/domains/locations';

@Pipe({ name: 'matchingStates', pure: false })
export class MatchingStatesPipe implements PipeTransform {
  transform(
    states: State[],
    control: AbstractControl & {
      availableOptions?: string[];
    },
  ): State[] {
    // TODO: Need for ! on availableOptions should not be necessary, given we have type guarded it earlier in the line!!!
    return !!control.availableOptions ? states.filter(state => control.availableOptions!.includes(state.code)) : states;
  }
}
