import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';
import { from } from 'rxjs';
import { exhaustMap, filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { showClaimProductInstallationTypeModal } from '~gc/core/installer-profile/product-installation-type.actions';
import { environment } from '../../../environments/environment';
import {
  currentInstallerProductInstallationTypesClaimed,
  installTypeClaimIdSelected,
  showClaimCategorizedTrainingModal,
  showClaimSubtypesModal,
  showEnhancedInstallerProfile,
} from '../../core/installer-profile';
import { currentInstallerPhoneNumbers } from '../../core/installer-profile/phone-number-details.selectors';
import { currentInstaller } from '../../core/installer-profile/store/profile.selectors';
import {
  addPhoneNumber,
  confirmPhoneNumberRemoval,
  phoneNumberVerificationInitiated,
  refreshPhoneNumberCode,
  warnMinimumPhoneNumbersRequired,
} from '../../core/profile/phone-numbers.actions';
import { BrowserService } from '../../domains/browser.service';
import { PhoneNumber } from '../../domains/phone-number';
import { PhoneNumberUIService } from '../../domains/phone-number/phone-number-ui.service';
import { clearPhoneNumbers } from '../../domains/phone-number/phone-number.state';
import { Installer, ProfileFacade } from '../../domains/users';
import { Connection } from '../../domains/users/connection/connection.model';
import { disconnectUser, removeUser } from '../../domains/users/users-ui.actions';
import { ensureChildrenRequired, ensureExists } from '../../shared/utils/rxjs';
import { WithId } from '../../shared/utils/types';
import { ViewInstallerProfileEnhancedUiService } from './view-installer-profile-enhanced-ui.service';
import {
  defaultClaimSelected,
  editMyProfileSelected,
  installerCalled,
  installerEmailed,
  installerWebsiteVisited,
  installTypeClaimSelected,
  optionsMenuOpened,
  phoneNumberCodeRefreshed,
  phoneNumberRemoving,
  phoneNumbersAdding,
  phoneNumberVerifying,
  productInstallationSubtypeAddingFromEducationAndTraining,
  productInstallationSubtypeAddingFromOverview,
  productInstallationTypeAdding,
  trainingClaimAdding,
} from './view-installer-profile-enhanced.actions';


const PROFILE_MENU_OPTIONS_ACTIONS: Record<string, ActionCreator<string, any>> = {
  [disconnectUser.type]: (installer: Installer, connection: Connection) => disconnectUser({ connection }),
  [removeUser.type]: (installer: Installer) => removeUser({ user: installer }),
};

@Injectable()
export class ViewInstallerProfileEnhancedEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly popovers: PopoverController,
    private readonly phoneNumbersUI: PhoneNumberUIService,
    private readonly ui: ViewInstallerProfileEnhancedUiService,
    private readonly browser: BrowserService,
    private profileFacade: ProfileFacade,
  ) {}

  editMyProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editMyProfileSelected),
        tap(() => this.profileFacade.editProfile()),
      ),
    { dispatch: false },
  );

  callInstaller$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(installerCalled),
        filter(({ installer }) => !!installer),
        exhaustMap(() => from(this.phoneNumbersUI.showCallPhoneNumberModal()).pipe(map(() => clearPhoneNumbers()))),
      ),
    { dispatch: false },
  );

  showProfileMenuOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(optionsMenuOpened),
      switchMap(({ event, installer, connection }) => {
        return from(this.ui.showProfileActionsMenu(event, installer, connection)).pipe(
          ensureChildrenRequired(value => value?.role === 'act' && !!value?.data?.name),
          map(({ data }) => {
            if (connection) {
              return PROFILE_MENU_OPTIONS_ACTIONS[data.name](installer, connection);
            } else {
              return PROFILE_MENU_OPTIONS_ACTIONS[data.name](installer);
            }
          }),
          filter(action => !!action),
        );
      }),
    ),
  );

  visitInstallerWebsite$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(installerWebsiteVisited),
        map(({ installer }) => `${environment.apiRoot.profiles}${installer.externalId}`),
        tap(url => this.browser.openUrl(url)),
      ),
    { dispatch: false },
  );

  openInstallerEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(installerEmailed),
        map(({ installer }) => installer.email),
        tap((email: string) => this.browser.openEmail(email)),
      ),
    { dispatch: false },
  );

  setDefaultTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showEnhancedInstallerProfile),
      switchMap(() =>
        this.store.select(currentInstallerProductInstallationTypesClaimed).pipe(
          ensureExists(value => !!value?.length),
          map(claims => claims[0]),
          map(claim => defaultClaimSelected({ claim })),
          first(),
        ),
      ),
    ),
  );

  trackSelectedInstallTypeClaimId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(defaultClaimSelected, installTypeClaimSelected),
      map(({ claim }) => installTypeClaimIdSelected({ installTypeClaimId: claim.id! })), // TODO: Fix with ensureRequired
    ),
  );

  showClaimProductInstallationTypeModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallationTypeAdding),
      map(() => showClaimProductInstallationTypeModal()),
    ),
  );

  showClaimCategorizedTrainingModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(trainingClaimAdding),
      map(({ category }) => showClaimCategorizedTrainingModal({ category })),
    ),
  );

  showClaimSubtypesModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallationSubtypeAddingFromOverview, productInstallationSubtypeAddingFromEducationAndTraining),
      map(({ installType }) => showClaimSubtypesModal({ installType })),
    ),
  );

  verifyPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberVerifying),
      withLatestFrom(this.store.select(currentInstaller)),
      map(([{ phoneNumber, code }, installer]) => ({ phoneNumber, code, installer })),
      ensureChildrenRequired<
        { phoneNumber: PhoneNumber; code: string; installer?: Installer },
        { phoneNumber: PhoneNumber; code: string; installer: WithId<Installer> }
      >(value => !!value?.phoneNumber && !!value.code && !!value.installer),
      map(({ phoneNumber, code, installer }) =>
        phoneNumberVerificationInitiated({
          phoneNumber,
          code,
          user: installer,
        }),
      ),
    ),
  );

  addPhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumbersAdding),
      withLatestFrom(this.store.select(currentInstaller)),
      map(([{ phoneNumber }, installer]) => ({ phoneNumber, installer })),
      ensureChildrenRequired<
        { phoneNumber: PhoneNumber; installer?: Installer },
        { phoneNumber: PhoneNumber; installer: WithId<Installer> }
      >(value => !!value?.installer),
      map(({ phoneNumber, installer }) => addPhoneNumber({ phoneNumber, user: installer })),
    ),
  );

  confirmRemovePhoneNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberRemoving),
      withLatestFrom(this.store.select(currentInstaller), this.store.select(currentInstallerPhoneNumbers)),
      filter(([, , phoneNumbers]) => (phoneNumbers?.length ?? 0) > 1),
      map(([{ phoneNumber }, installer]) => ({ phoneNumber, installer })),
      ensureChildrenRequired<
        { phoneNumber: PhoneNumber; installer?: Installer },
        { phoneNumber: PhoneNumber; installer: WithId<Installer> }
      >(value => !!value?.installer),
      map(({ phoneNumber, installer }) => confirmPhoneNumberRemoval({ phoneNumber, user: installer })),
    ),
  );

  warnNotEnoughPhoneNumbersForRemoval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberRemoving),
      withLatestFrom(this.store.select(currentInstallerPhoneNumbers)),
      filter(([, phoneNumbers]) => (phoneNumbers?.length ?? 0) <= 1),
      map(() => warnMinimumPhoneNumbersRequired()),
    ),
  );

  refreshPhoneNumberCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(phoneNumberCodeRefreshed),
      withLatestFrom(this.store.select(currentInstaller)),
      map(([{ phoneNumber }, installer]) => ({ phoneNumber, installer })),
      ensureChildrenRequired<
        { phoneNumber: PhoneNumber; installer?: Installer },
        { phoneNumber: PhoneNumber; installer: WithId<Installer> }
      >(value => !!value?.installer),
      map(({ phoneNumber, installer }) => refreshPhoneNumberCode({ phoneNumber, user: installer })),
    ),
  );
}
