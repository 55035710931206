import { createSelector } from '@ngrx/store';
import { ListOption } from '../../shared/popovers/types/list-options';
import { view } from '../../shared/utils/ngrx';
import { isRole } from '../app/auth/auth.maps';
import { authenticatedRole, authenticatedUser } from '../app/auth/auth.selectors';
import { reportsProgress } from '../invoice-ui.utils';
import { currentRouteUrl } from '../router.selectors';
import { User } from '../users';
import { allInstallers } from '../users/user/user.selectors';
import { allUsers } from '../users/user/user.state';
import { WorkOrder } from '../work/work-order/work-order.model';
import { currentWorkOrder } from '../work/work-order/work-order.state';
import {
  approveInvoice,
  archiveInvoice,
  declineInvoice,
  payInvoice,
  signLienRelease,
  viewInvoice,
  viewLienRelease,
  voidInvoice,
} from './invoice-and-payment.actions';
import { interactingInvoice } from './invoice-and-payment.selectors';
import { Invoice, invoiceIsStatus, InvoiceStatus } from './invoice/invoice.model';

export interface ViewInvoiceContext {
  url: string;
  invoiceType: string;
  invoiceNumberLabel: string;
  options: {
    showViewWorkOrder: boolean;
  };
}

export const invoicingContext = createSelector(
  currentWorkOrder,
  currentRouteUrl,
  authenticatedUser,
  allUsers,
  view(
    'invoicingContext',
    (workOrder: WorkOrder, url: string, user: User, users: User[]): ViewInvoiceContext & any => ({
      url,
      options: { showViewWorkOrder: !url?.startsWith('/app/work/orders') },
      invoiceType: reportsProgress(users, user, workOrder) ? 'Progress' : 'Invoice',
      invoiceNumberLabel: reportsProgress(users, user, workOrder) ? 'Progress Report No. ' : 'Invoice No. ',
    }),
    false,
  ),
);

export const isProgressReportOnly = createSelector(allUsers, authenticatedUser, currentWorkOrder, reportsProgress);

export const buildInstallerInvoiceOptions = (doesReportProgress: boolean, invoice: Invoice): ListOption[] =>
  invoice
    ? [
        ...(InvoiceStatus.PaidPendingLienRelease === invoice.status
          ? [{ name: signLienRelease.type, label: 'Sign Lien Release', color: 'gc-green' }]
          : invoiceIsStatus(invoice, InvoiceStatus.Paid, InvoiceStatus.PaymentProcessing) && invoice.invoiceAmount !== 0
          ? [{ name: viewLienRelease.type, label: 'Show Lien Release' }]
          : []),
        { name: viewInvoice.type, label: doesReportProgress ? 'View Progress' : 'View Invoice' },
        ...(invoiceIsStatus(invoice, InvoiceStatus.New, InvoiceStatus.Read, InvoiceStatus.ApprovedPendingPayment)
          ? [
              {
                name: voidInvoice.type,
                label: doesReportProgress ? 'Remove Progress' : 'Void Invoice',
                color: 'danger',
              },
            ]
          : []),
        ...(invoiceIsStatus(invoice, InvoiceStatus.Declined)
          ? [{ name: archiveInvoice.type, label: doesReportProgress ? 'Archive Progress' : 'Archive Invoice' }]
          : []),
      ]
    : [];

export const buildCompanyInvoiceOptions = (doesReportProgress: boolean, invoice: Invoice, role: string) =>
  invoice
    ? [
        ...([InvoiceStatus.New, InvoiceStatus.Read].includes(invoice.status)
          ? [
              { name: viewInvoice.type, label: 'Review' },
              { name: approveInvoice.type, label: 'Approve' },
              ...(!doesReportProgress ? [{ name: declineInvoice.type, label: 'Decline', color: 'danger' }] : []),
            ]
          : []),
        ...([InvoiceStatus.ApprovedPendingPayment].includes(invoice.status)
          ? [
              ...(isRole(role, 'companyAdmin')
                ? [
                    { name: payInvoice.type, label: 'Pay' },
                    { name: viewInvoice.type, label: 'View' },
                    ...(!doesReportProgress ? [{ name: declineInvoice.type, label: 'Decline', color: 'danger' }] : []),
                  ]
                : []),
            ]
          : []),
        ...([InvoiceStatus.PaymentProcessing, InvoiceStatus.PaidPendingLienRelease].includes(invoice.status)
          ? [{ name: viewInvoice.type, label: doesReportProgress ? 'View Progress' : 'View Invoice' }]
          : []),
        ...([InvoiceStatus.Paid].includes(invoice.status)
          ? [
              { name: viewInvoice.type, label: doesReportProgress ? 'View Progress' : 'View Invoice' },
              ...(invoice.invoiceAmount === 0 ? [] : [{ name: viewLienRelease.type, label: 'View Lien Release' }]),
            ]
          : []),
        ...([InvoiceStatus.Declined].includes(invoice.status)
          ? [{ name: viewInvoice.type, label: doesReportProgress ? 'View Progress' : 'View Invoice' }]
          : []),
      ]
    : [];

export const reportsProgressForCurrentWorkOrder = createSelector(
  allUsers,
  authenticatedUser,
  currentWorkOrder,
  reportsProgress,
);

export const invoicingInstaller = createSelector(allInstallers, interactingInvoice, (users: User[], invoice: Invoice) =>
  users.find(user => user.id === invoice.installerId),
);

export const interactingInstallerInvoiceOptions = createSelector(
  reportsProgressForCurrentWorkOrder,
  interactingInvoice,
  buildInstallerInvoiceOptions,
);

export const interactingCompanyInvoiceOptions = createSelector(
  reportsProgressForCurrentWorkOrder,
  interactingInvoice,
  authenticatedRole,
  buildCompanyInvoiceOptions,
);
