import { createAction, props } from '@ngrx/store';
import { ConnectedInstallerTeamLead } from '../../../domains/users/models/connected-installer-team-lead.model';

export const connectionsSearchCriteriaChanged = createAction(
  '[MODAL: Publish to Connections] Search Criteria Changed',
  props<{ searchCriteria: string }>(),
);

export const connectionsSearchCriteriaCleared = createAction('[MODAL: Publish to Connections] Search Criteria Cleared');

export const connectionSelectionChanged = createAction(
  '[MODAL: Publish to Connections] (Selection) Changed',
  props<{ selection: ConnectedInstallerTeamLead['id'][] }>(),
);

export const connectionSelectionCleared = createAction('[MODAL: Publish to Connections] (Selection) Cleared');
