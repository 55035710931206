import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Company } from './company.model';

export const {
  initialState: initialCompanyState,
  selectors: { selectAll: allCompanies, selectEntities: companyEntities },
  actions: { load: loadCompany, loadAll: loadAllCompanies },
  facade: CompaniesFacadeBase,
} = buildState(Company);

export function companyReducer(state = initialCompanyState): IEntityState<Company> {
  return state;
}
