import { createAction, props } from '@ngrx/store';

export const devicePushNotificationsRegistered = createAction(
  '[Firebase] Push Notification Token Retrieved',
  props<{ token: string }>(),
);
export const devicePushNotificationRegistrationFailed = createAction(
  '[Firebase] Device Push Registration Failed',
  props<{ error: any }>(),
);

export const deviceNotificationPermissionDenied = createAction(
  '[Firebase] Push Notification Permission Denied',
  props<{ error: Error | any }>(),
);
export const deviceNotificationRegistrationSucceeded = createAction(
  '[Firebase] Push Notification Registration Succeeded',
);
