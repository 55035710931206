import { createSelector } from '@ngrx/store';
import { and } from '../shared/utils/utils';
import { authenticatedUser } from './app/auth/auth.selectors';
import { DetailedInstaller } from './models/detailed-installer';
import { InstallerNegotiationItem } from './models/installer-negotiation-item';
import { NegotiableWorkOrderItem } from './models/negotiable-work-order-item';
import { WorkOrderNegotiationItem } from './negotiations/models/work-order-negotiation-item.model';
import { NegotiationItem } from './negotiations/negotiation-item/negotiation-item.model';
import { allNegotiationItems } from './negotiations/negotiation-item/negotiation-item.state';
import { Negotiation } from './negotiations/negotiation/negotiation.model';
import { HasInstallerId } from './types';
import { InstallerTeamLead, User } from './users/user/user.model';
import {
  hasSomeChildItems,
  matchingInstallerFor,
  whereMatchesInstallers,
  whereMatchesWorkOrderItem,
  whereWorkOrderItemMatches,
} from './utils';
import {
  negotiationItemsForCurrentWorkOrder,
  negotiationsForCurrentWorkOrder,
} from './work-order-negotiation.selectors';
import { currentOrAllNegotiatingInstallersForCurrentWorkOrder } from './work-order-users.selectors';
import { currentInstaller } from './work/installer-review/installer-review.selectors';
import { WorkOrderItem } from './work/work-order-item/work-order-item.model';
import { currentWorkOrdersItems } from './work/work-order-item/work-order-item.selectors';
import { sortedWorkOrderItems } from './work/work-order-item/work-order-item.state';

export const toInstallerNegotiationItem = (installers: DetailedInstaller[]) => (negotiationItem: NegotiationItem) => ({
  ...negotiationItem,
  installer: installers.find(matchingInstallerFor(negotiationItem)),
});

export const joinWorkOrderItemsAndNegotiations = (
  workOrderItems: WorkOrderItem[],
  negotiationItems: NegotiationItem[],
  installers: DetailedInstaller[],
): NegotiableWorkOrderItem[] =>
  workOrderItems.map(
    workOrderItem =>
      ({
        ...workOrderItem,
        negotiations: negotiationItems
          .filter(and(whereMatchesWorkOrderItem(workOrderItem), whereMatchesInstallers(installers)))
          .map(toInstallerNegotiationItem(installers)),
      }) as NegotiableWorkOrderItem,
  );

export const negotiableWorkOrderItems = createSelector(
  sortedWorkOrderItems,
  allNegotiationItems,
  currentOrAllNegotiatingInstallersForCurrentWorkOrder,
  joinWorkOrderItemsAndNegotiations,
);

export const makeWorkOrderNegotiationItem =
  (workOrderItems: WorkOrderItem[]) =>
  (negotiationItem: NegotiationItem): WorkOrderNegotiationItem => ({
    ...negotiationItem,
    workOrderItem: workOrderItems.find(whereWorkOrderItemMatches(negotiationItem)),
  });

export const workOrderNegotiationItems = createSelector(
  negotiationItemsForCurrentWorkOrder,
  currentWorkOrdersItems,
  (negotiationItems: NegotiationItem[], workOrderItems: WorkOrderItem[]): WorkOrderNegotiationItem[] =>
    negotiationItems.map(makeWorkOrderNegotiationItem(workOrderItems)),
);

export const matchesInstallerOrUser = (installer?: DetailedInstaller, user?: User) => (has: HasInstallerId) =>
  has.installerId === (installer ? installer.id : user ? (user as InstallerTeamLead).installerTeamLeadId : undefined);

export const installerWorkOrderNegotiationItems = createSelector(
  workOrderNegotiationItems,
  currentInstaller,
  authenticatedUser,
  (
    negotiationItems: WorkOrderNegotiationItem[],
    installer?: DetailedInstaller,
    user?: User,
  ): WorkOrderNegotiationItem[] =>
    !installer && !user ? [] : negotiationItems.filter(matchesInstallerOrUser(installer, user)),
);

export const installerNegotiation = createSelector(
  negotiationsForCurrentWorkOrder,
  currentInstaller,
  authenticatedUser,
  (negotiations: Negotiation[], installer?: DetailedInstaller, user?: User) =>
    negotiations?.find(matchesInstallerOrUser(installer, user)),
);

export const workOrderUnderNegotiation = createSelector(
  negotiableWorkOrderItems,
  installerNegotiation,
  (items: NegotiableWorkOrderItem[], negotiation?: Negotiation) =>
    !!negotiation ||
    hasSomeChildItems<
      NegotiableWorkOrderItem,
      NegotiationItem | InstallerNegotiationItem,
      (NegotiationItem | InstallerNegotiationItem)[]
    >(item => item?.negotiations)(items),
);
