<ion-app>
  <div
    *ngIf="!!envWarningLabel"
    class="text-center p-5"
    [ngStyle]="{
      'font-size': '2rem',
      'font-weight': 'bold',
      'z-index': '99999',
      color: envWarningText ?? 'white',
      'background-color': envWarningBG ?? '#0000ff'
    }"
  ></div>
  <ion-split-pane contentId="main" when="lg" [ngStyle]="envWarningLabel ? { 'margin-top': '10px' } : undefined">
    <div id="mini-menu" [hidden]="!hideSidebar">
      <ion-buttons style="position: absolute; top: 4px; left: 0; display: block">
        <ion-button (click)="toggleSidebar()" size="small" fill="clear" color="dark" slot="start">
          <ion-icon slot="icon-only" name="chevron-forward-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </div>
    <ion-menu
      contentId="main"
      menuId="main"
      [hidden]="!(auth.isAuthenticated$ | push) || !(auth.role$ | push) || hideSidebar"
    >
      <ion-header class="ion-text-center ion-justify-content-center">
        <ion-buttons>
          <ion-img
            id="desktop-menu-logo"
            routerLink="/app/dashboard"
            src="/assets/images/logo.svg"
            class="pointable"
            slot="start"
          ></ion-img>
          <ion-button (click)="toggleSidebar()" class="hide-on-mobile" fill="clear" color="dark" slot="end">
            <ion-icon name="exit-outline" slot="icon-only" style="transform: rotate(180deg)"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-header>
      <ion-content>
        <ion-list class="flex" style="flex-direction: column; height: 100%">
          <ion-item
            (click)="navigate('/app/dashboard')"
            [ngClass]="{ active: 'dashboard' | isActiveRoute | push }"
            button="true"
            lines="none"
            mode="ios"
            detail="false"
            data-cy="dashboard-menu-tab"
          >
            <ion-icon
              [name]="('dashboard' | isActiveRoute | push) ? 'speedometer' : 'speedometer-outline'"
              [color]="('dashboard' | isActiveRoute | push) ? 'gc-green' : 'dark'"
              title="Dashboard"
            ></ion-icon>
            <span>Dashboard</span>
            <ion-icon
              *ngIf="(auth.role$ | push | isRole: 'installerLead') && !(auth.user$ | push | isInstallerApproved)"
              name="ellipse"
              class="indicator indicator-no-transform"
            ></ion-icon>
          </ion-item>
          <ion-item
            (click)="navigate('/app/work/work-orders')"
            [ngClass]="{ active: 'work/work-orders' | isActiveRoute | push }"
            button="true"
            lines="none"
            mode="ios"
            detail="false"
            data-cy="work-menu-tab"
          >
            <ion-icon
              [name]="('work/work-orders' | isActiveRoute | push) ? 'receipt' : 'receipt-outline'"
              [color]="('work/work-orders' | isActiveRoute | push) ? 'gc-green' : 'dark'"
            ></ion-icon>
            <span>Work</span>
          </ion-item>
          <ion-item
            *ngIf="
              (auth.role$ | push | isRole: 'companyAdmin' : 'companyManager' : 'installerMember' : 'companyEmployee') ||
              ((auth.role$ | push | isRole: 'installerLead') && (auth.user$ | push | isInstallerApproved))
            "
            (click)="navigate('/app/work/custom-calendar')"
            [ngClass]="{ active: 'work/custom-calendar' | isActiveRoute | push }"
            button="true"
            lines="none"
            mode="ios"
            detail="false"
            data-cy="custom-calendar-menu-tab"
          >
            <ion-icon
              [name]="('work/custom-calendar' | isActiveRoute | push) ? 'calendar' : 'calendar-outline'"
              [color]="('work/custom-calendar' | isActiveRoute | push) ? 'gc-green' : 'dark'"
            ></ion-icon>
            <span>Calendar</span>
          </ion-item>
          <ion-item
            *ngIf="auth.role$ | push | isRole: 'companyAdmin' : 'companyManager' : 'installerLead'"
            (click)="navigate('/app/invoices')"
            [ngClass]="{ active: 'invoices' | isActiveRoute | push }"
            button="true"
            lines="none"
            mode="ios"
            detail="false"
            data-cy="invoices-menu-tab"
          >
            <ion-icon
              [name]="('invoices' | isActiveRoute | push) ? 'cash' : 'cash-outline'"
              [color]="('invoices' | isActiveRoute | push) ? 'gc-green' : 'dark'"
            >
            </ion-icon>
            <span>Invoices <span *ngIf="auth.role$ | push | isRole: 'installerLead' | not">& Progress</span></span>
          </ion-item>
          <ion-item
            *ngIf="
              (auth.role$ | push | isRole: 'companyEmployee' : 'installerMember' | not) &&
              (auth.tokenPermissions$ | push | hasPermissions: 'team:access')
            "
            (click)="navigate('/app/team')"
            [ngClass]="{ active: 'team' | isActiveRoute | push }"
            button="true"
            lines="none"
            mode="ios"
            detail="false"
            data-cy="team-menu-tab"
          >
            <ion-icon
              [name]="('team' | isActiveRoute | push) ? 'people' : 'people-outline'"
              [color]="('team' | isActiveRoute | push) ? 'gc-green' : 'dark'"
            ></ion-icon>
            <span>Team</span>
          </ion-item>
          <ion-item
            *ngIf="
              (auth.role$ | push | isRole: 'companyEmployee' : 'installerMember' | not) &&
              (auth.tokenPermissions$ | push | hasPermissions: 'connections:access')
            "
            (click)="navigate('/app/connections')"
            [ngClass]="{ active: 'connections' | isActiveRoute | push }"
            button="true"
            lines="none"
            mode="ios"
            detail="false"
            data-cy="team-connections-tab"
          >
            <ion-icon
              [name]="('connections' | isActiveRoute | push) ? 'link' : 'link-outline'"
              [color]="('connections' | isActiveRoute | push) ? 'gc-green' : 'dark'"
            ></ion-icon>
            <span>Connections</span>
          </ion-item>
          <ion-item
            *ngIf="auth.role$ | push | isRole: 'companyAdmin'"
            (click)="navigate('/app/reporting')"
            [ngClass]="{ active: 'reporting' | isActiveRoute | push }"
            button="true"
            lines="none"
            mode="ios"
            detail="false"
            data-cy="reporting-menu-tab"
          >
            <ion-icon
              [name]="('reporting' | isActiveRoute | push) ? 'stats-chart' : 'stats-chart-outline'"
              [color]="('reporting' | isActiveRoute | push) ? 'gc-green' : 'dark'"
            ></ion-icon>
            <span>Reporting</span>
          </ion-item>
          <ion-item
            (click)="navigate('/app/profile-and-settings')"
            [ngClass]="{ active: 'profile-and-settings' | isActiveRoute | push }"
            button="true"
            lines="none"
            mode="ios"
            detail="false"
            style="margin-top: auto"
            data-cy="profile-and-settings-menu-tab"
          >
            <ion-icon
              [name]="('settings' | isActiveRoute | push) ? 'cog' : 'cog-outline'"
              [color]="('settings' | isActiveRoute | push) ? 'gc-green' : 'dark'"
            ></ion-icon>
            <span>Settings</span>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main"></ion-router-outlet>

    <ion-menu
      *ngIf="menuOpen$ | push as menuOpen"
      side="end"
      [swipeGesture]="false"
      menuId="gc-chat"
      type="push"
      contentId="main"
      #chatMenu
      [class.full-screen]="fullScreen"
      [class.tiled]="!fullScreen"
      [class.closed]="!menuOpen.open"
    >
      <ion-header>
        <ion-toolbar>
          <ion-buttons class="hide-on-desktop" slot="start">
            <ion-button color="dark" (click)="chat.closeMenu()">
              <ion-icon name="arrow-back" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
          <ion-title>Chat</ion-title>
          <ion-buttons class="hide-on-mobile" slot="end">
            <ion-button (click)="fullScreen = !fullScreen" color="dark">
              <ion-icon
                [name]="fullScreen ? 'return-up-forward-outline' : 'expand-outline'"
                slot="icon-only"
              ></ion-icon>
            </ion-button>
            <ion-button
              *ngIf="(auth.role$ | push | isRole: 'companyAdmin') && (chat.hasError$ | push | not)"
              color="dark"
              (click)="chat.removeMemberFromChannel()"
            >
              <ion-icon name="exit-outline" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button color="dark" (click)="chat.closeMenu()">
              <ion-icon name="close" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <gc-chat
          *ngIf="chat.isInitialized$ | push; else openChat"
          [fullScreen]="fullScreen"
          [channels]="channelService.channels$ | push"
          [activeChannel]="channelService.activeChannel$ | push"
          [hasError]="chat.hasError$ | push"
        ></gc-chat>
        <ng-template #openChat>
          <ion-item lines="none" class="ion-text-wrap"> Use the chat button on a Work Order to get started.</ion-item>
        </ng-template>
      </ion-content>
      <ion-footer></ion-footer>
    </ion-menu>
  </ion-split-pane>

  <!--
  Only way to preload stencil modules currently.
  https://github.com/ionic-team/ionic-framework/issues/19016
  -->
  <ion-toast [hidden]="true" style="display: none"></ion-toast>
</ion-app>
