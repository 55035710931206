import { Injectable } from '@angular/core';
import { AlertController, IonicSafeString } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { format } from 'date-fns/fp';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class WorkOrderDatesService {
  constructor(private readonly alerts: AlertController) {}

  confirmReschedule(startDate: Date, endDate: Date): Observable<OverlayEventDetail<any>> {
    const display = format('LLLL d, yyyy');

    return from(
      this.alerts.create({
        header: 'Confirm Reschedule',
        message:
          new IonicSafeString(`Are you sure you want to reschedule this work order? The installer will be notified.
                <br>
                <h6>
                  ${display(startDate)} - ${display(endDate)}
                </h6>`),
        buttons: [
          {
            text: 'Yes',
            role: 'ok',
          },
          {
            text: 'No',
            role: 'cancel',
          },
        ],
      }),
    ).pipe(switchMap(alert => from(alert.present()).pipe(switchMap(() => alert.onDidDismiss()))));
  }

  warnNoScheduleEditing(): Observable<OverlayEventDetail<any>> {
    return from(
      this.alerts.create({
        header: 'Cannot remove dates.',
        message: 'Your Work Order has already been published. Please edit its schedule on the detail screen.',
        buttons: [
          {
            text: 'Ok',
            role: 'ok',
          },
        ],
      }),
    ).pipe(switchMap(alert => from(alert.present()).pipe(switchMap(() => alert.onDidDismiss()))));
  }
}
