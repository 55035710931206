import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { User } from '../users';
import { NewUserSignUpInfo } from './models/new-user-sign-up-info';

@Injectable()
export class LoginService {
  constructor(
    private http: HttpClient,
    private alerts: AlertController,
    private readonly config: ConfigService,
  ) {}

  register(userInfo: NewUserSignUpInfo): Observable<Partial<User>> {
    return this.http.post(`${this.config.host}/users/signup`, userInfo);
  }

  async confirmRegistration(): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header: 'Set Password Email Sent.',
      message: 'You will receive an email shortly with instructions for setting your password.',
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  async warnFailedRegistration(error: Error | any): Promise<string | undefined> {
    const alert = await this.alerts.create({
      header: 'Registration Failed',
      message:
        'We encountered an error processing your registration. Try again. If the error persists, contact support@gocarrera.com',
      buttons: [
        {
          text: 'Ok',
          role: 'ok',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }
}
