import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { byNameAscending } from '../../utils';

@Entity({
  modelName: 'WorkOrderItem',
  uriName: 'items',
  comparer: byNameAscending,
})
export class WorkOrderItem {
  @Key id?: string;
  workOrderId!: string;
  name!: string;
  total?: number;
  pricePerUnit!: number;
  numberOfUnits!: number;
  numberOfUnitsAlreadyInvoiced!: number;
  unitOfMeasureSymbol!: string;
  isChangeOrder!: boolean;
  createdByInstaller!: boolean;
  changeStatus?: ChangeOrderItemStatus;
  acceptedAt?: string;
  acceptedById?: string;
  voidedAt?: string;
  voidedById?: string;
  declinedAt?: string;
  declinedById?: string;
  declinedReason?: string;
}

export enum ChangeOrderItemStatus {
  PendingAcceptance = 'PENDING_ACCEPTANCE',
  Accepted = 'ACCEPTED',
  DeclinedWithReason = 'DECLINED_WITH_REASON',
  Voided = 'VOIDED',
}
