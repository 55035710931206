import { Entity, IEntityTransformer, Key } from '@briebug/ngrx-auto-entity';
import { RequireId } from '../../../shared/utils/types';

const PRODUCT_INSTALLATION_TYPE_META_DATA: Record<number, { imageUrl: string }> = {
  1: { imageUrl: '/assets/images/carpet-feature.jpg' },
  2: { imageUrl: '/assets/images/ceramic-feature.jpg' },
  3: { imageUrl: '/assets/images/resilient-feature.jpg' },
  4: { imageUrl: '/assets/images/wood-feature.jpg' },
  5: { imageUrl: '/assets/images/services-feature.jpg' },
  6: { imageUrl: '/assets/images/concrete-feature.jpg' },
  7: { imageUrl: '/assets/images/floor-care-feature.jpg' },
};

export const attachImage: IEntityTransformer = {
  fromServer: <T extends RequireId<ProductInstallationType>>(data: T) => (
    (data.imageUrl = PRODUCT_INSTALLATION_TYPE_META_DATA[data.id]?.imageUrl), data
  ),
};

@Entity({
  modelName: 'ProductInstallationType',
  uriName: 'product-installation-types',
  transform: [attachImage],
})
export class ProductInstallationType {
  @Key id?: number;
  type!: string;
  description?: string;
  imageUrl?: string;
}
