import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IfModule } from '@rx-angular/template/if';
import { LetModule } from '@rx-angular/template/let';
import { PushModule } from '@rx-angular/template/push';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { HammerRatingChartModule } from '../../shared/charts';
import { AdvancedPhoneNumberManagerModule } from '../../shared/components/advanced-phone-number-manager/advanced-phone-number-manager.module';
import { HammerRatingModule } from '../../shared/components/hammer-rating/hammer-rating.module';
import { TabSkeletonModule } from '../../shared/components/tab-skeleton/tab-skeleton.module';
import { UserHeaderModule } from '../../shared/components/user-header/user-header.module';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { CustomListPopoverModule } from '../../shared/popovers/custom-list-popover/custom-list-popover.module';
import { BackgroundAreaComponent } from './components/background-area/background-area.component';
import { ComplianceAreaComponent } from './components/compliance-area/compliance-area.component';
import { DetailsAreaComponent } from './components/details-area/details-area.component';
import { InstallerHammerRatingChartsComponent } from './components/installer-hammer-rating-charts/installer-hammer-rating-charts.component';
import { InstallerKudosDetailsComponent } from './components/installer-kudos-details/installer-kudos-details.component';
import { InstallerProductInstallationSubtypesComponent } from './components/installer-product-installation-subtypes/installer-product-installation-subtypes.component';
import { InstallerSubtypesBadgeListComponent } from './components/installer-subtypes-badge-list/installer-subtypes-badge-list.component';
import { MainContentAreaComponent } from './components/main-content-area/main-content-area.component';
import { ModalSidebarComponent } from './components/modal-sidebar/modal-sidebar.component';
import { OverviewAreaComponent } from './components/overview-area/overview-area.component';
import { SidebarHeaderComponent } from './components/sidebar-header/sidebar-header.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { TrainingAndEducationAreaComponent } from './components/training-and-education-area/training-and-education-area.component';
import { TrainingListComponent } from './components/training-list/training-list.component';
import { TrainingProductInstallationSubtypesComponent } from './components/training-product-installation-subtypes/training-product-installation-subtypes.component';
import { EmptyProductInstallationTypeComponent } from './components/training-product-installation-type-details/empty-product-installation-type/empty-product-installation-type.component';
import { TrainingProductInstallationTypeDetailsComponent } from './components/training-product-installation-type-details/training-product-installation-type-details.component';
import { TrainingTabsComponent } from './components/training-tabs/training-tabs.component';
import { UserProfileAvatarComponent } from './components/user-profile-avatar/user-profile-avatar.component';
import { MODAL_NAME } from './store/modal.name';
import { reducer } from './store/modal.state';
import { ViewInstallerProfileEnhancedUiService } from './view-installer-profile-enhanced-ui.service';
import { ViewInstallerProfileEnhancedComponent } from './view-installer-profile-enhanced.component';
import { ViewInstallerProfileEnhancedEffects } from './view-installer-profile-enhanced.effects';

@NgModule({
  declarations: [
    ViewInstallerProfileEnhancedComponent,
    ModalSidebarComponent,
    SidebarHeaderComponent,
    SidebarMenuComponent,
    UserProfileAvatarComponent,
    MainContentAreaComponent,
    OverviewAreaComponent,
    BackgroundAreaComponent,
    ComplianceAreaComponent,
    TrainingAndEducationAreaComponent,
    DetailsAreaComponent,
    TrainingProductInstallationTypeDetailsComponent,
    TrainingTabsComponent,
    TrainingListComponent,
    InstallerHammerRatingChartsComponent,
    InstallerKudosDetailsComponent,
    InstallerProductInstallationSubtypesComponent,
    EmptyProductInstallationTypeComponent,
    TrainingProductInstallationSubtypesComponent,
    InstallerSubtypesBadgeListComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    HammerRatingModule,
    UserHeaderModule,
    DirectivesModule,
    FontAwesomeModule,
    StoreModule.forFeature(MODAL_NAME, reducer),
    EffectsModule.forFeature([ViewInstallerProfileEnhancedEffects]),
    CustomListPopoverModule,
    PushModule,
    NgApexchartsModule,
    LetModule,
    IfModule,
    TabSkeletonModule,
    HammerRatingChartModule,
    ReactiveFormsModule,
    AdvancedPhoneNumberManagerModule,
    NgxTippyModule,
  ],
  providers: [ViewInstallerProfileEnhancedUiService],
})
export class ViewInstallerProfileEnhancedModule {}
