import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { NegotiationItem } from './negotiation-item.model';

export const {
  initialState: initialNegotiationItemState,
  facade: NegotiationItemFacadeBase,
  selectors: { selectAll: allNegotiationItems, selectCurrentEntities: currentNegotiationItems },
  actions: {
    loadAll: loadAllNegotiationItems,
    selectMany: selectManyNegotiationItems,
    create: createNegotiationItem,
    replace: replaceNegotiationItem,
    delete: deleteNegotiationItem,
  },
} = buildState(NegotiationItem);

export function negotiationItemReducer(state = initialNegotiationItemState): IEntityState<NegotiationItem> {
  return state;
}
