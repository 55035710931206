<ng-container *rxLet="context$; let context">
  <ng-container *ngIf="context">
    <gc-modal-sidebar>
      <gc-sidebar-header
        header
        [installer]="context.installer"
        [canEditProfile]="context.canEditProfile"
        [connection]="context.connection"
        (called)="callInstaller($event)"
        (emailed)="emailInstaller($event)"
        (websiteVisited)="visitInstallerWebsite($event)"
        (optionsOpened)="openOptionsMenu($event, context.connection)"
        (closeProfileModal)="closeProfile(context.installer)"
        (editMyProfile)="editMyProfile(context.installer)"
      ></gc-sidebar-header>
      <gc-sidebar-menu
        menu
        [installer]="context.installer"
        (overviewSelected)="selectOverview($event)"
        (backgroundSelected)="selectBackground($event)"
        (complianceSelected)="selectCompliance($event)"
        (trainingSelected)="selectTraining($event)"
        (detailsSelected)="selectDetails($event)"
      ></gc-sidebar-menu>
    </gc-modal-sidebar>
    <gc-main-content-area
      [context]="context"
      [selectedArea]="selectedArea$ | push"
      (educationAreaSelected)="selectEducationArea($event)"
      (installTypeClaimAdded)="addNewProductInstallationTypeToProfile()"
      (installTypeClaimRemoved)="removeProductInstallationTypeFromProfile($event)"
      (trainingClaimAdded)="addNewTrainingClaimToCategory($event)"
      (trainingClaimRemoved)="removeTrainingClaimFromCategory($event)"
      (subtypeAddedFromOverview)="addNewSubtypeToProfileFromOverview($event)"
      (subtypeAddedFromEducationAndTraining)="addNewSubtypeToProfileFromEducationAndTraining($event)"
      (subtypeRemoved)="removeSubtypeFromProfile($event)"
    ></gc-main-content-area>
  </ng-container>
</ng-container>
