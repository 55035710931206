import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../state';
import { WorkOrder } from '../work/work-order/work-order.model';
import { PendingNegotiationDetails } from './models/pending-negotiation.model';
import { negotiationsCanceled, negotiationsFilterUpdated, negotiationsViewed } from './negotiations.actions';
import { filteredPendingNegotiationDetails, pendingNegotiationDetails } from './negotiations.selectors';
import { onlyNegotiated } from './negotiations.state';

@Injectable()
export class NegotiationsFacade {
  pendingNegotiationDetails$ = this.store.select(pendingNegotiationDetails);
  filteredPendingNegotiationDetails$: Observable<Partial<PendingNegotiationDetails>> = this.store.select(
    filteredPendingNegotiationDetails,
  );
  onlyNegotiated$ = this.store.select(onlyNegotiated);

  constructor(private store: Store<AppState>) {}

  beginNegotiating(): void {
    this.store.dispatch(negotiationsViewed());
  }

  cancelNegotiation(workOrder: WorkOrder): void {
    this.store.dispatch(negotiationsCanceled({ workOrder }));
  }

  updateNegotiationFilter(filterOnlyNegotiated: boolean) {
    this.store.dispatch(negotiationsFilterUpdated({ filterOnlyNegotiated }));
  }
}
