import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faBookOpenCover, faGridRound, faShieldCheck, faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { InstallerTeamLead } from '../../../../domains/users';

@Component({
  selector: 'gc-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent {
  @Input() installer!: InstallerTeamLead;

  @Output() overviewSelected = new EventEmitter();
  @Output() backgroundSelected = new EventEmitter();
  @Output() complianceSelected = new EventEmitter();
  @Output() trainingSelected = new EventEmitter();
  @Output() detailsSelected = new EventEmitter();

  protected readonly faGraduationCap = faGraduationCap;
  protected readonly faSparkles = faSparkles;
  protected readonly faShieldCheck = faShieldCheck;
  protected readonly faBookOpenCover = faBookOpenCover;
  protected readonly faGridRound = faGridRound;
}
