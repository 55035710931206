<ion-toolbar mode="md">
  <ion-buttons slot="start">
    <ion-back-button
      data-cy="work-order-detail-back-button"
      *ngIf="!isModal"
      color="dark"
      defaultHref="/app/dashboard"
      (click)="back.emit()"
    ></ion-back-button>
    <ion-button *ngIf="isModal" color="dark" (click)="closeModal.emit()">
      <ion-icon name="close" slot="icon-only"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <gc-lynk-button
      *ngIf="
        (auth.tokenPermissions$ | push | hasPermissions: 'lynk.session:create') &&
        (workOrder | isStatus: 'AWARDED_NOT_STARTED' : 'IN_PROGRESS_NO_INVOICES' : 'IN_PROGRESS_HAS_INVOICES')
      "
      [currentUser]="auth.user$ | push"
      colorMode="light"
    ></gc-lynk-button>

    <ion-button
      *ngIf="
        (((!!matchedInstaller || !!biddingMatchedInstallers.length) &&
          (workOrder | isStatus: 'PUBLISHED_AWAITING_RESPONSE' : 'PUBLISHED_WITH_RESPONSES')) ||
          (workOrder
            | isStatus: 'AWARDED_NOT_STARTED' : 'IN_PROGRESS_NO_INVOICES' : 'IN_PROGRESS_HAS_INVOICES' : 'DONE')) &&
        !(workOrder | isStatus: 'DRAFT')
      "
      fill="clear"
      ngxTippy="Chat with Installer(s)"
      [color]="isChatOpen ? 'gc-green' : 'dark'"
      (click)="chat.emit(workOrder)"
    >
      <ion-icon
        name="chatbubble-ellipses"
        slot="icon-only"
      ></ion-icon>
    </ion-button>
    <div
      *ngIf="workOrder?.channels | idsToChannels | channelHasUnread | push"
      class="indicator notification-indicator bg-red border-3-white"
    ></div>

    <ion-button data-cy="schedule-detail-link-button" fill="clear" color="dark" ngxTippy="Toggle Schedule Pane"
                (click)="this.schedulerToggled.emit()"
    >
      <ion-icon name="calendar-number" slot="icon-only" [ngClass]="{ 'gc-green': schedulerOpen }"></ion-icon>
    </ion-button>

    <ion-button data-cy="schedule-menu-options-button" fill="clear" color="dark" (click)="menu.emit($event)">
      <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
