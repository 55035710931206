import { buildState, IEntityState } from '@briebug/ngrx-auto-entity';
import { Action, createReducer } from '@ngrx/store';
import { BankAccount } from './bank-account.model';

export const {
  initialState: initialBankAccountState,
  facade: BankAccountFacadeBase,
  selectors: { selectAll: allBankAccounts, selectCurrentEntity: currentBankAccount },
  actions: { loadAll: loadAllBankAccounts, select: selectBankAccount, selected: bankAccountSelected },
} = buildState(BankAccount);

const reduce = createReducer(initialBankAccountState);

export function bankAccountReducer(state = initialBankAccountState, action: Action): IEntityState<BankAccount> {
  return reduce(state, action);
}
