import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-change-order-modal-header',
  templateUrl: './change-order-modal-header.component.html',
  styleUrls: ['./change-order-modal-header.component.scss'],
})
export class ChangeOrderModalHeaderComponent {
  @Input() icon!: string;
  @Output() cancel = new EventEmitter();
}
