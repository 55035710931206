import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { PhoneNumber } from './phone-number.model';
import { PhoneNumberFacadeBase } from './phone-number.state';

@Injectable({ providedIn: 'root' })
export class PhoneNumberFacade extends PhoneNumberFacadeBase {
  constructor(store: Store<AppState>) {
    super(PhoneNumber, store);
  }
}
