import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EntityService } from '../entity.service';
import { PhoneNumberUIService } from './phone-number-ui.service';
import { PhoneNumberEffects } from './phone-number.effects';
import { PhoneNumberFacade } from './phone-number.facade';
import { PhoneNumber } from './phone-number.model';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([PhoneNumberEffects])],
  providers: [PhoneNumberUIService, PhoneNumberFacade, { provide: PhoneNumber, useClass: EntityService }],
})
export class PhoneNumberModule {}
