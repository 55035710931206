import { Pipe, PipeTransform } from '@angular/core';
import { isCompanyUser, User } from '../../domains/users';

export const isMyCompanyEmployee = (user: User, authUser: User): boolean =>
  isCompanyUser(user) &&
  isCompanyUser(authUser) &&
  user.companyId === authUser.companyId &&
  user.type !== 'COMPANY_ADMIN';

@Pipe({ name: 'isMyCompanyEmployee' })
export class IsMyCompanyEmployeePipe implements PipeTransform {
  transform(user: User, authUser: User): boolean {
    return isMyCompanyEmployee(user, authUser);
  }
}
