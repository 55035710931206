import { Injectable } from '@angular/core';
import { getKeyFromModel, Select, uriNameOfEntityOrEmpty } from '@briebug/ngrx-auto-entity';
import { NavController } from '@ionic/angular';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { routerNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { EMPTY, from, merge, Observable } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { routeIncludesPath } from '../../shared/utils/ngrx';
import { ensureChildrenRequired, ensureExists } from '../../shared/utils/rxjs';
import { AppUIService } from '../app-ui.service';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { authenticatedUser } from '../app/auth/auth.selectors';
import { EmptyKey } from '../entity.service.utils';
import { FileUploadService } from '../file-upload.service';
import { PendingFile } from '../models/pending-file';
import { AppState } from '../state';
import { loadUserProfile } from '../users/profile/profile.actions';
import { User } from '../users/user/user.model';
import { selectedEmployeeInstallerProfile, skillsAndExperienceViewed } from '../users/users-ui.actions';
import { workOrderMapRouteVisit } from '../work/work-order-map/work-order-map.state';
import { viewWorkOrder } from '../work/work-order-ui.actions';
import { selectDetailArea, workOrdersRouteVisit } from '../work/work.actions';
import { Category } from './category/category.model';
import { loadAllCategoriesIfNecessary } from './category/category.state';
import { ProductInstallationTypeClaimed } from './product-installation-types-claimed/product-installation-types-claimed.model';
import {
  createProductInstallationTypeClaim,
  deleteProductInstallationTypeClaim,
  deleteProductInstallationTypeClaimedFailure,
  loadAllProductInstallationTypesClaimedIfNecessary,
  replaceProductInstallationTypeClaim,
} from './product-installation-types-claimed/product-installation-types-claimed.state';
import { ProductInstallationType } from './product-installation-types/product-installation-types.model';
import {
  deselectProductInstallType,
  loadAllProductInstallationTypesIfNecessary,
  selectProductInstallType,
} from './product-installation-types/product-installation-types.state';
import { TrainingFile } from './training-files/training-file.model';
import { createTrainingFileSucceeded, loadAllTrainingFiles } from './training-files/training-file.state';
import {
  addTrainingFile,
  cancelEditSkillsAndExperience,
  confirmDeletionOfInstallationTypesClaimed,
  confirmDeletionOfTrainingClaimed,
  productInstallationTypeClaimUpdated,
  productInstallTypeClaimedSelected,
  showUnclaimedTrainingSelection,
} from './training.actions';
import { clearTrainingFiles, loadAllTrainingsIfNecessary } from './training/training.state';
import { TrainingClaimed } from './trainings-claimed/trainings-claimed.model';
import {
  createTrainingClaim,
  deleteTrainingClaimed,
  loadAllTrainingsClaimed,
} from './trainings-claimed/trainings-claimed.state';
import { TrainingsUIService } from './trainings-ui.service';

@Injectable()
export class TrainingEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private nav: NavController,
    private store: Store<AppState>,
    private appUI: AppUIService,
    private trainingsUI: TrainingsUIService,
    private fileUploader: FileUploadService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  // TODO: Move training loading effect to new training effects class!!

  loadTraining$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallTypeClaimedSelected, selectedEmployeeInstallerProfile),
      map(() =>
        loadAllTrainingsIfNecessary({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(ProductInstallationType)]: EmptyKey,
              [uriNameOfEntityOrEmpty(Category)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );


  loadTrainingsObtained$ = createEffect(() =>
    this.actions$.pipe(
      ofType(skillsAndExperienceViewed, selectedEmployeeInstallerProfile),
      map(action => (action.type === selectedEmployeeInstallerProfile.type ? action.installer : action.user)),
      map(user =>
        loadAllTrainingsClaimed({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: getKeyFromModel(User, user),
              [uriNameOfEntityOrEmpty(ProductInstallationTypeClaimed)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  selectUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(skillsAndExperienceViewed),
      map(({ user }) => new Select(User, user)),
    ),
  );

  navigateToSkillsAndExperience$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(skillsAndExperienceViewed),
        tap(() => this.nav.navigateForward(['app/skills-and-experience'])),
      ),
    { dispatch: false },
  );

  setCurrentProductInstallationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallTypeClaimedSelected),
      map(({ installType }) => selectProductInstallType({ entity: installType })),
    ),
  );

  loadClaimedProductInstallationTypeFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallTypeClaimedSelected),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([, user]) => user),
      ensureExists(user => !!user),
      map(user =>
        loadAllTrainingFiles({
          criteria: {
            parents: {
              [uriNameOfEntityOrEmpty(User)]: user.id,
              [uriNameOfEntityOrEmpty(ProductInstallationTypeClaimed)]: EmptyKey,
            },
          },
        }),
      ),
    ),
  );

  deselectCurrentProductInstallationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelEditSkillsAndExperience),
      map(() => deselectProductInstallType()),
    ),
  );

  unloadClaimedProductInstallationTypeFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelEditSkillsAndExperience),
      map(() => clearTrainingFiles()),
    ),
  );

  updateProductInstallationTypeClaim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productInstallationTypeClaimUpdated),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ claim }, user]) => ({ claim, user })),
      ensureChildrenRequired(value => !!value?.claim && !!value?.user?.id),
      map(({ claim, user }) =>
        claim.id
          ? replaceProductInstallationTypeClaim({
              entity: claim,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: user?.id,
                },
              },
            })
          : createProductInstallationTypeClaim({
              entity: claim,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: user?.id,
                },
              },
            }),
      ),
    ),
  );

  addTrainingFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTrainingFile),
      tap(() => this.appUI.showBlockingSpinner()),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ claim, file }, user]) => ({ claim, file, user })),
      ensureChildrenRequired<
        { claim: ProductInstallationTypeClaimed; file?: PendingFile; user?: User },
        { claim: ProductInstallationTypeClaimed; file: PendingFile; user: Required<User> }
      >(value => !!value?.claim && !!value?.file && !!value?.user?.id),
      mergeMap(({ claim, file, user }) =>
        this.fileUploader
          .uploadTrainingFile(user, claim, file)
          .pipe(map(file => createTrainingFileSucceeded({ entity: file as TrainingFile }))),
      ),
      tap(
        () => this.appUI.hideBlockingSpinnerAfterDelay(3000),
        () => this.appUI.hideBlockingSpinnerAfterDelay(3000),
      ),
    ),
  );

  showClaimCategorizedTrainingModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showUnclaimedTrainingSelection),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ category, claimedInstallType }, user]) => ({ category, claimedInstallType, user })),
      ensureChildrenRequired<
        { category: Category; claimedInstallType: ProductInstallationTypeClaimed; user?: User },
        { category: Category; claimedInstallType: ProductInstallationTypeClaimed; user: Required<User> }
      >(value => !!value?.category && !!value?.claimedInstallType && !!value?.user?.id),
      exhaustMap(({ category, claimedInstallType, user }) =>
        from(this.trainingsUI.showClaimCategorizedTrainingModal(category, claimedInstallType)).pipe(
          filter(({ role }) => role === 'ok'),
          map(({ training }) =>
            createTrainingClaim({
              entity: { ...training, userId: user.id },
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: user.id,
                  [uriNameOfEntityOrEmpty(ProductInstallationTypeClaimed)]: claimedInstallType.id,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  confirmDeletionOfTrainingClaimed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmDeletionOfTrainingClaimed),
      withLatestFrom(this.store.select(authenticatedUser)),
      map(([{ claimed }, user]) => ({ claimed, user })),
      ensureChildrenRequired<
        { claimed: TrainingClaimed; user?: User },
        { claimed: TrainingClaimed; user: Required<User> }
      >(value => !!value?.claimed && !!value?.user?.id),
      exhaustMap(({ claimed, user }) =>
        from(this.trainingsUI.confirmDeletionOfTrainingClaimed(claimed)).pipe(
          filter(role => role === 'ok'),
          map(() =>
            deleteTrainingClaimed({
              entity: claimed,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: user.id,
                  [uriNameOfEntityOrEmpty(ProductInstallationTypeClaimed)]: claimed?.productInstallationTypeClaimedId,
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  confirmDeletionOfInstallationTypesClaimed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmDeletionOfInstallationTypesClaimed),
      withLatestFrom(this.store.select(authenticatedUser)),
      exhaustMap(([{ claimed }, user]) =>
        from(
          this.appUI.confirm(
            'Remove Product Installation Type',
            'Are you sure you want to remove this Product Installation Type?',
          ),
        ).pipe(
          filter(confirm => confirm),
          map(() =>
            deleteProductInstallationTypeClaim({
              entity: claimed,
              criteria: {
                parents: {
                  [uriNameOfEntityOrEmpty(User)]: getKeyFromModel(User, user),
                },
              },
            }),
          ),
        ),
      ),
    ),
  );

  notifiyDeleteInstallationTypeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteProductInstallationTypeClaimedFailure),
        switchMap(() =>
          this.appUI.toastResult(
            'You may need to remove all trainings claimed before removing this install type.',
            'Failed to remove product installation type',
            { duration: 5000, error: true },
          ),
        ),
      ),
    { dispatch: false },
  );

  closeSkillsAndExperienceModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cancelEditSkillsAndExperience),
        tap(() => this.trainingsUI.closeAddEditSkillsAndExperienceModal()),
      ),
    { dispatch: false },
  );

  showEditModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productInstallTypeClaimedSelected),
        exhaustMap(() => from(this.trainingsUI.showAddEditSkillsAndExperienceModal()).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );
}
