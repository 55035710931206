import { MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { isRole, RoleKeys } from '../../domains/app/auth/auth.maps';
import { createSource } from './operators';

export const onlyRoles = <T>(
  role: Observable<RoleKeys | undefined> | (() => Observable<RoleKeys | undefined>),
  ...allowedRoles: RoleKeys[]
): MonoTypeOperatorFunction<T> =>
  switchMap((data: T) =>
    createSource(role).pipe(
      filter(r => !!r && isRole(r, ...allowedRoles)),
      map(() => data),
    ),
  );

export const excludeRoles = <T>(
  role: Observable<RoleKeys | undefined> | (() => Observable<RoleKeys | undefined>),
  ...excludedRoles: RoleKeys[]
): MonoTypeOperatorFunction<T> =>
  switchMap((data: T) =>
    createSource(role).pipe(
      filter(r => !!r && !isRole(r, ...excludedRoles)),
      map(() => data),
    ),
  );
