import { Injectable } from '@angular/core';
import { Actions, createEffect, EffectNotification, ofType } from '@ngrx/effects';
import { from, Observable } from 'rxjs';
import { exhaustMap, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppUIService } from '../app-ui.service';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { connectionCreationFailed, connectionCreationSucceeded } from './connection/connection.state';
import {
  confirmNetworkConnectionDeclined,
  networkConnectionAccepted,
  networkConnectionDeclined,
  networkConnectionSelected,
} from './network.actions';
import { NetworksUIService } from './networks-ui.service';
import { showAddToConnections } from './team/team.actions';

@Injectable()
export class NetworkEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly networksUI: NetworksUIService,
    private readonly appUI: AppUIService,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  showNetworkConnectionModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showAddToConnections),
        switchMap(() => this.networksUI.showNetworkConnectionModal()),
      ),
    { dispatch: false },
  );

  showCreationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(connectionCreationSucceeded),
        switchMap(({}) => this.networksUI.toastResult('Connection invite sent successfully!')),
      ),
    { dispatch: false },
  );

  showCreationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(connectionCreationFailed),
        switchMap(({}) => this.networksUI.toastResult('Connection invite failed to send.', true)),
      ),
    { dispatch: false },
  );

  showPendingConnectionActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(networkConnectionSelected),
      switchMap(({ connection }) =>
        from(this.networksUI.confirmAcceptance({})).pipe(
          filter(role => role === 'ok'),
          map(() => networkConnectionAccepted({ connection })),
        ),
      ),
    ),
  );

  confirmDeclineNetworkConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmNetworkConnectionDeclined),
      exhaustMap(({ connection, received }) =>
        from(this.appUI.confirm()).pipe(
          filter(confirm => confirm),
          map(() => networkConnectionDeclined({ connection, received })),
        ),
      ),
    ),
  );
}
