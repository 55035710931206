import { createSelector } from '@ngrx/store';
import { isRole } from '~gc/domains/app/auth/auth.maps';
import { not } from '../../shared/utils/func';
import { existsWithId, RequireId } from '../../shared/utils/types';
import { authenticatedRole, authenticatedUser } from '../app/auth/auth.selectors';
import { InstallerTeamLead, User } from '../users';
import { ifAnyOfHaveWorkOrder, isViableDateNegotiation } from '../utils';
import { installerWorkOrderNegotiationItems } from '../work-order-item-negotiation.selectors';
import { WorkOrderItem } from '../work/work-order-item/work-order-item.model';
import { currentWorkOrdersItems } from '../work/work-order-item/work-order-item.selectors';
import { WorkOrder } from '../work/work-order/work-order.model';
import { currentWorkOrder } from '../work/work-order/work-order.state';
import { PendingNegotiationDetails } from './models/pending-negotiation.model';
import { WorkOrderNegotiationItem } from './models/work-order-negotiation-item.model';
import { Negotiation } from './negotiation/negotiation.model';
import { currentNegotiation } from './negotiation/negotiation.state';
import { onlyNegotiated } from './negotiations.state';


export const guaranteeProperAskingPrice =
  () =>
  (negotiationItem: WorkOrderNegotiationItem): WorkOrderNegotiationItem => ({
    ...negotiationItem,
    currentAskPrice: negotiationItem.currentAskPrice ?? negotiationItem.workOrderItem?.pricePerUnit ?? -1, // TODO: Ask daniel about this!!
  });

export const makeNewWorkOrderNegotiationItem =
  (workOrder: RequireId<WorkOrder>, currentUser: RequireId<User>) =>
  (workOrderItem: RequireId<WorkOrderItem>): WorkOrderNegotiationItem => ({
    workOrderId: workOrder.id,
    installerId: currentUser.id,
    workOrderItemId: workOrderItem.id,
    workOrderItem,
    currentAskPrice: workOrderItem.pricePerUnit,
    isAcceptedByInstaller: false,
  });

export const enhanceNegotiationForManagers = (
  role: string,
  workOrder: WorkOrder,
  negotiation?: Negotiation,
): Negotiation | undefined =>
  isRole(role, 'companyAdmin', 'companyManager') && isViableDateNegotiation(negotiation, workOrder) && !!negotiation
    ? {
        ...negotiation,
        currentAskStartDate: negotiation?.currentAskStartDate ?? workOrder.scheduledStartDate,
      }
    : undefined;

export const enhanceNegotiationForLeads = (
  role: string,
  user: User,
  workOrder: WorkOrder,
  negotiation?: Negotiation,
): Negotiation | undefined =>
  isRole(role, 'installerLead')
    ? ({
        ...(negotiation ?? { installerId: user.id, workOrderId: workOrder.id }),
        currentAskStartDate: negotiation?.currentAskStartDate ?? workOrder.scheduledStartDate,
      } as unknown as Negotiation)
    : undefined;

export const normalizeNegotiationItems = (
  role: string,
  negotiationItems: WorkOrderNegotiationItem[],
): WorkOrderNegotiationItem[] =>
  (negotiationItems ?? [])
    .filter(
      item =>
        isRole(role, 'installerLead') ||
        (isRole(role, 'companyAdmin', 'companyManager') && !item.isAcceptedByInstaller),
    )
    .map(guaranteeProperAskingPrice());

export const generateNegotiationsForRemainingWorkOrderItems = (
  authenticatedUser: RequireId<User>,
  workOrder: RequireId<WorkOrder>,
  negotiationItems: WorkOrderNegotiationItem[],
  workOrderItems: WorkOrderItem[],
): WorkOrderNegotiationItem[] =>
  (authenticatedUser as InstallerTeamLead).installerTeamLeadId
    ? workOrderItems
        .filter(not(ifAnyOfHaveWorkOrder(negotiationItems)))
        .filter(existsWithId)
        .map(makeNewWorkOrderNegotiationItem(workOrder, authenticatedUser))
    : [];

export const findPendingNegotiationItems = (
  role: string,
  authenticatedUser: RequireId<User>,
  workOrder: RequireId<WorkOrder>,
  negotiationItems: WorkOrderNegotiationItem[],
  workOrderItems: WorkOrderItem[],
): WorkOrderNegotiationItem[] =>
  [
    ...normalizeNegotiationItems(role, negotiationItems),
    ...generateNegotiationsForRemainingWorkOrderItems(authenticatedUser, workOrder, negotiationItems, workOrderItems),
  ].sort((a, b) => (a.workOrderItem?.name ?? '').localeCompare(b.workOrderItem?.name ?? ''));

export const buildPendingNegotiation = (
  role: string,
  authenticatedUser: RequireId<User>,
  workOrder: RequireId<WorkOrder>,
  negotiationItems: WorkOrderNegotiationItem[],
  workOrderItems: WorkOrderItem[],
  negotiation?: Negotiation,
): PendingNegotiationDetails => ({
  isUnavailable: false,
  negotiation:
    enhanceNegotiationForManagers(role, workOrder, negotiation) ??
    enhanceNegotiationForLeads(role, authenticatedUser, workOrder, negotiation),
  negotiationItems: findPendingNegotiationItems(role, authenticatedUser, workOrder, negotiationItems, workOrderItems),
});

export const combineNegotiationAndWorkOrderNegotiationItemsIntoPendingNegotiation = (
  negotiationItems: WorkOrderNegotiationItem[],
  workOrderItems: WorkOrderItem[],
  authenticatedUser?: User,
  role?: string,
  workOrder?: WorkOrder,
  negotiation?: Negotiation,
): PendingNegotiationDetails =>
  existsWithId(workOrder) && existsWithId(authenticatedUser) && role
    ? buildPendingNegotiation(role, authenticatedUser, workOrder, negotiationItems, workOrderItems, negotiation)
    : { isUnavailable: true };

export const pendingNegotiationDetails = createSelector(
  installerWorkOrderNegotiationItems,
  currentWorkOrdersItems,
  authenticatedUser,
  authenticatedRole,
  currentWorkOrder,
  currentNegotiation,
  combineNegotiationAndWorkOrderNegotiationItemsIntoPendingNegotiation,
);

export const filterPendingNegotiation = (
  negotiation: Partial<PendingNegotiationDetails>,
  filterNegotiated: boolean,
): Partial<PendingNegotiationDetails> => ({
  ...negotiation,
  negotiation: filterNegotiated
    ? negotiation.negotiation?.currentBidStartDate
      ? negotiation.negotiation
      : undefined
    : negotiation.negotiation,
  negotiationItems: filterNegotiated
    ? negotiation.negotiationItems?.filter(item => !!item.currentBidPrice)
    : negotiation.negotiationItems,
});

export const filteredPendingNegotiationDetails = createSelector(
  pendingNegotiationDetails,
  onlyNegotiated,
  filterPendingNegotiation,
);
