import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({
  modelName: 'HammerRating',
  uriName: 'hammer-rating',
})
export class HammerRating {
  @Key id?: number;
  value!: number;
  productInstallationTypeId!: number;
  userID!: string;
}
