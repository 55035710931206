import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AuthFacade } from '~gc/domains/app/auth/auth.facade';
import { Invoice } from '~gc/domains/invoices';
import { reportsProgressForCurrentWorkOrder } from '../../domains/invoices/invoice-ui.selectors';

@Component({
  selector: 'gc-decline-invoice-modal',
  templateUrl: './decline-invoice-modal.component.html',
  styleUrls: ['./decline-invoice-modal.component.scss'],
})
export class DeclineInvoiceModalComponent {
  @Input() invoice!: Invoice;

  reportsProgress$ = this.store.select(reportsProgressForCurrentWorkOrder);

  constructor(
    public auth: AuthFacade,
    public modalGC: ModalController,
    public readonly store: Store,
  ) {}
}
