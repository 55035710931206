import { Injectable } from '@angular/core';
import { LoadAllIfNecessary } from '@briebug/ngrx-auto-entity';
import { Actions, createEffect, EffectNotification, ofType, OnRunEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { exhaustMap, map, takeUntil } from 'rxjs/operators';
import { claimsAndTokenRetrieved } from '../app/auth/auth-connect.actions';
import { logout } from '../app/auth/auth.actions';
import { AppState } from '../state';
import { continueToNextEditStep } from '../work/work-order-edit/work-order-edit.actions';
import { viewWorkOrder, viewWorkOrderFromModal } from '../work/work-order-ui.actions';
import { UnitOfMeasure } from './unit-of-measure.model';

@Injectable()
export class UnitsOfMeasureEffects implements OnRunEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
  ) {}

  ngrxOnRunEffects(resolvedEffects$: Observable<EffectNotification>) {
    return this.actions$.pipe(
      ofType(claimsAndTokenRetrieved),
      exhaustMap(() => resolvedEffects$.pipe(takeUntil(this.actions$.pipe(ofType(logout))))),
    );
  }

  loadUnitsOfMeasure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(continueToNextEditStep, viewWorkOrder, viewWorkOrderFromModal),
      map(() => new LoadAllIfNecessary(UnitOfMeasure)),
    ),
  );
}
