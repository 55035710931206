import { AfterContentChecked, Component, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { UserFacade } from '../../../../domains/users/user/user.facade';
import { WorkOrderRelationsFacade } from '../../../../domains/work-order-relations.facade';
import { InstallerReviewFacade } from '../../../../domains/work/installer-review/installer-review.facade';

@Component({
  selector: 'gc-review-installers-modal',
  templateUrl: './review-installers-modal.component.html',
  styleUrls: ['./review-installers-modal.component.scss'],
})
export class ReviewInstallersModalComponent implements AfterContentChecked {
  bottomEdge = 0;

  constructor(
    public users: UserFacade,
    public installerReview: InstallerReviewFacade,
    public workOrderRelations: WorkOrderRelationsFacade,
    public modalGC: ModalController,
    private readonly elementRef: ElementRef,
  ) {}

  private readonly contentChanged = new Subject();
  private readonly heightAdjuster = this.contentChanged.asObservable().pipe(
    debounceTime(10),
    map(() => this.elementRef.nativeElement.querySelector('#bottomEdge')?.offsetTop),
    filter(nextBottomEdge => nextBottomEdge > 0),
    map(nextBottomEdge => nextBottomEdge + 100),
    tap(nextBottomEdge => console.log('nextBottomEdge', nextBottomEdge)),
    tap(nextBottomEdge => (this.bottomEdge = nextBottomEdge)),
  );

  ngAfterContentChecked() {
    this.contentChanged.next(null);
  }
}
