import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { EntityService } from '../entity.service';
import { DiscountCode } from './discount-codes/discount-code.model';
import { DiscountCodesFacade } from './discount-codes/discount-codes.facade';
import { DISCOUNT_CODES_FEATURE_KEY, discountCodesReducer } from './discount-codes/discount-codes.state';
import { PartnerDealsEffects } from './partner-deals.effects';
import { PartnerDealsFacade } from './partner-deals.facade';

@NgModule({
  imports: [
    EffectsModule.forFeature([PartnerDealsEffects]),
    StoreModule.forFeature(DISCOUNT_CODES_FEATURE_KEY, discountCodesReducer),
  ],
  providers: [
    EntityService,
    { provide: DiscountCode, useExisting: EntityService },
    PartnerDealsFacade,
    DiscountCodesFacade,
  ],
})
export class PartnerDealsModule {}
