import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PushModule } from '@rx-angular/template/push';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxCurrencyModule } from 'ngx-currency';
import { PublishToConnectionsModalComponent } from '~gc/modals/add-edit-work-order/publish-to-connections-modal/publish-to-connections-modal.component';
import { DatepickerItemModule } from '../../shared/components/datepicker-item/datepicker-item.module';
import { EmptyListWarningModule } from '../../shared/components/empty-list-warning/empty-list-warning.module';
import { FileDropTargetModule } from '../../shared/components/file-drop-target/file-drop-target.module';
import { FileManagerModule } from '../../shared/components/file-manager/file-manager.module';
import { FormUiModule } from '../../shared/components/forms-ui/form-ui.module';
import { ScheduleModule } from '../../shared/components/schedule/schedule.module';
import { SearchModule } from '../../shared/components/search/search.module';
import { SelectInstallerModule } from '../../shared/components/select-installer/select-installer.module';
import { TooltipModule } from '../../shared/components/tooltip/tooltip.module';
import { WorkOrderItemTotalModule } from '../../shared/components/work-order-item-total/work-order-item-total.module';
import { DirectivesModule } from '../../shared/directives/directives.module';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { AddEditWorkOrderModalComponent } from './add-edit-work-order-modal/add-edit-work-order-modal.component';
import { BasicsStepComponent } from './basics-step/basics-step.component';
import { DetailsStepComponent } from './details-step/details-step.component';
import { ItemsStepComponent } from './items-step/items-step.component';
import { PublishToEmployeesModalComponent } from './publish-to-employees-modal/publish-to-employees-modal.component';
import { PublishingStepComponent } from './publishing-step/publishing-step.component';
import { SaveNextComponent } from './save-next/save-next.component';
import { StartEndDateComponent } from './start-end-date/start-end-date.component';
import { FEATURE_NAME } from './store/feature.name';
import { reducer } from './store/feature.state';
import { WorkOrderDatesEffects } from './work-order-dates.effects';
import { WorkOrderDatesService } from './work-order-dates.service';
import { WorkOrderItemSlidingComponent } from './work-order-item-sliding/work-order-item-sliding.component';

@NgModule({
  declarations: [
    AddEditWorkOrderModalComponent,
    PublishToEmployeesModalComponent,
    PublishToConnectionsModalComponent,
    BasicsStepComponent,
    DetailsStepComponent,
    ItemsStepComponent,
    PublishingStepComponent,
    SaveNextComponent,
    WorkOrderItemSlidingComponent,
    StartEndDateComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([WorkOrderDatesEffects]),
    NgxCurrencyModule,
    EditorModule,
    FormUiModule,
    PipesModule,
    WorkOrderItemTotalModule,
    SelectInstallerModule,
    FileManagerModule,
    DatepickerItemModule,
    TooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    ScheduleModule,
    PushModule,
    DirectivesModule,
    EmptyListWarningModule,
    FileDropTargetModule,
    SearchModule,
  ],
  providers: [WorkOrderDatesService],
  exports: [AddEditWorkOrderModalComponent, PublishToEmployeesModalComponent, PublishToConnectionsModalComponent],
})
export class AddEditWorkOrderModule {}
