<ion-list lines="none">
  <ion-list-header>Select Employee</ion-list-header>

  <ion-radio-group [value]="workOrder.awardedToInstallerId">
    <ion-item *ngFor="let employee of employees" (click)="popoverGC.dismiss(employee.id)" detail="false" button>
      <ion-avatar slot="start">
        <ion-img [src]="employee.profilePhoto"></ion-img>
      </ion-avatar>
      <ion-label> {{ employee.firstName }} {{ employee.lastName }} </ion-label>
      <ion-radio slot="end" [value]="employee.id"></ion-radio>
    </ion-item>
  </ion-radio-group>
</ion-list>
