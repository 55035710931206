import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkOrderDate } from '~gc/core/calendar';
import { BrowserService } from '~gc/domains/browser.service';
import { FacilityType } from '~gc/domains/locations';
import { ProductInstallationType } from '~gc/domains/training/product-installation-types/product-installation-types.model';
import { WorkOrderForm } from '../draft-work-order.form';

@Component({
  selector: 'gc-basics-step',
  templateUrl: './basics-step.component.html',
  styleUrls: ['./basics-step.component.scss'],
})
export class BasicsStepComponent {
  @Input() facilityTypes?: FacilityType[];
  @Input() productInstallationTypes?: ProductInstallationType[];
  @Input() form!: WorkOrderForm;

  @Output() dateAdded = new EventEmitter<WorkOrderDate>();
  @Output() dateSaved = new EventEmitter<WorkOrderDate>();
  @Output() dateDeleted = new EventEmitter<string>();

  constructor(private readonly browser: BrowserService) {}

  async showKnowledgeBase() {
    await this.browser.openUrl('https://gocarrera.com/resources/knowledge-base?h=0');
  }

  protected readonly Event = Event;
}
