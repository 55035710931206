<ion-header>
  <gc-change-order-modal-header (cancel)="cancelAddEdit()">Add Change Order Item</gc-change-order-modal-header>
</ion-header>

<ion-content>
  <div *ngIf="form!.errors?.maxItemTotalAmountAllowed" class="alert alert__warning ion-margin">
    Change Order Item amount must be less than {{ maxItemTotalAmountAllowed | currency }}
  </div>
  <div *ngIf="form!.get('numberOfUnits')?.dirty && form!.get('numberOfUnits')?.invalid || form!.get('numberOfUnits')?.errors?.['isPositive'] === false" class="alert alert__warning ion-margin">
    Change Order Item quantity must be positive and contain only numbers
  </div>
  <div *ngIf="form!.get('pricePerUnit')?.errors?.['isPositive'] === false" class="alert alert__warning ion-margin">
    Change Order Item amount must be positive
  </div>
  <form [formGroup]="form" class="ion-margin-top">
    <ion-list>
      <ion-item>
        <ion-input
          data-cy="change-order-item-name-input"
          formControlName="name"
          label="Name"
          labelPlacement="stacked"
        ></ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          data-cy="change-order-item-quantity-input"
          formControlName="numberOfUnits"
          label="Number of Units"
          labelPlacement="stacked"
          color="danger"
          type="number"
          step="1"
        ></ion-input>
      </ion-item>
      <ion-item>
        <!--<ion-input
          data-cy="change-order-item-price-input"
          formControlName="pricePerUnit"
          label="Cost per Unit"
          labelPlacement="stacked"
          color="danger"
          step=".01"
          placeholder="$0.00"
          [maskito]="currencyMask"
          [maskitoElement]="maskPredicate"

        ></ion-input>-->
        <input
          data-cy="change-order-item-price-input"
          placeholder="$0.00 per unit"
          label="Cost per Unit"
          currencyMask
          formControlName="pricePerUnit"
          inputmode="numeric"
          type="text"
          contenteditable="true"
          class="money-input"
          style="
            padding: 0 20px;
            box-sizing: border-box;
            border: none;
            background: #f6faf3;
            width: 100%;
            height: 100%;
            text-align: left !important;
          "
          [ngStyle]="{ 'color': form!.get('pricePerUnit')?.errors?.['isPositive'] === false ? 'red' : 'black'}"
        />
      </ion-item>
      <ion-item lines="full">
        <ion-select
          data-cy="change-order-item-type-input"
          formControlName="unitOfMeasureSymbol"
          label="Unit of Measure"
          labelPlacement="floating"
        >
          <ion-select-option *ngFor="let unit of unitsOfMeasure" [value]="unit.symbol">
            {{ unit.description }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
  </form>
</ion-content>

<ion-footer class="ion-padding">
  <gc-change-order-modal-footer [disabled]="form!.invalid" (submitChangeOrder)="submit()"
    >Add Change Order Item
  </gc-change-order-modal-footer>
</ion-footer>
